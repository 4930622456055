import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Input } from 'antd';
import SelfBarChart from './components/SelfBarChart';
import BarChartsViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class BarCharts extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new BarChartsViewModel({ fullMarks: props.fullMarks });
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.title}>平均總分、落實度、滿意度圖表</div>

        <div className={styles.basicFractionContainer}>
          <div className={styles.label}>基準分數:</div>

          <div className={styles.formContainer}>
            <Input
              className={styles.input}
              size="small"
              value={this.vm.basicFraction}
              onChange={this.vm.onChangeBasicFraction}
            />
          </div>
        </div>

        <div className={styles.barCharts}>
          {this.props.barCharts.map((item) => {
            return (
              <div className={styles.paddingRight} key={item.id}>
                <SelfBarChart
                  data={item.data}
                  basicFraction={this.vm.basicFraction}
                  startAt={item.startAt}
                  endAt={item.endAt}
                  fullMarks={this.props.fullMarks}
                  tickCount={this.props.tickCount}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}


BarCharts.propTypes = {
  barCharts: PropTypes.array,
  fullMarks: PropTypes.number.isRequired,
  tickCount: PropTypes.number.isRequired
};

BarCharts.defaultProps = {
  barCharts: []
};

export default BarCharts;
