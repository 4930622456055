import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import ProjectServices from 'src/services/ProjectServices';
import ErrorServices from 'src/services/ErrorServices';
import { ROUTES, ROUTE_PARAMS, USER_TYPES } from 'src/global/constants';
import CustomAnchorViewModel from 'src/components/CustomAnchor/viewModel';
import CreateModalViewModel from './components/CreateModal/viewModel';
import RouteViewModel from './viewModels/RouteViewModel';

class ProjectListPageViewModel {
  @observable profile = null;
  @observable searchStatus = null;
  @observable searchOwner = null;
  @observable keyword = '';
  @observable isInit = true;
  @observable isAwait = false;

  router = null;

  routeVM = new RouteViewModel();
  createModalVM = new CreateModalViewModel();
  anchorVM = null;

  @computed
  get hasEdit() {
    switch (this.profile.type) {
      case USER_TYPES.boss.value:
      case USER_TYPES.salesperson.value:
        return true;

      default:
        return false;
    }
  }

  constructor(props) {
    this.profile = props.profile;
    this.router = props.router;

    this.anchorVM = new CustomAnchorViewModel({ api: this.postProjectListAPI });

    makeObservable(this);
  }

  didMount = (props) => {
    this.init(props);
  };

  @action
  init = (props) => {
    // 根據網址判斷資料
    this.routeVM.init(props.router);

    //  自動選擇第一個
    this.searchStatus = this.routeVM.statuses[0].value;
    this.searchOwner = this.routeVM.owners[0].value;

    if (!this.isAwait) {
      this.anchorVM.reset();
    }
  };

  @action
  onChangeStatus = (value) => {
    this.searchStatus = value;
    this.anchorVM.reset();
  };

  @action
  onChangeOwner = (value) => {
    this.searchOwner = value;
    this.anchorVM.reset();
  };

  @action
  onKeywordChange = (event) => {
    this.keyword = event.target.value;
  }

  @action
  onSearch = () => {
    this.anchorVM.reset();
  }

  @action
  onCreated = () => {
    this.anchorVM.reset();
  };

  onToClientClick = ({ clientId, projectId }) => {
    const route = `/${ROUTES.client.value}/${clientId}`;
    const state = {};

    state[ROUTE_PARAMS.projectId] = projectId;

    this.router.navigate(route, { state });
  }

  @action
  postProjectListAPI = async ({ anchor }) => {
    this.isAwait = true;

    const steps = this.searchStatus.split(',');
    const owner = this.searchOwner === 'all' ? undefined : this.searchOwner;
    const checkKeyword = !!this.keyword.trim();

    const res = await ProjectServices.postProjectList({
      anchor,
      steps,
      handler: owner,
      keyword: checkKeyword ? this.keyword : undefined
    });

    runInAction(() => {
      this.isInit = false;
      this.isAwait = false;
    });

    return res;
  }
}

export default ProjectListPageViewModel;
