import React from 'react';
import { Form } from 'antd';

// 使用 antd 的 useForm
function withForm(Component) {
  return (props) => {
    const [form] = Form.useForm();

    return <Component {...props} form={form} />;
  };
}

export default withForm;
