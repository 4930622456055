import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import withRouter from 'src/components/withRouter';
import { TOPIC_TYPES } from 'src/global/topic';
import InferiorFacetTabViewModel from './viewModel';
import FractionAnswer from '../FractionAnswer';
import ShortAnswer from '../ShortAnswer';
import styles from './styles.module.scss';

@observer
class InferiorFacetTab extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new InferiorFacetTabViewModel({
      data: props.data,
      site: props.site,
      facet: props.facet,
      inferiorFacet: props.inferiorFacet,
      createDrawerVM: props.createDrawerVM,
      onCreated: props.onCreated,
      router: props.router
    });
  }

  componentDidUpdate(preProps) {
    this.vm.didUpdate(this.props, preProps);
  }

  renderTopic = (item) => {
    switch (item.type) {
      case TOPIC_TYPES.fraction.value:
      case TOPIC_TYPES.bonus.value:
        return (
          <FractionAnswer
            vm={item}
            key={item.id}
            disabled={this.props.disabled}
          />
        );

      case TOPIC_TYPES.shortAnswer.value:
        return (
          <ShortAnswer vm={item} key={item.id} disabled={this.props.disabled} />
        );

      default:
        return null;
    }
  };

  render() {
    return (
      <div className={styles.container}>
        {this.vm.data.map((item) => {
          return this.renderTopic(item);
        })}

        {!this.props.disabled && (
          <div className={styles.buttons}>
            <Button
              className={styles.button}
              onClick={this.vm.onCreateFractionAnswer}
              disabled={this.vm.isAwait}
            >
              ＋新增分數題
            </Button>

            <Button
              className={styles.button}
              onClick={this.vm.onCreateShortAnswer}
              disabled={this.vm.isAwait}
            >
              ＋新增簡答題
            </Button>

            <Button
              className={styles.button}
              onClick={this.vm.onOpenCreateDrawer}
              disabled={this.vm.isAwait}
            >
              ＋從題庫中選擇
            </Button>
          </div>
        )}
      </div>
    );
  }
}

InferiorFacetTab.propTypes = {
  data: PropTypes.array.isRequired,
  site: PropTypes.string.isRequired,
  facet: PropTypes.string.isRequired,
  inferiorFacet: PropTypes.string.isRequired,
  createDrawerVM: PropTypes.object.isRequired,
  onCreated: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  router: PropTypes.object.isRequired
};

InferiorFacetTab.defaultProps = {};

export default withRouter(InferiorFacetTab);
