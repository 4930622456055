import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { observer } from 'mobx-react';
import StyleItem from 'src/components/StyleItem';
import TextAreaModal from 'src/components/TextAreaModal';
import InternalItemViewModel from './viewModel';

// 溝通與說明紀錄(內部溝通專用)
@observer
class InternalItem extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new InternalItemViewModel({
      project: props.project,
      checkParams: props.checkParams
    });
  }

  componentDidUpdate(preProps) {
    this.vm.didUpdate(this.props, preProps);
  }

  render() {
    return (
      <>
        <StyleItem>
          <span>溝通與說明紀錄(內部溝通專用)</span>

          <Button type="primary" onClick={this.vm.modalVM.show}>
            {this.vm.label.button}
          </Button>
        </StyleItem>

        <TextAreaModal
          vm={this.vm.modalVM}
          label="溝通與說明紀錄(內部溝通專用)"
          subLabel="● 在此欄位留言者，請務必註明「留言人、留言日期時間」"
          value={this.vm.value}
          onChange={this.vm.onValueChange}
          onConfirm={this.vm.onConfirm}
          confirmText="儲存"
          disabled={this.vm.disabled.modal}
          isAwait={this.vm.isAwait}
          rows={25}
          maxLength={9999}
        />
      </>
    );
  }
}

InternalItem.propTypes = {
  project: PropTypes.object.isRequired,
  checkParams: PropTypes.object.isRequired
};

InternalItem.defaultProps = {
};

export default InternalItem;
