import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button, Checkbox, Input } from 'antd';
import { IMPLEMENTATION_TYPES, SATISFACTION_TYPES } from 'src/global/topic';
import ICONS from 'src/global/icons';
import clsx from 'clsx';
import DeleteModal from 'src/components/DeleteModal';
import styles from './styles.module.scss';

@observer
class FractionAnswer extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
  }

  render() {
    return (
      <div
        className={clsx([
          styles.container,
          this.vm.invalid && styles.invalidBorder
        ])}
      >
        <div className={styles.title}>
          <div className={styles.text}>題目型態：分數題</div>

          <Button
            type="link"
            className={styles.deleteButton}
            onClick={this.vm.deleteModalVM.show}
            disabled={this.props.disabled}
          >
            <ICONS.Delete />
          </Button>
        </div>

        <div className={styles.bonusContainer}>
          <Checkbox
            checked={this.vm.isBonus}
            onChange={this.vm.setBonus}
            onBlur={this.vm.onBlur}
            disabled={this.props.disabled}
          />

          <span className={styles.label}>加分題</span>
        </div>

        <div className={styles.inputContainer}>
          <span className={styles.label}>題目：</span>

          <Input
            size="small"
            value={this.vm.topic}
            onChange={this.vm.onChangeTopic}
            onBlur={this.vm.onBlur}
            disabled={this.props.disabled}
            maxLength={100}
            showCount
          />
        </div>

        <div className={styles.inputContainer}>
          <span className={styles.label}>說明：</span>

          <Input.TextArea
            size="small"
            rows={4}
            value={this.vm.content}
            onChange={this.vm.onChangeContent}
            onBlur={this.vm.onBlur}
            disabled={this.props.disabled}
            maxLength={500}
            showCount
          />
        </div>

        {/* 落實度分數 */}
        <div className={styles.weightedContainer}>
          <ICONS.Success
            className={clsx([
              styles.icon,
              this.vm.check.hasImplementWeighted && styles.active
            ])}
          />

          <div className={styles.item}>
            <div className={styles.label}>落實度分數：</div>
          </div>

          {this.vm.options.implementation.map((item) => {
            const checked = this.vm.implementation === item.value;
            const inputValue = checked ? this.vm.implementWeighted : null;
            const disabledInput = this.props.disabled || !checked;
            const isBonus = item.value === IMPLEMENTATION_TYPES[0].value;

            return (
              <div className={styles.item} key={item.value}>
                {isBonus && <div className={styles.semicolon}>;</div>}

                <Checkbox
                  className={styles.checkbox}
                  checked={checked}
                  onChange={() => {
                    this.vm.setImplementation(item.value);
                  }}
                  disabled={this.props.disabled}
                />

                <div className={styles.label}>{item.label}</div>

                <div className={styles.weightedInputContainer}>
                  <Input
                    size="small"
                    suffix={!isBonus && '%'}
                    value={inputValue}
                    onChange={this.vm.onChangeImplementWeighted}
                    disabled={disabledInput}
                    onBlur={this.vm.onBlur}
                    controls={false}
                  />
                </div>
              </div>
            );
          })}
        </div>

        {/* 滿意度分數 */}
        <div className={styles.weightedContainer}>
          <ICONS.Success
            className={clsx([
              styles.icon,
              this.vm.check.hasSatisWeighted && styles.active
            ])}
          />

          <div className={styles.item}>
            <div className={styles.label}>滿意度分數：</div>
          </div>

          {this.vm.options.satisfaction.map((item, i) => {
            const checked = this.vm.satisfaction === item.value;
            const inputValue = checked ? this.vm.satisWeighted : null;
            const disabledInput = this.props.disabled || !checked;
            const isBonus = item.value === SATISFACTION_TYPES[0].value;

            return (
              <div className={styles.item} key={item.value}>
                {!!i && <div className={styles.semicolon}>;</div>}

                <Checkbox
                  className={styles.checkbox}
                  checked={checked}
                  onChange={() => {
                    this.vm.setSatisfaction(item.value);
                  }}
                  disabled={this.props.disabled}
                />

                <div className={styles.label}>{item.label}</div>

                <div className={styles.weightedInputContainer}>
                  <Input
                    size="small"
                    suffix={!isBonus && '%'}
                    value={inputValue}
                    onChange={this.vm.onChangeSatisWeighted}
                    disabled={disabledInput}
                    onBlur={this.vm.onBlur}
                    controls={false}
                  />
                </div>
              </div>
            );
          })}
        </div>

        <DeleteModal
          vm={this.vm.deleteModalVM}
          onConfirm={this.vm.onDeleteConfirm}
        />
      </div>
    );
  }
}

FractionAnswer.propTypes = {
  vm: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired
};

FractionAnswer.defaultProps = {};

export default FractionAnswer;
