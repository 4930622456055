import { makeObservable, observable, action, computed } from 'mobx';
import { diff } from 'deep-diff';
import { ROUTES, ROUTE_PARAMS, PROJECT_STATUS } from 'src/global/constants';

class BudgetItemViewModel {
  @observable data = null;
  @observable checkParams = null;
  router = null;

  @computed
  get isShow() {
    switch (this.data.step) {
      case PROJECT_STATUS.S2.value:
      case PROJECT_STATUS.S3.value:
      case PROJECT_STATUS.S7.value:
      case PROJECT_STATUS.S8.value:
      case PROJECT_STATUS.S9.value:
      case PROJECT_STATUS.S10.value: {
        const isBoss = this.checkParams.isBoss;
        const isOM = this.checkParams.isOM;

        return isBoss || isOM;
      }

      default:
        return false;
    }
  }

  @computed
  get isFinish() {
    // 專案是否已設定損益表
    return this.data.profitFormCompleted;
  }

  constructor(props) {
    this.router = props.router;

    this.init(props);

    makeObservable(this);
  }

  @action
  init = (props) => {
    this.data = props.data;
    this.checkParams = props.checkParams;
  };

  didUpdate = (props, preProps) => {
    const diffData = diff(props.data, preProps.data);
    const diffCheckParams = diff(props.checkParams, preProps.checkParams);

    if (diffData || diffCheckParams) {
      this.init(props);
    }
  };

  onToBudget = () => {
    const projectId = this.router.params[ROUTE_PARAMS.projectId];
    const route = `/${ROUTES.budget.value}/${projectId}`;

    this.router.navigate(route);
  };
}

export default BudgetItemViewModel;
