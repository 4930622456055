class CheckFormItemDataModel {
  constructor(props) {
    this.id = props.id;

    this.facet = props.facet;
    this.inferiorFacet = props.inferiorFacet;

    this.type = props.type;

    this.site = props.site;

    this.topic = props.topic;

    this.content = props.content;

    this.implementation
      = props.implementation === null ? null : String(props.implementation);

    this.implementWeighted
      = props.implementWeighted === null ? null : String(props.implementWeighted);

    this.satisfaction
      = props.satisfaction === null ? null : String(props.satisfaction);

    this.satisWeighted
      = props.satisWeighted === null ? null : String(props.satisWeighted);
  }
}

export default CheckFormItemDataModel;
