class CaseListItemDataModel {
  constructor(props) {
    this.id = props.id;

    this.siteType = props.siteType;

    this.step = props.step;
    this.stepName = props.stepName;

    this.handler = props.handler;

    this.visitorId = props.visitorId;

    this.name = props.name;

    // 案件開始日期
    this.startDate = props.startDate;

    // 案件截止日
    this.expiryDate = props.expiryDate;

    this.address = props.address;

    // 地區, 永遠拿到 string or null
    this.region = props.region;

    this.phone = props.phone;

    // 營業時間
    this.businessHours = props.businessHours;

    // 非現場, 案件類型
    this.caseType = props.caseType;

    // 非現場, 案件說明
    this.directions = props.directions;

    // 上傳檔案位置
    this.uploadPath = props.uploadPath;

    // 訪員資料
    this.visitorId = props.visitorId;
    this.visitorName = props.visitorName;
  }
}

export default CaseListItemDataModel;
