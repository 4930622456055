import { request, getHost } from './utils';

class EmployeeAPIs {
  // 讀取員工列表
  static postEmployeeList({ token, limit, anchor }) {
    const options = {
      method: 'post',

      url: `${getHost()}/v1/employee/list`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      params: {
        limit, anchor
      }
    };

    return request(options);
  }

  // 更新員工
  static putEmployeeUpdate({ token, id, data }) {
    const options = {
      method: 'put',

      url: `${getHost()}/v1/employee/update/${id}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      data
    };

    return request(options);
  }
}

export default EmployeeAPIs;
