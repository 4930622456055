// 訪員相關

// 年齡篩選
export const AGE_TYPES = {
  1: {
    value: 1,
    label: '21~25'
  },

  2: {
    value: 2,
    label: '26~30'
  },

  3: {
    value: 3,
    label: '31~35'
  },

  4: {
    value: 4,
    label: '36~40'
  },

  5: {
    value: 5,
    label: '41~45'
  },

  6: {
    value: 6,
    label: '46~50'
  },

  7: {
    value: 7,
    label: '51~55'
  },

  8: {
    value: 8,
    label: '56~60'
  },

  9: {
    value: 9,
    label: '61~65'
  },

  10: {
    value: 10,
    label: '66~70'
  }
};

// 證照篩選
export const LICENSE_TYPES = {
  tssa: {
    value: 'tssa',
    label: 'TSAA實務神祕客證照'
  },

  sgs: {
    value: 'sgs',
    label: 'SGS神祕客證照'
  },

  consultant: {
    value: 'consultant',
    label: '顧問式神祕客證照'
  },

  double: {
    value: 'double',
    label: '進階雙證（A+B、A+C、B+C）'
  }
};

// 駕照篩選
export const DRIVING_LICENSE_TYPES = {
  car: {
    value: 'car',
    label: '汽車駕照'
  },

  motor: {
    value: 'motor',
    label: '機車駕照'
  },

  double: {
    value: 'double',
    label: '雙照（汽車及機車駕照）'
  },

  none: {
    value: 'none',
    label: '無照'
  }
};

// 電信
export const TELECOM_TYPES = {
  Chunghwa: {
    value: 'Chunghwa',
    label: '中華電信'
  },
  TaiwanMobile: { value: 'TaiwanMobile', label: '台灣大哥大' },
  FarEasTone: { value: 'FarEasTone', label: '遠傳電信' },
  TaiwanStar: { value: 'TaiwanStar', label: '台灣之星(威寶電信)' },
  AsiaPacific: { value: 'AsiaPacific', label: '亞太電信' }
};

// 可跑區域
export const PRACTICE_CITIES = {
  keelungCity: { value: 'keelungCity', label: '基隆市' },
  newTaipeiCity: { value: 'newTaipeiCity', label: '新北市' },
  taipeiCity: { value: 'taipeiCity', label: '臺北市' },
  taoyuanCity: { value: 'taoyuanCity', label: '桃園市' },
  hsinchuCounty: { value: 'hsinchuCounty', label: '新竹縣' },
  hsinchuCity: { value: 'hsinchuCity', label: '新竹市' },
  miaoliCity: { value: 'miaoliCity', label: '苗栗市' },
  miaoliCounty: { value: 'miaoliCounty', label: '苗栗縣' },
  taichungCity: { value: 'taichungCity', label: '臺中市' },
  changhuaCounty: { value: 'changhuaCounty', label: '彰化縣' },
  changhuaCity: { value: 'changhuaCity', label: '彰化市' },
  nantouCity: { value: 'nantouCity', label: '南投市' },
  nantouCounty: { value: 'nantouCounty', label: '南投縣' },
  yunlinCounty: { value: 'yunlinCounty', label: '雲林縣' },
  chiayiCounty: { value: 'chiayiCounty', label: '嘉義縣' },
  chiayiCity: { value: 'chiayiCity', label: '嘉義市' },
  tainanCity: { value: 'tainanCity', label: '臺南市' },
  kaohsiungCity: { value: 'kaohsiungCity', label: '高雄市' },
  pingtungCounty: { value: 'pingtungCounty', label: '屏東縣' },
  pingtungCity: { value: 'pingtungCity', label: '屏東市' },
  yilanCounty: { value: 'yilanCounty', label: '宜蘭縣' },
  yilanCity: { value: 'yilanCity', label: '宜蘭市' },
  hualienCounty: { value: 'hualienCounty', label: '花蓮縣' },
  hualienCity: { value: 'hualienCity', label: '花蓮市' },
  taitungCity: { value: 'taitungCity', label: '臺東市' },
  taitungCounty: { value: 'taitungCounty', label: '臺東縣' },
  penghuCounty: { value: 'penghuCounty', label: '澎湖縣' },
  greenIsland: { value: 'greenIsland', label: '綠島' },
  orchidIsland: { value: 'orchidIsland', label: '蘭嶼' },
  kinmenCounty: { value: 'kinmenCounty', label: '金門縣' },
  matsuMatus: { value: 'matsuMatus', label: '馬祖' },
  lienchiangCounty: { value: 'lienchiangCounty', label: '連江縣' }
};
