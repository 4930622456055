import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import ICONS from 'src/global/icons';
import bossImageSrc from 'src/assets/boss.png';
import CustomModal from '../CustomModal';
import styles from './styles.module.scss';

function DeleteModal(props) {
  return (
    <CustomModal vm={props.vm} width={240} destroyOnClose={false}>
      <div className={styles.container}>
        <div className={styles.content}>
          <ICONS.Warning className={styles.icon} />
          確定要刪除嗎？
        </div>

        <div className={styles.imageContainer}>
          <img src={bossImageSrc} alt="boss" className={styles.image} />
        </div>

        <div className={styles.buttons}>
          <Button
            size="small"
            className={styles.button}
            onClick={props.vm.hide}
          >
            NO
          </Button>

          <Button type="primary" size="small" onClick={props.onConfirm}>
            YES
          </Button>
        </div>
      </div>
    </CustomModal>
  );
}

DeleteModal.propTypes = {
  vm: PropTypes.object.isRequired,
  onConfirm: PropTypes.func
};

DeleteModal.defaultProps = {
  onConfirm: () => {}
};

export default DeleteModal;
