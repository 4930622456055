import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Input, Button } from 'antd';
import CustomModal from 'src/components/CustomModal';
import styles from './styles.module.scss';
import './reset.scss';

@observer
class TextAreaModal extends React.Component {
  render() {
    return (
      <div id="textAreaModal">
        <CustomModal vm={this.props.vm} hasClose>
          <div className={styles.container}>
            <div className={styles.label}>{this.props.label}</div>

            {!!this.props.subLabel && (
              <div className={styles.label}>{this.props.subLabel}</div>
            )}

            {this.props.disabled && (
              <div className={styles.content}>{this.props.value}</div>
            )}

            {!this.props.disabled && (
              <div className={styles.formContainer}>
                <div className={styles.inputContainer}>
                  <Input.TextArea
                    value={this.props.value}
                    onChange={this.props.onChange}
                    rows={this.props.rows}
                    maxLength={this.props.maxLength}
                    showCount
                    disabled={this.props.isAwait}
                  />
                </div>

                <div className={styles.buttonsContainer}>
                  <Button
                    className={styles.cancelButton}
                    onClick={this.props.vm.hide}
                  >
                    取消
                  </Button>

                  <Button
                    type="primary"
                    onClick={this.props.onConfirm}
                    disabled={this.props.isAwait}
                  >
                    {this.props.confirmText}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </CustomModal>
      </div>
    );
  }
}

TextAreaModal.propTypes = {
  vm: PropTypes.object.isRequired,
  label: PropTypes.string,
  subLabel: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onConfirm: PropTypes.func,
  confirmText: PropTypes.string,
  rows: PropTypes.number,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  isAwait: PropTypes.bool
};

TextAreaModal.defaultProps = {
  label: '標題',
  subLabel: '',
  value: '',
  onChange: () => {},
  onConfirm: () => {},
  confirmText: '確認',
  rows: 5,
  maxLength: 500,
  disabled: false,
  isAwait: false
};

export default TextAreaModal;
