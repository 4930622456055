import ProjectAPIs from 'src/apis/ProjectAPIs';
import { LIMIT } from 'src/global/constants';
import ProjectListItemDataModel from 'src/models/response/ProjectListItemDataModel';
import ProjectDetailDataModel from 'src/models/response/ProjectDetailDataModel';
import CookieServices from './CookieServices';

class ProjectServices {
  // 讀取專案列表
  static async postProjectList({
    limit = LIMIT,
    anchor,
    steps,
    handler,
    keyword
  }) {
    const res = await ProjectAPIs.postProjectList({
      token: CookieServices.getToken(),
      limit,
      anchor,
      steps,
      handler,
      keyword
    });

    return {
      data: res.data.list.map((item) => new ProjectListItemDataModel(item)),
      total: res.data.total,
      anchor: res.data.anchor
    };
  }

  // 新增專案
  static async putProjectCreate({ projectName, companyId }) {
    const res = await ProjectAPIs.putProjectCreate({
      token: CookieServices.getToken(),
      projectName,
      companyId
    });
    const projectId = String(res.data?.projectId || 1);

    return {
      data: {
        projectId
      }
    };
  }

  // 讀取專案基本 detail
  static async getProjectDetail({ projectId }) {
    const res = await ProjectAPIs.getProjectDetail({
      token: CookieServices.getToken(),
      projectId
    });

    return {
      data: new ProjectDetailDataModel(res.data)
    };
  }

  // 更新專案名稱
  static async postProjectUpdateName({ projectId, projectName }) {
    const res = await ProjectAPIs.postProjectUpdateName({
      token: CookieServices.getToken(),
      projectId,
      name: projectName
    });

    return {
      data: res.data
    };
  }

  // 讀取總規格表
  static async getProjectSpecification({ projectId }) {
    const res = await ProjectAPIs.getProjectSpecification({
      token: CookieServices.getToken(),
      projectId
    });

    return {
      caseAmount: {
        onSite: res.data.caseAmount.onSite,
        offSite: res.data.caseAmount.offSite,
        other: res.data.caseAmount.other
      },
      data: res.data.content
    };
  }

  // 更新總規格表
  static async postProjectSpecification({
    projectId,
    startTime,
    stopTime,
    content,
    caseAmount
  }) {
    const res = await ProjectAPIs.postProjectSpecification({
      token: CookieServices.getToken(),
      projectId,
      startTime,
      stopTime,
      content,
      caseAmount
    });

    return {
      data: res.data
    };
  }

  // 更新專案簡述
  static async postProjectUpdateAbout({ projectId, about }) {
    const res = await ProjectAPIs.postProjectUpdateAbout({
      token: CookieServices.getToken(),
      projectId,
      about
    });

    return {
      data: res.data
    };
  }

  // 更新專案備註
  static async postProjectUpdateComment({ projectId, content }) {
    const res = await ProjectAPIs.postProjectUpdateComment({
      token: CookieServices.getToken(),
      projectId,
      content
    });

    return {
      data: res.data
    };
  }

  // 更新執案指導資訊
  static async postProjectUpdateGuidance({ projectId, guidance }) {
    const res = await ProjectAPIs.postProjectUpdateGuidance({
      token: CookieServices.getToken(),
      projectId,
      guidance
    });

    return {
      data: res.data
    };
  }

  // 更新專案溝通與說明紀錄
  static async postProjectUpdateMessage({ projectId, content }) {
    const res = await ProjectAPIs.postProjectUpdateMessage({
      token: CookieServices.getToken(),
      projectId,
      content
    });

    return {
      data: res.data
    };
  }

  // 專案推進或是退回
  static async postProjectAction({ projectId, nextStep, pmId, message }) {
    const res = await ProjectAPIs.postProjectAction({
      token: CookieServices.getToken(),
      projectId,
      nextStep,
      pmId,
      message
    });

    return {
      data: res.data
    };
  }

  // 更新專案訪客問卷
  static async postProjectUpdateQuest({ projectId, questPath }) {
    const res = await ProjectAPIs.postProjectUpdateQuest({
      token: CookieServices.getToken(),
      projectId,
      questPath
    });

    return {
      data: res.data
    };
  }
}

export default ProjectServices;
