import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Form, Input, Select, DatePicker } from 'antd';
import withForm from 'src/components/withForm';
import CustomModal from 'src/components/CustomModal';
import SimpleForm from 'src/components/SimpleForm';
import styles from './styles.module.scss';
import './reset.scss';

@observer
class StaffEditModal extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({
      form: props.form
    });
  }

  render() {
    return (
      <div id="staffEditModal">
        <CustomModal
          vm={this.vm.modalVM}
          hasClose
          hasHeader
          title={this.vm.label.title}
          hasPadding
          hasFooter
          hasCancel
          confirmType="primary"
          onConfirm={this.vm.onConfirm}
          disabledConfirm={this.vm.disabledConfirm}
          onCancel={this.vm.onCancel}
        >
          <div className={styles.container}>
            <SimpleForm
              form={this.vm.form}
              labelCol={7}
              disabled={this.vm.isAwait}
            >
              <Form.Item
                label="角色"
                name="type"
                rules={this.vm.rules.required}
              >
                <Select options={this.vm.groups} placeholder="請選擇角色" />
              </Form.Item>

              <Form.Item
                label="姓名"
                name="name"
                rules={this.vm.rules.required}
              >
                <Input placeholder="請輸入" maxLength={50} />
              </Form.Item>

              <Form.Item
                label="身分證字號"
                name="idNumber"
                rules={this.vm.rules.idNumber}
              >
                <Input
                  placeholder="請輸入"
                  disabled={this.vm.disabled.idNumber}
                  maxLength={50}
                />
              </Form.Item>

              <Form.Item
                label="生日"
                name="birthday"
                rules={this.vm.rules.required}
              >
                <DatePicker
                  className={styles.expanded}
                  placeholder="選擇日期"
                  disabled={this.vm.disabled.birthday}
                />
              </Form.Item>

              <Form.Item
                label="到職日期"
                name="employmentDate"
                rules={this.vm.rules.required}
              >
                <DatePicker
                  className={styles.expanded}
                  placeholder="選擇日期"
                />
              </Form.Item>

              <Form.Item label="離職日期" name="resignationDate">
                <DatePicker
                  className={styles.expanded}
                  placeholder="選擇日期"
                />
              </Form.Item>

              <Form.Item label="加保日期" name="insuranceDate">
                <DatePicker
                  className={styles.expanded}
                  placeholder="選擇日期"
                />
              </Form.Item>

              <Form.Item label="退保日期" name="disInsuranceDate">
                <DatePicker
                  className={styles.expanded}
                  placeholder="選擇日期"
                />
              </Form.Item>

              <Form.Item label="通訊電話" name="phone">
                <Input placeholder="請輸入" maxLength={50} />
              </Form.Item>

              <Form.Item label="通訊地址" name="address">
                <Input placeholder="請輸入" maxLength={50} />
              </Form.Item>

              <div className={styles.emergencyContact}>緊急聯絡人</div>

              <Form.Item label="聯絡人姓名" name="contactName">
                <Input placeholder="請輸入" maxLength={50} />
              </Form.Item>

              <Form.Item label="關係" name="contactRelationship">
                <Input placeholder="請輸入" maxLength={50} />
              </Form.Item>

              <Form.Item label="電話" name="contactPhone">
                <Input placeholder="請輸入" maxLength={50} />
              </Form.Item>

              <Form.Item label="教育背景" name="education">
                <Input placeholder="請輸入" maxLength={50} />
              </Form.Item>

              <Form.Item label="證照" name="license">
                <Input placeholder="請輸入" maxLength={50} />
              </Form.Item>

              <Form.Item label="備註" name="comment">
                <Input placeholder="請輸入" maxLength={50} />
              </Form.Item>
            </SimpleForm>
          </div>
        </CustomModal>
      </div>
    );
  }
}

StaffEditModal.propTypes = {
  form: PropTypes.object.isRequired,
  vm: PropTypes.object.isRequired
};

StaffEditModal.defaultProps = {};

export default withForm(StaffEditModal);
