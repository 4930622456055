import { v4 } from 'uuid';

// 日期區間
class StatisticsTimeListItemDataModel {
  constructor(props) {
    this.id = v4();

    this.startTime = props.startTime;

    this.stopTime = props.stopTime;
  }
}

export default StatisticsTimeListItemDataModel;
