import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Input } from 'antd';
import SelfRow from 'src/components/CustomForm/components/SelfRow';
import FormatCell from 'src/components/CustomForm/components/FormatCell';
import SelfFormItem from 'src/components/CustomForm/components/SelfFormItem';
import BorderRightBottom from 'src/components/CustomForm/components/BorderRightBottom';

function Areas(props) {
  return (
    <BorderRightBottom>
      <SelfRow>
        <FormatCell label="北一">
          <SelfFormItem
            name={[props.nameKey, 'north1']}
          >
            <Input size="small" maxLength={50} />
          </SelfFormItem>
        </FormatCell>

        <FormatCell label="北二">
          <SelfFormItem
            name={[props.nameKey, 'north2']}

          >
            <Input size="small" maxLength={50} />
          </SelfFormItem>
        </FormatCell>

        <FormatCell label="中">
          <SelfFormItem
            name={[props.nameKey, 'center1']}
            SelfFormItem
          >
            <Input size="small" maxLength={50} />
          </SelfFormItem>
        </FormatCell>
      </SelfRow>

      <SelfRow>
        <FormatCell label="南一">
          <SelfFormItem
            name={[props.nameKey, 'south1']}
            SelfFormItem
          >
            <Input size="small" maxLength={50} />
          </SelfFormItem>
        </FormatCell>

        <FormatCell label="南二">
          <SelfFormItem
            name={[props.nameKey, 'south2']}
            SelfFormItem
          >
            <Input size="small" maxLength={50} />
          </SelfFormItem>
        </FormatCell>

        <FormatCell label="東">
          <SelfFormItem
            name={[props.nameKey, 'east1']}
            SelfFormItem
          >
            <Input size="small" maxLength={50} />
          </SelfFormItem>
        </FormatCell>
      </SelfRow>

      <SelfRow>
        <FormatCell label="離/外島">
          <SelfFormItem
            name={[props.nameKey, 'island1']}
            SelfFormItem
          >
            <Input size="small" maxLength={50} />
          </SelfFormItem>
        </FormatCell>
      </SelfRow>
    </BorderRightBottom>
  );
}

Areas.propTypes = {
  nameKey: PropTypes.string.isRequired
};

Areas.defaultProps = {};

export default Areas;
