import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { InputNumber } from 'antd';
import ICONS from 'src/global/icons';
import clsx from 'clsx';
import styles from './styles.module.scss';
import './reset.scss';

function SimpleInputNumber(props) {
  return (
    <div className="simpleInputNumber">
      <InputNumber type="number" {...props} />
    </div>
  );
}

SimpleInputNumber.propTypes = {
  size: PropTypes.string
};

SimpleInputNumber.defaultProps = {
  size: 'small'
};

export default observer(SimpleInputNumber);
