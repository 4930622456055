import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction
} from 'mobx';
import {
  USER_TYPES,
  ROUTES,
  ROUTE_PARAMS,
  INTERVIEWER_TABS
} from 'src/global/constants';
import VisitorServices from 'src/services/VisitorServices';
import ErrorServices from 'src/services/ErrorServices';
import InterviewerDetail from './component/InterviewerDetail';
import InterviewerMission from './component/interviewerMission';

class InterviewerDetailPageViewModel {
  @observable data = null;
  @observable profile = null;
  router = null;
  // 訪員 id
  visitorId = null;

  @computed
  get hasData() {
    return !!this.data;
  }

  @computed
  get hasEdit() {
    switch (this.profile.type) {
      case USER_TYPES.boss.value:
      case USER_TYPES.operationsManagement.value:
      case USER_TYPES.projectManager.value:
        return true;

      default:
        return false;
    }
  }

  @computed
  get routes() {
    return [
      {
        label: ROUTES.interviewer.label,
        link: `/${ROUTES.interviewer.value}`
      },
      {
        label: this.data.name
      }
    ];
  }

  @computed
  get backLink() {
    return `/${ROUTES.interviewer.value}`;
  }

  @computed
  get pages() {
    return [
      {
        key: INTERVIEWER_TABS.interviewerDetail.value,
        label: INTERVIEWER_TABS.interviewerDetail.label,
        children: (
          <InterviewerDetail
            data={this.data}
            hasEdit={this.hasEdit}
            updated={this.updated}
          />
        )
      },
      {
        key: INTERVIEWER_TABS.interviewerMission.value,
        label: INTERVIEWER_TABS.interviewerMission.label,
        children: <InterviewerMission data={this.data} hasEdit={this.hasEdit} />
      }
    ];
  }

  constructor(props) {
    this.profile = props.profile;
    this.router = props.router;
    makeObservable(this);
  }

  @action
  didMount = (props) => {
    this.visitorId = props.router.params[ROUTE_PARAMS.interviewerId];
    this.getAccountProfileAPI();
  };

  @action
  updated = (data) => {
    this.data = data;
  };

  // call api
  getAccountProfileAPI = async () => {
    try {
      const res = await VisitorServices.getAccountProfile({
        id: this.visitorId
      });

      runInAction(() => {
        this.data = res.data;
      });
    } catch (error) {
      const message = ErrorServices.getAccountProfile(error);

      console.log(
        'InterviewerDetailPageViewModel',
        'getAccountProfile',
        message
      );
    }
  };
}

export default InterviewerDetailPageViewModel;
