// 損益表, 申請修改紀錄

class EventListDataModel {
  constructor(props) {
    this.eventId = props.eventId;

    this.content = props.content;

    this.applyerName = props.applyerName;

    this.applyTime = props.applyTime;

    this.status = props.status;
  }
}

export default EventListDataModel;
