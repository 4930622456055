import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

function FormList(props) {
  return (
    <div id="formList" className={styles.container}>
      <div className={styles.title}>{props.title}</div>

      {props.children}
    </div>
  );
}

FormList.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node
};

FormList.defaultProps = {
  title: '表單列表',
  children: null
};

export default FormList;
