import { request, getHost } from './utils';

// 案件
class CaseAPIs {
  // 讀取案件列表
  static getCase({ token, projectId }) {
    const options = {
      method: 'get',

      url: `${getHost()}/v1/case/${projectId}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      }
    };

    return request(options);
  }

  // 新增案件
  static postCaseCreate({ token, projectId, site, info }) {
    const options = {
      method: 'post',

      url: `${getHost()}/v1/case/create`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      data: {
        projectId,
        site,
        info
      }
    };

    return request(options);
  }

  // 刪除案件
  static deleteCase({ token, ids }) {
    const options = {
      method: 'delete',

      url: `${getHost()}/v1/case`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      data: {
        ids
      }
    };

    return request(options);
  }

  // 批次新增案件
  static postCaseCreateBatch({ token, projectId, site, info }) {
    const options = {
      method: 'post',

      url: `${getHost()}/v1/case/create/batch`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      data: {
        projectId, site, info
      }
    };

    return request(options);
  }

  // 更新案件
  static putCase({ token, id, data }) {
    const options = {
      method: 'put',

      url: `${getHost()}/v1/case/${id}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      data
    };

    return request(options);
  }

  // 案件推進(選擇派派遣訪員)
  static postCaseAction({ token, caseId, nextStep, visitorId, message }) {
    const options = {
      method: 'post',

      url: `${getHost()}/v1/case/action/${caseId}/${nextStep}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      params: {
        visitorId
      },

      data: {
        message
      }
    };

    return request(options);
  }

  // 讀取案件詳情
  static getCaseDetail({ token, caseId }) {
    const options = {
      method: 'get',

      url: `${getHost()}/v1/case/${caseId}/detail`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      }
    };

    return request(options);
  }

  // pm 備註
  static putCaseContent({ token, caseId, content }) {
    const options = {
      method: 'put',

      url: `${getHost()}/v1/case/${caseId}/contant`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      data: {
        content
      }
    };

    return request(options);
  }
}

export default CaseAPIs;
