import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { Column } from '@ant-design/plots';
import styles from './styles.module.scss';
import './reset.scss';

@observer
class SelfBarChart extends React.Component {
  @computed
  get check() {
    return {
      isShowBasicLine: !!this.props.basicFraction
    };
  }

  @computed
  get label() {
    const date = `日期：${this.props.startAt} ~ ${this.props.endAt}`;

    return {
      date
    };
  }

  @computed
  get annotations() {
    return this.check.isShowBasicLine
      ? [
        {
          type: 'text',
          position: ['start', this.props.basicFraction],
          content: this.props.basicFraction,
          offsetY: -10,
          style: {
            fill: '#CF1322'
          }
        },
        {
          type: 'line',
          start: ['start', this.props.basicFraction],
          end: ['end', this.props.basicFraction],
          style: {
            stroke: '#CF1322'
          }
        }
      ]
      : undefined;
  }

  getColor = (item) => {
    const find = this.props.data.find(({ label }) => label === item.label);

    return find.color;
  };

  render() {
    return (
      <div id="selfBarChart" className={styles.container}>
        <div className={styles.chartContainer}>
          <Column
            data={this.props.data}
            xField="label"
            yField="value"
            xAxis={{
              label: {
                style: {
                  fill: '#000000'
                }
              }
            }}
            yAxis={{
              label: {
                style: {
                  fill: '#000000'
                }
              },
              minLimit: 0,
              maxLimit: this.props.fullMarks,
              tickCount: this.props.tickCount
            }}
            columnWidthRatio="0.3"
            color={this.getColor}
            tooltip={{
              title: 'label',
              fields: ['value']
            }}
            annotations={this.annotations}
          />
        </div>

        <div className={styles.date}>{this.label.date}</div>
      </div>
    );
  }
}

SelfBarChart.propTypes = {
  data: PropTypes.array.isRequired,
  basicFraction: PropTypes.string,
  startAt: PropTypes.string.isRequired,
  endAt: PropTypes.string.isRequired,
  fullMarks: PropTypes.number.isRequired,
  tickCount: PropTypes.number.isRequired
};

SelfBarChart.defaultProps = {
  basicFraction: null
};

export default SelfBarChart;
