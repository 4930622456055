import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import CustomTable from 'src/components/CustomTable';
import DescTableViewModel from './viewModel';
import styles from './styles.module.scss';
import './reset.scss';

@observer
class DescTable extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new DescTableViewModel({
      case: props.case
    });
  }

  componentDidUpdate(preProps) {
    this.vm.didUpdate(this.props, preProps);
  }

  renderOnSiteTable = () => {
    return (
      <>
        <Table.Column width={150} title="門市名稱" dataIndex="name" />

        <Table.Column width={200} title="門市地址" dataIndex="address" />

        <Table.Column width={140} title="所屬區域" dataIndex="region" />

        <Table.Column width={130} title="電話" dataIndex="phone" />

        <Table.Column width={120} title="營業時間" dataIndex="businessHours" />
      </>
    );
  };

  renderOffSiteTable = () => {
    return (
      <>
        <Table.Column width={240} title="案件名稱" dataIndex="name" />

        <Table.Column width={130} title="案件類型" dataIndex="caseType" />

        <Table.Column
          width={200}
          title="案件說明"
          render={(item) => {
            return <div className={styles.omit}>{item.directions}</div>;
          }}
        />
      </>
    );
  };

  render() {
    return (
      <div id="caseDetailPageDescTable" className={styles.container}>
        <CustomTable data={this.vm.tableData} hasScrollX>
          <Table.Column width={120} title="狀態" dataIndex="step" />

          {this.vm.check.onSite && this.renderOnSiteTable()}

          {this.vm.check.offSite && this.renderOffSiteTable()}

          <Table.Column width={140} title="執案開始日" dataIndex="startDate" />

          <Table.Column width={200} title="案件截止日" dataIndex="expiryDate" />

          <Table.Column width={140} title="指派訪員" dataIndex="visitorName" />
        </CustomTable>
      </div>
    );
  }
}

DescTable.propTypes = {
  case: PropTypes.object.isRequired
};

DescTable.defaultProps = {};

export default DescTable;
