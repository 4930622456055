import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { makeObservable, observable, action, toJS } from 'mobx';
import { Table } from 'antd';
import clsx from 'clsx';
import styles from './styles.module.scss';
import './reset.scss';

@observer
class FixedTable extends React.Component {
  @observable height = 0;
  containerRef = React.createRef();

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.resize();
  }

  @action resize = () => {
    const clientHeight = this.containerRef.current.clientHeight;
    const labelHeight = this.props.labelHeight;

    if (this.props.scrollY) {
      this.height = clientHeight - labelHeight;
    }
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  render() {
    const {
      scrollX,
      scrollY,
      labelHeight,
      flex,
      hidden,
      padding,
      children,
      ...ps
    } = this.props;

    return (
      <div
        className={clsx([
          'fixedTable',
          styles.container,
          flex && styles.flex,
          hidden && styles.hidden
        ])}
        ref={this.containerRef}
      >
        <Table
          className={clsx([padding && 'padding'])}
          rowKey={(item) => item.id}
          pagination={false}
          scroll={{
            x: scrollX,
            y: this.height
          }}
          {...ps}
        >
          {children}
        </Table>
      </div>
    );
  }
}

FixedTable.propTypes = {
  scrollX: PropTypes.any,
  scrollY: PropTypes.bool,
  labelHeight: PropTypes.number,
  flex: PropTypes.bool,
  hidden: PropTypes.bool,
  padding: PropTypes.bool,
  children: PropTypes.node
};

FixedTable.defaultProps = {
  scrollX: undefined,
  scrollY: false,
  labelHeight: 0,
  flex: false,
  hidden: false,
  padding: false,
  children: null
};

export default FixedTable;
