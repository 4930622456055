import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import withRouter from 'src/components/withRouter';
import StyleItem from 'src/components/StyleItem';
import TagItem from '../TagItem';
import CheckItemViewModel from './viewModel';

// 專案查檢表
@observer
class CheckItem extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new CheckItemViewModel({
      data: props.data,
      checkParams: props.checkParams,
      router: props.router
    });
  }

  componentDidUpdate(preProps) {
    this.vm.didUpdate(this.props, preProps);
  }

  render() {
    return this.vm.isShow ? (
      <TagItem
        label={this.vm.itemLabel}
        isFinish={this.vm.isFinish}
        finishLabel="發布"
        unFinishLabel="未發布"
        buttonText="前往查檢表管理"
        onClick={this.vm.onToCheck}
      />
    ) : null;
  }
}

CheckItem.propTypes = {
  data: PropTypes.object.isRequired,
  checkParams: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired
};

CheckItem.defaultProps = {
};

export default withRouter(CheckItem);
