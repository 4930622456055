import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import withRouter from 'src/components/withRouter';
import WarningModal from 'src/components/WarningModal';
import PushButtonViewModel from './viewModel';
import styles from './styles.module.scss';

// 專案狀態推進按鈕
@observer
class PushButton extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new PushButtonViewModel({
      data: props.data,
      checkParams: props.checkParams,
      router: props.router,
      onChanged: props.onChanged
    });
  }

  componentDidUpdate(preProps) {
    this.vm.didUpdate(this.props, preProps);
  }

  render() {
    return this.vm.isShow ? (
      <div className={styles.container}>
        <Button
          size="large"
          type="primary"
          className={this.vm.buttonStyle}
          onClick={this.vm.modalVM.show}
          disabled={this.vm.isAwait}
        >
          {this.vm.label}
        </Button>

        <WarningModal
          vm={this.vm.modalVM}
          content="送出後無法收回, 確定要送出嗎?"
          onConfirm={this.vm.onConfirm}
        />
      </div>
    ) : null;
  }
}

PushButton.propTypes = {
  data: PropTypes.object.isRequired,
  checkParams: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  onChanged: PropTypes.func
};

PushButton.defaultProps = {
  onChanged: () => {}
};

export default withRouter(PushButton);
