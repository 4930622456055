import React from 'react';
import PropTypes from 'prop-types';
import { observer, Observer } from 'mobx-react';
import { Button, Table } from 'antd';
import clsx from 'clsx';
import ICONS from 'src/global/icons';
import withProfile from 'src/components/withProfile';
import CustomHeader from 'src/components/CustomHeader';
import FixedTable from 'src/components/FixedTable';
import CustomDropdown from 'src/components/CustomDropdown';
import AddButton from 'src/components/AddButton';
import { Waypoint } from 'react-waypoint';
import StaffEditModal from './components/StaffEditModal';
import StaffPageViewModel from './viewModel';
import styles from './styles.module.scss';

// 老闆, 營業部, 可以編輯
@observer
class StaffPage extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new StaffPageViewModel({
      profile: props.profile
    });
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return (
      <div className={styles.container}>
        <CustomHeader title="員工管理" paddingBottom />

        {!this.vm.isInit && (
          <>
            <div className={styles.createButtonContainer}>
              <AddButton label="新增員工" onClick={this.vm.onCreate} />
            </div>

            <FixedTable
              dataSource={this.vm.data}
              scrollX="max-content"
              scrollY
              labelHeight={109}
              flex
              hidden
              padding
            >
              <Table.Column
                width={80}
                title="操作"
                align="center"
                render={(vm) => {
                  return !vm.check.isBoss ? (
                    <Observer>
                      {() => {
                        return (
                          <div className={clsx([vm.isDisabled && 'selected'])}>
                            <CustomDropdown
                              items={vm.dropdownItems}
                              onClick={vm.onClickDropdownItem}
                              position="bottom"
                            >
                              <Button type="text">
                                <ICONS.Edit className={styles.editIcon} />
                              </Button>
                            </CustomDropdown>

                            {/* 編輯員工 */}
                            <StaffEditModal vm={vm.editModalVM} />
                          </div>
                        );
                      }}
                    </Observer>
                  ) : null;
                }}
              />

              <Table.Column
                width={80}
                title="狀態"
                render={(vm) => {
                  return (
                    <Observer>
                      {() => {
                        return (
                          <div
                            className={clsx([
                              styles.breakAll,
                              vm.isDisabled && 'selected'
                            ])}
                          >
                            {vm.label.disabled}
                          </div>
                        );
                      }}
                    </Observer>
                  );
                }}
              />

              <Table.Column
                width={80}
                title="角色"
                render={(vm) => {
                  return (
                    <Observer>
                      {() => {
                        return (
                          <div
                            className={clsx([
                              styles.breakAll,
                              vm.isDisabled && 'selected'
                            ])}
                          >
                            {vm.label.type}
                          </div>
                        );
                      }}
                    </Observer>
                  );
                }}
              />

              <Table.Column
                title="姓名"
                render={(vm) => {
                  return (
                    <Observer>
                      {() => {
                        return (
                          <div
                            className={clsx([
                              styles.breakAll,
                              vm.isDisabled && 'selected'
                            ])}
                          >
                            {vm.data.name}
                          </div>
                        );
                      }}
                    </Observer>
                  );
                }}
              />

              <Table.Column
                width={120}
                title="身分證字號"
                render={(vm) => {
                  return (
                    <Observer>
                      {() => {
                        return (
                          <div
                            className={clsx([
                              styles.breakAll,
                              vm.isDisabled && 'selected'
                            ])}
                          >
                            {vm.label.idNumber}
                          </div>
                        );
                      }}
                    </Observer>
                  );
                }}
              />

              <Table.Column
                width={120}
                title="生日"
                render={(vm) => {
                  return (
                    <Observer>
                      {() => {
                        return (
                          <div
                            className={clsx([
                              styles.breakAll,
                              vm.isDisabled && 'selected'
                            ])}
                          >
                            {vm.label.birthday}
                          </div>
                        );
                      }}
                    </Observer>
                  );
                }}
              />

              <Table.Column
                width={120}
                title="到職日期"
                render={(vm) => {
                  return (
                    <Observer>
                      {() => {
                        return (
                          <div
                            className={clsx([
                              styles.breakAll,
                              vm.isDisabled && 'selected'
                            ])}
                          >
                            {vm.label.employmentDate}
                          </div>
                        );
                      }}
                    </Observer>
                  );
                }}
              />

              <Table.Column
                width={120}
                title="離職日期"
                render={(vm) => {
                  return (
                    <Observer>
                      {() => {
                        return (
                          <div
                            className={clsx([
                              styles.breakAll,
                              vm.isDisabled && 'selected'
                            ])}
                          >
                            {vm.label.resignationDate}
                          </div>
                        );
                      }}
                    </Observer>
                  );
                }}
              />

              <Table.Column
                title="通訊電話"
                render={(vm) => {
                  return (
                    <Observer>
                      {() => {
                        return (
                          <div
                            className={clsx([
                              styles.breakAll,
                              vm.isDisabled && 'selected'
                            ])}
                          >
                            {vm.data.phone}
                          </div>
                        );
                      }}
                    </Observer>
                  );
                }}
              />

              <Table.Column
                title="通訊地址"
                render={(vm) => {
                  return (
                    <Observer>
                      {() => {
                        return (
                          <div
                            className={clsx([
                              styles.maxWidth,
                              styles.breakAll,
                              vm.isDisabled && 'selected'
                            ])}
                          >
                            {vm.data.address}
                          </div>
                        );
                      }}
                    </Observer>
                  );
                }}
              />

              <Table.Column
                title="教育背景"
                render={(vm) => {
                  return (
                    <Observer>
                      {() => {
                        return (
                          <div
                            className={clsx([
                              styles.breakAll,
                              vm.isDisabled && 'selected'
                            ])}
                          >
                            {vm.data.education}
                          </div>
                        );
                      }}
                    </Observer>
                  );
                }}
              />

              <Table.Column
                title="證照"
                render={(vm) => {
                  return (
                    <Observer>
                      {() => {
                        return (
                          <div
                            className={clsx([
                              styles.breakAll,
                              vm.isDisabled && 'selected'
                            ])}
                          >
                            {vm.data.license}
                          </div>
                        );
                      }}
                    </Observer>
                  );
                }}
              />

              <Table.Column
                title="備註"
                render={(vm, _, i) => {
                  const isLast = i === this.vm.data.length - 1;

                  return (
                    <Observer>
                      {() => {
                        return (
                          <>
                            <div
                              className={clsx([
                                styles.maxWidth,
                                styles.breakAll,
                                vm.isDisabled && 'selected'
                              ])}
                            >
                              {vm.data.comment}
                            </div>

                            {isLast && (
                              <Waypoint onEnter={this.vm.onScrollEnd} />
                            )}
                          </>
                        );
                      }}
                    </Observer>
                  );
                }}
              />
            </FixedTable>
          </>
        )}

        {/* 員工彈窗 */}
        <StaffEditModal vm={this.vm.modalVM} />
      </div>
    );
  }
}

StaffPage.propTypes = {
  profile: PropTypes.object.isRequired
};

StaffPage.defaultProps = {};

export default withProfile(StaffPage, true);
