import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Line } from '@ant-design/plots';
import styles from './styles.module.scss';

function SelfLineChart(props) {
  return (
    <div className={styles.container} style={{ width: props.lineChartWidth }}>
      <Line
        data={props.data}
        xField="date"
        yField="value"
        yAxis={{
          label: {
            style: {
              fill: '#000000'
            }
          },
          minLimit: 0,
          maxLimit: props.fullMarks,
          tickCount: props.tickCount
        }}
        seriesField="label"
        legend={{ itemHeight: 24 }}
      />
    </div>
  );
}

SelfLineChart.propTypes = {
  data: PropTypes.array,
  lineChartWidth: PropTypes.number,
  fullMarks: PropTypes.number.isRequired,
  tickCount: PropTypes.number.isRequired
};

SelfLineChart.defaultProps = {
  data: [],
  lineChartWidth: undefined
};

export default observer(SelfLineChart);
