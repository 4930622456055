import React from 'react';
import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction
} from 'mobx';
import { RE_ID_NUMBER } from 'src/global/constants';
import CustomModalViewModel from 'src/components/CustomModal/viewModel';
import VisitorServices from 'src/services/VisitorServices';
import ErrorServices from 'src/services/ErrorServices';

class CreateModalViewModel {
  @observable isAwait = false;
  formRef = React.createRef();

  // 確認新增
  onCreated = null;

  modalVM = new CustomModalViewModel();

  // 新增檢查
  @computed
  get disabledConfirm() {
    return false;
  }

  @computed
  get rules() {
    const requiredLimit = {
      required: true,
      message: '請輸入'
    };

    return {
      required: [requiredLimit],
      idNumber: [
        requiredLimit,
        { pattern: RE_ID_NUMBER, message: '身分證字號格式錯誤' }
      ]
    };
  }

  constructor(props) {
    makeObservable(this);
  }

  init = ({ onCreated }) => {
    this.onCreated = onCreated;
  };

  open = () => {
    this.modalVM.show();
  };

  close = () => {
    this.modalVM.hide();
  };

  // 確認
  onConfirm = async () => {
    const { validateFields } = this.formRef.current;

    try {
      const data = await validateFields();

      console.log('data', data);
    } catch (error) {
      console.log('CreateModalViewModel', error);
    }

    this.postVisitorCreateBatchAPI();
  };

  // create api
  @action
  postVisitorCreateBatchAPI = async () => {
    const { validateFields } = this.formRef.current;
    this.isAwait = true;

    try {
      const formData = await validateFields();
      const data = {
        ...formData,
        birthday: formData.birthday.format('YYYY-MM-DD')
      };
      const res = await VisitorServices.postVisitorCreateBatch([data]);

      this.onCreated();
      this.close();
    } catch (error) {
      const message = ErrorServices.postVisitorCreateBatch(error);

      console.log('CreateModalViewModel', 'postVisitorCreateBatch', message);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default CreateModalViewModel;
