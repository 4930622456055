// 客戶管理

class CompanyListItemDataModel {
  constructor(props) {
    this.accountId = props.accountId;

    // 企業狀態, 停用 or 啟用
    this.isDisabled = props.isDisabled;

    // 企業編碼
    this.enterpriseNumber = props.enterpriseNumber;

    // 企業名稱
    this.name = props.name;

    // 產業類別?
    this.type = props.type;

    // 登記地址
    this.address = props.address;

    // 代表電話
    this.phone = props.phone;

    // 網址
    this.path = props.path;

    // 企業負責人
    this.principalName = props.principalName;

    // 統一編碼
    this.taxId = props.taxId;

    // 業務
    this.business = props.business;
    // 其他合作業務
    this.otherBusiness = props.otherBusiness;

    // 付款方式
    this.payment = props.payment;

    // 更新時間
    this.updatedAt = props.updatedAt;

    // ui 使用
    this.id = props.accountId;
  }
}

export default CompanyListItemDataModel;
