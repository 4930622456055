import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Input } from 'antd';
import SelfRow from 'src/components/CustomForm/components/SelfRow';
import FormatCell from 'src/components/CustomForm/components/FormatCell';
import SelfFormItem from 'src/components/CustomForm/components/SelfFormItem';
import BorderRightBottom from 'src/components/CustomForm/components/BorderRightBottom';
import SimpleInputNumber from 'src/components/SimpleInputNumber';
import styles from './styles.module.scss';

class CasePayDetail extends React.Component {
  others = Array.from({ length: 20 });

  render() {
    return (
      <BorderRightBottom>
        {this.others.map((_, i) => {
          const key = `其他${i}`;

          return (
            <div className={styles.group} key={key}>
              <div className={styles.label}>
                <SelfFormItem name={['casePayDetail', 'other', key, 'label']}>
                  <Input size="small" maxLength={50} placeholder="請輸入" />
                </SelfFormItem>
              </div>

              <div className={styles.content}>
                <SelfRow>
                  <FormatCell label="無照" labelSize="small">
                    <SelfFormItem
                      name={['casePayDetail', 'other', key, 'noLicense']}
                    >
                      <SimpleInputNumber
                        size="small"
                        controls={false}
                        min={0}
                        maxLength={50}
                      />
                    </SelfFormItem>
                  </FormatCell>

                  <FormatCell label="單照" labelSize="small">
                    <SelfFormItem
                      name={['casePayDetail', 'other', key, 'oneLicense']}
                    >
                      <SimpleInputNumber
                        size="small"
                        controls={false}
                        min={0}
                        maxLength={50}
                      />
                    </SelfFormItem>
                  </FormatCell>

                  <FormatCell label="雙照" labelSize="small">
                    <SelfFormItem
                      name={['casePayDetail', 'other', key, 'towLicense']}
                    >
                      <SimpleInputNumber
                        size="small"
                        controls={false}
                        min={0}
                        maxLength={50}
                      />
                    </SelfFormItem>
                  </FormatCell>
                </SelfRow>
              </div>
            </div>
          );
        })}
      </BorderRightBottom>
    );
  }
}

CasePayDetail.propTypes = {};

CasePayDetail.defaultProps = {};

export default observer(CasePayDetail);
