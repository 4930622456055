import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Checkbox, Input, Radio } from 'antd';
import ICONS from 'src/global/icons';
import clsx from 'clsx';
import SimpleTextArea from 'src/components/SimpleTextArea';
import styles from './styles.module.scss';
import './reset.scss';

@observer
class Topic extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
  }

  // 落實度
  renderImplementation = () => {
    switch (this.vm.implementation) {
      case '0':
        return (
          <Input
            size="small"
            value={this.vm.replyImplementScore}
            onChange={this.vm.onChangeReplyImplementScoreInput}
            disabled={!this.props.edit || this.props.disabled}
          />
        );

      case '1':
        return (
          <Radio.Group
            value={this.vm.replyImplementScore}
            onChange={this.vm.onChangeReplyImplementScoreRadio}
            disabled={!this.props.edit || this.props.disabled}
          >
            <Radio value="0">0</Radio>
            <Radio value="1">1</Radio>
          </Radio.Group>
        );

      default:
        return null;
    }
  };

  // 滿意度
  renderSatisfaction = () => {
    switch (this.vm.satisfaction) {
      case '0':
        return (
          <Input
            size="small"
            value={this.vm.replySatisScore}
            onChange={this.vm.onChangeReplySatisScoreInput}
            disabled={!this.props.edit || this.props.disabled}
          />
        );

      case '1':
        return (
          <Radio.Group
            value={this.vm.replySatisScore}
            onChange={this.vm.onChangeReplySatisScoreRadio}
            disabled={!this.props.edit || this.props.disabled}
          >
            <Radio value="1">1</Radio>
            <Radio value="2">2</Radio>
            <Radio value="3">3</Radio>
            <Radio value="4">4</Radio>
            <Radio value="5">5</Radio>
          </Radio.Group>
        );

      case '2':
        return (
          <Radio.Group
            value={this.vm.replySatisScore}
            onChange={this.vm.onChangeReplySatisScoreRadio}
            disabled={!this.props.edit || this.props.disabled}
          >
            <Radio value="0">0</Radio>
            <Radio value="0.25">0.25</Radio>
            <Radio value="0.5">0.5</Radio>
            <Radio value="0.75">0.75</Radio>
            <Radio value="1">1</Radio>
          </Radio.Group>
        );

      default:
        return null;
    }
  };

  render() {
    return (
      <div className={clsx(['checkItemTopic', styles.container])}>
        <div className={styles.title}>
          <div className={styles.text}>題目型態：分數題</div>
        </div>

        <div className={styles.bonusContainer}>
          <Checkbox
            checked={this.vm.check.isBonus}
            onChange={this.vm.setBonus}
            onBlur={this.vm.onBlur}
            disabled
          />

          <div className={styles.label}>加分題</div>
        </div>

        <div className={styles.textContainer}>
          <div className={styles.label}>題目：</div>
          <div className={styles.content}>{this.vm.topic}</div>
        </div>

        <div className={styles.textContainer}>
          <div className={styles.label}>說明：</div>
          <div className={styles.content}>{this.vm.content}</div>
        </div>

        {/* 落實度分數 */}
        {this.vm.check.hasImplementation && (
          <div className={styles.fractionContainer}>
            <ICONS.Success className={styles.icon} />

            <div className={styles.label}>落實度分數：</div>

            <div className={styles.fromContainer}>
              {this.renderImplementation()}
            </div>
          </div>
        )}

        {/* 滿意度分數 */}
        {this.vm.check.hasSatisfaction && (
          <div className={styles.fractionContainer}>
            <ICONS.Success className={styles.icon} />

            <div className={styles.label}>滿意度分數：</div>

            <div className={styles.formContainer}>
              {this.renderSatisfaction()}
            </div>
          </div>
        )}

        <div className={styles.descContainer}>
          <div className={styles.label}>描述：</div>

          <div className={styles.content}>
            {this.props.edit ? (
              <SimpleTextArea
                autoSize={{ minRows: 3 }}
                value={this.vm.replyContent}
                onChange={this.vm.onChangeReplyContent}
                disabled={this.props.disabled}
                maxLength={500}
                showCount
              />
            ) : (
              this.vm.replyContent
            )}
          </div>
        </div>
      </div>
    );
  }
}

Topic.propTypes = {
  vm: PropTypes.object.isRequired,
  edit: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired
};

Topic.defaultProps = {};

export default Topic;
