import React from 'react';
import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { ROUTES, USER_TYPES } from 'src/global/constants';
import { localStorageMethods } from 'src/global/methods';
import UserServices from 'src/services/UserServices';
import ErrorServices from 'src/services/ErrorServices';

class LoginPageViewModel {
  @observable isAwait = false;
  @observable isServerError = false;
  context = null;
  router = null;
  form = React.createRef();

  @computed
  get required() {
    const user = [
      {
        required: true,
        message: '請輸入帳號'
      }
    ];
    const password = [
      {
        required: true,
        message: '請輸入密碼'
      }
    ];

    return { user, password };
  }

  @computed
  get serverError() {
    return {
      validateStatus: this.isServerError ? 'error' : undefined,
      help: this.isServerError ? '帳號或密碼錯誤' : undefined
    };
  }

  constructor(props) {
    this.context = props.context;
    this.router = props.router;
    makeObservable(this);
  }

  didMount = () => {
    // 讀取帳號
    const account = localStorageMethods.get('account');

    if (account) {
      this.form.current.setFieldsValue({
        account,
        remember: true
      });
    }
  };

  @action
  onChangeInput = () => {
    this.isServerError = false;
  };

  onFinish = () => {
    this.postAccountLoginAPI();
  };

  getNavigateRoute(profile) {
    switch (profile.type) {
      // 老闆, 營管部, 業務, pm
      case USER_TYPES.boss.value:
      case USER_TYPES.operationsManagement.value:
      case USER_TYPES.salesperson.value:
      case USER_TYPES.projectManager.value:
        return `/${ROUTES.announcement.value}`;

      // 企業
      case USER_TYPES.company.value: {
        const route = `/${ROUTES.client.value}/admin`;

        return route;
      }

      default:
        return '';
    }
  }

  @action
  postAccountLoginAPI = async () => {
    this.isAwait = true;

    try {
      const data = this.form.current.getFieldsValue();
      const res = await UserServices.postUserLogin({
        account: data.account,
        password: data.password
      });
      const profile = res.data;
      const route = this.getNavigateRoute(profile);

      // 保存 profile
      this.context.actions.updateProfile(profile);

      // 記住帳號
      if (data.remember) {
        localStorageMethods.set('account', data.account);
      } else {
        // 不要記住我
        localStorageMethods.remove('account');
      }

      // 前往公告頁
      this.router.navigate(route);

      console.log('login', 'route', route);
    } catch (error) {
      const msg = ErrorServices.postAccountLogin(error);

      runInAction(() => {
        this.isServerError = true;

        console.log('postAccountLogin', msg);
      });
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default LoginPageViewModel;
