import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Tag, Button } from 'antd';
import clsx from 'clsx';
import ICONS from 'src/global/icons';
import StyleItem from 'src/components/StyleItem';
import styles from './styles.module.scss';

@observer
class TagItem extends React.Component {
  renderIcon = () => {
    switch (this.props.isFinish) {
      case true:
        return <ICONS.Success className={clsx([styles.icon, styles.green])} />;

      default:
        return <ICONS.Warning className={clsx([styles.icon, styles.red])} />;
    }
  };

  render() {
    return (
      <StyleItem>
        <div className={styles.label}>
          <span className={styles.text}>{this.props.label}</span>

          <Tag className={styles.budgetTag} icon={this.renderIcon()}>
            {this.props.isFinish
              ? this.props.finishLabel
              : this.props.unFinishLabel}
          </Tag>
        </div>

        <div className={styles.buttonContainer}>
          <Button type="primary" onClick={this.props.onClick}>
            {this.props.buttonText}
          </Button>
        </div>
      </StyleItem>
    );
  }
}

TagItem.propTypes = {
  label: PropTypes.string,
  isFinish: PropTypes.bool,
  finishLabel: PropTypes.string,
  unFinishLabel: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func
};

TagItem.defaultProps = {
  label: '標題',
  isFinish: false,
  finishLabel: '完成',
  unFinishLabel: '未完成',
  buttonText: '按鈕',
  onClick: () => {}
};

export default TagItem;
