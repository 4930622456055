import dayjs from 'dayjs';
import { makeObservable, observable, action, computed } from 'mobx';
import { PROJECT_STATUS } from 'src/global/constants';

class ProjectViewModel {
  id = null;
  projectName = null;
  companyName = null;

  @observable step = null;
  @observable questPath = null;
  @observable questUpdateTime = null;

  @observable companyId = null;

  @computed
  get label() {
    const questUpdateTime = this.questUpdateTime
      ? dayjs(this.questUpdateTime).format('YYYY.MM.DD HH:mm:ss')
      : null;

    return {
      step: PROJECT_STATUS[this.step].label,
      questUpdateTime
    };
  }

  constructor(props) {
    this.id = props.id;
    this.projectName = props.projectName;
    this.step = props.step;
    this.companyName = props.companyName;

    this.questPath = props.questPath;

    this.questUpdateTime = props.questUpdateTime;

    this.companyId = props.companyId;

    makeObservable(this);
  }

  @action
  onChangeQuestPath = (event) => {
    this.questPath = event.target.value;
  };

  @action
  onUpdated = () => {
    this.questUpdateTime = dayjs();
  };
}

export default ProjectViewModel;
