import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Spin } from 'antd';
import styles from './styles.module.scss';

@observer
class Loading extends React.Component {
  render() {
    return (
      <div className={styles.container}>
        <Spin size="large" />
      </div>
    );
  }
}

Loading.propTypes = {};

Loading.defaultProps = {};

export default Loading;
