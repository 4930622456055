import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Input, Button } from 'antd';
import withRouter from 'src/components/withRouter';
import WarningModal from 'src/components/WarningModal';
import RejectFormViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class RejectForm extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new RejectFormViewModel({
      router: props.router,
      data: props.data,
      checkParams: props.checkParams,
      onChanged: props.onChanged
    });
  }

  componentDidUpdate(preProps) {
    this.vm.didUpdate(this.props, preProps);
  }

  render() {
    return this.vm.isShow ? (
      <div className={styles.container}>
        <div className={this.vm.buttonsStyle}>
          {this.vm.buttons.map((item) => {
            return (
              <div className={styles.buttonContainer} key={item.value}>
                <Button
                  type="primary"
                  size="large"
                  className={styles.button}
                  onClick={() => {
                    this.vm.onStepClick(item);
                  }}
                  disabled={this.vm.disabled.stepButton}
                >
                  {item.label}
                </Button>
              </div>
            );
          })}
        </div>

        <WarningModal
          vm={this.vm.modalVM}
          content={this.vm.warningContent}
          onConfirm={this.vm.onWarningModalConfirm}
          disabledConfirm={this.vm.disabled.warningModalConfirm}
        />
      </div>
    ) : null;
  }
}

RejectForm.propTypes = {
  router: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  checkParams: PropTypes.object.isRequired,
  onChanged: PropTypes.func
};

RejectForm.defaultProps = {
  onChanged: () => {}
};

export default withRouter(RejectForm);
