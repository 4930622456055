import { useState } from 'react';
import { CHANNEL_MODELS } from 'src/global/form';

function useOptions() {
  const [options] = useState({
    channels: Object.values(CHANNEL_MODELS)
  });

  return options;
}

export default useOptions;
