import MessageAPIs from 'src/apis/MessageAPIs';
import MessageDataModel from 'src/models/response/MessageDataModel';
import CookieServices from './CookieServices';

class MessageServices {
  // 讀取通知列表
  static async getMessageList({ anchor }) {
    const res = await MessageAPIs.getMessageList({
      token: CookieServices.getToken(),
      anchor
    });

    return {
      data: res.data.data.map((item) => new MessageDataModel(item)),
      anchor: res.data.anchor || null
    };
  }

  // 通知已讀
  static async putMessageRead({ messageId }) {
    const res = await MessageAPIs.putMessageRead({
      token: CookieServices.getToken(),
      messageId
    });

    return {
      data: res.data
    };
  }
}

export default MessageServices;
