import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Input, Button } from 'antd';
import ICONS from 'src/global/icons';
import DeleteModal from 'src/components/DeleteModal';
import clsx from 'clsx';
import styles from './styles.module.scss';

@observer
class ShortAnswer extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
  }

  render() {
    return (
      <div
        className={clsx([
          styles.container,
          this.vm.invalid && styles.invalidBorder
        ])}
      >
        <div className={styles.title}>
          <div className={styles.text}>題目型態：簡答題</div>

          <Button
            type="link"
            className={styles.deleteButton}
            onClick={this.vm.deleteModalVM.show}
            disabled={this.props.disabled}
          >
            <ICONS.Delete />
          </Button>
        </div>

        <div className={styles.item}>
          <div className={styles.label}>題目：</div>

          <Input
            size="small"
            value={this.vm.topic}
            onChange={this.vm.onChangeTopic}
            onBlur={this.vm.onBlur}
            disabled={this.props.disabled}
            maxLength={100}
            showCount
          />
        </div>

        <div className={styles.item}>
          <div className={styles.label}>說明：</div>

          <Input.TextArea
            rows={4}
            size="small"
            value={this.vm.content}
            onChange={this.vm.onChangeContent}
            onBlur={this.vm.onBlur}
            disabled={this.props.disabled}
            maxLength={500}
            showCount
          />
        </div>

        <DeleteModal
          vm={this.vm.deleteModalVM}
          onConfirm={this.vm.onDeleteConfirm}
        />
      </div>
    );
  }
}

ShortAnswer.propTypes = {
  vm: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired
};

ShortAnswer.defaultProps = {};

export default ShortAnswer;
