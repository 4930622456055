import React from 'react';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import styles from './styles.module.scss';
import './reset.scss';

@observer
class CustomTable extends React.Component {
  render() {
    const {
      data,
      children,
      hasPadding,
      hasScrollX,
      scrollX,
      hasOverflow,
      onScrollEnd,
      ...ps
    } = this.props;

    return (
      <div
        id="customTable"
        className={clsx([
          styles.container,
          hasPadding && 'hasPadding',
          hasOverflow && styles.overflow
        ])}
      >
        <Table
          dataSource={data}
          pagination={false}
          rowKey={(item) => item.id}
          scroll={{
            x: hasScrollX && scrollX
          }}
          {...ps}
        >
          {children}
        </Table>

        {onScrollEnd && (
          <Waypoint onEnter={onScrollEnd} />
        )}
      </div>
    );
  }
}

CustomTable.propTypes = {
  data: PropTypes.array,
  children: PropTypes.node,
  size: PropTypes.string,
  hasPadding: PropTypes.bool,
  hasScrollX: PropTypes.bool,
  scrollX: PropTypes.string,
  hasOverflow: PropTypes.bool,
  onScrollEnd: PropTypes.func
};

CustomTable.defaultProps = {
  data: [],
  children: null,
  size: 'large',
  hasScrollX: false,
  scrollX: 'max-content',
  hasPadding: false,
  hasOverflow: false,
  onScrollEnd: null
};

export default CustomTable;
