import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Checkbox, Radio, Input } from 'antd';
import SelfRow from 'src/components/CustomForm/components/SelfRow';
import FormatCell from 'src/components/CustomForm/components/FormatCell';
import SelfFormItem from 'src/components/CustomForm/components/SelfFormItem';
import BorderRightBottom from 'src/components/CustomForm/components/BorderRightBottom';
import CheckAreaDetailViewModel from './viewModel';
import styles from './styles.module.scss';

// 專案受稽場域資料
@observer
class CheckAreaDetail extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new CheckAreaDetailViewModel(props);
  }

  render() {
    return (
      <BorderRightBottom>
        <div className={styles.group}>
          <div className={styles.label}>非現場</div>

          <div className={styles.content}>
            <SelfRow>
              <FormatCell label="社群軟體">
                <SelfFormItem
                  name={[
                    'checkAreaDetail',
                    'offSite',
                    'socialSoftware',
                    'value'
                  ]}
                >
                  <Checkbox.Group options={this.vm.mapOptions.socialSoftware} />
                </SelfFormItem>

                <div className={styles.socialSoftwareOtherDesc}>
                  <SelfFormItem
                    name={[
                      'checkAreaDetail',
                      'offSite',
                      'socialSoftware',
                      'otherDesc'
                    ]}
                  >
                    <Input size="small" maxLength={50} />
                  </SelfFormItem>
                </div>
              </FormatCell>
            </SelfRow>

            <SelfRow>
              <FormatCell label="APP">
                <SelfFormItem
                  name={['checkAreaDetail', 'offSite', 'app', 'value']}
                >
                  <Radio.Group options={this.vm.mapOptions.appTypes} />
                </SelfFormItem>
              </FormatCell>
            </SelfRow>

            <SelfRow>
              <FormatCell label="官方網站">
                <SelfFormItem
                  name={[
                    'checkAreaDetail',
                    'offSite',
                    'officialWebsite',
                    'value'
                  ]}
                >
                  <Radio.Group
                    options={this.vm.mapOptions.officialWebsiteTypes}
                  />
                </SelfFormItem>
              </FormatCell>
            </SelfRow>

            <SelfRow>
              <FormatCell label="電話撥打">
                <SelfFormItem
                  name={[
                    'checkAreaDetail',
                    'offSite',
                    'dial',
                    'value'
                  ]}
                >
                  <Checkbox.Group options={this.vm.mapOptions.dial} />
                </SelfFormItem>

                <div className={styles.dialOtherDesc}>
                  <SelfFormItem
                    name={[
                      'checkAreaDetail',
                      'offSite',
                      'dial',
                      'otherDesc'
                    ]}
                  >
                    <Input size="small" maxLength={50} />
                  </SelfFormItem>
                </div>
              </FormatCell>
            </SelfRow>
          </div>
        </div>

        <SelfRow>
          <FormatCell label="現場">
            <SelfFormItem name={['checkAreaDetail', 'onSite']}>
              <Radio.Group options={this.vm.mapOptions.onSiteTypes} />
            </SelfFormItem>
          </FormatCell>
        </SelfRow>
      </BorderRightBottom>
    );
  }
}

CheckAreaDetail.propTypes = {};

CheckAreaDetail.defaultProps = {};

export default CheckAreaDetail;
