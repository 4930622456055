// 表單相關
import { v4 } from 'uuid';

// 總規格表
// v1.0
export const GENERAL_FORM_V1 = {
  projectDetail: {
    projectName: null,
    companyName: null,
    projectNameCode: null,
    overallDate: null,
    // 執案週期數
    caseWeek: null
  },

  // 每波執行時間
  waveTimes: [
    {
      id: v4(),
      startAt: null,
      endAt: null
    }
  ],

  // 每波執行資料
  waveDetails: [
    {
      id: v4(),
      // 波段執案時間
      rangeAt: null,
      // 本波派案期限
      overallDate: null,
      // 本波訪員繳交期限
      handOverDeadlineDate: null,
      // 波段專案交付物
      deliverables: [],
      // 波段交付物預知期限
      handOverItemDates: [
        {
          // id: v4(),
          date: null,
          items: [],
          other: null
        }
      ],

      // 各種敘述
      checkFormDesc: null, // 查檢表
      originalReportDesc: null, // 稽查原始報告
      statisticsFileDesc: null, // 統計
      analysisReportDesc: null, // 分析報告
      powerPointFileDesc: null, // 簡報
      photosFileDesc: null, // 照片
      videoFileDesc: null, // 影片
      postProductionVideoFileDesc: null, // 後製影片檔
      recordingFileDesc: null, // 錄音
      postProductionRecordingFileDesc: null, // 後製錄音檔
      thingFileDesc: null, // 文件物品
      commodityDesc: null, // 商品
      otherDesc: null // 其他
    }
  ],

  // 專案案件總數量
  caseAmount: {
    onSite: null,
    offSite: null,
    other: null
  },

  // 通路區別
  channelsModel: {
    value: null,
    directSellingStoreDesc: null,
    franchiseStoreDesc: null
  },

  // 通路區域
  channelsArea: {
    north1: null,
    north2: null,
    center1: null,
    south1: null,
    south2: null,
    east1: null,
    island1: null
  },

  // 本次派案內容
  caseContent: {
    onSite: null,
    offSite: null,
    other: null
  },

  // 區域
  areas: {
    north1: null,
    north2: null,
    center1: null,
    south1: null,
    south2: null,
    east1: null,
    island1: null
  },

  // 訪員條件
  visitorRequirements: {
    // 證照
    license: null,
    // 駕照
    drivingLicense: null,
    // 良民證
    policeCriminalRecordCertificate: null,
    // 其他
    other: null,
    // 性別
    gender: {
      value: null,
      otherDesc: null
    },
    // 年齡
    age: {
      value: null,
      greaterThanDesc: null,
      lessThanDesc: null,
      equalToDesc: null,
      betweenDesc1: null,
      betweenDesc2: null,
      otherDesc: null
    },
    // 保戶
    insurers: {
      value: null,
      otherDesc: null
    },
    // 會員
    member: null,
    // 角色需求
    roleNeeds: {
      value: null,
      jobDesc: null,
      characterDesc: null,
      mateDesc: null
    },
    // 具備產品要求
    deviceNeeds: {
      value: null,
      phoneDesc: null,
      carDesc: null,
      motorDesc: null,
      otherDesc: null
    }
  },

  // 訪員執案要求
  visitorWorks: {
    // 消費要求
    shoppingNeeds: {
      value: null,
      commodity: null,
      spend: null
    },

    // 影音紀錄要求
    mediaRecordNeeds: {
      value: null,
      otherDesc: null
    },

    // 付款方式
    paymentMethod: {
      value: null,
      otherDesc: null
    },

    // 統一編號
    uniformNumber: {
      value: null,
      number: null
    },

    // 退換貨
    returnMethods: {
      value: null
    },

    // 稽查時間
    checkDates: {
      value: null,
      specifiedDateDesc: null,
      doNotCheckDateDesc: null,
      specifiedTimeDesc: null,
      limitTimeDesc: null,
      dwellTimeDesc: null
    },

    // 受稽人員要求
    inspectedPersonNeed: {
      value: null,
      specifyDesc: null,
      limitDesc: null
    },

    // 投訴要求
    complaintRequirements: {
      value: null,
      onSiteDesc: null,
      phoneDesc: null,
      networkDesc: null
    },

    // 投訴人要求
    complainantRequirements: {
      value: null,
      specifyDesc: null,
      limitDesc: null
    },

    // 指定索取物
    claims: {
      value: null,
      dmDesc: null,
      businessCardDesc: null,
      giveawayDesc: null,
      otherDesc: null
    },

    // 二次稽查需求
    againCheckNeeds: null,

    // 複數場域稽查
    checkAreas: null
  },

  // 專案受稽場域資料
  checkAreaDetail: {
    offSite: {
      socialSoftware: {
        value: null,
        otherDesc: null
      },

      app: {
        value: null
      },

      officialWebsite: {
        value: null
      }
    },

    onSite: null
  },

  casePayDetail: {
    offSite: {
      socialSoftware: {
        noLicense: null,
        oneLicense: null,
        towLicense: null
      },

      app: {
        noLicense: null,
        oneLicense: null,
        towLicense: null
      },

      officialWebsite: {
        noLicense: null,
        oneLicense: null,
        towLicense: null
      }
    },

    onSite: {
      noLicense: null,
      oneLicense: null,
      towLicense: null
    }
  }
};

// 交付物
export const DELIVERABLES = {
  checkForm: {
    value: 'checkForm',
    label: '查檢表'
  },
  originalReport: {
    value: 'originalReport',
    label: '稽查原始報告'
  },
  statisticsFile: {
    value: 'statisticsFile',
    label: '統計'
  },
  analysisReport: {
    value: 'analysisReport',
    label: '分析報告'
  },
  powerPointFile: {
    value: 'powerPointFile',
    label: '簡報'
  },
  photosFile: {
    value: 'photosFile',
    label: '照片'
  },
  videoFile: {
    value: 'videoFile',
    label: '影片'
  },
  postProductionVideoFile: {
    value: 'postProductionVideoFile',
    label: '後製影片檔'
  },
  recordingFile: {
    value: 'recordingFile',
    label: '錄音'
  },
  postProductionRecordingFile: {
    value: 'postProductionRecordingFile',
    label: '後製錄音檔'
  },
  thingFile: {
    value: 'thingFile',
    label: '文件物品'
  },
  commodity: {
    value: 'commodity',
    label: '商品'
  },
  other: {
    value: 'other',
    label: '其他'
  }
};

// 通路區別
export const CHANNEL_MODELS = {
  // 直營
  directSellingStore: {
    value: 'directSellingStore',
    label: '直營'
  },

  // 店格
  storeGrid: {
    value: 'storeGrid',
    label: '店格'
  },

  // 經銷/代銷
  dealer: {
    value: 'dealer',
    label: '經銷/代銷'
  }
};

// 證照選項
export const LICENSE = {
  tsaa: {
    value: 'tsaa',
    label: '單照（TSAA)'
  },

  sgs: {
    value: 'sgs',
    label: '單照（SGS)'
  },

  tsaaOrSgs: {
    value: 'tsaaOrSgs',
    label: '單照（TSAA 或 SGS 擇一)'
  },

  cma: {
    value: 'cma',
    label: '單照（CMA)'
  },

  either: {
    value: 'either',
    label: '單照（任一)'
  },

  tsaaAndSgs: {
    value: 'tsaaAndSgs',
    label: '雙照（TSAA+SGS)'
  },

  eitherAndCma: {
    value: 'eitherAndCma',
    label: '雙照（需含CMA)'
  },

  two: {
    value: 'two',
    label: '雙照（任二）'
  },

  optional: {
    value: 'optional',
    label: '不限'
  }
};

// 駕照選項
export const DRIVING_LICENSE = {
  all: {
    value: 'all',
    label: '機車+汽車'
  },

  either: {
    value: 'either',
    label: '機車或汽車'
  }
};

// 良民證選項
export const POLICE_CRIMINAL_RECORD_CERTIFICATE = {
  required: {
    value: 'required',
    label: '需要'
  },

  optional: {
    value: 'optional',
    label: '不限'
  }
};

// 性別選項
export const GENDERS = {
  male: {
    value: 'male',
    label: '男'
  },

  female: {
    value: 'female',
    label: '女'
  },

  optional: {
    value: 'optional',
    label: '不限'
  },

  other: {
    value: 'other',
    label: '其他'
  }
};

// 年齡選項
export const AGES = {
  greaterThan: {
    value: 'greaterThan',
    label: '大於'
  },

  lessThan: {
    value: 'lessThan',
    label: '小於'
  },

  equalTo: {
    value: 'equalTo',
    label: '等於'
  },

  between: {
    value: 'between',
    label: '介於'
  },

  other: {
    value: 'other',
    label: '其他'
  },

  optional: {
    value: 'optional',
    label: '無限制'
  }
};

// 保險公司
export const INSURERS = {
  CathayLifeInsurance: {
    value: 'CathayLifeInsurance',
    label: '國泰人壽'
  },

  FubonLifeInsurance: {
    value: 'FubonLifeInsurance',
    label: '富邦人壽'
  },

  NanShanLifeInsurance: {
    value: 'NanShanLifeInsurance',
    label: '南山人壽'
  },

  PCALifeAssurance: {
    value: 'PCALifeAssurance',
    label: '保誠人壽'
  },

  ChinaLifeInsurance: {
    value: 'ChinaLifeInsurance',
    label: '中國人壽'
  },

  TaishinLifeInsurance: {
    value: 'TaishinLifeInsurance',
    label: '新光人壽'
  },

  MercuriesLifeInsurance: {
    value: 'MercuriesLifeInsurance',
    label: '三商美邦人壽'
  },

  FargloryLifeInsurance: {
    value: 'FargloryLifeInsurance',
    label: '遠雄人壽'
  },

  other: {
    value: 'other',
    label: '其他'
  }
};

// 角色需求
export const ROLE_NEEDS = {
  job: {
    value: 'job',
    label: '職業'
  },

  character: {
    value: 'character',
    label: '性格'
  },

  mate: {
    value: 'mate',
    label: '攜伴'
  }
};

// 產品需求
export const DEVICE_NEEDS = {
  phone: {
    value: 'phone',
    label: '手機'
  },

  car: {
    value: 'car',
    label: '汽車'
  },

  motor: {
    value: 'motor',
    label: '機車'
  },

  other: {
    value: 'other',
    label: '其他'
  }
};

// 消費要求
export const SHOPPING_NEEDS = {
  commodity: {
    value: 'commodity',
    label: '指定消費商品'
  },

  spend: {
    value: 'spend',
    label: '消費金額'
  },

  optional: {
    value: 'optional',
    label: '無需消費'
  }
};

// 影音紀錄要求
export const MEDIA_RECORD_NEEDS = {
  photo: {
    value: 'photo',
    label: '照片'
  },

  video: {
    value: 'video',
    label: '影片'
  },

  recording: {
    value: 'recording',
    label: '錄音'
  },

  other: {
    value: 'other',
    label: '其他'
  }
};

// 付款方式
export const PAYMENT_METHODS = {
  cash: {
    value: 'cash',
    label: '現金'
  },

  creditCard: {
    value: 'creditCard',
    label: '信用卡'
  },

  optional: {
    value: 'optional',
    label: '不限'
  },

  other: {
    value: 'other',
    label: '其他'
  }
};

// 統一編號
export const UNIFORM_NUMBER_TYPES = {
  required: {
    value: 'required',
    label: ''
  },

  optional: {
    value: 'optional',
    label: '不需'
  }
};

// 退換貨
export const RETURN_METHODS = {
  return: {
    value: 'return',
    label: '退貨'
  },

  network: {
    value: 'network',
    label: '網路'
  },

  conan: {
    value: 'conan',
    label: '由柯南退貨'
  }
};

// 稽查時間
export const CHECK_DATE_TYPES = {
  specifiedDate: {
    value: 'specifiedDate',
    label: '指定日期'
  },
  doNotCheckDate: {
    value: 'doNotCheckDate',
    label: '不查日期'
  },
  specifiedTime: {
    value: 'specifiedTime',
    label: '指定時間'
  },
  limitTime: {
    value: 'limitTime',
    label: '限制時間'
  },
  dwellTime: {
    value: 'dwellTime',
    label: '滯留時間需求'
  },
  inBusinessHours: {
    value: 'inBusinessHours',
    label: '營業時間內均可稽查'
  }
};

// 受稽人員要求
export const INSPECTED_PERSON_NEED_TYPES = {
  specify: {
    value: 'specify',
    label: '指定稽查人員/單位'
  },
  limit: {
    value: 'limit',
    label: '限制稽查人員/單位'
  }
};

// 投訴要求
export const COMPLAINT_REQUIREMENTS = {
  onSite: {
    value: 'onSite',
    label: '現場'
  },
  phone: {
    value: 'phone',
    label: '電話'
  },
  network: {
    value: 'network',
    label: '網路'
  }
};

// 投訴對象要求
export const COMPLAINANT_REQUIREMENTS = {
  specify: {
    value: 'specify',
    label: '指定'
  },
  limit: {
    value: 'limit',
    label: '限制'
  }
};

// 指定索取物
export const CLAIMS = {
  dm: { value: 'dm', label: 'DM/文宣' },
  businessCard: { value: 'businessCard', label: '名片' },
  giveaway: { value: 'giveaway', label: '贈品' },
  other: { value: 'other', label: '其他' }
};

// 專案受稽場域資料, 社群軟體
export const SOCIAL_SOFTWARES = {
  faceBook: {
    value: 'facebook',
    label: '臉書'
  },

  instagram: {
    value: 'instagram',
    label: 'IG'
  },

  twitter: {
    value: 'twitter',
    label: 'Twitter'
  },

  telegram: {
    value: 'telegram',
    label: 'Telegram'
  },

  other: {
    value: 'other',
    label: '其他'
  }
};

// 專案受稽場域資料, APP
export const APP_TYPES = {
  true: {
    value: true,
    label: '有'
  },

  false: {
    value: false,
    label: '無'
  }
};

// 專案受稽場域資料, 電話撥打
export const DIAL = {
  shop: {
    value: 'shop',
    label: '門市／分店電話'
  },

  headOffice: {
    value: 'headOffice',
    label: '總行電話'
  },

  customerService: {
    value: 'customerService',
    label: '客服中心電話'
  },

  customerServiceHotline: {
    value: 'customerServiceHotline',
    label: '免費客服專線'
  },

  other: {
    value: 'other',
    label: '其他'
  }
};

// 專案受稽場域資料, 官方網站
export const OFFICIAL_WEBSITE_TYPES = {
  true: {
    value: true,
    label: '有'
  },

  false: {
    value: false,
    label: '無'
  }
};

// 專案受稽場域資料, 現場
export const ONSITE_TYPES = {
  true: {
    value: true,
    label: '有'
  },

  false: {
    value: false,
    label: '無'
  }
};
