import {
  // 帳號
  UserOutlined,
  // 密碼
  LockOutlined,
  UnlockOutlined,
  // 公告管理
  NotificationOutlined,
  SoundOutlined,
  // 人員管理
  TeamOutlined,
  // 專案管理
  SettingOutlined,
  // 查檢表與問卷管理
  CopyOutlined,
  FileWordOutlined,
  // 客戶(企業)管理
  FundOutlined,
  // 專案通知
  MailOutlined,
  // 編輯
  EditOutlined,
  // 加號
  PlusOutlined,
  // 減號 + 邊框
  MinusCircleOutlined,
  // 刪除
  DeleteOutlined,
  // 篩選
  FilterOutlined,
  // 驚嘆號 + 圓形邊框
  ExclamationCircleOutlined,
  // 驚嘆號 + 背景顏色
  ExclamationCircleFilled,
  // 打勾
  CheckOutlined,
  // 打勾 + 背景顏色
  CheckCircleFilled,
  // 向右箭頭
  RightOutlined,
  // 向下箭頭
  DownOutlined,
  // 向左箭頭
  LeftOutlined,
  // component 顯示
  EyeOutlined,
  // component 隱藏
  EyeInvisibleOutlined,
  // 向左箭頭
  ArrowLeftOutlined,
  // 磁碟片
  SaveOutlined,
  // 問號
  QuestionCircleOutlined,
  // 連結
  LinkOutlined
} from '@ant-design/icons';

// icon
const ICONS = {
  // 帳號
  Account: UserOutlined,

  // 密碼隱藏顯示
  PassWordHide: LockOutlined,
  PassWordShow: UnlockOutlined,

  // 公告
  Announcement: SoundOutlined,

  // 人員管理
  User: TeamOutlined,

  // 專案管理
  Project: SettingOutlined,

  // 查檢表與問卷管理
  Form: FileWordOutlined,

  // 客戶(企業)管理
  Client: FundOutlined,

  // 專案通知
  Notification: MailOutlined,

  // 新增
  Add: PlusOutlined,

  // 減號 + 邊框
  Reduce: MinusCircleOutlined,

  // 編輯
  Edit: EditOutlined,

  // 刪除
  Delete: DeleteOutlined,

  // 上一頁
  Back: ArrowLeftOutlined,

  // 往下箭頭
  BottomArrow: DownOutlined,

  // 往右箭頭
  RightArrow: RightOutlined,

  // 向左箭頭
  LeftArrow: LeftOutlined,

  // 警告, 驚嘆號
  Warning: ExclamationCircleFilled,
  WarningOutlined: ExclamationCircleOutlined,

  // 成功, 打勾
  Success: CheckCircleFilled,

  // 保存, 磁碟
  Save: SaveOutlined,

  // 顯示
  Show: EyeOutlined,

  // 隱藏
  Hide: EyeInvisibleOutlined,

  // 損益表問號
  BudgetFormula: QuestionCircleOutlined,

  // 連結
  Link: LinkOutlined
};

export default ICONS;
