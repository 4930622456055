import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction
} from 'mobx';
import { diff } from 'deep-diff';
import CustomModalViewModel from 'src/components/CustomModal/viewModel';
import ProjectServices from 'src/services/ProjectServices';
import ErrorServices from 'src/services/ErrorServices';

class InternalItemViewModel {
  @observable project = null;
  @observable checkParams = null;
  @observable value = '';
  @observable isAwait = false;
  onChanged = null;
  modalVM = new CustomModalViewModel();

  @computed
  get check() {
    const checkIdentity = this.checkParams.isHandlerOrBoss;

    return {
      isEdit: !this.checkParams.isEnd && checkIdentity
    };
  }

  @computed
  get disabled() {
    return {
      modal: !this.check.isEdit
    };
  }

  @computed
  get label() {
    return {
      button: this.check.isEdit ? '編輯' : '查看'
    };
  }

  constructor(props) {
    this.init(props);
    makeObservable(this);
  }

  @action
  init = (props) => {
    this.project = props.project;
    this.checkParams = props.checkParams;

    this.value = props.project.message || '';
  };

  didUpdate = (props, preProps) => {
    const diffProject = diff(props.project, preProps.project);
    const diffCheckParams = diff(props.checkParams, preProps.checkParams);

    if (diffProject || diffCheckParams) {
      this.init(props);
    }
  };

  @action
  onValueChange = (event) => {
    this.value = event.target.value;
  };

  onConfirm = () => {
    this.postProjectUpdateMessageAPI();
  };

  @action
  postProjectUpdateMessageAPI = async () => {
    this.isAwait = true;

    try {
      const msg = this.value.trim() || undefined;
      const res = await ProjectServices.postProjectUpdateMessage({
        projectId: this.project.projectId,
        content: msg
      });

      this.modalVM.hide();
    } catch (error) {
      const msg = ErrorServices.postProjectUpdateMessage(error);

      console.log('InternalItemViewModel', 'postProjectUpdateMessage', msg);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default InternalItemViewModel;
