import React from 'react';
import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import dayjs from 'dayjs';
import {
  RE_ID_NUMBER,
  USER_TYPES,
  ACCOUNT_CREATE_TYPES
} from 'src/global/constants';
import CustomModalViewModel from 'src/components/CustomModal/viewModel';
import UserServices from 'src/services/UserServices';
import EmployeeServices from 'src/services/EmployeeServices';
import ErrorServices from 'src/services/ErrorServices';
import { message } from 'antd';

class StaffEditModalViewModel {
  @observable isCreate = false;
  @observable isAwait = false;

  modalVM = new CustomModalViewModel();

  // 更新使用
  accountId = null;
  form = null;

  created = null;
  edited = null;

  @computed
  get label() {
    return {
      title: this.isCreate ? '新增' : '編輯'
    };
  }

  @computed
  get disabled() {
    return {
      idNumber: !this.isCreate,
      birthday: !this.isCreate
    };
  }

  @computed
  get groups() {
    return [
      USER_TYPES.projectManager,
      USER_TYPES.operationsManagement,
      USER_TYPES.salesperson
    ];
  }

  @computed
  get rules() {
    const requiredLimit = {
      required: true,
      message: '請輸入'
    };

    return {
      required: [requiredLimit],
      idNumber: [
        requiredLimit,
        { pattern: RE_ID_NUMBER, message: '身分證字號格式錯誤' }
      ]
    };
  }

  constructor() {
    makeObservable(this);
  }

  @action
  init = ({ form }) => {
    this.form = form;
  };

  @action
  onOpenCreate = ({ created }) => {
    this.isCreate = true;
    this.created = created;

    this.resetForm();

    this.modalVM.show();
  };

  @action
  onOpenEdit = ({ id, data, edited }) => {
    this.isCreate = false;
    this.edited = edited;
    this.accountId = id;

    this.resetForm();
    this.setFormData(data);

    this.modalVM.show();
  };

  // 確認
  onConfirm = () => {
    if (this.isCreate) {
      this.postAccountCreateAPI();
    } else {
      this.putEmployeeUpdateAPI();
    }
  };

  // 取消
  onCancel = () => {
    this.modalVM.hide();
  };

  resetForm = () => {
    const data = {
      type: null,
      name: null,
      idNumber: null,
      birthday: null,
      employmentDate: null,
      resignationDate: null,
      insuranceDate: null,
      disInsuranceDate: null,
      phone: null,
      address: null,
      contactName: null,
      contactPhone: null,
      contactRelationship: null,
      education: null,
      license: null,
      comment: null,
      updatedAt: null,
      isDisabled: false
    };

    this.form.setFieldsValue(data);
  };

  setFormData = (data) => {
    const birthday = dayjs(data.birthday);

    const idNumber = data.idNumber ? data.idNumber.toLocaleUpperCase() : null;

    const employmentDate = data.employmentDate
      ? dayjs(data.employmentDate)
      : null;

    const resignationDate = data.resignationDate
      ? dayjs(data.resignationDate)
      : null;

    const insuranceDate = data.insuranceDate ? dayjs(data.insuranceDate) : null;

    const disInsuranceDate = data.disInsuranceDate
      ? dayjs(data.disInsuranceDate)
      : null;

    const resData = {
      ...data,
      idNumber,
      birthday,
      employmentDate,
      resignationDate,
      insuranceDate,
      disInsuranceDate
    };

    this.form.setFieldsValue(resData);
  };

  getFormData = async () => {
    const data = await this.form.validateFields();

    return {
      ...data,
      birthday: data.birthday.format('YYYY-MM-DD'),
      employmentDate: data.employmentDate?.format('YYYY-MM-DD'),
      resignationDate: data.resignationDate?.format('YYYY-MM-DD'),
      insuranceDate: data.insuranceDate?.format('YYYY-MM-DD'),
      disInsuranceDate: data.disInsuranceDate?.format('YYYY-MM-DD')
    };
  };

  validDate = (data) => {
    const employmentDate = dayjs(data.employmentDate).valueOf();
    const resignationDate = data.resignationDate
      ? dayjs(data.resignationDate).valueOf()
      : 0;

    const insuranceDate = data.insuranceDate
      ? dayjs(data.insuranceDate).valueOf()
      : 0;
    const disInsuranceDate = data.disInsuranceDate
      ? dayjs(data.disInsuranceDate).valueOf()
      : 0;

    if (employmentDate && resignationDate && employmentDate > resignationDate) {
      message.error('到職日期與離職日期有誤, 請重新檢視', 5);

      return false;
    }

    if (insuranceDate && disInsuranceDate && insuranceDate > disInsuranceDate) {
      message.error('加保日期與退保日期有誤, 請重新檢視', 5);

      return false;
    }

    return true;
  };

  // 新增員工
  @action
  postAccountCreateAPI = async () => {
    this.isAwait = true;

    try {
      const data = await this.getFormData();
      const check = this.validDate(data);

      if (check) {
        const res = await UserServices.postAccountCreate({
          type: ACCOUNT_CREATE_TYPES.employee,
          data
        });

        this.created();

        this.modalVM.hide();
      }
    } catch (error) {
      const msg = ErrorServices.postAccountCreate(error);

      console.log('StaffEditModalViewModel', error, msg);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };

  // 更新員工
  @action
  putEmployeeUpdateAPI = async () => {
    this.isAwait = true;

    try {
      const data = await this.getFormData();
      const check = this.validDate(data);

      if (check) {
        const res = await EmployeeServices.putEmployeeUpdate({
          id: this.accountId,
          data: { ...data, idNumber: undefined }
        });

        this.edited(data);

        this.modalVM.hide();
      }
    } catch (error) {
      const msg = ErrorServices.putEmployeeUpdate(msg);

      console.log('StaffEditModalViewModel', error, msg);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default StaffEditModalViewModel;
