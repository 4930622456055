import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import FormDetail from './components/FormDetail';
import WaveTimes from './components/WaveTimes';
import WaveDetails from './components/WaveDetails';
import CaseAmount from './components/CaseAmount';
import ChannelStore from './components/ChannelStore';
import Areas from './components/Areas';
import VisitorRequirements from './components/VisitorRequirements';
import VisitorWorks from './components/VisitorWorks';
import CheckAreaDetail from './components/CheckAreaDetail';
import CasePayDetail from './components/CasePayDetail';
import styles from './styles.module.scss';

function Template1(props) {
  return (
    <div className={styles.formMainContent}>
      {/* 專案總規格 */}
      <div className={styles.formSection}>
        <FormDetail
          project={props.project}
          form={props.form}
          isEdit={props.isEdit}
        />
      </div>

      {/* 每波執行資料 */}
      <div className={styles.formSection}>
        <div className={styles.title}>
          <div className={clsx([styles.icon, styles.pmIcon])} />
          每波執行資料
        </div>

        <WaveDetails disabled={props.disabled} form={props.form} />
      </div>

      {/* 專案案件總數量 */}
      <div className={styles.formSection}>
        <div className={styles.title}>
          <div className={clsx([styles.icon, styles.saIcon])} />
          專案案件總數量
        </div>

        <CaseAmount nameKey="caseAmount" />
      </div>

      {/* 通路區別 */}
      <div className={styles.formSection}>
        <div className={styles.title}>
          <div className={clsx([styles.icon, styles.pmIcon])} />
          通路區別
        </div>

        <ChannelStore />
      </div>

      {/* 通路區域 */}
      <div className={styles.formSection}>
        <div className={styles.title}>
          <div className={clsx([styles.icon, styles.pmIcon])} />
          通路區域
        </div>

        <Areas nameKey="channelsArea" />
      </div>

      {/* 本專案內容 */}
      <div className={styles.formSection}>
        <div className={styles.title}>
          <div className={clsx([styles.icon, styles.pmIcon])} />
          本專案內容
        </div>

        <CaseAmount nameKey="caseContent" />
      </div>

      {/* 區域 */}
      <div className={styles.formSection}>
        <div className={styles.title}>
          <div className={clsx([styles.icon, styles.pmIcon])} />
          區域
        </div>

        <Areas nameKey="areas" />
      </div>

      {/* 訪員條件 */}
      <div className={styles.formSection}>
        <div className={styles.title}>訪員條件</div>

        <VisitorRequirements />
      </div>

      {/* 訪員執案要求 */}
      <div className={styles.formSection}>
        <div className={styles.title}>訪員執案要求</div>

        <VisitorWorks />
      </div>

      {/* 專案受稽場域資料 */}
      <div className={styles.formSection}>
        <div className={styles.title}>
          <div className={clsx([styles.icon, styles.pmIcon])} />
          專案受稽場域資料
        </div>

        <CheckAreaDetail />
      </div>

      {/* 執案件酬定表 */}
      <div className={styles.formSection}>
        <div className={styles.title}>
          <div className={clsx([styles.icon, styles.omIcon])} />
          執案件酬設定表
        </div>

        <CasePayDetail />
      </div>
    </div>
  );
}

Template1.propTypes = {
  project: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  isEdit: PropTypes.bool.isRequired
};

Template1.defaultProps = {};

export default Template1;
