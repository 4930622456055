import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { diff } from 'deep-diff';
import { ROUTE_PARAMS, SITE_TYPES } from 'src/global/constants';
import CustomModalViewModel from 'src/components/CustomModal/viewModel';
import CheckFormServices from 'src/services/CheckFormServices';
import ErrorServices from 'src/services/ErrorServices';

class AppendixViewModel {
  @observable description = null;
  @observable link = null;
  @observable disabled = false;
  @observable isAwait = false;

  projectId = null;
  site = null;

  modalVM = new CustomModalViewModel();

  @computed
  get disabledEdit() {
    return this.disabled || this.isAwait;
  }

  @computed
  get buttonLabel() {
    return this.disabled ? '查看' : '編輯';
  }

  constructor(props) {
    this.description = props.attached?.description;
    this.link = props.attached?.link;

    this.disabled = props.disabled;

    this.projectId = props.router.params[ROUTE_PARAMS.projectId];

    this.site = props.site;

    makeObservable(this);
  }

  @action
  didUpdate = (props, preProps) => {
    const diffDisabled = diff(props.disabled, preProps.disabled);

    if (diffDisabled) {
      this.disabled = props.disabled;
    }
  };

  @action
  onChangeDescription = (event) => {
    this.description = event.target.value;
  };

  @action
  onChangeLink = (event) => {
    this.link = event.target.value;
  };

  onConfirm = () => {
    this.putCheckFormAttachedAPI();
  };

  // 更新雲端連結
  @action
  putCheckFormAttachedAPI = async () => {
    this.isAwait = true;

    try {
      const data = { description: this.description, link: this.link };
      const onSiteAttached
        = this.site === SITE_TYPES.onSite.value
          ? JSON.stringify(data)
          : undefined;
      const offSiteAttached
        = this.site === SITE_TYPES.offSite.value
          ? JSON.stringify(data)
          : undefined;

      const res = await CheckFormServices.putCheckFormAttached({
        projectId: this.projectId,
        onSiteAttached,
        offSiteAttached
      });

      this.modalVM.hide();
    } catch (error) {
      const message = ErrorServices.putCheckFormAttached(error);

      console.log('AppendixViewModel', 'putCheckFormAttached', message);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default AppendixViewModel;
