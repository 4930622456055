import BigNumber from 'bignumber.js';
import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction
} from 'mobx';
import { LIMIT } from 'src/global/constants';

class CustomAnchorViewModel {
  @observable data = [];
  @observable anchor = undefined;
  @observable page = 0;
  @observable total = null;
  @observable isAwait = false;

  api = null;

  @computed
  get sliceData() {
    const start = Math.floor(this.page * LIMIT);
    const end = Math.floor((this.page + 1) * LIMIT);

    return this.data.slice(start, end);
  }

  @computed
  get check() {
    return {
      total: !!this.total,
      isShow: true
    };
  }

  @computed
  get disabled() {
    const isFirstPage = this.page === 0;
    const isLastPage = this.checkNextPage();

    return {
      prev: this.isAwait || isFirstPage,
      next: this.isAwait || isLastPage
    };
  }

  @computed
  get label() {
    return {
      total: `共 ${this.total} 筆`
    };
  }

  constructor({ api }) {
    this.api = api;
    makeObservable(this);
  }

  checkNextPage = () => {
    const checkAnchor = this.anchor === null;
    const index = new BigNumber(this.page)
      .plus(1)
      .multipliedBy(LIMIT)
      .toNumber();
    const item = this.data[index];

    return !this.data.length || (!item && checkAnchor);
  };

  @action
  onPrev = () => {
    this.page -= 1;

    console.log('prev');
  };

  @action
  onNext = () => {
    this.page += 1;

    const index = new BigNumber(this.page).multipliedBy(LIMIT).toNumber();
    const item = this.data[index];
    const checkAnchor = this.anchor === null;

    if (!item && !checkAnchor) {
      this.getDataAPI();
    }

    console.log('next');
  };

  @action
  reset = async () => {
    this.data = [];
    this.anchor = undefined;
    this.page = 0;

    await this.getDataAPI();
  };

  @action
  getDataAPI = async () => {
    this.isAwait = true;

    try {
      const res = await this.api({ anchor: this.anchor });

      runInAction(() => {
        this.data = [...this.data, ...res.data];
        this.total = res.total || null;
        this.anchor = res.anchor;

        console.log('CustomAnchorViewModel', 'res', res);
      });
    } catch (error) {
      console.log('CustomAnchorViewModel', error);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default CustomAnchorViewModel;
