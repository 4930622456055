import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import styles from './styles.module.scss';
import './reset.scss';

const CustomForm = React.forwardRef((props, ref) => {
  const { labelCol, labelAlign, children, ...ps } = props;

  return (
    <div id="customForm" className={styles.container}>
      <Form
        ref={ref}
        labelCol={{
          span: labelCol
        }}
        labelAlign={labelAlign}
        {...ps}
      >
        {children}
      </Form>
    </div>
  );
});

CustomForm.propTypes = {
  labelCol: PropTypes.number,
  labelAlign: PropTypes.string,
  children: PropTypes.node
};

CustomForm.defaultProps = {
  labelCol: 8,
  labelAlign: 'left',
  children: null
};

export default CustomForm;
