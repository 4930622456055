import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { diff } from 'deep-diff';
import { PROJECT_STATUS } from 'src/global/constants';
import { COMPLETION_STATUSES } from 'src/global/closingSheet';
import CustomModalViewModel from 'src/components/CustomModal/viewModel';
import ClosingSheetServices from 'src/services/ClosingSheetServices';
import ErrorServices from 'src/services/ErrorServices';

class CloseItemViewModel {
  @observable project = null;
  @observable checkParams = null;
  @observable formData = null;
  @observable isAwait = false;

  // 表單版本
  @observable version = '1.0';

  form = null;

  modalVM = new CustomModalViewModel();

  @computed
  get isShow() {
    switch (this.project.step) {
      case PROJECT_STATUS.S6.value:
      case PROJECT_STATUS.S7.value:
      case PROJECT_STATUS.S8.value:
      case PROJECT_STATUS.S9.value:
        return true;

      default:
        return false;
    }
  }

  @computed
  get disabled() {
    return {
      formModal: this.checkParams.isEnd || !this.checkParams.isHandlerOrBoss,
      form:
        this.isAwait || this.checkParams.isEnd || !this.checkParams.isHandlerOrBoss
    };
  }

  @computed
  get label() {
    return {
      button: this.disabled.formModal ? '查看' : '編輯'
    };
  }

  @computed
  get options() {
    return {
      completionStatuses: Object.values(COMPLETION_STATUSES)
    };
  }

  constructor(props) {
    this.form = props.form;

    this.init(props);

    makeObservable(this);
  }

  @action
  init = (props) => {
    this.project = props.project;
    this.checkParams = props.checkParams;
  };

  didMount = () => {
    if (!this.isAwait && this.isShow) {
      this.getCloseFormDataAPI();
    }
  };

  didUpdate = (props, preProps) => {
    const diffProject = diff(props.project, preProps.project);
    const diffCheckParams = diff(props.checkParams, preProps.checkParams);

    if (diffProject || diffCheckParams) {
      this.init(props);
    }
  };

  onSave = async () => {
    this.postClosingSheetAPI();
  };

  // 讀取結案表
  @action
  getCloseFormDataAPI = async () => {
    this.isAwait = true;

    try {
      const res = await ClosingSheetServices.getClosingSheet({
        projectId: this.project.projectId
      });
      const content = JSON.parse(res.data.content);

      runInAction(() => {
        this.version = content.version || '1.0';
        this.form.setFieldsValue(content.data);

        console.log('getClosingSheet', content);
      });
    } catch (error) {
      const message = ErrorServices.getClosingSheet(error);

      console.log('CloseItemViewModel', 'getClosingSheet', message);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };

  // 更新結案表
  @action
  postClosingSheetAPI = async () => {
    this.isAwait = true;

    try {
      const formData = await this.form.validateFields();
      const content = {
        version: this.version,
        data: formData
      };
      const res = await ClosingSheetServices.postClosingSheet({
        projectId: this.project.projectId,
        content: JSON.stringify(content)
      });

      this.modalVM.hide();
    } catch (error) {
      const message = ErrorServices.postClosingSheet(error);

      console.log('CloseItemViewModel', 'postClosingSheet', message);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default CloseItemViewModel;
