import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { diff } from 'deep-diff';
import CustomModalViewModel from 'src/components/CustomModal/viewModel';
import { CASE_STATUS } from 'src/global/case';
import CaseServices from 'src/services/CaseServices';
import ErrorServices from 'src/services/ErrorServices';

class RemarkItemViewModel {
  @observable case = null;
  @observable checkParams = null;
  @observable remark = null;
  @observable isAwait = false;

  modalVM = new CustomModalViewModel();

  @computed
  get isShow() {
    switch (this.case.step) {
      case CASE_STATUS.S8.value:
      case CASE_STATUS.S9.value:
      case CASE_STATUS.S10.value:
      case CASE_STATUS.S11.value:
      case CASE_STATUS.S12.value: {
        return true;
      }

      default:
        return false;
    }
  }

  @computed
  get disabled() {
    const isHandler = this.checkParams.isHandler;
    const isStep8 = this.case.step === CASE_STATUS.S8.value;

    return {
      textArea: !(isHandler && isStep8)
    };
  }

  @computed
  get label() {
    return {
      button: this.disabled.textArea ? '查看' : '編輯'
    };
  }

  constructor(props) {
    this.init(props);
    makeObservable(this);
  }

  @action
  init = (props) => {
    this.case = props.case;
    this.checkParams = props.checkParams;
    this.remark = props.case.pmContent || null;
  }

  didUpdate = (props, preProps) => {
    const diffCase = diff(props.case, preProps.case);

    if (diffCase) {
      this.init(props);
    }
  }

  @action
  onChangeRemark = (event) => {
    this.remark = event.target.value;
  };

  onConfirm = () => {
    this.putCaseContentAPI();
  };

  @action
  putCaseContentAPI = async () => {
    this.isAwait = true;

    try {
      const res = await CaseServices.putCaseContent({
        caseId: this.case.caseId,
        content: this.remark
      });

      this.modalVM.hide();
    } catch (error) {
      const message = ErrorServices.putCaseContent(error);

      console.log('RemarkItemViewModel', 'putCaseContent', message);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default RemarkItemViewModel;
