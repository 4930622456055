import ICONS from './icons';

export const AUTH_TOKEN_KEY = 'token';

// 系統公告數量
export const ANNOUNCEMENTS_LENGTH = 3;

// 表格一頁數量
export const LIMIT = 15;

// 正則, 身分證
export const RE_ID_NUMBER = /^[a-zA-Z0-9]{0,}$/;

// 正則, 訪員帳號, 身分證 + 生日
export const RE_INTERVIEWER_ACCOUNT = /^A[0-9]{9,17}$/;

// 正則, 員工帳號, 身分證 + 生日
export const RE_STAFF_ACCOUNT = /^A[0-9]{9,17}$/;

// 正則, 客戶(企業)帳號, 企業編碼 + 密碼
export const RE_CLIENT_ACCOUNT = /^[0-9]{8,}/;

// 正則, 只能輸入數字
export const RE_NUMBER = /^[0-9]{0,}$/;
export const RE_NUMBER_DECIMAL = /^[0-9]{0,}(.([0-9]){0,2})?$/;

// 正則, email, janey@gmail.com
export const RE_EMAIL = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;

// 正則, 只能輸入英文數字
export const RE_ALPHABET_NUMBER = /^[a-zA-Z0-9]{0,}$/;

// 路由分組
export const ROUTE_GROUPS = {
  // 公告
  announcement: {
    value: 'announcement',
    label: '公告',
    icon: ICONS.Announcement,
    children: []
  },

  // 人員管理
  user: {
    value: 'user',
    label: '人員管理',
    icon: ICONS.User,
    children: []
  },

  // 專案管理
  project: {
    value: 'project',
    label: '專案管理',
    icon: ICONS.Project,
    children: []
  },

  // 查檢表與問卷管理,
  form: {
    value: 'form',
    label: '查檢表與問卷管理',
    icon: ICONS.Form,
    children: []
  }
};

// 路由參數 key
export const ROUTE_PARAMS = {
  // 訪員 id
  interviewerId: 'interviewerId',
  // 訪員任務
  interviewerMissions: 'interviewerMissions',
  // 專案 id
  projectId: 'projectId',
  // 專案預算
  projectBudget: 'projectBudget',
  // 專案的檢查表
  projectChecklist: 'projectChecklist',
  // 案件詳情
  caseId: 'caseId',
  // 企業圖表
  clientId: 'clientId'
};

// 所有的路由
export const ROUTES = {
  // 登入
  login: {
    value: '',
    label: '登入'
  },

  // 系統公告
  announcement: {
    value: 'announcements',
    label: '系統公告',
    group: ROUTE_GROUPS.announcement.value
  },

  // 訪員管理
  interviewer: {
    value: 'interviewers',
    label: '訪員管理',
    group: ROUTE_GROUPS.user.value
  },

  // 訪員管理, 訪員詳情
  interviewerDetail: {
    value: `interviewers/:${ROUTE_PARAMS.interviewerId}`
  },

  // 員工管理
  staff: {
    value: 'staff',
    label: '員工管理',
    group: ROUTE_GROUPS.user.value
  },

  // 立案申請與管理
  proposal: {
    value: 'project-proposal',
    label: '立案申請與管理',
    group: ROUTE_GROUPS.project.value
  },

  // 立案申請與管理, 專案詳情
  // 僅用於 root route
  proposalDetail: {
    value: `project-proposal/:${ROUTE_PARAMS.projectId}`
  },

  // 派案與執行管理
  execute: {
    value: 'execute-project',
    label: '派案與執行管理',
    group: ROUTE_GROUPS.project.value
  },

  // 派案與執行管理, 專案(project)詳情
  // 僅用於 root route
  executeProjectDetail: {
    value: `execute-project/:${ROUTE_PARAMS.projectId}`
  },

  // 派案與執行管理, 案件(case)詳情
  // 僅用於 root route
  executeCaseDetail: {
    value: `execute-project/:${ROUTE_PARAMS.projectId}/case/:${ROUTE_PARAMS.caseId}`
  },

  // 結案管理
  close: {
    value: 'close-project',
    label: '結案管理',
    group: ROUTE_GROUPS.project.value
  },

  // 結案管理, 專案(project)詳情
  // 僅用於 root route
  closeProjectDetail: {
    value: `close-project/:${ROUTE_PARAMS.projectId}`
  },

  // 結案管理, 案件(case)詳情
  // 僅用於 root route
  closeCaseDetail: {
    value: `close-project/:${ROUTE_PARAMS.projectId}/case/:${ROUTE_PARAMS.caseId}`
  },

  // 專案請款與預算管理
  budget: {
    value: 'project-budget',
    label: '專案請款與預算管理',
    group: ROUTE_GROUPS.project.value
  },

  // 專案請款與預算管理, 預算詳情
  // 僅用於 root route
  budgetDetail: {
    value: `project-budget/:${ROUTE_PARAMS.projectId}`
  },

  // 專案查詢
  projectSearch: {
    value: 'search-project',
    label: '專案查詢',
    group: ROUTE_GROUPS.project.value
  },

  // 專案查詢, 專案詳情
  // 僅用於 root route
  projectSearchProjectDetail: {
    value: `search-project/:${ROUTE_PARAMS.projectId}`
  },

  // 專案查詢, 案件詳情
  projectSearchCaseDetail: {
    value: `search-project/:${ROUTE_PARAMS.projectId}/case/:${ROUTE_PARAMS.caseId}`
  },

  // 檢查表管理
  checklist: {
    value: 'check-list',
    label: '查檢表管理',
    group: ROUTE_GROUPS.form.value
  },

  // 檢查表管理, 檢查表詳情
  checklistDetail: {
    value: `check-list/:${ROUTE_PARAMS.projectId}`
  },

  // 訪客問卷管理
  questionnaire: {
    value: 'questionnaires',
    label: '訪客問卷管理',
    group: ROUTE_GROUPS.form.value
  },

  // 客戶管理
  client: {
    value: 'clients',
    label: '客戶管理',
    icon: ICONS.Client
  },

  // 客戶管理, 統計圖表
  clientDetail: {
    value: `clients/:${ROUTE_PARAMS.clientId}`
  },

  // 專案通知
  notification: {
    value: 'project-notification',
    label: '專案通知',
    icon: ICONS.Notification
  }
};

// 訪員 tab 分頁
// route search values
export const INTERVIEWER_TABS = {
  interviewerDetail: {
    value: 'detail',
    label: '個人資料'
  },
  interviewerMission: {
    value: 'missions',
    label: '任務一覽'
  }
};

// 專案狀態
// route search values
export const PROJECT_STATUS = {
  S1: {
    value: 'S1',
    label: '建立專案'
  },

  S2: {
    value: 'S2',
    label: '專案預估損益'
  },

  S3: {
    value: 'S3',
    label: '立案審核'
  },

  S4: {
    value: 'S4',
    label: '派案準備'
  },

  S5: {
    value: 'S5',
    label: '派案與執行'
  },

  S6: {
    value: 'S6',
    label: '申請專案結案'
  },

  S7: {
    value: 'S7',
    label: '填寫專案結案損益'
  },

  S8: {
    value: 'S8',
    label: '結案審核'
  },

  S9: {
    value: 'S9',
    label: '結案'
  },

  S10: {
    value: 'S10',
    label: '專案關閉'
  }
};

// route search keys
export const ROUTE_SEARCH_KEYS = {
  // tab page
  tabPage: 'tab-page',

  // 搜尋訪員
  searchInterviewer: 'search-interviewer',

  // 客戶搜尋
  searchClient: 'search-client',

  // 專案列表篩選, route 上一頁需要使用
  projectStatus: 'project-status',

  // 專案負責角色篩選, route 上一頁需要使用
  projectOwner: 'project-owner',

  // 派案作業是否顯示, 不一定會使用
  projectCaseShow: 'project-case-show',

  // 案件類型(派案作業的 tab 欄位), 不一定會使用
  projectCaseType: 'project-case-type',

  // 如果連頁數都要記錄下來...
  anchor: 'anchor'
};

// 帳號類型
export const USER_TYPES = {
  boss: {
    value: 'boss',
    label: '老闆'
  },

  operationsManagement: {
    value: 'operationsManagement',
    label: '營管部'
  },

  salesperson: {
    value: 'salesperson',
    label: '業務'
  },

  projectManager: {
    value: 'projectManager',
    label: 'PM'
  },

  company: {
    value: 'company',
    label: '企業'
  }
};

// 場地類型
export const SITE_TYPES = {
  onSite: {
    value: 'onSite',
    label: '現場'
  },

  offSite: {
    value: 'offSite',
    label: '非現場'
  }
};

// 查檢表狀態
export const CHECK_STATUS = {
  true: {
    value: 'true',
    label: '完成'
  },

  false: {
    value: 'false',
    label: '未完成'
  }
};

// 性別
export const GENDER_TYPES = {
  male: {
    value: 'male',
    label: '男'
  },
  female: {
    value: 'female',
    label: '女'
  },
  others: {
    value: 'others',
    label: '其他'
  }
};

// 婚姻
export const MARRIAGE_TYPES = {
  married: {
    value: 'married',
    label: '已婚'
  },
  unmarried: {
    value: 'unmarried',
    label: '未婚'
  },
  other: {
    value: 'other',
    label: '其他'
  }
};

// 有或沒有
export const WITH_OR_WITHOUT = {
  true: {
    value: true,
    label: '有'
  },
  false: {
    value: false,
    label: '無'
  }
};

// 有或沒有
export const YES_OR_NO = {
  true: {
    value: true,
    label: '是'
  },
  false: {
    value: false,
    label: '否'
  }
};

// 執案履歷, 評鑑
export const EVALUATORS = {
  service: {
    value: 'service',
    label: '臺灣服務業大評鑑'
  },
  customer: {
    value: 'customer',
    label: '臺灣客服中心評鑑'
  },
  consignment: {
    value: 'consignment',
    label: '房屋代銷安心評鑑'
  }
};

// 新增帳號
export const ACCOUNT_CREATE_TYPES = {
  // 訪員
  visitor: 'visitor',
  // 員工
  employee: 'employee',
  // 企業
  company: 'company'
};

// 匯入檔案的日期格式
export const IMPORT_DATE_FORMATS = [
  'YYYY-MM-DD',
  'YYYY-M-DD',
  'YYYY-MM-D',
  'YYYY-M-D',
  'YYYY/MM/DD',
  'YYYY/M/DD',
  'YYYY/MM/D',
  'YYYY/M/D'
];

// 通知的種類
export const BEHAVIORS = {
  projectDetail: 'projectDetail',
  profitDetail: 'profitDetail'
};
