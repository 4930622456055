import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction
} from 'mobx';
import { ROUTES, ROUTE_PARAMS, USER_TYPES, PROJECT_STATUS } from 'src/global/constants';
import ProjectServices from 'src/services/ProjectServices';
import CaseServices from 'src/services/CaseServices';
import ErrorServices from 'src/services/ErrorServices';
import { CASE_STATUS } from 'src/global/case';

class CaseDetailPageViewModel {
  @observable profile = null;
  @observable project = null;
  @observable case = null;
  @observable isAwait = false;

  projectId = null;
  caseId = null;

  router = null;

  @computed
  get routeParams() {
    const pathName = this.router.location.pathname;
    const backRoute = Object.values(ROUTES).filter((item) => item.value).filter((item) => {
      const index = pathName.indexOf(item.value);
      return index !== -1;
    })[0];
    const backLink = `/${backRoute.value}/${this.projectId}`;

    return {
      routes: [
        {
          link: `/${backRoute.value}`,
          label: backRoute.label
        },
        {
          link: backLink,
          label: this.project.projectName
        },
        {
          label: '案件詳情'
        }
      ],
      backLink
    };
  }

  @computed
  get checkParams() {
    const isBoss = this.profile.type === USER_TYPES.boss.value;
    const isOM = this.profile.type === USER_TYPES.operationsManagement.value;
    const isPM = this.profile.type === USER_TYPES.projectManager.value;
    const isProjectEnd = this.project?.step === PROJECT_STATUS.S9.value;

    return {
      isShow: !!this.project && !!this.case,
      isBoss,
      isHandler: isPM,
      isOM,
      isPM,
      isProjectEnd
    };
  }

  @computed
  get showFormList() {
    switch (this.case.step) {
      case CASE_STATUS.S7.value:
      case CASE_STATUS.S8.value:
      case CASE_STATUS.S9.value:
      case CASE_STATUS.S10.value:
      case CASE_STATUS.S11.value:
      case CASE_STATUS.S12.value:
        return true;

      default:
        return false;
    }
  }

  constructor(props) {
    this.profile = props.profile;
    this.router = props.router;

    this.projectId = props.router.params[ROUTE_PARAMS.projectId];
    this.caseId = props.router.params[ROUTE_PARAMS.caseId];

    makeObservable(this);
  }

  didMount = (props) => {
    this.getProjectDetailAPI();
    this.getCaseDetailAPI();
  };

  onStepChanged = () => {
    this.getCaseDetailAPI();
  }

  // 讀取專案詳情
  @action
  getProjectDetailAPI = async () => {
    try {
      const res = await ProjectServices.getProjectDetail({
        projectId: this.projectId
      });

      runInAction(() => {
        this.project = res.data;
      });
    } catch (error) {
      const message = ErrorServices.getProjectDetail(error);

      console.log('CaseDetailPageViewModel', 'getProjectDetail', message);
    }
  };

  // 讀取案件詳情
  @action
  getCaseDetailAPI = async () => {
    try {
      const res = await CaseServices.getCaseDetail({ caseId: this.caseId });

      runInAction(() => {
        // 元件需要 caseId
        this.case = { ...res.data, caseId: this.caseId };
      });
    } catch (error) {
      const message = ErrorServices.getCaseDetail(error);

      console.log('CaseDetailPageViewModel', 'getCaseDetail', message);
    }
  };
}

export default CaseDetailPageViewModel;
