import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Select, Input, Table, Button } from 'antd';
import { PROJECT_STATUS, USER_TYPES } from 'src/global/constants';
import withProfile from 'src/components/withProfile';
import withRouter from 'src/components/withRouter';
import CustomHeader from 'src/components/CustomHeader';
import FixedTable from 'src/components/FixedTable';
import CustomLink from 'src/components/CustomLink';
import CustomAnchor from 'src/components/CustomAnchor';
import AddButton from 'src/components/AddButton';
import CreateModal from './components/CreateModal';
import ProjectListPageViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class ProjectListPage extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new ProjectListPageViewModel({
      profile: props.profile,
      router: props.router
    });
  }

  componentDidMount() {
    this.vm.didMount(this.props);
  }

  render() {
    return (
      <div className={styles.container}>
        <CustomHeader title={this.vm.routeVM.title} paddingBottom />

        {!this.vm.isInit && (
          <>
            <div className={styles.filters}>
              <div className={styles.selects}>
                <Select
                  bordered={false}
                  options={this.vm.routeVM.statuses}
                  value={this.vm.searchStatus}
                  onChange={this.vm.onChangeStatus}
                  disabled={this.vm.isAwait}
                  dropdownMatchSelectWidth={false}
                />

                <Select
                  bordered={false}
                  options={this.vm.routeVM.owners}
                  value={this.vm.searchOwner}
                  onChange={this.vm.onChangeOwner}
                  disabled={this.vm.isAwait}
                  dropdownMatchSelectWidth={false}
                />
              </div>

              <div className={styles.space} />

              <div className={styles.search}>
                <Input.Search
                  placeholder="請輸入專案名稱"
                  onChange={this.vm.onKeywordChange}
                  onSearch={this.vm.onSearch}
                />
              </div>

              {this.vm.hasEdit && this.vm.routeVM.isProposal && (
                <AddButton
                  className={styles.addButton}
                  label="新增專案"
                  onClick={this.vm.createModalVM.open}
                  disabled={this.vm.isAwait}
                />
              )}
            </div>

            <FixedTable
              dataSource={this.vm.anchorVM.sliceData}
              scrollY
              labelHeight={55}
              flex
              hidden
              padding
            >
              <Table.Column
                width={100}
                title="操作"
                render={(item) => {
                  const isBudget = this.vm.routeVM.isBudget;
                  const route = this.vm.routeVM.route;
                  const label = isBudget ? '查看損益' : '查看詳情';
                  const link = `/${route.value}/${item.id}`;

                  return <CustomLink to={link}>{label}</CustomLink>;
                }}
              />

              <Table.Column
                width={250}
                title="專案名稱"
                dataIndex="projectName"
              />

              <Table.Column
                width={150}
                title="狀態"
                render={(item) => {
                  return PROJECT_STATUS[item.step].label;
                }}
              />

              <Table.Column
                width={100}
                title="負責角色"
                render={(item) => {
                  return USER_TYPES[item.handler].label;
                }}
              />

              {!this.vm.routeVM.isProposal && (
                <Table.Column width={100} title="主責PM" dataIndex="pmName" />
              )}

              <Table.Column
                width={250}
                title="企業名稱"
                render={(item) => {
                  return (
                    <Button
                      type="link"
                      size="small"
                      onClick={() =>
                        this.vm.onToClientClick({
                          clientId: item.companyId,
                          projectId: item.projectId
                        })}
                    >
                      {item.companyName}
                    </Button>
                  );
                }}
              />

              <Table.Column
                width={120}
                title="最後更新時間"
                dataIndex="updatedAtFormat"
              />
            </FixedTable>

            <CustomAnchor vm={this.vm.anchorVM} total />
          </>
        )}

        <CreateModal vm={this.vm.createModalVM} onCreated={this.vm.onCreated} />
      </div>
    );
  }
}

ProjectListPage.propTypes = {
  profile: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired
};

ProjectListPage.defaultProps = {};

export default withProfile(withRouter(ProjectListPage), true);
