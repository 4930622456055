class VisitorListItemDataModel {
  constructor(props) {
    this.isDisable = props.isDisable;

    this.accountId = props.accountId;

    this.nickName = props.nickName || null;

    this.name = props.name || null;

    this.gender = props.gender || null;

    this.phone = props.phone || null;

    this.email = props.email || null;

    this.lineId = props.lineId || null;

    this.address = props.address || null;

    this.deviceItem = props.deviceItem || null;

    this.practiceCity = props.practiceCity || null;

    this.tssaDate = props.tssaDate || null;

    this.sgsDate = props.sgsDate || null;

    this.consultantDate = props.consultantDate || null;

    this.visitorUpdated = props.visitorUpdated || false;
  }
}

export default VisitorListItemDataModel;
