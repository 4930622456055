import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { v4 } from 'uuid';
import { diff } from 'deep-diff';
import { ROUTE_PARAMS } from 'src/global/constants';
import { TOPIC_TYPES } from 'src/global/topic';
import CheckFormServices from 'src/services/CheckFormServices';
import ErrorServices from 'src/services/ErrorServices';

class InferiorFacetTabViewModel {
  @observable data = null;
  @observable isAwait = false;

  // 上層傳下來的
  @observable disabled = null;

  // 上層傳下來的
  site = null;
  facet = null;
  inferiorFacet = null;
  createDrawerVM = null;
  onCreated = null;

  projectId = null;

  router = null;

  constructor(props) {
    this.data = props.data;
    this.site = props.site;
    this.facet = props.facet;
    this.inferiorFacet = props.inferiorFacet;
    this.createDrawerVM = props.createDrawerVM;

    // 上層新增題目至 data vm 裡面
    this.onCreated = props.onCreated;

    this.projectId = props.router.params[ROUTE_PARAMS.projectId];

    makeObservable(this);
  }

  @action
  didUpdate = (props, preProps) => {
    const diffData = diff(props.data, preProps.data);

    if (diffData) {
      this.data = props.data;
    }
  };

  // 新增分數題
  onCreateFractionAnswer = () => {
    this.postCheckFormItemAPI(TOPIC_TYPES.fraction.value);
  };

  // 新增簡答題
  onCreateShortAnswer = () => {
    this.postCheckFormItemAPI(TOPIC_TYPES.shortAnswer.value);
  };

  // 從題庫選擇
  onOpenCreateDrawer = () => {
    this.createDrawerVM.open({
      site: this.site,
      facet: this.facet,
      inferiorFacet: this.inferiorFacet
    });
  };

  // 新增題目
  @action
  postCheckFormItemAPI = async (type) => {
    this.isAwait = true;

    try {
      const res = await CheckFormServices.postCheckFormItem({
        projectId: this.projectId,
        type,
        facet: this.facet,
        inferiorFacet: this.inferiorFacet,
        site: this.site
      });
      const data = {
        id: res.data.id,
        projectId: this.projectId,
        type,
        site: this.site,
        facet: this.facet,
        inferiorFacet: this.inferiorFacet
      };

      this.onCreated(data);
    } catch (error) {
      const message = ErrorServices.postCheckFormItem(error);

      console.log('InferiorFacetTabViewModel', 'postCheckFormItem', message);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  }
}

export default InferiorFacetTabViewModel;
