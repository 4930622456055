import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import Topic from '../Topic';
import styles from './styles.module.scss';

function InferiorFacetTab(props) {
  return (
    <div className={styles.container}>
      {props.data.map((vm) => {
        return (
          <Topic
            vm={vm}
            key={vm.itemId}
            edit={props.edit}
            disabled={props.disabled}
          />
        );
      })}
    </div>
  );
}

InferiorFacetTab.propTypes = {
  data: PropTypes.array.isRequired,
  edit: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired
};

InferiorFacetTab.defaultProps = {};

export default observer(InferiorFacetTab);
