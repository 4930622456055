import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Outlet } from 'react-router-dom';
import { Popover, Button } from 'antd';
import clsx from 'clsx';
import { AppStateContext, statusStore } from 'src/stores';
import withRouter from 'src/components/withRouter';
import userIcon from 'src/assets/user.svg';
import CustomMenu from './components/CustomMenu';
import PopoverContent from './components/PopoverContent';
import Loading from '../Loading';
import MainLayoutViewModel from './viewModel';
import styles from './styles.module.scss';
import './reset.scss';

@observer
class MainLayout extends React.Component {
  static contextType = AppStateContext;

  constructor(props) {
    super(props);
    this.vm = new MainLayoutViewModel({
      profile: props.profile,
      router: props.router
    });
  }

  componentDidMount() {
    this.vm.didMount({
      context: this.context
    });
  }

  componentDidUpdate(preProps) {
    this.vm.didUpdate(this.props, preProps);
  }

  render() {
    return (
      <>
        <div className={styles.container}>
          <div id="mainHeader" className={styles.header}>
            {this.vm.check.hasProfile && (
            <div className={styles.headerContent}>
              <div className={styles.title}>柯南國際-神秘客專案管理系統</div>

              <div className={styles.account}>
                {this.vm.check.hasProfile && (
                  <Popover
                    placement="bottomRight"
                    content={(
                      <PopoverContent
                        profile={this.vm.profile}
                        onLogout={this.vm.onLogout}
                      />
                    )}
                  >
                    <Button type="text" className={styles.popoverButton}>
                      <div className={styles.popoverIconContainer}>
                        <img
                          src={userIcon}
                          alt="user"
                          className={styles.icon}
                        />
                      </div>

                      <span>{this.vm.label.profileName}</span>
                    </Button>
                  </Popover>
                )}
              </div>
            </div>
            )}
          </div>

          <div className={styles.contentContainer}>
            {this.vm.check.hasProfile && this.vm.check.hasMenu && (
            <CustomMenu profile={this.vm.profile} />
            )}

            <div
              className={clsx([
                styles.mainContent,
                this.vm.check.hasMenu && styles.partContent,
                !this.vm.check.hasMenu && styles.fullContent
              ])}
            >
              <Outlet />
            </div>
          </div>
        </div>

        {statusStore.isLoading && <Loading />}
      </>
    );
  }
}

MainLayout.propTypes = {
  profile: PropTypes.object,
  router: PropTypes.object.isRequired
};

MainLayout.defaultProps = {
  profile: null
};

export default withRouter(MainLayout);
