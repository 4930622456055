import { makeObservable, observable, action, computed } from 'mobx';

class AnnouncementViewModel {
  @observable id= null;
  @observable title = null;
  @observable content = null;
  @observable path = null;

  @computed
  get padPath() {
    const rex1 = /^https:\/\//;
    let resPath = this.path;

    if (!rex1.test(resPath)) {
      resPath = `https://${resPath}`;
    }

    return resPath;
  }

  @computed
  get res() {
    return {
      id: this.id,
      title: this.title,
      content: JSON.stringify({ content: this.content, path: this.path })
    };
  }

  constructor(props) {
    this.id = props.id;
    this.title = props.title;
    this.content = props.content;
    this.path = props.path;

    makeObservable(this);
  }

  @action
  onChangeTitle = (event) => {
    const value = event.target.value;
    this.title = value;
  }

  @action
  onChangeContent = (event) => {
    const value = event.target.value;

    this.content = value;
  }

  @action
  onChangePath = (event) => {
    const value = event.target.value;

    this.path = value;
  }
}

export default AnnouncementViewModel;
