import { LIMIT } from 'src/global/constants';
import CheckFormAPIs from 'src/apis/CheckFormAPIs';
import CheckFormItemDataModel from 'src/models/response/CheckFormItemDataModel';
import CheckFormExportItemDataModel from 'src/models/response/CheckFormExportItemDataModel';
import CheckFormListDataModel from 'src/models/response/CheckFormListDataModel';
import CheckFormReplyDataModel from 'src/models/response/CheckFormReplyDataModel';
import CheckFormReplyListItemDataModel from 'src/models/response/CheckFormReplyListItemDataModel';
import CookieServices from './CookieServices';

// 查檢表
class CheckFormServices {
  // 讀取查檢表所有題目
  static async getCheckFormItem({ projectId }) {
    const res = await CheckFormAPIs.getCheckFormItem({
      token: CookieServices.getToken(),
      projectId
    });

    return {
      data: res.data.data.map((item) => new CheckFormItemDataModel(item)),
      onSiteAttached: JSON.parse(res.data.onSiteAttached),
      offSiteAttached: JSON.parse(res.data.offSiteAttached)
    };
  }

  // 新增查檢表題目
  static async postCheckFormItem({
    projectId,
    type,
    facet,
    inferiorFacet,
    topic,
    content,
    site
  }) {
    const res = await CheckFormAPIs.postCheckFormItem({
      token: CookieServices.getToken(),
      projectId,
      type,
      facet,
      inferiorFacet,
      topic,
      content,
      site
    });

    return {
      data: {
        id: res.data.itemId
      }
    };
  }

  // 更新查檢表題目
  static async putCheckFormItem({
    id,
    type,
    implementation,
    implementWeighted,
    satisfaction,
    satisWeighted,
    topic,
    content
  }) {
    const res = await CheckFormAPIs.putCheckFormItem({
      token: CookieServices.getToken(),
      id,
      type,
      implementation,
      implementWeighted,
      satisfaction,
      satisWeighted,
      topic,
      content
    });

    return {
      data: res.data
    };
  }

  // 刪除查檢表題目
  static async deleteCheckFormItem({ id }) {
    const res = await CheckFormAPIs.deleteCheckFormItem({
      token: CookieServices.getToken(),
      id
    });

    return {
      data: res.data
    };
  }

  // 查檢表完成
  static async putCheckFormCompleted({ projectId }) {
    const res = await CheckFormAPIs.putCheckFormCompleted({
      token: CookieServices.getToken(),
      projectId
    });

    return {
      data: res.data
    };
  }

  // 更新雲端連結
  static async putCheckFormAttached({
    projectId,
    onSiteAttached,
    offSiteAttached
  }) {
    const res = await CheckFormAPIs.putCheckFormAttached({
      token: CookieServices.getToken(),
      projectId,
      onSiteAttached,
      offSiteAttached
    });

    return {
      data: res.data
    };
  }

  // 會出查檢表結果
  static async getCheckFormExport({ projectId }) {
    const res = await CheckFormAPIs.getCheckFormExport({
      token: CookieServices.getToken(),
      projectId
    });

    return {
      data: res.data.data.checkFormItem.map((item) => {
        return new CheckFormExportItemDataModel(item);
      })
    };
  }

  // 讀取查檢表列表
  static async getCheckFormList({ anchor, projectStep, completed, keyword }) {
    const res = await CheckFormAPIs.getCheckFormList({
      token: CookieServices.getToken(),
      limit: LIMIT,
      anchor,
      projectStep,
      completed,
      keyword
    });

    return {
      data: res.data.data.map((item) => new CheckFormListDataModel(item)),
      anchor: res.data.anchor
    };
  }

  // 複製其他專案的查檢表
  static async postCheckFormItemImport({ projectId, sourceProjectId }) {
    const res = await CheckFormAPIs.postCheckFormItemImport({
      token: CookieServices.getToken(),
      projectId,
      sourceProjectId
    });

    return {
      data: res.data
    };
  }

  // 讀取查檢表的答案
  static async getCheckFormReply({ caseId }) {
    const res = await CheckFormAPIs.getCheckFormReply({
      token: CookieServices.getToken(),
      caseId
    });

    return {
      data: new CheckFormReplyDataModel(res.data.data),
      auditStartTime: res.data.auditStartTime,
      auditEndTime: res.data.auditEndTime
    };
  }

  // 修改查檢表答案
  static async postCheckFormReplyAdmin({ caseId, visitorId, reply }) {
    const res = await CheckFormAPIs.postCheckFormReplyAdmin({
      token: CookieServices.getToken(),
      caseId,
      visitorId,
      reply
    });

    return {
      data: res.data
    };
  }

  // 客戶統計使用的查檢表列表
  static async getCheckFormReplyList({ projectId, anchor, limit = LIMIT }) {
    const res = await CheckFormAPIs.getCheckFormReplyList({
      token: CookieServices.getToken(),
      projectId,
      anchor,
      limit
    });

    return {
      data: res.data.data.map(
        (item) => new CheckFormReplyListItemDataModel(item)
      ),
      anchor: res.data.anchor || null
    };
  }
}

export default CheckFormServices;
