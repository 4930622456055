import React from 'react';
import PropTypes from 'prop-types';
import { observer, Observer } from 'mobx-react';
import { Table, DatePicker, Button } from 'antd';
import ICONS from 'src/global/icons';
import CustomDrawer from 'src/components/CustomDrawer';
import CustomTable from 'src/components/CustomTable';
import AddButton from 'src/components/AddButton';
import styles from './styles.module.scss';

@observer
class DateDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({ onChanged: props.onChanged });
  }

  render() {
    return (
      <CustomDrawer
        vm={this.props.vm.drawerVM}
        title="設定日期區段"
        width={720}
        hasFixedTitle
      >
        <div className={styles.mainContent}>
          <div className={styles.createButtonContainer}>
            <AddButton label="日期區段" ghost onClick={this.vm.onCreate} />
          </div>

          <CustomTable data={this.vm.dates} hasOverflow>
            <Table.Column
              width={70}
              title="序號"
              render={(_, __, i) => {
                const index = Math.floor(i + 1);

                return String(index);
              }}
            />

            <Table.Column
              title="日期區段"
              render={(vm) => {
                return (
                  <Observer>
                    {() => {
                      return (
                        <DatePicker.RangePicker
                          value={vm.dates}
                          onChange={vm.onChangeDates}
                          disabledDate={this.vm.disabledDate}
                        />
                      );
                    }}
                  </Observer>
                );
              }}
            />

            <Table.Column
              width={70}
              render={(vm) => {
                return (
                  <Button
                    className={styles.deleteButton}
                    type="link"
                    size="small"
                    onClick={() => {
                      this.vm.onDelete(vm.id);
                    }}
                  >
                    <ICONS.Delete />
                  </Button>
                );
              }}
            />
          </CustomTable>

          <div className={styles.operateButtons}>
            <Button
              className={styles.cancelButton}
              onClick={this.vm.drawerVM.hide}
            >
              取消
            </Button>

            <Button type="primary" onClick={this.vm.onSave}>
              儲存
            </Button>
          </div>
        </div>
      </CustomDrawer>
    );
  }
}

DateDrawer.propTypes = {
  vm: PropTypes.object.isRequired,
  onChanged: PropTypes.func.isRequired
};

DateDrawer.defaultProps = {};

export default DateDrawer;
