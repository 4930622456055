import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import theme from 'src/global/theme';
import { ROUTES } from 'src/global/constants';
import { IS_DEV, DEV_ROUTES } from 'src/global/dev';
import { AppStateContext } from 'src/stores';
import locale from 'antd/locale/zh_TW';
import MainLayout from 'src/components/MainLayout';
import AnnouncementPage from './AnnouncementPage';
import InterviewerPage from './InterviewerPage';
import InterviewerDetailPage from './InterviewerDetailPage';
import StaffPage from './StaffPage';
import ProjectListPage from './ProjectListPage';
import ProjectDetailPage from './ProjectDetailPage';
import CaseDetailPage from './CaseDetailPage';
import BudgetDetailPage from './BudgetDetailPage';
import ChecklistPage from './ChecklistPage';
import CheckDetailPage from './CheckDetailPage';
import QuestionnairePage from './QuestionnairePage';
import ClientPage from './ClientPage';
import ClientDetailPage from './ClientDetailPage';
import NotificationPage from './NotificationPage';
import NotFoundPage from './NotFoundPage';
import LoginPage from './LoginPage';
import ReplyPage from './ReplyPage';

class PagesRoot extends React.Component {
  static contextType = AppStateContext;

  // 專案相關的頁面
  renderProjectManagement = () => {
    return (
      <>
        {/* 立案申請與管理 */}
        <Route
          path={ROUTES.proposal.value}
          element={(
            <ProjectListPage
              key={ROUTES.proposal.value}
              route={ROUTES.proposal.value}
            />
          )}
        />

        {/* 立案申請與管理, 專案詳情 */}
        <Route
          path={ROUTES.proposalDetail.value}
          element={(
            <ProjectDetailPage
              key={ROUTES.proposalDetail.value}
              route={ROUTES.proposalDetail.value}
            />
          )}
        />

        {/* 派案與執行管理 */}
        <Route
          path={ROUTES.execute.value}
          element={(
            <ProjectListPage
              key={ROUTES.execute.value}
              route={ROUTES.execute.value}
            />
          )}
        />

        {/* 派案與執行管理, 專案詳情 */}
        <Route
          path={ROUTES.executeProjectDetail.value}
          element={(
            <ProjectDetailPage
              key={ROUTES.executeProjectDetail.value}
              route={ROUTES.executeProjectDetail.value}
            />
          )}
        />

        {/* 派案與執行管理, 案件詳情 */}
        <Route
          path={ROUTES.executeCaseDetail.value}
          element={(
            <CaseDetailPage
              key={ROUTES.executeCaseDetail.value}
              route={ROUTES.executeCaseDetail.value}
            />
          )}
        />

        {/* 結案管理 */}
        <Route
          path={ROUTES.close.value}
          element={(
            <ProjectListPage
              key={ROUTES.close.value}
              route={ROUTES.close.value}
            />
          )}
        />

        {/* 結案管理, 專案詳情 */}
        <Route
          path={ROUTES.closeProjectDetail.value}
          element={(
            <ProjectDetailPage
              key={ROUTES.closeProjectDetail.value}
              route={ROUTES.closeProjectDetail.value}
            />
          )}
        />

        {/* 結案管理, 案件詳情 */}
        <Route
          path={ROUTES.closeCaseDetail.value}
          element={(
            <CaseDetailPage
              key={ROUTES.closeCaseDetail.value}
              route={ROUTES.closeCaseDetail.value}
            />
          )}
        />

        {/* 專案請款與預算管理 */}
        <Route
          path={ROUTES.budget.value}
          element={(
            <ProjectListPage
              key={ROUTES.budget.value}
              route={ROUTES.budget.value}
            />
          )}
        />

        {/* 專案請款與預算管理, 預算詳情 */}
        <Route
          path={ROUTES.budgetDetail.value}
          element={(
            <BudgetDetailPage
              key={ROUTES.budgetDetail.value}
              route={ROUTES.budgetDetail.value}
            />
          )}
        />

        {/* 專案查詢 */}
        <Route
          path={ROUTES.projectSearch.value}
          element={(
            <ProjectListPage
              key={ROUTES.projectSearch.value}
              route={ROUTES.projectSearch.value}
            />
          )}
        />

        {/* 專案查詢, 專案詳情 */}
        <Route
          path={ROUTES.projectSearchProjectDetail.value}
          element={(
            <ProjectDetailPage
              key={ROUTES.projectSearchProjectDetail.value}
              route={ROUTES.projectSearchProjectDetail.value}
            />
          )}
        />

        {/* 專案查詢, 案件詳情 */}
        <Route
          path={ROUTES.projectSearchCaseDetail.value}
          element={(
            <CaseDetailPage
              key={ROUTES.projectSearchCaseDetail.value}
              route={ROUTES.projectSearchCaseDetail.value}
            />
          )}
        />
      </>
    );
  };

  render() {
    return (
      <ConfigProvider theme={theme} locale={locale}>
        <BrowserRouter basename="/">
          <Routes>
            <Route
              element={<MainLayout profile={this.context.state.profile} />}
            >
              {/* 公告 */}
              <Route
                path={ROUTES.announcement.value}
                element={(
                  <AnnouncementPage
                    key={ROUTES.announcement.value}
                    route={ROUTES.announcement.value}
                  />
                )}
              />

              {/* 訪員管理 */}
              <Route
                path={ROUTES.interviewer.value}
                element={(
                  <InterviewerPage
                    key={ROUTES.interviewer.value}
                    route={ROUTES.interviewer.value}
                  />
                )}
              />

              {/* 訪員管理, 訪員詳情 */}
              <Route
                path={ROUTES.interviewerDetail.value}
                element={(
                  <InterviewerDetailPage
                    key={ROUTES.interviewerDetail.value}
                    route={ROUTES.interviewerDetail.value}
                  />
                )}
              />

              {/* 員工管理 */}
              <Route
                path={ROUTES.staff.value}
                element={(
                  <StaffPage
                    key={ROUTES.staff.value}
                    route={ROUTES.staff.value}
                  />
                )}
              />

              {/* 專案管理 */}
              {this.renderProjectManagement()}

              {/* 查檢表管理 */}
              <Route
                path={ROUTES.checklist.value}
                element={(
                  <ChecklistPage
                    key={ROUTES.checklist.value}
                    route={ROUTES.checklist.value}
                  />
                )}
              />

              {/* 查檢表管理, 查檢表詳情 */}
              <Route
                path={ROUTES.checklistDetail.value}
                element={(
                  <CheckDetailPage
                    key={ROUTES.checklistDetail.value}
                    route={ROUTES.checklistDetail.value}
                  />
                )}
              />

              {/* 訪問卷管理 */}
              <Route
                path={ROUTES.questionnaire.value}
                element={(
                  <QuestionnairePage
                    key={ROUTES.questionnaire.value}
                    route={ROUTES.questionnaire.value}
                  />
                )}
              />

              {/* 客戶管理 */}
              <Route
                path={ROUTES.client.value}
                element={(
                  <ClientPage
                    key={ROUTES.client.value}
                    route={ROUTES.client.value}
                  />
                )}
              />

              {/* 客戶管理 */}
              <Route
                path={ROUTES.clientDetail.value}
                element={(
                  <ClientDetailPage
                    key={ROUTES.clientDetail.value}
                    route={ROUTES.clientDetail.value}
                  />
                )}
              />

              {/* 專案通知 */}
              <Route
                path={ROUTES.notification.value}
                element={(
                  <NotificationPage
                    key={ROUTES.notification.value}
                    route={ROUTES.notification.value}
                  />
                )}
              />
            </Route>

            {/* 測試使用 */}
            {IS_DEV && (
              <Route
                path={DEV_ROUTES.reply.value}
                element={(
                  <ReplyPage
                    key={DEV_ROUTES.reply.value}
                    route={DEV_ROUTES.reply.value}
                  />
                )}
              />
            )}

            <Route path="" element={<LoginPage context={this.context} />} />

            <Route path="*" element={<NotFoundPage context={this.context} />} />
          </Routes>
        </BrowserRouter>
      </ConfigProvider>
    );
  }
}

export default PagesRoot;
