import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Input, Button } from 'antd';
import withRouter from 'src/components/withRouter';
import ICONS from 'src/global/icons';
import DescTableViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class DescTable extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new DescTableViewModel({
      router: props.router,
      checkParams: props.checkParams,
      project: props.project,
      onProjectNameChanged: props.onProjectNameChanged
    });
  }

  componentDidUpdate(preProps) {
    this.vm.didUpdate(this.props, preProps);
  }

  renderNameLabel = () => {
    return (
      <div className={styles.item}>
        {this.vm.label.projectName}

        {this.vm.hasEdit && (
          <Button
            type="text"
            size="small"
            className={styles.button}
            onClick={this.vm.onEdit}
            disabled={this.vm.isAwait}
          >
            <ICONS.Edit className={styles.icon} />
          </Button>
        )}
      </div>
    );
  };

  renderNameEdit = () => {
    return (
      <div className={styles.item}>
        <Input
          size="small"
          value={this.vm.projectName}
          onChange={this.vm.onChangeProjectName}
          onPressEnter={this.vm.onSave}
          disabled={this.vm.isAwait}
          maxLength={50}
        />

        <Button
          type="text"
          size="small"
          className={styles.button}
          onClick={this.vm.onSave}
          disabled={this.vm.isAwait}
        >
          <ICONS.Save className={styles.icon} />
        </Button>
      </div>
    );
  };

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.labels}>
          <div className={styles.item}>專案名稱</div>

          <div className={styles.item}>狀態</div>

          <div className={styles.item}>負責角色</div>

          <div className={styles.item}>企業名稱</div>
        </div>

        <div className={styles.contents}>
          {!this.vm.isEdit && this.renderNameLabel()}

          {this.vm.isEdit && this.renderNameEdit()}

          <div className={styles.item}>{this.vm.label.projectStep}</div>

          <div className={styles.item}>{this.vm.label.projectHandler}</div>

          <div className={styles.item}>
            <Button type="link" size="small" onClick={this.vm.onToClientClick}>
              {this.vm.label.projectCompanyName}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

DescTable.propTypes = {
  router: PropTypes.object.isRequired,
  checkParams: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  onProjectNameChanged: PropTypes.func.isRequired
};

DescTable.defaultProps = {};

export default withRouter(DescTable);
