class CheckFormReplyDataModel {
  constructor(props) {
    this.projectId = String(props.projectId);

    this.createdAt = props.createdAt;

    this.updatedAt = props.updatedAt;

    this.completed = props.completed;

    this.checkFormItem = props.checkFormItem.map((item) => {
      return {
        id: String(item.id),
        projectId: String(item.projectId),

        // 構面
        facet: item.facet,
        inferiorFacet: item.inferiorFacet,

        // 場地
        site: item.site,

        // 題目類型
        type: item.type,

        // 題目
        topic: item.topic,

        // 題目說明
        content: item.content,

        // 落實度類型
        implementation:
          item.implementation === null ? null : String(item.implementation),
        // 落實度最高分數
        implementWeighted:
          item.implementWeighted === null
            ? null
            : String(item.implementWeighted),

        // 滿意度類型
        satisfaction:
          item.satisfaction === null ? null : String(item.satisfaction),
        // 滿意度最高分數
        satisWeighted:
          item.satisWeighted === null ? null : String(item.satisWeighted),

        // 答案
        checkFormReply: item.checkFormReply.map((rItem) => {
          return {
            id: String(rItem.id),
            caseId: String(rItem.caseId),
            visitorId: String(rItem.visitorId),
            // 答案 id?
            itemId: String(rItem.itemId),
            // 落實度分數
            implementScore:
              rItem.implementScore === null
                ? null
                : String(rItem.implementScore),
            // 滿意度分數
            satisScore:
              rItem.satisScore === null ? null : String(rItem.satisScore),
            // 訪員答案
            content: rItem.content
          };
        })
      };
    });
  }
}

export default CheckFormReplyDataModel;
