import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Form, Select, Tag } from 'antd';
import styles from './styles.module.scss';

// 多選的 Select
function FormMultiple(props) {
  const onFilterOption = useCallback((value, item) => {
    const search = value.trim();
    const find = item.label.indexOf(search);

    return search ? find !== -1 : true;
  }, []);

  return props.isEdit ? (
    <Form.Item
      name={props.name}
      rules={props.required}
      className={styles.formItem}
    >
      <Select
        size="small"
        mode="multiple"
        options={props.options}
        dropdownMatchSelectWidth={false}
        showArrow
        filterOption={onFilterOption}
      />
    </Form.Item>
  ) : (
    <div className={styles.tags}>
      {props.label?.map((item) => {
        const key = item[props.labelKey];
        const label = props.const[key].label;

        return (
          <Tag className={styles.tag} color="red" key={label}>
            {label}
          </Tag>
        );
      })}
    </div>
  );
}

FormMultiple.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  // form 的 key
  name: PropTypes.string.isRequired,
  // 常數 object
  const: PropTypes.object.isRequired,
  // select 的選項
  options: PropTypes.array.isRequired,
  // api 的返回值
  label: PropTypes.array,
  // api 返回 array 包物件, 需要的 key
  labelKey: PropTypes.string.isRequired,
  // 是否必填
  required: PropTypes.array
};

FormMultiple.defaultProps = {
  label: null,
  required: []
};

export default observer(FormMultiple);
