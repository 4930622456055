import React from 'react';
import PropTypes from 'prop-types';
import { Observer, observer } from 'mobx-react';
import { Select, Input, Table, Button, Divider } from 'antd';
import { ROUTES } from 'src/global/constants';
import withProfile from 'src/components/withProfile';
import withRouter from 'src/components/withRouter';
import CustomHeader from 'src/components/CustomHeader';
import FixedTable from 'src/components/FixedTable';
import CustomLink from 'src/components/CustomLink';
import CustomAnchor from 'src/components/CustomAnchor';
import ChecklistPageViewModel from './viewModel';
import styles from './styles.module.scss';

// 查檢表管理
@observer
class ChecklistPage extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new ChecklistPageViewModel({ router: props.router });
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return (
      <div className={styles.container}>
        <CustomHeader title="查檢表管理" paddingBottom />

        {!this.vm.isInit && (
          <>
            <div className={styles.operateContainer}>
              <div className={styles.filters}>
                <Select
                  className={styles.select}
                  bordered={false}
                  dropdownMatchSelectWidth={false}
                  options={this.vm.options.checkFormStatus}
                  value={this.vm.searchCheckStatus}
                  onChange={this.vm.onChangeSearchCheckStatus}
                  disabled={this.vm.isAwait}
                />

                <Select
                  className={styles.select}
                  bordered={false}
                  dropdownMatchSelectWidth={false}
                  options={this.vm.options.projectStatus}
                  value={this.vm.searchProjectStatus}
                  onChange={this.vm.onChangeSearchProjectStatus}
                  disabled={this.vm.isAwait}
                />
              </div>

              <div className={styles.searchContainer}>
                <Input.Search
                  value={this.vm.search}
                  onChange={this.vm.onChangeSearch}
                  onSearch={this.vm.onSearch}
                  disabled={this.vm.isAwait}
                  placeholder="請輸入專案名稱"
                  maxLength={50}
                />
              </div>
            </div>

            <FixedTable
              dataSource={this.vm.anchorVM.sliceData}
              scrollY
              labelHeight={55}
              flex
              hidden
              padding
            >
              <Table.Column
                width={100}
                title="專案名稱"
                dataIndex="projectName"
              />

              <Table.Column
                width={100}
                title="查檢表狀態"
                render={(item) => (item.checkFormCompleted ? '完成' : '未完成')}
              />

              <Table.Column width={100} title="專案狀態" dataIndex="stepName" />

              <Table.Column
                width={100}
                title="企業名稱"
                render={(item) => {
                  return (
                    <Button
                      type="link"
                      size="small"
                      onClick={() =>
                        this.vm.onToClientClick({
                          clientId: item.companyId,
                          projectId: item.projectId
                        })}
                    >
                      {item.companyName}
                    </Button>
                  );
                }}
              />

              <Table.Column
                width={100}
                title="最後更新時間"
                dataIndex="checkFormUpdateAtFormat"
              />

              <Table.Column
                width={100}
                title="操作"
                render={(item) => {
                  return (
                    <Observer>
                      {() => {
                        const route = `/${ROUTES.checklist.value}/${item.projectId}`;
                        const disabledExport
                          = this.vm.isAwait || !item.checkFormCompleted;

                        return (
                          <>
                            <CustomLink to={route}>查看查檢表</CustomLink>

                            <Divider type="vertical" />

                            <Button
                              type="link"
                              className={styles.exportButton}
                              onClick={() => this.vm.onExport(item)}
                              disabled={disabledExport}
                            >
                              匯出
                            </Button>
                          </>
                        );
                      }}
                    </Observer>
                  );
                }}
              />
            </FixedTable>

            <CustomAnchor vm={this.vm.anchorVM} />
          </>
        )}
      </div>
    );
  }
}

ChecklistPage.propTypes = {
  router: PropTypes.object.isRequired
};

ChecklistPage.defaultProps = {};

export default withProfile(withRouter(ChecklistPage));
