import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { PROJECT_STATUS, ROUTES, ROUTE_PARAMS } from 'src/global/constants';
import ProjectServices from 'src/services/ProjectServices';
import ErrorServices from 'src/services/ErrorServices';
import CustomAnchorViewModel from 'src/components/CustomAnchor/viewModel';
import ProjectViewModel from './viewModels/ProjectViewModel';

class QuestionnairePageViewModel {
  @observable data = null;
  @observable searchProjectStep = null;
  @observable search = null;
  @observable editId = null;
  @observable isInit = true;
  @observable isAwait = false;

  anchorVM = null;

  router = null;

  @computed
  get searches() {
    const keyword = this.search?.trim() ? this.search : undefined;

    return {
      steps: this.searchProjectStep?.split(','),
      keyword
    };
  }

  @computed
  get options() {
    const steps = [
      PROJECT_STATUS.S4,
      PROJECT_STATUS.S5,
      PROJECT_STATUS.S6,
      PROJECT_STATUS.S7,
      PROJECT_STATUS.S8,
      PROJECT_STATUS.S9
    ];
    const defaultProjectStep = {
      value: steps.map((item) => item.value).join(','),
      label: '專案狀態選擇'
    };

    return {
      projectSteps: [defaultProjectStep, ...steps]
    };
  }

  @computed
  get check() {
    return {
      hasEditId: !!this.editId
    };
  }

  @computed
  get tableData() {
    return this.anchorVM.sliceData.map((item) => new ProjectViewModel(item));
  }

  constructor(props) {
    this.router = props.router;
    this.anchorVM = new CustomAnchorViewModel({ api: this.postProjectListAPI });
    makeObservable(this);
  }

  @action
  init = () => {
    this.searchProjectStep = this.options.projectSteps[0].value;
  }

  didMount = () => {
    if (!this.isAwait) {
      this.init();
      this.anchorVM.reset();
    }
  };

  checkEdit = (id) => {
    return this.editId === id;
  };

  @action
  setEditId = (id) => {
    this.editId = id;
  };

  @action
  onChangeSearchProjectStep = (value) => {
    this.searchProjectStep = value;

    this.anchorVM.reset();
  };

  @action
  onChangeSearch = (event) => {
    this.search = event.target.value;
  };

  onSearch = () => {
    this.anchorVM.reset();
  };

  onSave = (vm) => {
    this.postProjectUpdateQuestAPI(vm);
  };

  onToClientClick = ({ projectId, clientId }) => {
    const route = `/${ROUTES.client.value}/${clientId}`;
    const state = {};

    state[ROUTE_PARAMS.projectId] = projectId;

    this.router.navigate(route, { state });
  }

  @action
  postProjectListAPI = async ({ anchor }) => {
    this.isAwait = true;

    const res = await ProjectServices.postProjectList({
      anchor,
      steps: this.searches.steps,
      keyword: this.searches.keyword
    });

    runInAction(() => {
      this.isInit = false;
      this.isAwait = false;
    });

    return res;
  };

  // 更新專案訪客問卷
  @action
  postProjectUpdateQuestAPI = async (vm) => {
    this.isAwait = true;

    try {
      const res = await ProjectServices.postProjectUpdateQuest({
        projectId: vm.id,
        questPath: vm.questPath
      });

      runInAction(() => {
        vm.onUpdated();

        this.editId = null;
      });
    } catch (error) {
      const msg = ErrorServices.postProjectUpdateQuest(error);

      console.log('QuestionnairePageViewModel', 'postProjectUpdateQuest', msg);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default QuestionnairePageViewModel;
