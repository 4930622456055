import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import withProfile from 'src/components/withProfile';
import withRouter from 'src/components/withRouter';
import CustomBreadcrumb from 'src/components/CustomBreadcrumb';
import CustomHeader from 'src/components/CustomHeader';
import FormList from 'src/components/ListItem';
import CheckItem from './components/CheckItem';
import RemarkItem from './components/RemarkItem';
import RejectItem from './components/RejectItem';
import DescTable from './components/DescTable';
import CaseHistory from './components/CaseHistory';
import PushButton from './components/PushButton';
import RejectForm from './components/RejectForm';
import UploadPathItem from './components/UploadPathItem';
import CaseDetailPageViewModel from './viewModel';
import styles from './styles.module.scss';
import VisitorReplyMsg from './components/VisitorReplyMsg';

// 案件詳情
@observer
class CaseDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new CaseDetailPageViewModel({
      profile: props.profile,
      router: props.router
    });
  }

  componentDidMount() {
    this.vm.didMount(this.props);
  }

  render() {
    return this.vm.checkParams.isShow ? (
      <div className={styles.container}>
        <CustomBreadcrumb routes={this.vm.routeParams.routes} />

        <CustomHeader
          title="案件詳情"
          hasBack
          link={this.vm.routeParams.backLink}
        />

        <div className={styles.content}>
          <DescTable case={this.vm.case} />

          <CaseHistory
            case={this.vm.case}
            checkParams={this.vm.checkParams}
            onChanged={this.vm.onStepChanged}
          />

          {this.vm.showFormList && (
            <div className={styles.listContainer}>
              <FormList title="表單列表">
                <CheckItem
                  project={this.vm.project}
                  case={this.vm.case}
                  checkParams={this.vm.checkParams}
                />

                <UploadPathItem
                  case={this.vm.case}
                  checkParams={this.vm.checkParams}
                />

                <RemarkItem
                  case={this.vm.case}
                  checkParams={this.vm.checkParams}
                />
                <VisitorReplyMsg
                  case={this.vm.case}
                  checkParams={this.vm.checkParams}
                />
              </FormList>
            </div>
          )}

          {/* 退回原因 or 失敗原因 */}
          <RejectItem case={this.vm.case} checkParams={this.vm.checkParams} />

          <PushButton
            case={this.vm.case}
            checkParams={this.vm.checkParams}
            onChanged={this.vm.onStepChanged}
          />

          <RejectForm
            case={this.vm.case}
            checkParams={this.vm.checkParams}
            onChanged={this.vm.onStepChanged}
          />
        </div>
      </div>
    ) : null;
  }
}

CaseDetailPage.propTypes = {
  profile: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired
};

CaseDetailPage.defaultProps = {};

export default withProfile(withRouter(CaseDetailPage), true);
