import React from 'react';
import PropTypes from 'prop-types';
import { makeObservable, observable, computed } from 'mobx';
import { observer, Observer } from 'mobx-react';
import { Table, Button, Divider } from 'antd';
import { USER_TYPES } from 'src/global/constants';
import clsx from 'clsx';
import withRouter from 'src/components/withRouter';
import CustomTable from 'src/components/CustomTable';
import ReasonModal from '../ReasonModal';
import ReasonModalViewModel from '../ReasonModal/viewModel';
import styles from './styles.module.scss';
import ApplyModal from '../ApplyModal';

@observer
class ApplyTable extends React.Component {
  @observable profile = null;
  modalVM = new ReasonModalViewModel();

  @computed
  get check() {
    return {
      isBoss: this.profile.type === USER_TYPES.boss.value
    };
  }

  constructor(props) {
    super(props);
    this.profile = props.profile;
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.title}>專案預估損益申請修改紀錄</div>

        <CustomTable data={this.props.data}>
          <Table.Column
            title="狀態"
            render={(vm) => {
              return (
                <Observer>
                  {() => {
                    return vm.statusLabel;
                  }}
                </Observer>
              );
            }}
          />

          <Table.Column
            title="原因"
            render={(vm) => {
              return <div className={styles.reason}>{vm.reason}</div>;
            }}
          />

          <Table.Column title="申請人" dataIndex="owner" />

          <Table.Column title="申請時間" dataIndex="createDateLabel" />

          <Table.Column
            width={100}
            title="操作"
            render={(vm) => {
              return (
                <Observer>
                  {() => {
                    // 是老闆 + 申請中
                    const hasEdit = this.check.isBoss && vm.check.isApply;

                    return (
                      <div className={styles.operateButtons}>
                        {hasEdit && (
                          <Button
                            type="primary"
                            className={styles.marginRight}
                            onClick={vm.onAgree}
                            disabled={vm.isAwait}
                          >
                            同意
                          </Button>
                        )}

                        {hasEdit && (
                          <Button
                            type="link"
                            className={clsx([
                              styles.marginRight,
                              styles.rejectButton
                            ])}
                            onClick={vm.onReject}
                            disabled={vm.isAwait}
                          >
                            拒絕
                          </Button>
                        )}

                        <Button
                          onClick={() => {
                            vm.status == 'apply'
                              ? this.modalVM.applyModelViewModel.open({
                                reason: vm.reason,
                                eventId: vm.id
                              })
                              : this.modalVM.open(vm.reason);
                          }}
                        >
                          {vm.status == 'apply' ? '編輯留言' : '查看詳情'}
                        </Button>
                      </div>
                    );
                  }}
                </Observer>
              );
            }}
          />
        </CustomTable>

        <Divider />

        {/* 僅能查看原因的彈窗 */}
        <ReasonModal vm={this.modalVM} />
        <ApplyModal
          vm={this.modalVM.applyModelViewModel}
          editMode
          onConfirmed={this.props.reload}
          disabled={this.props.disabled}
        />
      </div>
    );
  }
}

ApplyTable.propTypes = {
  profile: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  router: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired
};

ApplyTable.defaultProps = {};

export default withRouter(ApplyTable);
