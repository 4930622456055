import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

function StyleItem(props) {
  return (
    <div className={styles.item}>
      <div className={styles.content}>{props.children}</div>
    </div>
  );
}

StyleItem.propTypes = {
  children: PropTypes.node
};

StyleItem.defaultProps = {
  children: null
};

export default StyleItem;
