import React from 'react';
import PropTypes from 'prop-types';
import { USER_TYPES } from 'src/global/constants';
import clsx from 'clsx';
import styles from './styles.module.scss';

const getLabelSize = (value) => {
  switch (value) {
    case 'small':
      return styles.small;

    case 'middle':
      return styles.middle;

    default:
      return undefined;
  }
};

const getIconColor = (value) => {
  switch (value) {
    case USER_TYPES.salesperson.value:
      return styles.saIcon;

    case USER_TYPES.projectManager.value:
      return styles.pmIcon;

    case USER_TYPES.operationsManagement.value:
      return styles.omIcon;

    default:
      return undefined;
  }
};

function FormatCell(props) {
  const labelStyle = getLabelSize(props.labelSize);
  const iconStyle = getIconColor(props.role);
  const hasRole = !!props.role;

  return (
    <div
      className={clsx([
        'formatCell',
        props.offsetBorderLeft && styles.offsetBorderLeft,
        styles.container
      ])}
    >
      <div className={clsx([styles.labelContainer, labelStyle])}>
        {hasRole && <div className={clsx([styles.icon, iconStyle])} />}

        {props.required && <div className={styles.required}>*</div>}

        <div className={styles.label}>{props.label}</div>
      </div>

      <div className={styles.contentContainer}>
        <div className={styles.content}>{props.children}</div>
      </div>
    </div>
  );
}

FormatCell.propTypes = {
  label: PropTypes.string,
  role: PropTypes.string,
  required: PropTypes.bool,
  labelSize: PropTypes.string,
  children: PropTypes.node,
  offsetBorderLeft: PropTypes.bool
};

FormatCell.defaultProps = {
  label: '標題',
  role: '',
  required: false,
  labelSize: 'middle',
  children: null,
  offsetBorderLeft: false
};

export default FormatCell;
