import React from 'react';
import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { diff } from 'deep-diff';
import { USER_TYPES, ROUTES, ROUTE_GROUPS } from 'src/global/constants';
import { getRoutes, routerMethods } from 'src/global/methods';
import MessageServices from 'src/services/MessageServices';
import ErrorServices from 'src/services/ErrorServices';
import MenuIcon from './components/MenuIcon';

const checkNotificationTime = 600000;

class CustomMenuViewModel {
  @observable profile = null;
  @observable itemKey = [];
  @observable groupKeys = [];

  // 檢查通知
  @observable hasUnreadNotification = false;
  @observable notifications = [];
  timer = null;

  // 頁面跳轉
  router = null;

  @computed
  get check() {
    const isClient = this.profile.type === USER_TYPES.company.value;

    return {
      hasNotifications: !isClient
    };
  }

  @computed
  get menu() {
    const type = this.profile?.type;
    const routes = getRoutes(type).filter((item) => item.value);

    // 不同帳號登入可能會導致常數錯誤, 需要使用複製的 route group
    const groups = this.cloneRouteGroup();

    // 有分組的 route
    routes
      .filter((item) => !!item.group)
      .forEach((bItem) => {
        const children = groups[bItem.group].children;
        const find = children.find((item) => item.value === bItem.value);

        // react 18 會執行兩次, 防止常數 array 內有相同的 route
        if (!find) {
          children.push(bItem);
        }
      });

    // 沒有分組, 但是有 label 的孤兒
    const aloneRoutes = routes.filter((item) => !item.group && !!item.label);

    const res = Object.values(groups)
      .concat(aloneRoutes)
      .filter((item) => {
        return !(item.children && item.children.length === 0);
      })
      .map((bItem) => {
        const isActive
          = bItem.value === ROUTES.notification.value
            ? this.hasUnreadNotification
            : false;

        return {
          key: bItem.value,
          label: bItem.label,
          icon: (
            <MenuIcon isActive={isActive}>
              {React.createElement(bItem.icon)}
            </MenuIcon>
          ),
          children: bItem.children?.map((item) => {
            return {
              key: item.value,
              label: item.label
            };
          })
        };
      });

    return res;
  }

  constructor(props) {
    this.init(props);
    makeObservable(this);
  }

  @action
  init = (props) => {
    const route = routerMethods.getMainRoute(props.router.location.pathname);

    this.profile = props.profile;
    this.router = props.router;

    this.setRoute(route);
    this.setGroup(route);
  };

  didMount = () => {
    if (this.check.hasNotifications) {
      this.firstCheckNotification();
    }
  };

  willUnmount = () => {
    this.timer = window.clearInterval(this.timer);
  };

  // 檢查 route
  didUpdate = (props, preProps) => {
    this.checkRoute(props, preProps);
  };

  checkRoute = (props, preProps) => {
    const route = routerMethods.getMainRoute(props.router.location.pathname);
    const preRoute = routerMethods.getMainRoute(
      preProps.router.location.pathname
    );
    const diffFlag = diff(route, preRoute);

    if (diffFlag) {
      this.setRoute(route);
      this.setGroup(route);
    }
  };

  // 複製 ROUTE_GROUPS
  // 因為有 ant icon 所以不能使用 parse stringify 複製
  cloneRouteGroup = () => {
    const res = {};

    Object.values(ROUTE_GROUPS).forEach((item) => {
      res[item.value] = {
        value: item.value,
        label: item.label,
        icon: item.icon,
        children: []
      };
    });

    return res;
  };

  // 設定 itemKey
  @action
  setRoute = (route) => {
    this.itemKey = [route];
  };

  // 依照 route 打開 menu 分組
  @action
  setGroup = (route) => {
    // 找 route 資料
    const routeItem = Object.values(ROUTES).find((item) => {
      return item.value === route;
    });
    // route 的分組
    const routeGroup = routeItem.group;
    // route 的分組 有沒有在展開的 menu array 內
    const findGroup = this.groupKeys.find((key) => {
      return ROUTE_GROUPS[key].value === routeGroup;
    });

    if (!!routeGroup && !findGroup) {
      this.groupKeys.push(routeGroup);
    }
  };

  // 點擊 menu 分組
  @action
  onClickGroup = (openKeys) => {
    this.groupKeys = openKeys;
  };

  // 點擊 menu 項目
  @action
  onClickItem = ({ item, key, keyPath, domEvent }) => {
    this.router.navigate(`/${key}`);

    if (key === ROUTES.notification.value) {
      this.hasUnreadNotification = false;
    }
  };

  // 檢查通知

  checkNotificationRoute = () => {
    const pathname = window.location.pathname;
    const check = pathname.includes(ROUTES.notification.value);

    return check;
  };

  firstCheckNotification = async () => {
    const checkRoute = this.checkNotificationRoute();
    const ns = await this.getAllNotification();

    runInAction(() => {
      const findUnread = ns.find((item) => !item.isRead);

      if (!checkRoute) {
        if (findUnread) {
          this.hasUnreadNotification = true;
        } else {
          this.hasUnreadNotification = false;
        }
      }

      this.notifications = ns;

      this.timer = window.clearInterval(this.timer);
      this.timer = window.setInterval(
        this.alwaysCheckNotification,
        checkNotificationTime
      );

      console.log('CustomMenu', '第一次', '檢查通知');
    });
  };

  alwaysCheckNotification = async () => {
    const checkRoute = this.checkNotificationRoute();

    // 在通知頁不檢查
    if (!checkRoute && !this.hasUnreadNotification) {
      const res = await this.getMessageListAPI();

      runInAction(() => {
        const oldFistId = this.notifications[0]?.id;
        const newFistId = res.data[0]?.id;

        if (oldFistId && newFistId && oldFistId !== newFistId) {
          this.hasUnreadNotification = true;
          this.notifications = res.data;
        } else if (!oldFistId && newFistId) {
          this.hasUnreadNotification = true;
          this.notifications = res.data;
        } else {
          this.hasUnreadNotification = false;
        }

        console.log(
          'CustomMenu',
          '持續檢查通知',
          'oldFistId',
          oldFistId,
          'newFistId',
          newFistId
        );
      });
    }
  };

  // 所有通知
  getAllNotification = async (ns = [], anchor = undefined) => {
    const res = await this.getMessageListAPI(anchor);
    const newNs = ns.slice();

    newNs.push(...res.data);

    if (res.anchor) {
      const anchorNs = await this.getAllNotification(ns, res.anchor);
      newNs.push(...anchorNs);
    }

    return newNs;
  };

  @action
  getMessageListAPI = async (anchor) => {
    try {
      const res = await MessageServices.getMessageList({ anchor });

      return res;
    } catch (error) {
      const msg = ErrorServices.getMessageList(error);

      console.log('getMessageListAPI', msg);

      return {
        data: [],
        anchor: null
      };
    }
  };
}

export default CustomMenuViewModel;
