import { request, getHost } from './utils';

class UserAPIs {
  // 登入
  static postUserLogin({ account, password }) {
    const options = {
      method: 'post',

      url: `${getHost()}/v1/account/login`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache'
      },

      data: {
        account,
        password
      }
    };

    return request(options);
  }

  // 讀取自己的資料
  static getProfile(token) {
    const options = {
      method: 'get',

      url: `${getHost()}/v1/account/profile`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      }
    };

    return request(options);
  }

  // 停用和啟用帳號
  static putAccountDisable({ token, id, disable }) {
    const options = {
      method: 'put',

      url: `${getHost()}/v1/account/disable/${id}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      data: {
        disable
      }
    };

    return request(options);
  }

  // 讀取指定帳號資料
  static getAccountProfile({ token, id }) {
    const options = {
      method: 'get',

      url: `${getHost()}/v1/account/profile/${id}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      }
    };

    return request(options);
  }

  // 新增帳號
  static postAccountCreate({ token, type, info }) {
    const options = {
      method: 'post',

      url: `${getHost()}/v1/account/create`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      data: {
        type, info
      }
    };

    return request(options);
  }

  static putUserCaseCustomerViewable({ token, projectId, caseId, viewable }) {
    const options = {
      method: 'put',

      url: `${getHost()}/v1/user/case/customer/viewable/${projectId}/${caseId}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      params: {
        viewable
      }
    };

    return request(options);
  }

  static putUserCaseCustomerViewableByIds({ token, projectId, viewable, ids }) {
    const options = {
      method: 'put',
      url: `${getHost()}/v1/user/case/customer/viewable/${projectId}`,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },
      params: {
        viewable
      },
      data: {
        ids
      }
    };

    return request(options);
  }
}

export default UserAPIs;
