import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Popover } from 'antd';
import './reset.scss';

function CustomPopover(props) {
  const { children, ...ps } = props;

  return (
    <div id="customPopover">
      <Popover {...ps} getContainer={false}>
        {props.children}
      </Popover>
    </div>
  );
}

CustomPopover.propTypes = {
  children: PropTypes.node
};

CustomPopover.defaultProps = {
  children: null
};

export default observer(CustomPopover);
