import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import { USER_TYPES } from 'src/global/constants';
import styles from './styles.module.scss';

function PopoverContent(props) {
  return (
    <div className={styles.container}>
      <div className={styles.detail}>
        <div>{props.profile.name}</div>
        <div>{props.profile.account}</div>
        <div>{USER_TYPES[props.profile.type].label}</div>
      </div>

      <Button
        className={styles.button}
        type="primary"
        onClick={props.onLogout}
      >
        <span className={styles.logOutText}>登出</span>
      </Button>
    </div>
  );
}

PopoverContent.propTypes = {
  profile: PropTypes.object.isRequired,
  onLogout: PropTypes.func.isRequired
};

PopoverContent.defaultProps = {
};

export default PopoverContent;
