import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import CustomDrawerViewModel from 'src/components/CustomDrawer/viewModel';
import CheckFormServices from 'src/services/CheckFormServices';
import ErrorServices from 'src/services/ErrorServices';
import localTopics from 'src/global/topics';

class CreateTopicDrawerViewModel {
  @observable data = [];
  @observable select = null;
  @observable search = null;
  @observable createId = null;
  @observable isAwait = false;

  drawerVM = new CustomDrawerViewModel();

  // vm init
  onCreated = null;

  // props 傳入
  projectId = null;

  // open 傳入
  site = null;
  facet = null;
  inferiorFacet = null;

  @computed
  get selectData() {
    if (this.select) {
      return this.data.filter((item) => {
        const checkClass1 = item.class1 === this.select[0];
        const checkClass2 = item.class2 === this.select[1];
        const checkClass3 = item.class3 === this.select[2];
        const checkClass4 = item.class4 === this.select[3];
        const checkClass5 = item.class5 === this.select[4];

        return checkClass1 && checkClass2 && checkClass3 && checkClass4 && checkClass5;
      });
    }

    return this.data;
  }

  @computed
  get searchData() {
    const checkSearch = this.search?.trim();

    if (checkSearch) {
      return this.selectData.filter((item) => {
        const findIndex = item.topic.indexOf(checkSearch);

        return findIndex !== -1;
      });
    }

    return this.selectData;
  }

  @computed
  get disabledCreate() {
    return this.createId === null || this.isAwait;
  }

  @computed
  get antCascader() {
    const res = [];

    this.data.forEach((item) => {
      const findClass1 = res.find((class1) => class1.value === item.class1);
      const findClass2 = findClass1?.children.find(
        (class2) => class2.value === item.class2
      );
      const findClass3 = findClass2?.children.find(
        (class3) => class3.value === item.class3
      );
      const findClass4 = findClass3?.children.find(
        (class4) => class4.value === item.class4
      );
      const findClass5 = findClass4?.children.find(
        (class5) => class5.value === item.class5
      );

      const class5 = {
        value: item.class5,
        label: item.class5
      };
      const class4 = {
        value: item.class4,
        label: item.class4,
        children: [class5]
      };
      const class3 = {
        value: item.class3,
        label: item.class3,
        children: [class4]
      };
      const class2 = {
        value: item.class2,
        label: item.class2,
        children: [class3]
      };
      const class1 = {
        value: item.class1,
        label: item.class1,
        children: [class2]
      };

      if (!findClass1) {
        res.push(class1);
      } else if (!findClass2) {
        findClass1.children.push(class2);
      } else if (!findClass3) {
        findClass2.children.push(class3);
      } else if (!findClass4) {
        findClass3.children.push(class4);
      } else if (!findClass5) {
        findClass4.children.push(class5);
      }
    });

    return res;
  }

  @computed
  get label() {
    const length = this.searchData.length;

    return {
      total: `${length} 筆資料`
    };
  }

  constructor(props) {
    this.data = localTopics;
    this.onCreated = props.onCreated;

    console.log('localTopics', localTopics);

    makeObservable(this);
  }

  init = ({ projectId }) => {
    this.projectId = projectId;
  };

  open = ({ site, facet, inferiorFacet }) => {
    this.site = site;
    this.facet = facet;
    this.inferiorFacet = inferiorFacet;

    this.drawerVM.show();
  };

  @action
  onChangeChecked = (event, id) => {
    const checked = event.target.checked;

    if (checked) {
      this.createId = id;
    } else {
      this.createId = null;
    }
  };

  @action
  onChangeSearch = (event) => {
    this.search = event.target.value;
  };

  @action
  onChangeSelect = (value) => {
    this.select = value;

    console.log('select', value);
  };

  onSearch = () => {};

  onCreate = () => {
    this.postCreateTopicAPI();
  };

  @action
  postCreateTopicAPI = async () => {
    this.isAwait = true;

    try {
      const target = this.data.find((item) => item.id === this.createId);
      const data = {
        site: this.site,
        facet: this.facet,
        inferiorFacet: this.inferiorFacet,
        type: target.type,
        topic: target.topic,
        content: target.content
      };
      const res = await CheckFormServices.postCheckFormItem({
        ...data,
        projectId: this.projectId
      });

      runInAction(() => {
        const topic = { ...data, id: res.data.id };

        this.createId = null;
        this.onCreated(topic);
      });
    } catch (error) {
      const msg = ErrorServices.postCheckFormItem(error);

      console.log('CreateTopicDrawerViewModel', msg);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default CreateTopicDrawerViewModel;
