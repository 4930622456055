class AnnouncementDataModel {
  constructor(props) {
    const mainContent = JSON.parse(props.content);

    this.id = props.id;

    this.title = props.title;

    this.content = mainContent.content;
    this.path = mainContent.path;

    this.updatedAt = props.updatedAt;

    this.createdAt = props.createdAt;
  }
}

export default AnnouncementDataModel;
