// 查檢表問題的類型
export const TOPIC_TYPES = {
  fraction: {
    value: 'fraction',
    label: '分數題'
  },

  shortAnswer: {
    value: 'shortAnswer',
    label: '簡答題'
  },

  bonus: {
    value: 'bonus',
    label: '加分題'
  }
};

// 查檢表問題的構面
export const TOPIC_FACETS = {
  basic: {
    value: 'basic',
    label: '基本服務'
  },
  professional: {
    value: 'professional',
    label: '專業服務'
  },
  friendlyService: {
    value: 'friendlyService',
    label: '友善服務'
  },
  hospitalityService: {
    value: 'hospitalityService',
    label: '公平待客'
  },
  hardwareObserve: {
    value: 'hardwareObserve',
    label: '環境設施'
  },
  complaintResponse: {
    value: 'complaintResponse',
    label: '客訴處理'
  },
  emergency: {
    value: 'emergency',
    label: '應變服務'
  },
  observe: {
    value: 'observe',
    label: '魅反觀察'
  },
  extraObserve: {
    value: 'extraObserve',
    label: '額外觀察'
  },
  websiteObserve: {
    value: 'websiteObserve',
    label: '官網觀察'
  },
  phoneInteraction: {
    value: 'phoneInteraction',
    label: '電話服務'
  },
  mailComplaint: {
    value: 'mailComplaint',
    label: '網路留言信箱'
  },
  app: {
    value: 'app',
    label: 'APP'
  },
  smartCustomerService: {
    value: 'smartCustomerService',
    label: '智能客服'
  },
  videoChatCustomerService: {
    value: 'videoChatCustomerService',
    label: '視訊客服'
  },
  textCustomerService: {
    value: 'textCustomerService',
    label: '文字客服'
  },
  other: {
    value: 'other',
    label: '其他'
  }
};

// 查檢表問題的次構面
export const TOPIC_INFERIOR_FACETS = {
  welcomeService: {
    value: 'welcomeService',
    label: '迎賓服務'
  },
  deportment: {
    value: 'deportment',
    label: '服裝儀容'
  },
  polite: {
    value: 'polite',
    label: '應對禮儀'
  },
  visitorOut: {
    value: 'visitorOut',
    label: '送客服務'
  },
  digitalGuided: {
    value: 'digitalGuided',
    label: '數位引導'
  },
  productIntroduce: {
    value: 'productIntroduce',
    label: '產品介紹'
  },
  checkoutService: {
    value: 'checkoutService',
    label: '結帳服務'
  },
  afterSalesService: {
    value: 'afterSalesService',
    label: '售後服務'
  },
  empathy: {
    value: 'empathy',
    label: '同理展現,致歉同理,展現同理'
  },
  customerCare: {
    value: 'customerCare',
    label: '關懷顧客'
  },
  memberPromotion: {
    value: 'memberPromotion',
    label: '會員推廣'
  },
  guided: {
    value: 'guided',
    label: '導覽帶看'
  },
  environment: {
    value: 'environment',
    label: '環境設施,環境整潔'
  },
  serviceMechanism: {
    value: 'serviceMechanism',
    label: '服務機制'
  },
  friendlyFacilities: {
    value: 'friendlyFacilities',
    label: '友善設施'
  },
  friendlyService: {
    value: 'friendlyService',
    label: '友善服務,貼心服務'
  },
  legal: {
    value: 'legal',
    label: '法規符合'
  },
  facilityOperations: {
    value: 'facilityOperations',
    label: '設施運作'
  },
  product: {
    value: 'product',
    label: '商品展示,商品銷售'
  },
  space: {
    value: 'space',
    label: '空間氛圍'
  },
  contactInformation: {
    value: 'contactInformation',
    label: '聯絡資訊'
  },
  complaintResponse: {
    value: 'complaintResponse',
    label: '問題解決'
  },
  charmService: {
    value: 'charmService',
    label: '魅力服務'
  },
  reverseService: {
    value: 'reverseService',
    label: '反轉服務'
  },
  servicePioneer: {
    value: 'servicePioneer',
    label: '服務尖兵'
  },
  soundAndEmojis: {
    value: 'soundAndEmojis',
    label: '聲音表情'
  },
  phoneAnswering: {
    value: 'phoneAnswering',
    label: '電話接聽'
  },
  phoneClosing: {
    value: 'phoneClosing',
    label: '電話收尾'
  },
  demandExploration: {
    value: 'demandExploration',
    label: '需求探詢'
  },
  voiceSystem: {
    value: 'voiceSystem',
    label: '語音系統'
  },
  userInterface: {
    value: 'userInterface',
    label: '使用介面,介面外觀'
  },
  useFunction: {
    value: 'useFunction',
    label: '功能運作'
  },
  processingEfficiency: {
    value: 'processingEfficiency',
    label: '處理時效'
  },
  ansQuestions: {
    value: 'ansQuestions',
    label: '資訊處理'
  },
  complaintChannel: {
    value: 'complaintChannel',
    label: '客訴管道'
  },
  contactEtiquette: {
    value: 'contactEtiquette',
    label: '接聽禮儀'
  },
  tone: {
    value: 'tone',
    label: '口氣語速'
  },
  responseContent: {
    value: 'responseContent',
    label: '應對內容,回應內容'
  },
  thoughtful: {
    value: 'thoughtful',
    label: '體驗感受'
  },
  serviceAttitude: {
    value: 'serviceAttitude',
    label: '態度感受,應對態度'
  },
  wording: {
    value: 'wording',
    label: '用字遣詞'
  },
  other: {
    value: 'other',
    label: '其他'
  },
  other1: {
    value: 'other1',
    label: '其他一'
  },
  other2: {
    value: 'other2',
    label: '其他二'
  },
  other3: {
    value: 'other3',
    label: '其他三'
  },
  other4: {
    value: 'other4',
    label: '其他四'
  }
};

// 現場的問題構面格式
export const ON_SITE_TOPIC_FORMAT = {
  basic: {
    value: TOPIC_FACETS.basic.value,
    label: TOPIC_FACETS.basic.label,
    inferiorFacets: {
      welcomeService: {
        value: TOPIC_INFERIOR_FACETS.welcomeService.value,
        label: TOPIC_INFERIOR_FACETS.welcomeService.label,
        topics: []
      },

      deportment: {
        value: TOPIC_INFERIOR_FACETS.deportment.value,
        label: TOPIC_INFERIOR_FACETS.deportment.label,
        topics: []
      },

      polite: {
        value: TOPIC_INFERIOR_FACETS.polite.value,
        label: TOPIC_INFERIOR_FACETS.polite.label,
        topics: []
      },

      visitorOut: {
        value: TOPIC_INFERIOR_FACETS.visitorOut.value,
        label: TOPIC_INFERIOR_FACETS.visitorOut.label,
        topics: []
      },

      digitalGuided: {
        value: TOPIC_INFERIOR_FACETS.digitalGuided.value,
        label: TOPIC_INFERIOR_FACETS.digitalGuided.label,
        topics: []
      },

      other: {
        value: TOPIC_INFERIOR_FACETS.other.value,
        label: TOPIC_INFERIOR_FACETS.other.label,
        topics: []
      }
    }
  },

  professional: {
    value: TOPIC_FACETS.professional.value,
    label: TOPIC_FACETS.professional.label,
    inferiorFacets: {
      productIntroduce: {
        value: TOPIC_INFERIOR_FACETS.productIntroduce.value,
        label: TOPIC_INFERIOR_FACETS.productIntroduce.label,
        topics: []
      },

      checkoutService: {
        value: TOPIC_INFERIOR_FACETS.checkoutService.value,
        label: TOPIC_INFERIOR_FACETS.checkoutService.label,
        topics: []
      },

      afterSalesService: {
        value: TOPIC_INFERIOR_FACETS.afterSalesService.value,
        label: TOPIC_INFERIOR_FACETS.afterSalesService.label,
        topics: []
      },

      product: {
        value: TOPIC_INFERIOR_FACETS.product.value,
        label: '商品銷售',
        topics: []
      },

      customerCare: {
        value: TOPIC_INFERIOR_FACETS.customerCare.value,
        label: TOPIC_INFERIOR_FACETS.customerCare.label,
        topics: []
      },

      memberPromotion: {
        value: TOPIC_INFERIOR_FACETS.memberPromotion.value,
        label: TOPIC_INFERIOR_FACETS.memberPromotion.label,
        topics: []
      },

      friendlyService: {
        value: TOPIC_INFERIOR_FACETS.friendlyService.value,
        label: '貼心服務',
        topics: []
      },

      guided: {
        value: TOPIC_INFERIOR_FACETS.guided.value,
        label: TOPIC_INFERIOR_FACETS.guided.label,
        topics: []
      },

      other: {
        value: TOPIC_INFERIOR_FACETS.other.value,
        label: TOPIC_INFERIOR_FACETS.other.label,
        topics: []
      }
    }
  },

  friendlyService: {
    value: TOPIC_FACETS.friendlyService.value,
    label: TOPIC_FACETS.friendlyService.label,
    inferiorFacets: {
      environment: {
        value: TOPIC_INFERIOR_FACETS.environment.value,
        label: '環境設施',
        topics: []
      },

      serviceMechanism: {
        value: TOPIC_INFERIOR_FACETS.serviceMechanism.value,
        label: TOPIC_INFERIOR_FACETS.serviceMechanism.label,
        topics: []
      },

      serviceAttitude: {
        value: TOPIC_INFERIOR_FACETS.serviceAttitude.value,
        label: '應對態度',
        topics: []
      },

      empathy: {
        value: TOPIC_INFERIOR_FACETS.empathy.value,
        label: '同理展現',
        topics: []
      },

      other: {
        value: TOPIC_INFERIOR_FACETS.other.value,
        label: TOPIC_INFERIOR_FACETS.other.label,
        topics: []
      }
    }
  },

  hospitalityService: {
    value: TOPIC_FACETS.hospitalityService.value,
    label: TOPIC_FACETS.hospitalityService.label,
    inferiorFacets: {
      friendlyFacilities: {
        value: TOPIC_INFERIOR_FACETS.friendlyFacilities.value,
        label: TOPIC_INFERIOR_FACETS.friendlyFacilities.label,
        topics: []
      },

      friendlyService: {
        value: TOPIC_INFERIOR_FACETS.friendlyService.value,
        label: '友善服務',
        topics: []
      },

      legal: {
        value: TOPIC_INFERIOR_FACETS.legal.value,
        label: TOPIC_INFERIOR_FACETS.legal.label,
        topics: []
      },

      other: {
        value: TOPIC_INFERIOR_FACETS.other.value,
        label: TOPIC_INFERIOR_FACETS.other.label,
        topics: []
      }
    }
  },

  hardwareObserve: {
    value: TOPIC_FACETS.hardwareObserve.value,
    label: TOPIC_FACETS.hardwareObserve.label,
    inferiorFacets: {
      facilityOperations: {
        value: TOPIC_INFERIOR_FACETS.facilityOperations.value,
        label: TOPIC_INFERIOR_FACETS.facilityOperations.label,
        topics: []
      },

      product: {
        value: TOPIC_INFERIOR_FACETS.product.value,
        label: '商品展示',
        topics: []
      },

      environment: {
        value: TOPIC_INFERIOR_FACETS.environment.value,
        label: '環境整潔',
        topics: []
      },

      space: {
        value: TOPIC_INFERIOR_FACETS.space.value,
        label: TOPIC_INFERIOR_FACETS.space.label,
        topics: []
      },

      other: {
        value: TOPIC_INFERIOR_FACETS.other.value,
        label: TOPIC_INFERIOR_FACETS.other.label,
        topics: []
      }
    }
  },

  complaintResponse: {
    value: TOPIC_FACETS.complaintResponse.value,
    label: TOPIC_FACETS.complaintResponse.label,
    inferiorFacets: {
      empathy: {
        value: TOPIC_INFERIOR_FACETS.empathy.value,
        label: '致歉同理',
        topics: []
      },

      serviceAttitude: {
        value: TOPIC_INFERIOR_FACETS.serviceAttitude.value,
        label: '應對態度',
        topics: []
      },

      contactInformation: {
        value: TOPIC_INFERIOR_FACETS.contactInformation.value,
        label: TOPIC_INFERIOR_FACETS.contactInformation.label,
        topics: []
      },


      complaintResponse: {
        value: TOPIC_INFERIOR_FACETS.complaintResponse.value,
        label: TOPIC_INFERIOR_FACETS.complaintResponse.label,
        topics: []
      }
    }
  },

  emergency: {
    value: TOPIC_FACETS.emergency.value,
    label: TOPIC_FACETS.emergency.label,
    inferiorFacets: {
      serviceAttitude: {
        value: TOPIC_INFERIOR_FACETS.serviceAttitude.value,
        label: '應對態度',
        topics: []
      },

      empathy: {
        value: TOPIC_INFERIOR_FACETS.empathy.value,
        label: '展現同理',
        topics: []
      },

      complaintResponse: {
        value: TOPIC_INFERIOR_FACETS.complaintResponse.value,
        label: TOPIC_INFERIOR_FACETS.complaintResponse.label,
        topics: []
      },

      other: {
        value: TOPIC_INFERIOR_FACETS.other.value,
        label: TOPIC_INFERIOR_FACETS.other.label,
        topics: []
      }
    }
  },

  observe: {
    value: TOPIC_FACETS.observe.value,
    label: TOPIC_FACETS.observe.label,
    inferiorFacets: {
      charmService: {
        value: TOPIC_INFERIOR_FACETS.charmService.value,
        label: TOPIC_INFERIOR_FACETS.charmService.label,
        topics: []
      },

      reverseService: {
        value: TOPIC_INFERIOR_FACETS.reverseService.value,
        label: TOPIC_INFERIOR_FACETS.reverseService.label,
        topics: []
      },

      servicePioneer: {
        value: TOPIC_INFERIOR_FACETS.servicePioneer.value,
        label: TOPIC_INFERIOR_FACETS.servicePioneer.label,
        topics: []
      },

      other: {
        value: TOPIC_INFERIOR_FACETS.other.value,
        label: TOPIC_INFERIOR_FACETS.other.label,
        topics: []
      }
    }
  },

  extraObserve: {
    value: TOPIC_FACETS.extraObserve.value,
    label: TOPIC_FACETS.extraObserve.label,
    inferiorFacets: {
      other1: {
        value: TOPIC_INFERIOR_FACETS.other1.value,
        label: TOPIC_INFERIOR_FACETS.other1.label,
        topics: []
      },

      other2: {
        value: TOPIC_INFERIOR_FACETS.other2.value,
        label: TOPIC_INFERIOR_FACETS.other2.label,
        topics: []
      },

      other3: {
        value: TOPIC_INFERIOR_FACETS.other3.value,
        label: TOPIC_INFERIOR_FACETS.other3.label,
        topics: []
      },

      other4: {
        value: TOPIC_INFERIOR_FACETS.other4.value,
        label: TOPIC_INFERIOR_FACETS.other4.label,
        topics: []
      }
    }
  }
};

// 非現場的問題構面格式
export const OFF_SITE_TOPIC_FORMAT = {
  basic: {
    value: TOPIC_FACETS.basic.value,
    label: TOPIC_FACETS.basic.label,
    inferiorFacets: {
      polite: {
        value: TOPIC_INFERIOR_FACETS.polite.value,
        label: TOPIC_INFERIOR_FACETS.polite.label,
        topics: []
      },

      soundAndEmojis: {
        value: TOPIC_INFERIOR_FACETS.soundAndEmojis.value,
        label: TOPIC_INFERIOR_FACETS.soundAndEmojis.label,
        topics: []
      },

      phoneAnswering: {
        value: TOPIC_INFERIOR_FACETS.phoneAnswering.value,
        label: TOPIC_INFERIOR_FACETS.phoneAnswering.label,
        topics: []
      },

      phoneClosing: {
        value: TOPIC_INFERIOR_FACETS.phoneClosing.value,
        label: TOPIC_INFERIOR_FACETS.phoneClosing.label,
        topics: []
      }
    }
  },

  professional: {
    value: TOPIC_FACETS.professional.value,
    label: TOPIC_FACETS.professional.label,
    inferiorFacets: {
      demandExploration: {
        value: TOPIC_INFERIOR_FACETS.demandExploration.value,
        label: TOPIC_INFERIOR_FACETS.demandExploration.label,
        topics: []
      }
    }
  },

  hardwareObserve: {
    value: TOPIC_FACETS.hardwareObserve.value,
    label: TOPIC_FACETS.hardwareObserve.label,
    inferiorFacets: {
      voiceSystem: {
        value: TOPIC_INFERIOR_FACETS.voiceSystem.value,
        label: TOPIC_INFERIOR_FACETS.voiceSystem.label,
        topics: []
      }
    }
  },

  complaintResponse: {
    value: TOPIC_FACETS.complaintResponse.value,
    label: TOPIC_FACETS.complaintResponse.label,
    inferiorFacets: {
      empathy: {
        value: TOPIC_INFERIOR_FACETS.empathy.value,
        label: '致歉同理',
        topics: []
      },

      serviceAttitude: {
        value: TOPIC_INFERIOR_FACETS.serviceAttitude.value,
        label: '應對態度',
        topics: []
      },

      contactInformation: {
        value: TOPIC_INFERIOR_FACETS.contactInformation.value,
        label: TOPIC_INFERIOR_FACETS.contactInformation.label,
        topics: []
      },

      complaintResponse: {
        value: TOPIC_INFERIOR_FACETS.complaintResponse.value,
        label: TOPIC_INFERIOR_FACETS.complaintResponse.label,
        topics: []
      }
    }
  },

  emergency: {
    value: TOPIC_FACETS.emergency.value,
    label: TOPIC_FACETS.emergency.label,
    inferiorFacets: {
      serviceAttitude: {
        value: TOPIC_INFERIOR_FACETS.serviceAttitude.value,
        label: '應對態度',
        topics: []
      },

      empathy: {
        value: TOPIC_INFERIOR_FACETS.empathy.value,
        label: '展現同理',
        topics: []
      },

      complaintResponse: {
        value: TOPIC_INFERIOR_FACETS.complaintResponse.value,
        label: TOPIC_INFERIOR_FACETS.complaintResponse.label,
        topics: []
      },

      other: {
        value: TOPIC_INFERIOR_FACETS.other.value,
        label: TOPIC_INFERIOR_FACETS.other.label,
        topics: []
      }
    }
  },

  observe: {
    value: TOPIC_FACETS.observe.value,
    label: TOPIC_FACETS.observe.label,
    inferiorFacets: {
      charmService: {
        value: TOPIC_INFERIOR_FACETS.charmService.value,
        label: TOPIC_INFERIOR_FACETS.charmService.label,
        topics: []
      },

      reverseService: {
        value: TOPIC_INFERIOR_FACETS.reverseService.value,
        label: TOPIC_INFERIOR_FACETS.reverseService.label,
        topics: []
      },

      servicePioneer: {
        value: TOPIC_INFERIOR_FACETS.servicePioneer.value,
        label: TOPIC_INFERIOR_FACETS.servicePioneer.label,
        topics: []
      },

      other: {
        value: TOPIC_INFERIOR_FACETS.other.value,
        label: TOPIC_INFERIOR_FACETS.other.label,
        topics: []
      }
    }
  },

  websiteObserve: {
    value: TOPIC_FACETS.websiteObserve.value,
    label: TOPIC_FACETS.websiteObserve.label,
    inferiorFacets: {
      userInterface: {
        value: TOPIC_INFERIOR_FACETS.userInterface.value,
        label: '介面外觀',
        topics: []
      },

      useFunction: {
        value: TOPIC_INFERIOR_FACETS.useFunction.value,
        label: TOPIC_INFERIOR_FACETS.useFunction.label,
        topics: []
      },

      complaintChannel: {
        value: TOPIC_INFERIOR_FACETS.complaintChannel.value,
        label: TOPIC_INFERIOR_FACETS.complaintChannel.label,
        topics: []
      },

      other: {
        value: TOPIC_INFERIOR_FACETS.other.value,
        label: TOPIC_INFERIOR_FACETS.other.label,
        topics: []
      }
    }
  },

  phoneInteraction: {
    value: TOPIC_FACETS.phoneInteraction.value,
    label: TOPIC_FACETS.phoneInteraction.label,
    inferiorFacets: {
      contactEtiquette: {
        value: TOPIC_INFERIOR_FACETS.contactEtiquette.value,
        label: TOPIC_INFERIOR_FACETS.contactEtiquette.label,
        topics: []
      },

      tone: {
        value: TOPIC_INFERIOR_FACETS.tone.value,
        label: TOPIC_INFERIOR_FACETS.tone.label,
        topics: []
      },

      responseContent: {
        value: TOPIC_INFERIOR_FACETS.responseContent.value,
        label: '應對內容',
        topics: []
      },

      soundAndEmojis: {
        value: TOPIC_INFERIOR_FACETS.soundAndEmojis.value,
        label: TOPIC_INFERIOR_FACETS.soundAndEmojis.label,
        topics: []
      },

      other: {
        value: TOPIC_INFERIOR_FACETS.other.value,
        label: TOPIC_INFERIOR_FACETS.other.label,
        topics: []
      }
    }
  },

  mailComplaint: {
    value: TOPIC_FACETS.mailComplaint.value,
    label: TOPIC_FACETS.mailComplaint.label,
    inferiorFacets: {
      processingEfficiency: {
        value: TOPIC_INFERIOR_FACETS.processingEfficiency.value,
        label: TOPIC_INFERIOR_FACETS.processingEfficiency.label,
        topics: []
      },

      empathy: {
        value: TOPIC_INFERIOR_FACETS.empathy.value,
        label: '致歉同理',
        topics: []
      },

      responseContent: {
        value: TOPIC_INFERIOR_FACETS.responseContent.value,
        label: '回應內容',
        topics: []
      },

      other: {
        value: TOPIC_INFERIOR_FACETS.other.value,
        label: TOPIC_INFERIOR_FACETS.other.label,
        topics: []
      }
    }
  },

  app: {
    value: TOPIC_FACETS.app.value,
    label: TOPIC_FACETS.app.label,
    inferiorFacets: {
      userInterface: {
        value: TOPIC_INFERIOR_FACETS.userInterface.value,
        label: '使用介面',
        topics: []
      },

      useFunction: {
        value: TOPIC_INFERIOR_FACETS.useFunction.value,
        label: TOPIC_INFERIOR_FACETS.useFunction.label,
        topics: []
      },

      other: {
        value: TOPIC_INFERIOR_FACETS.other.value,
        label: TOPIC_INFERIOR_FACETS.other.label,
        topics: []
      }
    }
  },

  smartCustomerService: {
    value: TOPIC_FACETS.smartCustomerService.value,
    label: TOPIC_FACETS.smartCustomerService.label,
    inferiorFacets: {
      ansQuestions: {
        value: TOPIC_INFERIOR_FACETS.ansQuestions.value,
        label: TOPIC_INFERIOR_FACETS.ansQuestions.label,
        topics: []
      },

      thoughtful: {
        value: TOPIC_INFERIOR_FACETS.thoughtful.value,
        label: TOPIC_INFERIOR_FACETS.thoughtful.label,
        topics: []
      },

      complaintResponse: {
        value: TOPIC_INFERIOR_FACETS.complaintResponse.value,
        label: TOPIC_INFERIOR_FACETS.complaintResponse.label,
        topics: []
      },

      other: {
        value: TOPIC_INFERIOR_FACETS.other.value,
        label: TOPIC_INFERIOR_FACETS.other.label,
        topics: []
      }
    }
  },

  videoChatCustomerService: {
    value: TOPIC_FACETS.videoChatCustomerService.value,
    label: TOPIC_FACETS.videoChatCustomerService.label,
    inferiorFacets: {
      polite: {
        value: TOPIC_INFERIOR_FACETS.polite.value,
        label: TOPIC_INFERIOR_FACETS.polite.label,
        topics: []
      },

      serviceAttitude: {
        value: TOPIC_INFERIOR_FACETS.serviceAttitude.value,
        label: '態度感受',
        topics: []
      },

      responseContent: {
        value: TOPIC_INFERIOR_FACETS.responseContent.value,
        label: '回應內容',
        topics: []
      },

      other: {
        value: TOPIC_INFERIOR_FACETS.other.value,
        label: TOPIC_INFERIOR_FACETS.other.label,
        topics: []
      }
    }
  },

  textCustomerService: {
    value: TOPIC_FACETS.textCustomerService.value,
    label: TOPIC_FACETS.textCustomerService.label,
    inferiorFacets: {
      polite: {
        value: TOPIC_INFERIOR_FACETS.polite.value,
        label: TOPIC_INFERIOR_FACETS.polite.label,
        topics: []
      },

      serviceAttitude: {
        value: TOPIC_INFERIOR_FACETS.serviceAttitude.value,
        label: '態度感受',
        topics: []
      },

      complaintResponse: {
        value: TOPIC_INFERIOR_FACETS.complaintResponse.value,
        label: TOPIC_INFERIOR_FACETS.complaintResponse.label,
        topics: []
      },

      wording: {
        value: TOPIC_INFERIOR_FACETS.wording.value,
        label: TOPIC_INFERIOR_FACETS.wording.label,
        topics: []
      },

      other: {
        value: TOPIC_INFERIOR_FACETS.other.value,
        label: TOPIC_INFERIOR_FACETS.other.label,
        topics: []
      }
    }
  }
};

// 落實度
export const IMPLEMENTATION_TYPES = [
  {
    value: '0',
    label: '自訂配分，最大值：'
  },

  {
    value: '1',
    label: '0或1，加權佔比：'
  }
];

// 滿意度
export const SATISFACTION_TYPES = [
  {
    value: '0',
    label: '自訂配分，最大值：'
  },

  {
    value: '1',
    label: '1-5分級距1，加權佔比：'
  },

  {
    value: '2',
    label: '0-1級距0.25，加權佔比：'
  }
];
