import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { DatePicker, Form, Input, Select, Button, Cascader } from 'antd';
import CustomDrawer from 'src/components/CustomDrawer';
import CustomForm from 'src/components/CustomForm';
import withForm from 'src/components/withForm';
import styles from './styles.module.scss';
import './reset.scss';

// 新增 + 編輯, 案件
@observer
class CaseDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({
      project: props.project,
      form: props.form,
      onCreated: props.onCreated
    });
  }

  // 現場案件
  renderOnSiteForm = () => {
    return (
      <div className={styles.formContainer}>
        <div className={styles.item}>
          <Form.Item label="門市名稱" name="name" rules={this.vm.required}>
            <Input disabled={this.vm.isAwait} placeholder="請輸入" />
          </Form.Item>
        </div>

        <div className={styles.item}>
          <Form.Item label="門市地址" name="address">
            <Input disabled={this.vm.isAwait} placeholder="請輸入" />
          </Form.Item>
        </div>

        <div className={styles.item}>
          <Form.Item label="所屬區域" name="region">
            <Cascader options={this.vm.options.cities} placeholder="請選擇" />
          </Form.Item>
        </div>

        <div className={styles.item}>
          <Form.Item label="電話" name="phone">
            <Input placeholder="請輸入" />
          </Form.Item>
        </div>

        <div className={styles.item}>
          <Form.Item label="營業時間" name="businessHours">
            <Input placeholder="請輸入" />
          </Form.Item>
        </div>

        <div className={styles.item}>
          <Form.Item
            label="執案開始日"
            name="startDate"
            rules={this.vm.required}
          >
            <DatePicker className={styles.expand} placeholder="選擇日期" />
          </Form.Item>
        </div>

        <div className={styles.item}>
          <Form.Item
            label="案件截止日"
            name="expiryDate"
            rules={this.vm.required}
          >
            <DatePicker className={styles.expand} placeholder="選擇日期" />
          </Form.Item>
        </div>

        <div className={styles.item}>
          <Form.Item label="上傳檔案位置" name="uploadPath">
            <Input placeholder="請輸入有效雲端連結網址" />
          </Form.Item>
        </div>
      </div>
    );
  };

  // 非現場案件
  renderOffSiteForm = () => {
    return (
      <div className={styles.formContainer}>
        <div className={styles.item}>
          <Form.Item label="案件名稱" name="name" rules={this.vm.required}>
            <Input placeholder="請輸入" />
          </Form.Item>
        </div>

        <div className={styles.item}>
          <Form.Item label="案件類型" name="caseType" rules={this.vm.required}>
            <Select
              options={this.vm.options.types}
              disabled={this.vm.isAwait}
              placeholder="請選擇"
            />
          </Form.Item>
        </div>

        <div className={styles.item}>
          <Form.Item
            label="執案開始日"
            name="startDate"
            rules={this.vm.required}
          >
            <DatePicker className={styles.expand} placeholder="選擇日期" />
          </Form.Item>
        </div>

        <div className={styles.item}>
          <Form.Item
            label="案件截止日"
            name="expiryDate"
            rules={this.vm.required}
          >
            <DatePicker className={styles.expand} placeholder="選擇日期" />
          </Form.Item>
        </div>

        <div className={styles.item}>
          <Form.Item label="上傳檔案位置" name="uploadPath">
            <Input placeholder="請輸入" />
          </Form.Item>
        </div>

        <div className={styles.item}>
          <Form.Item label="案件說明" name="directions">
            <Input.TextArea rows={16} placeholder="請輸入" />
          </Form.Item>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div id="caseDrawer">
        <CustomDrawer vm={this.vm.drawerVM} width={650} title={this.vm.title}>
          <div className={styles.container}>
            <div className={styles.formContainer}>
              <Form form={this.vm.form} disabled={this.vm.isAwait}>
                {/* 現場案件 */}
                {this.vm.check.onSite && this.renderOnSiteForm()}

                {/* 非現場案件 */}
                {this.vm.check.offSite && this.renderOffSiteForm()}
              </Form>
            </div>

            <div className={styles.buttons}>
              <Button
                type="primary"
                className={styles.saveButton}
                onClick={this.vm.onSave}
                disabled={this.vm.isAwait}
              >
                儲存
              </Button>

              <Button onClick={this.vm.drawerVM.hide}>取消</Button>
            </div>
          </div>
        </CustomDrawer>
      </div>
    );
  }
}

CaseDrawer.propTypes = {
  vm: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  onCreated: PropTypes.func.isRequired
};

CaseDrawer.defaultProps = {};

export default withForm(CaseDrawer);
