import CompanyAPIs from 'src/apis/CompanyAPIs';
import UserAPIs from 'src/apis/UserAPIs';
import { LIMIT } from 'src/global/constants';
import CompanyListItemLiteDataModel from 'src/models/response/CompanyListItemLiteDataModel';
import CompanyListItemDataModel from 'src/models/response/CompanyListItemDataModel';
import CompanyDetailDataModel from 'src/models/response/CompanyDetailDataModel';
import CookieServices from './CookieServices';

class CompanyServices {
  // 讀取企業(客戶)列表
  static async postCompanyList({ limit = LIMIT, anchor, keyword }) {
    const res = await CompanyAPIs.postCompanyList({
      token: CookieServices.getToken(),
      limit,
      anchor,
      keyword
    });

    return {
      data: res.data.data.map((item) => new CompanyListItemDataModel(item)),
      anchor: res.data.anchor
    };
  }

  // 讀取企業(客戶)列表, 精簡版(作為選項使用)
  static async postCompanyListLite({ isDisabled, keyword }) {
    const res = await CompanyAPIs.postCompanyListLite({
      token: CookieServices.getToken(),
      isDisabled,
      keyword
    });
    const data = res.data.data.map(
      (item) => new CompanyListItemLiteDataModel(item)
    );

    return {
      data
    };
  }

  // 更新企業
  static async putCompanyUpdate({ clientId, info }) {
    const res = await CompanyAPIs.putCompanyUpdate({
      token: CookieServices.getToken(),
      clientId,
      info
    });

    return {
      data: res.data
    };
  }

  // 讀取帳號詳細資料
  static async getAccountProfile({ id }) {
    const res = await UserAPIs.getAccountProfile({
      token: CookieServices.getToken(),
      id
    });
    const data = res.data.data;
    const isDisabled = res.data.isDisabled;

    return {
      data: new CompanyDetailDataModel({ ...data, isDisabled })
    };
  }
}

export default CompanyServices;
