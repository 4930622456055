import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import SimpleModal from '../SimpleModal';
import styles from './styles.module.scss';

function FormModal(props) {
  return (
    <SimpleModal
      vm={props.vm}
      width={1400}
      closeIcon={false}
      footer={null}
      destroyOnClose
    >
      <div className={styles.header}>表單</div>

      <div className={styles.content}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>{props.title}</div>

          {props.disableEdit && (
            <div className={styles.buttons}>
              <Button size="large" onClick={props.vm.hide}>
                關閉
              </Button>
            </div>
          )}

          {!props.disableEdit && (
            <div className={styles.buttons}>
              <Button
                className={styles.closeButton}
                size="large"
                onClick={props.vm.hide}
              >
                取消
              </Button>

              <Button
                type="primary"
                size="large"
                onClick={props.onSave}
                disabled={props.isAwait}
              >
                儲存
              </Button>
            </div>
          )}
        </div>

        {props.children}

        <div className={styles.buttons}>
          {props.disableEdit && (
            <div className={styles.buttons}>
              <Button size="large" onClick={props.vm.hide}>
                關閉
              </Button>
            </div>
          )}

          {!props.disableEdit && (
            <div className={styles.buttons}>
              <Button
                className={styles.closeButton}
                size="large"
                onClick={props.vm.hide}
              >
                取消
              </Button>

              <Button
                type="primary"
                size="large"
                onClick={props.onSave}
                disabled={props.isAwait}
              >
                儲存
              </Button>
            </div>
          )}
        </div>
      </div>
    </SimpleModal>
  );
}

FormModal.propTypes = {
  vm: PropTypes.object.isRequired,
  title: PropTypes.string,
  disableEdit: PropTypes.bool,
  onSave: PropTypes.func,
  isAwait: PropTypes.bool,
  children: PropTypes.node
};

FormModal.defaultProps = {
  title: '某個表單',
  disableEdit: false,
  onSave: () => {},
  isAwait: false,
  children: null
};

export default observer(FormModal);
