import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import {
  TOPIC_TYPES,
  TOPIC_FACETS,
  TOPIC_INFERIOR_FACETS
} from 'src/global/topic';
import { SITE_TYPES } from 'src/global/constants';

class CheckFormItemViewModel {
  @observable data = null;
  @observable caseId = null;
  @observable id = null;
  // 題目類型
  @observable type = null;

  // 滿意度的類型與上限
  @observable implementation = null;
  @observable implementWeighted = null;

  // 落實度的類型與上限
  @observable satisfaction = null;
  @observable satisWeighted = null;

  // 答案
  @observable implementScore = null;
  @observable satisScore = null;
  @observable content = null;

  @computed
  get check() {
    return {
      idBonus: this.type === TOPIC_TYPES.bonus.value
    };
  }

  @computed
  get inputNumber() {
    return {
      implementWeighted: Number(this.implementWeighted || 0),
      satisWeighted: Number(this.satisWeighted || 0)
    };
  }

  @computed
  get label() {
    return {
      site: SITE_TYPES[this.data.site].label,
      facet: TOPIC_FACETS[this.data.facet].label,
      inferiorFacet: TOPIC_INFERIOR_FACETS[this.data.inferiorFacet].label,
      type: TOPIC_TYPES[this.data.type].label,
      implementWeighted: `落實度 - ${this.implementWeighted}`,
      satisWeighted: `滿意度 - ${this.satisWeighted}`,
      topic: this.data.topic,
      content: this.data.content
    };
  }

  @computed
  get res() {
    const implementScore
      = this.implementScore || this.implementScore === 0
        ? String(this.implementScore).trim()
        : null;
    const satisScore
      = this.satisScore || this.satisScore === 0
        ? String(this.satisScore).trim()
        : null;

    return {
      caseId: Number(this.caseId),
      itemId: Number(this.id),
      implementScore: implementScore ? Number(implementScore) : null,
      satisScore: satisScore ? Number(satisScore) : null,
      content: this.content?.trim() || null
    };
  }

  constructor(props) {
    const data = props.data;

    this.caseId = props.caseId;

    this.data = data;

    this.id = data.id;

    this.type = data.type;

    this.implementation = data.implementation || null;
    this.implementWeighted = data.implementWeighted || null;

    this.satisfaction = data.satisfaction || null;
    this.satisWeighted = data.satisWeighted || null;

    this.setAnswer(data);

    makeObservable(this);
  }

  @action
  setAnswer = (props) => {
    const answer = props.checkFormReply[0];

    if (answer) {
      this.implementScore = answer.implementScore;
      this.satisScore = answer.satisScore;
      this.content = answer.content;
    }
  };

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  @action
  onChangeImplementScoreInputNumber = (value) => {
    this.implementScore = Math.floor(value);

    console.log('implementScore', value);
  };

  @action
  onChangeImplementScore = (event) => {
    const value = event.target.value;

    this.implementScore = value;

    console.log('implementScore', value);
  };

  @action
  onChangeSatisScoreInputNumber = (value) => {
    this.satisScore = Math.floor(value);

    console.log('satisScore', value);
  };

  @action
  onChangeSatisScore = (event) => {
    const value = event.target.value;

    this.satisScore = value;

    console.log('satisScore', value);
  };

  @action
  onChangeContent = (event) => {
    const value = event.target.value;

    this.content = value;

    console.log('content', value);
  };
}

export default CheckFormItemViewModel;
