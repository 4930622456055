import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import ICONS from 'src/global/icons';
import styles from './styles.module.scss';

function CustomAnchor(props) {
  return props.vm.check.isShow ? (
    <div className={styles.container}>
      <div className={styles.rect}>
        <Button
          className={styles.button}
          onClick={props.vm.onPrev}
          disabled={props.vm.disabled.prev}
        >
          <ICONS.LeftArrow className={styles.icon} />
        </Button>
      </div>

      <div className={styles.rect}>
        <Button
          className={styles.button}
          onClick={props.vm.onNext}
          disabled={props.vm.disabled.next}
        >
          <ICONS.RightArrow className={styles.icon} />
        </Button>

        {props.total && props.vm.check.total && (
          <div className={styles.total}>{props.vm.label.total}</div>
        )}
      </div>
    </div>
  ) : null;
}

CustomAnchor.propTypes = {
  vm: PropTypes.object.isRequired,
  total: PropTypes.bool
};

CustomAnchor.defaultProps = {
  total: false
};

export default observer(CustomAnchor);
