import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Input } from 'antd';
import styles from './styles.module.scss';

// 專案件酬試算, 固定的項目
function FixedItem(props) {
  return (
    <div className={styles.container}>
      <div className={styles.label}>
        {props.vm.label}
        <span className={styles.colon}>:</span>
      </div>

      <div className={styles.content}>
        <Input
          className={styles.input}
          value={props.vm.value}
          onChange={props.vm.onChangeValue}
          disabled={props.disabled}
          placeholder={props.vm.placeholder}
          maxLength={50}
        />
      </div>
    </div>
  );
}

FixedItem.propTypes = {
  vm: PropTypes.object.isRequired,
  disabled: PropTypes.bool
};

FixedItem.defaultProps = {
  disabled: false
};

export default observer(FixedItem);
