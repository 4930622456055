import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import CustomTable from 'src/components/CustomTable';
import styles from './styles.module.scss';

function ReadOnlyTable(props) {
  return (
    <CustomTable data={props.data} hasPadding>
      <Table.Column width={200} title="公告項目" dataIndex="title" />

      <Table.Column
        title="公告內容"
        render={(vm) => {
          return (
            <a
              className={styles.link}
              target="_blank"
              href={vm.path}
              rel="noreferrer"
            >
              {vm.content}
            </a>
          );
        }}
      />
    </CustomTable>
  );
}

ReadOnlyTable.propTypes = {
  data: PropTypes.array
};

ReadOnlyTable.defaultProps = {
  data: []
};

export default observer(ReadOnlyTable);
