import React from 'react';
import PropTypes from 'prop-types';
import { observer, Observer } from 'mobx-react';
import { Table, Input, Button } from 'antd';
import ICONS from 'src/global/icons';
import CustomTable from 'src/components/CustomTable';
import SimpleTextArea from 'src/components/SimpleTextArea';
import styles from './styles.module.scss';

function ContactsTable(props) {
  return (
    <CustomTable data={props.data} hasScrollX>
      <Table.Column
        width={120}
        title="類別"
        render={(vm) => {
          return (
            <Observer>
              {() => {
                return props.disabled ? (
                  <div className={styles.textContainer}>{vm.label.type}</div>
                ) : (
                  <SimpleTextArea
                    size="small"
                    autoSize
                    value={vm.type}
                    onChange={vm.onChangeType}
                    placeholder="請輸入"
                    maxLength={50}
                  />
                );
              }}
            </Observer>
          );
        }}
      />

      <Table.Column
        width={120}
        title="部門單位"
        render={(vm) => {
          return (
            <Observer>
              {() => {
                return props.disabled ? (
                  <div className={styles.textContainer}>{vm.label.sector}</div>
                ) : (
                  <SimpleTextArea
                    size="small"
                    autoSize
                    value={vm.sector}
                    onChange={vm.onChangeSector}
                    placeholder="請輸入"
                    maxLength={50}
                  />
                );
              }}
            </Observer>
          );
        }}
      />

      <Table.Column
        width={120}
        title="姓名"
        render={(vm) => {
          return (
            <Observer>
              {() => {
                return props.disabled ? (
                  <div className={styles.textContainer}>{vm.label.name}</div>
                ) : (
                  <SimpleTextArea
                    size="small"
                    autoSize
                    value={vm.name}
                    onChange={vm.onChangeName}
                    placeholder="請輸入"
                    maxLength={50}
                  />
                );
              }}
            </Observer>
          );
        }}
      />

      <Table.Column
        width={120}
        title="職稱"
        render={(vm) => {
          return (
            <Observer>
              {() => {
                return props.disabled ? (
                  <div className={styles.textContainer}>{vm.label.title}</div>
                ) : (
                  <SimpleTextArea
                    size="small"
                    autoSize
                    value={vm.title}
                    onChange={vm.onChangeTitle}
                    placeholder="請輸入"
                    maxLength={50}
                  />
                );
              }}
            </Observer>
          );
        }}
      />

      <Table.Column
        width={160}
        title="電話"
        render={(vm) => {
          return (
            <Observer>
              {() => {
                return props.disabled ? (
                  <div className={styles.textContainer}>
                    {vm.label.telephone}
                  </div>
                ) : (
                  <SimpleTextArea
                    size="small"
                    autoSize
                    value={vm.telephone}
                    onChange={vm.onChangeTelephone}
                    placeholder="請輸入"
                    maxLength={50}
                  />
                );
              }}
            </Observer>
          );
        }}
      />

      <Table.Column
        width={150}
        title="手機"
        render={(vm) => {
          return (
            <Observer>
              {() => {
                return props.disabled ? (
                  <div className={styles.textContainer}>{vm.label.phone}</div>
                ) : (
                  <SimpleTextArea
                    size="small"
                    autoSize
                    value={vm.phone}
                    onChange={vm.onChangePhone}
                    placeholder="請輸入"
                    maxLength={50}
                  />
                );
              }}
            </Observer>
          );
        }}
      />

      <Table.Column
        width={260}
        title="電子郵件"
        render={(vm) => {
          return (
            <Observer>
              {() => {
                return props.disabled ? (
                  <div className={styles.textContainer}>{vm.label.email}</div>
                ) : (
                  <SimpleTextArea
                    size="small"
                    autoSize
                    value={vm.email}
                    onChange={vm.onChangeEmail}
                    placeholder="請輸入"
                    maxLength={50}
                  />
                );
              }}
            </Observer>
          );
        }}
      />

      <Table.Column
        width={60}
        title="操作"
        align="center"
        render={(vm) => {
          return (
            <Observer>
              {() => {
                return (
                  <div className={styles.operate}>
                    <Button
                      type="link"
                      className={styles.deleteButton}
                      onClick={() => {
                        props.onDeleteContact(vm.id);
                      }}
                      disabled={props.disabled}
                    >
                      <ICONS.Delete className={styles.deleteIcon} />
                    </Button>
                  </div>
                );
              }}
            </Observer>
          );
        }}
      />
    </CustomTable>
  );
}

ContactsTable.propTypes = {
  data: PropTypes.array,
  onDeleteContact: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

ContactsTable.defaultProps = {
  data: [],
  disabled: false
};

export default observer(ContactsTable);
