import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { diff } from 'deep-diff';
import { CASE_STATUS } from 'src/global/case';
import CustomModalViewModel from 'src/components/CustomModal/viewModel';

class UploadPathItemViewModel {
  @observable case = null;
  @observable checkParams = null;

  modalVM = new CustomModalViewModel();

  @computed
  get modalContentLabel() {
    return '上傳檔案位置:';
  }

  @computed
  get check() {
    return {
      uploadPath: !!this.case.uploadPath
    };
  }

  @computed
  get modalContent() {
    const link = this.check.uploadPath ? this.case.uploadPath : '';
    const label = this.check.uploadPath ? this.case.uploadPath : '無';

    return {
      link,
      label
    };
  }

  @computed
  get isShow() {
    switch (this.case.step) {
      case CASE_STATUS.S7.value:
      case CASE_STATUS.S8.value:
      case CASE_STATUS.S9.value:
      case CASE_STATUS.S10.value:
      case CASE_STATUS.S11.value:
      case CASE_STATUS.S12.value: {
        return true;
      }

      default:
        return false;
    }
  }

  constructor(props) {
    this.init(props);
    makeObservable(this);
  }

  @action
  init = (props) => {
    this.case = props.case;
    this.checkParams = props.checkParams;
    this.remark = props.case.pmContent || null;
  }

  didUpdate = (props, preProps) => {
    const diffCase = diff(props.case, preProps.case);

    if (diffCase) {
      this.init(props);
    }
  }
}

export default UploadPathItemViewModel;
