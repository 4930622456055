import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import styles from './styles.module.scss';

function SelfFormItem(props) {
  return (
    <Form.Item
      name={props.name}
      className={styles.container}
      rules={[
        {
          required: props.required,
          message: props.requiredMessage
        }
      ]}
    >
      {props.children}
    </Form.Item>
  );
}

SelfFormItem.propTypes = {
  name: PropTypes.any,
  children: PropTypes.node,
  required: PropTypes.bool,
  requiredMessage: PropTypes.string
};

SelfFormItem.defaultProps = {
  name: undefined,
  children: null,
  required: false,
  requiredMessage: '請輸入'
};

export default SelfFormItem;
