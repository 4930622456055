import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { Collapse, Tabs } from 'antd';
import clsx from 'clsx';
import InferiorFacetTab from '../InferiorFacetTab';
import HintLabel from '../HintLabel';
import styles from './styles.module.scss';
import './reset.scss';

@observer
class Facet extends React.Component {
  @computed
  get inferiorFacets() {
    return this.props.data.inferiorFacets;
  }

  @computed
  get topics() {
    const topics = this.inferiorFacets.map((item) => item.topics).flat();

    return topics;
  }

  @computed
  get invalid() {
    return !!this.topics.find((topic) => topic.invalid);
  }

  @computed
  get total() {
    let implementWeighted = 0;
    let satisWeighted = 0;

    for (let i = 0; i < this.topics.length; i += 1) {
      const fraction = this.topics[i].facetFraction;

      if (fraction) {
        implementWeighted += Number(fraction.implementWeighted);
        satisWeighted += Number(fraction.satisWeighted);
      }
    }

    return {
      implementWeighted,
      satisWeighted
    };
  }

  @computed
  get tabs() {
    return this.inferiorFacets.map((item) => {
      const findInvalid = !!item.topics.find((topic) => topic.invalid);

      return {
        key: item.key,
        label: <HintLabel label={item.label} showIcon={findInvalid} />,
        children: (
          <InferiorFacetTab
            data={item.topics}
            inferiorFacet={item.value}
            site={this.props.site}
            facet={this.props.facet}
            createDrawerVM={this.props.createDrawerVM}
            onCreated={this.props.onCreated}
            disabled={this.props.disabled}
          />
        )
      };
    });
  }

  render() {
    return (
      <div id="facet" className={styles.container}>
        <div className={styles.sumContainer}>
          <div className={clsx([styles.item, styles.lengthItem])}>
            題目數量：
            {this.topics.length}
          </div>

          <div className={clsx([styles.item, styles.implementWeightedItem])}>
            落實度分數加權佔比：
            {this.total.implementWeighted}
            %
          </div>

          <div className={clsx([styles.item, styles.satisWeightedItem])}>
            滿意度分數加權佔比：
            {this.total.satisWeighted}
            %
          </div>
        </div>

        <Collapse>
          <Collapse.Panel
            header={(
              <HintLabel
                label={this.props.data.label}
                showIcon={this.invalid}
              />
            )}
          >
            <div className={styles.tabContainer}>
              <Tabs type="card" items={this.tabs} />
            </div>
          </Collapse.Panel>
        </Collapse>
      </div>
    );
  }
}

Facet.propTypes = {
  data: PropTypes.object.isRequired,
  site: PropTypes.string.isRequired,
  facet: PropTypes.string.isRequired,
  createDrawerVM: PropTypes.object.isRequired,
  onCreated: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

Facet.defaultProps = {};

export default Facet;
