import StatisticsAPIs from 'src/apis/StatisticsAPIs';
import StatisticsTimeListItemDataModel from 'src/models/response/StatisticsTimeListItemDataModel';
import StatisticsDataModel from 'src/models/response/StatisticsDataModel';
import CookieServices from './CookieServices';

class StatisticsServices {
  // 新增統計列表
  static async postStatisticsTimeList({ projectId, timeList }) {
    const res = await StatisticsAPIs.postStatisticsTimeList({
      token: CookieServices.getToken(),
      projectId,
      timeList
    });

    return {
      data: res.data
    };
  }

  // 讀取統計列表
  static async getStatisticsTimeList({ projectId }) {
    const res = await StatisticsAPIs.getStatisticsTimeList({
      token: CookieServices.getToken(),
      projectId
    });

    return {
      data: res.data.data.map(
        (item) => new StatisticsTimeListItemDataModel(item)
      )
    };
  }

  // 讀取全部統計資料
  static async getStatisticsData({ projectId }) {
    const res = await StatisticsAPIs.getStatisticsData({
      token: CookieServices.getToken(),
      projectId
    });

    return {
      data: new StatisticsDataModel(res.data.data)
    };
  }
}

export default StatisticsServices;
