import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Form, Input, Select } from 'antd';
import withRouter from 'src/components/withRouter';
import CustomModal from 'src/components/CustomModal';
import SimpleForm from 'src/components/SimpleForm';
import './reset.scss';

@observer
class CreateModal extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({
      onCreated: props.onCreated,
      router: props.router
    });
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return (
      <div id="createProjectModal">
        <CustomModal
          vm={this.vm.modalVM}
          hasClose
          hasHeader
          title="新增"
          hasPadding
          hasFooter
          confirmType="primary"
          onConfirm={this.vm.onConfirm}
          disabledConfirm={this.vm.isAwait}
          hasCancel
          onCancel={this.vm.modalVM.hide}
        >
          <SimpleForm ref={this.vm.formRef} labelCol={6}>
            <Form.Item
              label="專案名稱"
              name="projectName"
              rules={this.vm.required}
              disabled={this.vm.isAwait}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="企業"
              name="companyId"
              rules={this.vm.required}
              disabled={this.vm.isAwait}
            >
              <Select
                showSearch
                options={this.vm.clients}
                filterOption={this.vm.onFilterOption}
              />
            </Form.Item>
          </SimpleForm>
        </CustomModal>
      </div>
    );
  }
}

CreateModal.propTypes = {
  vm: PropTypes.object.isRequired,
  onCreated: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired
};

export default withRouter(CreateModal);
