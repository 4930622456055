import React from 'react';
import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction
} from 'mobx';
import CustomDrawerViewModel from 'src/components/CustomDrawer/viewModel';
import CaseServices from 'src/services/CaseServices';
import VisitorServices from 'src/services/VisitorServices';
import ErrorServices from 'src/services/ErrorServices';
import { GENDER_TYPES, WITH_OR_WITHOUT } from 'src/global/constants';
import {
  TELECOM_TYPES,
  PRACTICE_CITIES,
  AGE_TYPES,
  LICENSE_TYPES,
  DRIVING_LICENSE_TYPES
} from 'src/global/visitor';
import { CASE_STATUS } from 'src/global/case';
import VisitorViewModel from './viewModels/VisitorViewModel';

class InterviewerDrawerViewModel {
  @observable data = [];
  @observable totalCount = 0;
  @observable anchor = undefined;
  @observable isAwait = false;
  @observable visitorId = null;

  formRef = React.createRef();

  drawerVM = new CustomDrawerViewModel();

  // 外部傳入
  caseId = null;
  onChanged = null;

  @computed
  get disabled() {
    return {
      save: this.isAwait || this.visitorId === null
    };
  }

  @computed
  get label() {
    const visitor = this.data.find((item) => item.id === this.visitorId);
    const visitorName = visitor?.name || '';

    const dataLength = `共篩選出 ${this.totalCount} 筆資料`;

    return {
      visitorName: `選擇訪員：${visitorName}`,
      dataLength
    };
  }

  @computed
  get options() {
    const defaultOption = {
      value: '',
      label: '請選擇'
    };
    const ages = [defaultOption, ...Object.values(AGE_TYPES)];
    const genders = [defaultOption, ...Object.values(GENDER_TYPES)];
    const videoEquipment = [defaultOption, ...Object.values(WITH_OR_WITHOUT)];

    return {
      ages,
      licenses: Object.values(LICENSE_TYPES),
      drivingLicenses: Object.values(DRIVING_LICENSE_TYPES),
      cities: Object.values(PRACTICE_CITIES),
      genders,
      telecoms: Object.values(TELECOM_TYPES),
      videoEquipment
    };
  }

  constructor(props) {
    makeObservable(this);
  }

  @action
  open = ({ id, onChanged }) => {
    this.caseId = id;
    this.onChanged = onChanged;

    this.drawerVM.show();
  };

  @action
  onChangeVisitorId = (event, id) => {
    const checked = event.target.checked;

    this.visitorId = checked ? id : null;
  };

  onScrollEnd = () => {
    if (!this.isAwait && this.anchor !== null && this.anchor !== undefined) {
      this.postVisitorListAPI();
    }
  };

  @action
  onSearch = () => {
    this.data = [];
    this.anchor = undefined;
    this.postVisitorListAPI();
  };

  onSave = () => {
    this.postCaseActionAPI();
  };

  @action
  reset = () => {
    this.visitorId = null;
    this.formRef.current.setFieldsValue({});
  };

  getFilterData = () => {
    const data = this.formRef.current.getFieldsValue();

    // 訪員證照
    const license = data.license?.length ? data.license : undefined;
    // 執案區域
    const workingCounty = data.workingCounty?.length
      ? data.workingCounty
      : undefined;
    // 錄影設備
    const haveDevice = data.haveDevice ? data.haveDevice : undefined;
    // 電信用戶
    const telecom = data.telecom?.length ? data.telecom : undefined;
    // 性別
    const gender = data.gender ? data.gender : undefined;
    // 年齡
    const age = data.age ? data.age : undefined;
    // 駕照
    const drvingLicense = data.drvingLicense?.length
      ? data.drvingLicense
      : undefined;

    const filter = {
      workingCounty,
      license,
      haveDevice,
      telecom,
      gender,
      age,
      drvingLicense,
      isDisabled: false
    };

    const checkKeyword = !!data.keyword?.trim();
    const keyword = checkKeyword ? data.keyword.trim() : undefined;

    return {
      filter,
      keyword
    };
  };

  // 讀取訪員列表
  @action
  postVisitorListAPI = async () => {
    this.isAwait = true;

    try {
      const formData = this.getFilterData();
      const res = await VisitorServices.postVisitorList({
        anchor: this.anchor,
        filter: formData.filter,
        keyword: formData.keyword
      });

      runInAction(() => {
        const resData = res.data.map((item) => new VisitorViewModel(item));

        this.data = [...this.data, ...resData];
        this.totalCount = res.totalCount;
        this.anchor = res.anchor;
      });
    } catch (error) {
      const message = ErrorServices.postVisitorList(error);

      console.log('InterviewerDrawerViewModel', 'postVisitorList', message);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };

  // 指派訪員
  @action
  postCaseActionAPI = async () => {
    this.isAwait = true;

    try {
      const visitor = this.data.find((item) => item.id === this.visitorId);
      const step = CASE_STATUS.S3;
      const res = await CaseServices.postCaseAction({
        caseId: this.caseId,
        nextStep: step.value,
        visitorId: visitor.id
      });

      this.onChanged({
        step,
        visitorId: visitor.id,
        visitorName: visitor.name
      });

      this.drawerVM.hide();
    } catch (error) {
      const message = ErrorServices.postCaseAction(error);

      console.log('InterviewerDrawerViewModel', 'postCaseAction', message);
    } finally {
      runInAction(() => {
        this.isAwait = false;

        this.reset();
      });
    }
  };
}

export default InterviewerDrawerViewModel;
