import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { ROUTES } from 'src/global/constants';
import withProfile from 'src/components/withProfile';
import CustomHeader from 'src/components/CustomHeader';
import EditTable from './components/EditTable/index';
import ReadOnlyTable from './components/ReadOnlyTable/ReadOnlyTable';
import AnnouncementPageViewModel from './viewModel';
import styles from './styles.module.scss';

// 老闆 ,業務, 可以編輯
// 營業部, PM, 只讀
@observer
class AnnouncementPage extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new AnnouncementPageViewModel({ profile: props.profile });
  }

  componentDidMount() {
    this.vm.didMount();
  }

  renderTable = () => {
    if (this.vm.hasEdit) {
      return (
        <EditTable
          data={this.vm.data}
          isAwait={this.vm.isAwait}
          disableEdit={this.vm.disableEdit}
          checkEdit={this.vm.checkEdit}
          onEdit={this.vm.onEdit}
          onSave={this.vm.onSave}
        />
      );
    }

    return <ReadOnlyTable data={this.vm.data} />;
  };

  render() {
    return (
      <div className={styles.container}>
        <CustomHeader title={ROUTES.announcement.label} paddingBottom />

        {!this.vm.isInit && this.renderTable()}
      </div>
    );
  }
}

AnnouncementPage.propTypes = {
  profile: PropTypes.object.isRequired
};

AnnouncementPage.defaultProps = {};

export default withProfile(AnnouncementPage, true);
