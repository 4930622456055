import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction
} from 'mobx';
import { APPLY_EDIT_INCOME_STATUS } from 'src/global/profitForm';
import ProfitFormServices from 'src/services/ProfitFormServices';
import ErrorServices from 'src/services/ErrorServices';
import dayjs from 'dayjs';
import { delay } from 'src/utils';

// 申請修改紀錄每項
class ApplyViewModel {
  @observable status = null;
  @observable isAwait = false;
  @observable createDate = null;

  id = null;
  reason = null;
  owner = null;
  createDate = null;

  onAccepted = null;

  @computed
  get statusLabel() {
    if (this.status) {
      return APPLY_EDIT_INCOME_STATUS[this.status].label;
    }

    return null;
  }

  @computed
  get createDateLabel() {
    return dayjs(this.createDate).format('YYYY.MM.DD HH:mm:ss');
  }

  @computed
  get check() {
    return {
      isApply: this.status === APPLY_EDIT_INCOME_STATUS.apply.value
    };
  }

  constructor(props) {
    this.status = props.data.status;
    this.id = props.data.eventId;
    this.reason = props.data.content;
    this.owner = props.data.applyerName;
    this.createDate = props.data.applyTime;

    // 同意申請修改
    this.onAccepted = props.onAccepted;

    makeObservable(this);
  }

  @action
  onAgree = () => {
    this.postAcceptProfitFormEvenAPI();
  };

  @action
  onReject = () => {
    this.postRejectProfitFormEvenAPI();
  };

  // 接受申請
  @action
  postAcceptProfitFormEvenAPI = async () => {
    this.isAwait = true;

    try {
      const res = await ProfitFormServices.postAcceptProfitFormEven({
        eventId: this.id
      });

      runInAction(() => {
        this.status = APPLY_EDIT_INCOME_STATUS.accept.value;

        this.onAccepted();
      });
    } catch (error) {
      const message = ErrorServices.postAcceptProfitFormEven(error);

      console.log('ApplyModalViewModel', 'postAcceptProfitFormEven', message);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };

  // 拒絕申請
  @action
  postRejectProfitFormEvenAPI = async () => {
    this.isAwait = true;

    try {
      const res = await ProfitFormServices.postRejectProfitFormEven({
        eventId: this.id
      });

      runInAction(() => {
        this.status = APPLY_EDIT_INCOME_STATUS.reject.value;
      });
    } catch (error) {
      const message = ErrorServices.postRejectProfitFormEven(error);

      console.log('ApplyModalViewModel', 'postRejectProfitFormEven', message);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default ApplyViewModel;
