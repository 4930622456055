import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';
import './reset.scss';

class CustomBreadcrumb extends React.Component {
  renderLink(item) {
    return (
      <Breadcrumb.Item key={item.label}>
        <Link className={styles.link} to={item.link}>
          {item.label}
        </Link>
      </Breadcrumb.Item>
    );
  }

  renderLabel = (item) => {
    return (
      <Breadcrumb.Item key={item.label}>
        <span className={styles.pageSpan}>{item.label}</span>
      </Breadcrumb.Item>
    );
  }

  render() {
    return (
      <div id="customBreadcrumb" className={styles.container}>
        <Breadcrumb>
          {this.props.routes.map((item) => {
            const hasLink = !!item.link;
            return hasLink ? this.renderLink(item) : this.renderLabel(item);
          })}
        </Breadcrumb>
      </div>
    );
  }
}

CustomBreadcrumb.propTypes = {
  routes: PropTypes.array
};

CustomBreadcrumb.defaultProps = {
  routes: []
};

export default CustomBreadcrumb;
