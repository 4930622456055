import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Input, DatePicker, Button } from 'antd';
import { USER_TYPES } from 'src/global/constants';
import ICONS from 'src/global/icons';
import SelfRow from 'src/components/CustomForm/components/SelfRow';
import SelfFormItem from 'src/components/CustomForm/components/SelfFormItem';
import FormatCell from 'src/components/CustomForm/components/FormatCell';
import BorderRightBottom from 'src/components/CustomForm/components/BorderRightBottom';
import FormDetailViewModel from './viewModel';
import styles from './styles.module.scss';

// 專案總規格
@observer
class FormDetail extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new FormDetailViewModel({ form: props.form });
  }

  renderLinkInput = () => {
    return this.props.isEdit ? (
      <div className={styles.formLink}>
        <SelfFormItem name={['projectDetail', 'quotation']}>
          <Input size="small" onChange={this.vm.onLinkChange} />
        </SelfFormItem>

        {this.vm.check.link ? (
          <a
            className={styles.linkButton}
            href={this.vm.link}
            target="_blank"
            rel="noreferrer"
          >
            <ICONS.Link className={styles.icon} />
          </a>
        ) : null}
      </div>
    ) : null;
  };

  renderLink = () => {
    return !this.props.isEdit && this.vm.check.link ? (
      <a
        className={styles.link}
        href={this.vm.link}
        target="_blank"
        rel="noreferrer"
      >
        {this.vm.link}
      </a>
    ) : null;
  };

  render() {
    return (
      <BorderRightBottom>
        <SelfRow>
          <FormatCell label="專案名稱">
            {this.props.project.projectName}
          </FormatCell>

          <FormatCell label="企業名稱">
            {this.props.project.companyName}
          </FormatCell>
        </SelfRow>

        <SelfRow>
          <FormatCell
            label="專案整體期間"
            role={USER_TYPES.salesperson.value}
            required
          >
            <SelfFormItem name={['projectDetail', 'overallDate']} required>
              <DatePicker.RangePicker size="small" />
            </SelfFormItem>
          </FormatCell>

          <FormatCell label="執案週期數" role={USER_TYPES.projectManager.value}>
            <SelfFormItem name={['projectDetail', 'caseWeek']}>
              <Input addonAfter="波" size="small" maxLength={50} />
            </SelfFormItem>
          </FormatCell>
        </SelfRow>

        <SelfRow>
          <FormatCell label="專案報價單" role={USER_TYPES.salesperson.value}>
            {this.renderLinkInput()}
            {this.renderLink()}
          </FormatCell>
        </SelfRow>
      </BorderRightBottom>
    );
  }
}

FormDetail.propTypes = {
  project: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  isEdit: PropTypes.bool.isRequired
};

FormDetail.defaultProps = {};

export default FormDetail;
