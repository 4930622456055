import PagesRoot from 'src/pages';
import { AppStateProvider } from 'src/stores';
import styles from './App.module.css';

function App() {
  return (
    <div className={styles.app}>
      <AppStateProvider>
        <PagesRoot />
      </AppStateProvider>
    </div>
  );
}

export default App;
