import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import dayjs from 'dayjs';
import CheckFormServices from 'src/services/CheckFormServices';
import ErrorServices from 'src/services/ErrorServices';
import { downloadCSV } from 'src/global/methods';
import { exportCheckForm } from 'src/global/export';

class ExportCheckFormViewModel {
  @observable project = null;
  @observable isAwait = false;

  constructor(props) {
    this.project = props.project;

    makeObservable(this);
  }

  onExport = () => {
    this.getCheckFormExport();
  };

  @action
  getCheckFormExport = async () => {
    this.isAwait = true;

    try {
      const projectId = this.project.id;
      const res = await CheckFormServices.getCheckFormExport({ projectId });

      runInAction(() => {
        const formatData = exportCheckForm({
          data: res.data,
          status: true,
          visitorName: true,
          visitorNickName: true,
          auditStartTime: true,
          auditEndTime: true,
          implementationTotal: true,
          satisfactionTotal: true
        });
        const today = dayjs().format('YYYYMMDD');
        const fileName = `${this.project.projectName}_${today}`;

        downloadCSV({ data: formatData, fileName });
      });
    } catch (error) {
      const msg = ErrorServices.getCheckFormExport(error);

      console.log('ExportCheckFormViewModel', 'getCheckFormExport', msg);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default ExportCheckFormViewModel;
