import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { diff } from 'deep-diff';
import { CASE_STATUS } from 'src/global/case';
import CaseServices from 'src/services/CaseServices';
import ErrorServices from 'src/services/ErrorServices';
import { message } from 'antd';

class RejectFormViewModel {
  @observable case = null;
  @observable checkParams = null;
  @observable reason = null;
  @observable isAwait = false;

  onChanged = null;

  @computed
  get isShow() {
    switch (this.case.step) {
      case CASE_STATUS.S8.value:
        return this.checkParams.isPM;

      default:
        return false;
    }
  }

  @computed
  get check() {
    return {
      reason: this.reason?.trim() || null
    };
  }

  constructor(props) {
    this.onChanged = props.onChanged;

    this.init(props);

    makeObservable(this);
  }

  @action
  init = (props) => {
    this.case = props.case;
    this.checkParams = props.checkParams;
  }

  didUpdate = (props, preProps) => {
    const diffCase = diff(props.case, preProps.case);

    if (diffCase) {
      this.init(props);
    }
  }

  @action
  onChangeReason = (event) => {
    this.reason = event.target.value;
  }

  // 退件
  onReturnStep = () => {
    if (this.check.reason) {
      this.postCasePushAPI(CASE_STATUS.S9);
    } else {
      message.warning('請填寫退回原因', 5);
    }
  };

  // 失敗
  onReject = () => {
    if (this.check.reason) {
      this.postCasePushAPI(CASE_STATUS.S11);
    } else {
      message.warning('請填寫不通過原因', 5);
    }
  }

  @action
  postCasePushAPI = async (step) => {
    this.isAwait = true;

    try {
      const reason = this.reason?.trim();
      const res = await CaseServices.postCaseAction({
        caseId: this.case.caseId,
        nextStep: step.value,
        message: reason || null
      });

      runInAction(() => {
        this.reason = '';

        this.onChanged();
      });
    } catch (error) {
      const msg = ErrorServices.postCaseAction(error);

      console.log('PushButtonViewModel', 'postCaseAction', msg);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default RejectFormViewModel;
