import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { DEV_ROUTE_SEARCHES } from 'src/global/dev';
import { routerMethods } from 'src/global/methods';
import getScore from 'src/global/statistics';
import UserServices from 'src/services/UserServices';
import AppServices from 'src/services/AppServices';
import ErrorServices from 'src/services/ErrorServices';
import CheckFormItemViewModel from './components/CheckFormItem/viewModel';

const cases = {
  assigned: {
    projects: []
  },
  accepted: {
    projects: []
  },
  progress: {
    projects: []
  },
  returned: {
    projects: []
  }
};

class ReplyPageViewModel {
  @observable profile = null;
  // 所有的案件
  @observable cases = cases;
  @observable checkFormReply = null;
  @observable checkFormItems = [];
  @observable repliedCheckFormItems = [];
  @observable scoreRepliedCheckFormItems = [];
  @observable repliedCaseId = null;

  @observable isInit = true;
  @observable isAwait = false;

  // 選擇被指派的
  @observable assignedSelect = null;
  // 選擇已接受的案件
  @observable acceptedSelect = null;
  // 選擇開始稽查的案件
  @observable progressSelect = null;
  // 選擇退件的案件
  @observable returnedSelect = null;

  // 案件Id, 稽查, 退回使用
  caseId = null;

  router = null;

  @computed
  get check() {
    return {
      hasCases: !!this.cases,
      hasCheckFormItems: !!this.checkFormItems.length
    };
  }

  @computed
  get disabled() {
    return {
      reject: this.isAwait || !this.assignedSelect,
      resolve: this.isAwait || !this.assignedSelect,
      accepted: this.isAwait || !this.acceptedSelect,
      replied: this.isAwait || !this.repliedCaseId?.trim(),
      checkFormItem: this.isAwait
    };
  }

  @computed
  get options() {
    const assigned = this.check.hasCases
      ? this.getProjectOptions(this.cases.assigned.projects)
      : [];

    const accepted = this.check.hasCases
      ? this.getProjectOptions(this.cases.accepted.projects)
      : [];

    const progress = this.check.hasCases
      ? this.getProjectOptions(this.cases.progress.projects)
      : [];

    const returned = this.check.hasCases
      ? this.getProjectOptions(this.cases.returned.projects)
      : [];

    return {
      assigned,
      accepted,
      progress,
      returned
    };
  }

  // 統計使用
  @computed
  get score() {
    const res = getScore(this.scoreRepliedCheckFormItems);

    return res;
  }

  constructor(props) {
    this.router = props.router;
    makeObservable(this);
  }

  didMount = (props) => {
    const searches = routerMethods.getSearch(props.router, [
      DEV_ROUTE_SEARCHES.user,
      DEV_ROUTE_SEARCHES.password
    ]);
    const account = searches.user?.trim();
    const password = searches.password?.trim();

    if (!this.isAwait && account && password) {
      this.postUserLoginAPI({
        account,
        password
      });
    }
  };

  getProjectOptions = (projects) => {
    return projects.map((project) => {
      return {
        value: project.projectId,
        label: project.projectName,
        children: project.cases.map((cItem) => {
          return {
            value: cItem.caseId,
            label: cItem.caseName
          };
        })
      };
    });
  };

  @action
  onChangeAssignedSelect = (value) => {
    this.assignedSelect = value;

    console.log('選擇被指派的案件', 'assignedSelect', value);
  };

  @action
  onChangeAcceptedSelect = (value) => {
    this.acceptedSelect = value;

    console.log('選擇已接受的案件', 'acceptedSelect', value);
  };

  @action
  onChangeProgressSelect = (value) => {
    this.progressSelect = value;

    if (value) {
      this.caseId = String(value[1]);
      this.getCheckFormReplyAPI();
    }

    console.log('選擇開始稽查的案件', 'progressSelect', value);
  };

  @action
  onChangeReturnedSelect = (value) => {
    this.returnedSelect = value;

    if (value) {
      this.caseId = String(value[1]);
      this.getCheckFormReplyAPI();
    }

    console.log('退件的案件', 'returnedSelect', value);
  };

  @action
  onChangeRepliedCaseId = (event) => {
    this.repliedCaseId = event.target.value;
  };

  onAccept = () => {
    this.putUserCaseAcceptAPI();
  };

  onReject = () => {
    this.putUserCaseRejectAPI();
  };

  // 儲存答案
  onSave = () => {
    const res = this.checkFormItems.map((item) => item.res);

    this.replyLoop();

    console.log('save', 'res', res);
  };

  // 稽查結束, 或是退件再送出
  onReplyEnd = async () => {
    const caseId = this.caseId;

    await this.replyLoop();
    await this.putUserCaseActionAPI(caseId);
    await this.putUserCaseActionAPI(caseId, true);
  };

  @action
  replyLoop = async () => {
    this.isAwait = true;

    const res = this.checkFormItems.map((item) => item.res);

    await Promise.all(
      res.map((item) => {
        return this.postCheckFormReplyAPI(item);
      })
    );

    runInAction(() => {
      this.isAwait = false;
    });
  };

  // 案件推進
  onCaseAction = () => {
    const caseId = String(this.acceptedSelect[1]);

    this.putUserCaseActionAPI(caseId, true);
  };

  // 讀取已完成的查檢表
  onReplied = () => {
    this.getRepliedCheckFormReplyAPI();
  };

  @action
  reset = () => {
    this.cases = null;
    this.checkFormItems = [];

    this.isInit = true;

    this.assignedSelect = null;
    this.acceptedSelect = null;
    this.progressSelect = null;
    this.repliedCaseId = null;

    this.repliedCheckFormItems = [];
    this.scoreRepliedCheckFormItems = [];

    this.getUserCaseAPI();
  };

  // 訪員登入
  @action
  postUserLoginAPI = async ({ account, password }) => {
    this.isAwait = true;

    try {
      const res = await UserServices.postUserLogin({ account, password });

      runInAction(() => {
        this.profile = res.data;

        this.getUserCaseAPI({ folder: 'assigned' });
        this.getUserCaseAPI({ folder: 'accepted' });
        this.getUserCaseAPI({ folder: 'progress' });
        this.getUserCaseAPI({ folder: 'returned' });

        console.log('profile', res.data);
      });
    } catch (error) {
      const msg = ErrorServices.postUserLogin(error);

      console.log('ReplyPageViewModel', 'postUserLogin', msg);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };

  // 訪員所有的案件
  @action
  getUserCaseAPI = async ({ folder }) => {
    this.isAwait = true;

    try {
      const res = await AppServices.getUserCase({
        folder
      });

      runInAction(() => {
        this.cases[folder].projects = res.data;

        console.log('cases', 'folder', res.data);
      });
    } catch (error) {
      const msg = ErrorServices.getUserCase(error);

      console.log('ReplyPageViewModel', 'getUserCase', msg);
    } finally {
      runInAction(() => {
        this.isInit = false;
        this.isAwait = false;
      });
    }
  };

  // 訪員接受案件
  @action
  putUserCaseAcceptAPI = async () => {
    this.isAwait = true;

    try {
      const projectId = String(this.assignedSelect[0]);
      const res = await AppServices.putUserCaseAccept({ projectId });

      this.reset();
    } catch (error) {
      const msg = ErrorServices.putUserCaseAccept(error);

      console.log('ReplyPageViewModel', 'putUserCaseAccept', msg);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };

  // 拒絕案件
  @action
  putUserCaseRejectAPI = async () => {
    this.isAwait = true;

    try {
      const projectId = String(this.assignedSelect[0]);
      const res = await AppServices.putUserCaseReject({ projectId });

      this.reset();
    } catch (error) {
      const msg = ErrorServices.putUserCaseReject(error);

      console.log('ReplyPageViewModel', 'putUserCaseReject', msg);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };

  // 案件推進
  @action
  putUserCaseActionAPI = async (caseId, reset = false) => {
    this.isAwait = true;

    try {
      const res = await AppServices.putUserCaseAction({ caseId });

      if (reset) {
        this.reset();
      }
    } catch (error) {
      const msg = ErrorServices.putUserCaseAction(error);

      window.location.reload();

      console.log('ReplyPageViewModel', 'putUserCaseAction', msg);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };

  // 訪員讀取查檢表答案
  @action
  getCheckFormReplyAPI = async () => {
    this.isAwait = true;

    try {
      const res = await AppServices.getCheckFormReply({ caseId: this.caseId });

      runInAction(() => {
        this.checkFormReply = res.data;
        this.checkFormItems = res.data.checkFormItem.map((item) => {
          return new CheckFormItemViewModel({ data: item, caseId: this.caseId });
        });

        console.log('check form reply', res.data);
      });
    } catch (error) {
      const msg = ErrorServices.getCheckFormReply(error);

      console.log('ReplyPageViewModel', 'getCheckFormReply', msg);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };

  // 訪員回答問題
  @action
  postCheckFormReplyAPI = async (data) => {
    try {
      const res = await AppServices.postCheckFormReply(data);
    } catch (error) {
      const msg = ErrorServices.postCheckFormReply(error);

      console.log('ReplyPageViewModel', 'postCheckFormReply', msg);
    }
  };

  // 訪員讀取已完成的答案
  @action
  getRepliedCheckFormReplyAPI = async () => {
    this.isAwait = true;
    this.repliedCheckFormItems = [];
    this.scoreRepliedCheckFormItems = [];

    try {
      const caseId = this.repliedCaseId;
      const res = await AppServices.getCheckFormReply({
        caseId
      });

      runInAction(() => {
        this.repliedCheckFormItems = res.data.checkFormItem.map((item) => {
          return new CheckFormItemViewModel({ data: item, caseId });
        });
        this.scoreRepliedCheckFormItems = res.data.checkFormItem;

        console.log('items', this.score);
        console.log('score', this.score);
      });
    } catch (error) {
      const msg = ErrorServices.getCheckFormReply(error);

      console.log('ReplyPageViewModel', 'getCheckFormReply', msg);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default ReplyPageViewModel;
