import { request, getHost } from './utils';

class AppAPIs {
  // 讀取訪員案件
  static getUserCase({ token, folder, limit, anchor }) {
    const options = {
      method: 'get',

      url: `${getHost()}/v1/user/case/data`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      params: {
        folder,
        limit,
        anchor
      }
    };

    return request(options);
  }

  // 訪員接受案件
  static putUserCaseAccept({ token, projectId }) {
    const options = {
      method: 'put',

      url: `${getHost()}/v1/user/case/accept/${projectId}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      }
    };

    return request(options);
  }

  // 訪員拒絕案件
  static putUserCaseReject({ token, projectId }) {
    const options = {
      method: 'put',

      url: `${getHost()}/v1/user/case/reject/${projectId}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      }
    };

    return request(options);
  }

  // 案件推進
  static putUserCaseAction({ token, caseId }) {
    const options = {
      method: 'put',

      url: `${getHost()}/v1/user/case/action/${caseId}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      }
    };

    return request(options);
  }

  // 訪員讀取查檢表答案
  static getCheckFormReply({ token, caseId }) {
    const options = {
      method: 'get',

      url: `${getHost()}/v1/checkForm/reply/${caseId}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      }
    };

    return request(options);
  }

  // 訪員回答問題
  static postCheckFormReply({
    token,
    caseId,
    itemId,
    implementScore,
    satisScore,
    content
  }) {
    const options = {
      method: 'post',

      url: `${getHost()}/v1/checkForm/reply`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      data: {
        caseId,
        itemId,
        implementScore,
        satisScore,
        content
      }
    };

    return request(options);
  }
}

export default AppAPIs;
