class CompanyListItemLiteDataModel {
  constructor(props) {
    this.account = {
      isDisabled: props.account.isDisabled
    };

    this.accountId = props.accountId;

    this.name = props.name;
  }
}

export default CompanyListItemLiteDataModel;
