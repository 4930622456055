import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import StyleItem from 'src/components/StyleItem';
import FormList from 'src/components/ListItem';
import RejectItemViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class RejectItem extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new RejectItemViewModel({
      case: props.case,
      checkParams: props.checkParams
    });
  }

  componentDidUpdate(preProps) {
    this.vm.didUpdate(this.props, preProps);
  }

  render() {
    return this.vm.isShow ? (
      <div className={styles.container}>
        <FormList title={this.vm.label.title}>
          <StyleItem>
            <div className={styles.lineHeight}>
              {this.vm.label.content}
            </div>
          </StyleItem>
        </FormList>
      </div>
    ) : null;
  }
}

RejectItem.propTypes = {
  case: PropTypes.object.isRequired,
  checkParams: PropTypes.object.isRequired
};

RejectItem.defaultProps = {};

export default RejectItem;
