import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { observer } from 'mobx-react';
import ICONS from 'src/global/icons';
import styles from './styles.module.scss';

function AddButton(props) {
  const { label, ...ps } = props;

  return (
    <Button
      type="primary"
      className={styles.button}
      {...ps}
    >
      <div className={styles.content}>
        <div className={styles.iconContainer}>
          <ICONS.Add className={styles.icon} />
        </div>

        <div className={styles.label}>
          {label}
        </div>
      </div>
    </Button>
  );
}

AddButton.propTypes = {
  label: PropTypes.string
};

AddButton.defaultProps = {
  label: '新增按鈕'
};

export default AddButton;
