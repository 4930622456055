import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import CustomModalViewModel from 'src/components/CustomModal/viewModel';
import ApplyModalViewModel from '../ApplyModal/viewModel';

class ReasonModalViewModel {
  @observable reason = null;
  @observable list = [];
  modalVM = new CustomModalViewModel();
  applyModelViewModel = new ApplyModalViewModel();

  constructor(props) {
    makeObservable(this);
  }

  @action
  open = (reason) => {
    this.reason = reason;
    this.modalVM.show();
  };

}

export default ReasonModalViewModel;
