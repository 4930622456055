import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styles from './styles.module.scss';

function SelfRow(props) {
  return (
    <div className={styles.container}>
      {props.children}
    </div>
  );
}

SelfRow.propTypes = {
  children: PropTypes.node
};

SelfRow.defaultProps = {
  children: null
};

export default SelfRow;
