import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Form, Select, Input, Button, Divider, Popover } from 'antd';
import clsx from 'clsx';
import ICONS from 'src/global/icons';
import withProfile from 'src/components/withProfile';
import withRouter from 'src/components/withRouter';
import withForm from 'src/components/withForm';
import CustomBreadcrumb from 'src/components/CustomBreadcrumb';
import CustomHeader from 'src/components/CustomHeader';
import SimpleForm from 'src/components/SimpleForm';
import SimpleInputNumber from 'src/components/SimpleInputNumber';
import WarningModal from 'src/components/WarningModal';
import FixedItem from './components/FixedItem';
import DynamicItem from './components/DynamicItem';
import IncomeDetailTable from './components/IncomeDetailTable';
import ApplyModal from './components/ApplyModal';
import ApplyTable from './components/ApplyTable';
import BudgetDetailPageViewModel from './viewModel';
import styles from './styles.module.scss';
import './reset.scss';

// 請款與預算詳情
@observer
class BudgetDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new BudgetDetailPageViewModel({
      profile: props.profile,
      router: props.router,
      form: props.form
    });
  }

  componentDidMount() {
    this.vm.didMount();
  }

  // 儲存 + 完成 + 申請修改預估損益
  renderOperateButtons = () => {
    return (
      <div className={styles.operateContainer}>
        <div className={styles.buttonContainer}>
          <Button
            type="primary"
            onClick={this.vm.onSave}
            disabled={this.vm.disabled.save}
          >
            儲存
          </Button>
        </div>

        <div className={styles.buttonContainer}>
          <Button
            type="primary"
            onClick={this.vm.onFinish}
            disabled={this.vm.disabled.finish}
          >
            完成
          </Button>
        </div>

        <div className={styles.buttonContainer}>
          <Button
            type="primary"
            onClick={this.vm.applyModalVM.open}
            disabled={this.vm.disabled.applyRevise}
          >
            申請修改預估損益
          </Button>
        </div>
      </div>
    );
  };

  // 專案件酬試算
  renderAllowances = () => {
    return (
      <div className={styles.allowancesContainer}>
        <div className={styles.title}>專案件酬試算</div>

        <div className={styles.overflowX}>
          <div className={styles.allowancesContent}>
            <div className={styles.itemsContainer}>
              <div className={clsx(['allowances', styles.items])}>
                <Form.Item
                  label="業種(行業別)"
                  name="industry"
                  rules={this.vm.required}
                >
                  <Select
                    size="small"
                    options={this.vm.options.industries}
                    onChange={this.vm.onSelectChange}
                    dropdownMatchSelectWidth={false}
                  />
                </Form.Item>

                <Form.Item
                  label="場域選擇"
                  name="place"
                  rules={this.vm.required}
                >
                  <Select
                    mode="multiple"
                    size="small"
                    options={this.vm.options.places}
                    onChange={this.vm.onSelectChange}
                    dropdownMatchSelectWidth={false}
                  />
                </Form.Item>

                {/* 專案件酬試算, 左側固定欄位 */}
                {this.vm.dataVM.mapAllowances.before.map((vm) => {
                  return (
                    <FixedItem
                      vm={vm}
                      disabled={this.vm.disabled.allowances}
                      key={vm.id}
                    />
                  );
                })}
              </div>

              <div className={styles.items}>
                {this.vm.dataVM.mapAllowances.center.map((vm) => {
                  return (
                    <FixedItem
                      key={vm.id}
                      vm={vm}
                      disabled={this.vm.disabled.allowances}
                    />
                  );
                })}

                {/* 專案件酬試算, 動態欄位 */}
                {this.vm.dataVM.dynamicAllowances.map((vm) => {
                  return (
                    <DynamicItem
                      vm={vm}
                      disabled={this.vm.disabled.allowances}
                      key={vm.id}
                      onDelete={this.vm.dataVM.deleteAllowance}
                    />
                  );
                })}

                {/* 專案件酬試算, 新增動態欄位按鈕 */}
                {this.vm.dataVM.showAddDynamicAllowanceButton && (
                  <div className={styles.addDynamicItemButtonContainer}>
                    <Button
                      type="link"
                      size="small"
                      className={styles.button}
                      onClick={this.vm.dataVM.addAllowance}
                      disabled={this.vm.disabled.allowances}
                    >
                      ＋新增其他條件加給
                    </Button>
                  </div>
                )}
              </div>

              <div className={styles.items}>
                {this.vm.dataVM.mapAllowances.after.map((vm) => {
                  return (
                    <FixedItem
                      key={vm.id}
                      vm={vm}
                      disabled={this.vm.disabled.allowances}
                    />
                  );
                })}
              </div>
            </div>

            {/* 專案件酬結果區塊 */}
            <div className={styles.resContainer}>
              <div className={styles.divide} />

              {/* 計算結果區塊 */}
              <div className={styles.resContainer}>
                <div className={styles.resTable}>
                  <div className={styles.labels}>
                    <div className={styles.label}>案件類別</div>
                    <div className={styles.label}>案件數量</div>
                    <div className={styles.label}>訪員成本費用</div>
                  </div>

                  <div className={styles.contents}>
                    <div className={styles.content}>現場</div>

                    <div className={styles.content}>
                      <Input
                        size="small"
                        value={this.vm.dataVM.onSiteCaseLength}
                        onChange={this.vm.onChangeOnSiteCaseLength}
                        disabled={this.vm.disabled.allowances}
                        maxLength={50}
                      />
                    </div>

                    <div className={styles.label}>
                      {this.vm.dataVM.cases.onSite.total}
                    </div>
                  </div>

                  <div className={styles.contents}>
                    <div className={styles.content}>非現場</div>

                    <div className={styles.content}>
                      <Input
                        size="small"
                        value={this.vm.dataVM.offSiteCaseLength}
                        onChange={this.vm.onChangeOffSiteCaseLength}
                        disabled={this.vm.disabled.allowances}
                        maxLength={50}
                      />
                    </div>

                    <div className={styles.label}>
                      {this.vm.dataVM.cases.offSite.total}
                    </div>
                  </div>

                  <div className={styles.contents}>
                    <div className={styles.content}>其他</div>

                    <div className={styles.content}>
                      <Input
                        size="small"
                        value={this.vm.dataVM.otherCaseLength}
                        onChange={this.vm.onChangeOtherCaseLength}
                        disabled={this.vm.disabled.allowances}
                        maxLength={50}
                      />
                    </div>

                    <div className={styles.label}>
                      {this.vm.dataVM.cases.other.total}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // 專案損益表
  renderIncome = () => {
    return (
      <div className={styles.incomeContainer}>
        <div className={styles.title}>專案損益表</div>

        {/* 損益結果表格 */}
        <div className={styles.resTableContainer}>
          <div className={styles.labels}>
            <div className={styles.label}>專案收入(未稅)</div>

            <div className={styles.label}>專案預估費用</div>

            <div className={styles.label}>
              <div className={styles.text}>專案預估毛利</div>

              <Popover
                placement="topRight"
                title="專案預估毛利公式"
                content="專案收入(未稅) - 專案預估費用"
              >
                <ICONS.BudgetFormula className={styles.icon} />
              </Popover>
            </div>

            <div className={styles.label}>
              <div className={styles.text}>預估毛利率</div>

              <Popover
                placement="topRight"
                title="預估毛利率公式"
                content="專案預估毛利 / 專案收入(未稅)"
              >
                <ICONS.BudgetFormula className={styles.icon} />
              </Popover>
            </div>

            <div className={styles.label}>
              <div className={styles.text}>專案結算費用</div>
            </div>

            <div className={styles.label}>
              <div className={styles.text}>專案結算毛利</div>

              <Popover
                placement="topRight"
                title="專案結算毛利公式"
                content="專案收入(未稅) -  專案結算費用"
              >
                <ICONS.BudgetFormula className={styles.icon} />
              </Popover>
            </div>

            <div className={styles.label}>
              <div className={styles.text}>結算毛利率</div>

              <Popover
                placement="topRight"
                title="結算毛利率公式"
                content="專案結算毛利 / 專案收入(未稅)"
              >
                <ICONS.BudgetFormula className={styles.icon} />
              </Popover>
            </div>
          </div>

          <div className={styles.contents}>
            <div className={clsx(['income', styles.content])}>
              <Form.Item
                name="income"
                rules={this.vm.required}
                disabled={this.vm.disabled.income}
              >
                <SimpleInputNumber
                  controls={false}
                  size="small"
                  onChange={this.vm.onChangeIncome}
                  min={0}
                  maxLength={50}
                />
              </Form.Item>
            </div>

            <div className={styles.content}>
              {this.vm.dataVM.estimatedFee.label}
            </div>

            <div className={styles.content}>
              {this.vm.dataVM.estimatedIncome.label}
            </div>

            <div className={styles.content}>
              {this.vm.dataVM.estimatedIncome.ratioLabel}
            </div>

            <div className={styles.content}>
              {this.vm.dataVM.resultFee.label}
            </div>

            <div className={styles.content}>
              {this.vm.dataVM.resultIncome.label}
            </div>

            <div className={styles.content}>
              {this.vm.dataVM.resultIncome.ratioLabel}
            </div>
          </div>
        </div>

        {/* 損益詳細表格 */}
        <div className={styles.detailTableRow}>
          <IncomeDetailTable
            data={this.vm.dataVM.incomes}
            disabledExpected={this.vm.disabled.estimated}
            disabledResult={this.vm.disabled.result}
          />
        </div>
      </div>
    );
  };

  render() {
    return this.vm.check.isShow ? (
      <div id="budgetDetailPage" className={styles.container}>
        <CustomBreadcrumb routes={this.vm.routes} />

        <CustomHeader
          title={this.vm.project.projectName}
          hasBack
          link={this.vm.backLink}
          paddingBottom
        />

        {this.vm.check.isShowOperateButtons && this.renderOperateButtons()}

        <div className={styles.mainContent}>
          {/* 專案預估損益申請修改紀錄 + 專案損益表 */}
          <div className={styles.formContainer}>
            {/* 專案預估損益申請修改紀錄 */}
            {this.vm.check.isHandler && (
              <ApplyTable
                profile={this.props.profile}
                data={this.vm.eventList}
                reload={this.vm.onApplied}
                disabled={this.vm.isAwait}
              />
            )}

            <SimpleForm
              form={this.vm.form}
              disabled={this.vm.disabled.allowances}
            >
              {/* 專案件酬試算 */}
              {this.renderAllowances()}

              <Divider />

              {/* 專案損益表 */}
              {this.renderIncome()}
            </SimpleForm>
          </div>
        </div>

        <ApplyModal
          vm={this.vm.applyModalVM}
          onConfirmed={this.vm.onApplied}
          disabled={this.vm.isAwait}
        />

        <WarningModal
          vm={this.vm.finishModalVM}
          content="「完成」後無法再編輯專案預估損益，確定繼續嗎？"
          onConfirm={this.vm.onFinishConfirm}
        />
      </div>
    ) : null;
  }
}

BudgetDetailPage.propTypes = {
  profile: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

BudgetDetailPage.defaultProps = {};

export default withProfile(withRouter(withForm(BudgetDetailPage)), true);
