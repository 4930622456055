import React from 'react';
import PropTypes from 'prop-types';
import { observer, Observer } from 'mobx-react';
import { Table, Input, Button } from 'antd';
import CustomTable from 'src/components/CustomTable';
import styles from './styles.module.scss';

function EditTable(props) {
  return (
    <CustomTable data={props.data} hasPadding>
      <Table.Column
        width={200}
        title="公告項目"
        render={(vm) => {
          return (
            <Observer>
              {() => {
              // 你是不是正在編輯
                const isEdit = props.checkEdit(vm);

                return isEdit ? (
                  <Input.TextArea
                    autoSize
                    maxLength={50}
                    showCount
                    value={vm.title}
                    onChange={vm.onChangeTitle}
                    disabled={props.isAwait}
                  />
                ) : (
                  <div className={styles.content}>{vm.title}</div>
                );
              }}
            </Observer>
          );
        }}
      />

      <Table.Column
        width={300}
        title="公告內容"
        render={(vm) => {
          return (
            <Observer>
              {() => {
                // 你是不是正在編輯
                const isEdit = props.checkEdit(vm);

                return isEdit ? (
                  <Input.TextArea
                    autoSize
                    maxLength={50}
                    showCount
                    value={vm.content}
                    onChange={vm.onChangeContent}
                    disabled={props.isAwait}
                  />
                ) : (
                  <div className={styles.content}>{vm.content}</div>
                );
              }}
            </Observer>
          );
        }}
      />

      <Table.Column
        title="公告連結"
        render={(vm) => {
          return (
            <Observer>
              {() => {
                // 你是不是正在編輯
                const isEdit = props.checkEdit(vm);

                return isEdit ? (
                  <Input.TextArea
                    autoSize
                    maxLength={400}
                    showCount
                    value={vm.path}
                    onChange={vm.onChangePath}
                    disabled={props.isAwait}
                  />
                ) : (
                  <a
                    className={styles.link}
                    href={vm.padPath}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {vm.path}
                  </a>
                );
              }}
            </Observer>
          );
        }}
      />

      <Table.Column
        width={100}
        render={(vm) => {
          return (
            <Observer>
              {() => {
                // 你是不是正在編輯
                const isEdit = props.checkEdit(vm);
                const disabled = isEdit ? props.isAwait : props.disableEdit;
                const label = isEdit ? '儲存' : '編輯';

                return (
                  <Button
                    type="link"
                    disabled={disabled}
                    onClick={() => {
                      if (isEdit) {
                        props.onSave(vm);
                      } else {
                        props.onEdit(vm);
                      }
                    }}
                  >
                    {label}
                  </Button>
                );
              }}
            </Observer>
          );
        }}
      />
    </CustomTable>
  );
}

EditTable.propTypes = {
  data: PropTypes.array,
  isAwait: PropTypes.bool,
  disableEdit: PropTypes.bool,
  checkEdit: PropTypes.func,
  onEdit: PropTypes.func,
  onSave: PropTypes.func
};

EditTable.defaultProps = {
  data: [],
  checkEdit: () => {},
  isAwait: false,
  disableEdit: false,
  onEdit: () => {},
  onSave: () => {}
};

export default observer(EditTable);
