import { request, getHost } from './utils';

// 損益表
class ProfitFormAPIs {
  // 讀取損益表
  static getProfitForm({ token, projectId }) {
    const options = {
      method: 'get',

      url: `${getHost()}/v1/profitForm/${projectId}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      }
    };

    return request(options);
  }

  // 更新損益表
  static postProfitFormUpsert({
    token,
    projectId,
    industry,
    place,
    pieceFee,
    estimatedProfit,
    resultProfit,
    complete, income
  }) {
    const options = {
      method: 'post',

      url: `${getHost()}/v1/profitForm/upsert`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      data: {
        projectId,
        industry,
        place,
        pieceFee,
        estimatedProfit,
        resultProfit,
        complete,
        income
      }
    };

    return request(options);
  }

  // 申請損益表修改
  static postCreateProfitFormEven({ token, projectId, content }) {
    const options = {
      method: 'post',

      url: `${getHost()}/v1/profitForm/apply`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      data: {
        projectId,
        content
      }
    };

    return request(options);
  }

  // 接受損益表修改要求
  static postAcceptProfitFormEven({ token, eventId }) {
    const options = {
      method: 'post',

      url: `${getHost()}/v1/profitForm/accept/${eventId}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      }
    };

    return request(options);
  }

  // 拒絕損益表修改要求
  static postRejectProfitFormEven({ token, eventId }) {
    const options = {
      method: 'post',

      url: `${getHost()}/v1/profitForm/reject/${eventId}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      }
    };

    return request(options);
  }

  // 更新損益表申請內容
  static postUpdateProfitFormEventContent({ token, eventId, content }) {
    const options = {
      method: 'post',

      url: `${getHost()}/v1/profitForm/apply/content/${eventId}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },
      data: {
        content
      }
    };

    return request(options);
  }

}

export default ProfitFormAPIs;
