import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import EmployeeServices from 'src/services/EmployeeServices';
import ErrorServices from 'src/services/ErrorServices';
import StaffViewModel from './viewModels/StaffViewModel';
import StaffEditModalViewModel from './components/StaffEditModal/viewModel';

class StaffPageViewModel {
  @observable profile = null;
  @observable data = [];
  @observable anchor = undefined;
  @observable isInit = true;
  @observable isAwait = false;

  modalVM = new StaffEditModalViewModel();

  constructor(props) {
    this.profile = props.profile;
    makeObservable(this);
  }

  didMount = () => {
    if (!this.isAwait) {
      this.postEmployeeListAPI();
    }
  }

  @action
  onCreate = () => {
    this.modalVM.onOpenCreate({ created: this.created });
  };

  @action
  created = () => {
    this.data = [];
    this.postEmployeeListAPI();
  };

  @action
  onEditChanged = (data) => {
    const editVM = this.data.find((vm) => vm.id === data.id);

    console.log('StaffPageViewModel', 'onEditChanged', editVM);
    console.log('StaffPageViewModel', 'onEditChanged', data);

    editVM.onEditChanged(data);
  };

  onScrollEnd = () => {
    if (!this.isAwait && this.anchor !== null) {
      this.postEmployeeListAPI();
    }
  };

  @action
  postEmployeeListAPI = async () => {
    this.isAwait = true;

    try {
      const res = await EmployeeServices.postEmployeeList({
        anchor: this.anchor
      });

      runInAction(() => {
        const newData = res.data.map((item) => new StaffViewModel(item));
        this.data = [...this.data, ...newData];
        this.anchor = res.anchor;
      });
    } catch (error) {
      const message = ErrorServices.postEmployeeList(error);

      console.log('StaffPageViewModel', 'postEmployeeList', message);
    } finally {
      runInAction(() => {
        this.isInit = false;
        this.isAwait = false;
      });
    }
  };
}

export default StaffPageViewModel;
