import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { ROUTE_PARAMS } from 'src/global/constants';
import StatisticsServices from 'src/services/StatisticsServices';
import CheckFormServices from 'src/services/CheckFormServices';
import ErrorServices from 'src/services/ErrorServices';
import BigNumber from 'bignumber.js';
import DateDrawerViewModel from '../DateDrawer/viewModel';
import DataViewModel from './viewModels/DataViewModel';

class DetailViewModel {
  @observable profile = null;
  // 客戶資料
  @observable client = null;
  // 統計資料
  @observable data = null;
  // 日期區間
  @observable dates = [];

  // 選擇的專案
  @observable projectId = null;

  @observable isAwait = false;

  clientId = null;

  dataVM = new DataViewModel(this);

  dateDrawerVM = new DateDrawerViewModel();

  @computed
  get check() {
    return {
      hasDates: !!this.dataVM.dates.length
    };
  }

  @computed
  get label() {
    const statistics = this.dataVM.statistics;
    const bImplement = new BigNumber(statistics?.implementAverage || 0);
    const bSatisfaction = new BigNumber(statistics?.satisAverage || 0);

    return {
      total: {
        implement: bImplement.toFormat(),
        satisfaction: bSatisfaction.toFormat()
      }
    };
  }

  constructor(props) {
    this.profile = props.profile;
    this.projectId = props.projectId;

    this.readRoute(props);

    makeObservable(this);
  }

  @action
  readRoute = (props) => {
    const clientId = props.router.params[ROUTE_PARAMS.clientId];
    const projectId = props.router.location.state?.[ROUTE_PARAMS.projectId];

    if (clientId === 'admin') {
      this.clientId = String(this.profile.id);
    } else {
      this.clientId = clientId;
    }

    if (projectId) {
      this.projectId = projectId;
    }
  };

  @action
  didMount = () => {
    if (!this.isAwait && this.projectId) {
      this.reset();
    }
  };

  @action
  didUpdate = (props, preProps) => {
    if (props.projectId && props.projectId !== preProps.projectId) {
      this.projectId = props.projectId;
      this.reset();
    }
  };

  // 套用權重至圖表
  @action
  onClickWeights = () => {
    if (this.projectId) {
      this.dataVM.setTotalFraction();
      this.dataVM.setCharts();
    }
  };

  onOpenDateDrawer = () => {
    this.dateDrawerVM.onOpen({
      projectId: this.projectId,
      dates: this.dataVM.dates
    });
  };

  @action
  reset = async () => {
    await Promise.all([
      this.getStatisticsDataAPI(),
      this.getStatisticsTimeListAPI(),
      this.getCheckFormItemAPI()
    ]);

    if (this.projectId) {
      this.dataVM.setTotalFraction();
      this.dataVM.setCharts();
    }
  };

  // 讀取全部統計資料
  @action
  getStatisticsDataAPI = async () => {
    // projectId 可能會不存在
    if (this.projectId) {
      this.isAwait = true;

      try {
        const res = await StatisticsServices.getStatisticsData({
          projectId: this.projectId
        });

        this.dataVM.setStatistics(res.data);
      } catch (error) {
        const msg = ErrorServices.getStatisticsData(error);

        console.log('DetailViewModel', 'getStatisticsData', msg);
      } finally {
        runInAction(() => {
          this.isAwait = false;
        });
      }
    }
  };

  // 讀取日期區段
  @action
  getStatisticsTimeListAPI = async () => {
    // projectId 可能會不存在
    if (this.projectId) {
      this.isAwait = true;

      try {
        const res = await StatisticsServices.getStatisticsTimeList({
          projectId: this.projectId
        });

        this.dataVM.setDates(res.data);
      } catch (error) {
        const msg = ErrorServices.getStatisticsTimeList(error);

        console.log('DetailViewModel', 'getStatisticsTimeList', msg);
      } finally {
        runInAction(() => {
          this.isAwait = false;
        });
      }
    }
  };

  // 讀取查檢表所有題目
  @action
  getCheckFormItemAPI = async () => {
    // projectId 可能會不存在
    if (this.projectId) {
      this.isAwait = true;

      try {
        const res = await CheckFormServices.getCheckFormItem({
          projectId: this.projectId
        });

        this.dataVM.setTopics(res.data);
      } catch (error) {
        const msg = ErrorServices.getCheckFormItem(error);

        runInAction(() => {
          this.topics = [];
        });

        console.log('DetailViewModel', 'getCheckFormItem', msg);
      } finally {
        runInAction(() => {
          this.isAwait = false;
        });
      }
    }
  };
}

export default DetailViewModel;
