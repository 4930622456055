import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import clsx from 'clsx';
import ICONS from 'src/global/icons';
import withRouter from '../withRouter';
import styles from './styles.module.scss';

class CustomHeader extends React.Component {
  onBack = () => {
    this.props.router.navigate(-1);

    console.log(this.props.router);
  };

  render() {
    return (
      <div className={clsx([this.props.paddingBottom && styles.paddingBottom])}>
        <div className={styles.header}>
          <Button
            type="text"
            className={styles.backButton}
            onClick={this.onBack}
          >
            <ICONS.Back className={styles.icon} />
          </Button>

          <div className={styles.title}>{this.props.title}</div>
        </div>
      </div>
    );
  }
}

CustomHeader.propTypes = {
  router: PropTypes.object.isRequired,
  title: PropTypes.string,
  paddingBottom: PropTypes.bool
};

CustomHeader.defaultProps = {
  title: '標題',
  paddingBottom: false
};

export default withRouter(CustomHeader);
