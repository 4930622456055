// 結案表

// 完成的選項
export const COMPLETION_STATUSES = {
  finished: {
    value: 'finished',
    label: '已完成'
  },

  unfinished: {
    value: 'unfinished',
    label: '未完成'
  },

  completedPart: {
    value: 'completedPart',
    label: '完成部分'
  }
};
