import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Drawer } from 'antd';
import styles from './styles.module.scss';
import './reset.scss';

function CustomDrawer(props) {
  return (
    <div id="customDrawer">
      <Drawer
        open={props.vm.isShow}
        width={props.width}
        getContainer={false}
        title={props.title}
        onClose={props.vm.hide}
        maskClosable={false}
        destroyOnClose={props.destroyOnClose}
      >
        <div className={styles.content}>{props.children}</div>
      </Drawer>
    </div>
  );
}

CustomDrawer.propTypes = {
  vm: PropTypes.object.isRequired,
  title: PropTypes.string,
  width: PropTypes.number,
  children: PropTypes.node,
  destroyOnClose: PropTypes.bool
};

CustomDrawer.defaultProps = {
  title: '標題',
  width: 800,
  children: null,
  destroyOnClose: false
};

export default observer(CustomDrawer);
