import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import withRouter from 'src/components/withRouter';
import CustomTable from 'src/components/CustomTable';
import CustomAnchor from 'src/components/CustomAnchor';
import InterviewerMissionViewModel from './viewModel';
import styles from './styles.module.scss';

// 訪員任務
@observer
class InterviewerMission extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new InterviewerMissionViewModel(props);
  }

  componentDidMount() {
    this.vm.didMount(this.props);
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.header}>{this.vm.label.id}</div>

        <div className={styles.tableContainer}>
          <CustomTable data={this.vm.anchorVM.sliceData} hasPadding hasScrollX>
            <Table.Column title="專案名稱" dataIndex="projectName" />

            <Table.Column title="案件總量" dataIndex="totalCount" />

            <Table.ColumnGroup title="現場案件數">
              <Table.Column title="現場案件數" dataIndex="onSiteCount" />
              <Table.Column title="已拒絕" dataIndex="onSiteRejected" />
              <Table.Column title="已承件" dataIndex="onSiteAccepted" />
              <Table.Column title="啟動稽查" dataIndex="onSiteStarted" />
              <Table.Column title="允收" dataIndex="onSiteSucceed" />
            </Table.ColumnGroup>

            <Table.ColumnGroup title="非現場案件數">
              <Table.Column title="非場案件數" dataIndex="offSiteCount" />
              <Table.Column title="已拒絕" dataIndex="offSiteRejected" />
              <Table.Column title="已承件" dataIndex="offSiteAccepted" />
              <Table.Column title="啟動稽查" dataIndex="offSiteStarted" />
              <Table.Column title="允收" dataIndex="offSiteSucceed" />
            </Table.ColumnGroup>

            <Table.Column title="起迄日期" dataIndex="startEndDate" />
          </CustomTable>
        </div>

        <CustomAnchor vm={this.vm.anchorVM} />
      </div>
    );
  }
}

export default withRouter(InterviewerMission);
