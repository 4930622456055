import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

function CustomLink(props) {
  return <Link className={styles.link} to={props.to}>{props.children}</Link>;
}

CustomLink.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string
};

CustomLink.defaultProps = {
  children: null,
  to: ''
};

export default CustomLink;
