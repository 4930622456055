import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import dayjs from 'dayjs';
import CustomDrawerViewModel from 'src/components/CustomDrawer/viewModel';
import StatisticsServices from 'src/services/StatisticsServices';
import ErrorServices from 'src/services/ErrorServices';
import DateViewModel from './viewModels/DateViewModel';

class DateDrawerViewModel {
  @observable dates = [];
  @observable isAwait = false;

  drawerVM = new CustomDrawerViewModel();

  projectId = null;
  onChanged = null;

  constructor(props) {
    makeObservable(this);
  }

  init = ({ onChanged }) => {
    this.onChanged = onChanged;
  };

  @action
  onOpen = ({ projectId, dates }) => {
    this.projectId = projectId;
    this.dates = dates.map((item) => new DateViewModel(item));

    this.drawerVM.show();
  };

  @action
  onCreate = () => {
    this.dates = [...this.dates, new DateViewModel()];
  };

  @action
  onDelete = (id) => {
    this.dates = this.dates.filter((item) => item.id !== id);
  };

  // 不能選擇的時間
  disabledDate = (current) => {
    const toDay = dayjs().startOf('date').valueOf();

    return false;
  };

  onSave = () => {
    this.postStatisticsTimeListAPI();
  };

  // 新增日期區段
  @action
  postStatisticsTimeListAPI = async () => {
    this.isAwait = false;

    try {
      const timeList = this.dates.filter((vm) => !!vm.res).map((vm) => vm.res);
      const res = await StatisticsServices.postStatisticsTimeList({
        projectId: this.projectId,
        timeList
      });

      this.drawerVM.hide();
      this.onChanged();
    } catch (error) {
      const msg = ErrorServices.postStatisticsTimeList(error);

      console.log('DateDrawerViewModel', 'postStatisticsTimeList', msg);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default DateDrawerViewModel;
