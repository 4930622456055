const theme = {
  token: {
    colorPrimaryBg: '#FFF1F0',
    colorPrimaryBgHover: '#ffccc7',
    colorPrimary: '#cf1322',
    colorInfo: '#cf1322',
    colorTextBase: '#252525',
    borderRadius: 2,
    wireframe: false
  }
};

export default theme;
