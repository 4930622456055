import { v4 } from 'uuid';

class VisitorDataModel {
  constructor(props) {
    // id
    this.accountId = props.accountId;

    // 停用或啟用
    this.isDisable = props.isDisable;

    // -------------------------------------------------------------------------
    // -------------------------------------------------------------------------
    // -------------------------------------------------------------------------

    // 基本資料

    // 身分證
    this.idNumber = props.idNumber;

    // 姓名
    this.name = props.name;

    // 代號
    this.nickName = props.nickName;

    // 性別
    this.gender = props.gender;

    // 生日
    this.birthday = props.birthday;

    // 婚姻
    this.marriage = props.marriage;

    // Email
    this.email = props.email;

    // Line ID
    this.lineId = props.lineId;

    // 手機
    this.phone = props.phone;

    // 最高學歷
    this.education = props.education;

    // 簽訂保密合約日
    this.signingDate = props.signingDate;

    // -------------------------------------------------------------------------
    // -------------------------------------------------------------------------
    // -------------------------------------------------------------------------

    // 生日賀卡
    this.greetingRecord = props.greetingRecord?.map((item) => {
      return {
        year: item.year
      };
    });

    // -------------------------------------------------------------------------
    // -------------------------------------------------------------------------
    // -------------------------------------------------------------------------

    // 執案條件

    // 地址
    this.address = props.address;

    // 擁有汽車品牌(無則免填)
    this.carItem = props.carItem;

    // 擁有機車品牌(無則免填)
    this.motorItem = props.motorItem;

    // 汽車駕照
    this.carLicense = props.carLicense;

    // 機車駕照
    this.motorLicense = props.motorLicense;

    // 壽險保戶
    this.insurance = props.insurance;

    // 電信用戶
    this.telecom = props.telecom;

    // 側錄設備有無
    this.haveDevice = props.haveDevice;

    // 側錄設備品項(無則免填)
    this.deviceItem = props.deviceItem;

    // 手機品牌
    this.phoneBrand = props.phoneBrand;

    // 可跑區域
    this.practiceCity = props.practiceCity.map((item) => {
      return {
        cityName: item.cityName
      };
    });

    // -------------------------------------------------------------------------
    // -------------------------------------------------------------------------
    // -------------------------------------------------------------------------

    // 能力培訓

    // 證照有無及意願
    this.licenseAndWillingness = props.licenseAndWillingness;

    // TSAA/發證日
    this.tssaDate = props.tssaDate;

    // SGS/發證日
    this.sgsDate = props.sgsDate;

    // 顧問神秘客/發證日
    this.consultantDate = props.consultantDate;

    // 受訓說明
    this.trainingCaption = props.trainingCaption;

    // 受訓取得日期
    this.trainingDate = props.trainingDate;

    // -------------------------------------------------------------------------
    // -------------------------------------------------------------------------
    // -------------------------------------------------------------------------

    // 執案履歷

    // 執案履歷 產業?
    this.visitorIndustry = props.visitorIndustry.map((item) => {
      return {
        id: v4(),
        category: item.category,
        count: item.count
      };
    });

    // 執案履歷 同業?
    this.peerDetails = props.peerDetails;

    // 執案履歷 評鑑?
    this.evaluation = props.evaluation.map((item) => {
      return { evaluation: item.evaluation };
    });

    // -------------------------------------------------------------------------
    // -------------------------------------------------------------------------
    // -------------------------------------------------------------------------

    // 績效紀錄

    // 特殊狀況
    this.performanceStatus = props.performanceStatus;

    // 特殊狀況說明
    this.performanceDirections = props.performanceDirections;

    // -------------------------------------------------------------------------
    // -------------------------------------------------------------------------
    // -------------------------------------------------------------------------

    // 實測通過日期
    this.passedDate = props.passedDate;

    // 備註
    this.remark = props.remark;

    // 評鑑註記
    this.testFlag = props.testFlag;

    // 登打者註記
    this.updaterId = props.updaterId;
    this.updaterName = props.updaterName;
    this.updatedAt = props.updatedAt;

    // 待補充
    this.visitorUpdated = props.visitorUpdated;

    // 新增時間
    this.createdAt = props.createdAt;
  }
}

export default VisitorDataModel;
