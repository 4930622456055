import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import dayjs from 'dayjs';
import { SITE_TYPES } from 'src/global/constants';
import { CASE_TYPES } from 'src/global/case';
import { CITIES } from 'src/global/cities';
import CustomDrawerViewModel from 'src/components/CustomDrawer/viewModel';
import ErrorServices from 'src/services/ErrorServices';
import CaseServices from 'src/services/CaseServices';
import { delay } from 'src/utils';
import { message } from 'antd';

// 新增或編輯案件
class CaseDrawerViewModel {
  @observable siteType = '';
  @observable caseVM = null;
  @observable isAwait = false;

  drawerVM = new CustomDrawerViewModel();

  project = null;

  form = null;

  onCreated = null;

  @computed
  get title() {
    return this.caseVM ? '編輯案件' : '新增案件';
  }

  @computed
  get required() {
    return [
      {
        required: true,
        message: '請輸入'
      }
    ];
  }

  @computed
  get check() {
    return {
      onSite: this.siteType === SITE_TYPES.onSite.value,
      offSite: this.siteType === SITE_TYPES.offSite.value
    };
  }

  @computed
  get options() {
    return {
      cities: CITIES.county,
      types: [
        CASE_TYPES.phone,
        CASE_TYPES.web,
        CASE_TYPES.app,
        CASE_TYPES.social
      ]
    };
  }

  constructor(props) {
    makeObservable(this);
  }

  init = ({ project, form, onCreated }) => {
    this.project = project;
    this.form = form;
    this.onCreated = onCreated;
  };

  // 新增. 現場案件
  @action
  onCreateOnSiteCase = () => {
    this.reset();

    this.siteType = SITE_TYPES.onSite.value;
    this.caseVM = null;
    this.drawerVM.show();
  };

  // 編輯, 現場案件
  @action
  onEditOnSiteCase = (caseVM) => {
    this.reset();

    this.setFormData(caseVM);

    this.siteType = SITE_TYPES.onSite.value;
    this.caseVM = caseVM;
    this.drawerVM.show();
  };

  // 新增, 非現場案件
  @action
  onCreateOffSiteCase = () => {
    this.reset();

    this.siteType = SITE_TYPES.offSite.value;
    this.caseVM = null;
    this.drawerVM.show();
  };

  // 編輯, 非現場案件
  @action
  onEditOffSiteCase = (caseVM) => {
    this.reset();

    this.setFormData(caseVM);

    this.siteType = SITE_TYPES.offSite.value;
    this.caseVM = caseVM;
    this.drawerVM.show();
  };

  @action
  reset = () => {
    this.siteType = null;
    this.caseVM = null;

    this.form.setFieldsValue({
      name: null,
      caseType: null,
      address: null,
      region: null,
      phone: null,
      businessHours: null,
      startDate: null,
      expiryDate: null,
      uploadPath: null,
      directions: null
    });
  };

  setFormData = (vm) => {
    // string or null
    const region = vm.region ? JSON.parse(vm.region) : null;

    const data = {
      name: vm.name,
      caseType: vm.caseType,
      address: vm.address,
      region,
      phone: vm.phone,
      businessHours: vm.businessHours,
      startDate: dayjs(vm.startDate),
      expiryDate: dayjs(vm.expiryDate),
      uploadPath: vm.uploadPath,
      directions: vm.directions
    };

    this.form.setFieldsValue(data);
  };

  getFormData = async () => {
    const formData = await this.form.validateFields();
    const region = formData.region ? JSON.stringify(formData.region) : null;
    const startDate = formData.startDate.toISOString();
    const expiryDate = formData.expiryDate.toISOString();

    // 檢查日期
    const startDateTime = formData.startDate.valueOf();
    const expiryDateTime = formData.expiryDate.valueOf();

    if (startDateTime > expiryDateTime) {
      const msg = '案件截止日早於執案開始日';

      message.error(msg, 5);

      throw new Error('Whoops!');
    }

    return {
      name: formData.name,
      caseType: formData.caseType,
      address: formData.address || null,
      region,
      phone: formData.phone || null,
      businessHours: formData.businessHours || null,
      startDate,
      expiryDate,
      uploadPath: formData.uploadPath || null,
      directions: formData.directions || null
    };
  };

  onSave = async () => {
    if (this.caseVM) {
      this.putCaseAPI();
    } else {
      this.postCaseCreateAPI();
    }
  };

  // 新增案件
  @action
  postCaseCreateAPI = async () => {
    this.isAwait = true;

    try {
      const data = await this.getFormData();
      const res = await CaseServices.postCaseCreate({
        projectId: this.project.projectId,
        site: this.siteType,
        info: { ...data }
      });

      await this.onCreated();

      this.drawerVM.hide();
    } catch (error) {
      const msg = ErrorServices.postCaseCreate(error);

      console.log('CaseDrawerViewModel', 'postCaseCreate', msg, error);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };

  // 更新案件
  @action
  putCaseAPI = async () => {
    this.isAwait = true;

    try {
      const data = await this.getFormData();
      const res = await CaseServices.putCase({
        id: this.caseVM.id,
        data
      });

      // 更新 case vm
      this.caseVM.update(data);

      this.drawerVM.hide();
    } catch (error) {
      const msg = ErrorServices.putCase(error);

      console.log('CaseDrawerViewModel', 'putCase', msg);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default CaseDrawerViewModel;
