import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Form, Button } from 'antd';
import WaveDetail from './components/WaveDetail';
import styles from './styles.module.scss';

class WaveDetails extends React.Component {
  getLastData = () => {
    const waveDetails = this.props.form.getFieldValue('waveDetails') || [];
    const length = waveDetails.length;
    return length ? waveDetails[length - 1] : undefined;
  }

  render() {
    return (
      <div className={styles.container}>
        <Form.List name="waveDetails">
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map((item, i) => {
                  return (
                    <div className={styles.waveDetailContainer} key={item.key}>
                      <WaveDetail
                        item={item}
                        index={i}
                        onRemove={remove}
                        disabled={this.props.disabled}
                      />
                    </div>
                  );
                })}

                <div className={styles.addButtonContainer}>
                  <Button
                    onClick={() => {
                      const data = this.getLastData();
                      add(data);
                    }}
                    disabled={this.props.disabled}
                  >
                    新增波段（複製上一波段執行資料）
                  </Button>
                </div>
              </>
            );
          }}
        </Form.List>
      </div>
    );
  }
}

WaveDetails.propTypes = {
  disabled: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired
};

WaveDetails.defaultProps = {};

export default observer(WaveDetails);
