import React from 'react';
import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction
} from 'mobx';
import {
  ROUTE_PARAMS,
  GENDER_TYPES,
  MARRIAGE_TYPES,
  WITH_OR_WITHOUT,
  YES_OR_NO,
  EVALUATORS
} from 'src/global/constants';
import { INDUSTRIES } from 'src/global/profitForm';
import { PRACTICE_CITIES, TELECOM_TYPES } from 'src/global/visitor';
import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import VisitorServices from 'src/services/VisitorServices';
import ErrorServices from 'src/services/ErrorServices';

class InterviewerDetailViewModel {
  @observable interviewerId = '';
  @observable data = null;
  @observable isEdit = false;
  @observable isAwait = false;
  formRef = React.createRef();
  router = null;
  updated=null;

  @computed
  get label() {
    const visitorIndustry = this.data.visitorIndustry.map((item) => {
      return {
        id: item.id,
        label: `${INDUSTRIES[item.category].label}${item.count}`
      };
    });
    const interviewerId = this.interviewerId.padStart(4, '0');
    const idNumber = this.data.idNumber.toLocaleUpperCase();

    return {
      title: `序號：${interviewerId}`,
      editLabel: this.isEdit ? '保存' : '編輯',
      visitorIndustry,
      idNumber,
      visitorUpdated: YES_OR_NO[this.data.visitorUpdated].label
    };
  }

  @computed
  get check() {
    return {
      hasData: !!this.data
    };
  }

  @computed
  get required() {
    return [
      {
        required: true,
        message: '請輸入'
      }
    ];
  }

  @computed
  get antOptions() {
    const startAtIns = dayjs('2020-01-01', 'YYYY-MM-DD');
    const endAtIns = dayjs().startOf('year');
    const diffDays = endAtIns.diff(startAtIns, 'year');
    const years = new BigNumber(diffDays).plus(1).toNumber();
    const mapYears = Array.from({ length: years }, (_, index) => {
      const year = String(startAtIns.year() + index);

      return {
        value: year,
        label: year
      };
    });

    return {
      gender: Object.values(GENDER_TYPES),
      marriage: Object.values(MARRIAGE_TYPES),
      years: mapYears,
      withOrWithout: Object.values(WITH_OR_WITHOUT),
      telecom: Object.values(TELECOM_TYPES),
      cities: Object.values(PRACTICE_CITIES),
      evaluators: Object.values(EVALUATORS)
    };
  }

  @computed
  get birthdayLabel() {
    if (this.data.birthday) {
      return dayjs(this.data.birthday).format('YYYY/MM/DD');
    }

    return '';
  }

  @computed
  get updateLabel() {
    if (this.data.updaterName && this.data.updatedAt) {
      const date = dayjs(this.data.updatedAt).format('YYYY-MM-DD HH:mm:ss');

      return `最近一次更新：${this.data.updaterName}, ${date}`;
    }

    return '';
  }

  constructor(props) {
    this.data = props.data;
    this.updated = props.updated;
    this.router = props.router;

    makeObservable(this);
  }

  didMount = (props) => {
    this.readRouteParams(props);
  };

  @action
  readRouteParams = (props) => {
    const interviewerId = props.router.params[ROUTE_PARAMS.interviewerId];
    this.interviewerId = interviewerId;
  };

  setFormData = (data) => {
    // 生日
    const birthday = data.birthday ? dayjs(data.birthday) : null;
    // 簽訂保密合約日
    const signingDate = data.signingDate ? dayjs(data.signingDate) : null;
    // 生日賀卡
    const greetingRecord = data.greetingRecord
      ? data.greetingRecord.map((item) => String(item.year))
      : null;
    // 可跑區域
    const practiceCity = data.practiceCity
      ? data.practiceCity.map((item) => item.cityName)
      : null;
    // tssa發證日
    const tssaDate = data.tssaDate ? dayjs(data.tssaDate) : null;
    // sgs發證日
    const sgsDate = data.sgsDate ? dayjs(data.sgsDate) : null;
    // 顧問神秘客發證日
    const consultantDate = data.consultantDate
      ? dayjs(data.consultantDate)
      : null;
    // 受訓取得日期
    const trainingDate = data.trainingDate ? dayjs(data.trainingDate) : null;
    // 實測通過日期
    const passedDate = data.passedDate ? dayjs(data.passedDate) : null;
    // 執案履歷, 評鑑
    const evaluation = data.evaluation
      ? data.evaluation.map((item) => item.evaluation)
      : null;

    this.formRef.current.setFieldsValue({
      ...this.data,
      birthday,
      signingDate,
      greetingRecord,
      practiceCity,
      tssaDate,
      sgsDate,
      consultantDate,
      trainingDate,
      passedDate,
      evaluation
    });
  };

  getFormData = async () => {
    const data = await this.formRef.current.validateFields();
    // 簽訂保密合約日
    const signingDate = data.signingDate?.toISOString();
    // tssa發證日
    const tssaDate = data.tssaDate?.toISOString();
    // sgs發證日
    const sgsDate = data.sgsDate?.toISOString();
    // 顧問神秘客發證日
    const consultantDate = data.consultantDate?.toISOString();
    // 受訓取得日期
    const trainingDate = data.trainingDate?.toISOString();
    // 實測通過日期
    const passedDate = data.passedDate?.toISOString();

    return {
      ...data,
      signingDate,
      tssaDate,
      sgsDate,
      consultantDate,
      trainingDate,
      passedDate,
      greetingYears: data.greetingRecord.map((item) => Number(item)),
      workingCounty: data.practiceCity,
      idNumber: undefined,
      birthday: undefined
    };
  };

  @action
  onChangeEdit = async () => {
    if (this.isEdit) {
      this.putVisitorUpdateAPI();
    } else {
      this.setFormData(this.data);
      this.isEdit = true;
    }
  };

  @action
  putVisitorUpdateAPI = async () => {
    this.isAwait = true;

    try {
      const data = await this.getFormData();
      const res = await VisitorServices.putVisitorUpdate({
        id: this.interviewerId,
        data
      });

      this.getAccountProfileAPI();
    } catch (error) {
      const message = ErrorServices.putVisitorUpdate(error);

      runInAction(() => {
        this.isAwait = false;

        console.log('InterviewerDetailViewModel', 'putVisitorUpdate', message);
      });
    }
  };

  // call api
  getAccountProfileAPI = async () => {
    try {
      const res = await VisitorServices.getAccountProfile({
        id: this.interviewerId
      });

      runInAction(() => {
        this.data = res.data;
        this.isEdit = false;

        this.updated(res.data);
      });
    } catch (error) {
      const message = ErrorServices.getAccountProfile(error);

      console.log(
        'InterviewerDetailPageViewModel',
        'getAccountProfile',
        message
      );
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default InterviewerDetailViewModel;
