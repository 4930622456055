import React from 'react';
import PropTypes from 'prop-types';
import { observer, Observer } from 'mobx-react';
import CustomDrawer from 'src/components/CustomDrawer';
import { Button, Input, Table, Checkbox } from 'antd';
import CustomTable from 'src/components/CustomTable';
import WarningModal from 'src/components/WarningModal';
import styles from './styles.module.scss';

@observer
class BringInCheckFormDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({ onChanged: props.onChanged, project: props.project });
  }

  render() {
    return (
      <div className={styles.container}>
        <Button type="primary" onClick={this.vm.open}>
          帶入既有查檢表
        </Button>

        <CustomDrawer vm={this.props.vm.drawerVM} title="帶入既有查檢表">
          <div className={styles.drawerContent}>
            <div className={styles.searchContainer}>
              <div className={styles.label}>查檢表搜尋</div>

              <Input.Search
                className={styles.input}
                value={this.vm.search}
                onChange={this.vm.onChangeSearch}
                onSearch={this.vm.onSearch}
              />
            </div>

            <div className={styles.tableLabel}>
              <div className={styles.label}>搜尋結果</div>

              <div className={styles.total}>{this.vm.label.total}</div>
            </div>

            <CustomTable
              data={this.vm.data}
              hasOverflow
              onScrollEnd={this.vm.onScrollEnd}
            >
              <Table.Column
                title="選擇"
                render={(item) => {
                  return (
                    <Observer>
                      {() => {
                        const checked = item.projectId === this.vm.selectId;

                        return (
                          <Checkbox
                            checked={checked}
                            onChange={(event) => {
                              this.vm.onChangeSelectId(event, item);
                            }}
                          />
                        );
                      }}
                    </Observer>
                  );
                }}
              />

              <Table.Column title="專案名稱" dataIndex="projectName" />

              <Table.Column title="企業名稱" dataIndex="companyName" />

              <Table.Column
                title="查檢表最後更新時間"
                dataIndex="checkFormUpdateAtFormat"
              />
            </CustomTable>

            <div className={styles.resContainer}>
              <div className={styles.label}>{this.vm.label.selected}</div>

              <Button
                className={styles.saveButton}
                type="primary"
                onClick={this.vm.onSave}
                disabled={this.vm.disabled.save}
              >
                帶入
              </Button>
            </div>
          </div>
        </CustomDrawer>

        <WarningModal
          vm={this.vm.warningModalVM}
          content="若帶入查檢表，則會覆蓋原有的檔案，確定要帶入嗎？"
          onConfirm={this.vm.onConfirmWarningModal}
          disabledConfirm={this.vm.isAwait}
        />
      </div>
    );
  }
}

BringInCheckFormDrawer.propTypes = {
  vm: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  onChanged: PropTypes.func.isRequired
};

BringInCheckFormDrawer.defaultProps = {};

export default BringInCheckFormDrawer;
