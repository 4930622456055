import BigNumber from 'bignumber.js';

// 落實度
function getImplementScore(data) {
  const answer = data.checkFormReply[0];

  switch (data.implementation) {
    case '0':
      return answer.implementScore || 0;

    case '1':
      return answer.implementScore === '1' ? data.implementWeighted : 0;

    default:
      return '0';
  }
}

// 滿意度
function getSatisScore(data) {
  const answer = data.checkFormReply[0];

  // satisfaction 滿意度類型
  // satisWeighted 滿意度最高分數
  // satisScore 訪員的評分

  switch (data.satisfaction) {
    // 自訂類型
    case '0':
      return answer.satisScore || 0;

    // satisScore: 1 ~ 5
    case '1':
      return new BigNumber(data.satisWeighted)
        .dividedBy(5)
        .multipliedBy(answer.satisScore || 0).toNumber();

    // satisScore: 0.25 ~ 1
    case '2':
      return new BigNumber(data.satisWeighted)
        .multipliedBy(answer.satisScore || 0).toNumber();

    default:
      return 0;
  }
}

function getScore(items) {
  const res = items.reduce(
    (sum, item) => {
      console.log('--------------------');
      console.log('題目', item.topic);
      console.log('說明', item.content);

      const answer = item.checkFormReply[0];

      const implementScore = getImplementScore(item);

      console.log('落實度');
      console.log('落實度類型', item.implementation);
      console.log('落實度最高分', item.implementWeighted);
      console.log('落實度答案', answer.implementScore);
      console.log('落實度得分', implementScore);

      const satisScore = getSatisScore(item);

      console.log('滿意度');
      console.log('滿意度類型', item.satisfaction);
      console.log('滿意度最高分', item.satisWeighted);
      console.log('滿意度答案', answer.satisScore);
      console.log('滿意度得分', satisScore);

      console.log('--------------------');

      return {
        bI: sum.bI.plus(implementScore),
        bS: sum.bS.plus(satisScore)
      };
    },
    {
      bI: new BigNumber(0),
      bS: new BigNumber(0)
    }
  );

  return {
    implementScore: res.bI.toNumber(),
    satisScore: res.bS.toNumber()
  };
}

export default getScore;
