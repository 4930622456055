import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import ExportCheckFormViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class ExportCheckForm extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new ExportCheckFormViewModel({
      project: props.project
    });
  }

  render() {
    return (
      <Button
        type="primary"
        onClick={this.vm.onExport}
        disabled={this.vm.isAwait}
      >
        匯出查檢表結果
      </Button>
    );
  }
}

ExportCheckForm.propTypes = {
  project: PropTypes.object.isRequired
};

ExportCheckForm.defaultProps = {};

export default ExportCheckForm;
