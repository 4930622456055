import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import withRouter from 'src/components/withRouter';
import StyleItem from 'src/components/StyleItem';
import CheckFormModal from 'src/components/CheckFormModal';
import CheckItemViewModel from './viewModel';

@observer
class CheckItem extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new CheckItemViewModel({
      project: props.project,
      case: props.case,
      checkParams: props.checkParams,
      router: props.router
    });
  }

  componentDidMount() {
    this.vm.didMount();
  }

  componentDidUpdate(preProps) {
    this.vm.didUpdate(this.props, preProps);
  }

  render() {
    return this.vm.isShow ? (
      <>
        <StyleItem>
          <div>查檢表</div>

          <Button
            type="primary"
            onClick={this.vm.onModalOpen}
            disabled={this.vm.disabled.button}
          >
            {this.vm.label.itemButton}
          </Button>
        </StyleItem>

        <CheckFormModal
          vm={this.vm.checkFormModalVM}
          edit={this.vm.checkFormEdit}
          disabled={this.vm.disabled.checkForm}
          detail
          onSave={this.vm.onSave}
        />
      </>
    ) : null;
  }
}

CheckItem.propTypes = {
  project: PropTypes.object.isRequired,
  case: PropTypes.object.isRequired,
  checkParams: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired
};

CheckItem.defaultProps = {};

export default withRouter(CheckItem);
