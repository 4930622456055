import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import withProfile from 'src/components/withProfile';
import withRouter from 'src/components/withRouter';
import CustomBreadcrumb from 'src/components/CustomBreadcrumb';
import CustomHeader from 'src/components/CustomHeader';
import ListItem from 'src/components/ListItem';
import DescTable from './components/DescTable';
import FormatItem from './components/FormatItem';
import BudgetItem from './components/BudgetItem';
import CheckItem from './components/CheckItem';
import DescriptionItem from './components/DescriptionItem';
import RemarkItemItem from './components/RemarkItem';
import GuideItem from './components/GuideItem';
import CloseItem from './components/CloseItem';
import CaseTable from './components/CaseTable';
import SelectPM from './components/SelectPM';
import PushButton from './components/PushButton';
import RejectForm from './components/RejectForm';
import InternalItem from './components/InternalItem';
import ProjectDetailPageViewModel from './viewModel';
import styles from './styles.module.scss';

// 專案詳情
@observer
class ProjectDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new ProjectDetailPageViewModel({
      profile: props.profile,
      router: props.router
    });
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return this.vm.hasData ? (
      <div className={styles.container}>
        <CustomBreadcrumb routes={this.vm.routes} />

        <CustomHeader
          title={this.vm.data.projectName}
          hasBack
          link={this.vm.backLink}
        />

        <div className={styles.content}>
          <DescTable
            project={this.vm.data}
            checkParams={this.vm.checkParams}
            onProjectNameChanged={this.vm.onProjectNameChanged}
          />

          <ListItem title="表單列表">
            {/* 總規格表 */}
            <FormatItem data={this.vm.data} checkParams={this.vm.checkParams} />

            {/* 查檢表 */}
            <CheckItem data={this.vm.data} checkParams={this.vm.checkParams} />

            {/* 專案簡述 */}
            <DescriptionItem
              project={this.vm.data}
              checkParams={this.vm.checkParams}
            />

            {/* 執案指導資訊 */}
            <GuideItem
              project={this.vm.data}
              checkParams={this.vm.checkParams}
            />

            {/* 結案表 */}
            <CloseItem
              project={this.vm.data}
              checkParams={this.vm.checkParams}
            />

            {/* 損益表 */}
            <BudgetItem data={this.vm.data} checkParams={this.vm.checkParams} />

            {/* 專案備註 */}
            <RemarkItemItem
              project={this.vm.data}
              checkParams={this.vm.checkParams}
            />

            {/* 溝通與說明紀錄(內部溝通專用) */}
            <InternalItem
              project={this.vm.data}
              checkParams={this.vm.checkParams}
            />
          </ListItem>

          {/* 案件表格 */}
          <CaseTable project={this.vm.data} checkParams={this.vm.checkParams} />

          {/* 專案推進 */}
          <PushButton
            data={this.vm.data}
            checkParams={this.vm.checkParams}
            onChanged={this.vm.onPushed}
          />

          {/* 選擇 pm */}
          <SelectPM data={this.vm.data} checkParams={this.vm.checkParams} />

          {/* 退件 */}
          <RejectForm
            data={this.vm.data}
            checkParams={this.vm.checkParams}
            onChanged={this.vm.onPushed}
          />
        </div>
      </div>
    ) : null;
  }
}

ProjectDetailPage.propTypes = {
  profile: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired
};

ProjectDetailPage.defaultProps = {};

export default withProfile(withRouter(ProjectDetailPage), true);
