import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction
} from 'mobx';
import { diff } from 'deep-diff';
import { SITE_TYPES, PROJECT_STATUS } from 'src/global/constants';
import CaseServices from 'src/services/CaseServices';
import ErrorServices from 'src/services/ErrorServices';
import SiteTable from './components/SiteTable';
import CaseViewModel from './viewModels/CaseViewModel';
import InterviewerDrawerViewModel from './components/InterviewerDrawer/viewModel';
import ImportModalViewModel from './components/ImportModal/viewModel';
import CaseDrawer from './components/CaseDrawer/viewModel';

class CaseTableViewModel {
  @observable project = null;
  @observable checkParams = null;
  @observable list = [];
  @observable toggleShow = true;
  @observable isAwait = false;
  @observable isReady = false;
  // 訪員 drawer
  interviewerDrawerVM = new InterviewerDrawerViewModel();
  // 案件 drawer
  caseDrawerVM = new CaseDrawer();
  // 批次匯入案件
  importModalVM = new ImportModalViewModel();

  @computed
  get isShow() {
    switch (this.project.step) {
      case PROJECT_STATUS.S5.value:
      case PROJECT_STATUS.S6.value:
      case PROJECT_STATUS.S7.value:
      case PROJECT_STATUS.S8.value:
      case PROJECT_STATUS.S9.value:
        return true;

      default:
        return false;
    }
  }

  @computed
  get disabled() {
    // 派案與執行
    const isSend = this.project.step === PROJECT_STATUS.S5.value;

    return {
      edit: !isSend || !this.checkParams.isHandlerOrBoss
    };
  }

  @computed
  get cases() {
    const onSite = [];
    const offSite = [];

    this.list.forEach((item) => {
      if (item.siteType === SITE_TYPES.onSite.value) {
        onSite.push(item);
      }

      if (item.siteType === SITE_TYPES.offSite.value) {
        offSite.push(item);
      }
    });

    return {
      onSite, offSite
    };
  }

  @computed
  get pages() {
    return [
      {
        key: SITE_TYPES.onSite.value,
        label: '現場稽查',
        children: (
          <SiteTable
            type={SITE_TYPES.onSite.value}
            data={this.cases.onSite}
            hasData={!!this.cases.onSite.length}
            disabled={this.disabled.edit}
            onOneDeleted={this.onOneDeleted}
            onManyDeleted={this.onManyDeleted}
            interviewerDrawerVM={this.interviewerDrawerVM}
            caseDrawerVM={this.caseDrawerVM}
            importModalVM={this.importModalVM}
          />
        )
      },
      {
        key: SITE_TYPES.offSite.value,
        label: '非現場稽查',
        children: (
          <SiteTable
            type={SITE_TYPES.offSite.value}
            data={this.cases.offSite}
            hasData={!!this.cases.offSite.length}
            disabled={this.disabled.edit}
            onOneDeleted={this.onOneDeleted}
            onManyDeleted={this.onManyDeleted}
            interviewerDrawerVM={this.interviewerDrawerVM}
            caseDrawerVM={this.caseDrawerVM}
            importModalVM={this.importModalVM}
          />
        )
      }
    ];
  }

  constructor(props) {
    this.init(props);
    makeObservable(this);
  }

  @action
  init = (props) => {
    this.project = props.project;
    this.checkParams = props.checkParams;
  };

  didMount = () => {
    if (!this.isAwait && this.isShow) {
      this.getCaseListAPI();
    }
  };

  didUpdate = (props, preProps) => {
    const diffProject = diff(props.project, preProps.project);
    const diffCheckParams = diff(props.checkParams, preProps.checkParams);

    if (diffProject || diffCheckParams) {
      this.init(props);
    }

    if (!this.isAwait && !this.isReady && this.isShow) {
      this.getCaseListAPI();
    }
  };

  // 新增完成
  onCreated = async () => {
    await this.getCaseListAPI();
  };

  // 刪除一個
  @action
  onOneDeleted = (id) => {
    this.deleteCaseAPI([id]);
  };

  // 刪除多個
  @action
  onManyDeleted = (keys) => {
    this.deleteCaseAPI(keys);
  };

  @action
  onToggleShow = () => {
    this.toggleShow = !this.toggleShow;
  };

  @action
  postCreateCaseAPI = () => {};

  // 讀取案件列表
  @action
  getCaseListAPI = async () => {
    this.isAwait = true;

    try {
      const projectId = this.project.projectId;
      const res = await CaseServices.getCase({ projectId });

      runInAction(() => {
        this.list = res.data.map((item) => new CaseViewModel(item));

        console.log('案件列表', res.data);
      });
    } catch (error) {
      const message = ErrorServices.getCase(error);

      console.log('CaseTableViewModel', 'getCase', message);
    } finally {
      runInAction(() => {
        this.isAwait = false;
        this.isReady = true;
      });
    }
  };

  // 刪除案件
  @action
  deleteCaseAPI = async (ids) => {
    this.isAwait = true;

    try {
      const res = await CaseServices.deleteCase({ ids });

      runInAction(() => {
        this.list = this.list.filter((item) => {
          const index = ids.findIndex((id) => id === item.id);

          return index === -1;
        });
      });
    } catch (error) {
      const message = ErrorServices.deleteCase(error);

      console.log('CaseTableViewModel', 'deleteCase', message);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default CaseTableViewModel;
