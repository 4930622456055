class ProjectDetailDataModel {
  constructor(props) {
    this.projectId = String(props.projectId);

    this.projectName = props.projectName;

    this.companyId = String(props.companyId);

    this.companyName = props.companyName;

    this.step = props.step;

    this.stepName = props.stepName;

    this.handler = props.handler;

    this.projectPMId = String(props.projectPMId);

    // 退件原因
    this.message = props.message;

    // 簡述
    this.about = props.about;

    // 備註
    this.comment = props.comment;

    this.guidance = props.guidance;

    this.createdAt = props.createdAt;

    this.updatedAt = props.updatedAt;

    // 專案查檢表是否完成
    this.checkFormCompleted = props.checkFormCompleted;

    // 專案損益表是否完成
    this.profitFormCompleted = props.profitFormCompleted;

    // ui table 使用
    this.id = String(props.projectId);
  }
}

export default ProjectDetailDataModel;
