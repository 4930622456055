import { useState } from 'react';
import { COMPLETION_STATUSES } from 'src/global/closingSheet';

function useOptions() {
  const [completionStatuses] = useState(Object.values(COMPLETION_STATUSES));

  return {
    completionStatuses
  };
}

export default useOptions;
