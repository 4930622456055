import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import {
  SOCIAL_SOFTWARES,
  APP_TYPES,
  OFFICIAL_WEBSITE_TYPES,
  ONSITE_TYPES,
  DIAL
} from 'src/global/form';

class CheckAreaDetailViewModel {
  @computed
  get mapOptions() {
    return {
      socialSoftware: Object.values(SOCIAL_SOFTWARES),
      appTypes: Object.values(APP_TYPES),
      officialWebsiteTypes: Object.values(OFFICIAL_WEBSITE_TYPES),
      onSiteTypes: Object.values(ONSITE_TYPES),
      dial: Object.values(DIAL)
    };
  }

  constructor(props) {
    makeObservable(this);
  }
}

export default CheckAreaDetailViewModel;
