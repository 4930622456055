import { v4 } from 'uuid';

class StatisticsDataModel {
  constructor(props) {
    // 按照日期統計?
    this.statisticsByDate = props.statisticsByDate;

    // 落實度
    this.implementAverage = props.implementAverage;

    // 滿意度
    this.satisAverage = props.satisAverage;

    // 現場構面平均落實度
    this.onSiteScoreList = props.onSiteScoreList.map((item) => {
      return {
        id: v4(),
        siteType: item.siteType,
        facet: item.facet,
        fractionScore: String(item.fractionScore)
      };
    });

    // 非現場構面平均落實度
    this.offSiteScoreList = props.offSiteScoreList.map((item) => {
      return {
        id: v4(),
        siteType: item.siteType,
        facet: item.facet,
        fractionScore: String(item.fractionScore)
      };
    });

    this.statisticsByDate = props.statisticsByDate.map((item) => {
      return {
        caseCount: item.caseCount,
        date: item.date,
        implementAverage: item.implementAverage,
        satisAverage: item.satisAverage,
        implementData: item.implementData.map((sItem) => {
          return {
            facet: sItem.facet,
            implementAverage: sItem.implementAverage
          };
        })
      };
    });
  }
}

export default StatisticsDataModel;
