import dayjs from 'dayjs';

// 匯出查檢表的題目 + 回答

class CheckFormExportItemDataModel {
  constructor(props) {
    // 題目
    this.topic = props.topic;
    // 說明
    this.content = props.content;

    // 構面
    this.facet = props.facet;
    // 次構面
    this.inferiorFacet = props.inferiorFacet;

    // 題目類型
    this.type = props.type;

    // 現場或是非現場
    this.site = props.site;

    // 落實度分數區間
    this.implementation = props.implementation;
    // 落實度分數
    this.implementWeighted = props.implementWeighted;

    // 滿意度分數區間
    this.satisfaction = props.satisfaction;
    // 滿意度分數
    this.satisWeighted = props.satisWeighted;

    // 答案
    this.checkFormReply = props.checkFormReply.map((item) => {
      return {
        auditStartTime: dayjs(item.AuditStartTime).format(
          'YYYY-MM-DD HH:mm:ss'
        ),
        auditEndTime: dayjs(item.AuditEndTime).format('YYYY-MM-DD HH:mm:ss'),
        visitorId: item.visitorId,
        visitorName: item.visitorName,
        visitorNickName: item.visitorNickName,
        implementScore: item.implementScore,
        satisScore: item.satisScore,
        content: item.content,
        targetName: item.targetName,
        caseStepName: item.caseStepName
      };
    });
  }
}

export default CheckFormExportItemDataModel;
