import { makeObservable, observable, action, computed } from 'mobx';
import { RE_NUMBER, SITE_TYPES } from 'src/global/constants';
import { TOPIC_FACETS } from 'src/global/topic';
import dayjs from 'dayjs';
import BigNumber from 'bignumber.js';

class DataViewModel {
  @observable statistics = null;
  @observable dates = [];
  @observable topics = [];
  // 長條圖
  @observable barChartsData = [];
  // 折線圖資料
  @observable barLineData = [];

  // 平均總分
  @observable totalFraction = '0';

  // 權重
  @observable weights = {
    implement: '50',
    satisfaction: '50'
  };

  @computed
  get disabled() {
    return {
      weights: !this.weights.implement && !this.weights.satisfaction
    };
  }

  @computed
  get inferiorFacets() {
    const onSiteScoreList = this.statistics?.onSiteScoreList || [];
    const onSite = onSiteScoreList
      .filter((item) => {
        return item.fractionScore !== null;
      })
      .map((item) => {
        const bFraction = new BigNumber(item.fractionScore);

        return {
          id: item.id,
          label: TOPIC_FACETS[item.facet]?.label,
          fraction: bFraction.isNaN() ? null : bFraction.toFormat()
        };
      });

    const offSiteScoreList = this.statistics?.offSiteScoreList || [];
    const offSite = offSiteScoreList
      .filter((item) => {
        return item.fractionScore !== null;
      })
      .map((item) => {
        const bFraction = new BigNumber(item.fractionScore);

        return {
          id: item.id,
          label: TOPIC_FACETS[item.facet]?.label,
          fraction: bFraction.isNaN() ? null : bFraction.toFormat()
        };
      });

    return {
      onSite,
      offSite
    };
  }

  @computed
  get lineChartWidth() {
    const length = this.barChartsData.length;

    if (length > 5) {
      return new BigNumber(length).multipliedBy(200).toNumber();
    }

    return undefined;
  }

  // 圖表最大刻度
  @computed
  get fullMarks() {
    const topics = this.groupSiteTopics(this.topics);
    const onSiteWeighted = this.reduceTopicsWeighted(topics.onSite);
    const offSiteWeighted = this.reduceTopicsWeighted(topics.offSite);
    const factionValues = onSiteWeighted
      .concat(offSiteWeighted)
      .sort((a, b) => b - a);
    const bigFullMarks = factionValues[0] || 100;
    const remainder = bigFullMarks % 20;
    const calcFullMarks = remainder
      ? 20 - remainder + bigFullMarks
      : bigFullMarks;

    return bigFullMarks > 100 ? calcFullMarks : 100;
  }

  // 圖表刻度數量
  get tickCount() {
    const length = this.fullMarks / 20 + 1;

    return length;
  }

  constructor() {
    makeObservable(this);
  }

  @action
  setStatistics = (val) => {
    this.statistics = val;
  };

  @action
  setDates = (val) => {
    this.dates = val;
  };

  @action
  setTopics = (val) => {
    this.topics = val;
  };

  // 使用場地分組題目
  groupSiteTopics = (topics) => {
    const onSite = [];
    const offSite = [];

    for (let i = 0; i < topics.length; i += 1) {
      const topic = topics[i];
      const isOnSite = topic.site === SITE_TYPES.onSite.value;

      if (isOnSite) {
        onSite.push(topic);
      } else {
        offSite.push(topic);
      }
    }

    return {
      onSite,
      offSite
    };
  };

  // 計算題目加權總分
  reduceTopicsWeighted = (topics) => {
    const faction = topics.reduce(
      (sum, item) => {
        const implementWeighted = item.implementWeighted;
        const satisWeighted = item.satisWeighted;
        let i = sum.i;
        let s = sum.s;

        if (implementWeighted) {
          i += Number(implementWeighted);
        }

        if (satisWeighted) {
          s += Number(satisWeighted);
        }

        return { i, s };
      },
      { i: 0, s: 0 }
    );

    return Object.values(faction);
  };

  // 計算平均總分
  @action
  setTotalFraction = () => {
    const bImplement = new BigNumber(this.statistics.implementAverage || 0);
    const bWImplement = new BigNumber(this.weights.implement);
    const checkWImplement = bWImplement.isNaN()
      ? 0
      : bWImplement.dividedBy(100).multipliedBy(bImplement);

    const bSatisfaction = new BigNumber(this.statistics.satisAverage || 0);
    const bWSatisfaction = new BigNumber(this.weights.satisfaction);
    const checkWSatisfaction = bWSatisfaction.isNaN()
      ? 0
      : bWSatisfaction.dividedBy(100).multipliedBy(bSatisfaction);

    const bFraction = new BigNumber(0)
      .plus(checkWImplement)
      .plus(checkWSatisfaction);

    this.totalFraction = bFraction.toFormat(2);
  };

  // 計算日期區間的圖表資料
  @action
  setCharts = () => {
    // 折線圖資料
    const barLineData = [];
    const statisticsByDate = this.statistics.statisticsByDate;
    // 設定的日期區段
    const mapDates = this.dates.map((date) => {
      const startAtTimestamp = dayjs(date.startTime).startOf('date').valueOf();
      const endAtTimestamp = dayjs(date.stopTime).endOf('date').valueOf();

      const filterDates = statisticsByDate.filter((fDate) => {
        const targetTimestamp = dayjs(fDate.date, 'YY-MM-DD').valueOf();
        const checkStartAt = startAtTimestamp <= targetTimestamp;
        const checkEndAt = endAtTimestamp >= targetTimestamp;

        return checkStartAt && checkEndAt;
      });

      // 先讀取構面資料,
      const initFacets = statisticsByDate[0] || {
        implementData: []
      };
      const reduceFacets = {};
      // 折線圖最少要 key, 才會有項目
      initFacets.implementData.forEach((item) => {
        reduceFacets[item.facet] = new BigNumber(0);
      });
      const facetCaseCount = {};

      const total = filterDates.reduce(
        (res, fDate) => { 

          const bi = new BigNumber(fDate.implementAverage || 0).multipliedBy(
            fDate.caseCount
          );
          const bs = new BigNumber(fDate.satisAverage || 0).multipliedBy(
            fDate.caseCount
          );

          let bT = new BigNumber(res.bT);
          let bI = new BigNumber(res.bI);
          let bS = new BigNumber(res.bS);

          if (!bi.isNaN() && !bs.isNaN()) {
            const biw = bi.dividedBy(100).multipliedBy(this.weights.implement);
            const bsW = bs
              .dividedBy(100)
              .multipliedBy(this.weights.satisfaction);

            bT = bT.plus(biw).plus(bsW);
          }

          if (!bi.isNaN()) {
            bI = res.bI.plus(bi);
          }

          if (!bs.isNaN()) {
            bS = res.bS.plus(bs);
          }

          // 平均構面
          const facets = res.facets;
         

          fDate.implementData.forEach((fItem) => {
            const bTf = new BigNumber(fItem.implementAverage);
            let bF = new BigNumber(facets[fItem.facet] || 0);

            if (!bTf.isNaN()) {
              bF = bF.plus(bTf);
            }
            
            facets[fItem.facet] = bF;
            if (!facetCaseCount[fItem.facet]) {
              facetCaseCount[fItem.facet] = 0;
          }
          facetCaseCount[fItem.facet]++;
          });

          return {
            bT,
            bI,
            bS,
            bC: res.bC.plus(fDate.caseCount),
            facets
          };
        },
        {
          // 平均總分
          bT: new BigNumber(0),
          // 平均落實度
          bI: new BigNumber(0),
          // 平均滿意度
          bS: new BigNumber(0),
          //  caseCount總和, 為了避免一天有多個案件導致計算錯誤
          bC: new BigNumber(0),
          // 平均構面
          facets: reduceFacets
        }
      );

      // -----------------------------------------------------------------------
      // -----------------------------------------------------------------------
      // -----------------------------------------------------------------------

      const startAtFormat = dayjs(date.startTime).format('YYYY-MM-DD');
      const endAtFormat = dayjs(date.stopTime).format('YYYY-MM-DD');

      // 避免 0 / 0 = NaN
      const length = total.bC.toNumber() || 1;

      console.log('filterDates', 'length', length);

      const res = {
        total: Number(total.bT.dividedBy(length).toFixed(2)),
        implementation: Number(total.bI.dividedBy(length).toFixed(2)),
        satisfaction: Number(total.bS.dividedBy(length).toFixed(2))
      };

      // -----------------------------------------------------------------------
      // -----------------------------------------------------------------------
      // -----------------------------------------------------------------------

      // 折線圖資料

      const lineDate = `${startAtFormat} ~ ${endAtFormat}`;

      barLineData.push({
        date: lineDate,
        label: '平均總分',
        value: res.total
      });

      barLineData.push({
        date: lineDate,
        label: '平均落實度',
        value: res.implementation
      });

      barLineData.push({
        date: lineDate,
        label: '平均滿意度',
        value: res.satisfaction
      });
      Object.keys(total.facets).forEach((key) => {
        const data = {
          date: lineDate,
          label: TOPIC_FACETS[key].label,
          value: Number(total.facets[key].dividedBy(facetCaseCount[key]).toFixed(2))
        };
        barLineData.push(data);
      });

      // -----------------------------------------------------------------------
      // -----------------------------------------------------------------------
      // -----------------------------------------------------------------------

      return {
        id: date.id,
        data: [
          {
            key: 'total',
            value: res.total,
            label: '平均總分',
            color: '#FFA629'
          },
          {
            key: 'implementation',
            value: res.implementation,
            label: '平均落實度',
            color: '#FFA9A7'
          },
          {
            key: 'satisfaction',
            value: res.satisfaction,
            label: '平均滿意度',
            color: '#FFC670'
          }
        ],
        startAt: startAtFormat,
        endAt: endAtFormat
      };
    });

    this.barChartsData = mapDates;
    this.barLineData = barLineData;
  };

  // 落實度權重
  @action
  onChangeWeightsImplement = (event) => {
    const value = String(Number(event.target.value));
    const checkValue = !!event.target.value;
    const isNumber = RE_NUMBER.test(value);
    const inMax = Number(value) <= 100;

    if (isNumber) {
      const satisfaction = new BigNumber(100).minus(value).toFixed(0);

      this.weights.implement = value;
      this.weights.satisfaction = satisfaction;
    }

    if (isNumber && !inMax) {
      this.weights.implement = '100';
      this.weights.satisfaction = '0';
    }

    if (!checkValue) {
      this.weights.implement = '';
      this.weights.satisfaction = '';
    }
  };

  // 滿意度權重
  @action
  onChangeWeightsSatisfaction = (event) => {
    const value = String(Number(event.target.value));
    const checkValue = !!event.target.value;
    const isNumber = RE_NUMBER.test(value);
    const inMax = Number(value) <= 100;

    if (isNumber) {
      const implement = new BigNumber(100).minus(value).toFixed(0);

      this.weights.implement = implement;
      this.weights.satisfaction = value;
    }

    if (isNumber && !inMax) {
      this.weights.implement = '0';
      this.weights.satisfaction = '100';
    }

    if (!checkValue) {
      this.weights.implement = '';
      this.weights.satisfaction = '';
    }
  };
}

export default DataViewModel;
