import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { ROUTE_PARAMS } from 'src/global/constants';
import CustomModalViewModel from 'src/components/CustomModal/viewModel';
import ProfitFormServices from 'src/services/ProfitFormServices';
import ErrorServices from 'src/services/ErrorServices';

class ApplyModalViewModel {
  @observable isAwait = false;

  projectId = null;
  eventId = null;
  form = null;
  router = null;
  onConfirmed = null;
  editMode = false;
  modalVM = new CustomModalViewModel();

  @computed
  get required() {
    return [
      {
        required: true,
        message: '請輸入'
      }
    ];
  }

  @computed
  get disabled() {
    return {
      confirm: this.isAwait
    };
  }

  constructor() {
    makeObservable(this);
  }

  init = (props) => {
    this.form = props.form;
    this.router = props.router;
    this.onConfirmed = props.onConfirmed;
    this.eventId = props.eventId;
    this.editMode = props.editMode ?? false;
    this.projectId = props.router.params[ROUTE_PARAMS.projectId];
  };

  open = (data) => {
    this.form.setFieldValue('reason', data?.reason || null);
    this.eventId = data?.eventId;
    this.modalVM.show();
  };

  onConfirm = () => {
    if (this.editMode) {
      this.postUpdateProfitFormEventContentContentAPI();
    } else {
      this.postUpdateApplyAPI();
    }
  };

  // 申請損益表修改
  @action
  postUpdateApplyAPI = async () => {
    this.isAwait = true;

    try {
      const data = await this.form.validateFields();
      const res = await ProfitFormServices.postCreateProfitFormEven({
        projectId: this.projectId,
        content: data.reason
      });

      this.modalVM.hide();

      this.onConfirmed();
    } catch (error) {
      const message = ErrorServices.postCreateProfitFormEven(error);

      console.log('ApplyModalViewModel', 'postCreateProfitFormEven', message);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };

  @action
  postUpdateProfitFormEventContentContentAPI = async () => {
    this.isAwait = true;
    try {
      const data = await this.form.validateFields();
      await ProfitFormServices.postUpdateProfitFormEventContent({
        eventId: this.eventId,
        content: data.reason
      });
      this.onConfirmed(this.eventId, data.reason);
      this.modalVM.hide();
    } catch (error) {
      const message = ErrorServices.postCreateProfitFormEven(error);

      console.log('ApplyModalViewModel', 'UpdateProfitFormEventContent', message);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default ApplyModalViewModel;
