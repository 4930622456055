import { makeObservable, observable, action, computed } from 'mobx';
import { v4 } from 'uuid';
import { RE_EMAIL } from 'src/global/constants';
import { message } from 'antd';

const errors = {
  email: {
    type: 'email',
    msg: 'Email 格式錯誤'
  }
};

class ContactViewModel {
  id = null;
  @observable type = null;
  @observable sector = null;
  @observable name = null;
  @observable title = null;
  @observable telephone = null;
  @observable phone = null;
  @observable email = null;

  @computed
  get label() {
    return {
      type: this.type || '-',
      sector: this.sector || '-',
      name: this.name || '-',
      title: this.title || '-',
      telephone: this.telephone || '-',
      phone: this.phone || '-',
      email: this.email || '-'
    };
  }

  @computed
  get check() {
    const checkType = this.type?.trim();
    const checkSector = this.sector?.trim();
    const checkName = this.name?.trim();
    const checkTitle = this.title?.trim();
    const checkTelephone = this.telephone?.trim();
    const checkPhone = this.phone?.trim();
    const checkEmail = this.email?.trim();

    return (
      checkType
      || checkSector
      || checkName
      || checkTitle
      || checkTelephone
      || checkPhone
      || checkEmail
    );
  }

  constructor(props) {
    this.id = props.id || v4();

    // 類別
    this.type = props?.type || null;

    // 部門單位
    this.sector = props?.sector || null;

    this.name = props?.name || null;

    // 職稱
    this.title = props?.title || null;

    this.telephone = props?.telephone || null;

    this.phone = props?.phone || null;

    this.email = props?.email || null;

    makeObservable(this);
  }

  @action getData = () => {
    if (!this.check) {
      return false;
    }

    const email = this.email?.trim();
    const checkEmailFormat = RE_EMAIL.test(email);

    if (!!email && !checkEmailFormat) {
      message.error('往來窗口email格式不符，請檢視', 5);

      throw new Error(errors.email.msg);
    }

    return {
      type: this.type?.trim(),
      sector: this.sector?.trim(),
      name: this.name?.trim(),
      title: this.title?.trim(),
      telephone: this.telephone?.trim(),
      phone: this.phone?.trim(),
      email: this.email?.trim()
    };
  };

  @action
  onChangeType = (event) => {
    this.type = event.target.value;
    this.showError = false;
  };

  @action
  onChangeSector = (event) => {
    this.sector = event.target.value;
    this.showError = false;
  };

  @action
  onChangeName = (event) => {
    this.name = event.target.value;
    this.showError = false;
  };

  @action
  onChangeTitle = (event) => {
    this.title = event.target.value;
    this.showError = false;
  };

  @action
  onChangeTelephone = (event) => {
    this.telephone = event.target.value;
    this.showError = false;
  };

  @action
  onChangePhone = (event) => {
    this.phone = event.target.value;
    this.showError = false;
  };

  @action
  onChangeEmail = (event) => {
    this.email = event.target.value;
    this.showError = false;
  };
}

export default ContactViewModel;
