import { makeObservable, observable, action } from 'mobx';

class CustomDrawerViewModel {
  @observable isShow = false;

  constructor() {
    makeObservable(this);
  }

  @action
  show = () => {
    this.isShow = true;
  }

  @action
  hide = () => {
    this.isShow = false;
  }

  onClose = (e) => {
    this.hide();
  }
}

export default CustomDrawerViewModel;
