class EmployeeDataModel {
  constructor(props) {
    this.isDisabled = props.isDisabled;

    this.accountId = String(props.accountId);

    this.type = props.type;

    this.name = props.name;

    this.idNumber = props.idNumber;

    this.birthday = props.birthday;

    // 就業日
    this.employmentDate = props.employmentDate;

    // 離職日
    this.resignationDate = props.resignationDate;

    // 保險日
    this.insuranceDate = props.insuranceDate;

    // 退保日
    this.disInsuranceDate = props.disInsuranceDate;

    this.phone = props.phone;

    this.address = props.address;

    // 聯絡人
    this.contactName = props.contactName;
    this.contactPhone = props.contactPhone;
    this.contactRelationship = props.contactRelationship;

    this.education = props.education;

    this.license = props.license;

    this.comment = props.comment;

    this.createdAt = props.createdAt;

    this.updatedAt = props.updatedAt;
  }
}

export default EmployeeDataModel;
