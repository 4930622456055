import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction
} from 'mobx';
import { diff } from 'deep-diff';
import { USER_TYPES } from 'src/global/constants';
import { PRACTICE_CITIES } from 'src/global/visitor';
import ErrorServices from 'src/services/ErrorServices';
import VisitorServices from 'src/services/VisitorServices';
import CreateModalViewModel from './components/CreateModal/viewModel';
import ImportModalViewModel from './components/ImportModal/viewModel';
import InterviewerViewModel from './viewModels/InterviewerViewModel';

class InterviewerPageViewModel {
  @observable profile = null;
  @observable search = '';
  @observable workingCounty = [];
  @observable temporaryWorkingCounty = [];
  @observable data = [];
  @observable anchor = undefined;
  @observable isInit = true;
  @observable isAwait = false;

  selectOpen = false;

  createModalVM = new CreateModalViewModel();
  importModalVM = new ImportModalViewModel();

  router = null;

  // 可管理訪員的角色：
  // edit: 營管部、PM, 新增, 操作, detail 詳情編輯
  // view：老闆、業務, 與上相反
  @computed
  get hasEdit() {
    switch (this.profile.type) {
      case USER_TYPES.boss.value:
      case USER_TYPES.operationsManagement.value:
      case USER_TYPES.projectManager.value:
        return true;

      default:
        return false;
    }
  }

  @computed
  get options() {
    return {
      cities: Object.values(PRACTICE_CITIES)
    };
  }

  constructor(props) {
    this.profile = props.profile;
    this.router = props.router;

    makeObservable(this);
  }

  didMount = (props) => {
    if (!this.isAwait) {
      this.postVisitorListAPI();
    }
  };

  @action
  onSearchChange = (event) => {
    const value = event.target.value;

    this.search = value;
  };

  @action
  onSearch = () => {
    this.reset();
  };

  @action
  onTemporaryWorkingCountyChange = (val) => {
    this.temporaryWorkingCounty = val;
  };

  @action
  onSelectBlur = () => {
    const diffWorkingCounty = diff(
      this.workingCounty,
      this.temporaryWorkingCounty
    );

    if (diffWorkingCounty) {
      this.workingCounty = [...this.temporaryWorkingCounty];

      this.reset();

      console.log('onSelectBlur', 'diffWorkingCounty', diffWorkingCounty);
    }
  };

  @action
  onSelectDelete = () => {
    const diffWorkingCounty = diff(
      this.workingCounty,
      this.temporaryWorkingCounty
    );

    if (diffWorkingCounty && !this.selectOpen) {
      this.workingCounty = [...this.temporaryWorkingCounty];

      this.reset();

      console.log('onSelectBlur', 'diffWorkingCounty', diffWorkingCounty);
    }
  };

  onSelectOpenChange = (open) => {
    this.selectOpen = open;
  };

  onScrollEnd = () => {
    if (!this.isAwait && this.anchor !== null) {
      this.postVisitorListAPI();
    }
  };

  @action
  onCreated = () => {
    this.reset();
  };

  @action
  reset = () => {
    this.data = [];
    this.anchor = undefined;

    this.postVisitorListAPI();
  };

  @action
  postVisitorListAPI = async () => {
    this.isAwait = true;

    try {
      const search = this.search.trim() || undefined;
      const workingCounty = this.workingCounty.length
        ? this.workingCounty
        : undefined;
      const res = await VisitorServices.postVisitorList({
        anchor: this.anchor,
        keyword: search,
        filter: {
          workingCounty
        }
      });

      runInAction(() => {
        const newData = res.data.map((item) => new InterviewerViewModel(item));

        this.data = [...this.data, ...newData];
        this.anchor = res.anchor;
      });
    } catch (error) {
      const message = ErrorServices.postVisitorList(error);

      console.log('InterviewerPageViewModel', 'postVisitorList', message);
    } finally {
      runInAction(() => {
        this.isInit = false;
        this.isAwait = false;
      });
    }
  };
}

export default InterviewerPageViewModel;
