import { request, getHost } from './utils';

// 訪員
class VisitorAPIs {
  // 讀取訪員列表
  static postVisitorList({ token, limit, anchor, keyword, filter }) {
    const options = {
      method: 'post',

      url: `${getHost()}/v1/visitor/list`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      params: {
        limit,
        anchor
      },

      data: { keyword, ...filter }
    };

    return request(options);
  }

  // 新增訪員
  static postVisitorCreateBatch({ token, info }) {
    const options = {
      method: 'post',

      url: `${getHost()}/v1/visitor/create/batch`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      data: {
        info
      }
    };

    return request(options);
  }

  // 更新訪員
  static putVisitorUpdate({ token, id, data }) {
    const options = {
      method: 'put',

      url: `${getHost()}/v1/visitor/update/${id}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      data
    };

    return request(options);
  }

  // 讀取訪員任務
  static getVisitorCase({ token, visitorId, limit, anchor }) {
    const options = {
      method: 'get',

      url: `${getHost()}/v1/visitor/case/${visitorId}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      params: {
        limit, anchor
      }
    };

    return request(options);
  }
}

export default VisitorAPIs;
