import React from 'react';
import PropTypes from 'prop-types';
import { Form, Select } from 'antd';
import styles from './styles.module.scss';

// 生日賀卡
function FormGreetingRecord(props) {
  const label = props.label
    ? props.label.map((item) => item.year).join(', ')
    : null;

  return props.isEdit ? (
    <Form.Item
      className={styles.formItem}
      name={props.name}
      rules={props.required}
    >
      <Select size="small" mode="multiple" options={props.options} />
    </Form.Item>
  ) : (
    <div className={styles.label}>{label}</div>
  );
}

FormGreetingRecord.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.array,
  required: PropTypes.array
};

FormGreetingRecord.defaultProps = {
  label: null,
  required: []
};

export default FormGreetingRecord;
