import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button, Tabs } from 'antd';
import clsx from 'clsx';
import ICONS from 'src/global/icons';
import withRouter from 'src/components/withRouter';
import ExportCheckForm from './components/ExportCheckForm';
import InterviewerDrawer from './components/InterviewerDrawer';
import CaseDrawer from './components/CaseDrawer';
import ImportModal from './components/ImportModal';
import CaseTableViewModel from './viewModel';
import styles from './styles.module.scss';
import './reset.scss';

// 派案作業
@observer
class CaseTable extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new CaseTableViewModel({
      project: props.project,
      checkParams: props.checkParams,
      router: props.router
    });
  }

  componentDidMount() {
    this.vm.didMount();
  }

  componentDidUpdate(preProps) {
    this.vm.didUpdate(this.props, preProps);
  }

  render() {
    return this.vm.isShow ? (
      <div id="caseTable" className={styles.container}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>派案作業</div>

          <Button
            type="link"
            className={styles.button}
            onClick={this.vm.onToggleShow}
          >
            {this.vm.toggleShow ? (
              <ICONS.Show className={styles.icon} />
            ) : (
              <ICONS.Hide className={styles.icon} />
            )}
          </Button>

          <div className={styles.exportButtonContainer}>
            <ExportCheckForm project={this.props.project} />
          </div>
        </div>

        {this.vm.isReady && (
          <div
            className={clsx([
              styles.tableContainer,
              this.vm.toggleShow && styles.show
            ])}
          >
            <Tabs items={this.vm.pages} />
          </div>
        )}

        <InterviewerDrawer vm={this.vm.interviewerDrawerVM} />

        <CaseDrawer
          vm={this.vm.caseDrawerVM}
          project={this.props.project}
          onCreated={this.vm.onCreated}
        />

        <ImportModal
          vm={this.vm.importModalVM}
          project={this.props.project}
          onCreated={this.vm.onCreated}
        />
      </div>
    ) : null;
  }
}

CaseTable.propTypes = {
  project: PropTypes.object.isRequired,
  checkParams: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired
};

export default withRouter(CaseTable);
