import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from './styles.module.scss';

function Fraction(props) {
  const isMiddle = props.size === 'middle';
  const isSmall = props.size === 'small';

  return (
    <div
      className={clsx([
        isMiddle && styles.middle,
        isSmall && styles.small
      ])}
    >
      <div className={styles.label}>{props.label}</div>

      <div className={styles.content}>
        <span className={styles.fraction}>{props.fraction}</span>
        <span className={styles.unit}>分</span>
      </div>
    </div>
  );
}

Fraction.propTypes = {
  label: PropTypes.string,
  fraction: PropTypes.string,
  size: PropTypes.string
};

Fraction.defaultProps = {
  label: '平均總分',
  fraction: '90.00',
  size: 'middle'
};

export default Fraction;
