import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Input } from 'antd';
import SelfRow from 'src/components/CustomForm/components/SelfRow';
import FormatCell from 'src/components/CustomForm/components/FormatCell';
import SelfFormItem from 'src/components/CustomForm/components/SelfFormItem';
import BorderRightBottom from 'src/components/CustomForm/components/BorderRightBottom';

function CaseAmount(props) {
  return (
    <BorderRightBottom>
      <SelfRow>
        <FormatCell label="現場">
          <SelfFormItem name={[props.nameKey, 'onSite']}>
            <Input size="small" maxLength={50} />
          </SelfFormItem>
        </FormatCell>

        <FormatCell label="非現場">
          <SelfFormItem name={[props.nameKey, 'offSite']}>
            <Input size="small" maxLength={50} />
          </SelfFormItem>
        </FormatCell>

        <FormatCell label="其他">
          <SelfFormItem name={[props.nameKey, 'other']}>
            <Input size="small" maxLength={50} />
          </SelfFormItem>
        </FormatCell>
      </SelfRow>
    </BorderRightBottom>
  );
}

CaseAmount.propTypes = {
  nameKey: PropTypes.string.isRequired
};

CaseAmount.defaultProps = {};

export default CaseAmount;
