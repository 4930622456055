import React from 'react';
import PropTypes from 'prop-types';
import { observer, Observer } from 'mobx-react';
import { Button, Checkbox, Table, Switch } from 'antd';
import { Waypoint } from 'react-waypoint';
import clsx from 'clsx';
import CheckFormModal from 'src/components/CheckFormModal';
import FixedTable from 'src/components/FixedTable';
import CheckFormsViewModel from './viewModel';
import styles from './styles.module.scss';
import './reset.scss';

@observer
class CheckForms extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new CheckFormsViewModel({
      profile: props.profile,
      project: props.project
    });
  }

  componentDidMount() {
    this.vm.didMount();
  }

  componentDidUpdate(preProps) {
    this.vm.didUpdate(this.props, preProps);
  }

  render() {
    return (
      <>
        <div className={clsx(['checkForms', styles.container])}>
          <div className={styles.operate}>
            <Button
              type="primary"
              onClick={this.vm.onExport}
              disabled={this.vm.disabled.export}
            >
              合併匯出
            </Button>

            {this.vm.check.edit && (
              <Button
                type="primary"
                onClick={this.vm.onDisabled}
                disabled={this.vm.disabledViewable}
              >
                允許查看
              </Button>
            )}
          </div>

          <div className={styles.table}>
            <FixedTable
              dataSource={this.vm.data}
              scrollY
              labelHeight={55}
              flex
              // hidden
              // padding
            >
              <Table.Column
                title={(
                  <Observer>
                    {() => (
                      <Checkbox
                        checked={this.vm.toggleAll}
                        onChange={this.vm.onToggleAllChange}
                      />
                    )}
                  </Observer>
                )}
                width={40}
                render={(vm) => (
                  <Observer>
                    {() => (
                      <Checkbox
                        checked={vm.isActive}
                        onChange={vm.onActiveChange}
                        disabled={vm.disabled.active}
                      />
                    )}
                  </Observer>
                )}
              />

              <Table.Column title="門市/案件名稱" dataIndex="name" />

              {this.vm.check.edit && (
                <Table.Column
                  title="是否允許查看此張查檢表"
                  width={190}
                  render={(vm) => (
                    <Observer>
                      {() => (
                        <Switch
                          checkedChildren="允許"
                          unCheckedChildren="不允許"
                          checked={vm.isCustomerViewable}
                          onChange={vm.onCustomerViewableChange}
                          disabled={vm.disabled.customerViewable}
                        />
                      )}
                    </Observer>
                  )}
                />
              )}

              <Table.Column
                title="操作"
                width={140}
                render={(vm, _, index) => (
                  <Observer>
                    {() => (
                      <div className={styles.operate}>
                        <Button
                          type="link"
                          className={styles.button}
                          onClick={() => this.vm.onCheckFormModalOpen(vm)}
                          disabled={vm.disabled.checkForm}
                        >
                          查看查檢表
                        </Button>

                        <Button
                          type="link"
                          className={styles.button}
                          onClick={vm.onCheckFormExport}
                          disabled={vm.disabled.export}
                        >
                          匯出
                        </Button>
                      </div>
                    )}
                  </Observer>
                )}
              />
            </FixedTable>
          </div>
        </div>

        <CheckFormModal vm={this.vm.checkFormModalVM} />
      </>
    );
  }
}

CheckForms.propTypes = {
  project: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired
};

CheckForms.defaultProps = {};

export default CheckForms;
