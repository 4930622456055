import React from 'react';
import PropTypes from 'prop-types';
import { Button, Upload } from 'antd';
import { observer } from 'mobx-react';
import { InboxOutlined } from '@ant-design/icons';
import styles from './styles.module.scss';
import './reset.scss';

function CustomUpload(props) {
  const { onDownloadExample, ...ps } = props;

  return (
    <div id="customUpload" className={styles.container}>
      <div className={styles.example}>
        <Button onClick={onDownloadExample}>下載範本</Button>
      </div>

      <Upload.Dragger
        accept="text/csv"
        multiple
        {...ps}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>

        <div className={styles.title}>
          點擊此處選擇檔案或直接將檔案拖曳於此
        </div>

        <div className={styles.subtitle}>
          Click or drag file to this area to upload
        </div>
      </Upload.Dragger>
    </div>
  );
}

CustomUpload.propTypes = {
  onDownloadExample: PropTypes.func
};

CustomUpload.defaultProps = {
  onDownloadExample: null
};

export default observer(CustomUpload);
