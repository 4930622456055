import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { v4 } from 'uuid';
import { RE_NUMBER } from 'src/global/constants';

class AllowanceViewModel {
  @observable id = null;
  @observable label = null;
  @observable value = null;
  @observable isRequired = null;
  @observable placeholder = null;

  // 損益表修改過
  setIsEdit = null;

  @computed
  get res() {
    return {
      id: this.id,
      label: this.label,
      value: this.value,
      placeholder: this.placeholder
    };
  }

  constructor(props) {
    this.id = props?.data?.id || v4();
    this.label = props?.data?.label;
    this.value = props?.data?.value || null;
    this.placeholder = props?.data?.placeholder;

    // 損益表修改過
    this.setIsEdit = props.setIsEdit;

    makeObservable(this);
  }

  @action
  onChangeLabel = (event) => {
    this.label = event.target.value;

    // 損益表修改過
    this.setIsEdit(true);
  };

  @action
  onChangeValue = (event) => {
    const value = event.target.value;
    const isNumber = RE_NUMBER.test(value);
    const check = !value || isNumber;

    if (check) {
      this.value = event.target.value;
    }

    // 損益表修改過
    this.setIsEdit(true);
  };
}

export default AllowanceViewModel;
