import React from 'react';
import PropTypes from 'prop-types';
import { AppStateContext } from 'src/stores';
import { ROUTES } from 'src/global/constants';
import { getRoutes } from 'src/global/methods';
import UserServices from 'src/services/UserServices';
import CookieServices from 'src/services/CookieServices';
import withRouter from 'src/components/withRouter';
import ErrorServices from 'src/services/ErrorServices';

const withProfile = (WrappedComponent, isRequired = false) => {
  class WithProfileHOC extends React.Component {
    static contextType = AppStateContext;
    static propTypes = {
      router: PropTypes.object.isRequired,
      route: PropTypes.string.isRequired
    };

    constructor(props) {
      super(props);
      this.state = { isAwait: true };
    }

    componentDidMount() {
      this.getProfileAPI();
    }

    setAwait = (value) => {
      this.setState(() => {
        return { isAwait: value };
      });
    };

    checkRoute = (profile) => {
      const routes = getRoutes(profile.type);
      const find = routes.find((item) => item.value === this.props.route);

      if (find) {
        this.setAwait(false);
      } else {
        this.toLogin();
      }
    };

    getProfileAPI = async () => {
      const profile = this.context.state.profile;

      // 已經從 login 登入了
      if (profile) {
        this.checkRoute(profile);
      } else {
        try {
          const res = await UserServices.getProfile();

          this.updateProfile(res.data);
          this.checkRoute(res.data);
        } catch (error) {
          const message = ErrorServices.getProfile(error);

          // 刪除 token
          await UserServices.postUserLogout();

          this.updateProfile(null);
          this.toLogin();
        }
      }
    };

    toLogin = () => {
      requestAnimationFrame(() => {
        const route = `/${ROUTES.login.value}`;
        this.props.router.navigate(route, { replace: true });
      });
    };

    updateProfile = (profile) => {
      requestAnimationFrame(() => {
        this.context.actions.updateProfile(profile);
      });
    };

    render() {
      const { router, ...ps } = this.props;
      const profile = this.context.state.profile;
      const isAwait = this.state.isAwait;

      if (isAwait) {
        return null;
      }

      if (isRequired && !profile) {
        return null;
      }

      return <WrappedComponent {...ps} profile={profile} />;
    }
  }

  return withRouter(WithProfileHOC);
};

export default withProfile;
