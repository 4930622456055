import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Form, Button } from 'antd';
import BigNumber from 'bignumber.js';
import WaveTime from './components/WaveTime';
import styles from './styles.module.scss';

function WaveTimes(props) {
  return (
    <div className={styles.container}>
      <div className={styles.tableRow}>
        <div className={styles.tableTitle}>波數</div>
        <div className={styles.tableTitle}>開始時間</div>
        <div className={styles.tableTitle}>結束時間</div>
      </div>

      <Form.List name="waveTimes">
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map((item, index) => {
                const i = new BigNumber(index).plus(1).toString();
                const label = `第${i}波`;

                return (
                  <WaveTime
                    key={item.key}
                    item={item}
                    label={label}
                    onRemove={remove}
                    disabled={props.disabled}
                  />
                );
              })}

              <div className={styles.addButtonContainer}>
                <Button
                  onClick={() => add()}
                  disabled={props.disabled}
                >
                  新增波數
                </Button>
              </div>
            </>
          );
        }}
      </Form.List>
    </div>
  );
}

WaveTimes.propTypes = {
  disabled: PropTypes.bool.isRequired
};

WaveTimes.defaultProps = {
};

export default observer(WaveTimes);
