import { makeObservable, observable } from 'mobx';

class VisitorViewModel {
  @observable id = '';
  @observable name = '';
  @observable nickName = '';

  constructor(props) {
    this.id = props.accountId;
    this.name = props.name;
    this.nickName = props.nickName;

    makeObservable(this);
  }
}

export default VisitorViewModel;
