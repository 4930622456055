import { makeObservable, observable, computed, action } from 'mobx';
import { RE_NUMBER } from 'src/global/constants';

class BarChartsViewModel {
  @observable basicFraction = '50';
  @observable maxBasicFraction = 0;

  constructor(props) {
    this.maxBasicFraction = props.fullMarks;
    makeObservable(this);
  }

  @action
  onChangeBasicFraction = (event) => {
    const val = event.target.value;
    const isNumber = RE_NUMBER.test(val);

    if (isNumber) {
      const numberValue = Number(val);

      this.basicFraction
        = numberValue > this.maxBasicFraction
          ? String(this.maxBasicFraction)
          : val;
    }
  };
}

export default BarChartsViewModel;
