import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import clsx from 'clsx';
import withRouter from 'src/components/withRouter';
import ICONS from 'src/global/icons';
import CaseHistoryViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class CaseHistory extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new CaseHistoryViewModel({
      case: props.case,
      checkParams: props.checkParams,
      router: props.router,
      onChanged: props.onChanged
    });
  }

  componentDidUpdate(preProps) {
    this.vm.didUpdate(this.props, preProps);
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>派案歷程</div>

          {this.vm.check.remove && (
            <Button
              type="primary"
              className={styles.rejectButton}
              onClick={this.vm.onRemove}
              disabled={this.vm.isAwait}
            >
              <ICONS.Reduce className={styles.icon} />
              撤案
            </Button>
          )}
        </div>

        {this.vm.check.hasLog && (
          <div className={styles.logs}>
            <div className={styles.paddingLeftRight}>
              {this.vm.logs.map((item) => {
                return (
                  <div className={styles.log} key={item.id}>
                    <div className={styles.media}>
                      <div
                        className={clsx([
                          styles.circle,
                          item.isLargeCircle && styles.large,
                          item.isMiddleCircle && styles.middle,
                          item.isBlack && styles.black
                        ])}
                      />
                      {item.hasLine && <div className={styles.line} />}
                    </div>

                    <div className={styles.label}>{item.text}</div>

                    <div className={styles.label}>{item.visitorName}</div>

                    <div className={styles.date}>{item.date}</div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
}

CaseHistory.propTypes = {
  case: PropTypes.object.isRequired,
  checkParams: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  onChanged: PropTypes.func.isRequired
};

CaseHistory.defaultProps = {};

export default withRouter(CaseHistory);
