import { makeObservable, observable, action, computed } from 'mobx';
import { ROUTES, PROJECT_STATUS, USER_TYPES } from 'src/global/constants';

class RouteViewModel {
  @observable route = null;

  @computed
  get title() {
    return this.route ? this.route.label : '';
  }

  // 是立案申請管理, 可以新增
  @computed
  get isProposal() {
    return this.route?.value === ROUTES.proposal.value;
  }

  // 是專案請款與預算管理, 表格操作跳轉至預算頁
  @computed
  get isBudget() {
    return this.route?.value === ROUTES.budget.value;
  }

  @computed
  get statuses() {
    if (this.route) {
      switch (this.route.value) {
        // 立案申請與管理
        case ROUTES.proposal.value: {
          const defaultStatus = {
            value: `${PROJECT_STATUS.S1.value},${PROJECT_STATUS.S2.value},${PROJECT_STATUS.S3.value}`,
            label: '狀態選擇'
          };

          return [
            defaultStatus,
            PROJECT_STATUS.S1,
            PROJECT_STATUS.S2,
            PROJECT_STATUS.S3
          ];
        }

        // 派案與執行管理
        case ROUTES.execute.value: {
          const defaultStatus = {
            value: `${PROJECT_STATUS.S4.value},${PROJECT_STATUS.S5.value}`,
            label: '狀態選擇'
          };

          return [
            defaultStatus,
            PROJECT_STATUS.S4,
            PROJECT_STATUS.S5
          ];
        }

        // 結案管理
        case ROUTES.close.value: {
          const defaultStatus = {
            value: `${PROJECT_STATUS.S6.value},${PROJECT_STATUS.S7.value},${PROJECT_STATUS.S8.value}`,
            label: '狀態選擇'
          };

          return [
            defaultStatus,
            PROJECT_STATUS.S6,
            PROJECT_STATUS.S7,
            PROJECT_STATUS.S8
          ];
        }

        // 專案請款與預算管理
        case ROUTES.budget.value: {
          const filterStatus = Object.values(PROJECT_STATUS).filter((item) => {
            return item.value !== PROJECT_STATUS.S1.value;
          });
          const joinStatus = filterStatus.map((item) => item.value).join(',');
          const defaultStatus = {
            value: joinStatus,
            label: '狀態選擇'
          };

          return [defaultStatus, ...filterStatus];
        }

        // 專案查詢
        case ROUTES.projectSearch.value: {
          const status = Object.values(PROJECT_STATUS);
          const joinStatus = status.map((item) => item.value).join(',');
          const defaultStatus = {
            value: joinStatus,
            label: '狀態選擇'
          };

          return [defaultStatus, ...status];
        }

        default:
          return [];
      }
    }

    return [];
  }

  @computed
  get owners() {
    if (this.route) {
      switch (this.route.value) {
        // 立案申請與管理
        case ROUTES.proposal.value: {
          const defaultType = {
            value: 'all',
            label: '角色選擇'
          };

          return [
            defaultType,
            USER_TYPES.salesperson,
            USER_TYPES.operationsManagement,
            USER_TYPES.boss
          ];
        }

        // 派案與執行管理
        case ROUTES.execute.value: {
          const defaultType = {
            value: 'all',
            label: '角色選擇'
          };

          return [
            defaultType,
            USER_TYPES.projectManager,
            USER_TYPES.operationsManagement
          ];
        }

        // 結案管理
        case ROUTES.close.value: {
          const defaultType = {
            value: 'all',
            label: '角色選擇'
          };

          return [
            defaultType,
            USER_TYPES.projectManager,
            USER_TYPES.operationsManagement,
            USER_TYPES.boss
          ];
        }

        // 專案請款與預算管理
        case ROUTES.budget.value: {
          const defaultType = {
            value: 'all',
            label: '角色選擇'
          };

          return [
            defaultType,
            USER_TYPES.salesperson,
            USER_TYPES.projectManager,
            USER_TYPES.operationsManagement,
            USER_TYPES.boss
          ];
        }

        case ROUTES.projectSearch.value: {
          const defaultType = {
            value: 'all',
            label: '角色選擇'
          };

          return [
            defaultType,
            USER_TYPES.salesperson,
            USER_TYPES.projectManager,
            USER_TYPES.operationsManagement,
            USER_TYPES.boss
          ];
        }

        default:
          return [];
      }
    }

    return [];
  }

  constructor() {
    makeObservable(this);
  }

  @action
  init = (router) => {
    const pathName = router.location.pathname;
    const routes = Object.values(ROUTES).filter((item) => item.value);
    const route = routes.find((item) => {
      const index = pathName.indexOf(item.value);

      return index !== -1;
    });

    this.route = route;
  };
}

export default RouteViewModel;
