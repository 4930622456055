import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import CustomModalViewModel from 'src/components/CustomModal/viewModel';
import CheckFormServices from 'src/services/CheckFormServices';
import ErrorServices from 'src/services/ErrorServices';

class ShortAnswerViewModel {
  data = null;

  // id + 資料
  id = null;
  projectId = null;
  site = null;
  facet = null;
  inferiorFacet = null;
  type = null;

  // 問題與內容
  @observable topic = null;
  @observable content = null;

  deleteModalVM = new CustomModalViewModel();

  onDeleted = null;

  // 這個題目是不是無效
  @computed
  get invalid() {
    if (this.topic?.trim()) {
      return false;
    }

    return true;
  }

  constructor(props) {
    const data = props.data;
    const onDeleted = props.onDeleted;

    this.data = data;

    this.id = data.id;
    this.projectId = data.projectId;
    this.site = data.site;
    this.facet = data.facet;
    this.inferiorFacet = data.inferiorFacet;
    this.type = data.type;

    this.topic = data.topic || null;
    this.content = data.content || null;

    this.onDeleted = onDeleted;

    makeObservable(this);
  }

  @action
  onChangeTopic = (event) => {
    this.topic = event.target.value;
  };

  @action
  onChangeContent = (event) => {
    this.content = event.target.value;
  };

  onBlur = () => {
    this.postUpdateDataAPI();
  };

  onDeleteConfirm = () => {
    this.deleteCheckFormItemAPI();
  };

  // 更新題目
  postUpdateDataAPI = async () => {
    try {
      const res = await CheckFormServices.putCheckFormItem({
        id: this.id,
        topic: this.topic,
        content: this.content
      });

      console.log(res);
    } catch (error) {
      const message = ErrorServices.putCheckFormItem(error);

      console.log('FractionAnswerViewModel', 'putCheckFormItem', message);
    }
  };

  // 刪除題目
  @action
  deleteCheckFormItemAPI = async () => {
    this.isAwait = true;

    try {
      const res = await CheckFormServices.deleteCheckFormItem({ id: this.id });

      this.deleteModalVM.hide();
      this.onDeleted(this.id);
    } catch (error) {
      const message = ErrorServices.deleteCheckFormItem(error);

      console.log('FractionAnswerViewModel', 'deleteCheckFormItem', message);
    }
  };
}

export default ShortAnswerViewModel;
