import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { ROUTE_PARAMS } from 'src/global/constants';
import CompanyServices from 'src/services/CompanyServices';
import ErrorServices from 'src/services/ErrorServices';
import Detail from './components/Detail';
import CheckForms from './components/CheckForms';

class ClientDetailPageViewModel {
  @observable profile = null;
  // 客戶資料
  @observable client = null;

  // 選擇的專案
  @observable projectId = null;

  @observable isAwait = false;

  // 初始化
  @observable isInit = true;

  clientId = null;

  @computed
  get check() {
    const checkProject = !!this.client?.project.length;

    return {
      hasProject: checkProject
    };
  }

  @computed
  get mapProjects() {
    return this.client?.project.map((item) => {
      return { value: item.id, label: item.name };
    }) || [];
  }

  @computed
  get project() {
    return this.client?.project.find((item) => item.id === this.projectId);
  }

  @computed
  get pages() {
    return [
      {
        key: 'detail',
        label: '數據統計',
        children: <Detail profile={this.profile} projectId={this.projectId} />
      },
      {
        key: 'checkForms',
        label: '查檢表詳情',
        children: (
          <CheckForms profile={this.profile} project={this.project} />
        )
      }
    ];
  }

  constructor(props) {
    this.profile = props.profile;
    this.readRoute(props);
    makeObservable(this);
  }

  @action
  readRoute = (props) => {
    const clientId = props.router.params[ROUTE_PARAMS.clientId];
    const projectId = props.router.location.state?.[ROUTE_PARAMS.projectId];

    if (clientId === 'admin') {
      this.clientId = String(this.profile.id);
    } else {
      this.clientId = clientId;
    }

    if (projectId) {
      this.projectId = projectId;
    }
  };

  @action
  didMount = () => {
    if (!this.isAwait) {
      // 讀取企業資料
      this.getAccountProfileAPI();
    }
  };

  @action
  onChangeProjectId = (value) => {
    this.projectId = value;
  };

  // 讀取企業資料
  @action
  getAccountProfileAPI = async () => {
    this.isAwait = true;

    try {
      const res = await CompanyServices.getAccountProfile({
        id: this.clientId
      });

      runInAction(() => {
        const projectId = res.data.project[0]?.id || null;

        this.client = res.data;
        this.projectId = this.projectId ? this.projectId : projectId;
      });
    } catch (error) {
      const msg = ErrorServices.getAccountProfile(error);

      console.log('ClientDetailPageViewModel', 'getAccountProfile', msg);
    } finally {
      runInAction(() => {
        this.isInit = false;
        this.isAwait = false;
      });
    }
  };
}

export default ClientDetailPageViewModel;
