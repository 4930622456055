import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Form, DatePicker } from 'antd';
import dayjs from 'dayjs';
import styles from './styles.module.scss';

function FormDatePicker(props) {
  // label 來自 data
  const label = props.label ? dayjs(props.label).format('YYYY/MM/DD') : null;

  // form 的資料是 dayjs ins
  return props.isEdit ? (
    <Form.Item name={props.name} rules={props.required}>
      <DatePicker size="small" placeholder="請選擇日期" />
    </Form.Item>
  ) : (
    <div className={styles.label}>{label}</div>
  );
}

FormDatePicker.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.array
};

FormDatePicker.defaultProps = {
  label: null,
  required: []
};

export default observer(FormDatePicker);
