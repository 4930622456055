import Papa from 'papaparse';

const ENCODING = 'Big5';

export const papaConfig = {
  export: {
    quotes: false, // or array of booleans
    quoteChar: '"',
    escapeChar: '"',
    delimiter: ',',
    header: true,
    encoding: ENCODING,
    newline: '\r\n',
    skipEmptyLines: false, // other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
    columns: null // or array of strings
  },
  import: {
    delimiter: '', // auto-detect
    newline: '', // auto-detect
    quoteChar: '"',
    escapeChar: '"',
    header: true, // 很好用, array => json
    transformHeader: undefined,
    dynamicTyping: false,
    preview: 0,
    encoding: ENCODING,
    worker: false,
    comments: false,
    step: undefined,
    complete: undefined,
    error: undefined,
    download: false,
    downloadRequestHeaders: undefined,
    downloadRequestBody: undefined,
    skipEmptyLines: false,
    chunk: undefined,
    chunkSize: undefined,
    fastMode: undefined,
    beforeFirstChunk: undefined,
    withCredentials: undefined,
    transform: undefined,
    delimitersToGuess: [',', '\t', '|', ';', Papa.RECORD_SEP, Papa.UNIT_SEP]
  }
};
