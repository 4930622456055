import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { ROUTES, BEHAVIORS } from 'src/global/constants';
import MessageServices from 'src/services/MessageServices';
import ErrorServices from 'src/services/ErrorServices';
import CustomAnchorViewModel from 'src/components/CustomAnchor/viewModel';

class NotificationPageViewModel {
  @observable profile = null;
  @observable isAwait = false;

  anchorVM = null;
  router = null;

  constructor(props) {
    this.profile = props.profile;
    this.router = props.router;

    this.anchorVM = new CustomAnchorViewModel({ api: this.getMessageListAPI });

    makeObservable(this);
  }

  didMount = () => {
    if (!this.isAwait) {
      this.anchorVM.reset();
    }
  }

  onClickRow = (item, index) => {
    let route = null;

    switch (item.behavior) {
      // 專案詳情
      case BEHAVIORS.projectDetail:
        route = `/${ROUTES.projectSearch.value}/${item.projectId}`;
        break;

      // 預算表詳情
      case BEHAVIORS.profitDetail:
        route = `/${ROUTES.budget.value}/${item.projectId}`;
        break;

      default:
        break;
    }

    return (event) => {
      this.putMessageRead(item.id);
      this.router.navigate(route);
    };
  }

  // 讀取通知列表
  @action
  getMessageListAPI = async ({ anchor }) => {
    this.isAwait = true;

    const res = await MessageServices.getMessageList({ anchor });

    runInAction(() => {
      this.isAwait = false;
    });

    return res;
  }

  // 已讀通知
  @action
  putMessageRead = async (id) => {
    this.isAwait = true;

    try {
      const res = await MessageServices.putMessageRead({ messageId: id });

    } catch (error) {
      const msg = ErrorServices.putMessageRead(error);

      console.log('NotificationPageViewModel', 'putMessageRead', msg);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  }
}

export default NotificationPageViewModel;
