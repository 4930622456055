import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { message } from 'antd';
import { ROUTES, ROUTE_PARAMS } from 'src/global/constants';
import ProjectServices from 'src/services/ProjectServices';
import CheckFormServices from 'src/services/CheckFormServices';
import ErrorServices from 'src/services/ErrorServices';
import CustomModalViewModel from 'src/components/CustomModal/viewModel';
import CreateTopicDrawerViewModel from './components/CreateTopicDrawer/viewModel';
import BringInCheckFormDrawerViewModel from './components/BringInCheckFormDrawer/viewModel';
import DataViewModel from './viewModels/DataViewModel';

class CheckDetailPageViewModel {
  @observable profile = null;
  @observable project = null;

  // 雲端連結
  @observable onSiteAttached = null;
  @observable offSiteAttached = null;

  @observable isInit = false;
  @observable isAwait = false;

  // 查檢表完成
  @observable isCompleted = false;

  projectId = null;

  router = null;

  dataVM = null;
  createDrawerVM = null;
  bringInCheckFormDrawerVM = new BringInCheckFormDrawerViewModel();
  finishModalVM = new CustomModalViewModel();

  @computed
  get check() {
    return {
      isShow: !!this.project && this.isInit,
      disabled: this.isCompleted
    };
  }

  @computed
  get disabled() {
    return {
      edit: this.isCompleted
    };
  }

  @computed
  get backLink() {
    return `/${ROUTES.checklist.value}`;
  }

  @computed
  get routes() {
    return [
      {
        link: this.backLink,
        label: ROUTES.checklist.label
      },
      {
        label: this.project.projectName
      }
    ];
  }

  @computed
  get title() {
    return this.project.projectName;
  }

  @computed
  get invalids() {
    const onSite = !!this.dataVM.groupSiteTopics.onSite.find(
      (item) => item.invalid
    );
    const offSite = !!this.dataVM.groupSiteTopics.offSite.find(
      (item) => item.invalid
    );

    return {
      onSite,
      offSite
    };
  }

  constructor(props) {
    this.profile = props.profile;
    this.router = props.router;

    this.dataVM = new DataViewModel();

    this.createDrawerVM = new CreateTopicDrawerViewModel({
      onCreated: this.dataVM.onCreated
    });

    this.projectId = props.router.params[ROUTE_PARAMS.projectId];

    makeObservable(this);
  }

  didMount = async () => {
    if (!this.isAwait) {
      await this.getProjectDetailAPI();
      await this.getCheckFormItemAPI();

      runInAction(() => {
        this.isInit = true;
      });
    }
  };

  onChanged = () => {
    this.getCheckFormItemAPI();
  };

  onFinish = () => {
    const invalidTopics = this.dataVM.getInvalidTopics();
    const description = `尚有 ${invalidTopics.length} 題未設定完成，請重新檢查`;

    if (invalidTopics.length) {
      message.error(description, 5);
    } else {
      this.finishModalVM.show();
    }
  };

  onFinishModalConfirm = () => this.putCheckFormCompletedAPI();

  // 讀取專案資料
  @action
  getProjectDetailAPI = async () => {
    this.isAwait = true;

    try {
      const res = await ProjectServices.getProjectDetail({
        projectId: this.projectId
      });

      runInAction(() => {
        this.project = res.data;
        this.isCompleted = res.data.checkFormCompleted;
      });
    } catch (error) {
      const msg = ErrorServices.getProjectDetail(error);

      console.log('CheckDetailPageViewModel', 'getProjectDetail', msg);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };

  // 讀取查檢表所有題目
  @action
  getCheckFormItemAPI = async () => {
    this.isAwait = true;

    try {
      const res = await CheckFormServices.getCheckFormItem({
        projectId: this.projectId
      });

      runInAction(() => {
        this.dataVM.setTopics(res.data);

        this.onSiteAttached = res.onSiteAttached;
        this.offSiteAttached = res.offSiteAttached;
      });
    } catch (error) {
      const msg = ErrorServices.getCheckFormItem(error);

      console.log('CheckDetailPageViewModel', 'getCheckFormItem', msg);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };

  // 查檢表完成
  @action
  putCheckFormCompletedAPI = async () => {
    this.isAwait = false;

    try {
      const res = await CheckFormServices.putCheckFormCompleted({
        projectId: this.projectId
      });

      runInAction(() => {
        const route = `/${ROUTES.execute.value}/${this.projectId}`;

        this.isCompleted = true;

        this.router.navigate(route);
      });
    } catch (error) {
      const msg = ErrorServices.putCheckFormCompleted(error);

      // 加權分數沒有到 100
      if (error.response?.status === 400) {
        message.error(msg, 5);
      }

      console.log('CheckDetailPageViewModel', 'putCheckFormCompleted', message);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default CheckDetailPageViewModel;
