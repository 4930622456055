// 範例
export const example = [
  {
    姓名: '王小明',
    身分證字號: 'A123456789',
    生日: '2000-01-01',
    通訊電話: '0912345678',
    備註: '請輸入備註'
  }
];
