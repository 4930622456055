import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import BigNumber from 'bignumber.js';
import Facet from '../Facet';
import Appendix from '../Appendix';
import styles from './styles.module.scss';

@observer
class SiteTab extends React.Component {
  @computed
  get total() {
    const topics = this.props.data.topics;
    // 非加分題
    let implementWeighted = new BigNumber(0);
    let satisWeighted = new BigNumber(0);
    // 加分題
    let bonusImplementWeighted = new BigNumber(0);
    let bonusSatisWeighted = new BigNumber(0);

    for (let i = 0; i < topics.length; i += 1) {
      const fraction = topics[i].siteFraction;
      const isBonus = !!fraction?.isBonus;

      if (fraction && !isBonus) {
        implementWeighted = implementWeighted.plus(fraction.implementWeighted);
        satisWeighted = satisWeighted.plus(fraction.satisWeighted);
      }

      if (fraction && isBonus) {
        bonusImplementWeighted = bonusImplementWeighted.plus(
          fraction.implementWeighted
        );
        bonusSatisWeighted = bonusSatisWeighted.plus(fraction.satisWeighted);
      }
    }

    return {
      implementWeighted: implementWeighted.toString(),
      satisWeighted: satisWeighted.toString(),
      bonusImplementWeighted: bonusImplementWeighted.toString(),
      bonusSatisWeighted: bonusSatisWeighted.toString()
    };
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.minWidth}>
          <div className={styles.totals}>
            <div className={styles.item}>
              題目總數：
              {this.props.data.topics.length}
            </div>

            <div className={styles.item}>
              <div className={styles.row}>落實度</div>
              <div className={styles.row}>
                加權總分：
                {this.total.implementWeighted}
                {' / 100'}
              </div>
              <div className={styles.row}>
                加分題總分：
                {this.total.bonusImplementWeighted}
              </div>
            </div>

            <div className={styles.item}>
              <div className={styles.row}>滿意度</div>
              <div className={styles.row}>
                加權總分：
                {this.total.satisWeighted}
                {' / 100'}
              </div>
              <div className={styles.row}>
                加分題總分：
                {this.total.bonusSatisWeighted}
              </div>
            </div>
          </div>

          <div className={styles.mainContent}>
            {this.props.data.facets.map((item) => {
              return (
                <div className={styles.itemContainer} key={item.value}>
                  <Facet
                    data={item}
                    site={this.props.site}
                    facet={item.value}
                    createDrawerVM={this.props.createDrawerVM}
                    onCreated={this.props.onCreated}
                    disabled={this.props.disabled}
                  />
                </div>
              );
            })}
          </div>

          <Appendix
            site={this.props.site}
            attached={this.props.attached}
            disabled={this.props.disabled}
          />
        </div>
      </div>
    );
  }
}

SiteTab.propTypes = {
  data: PropTypes.object.isRequired,
  site: PropTypes.string.isRequired,
  createDrawerVM: PropTypes.object.isRequired,
  onCreated: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  attached: PropTypes.object
};

SiteTab.defaultProps = {
  attached: null
};

export default SiteTab;
