import { request, getHost } from './utils';

class CompanyAPIs {
  // 讀取企業(客戶)列表
  static postCompanyList({ token, limit, anchor, keyword }) {
    const options = {
      method: 'post',

      url: `${getHost()}/v1/company/list`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      params: {
        limit, anchor
      },

      data: {
        keyword
      }
    };

    return request(options);
  }

  // 讀取企業(客戶)列表, 精簡版(作為選項使用)
  static postCompanyListLite({ token, isDisabled, keyword }) {
    const options = {
      method: 'post',

      url: `${getHost()}/v1/company/list/lite`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      data: {
        isDisabled,
        keyword
      }
    };

    return request(options);
  }

  // 更新企業
  static putCompanyUpdate({ token, clientId, info }) {
    const options = {
      method: 'put',

      url: `${getHost()}/v1/company/update/${clientId}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      data: {
        isDisabled: info.isDisabled,
        // 企業編碼
        companyNumber: info.companyNumber,
        // 企業全名
        companyName: info.companyName,
        // 展業類別
        propertyType: info.propertyType,
        // 密碼
        password: info.password,
        // 登記地址
        address: info.address,
        // 代表電話
        phone: info.phone,
        // 網址
        path: info.path,
        // 負責人
        principalName: info.principalName,
        // 統一編號
        taxId: info.taxId,
        // 合作業務
        business: info.business,
        // 其他合作業務
        otherBusiness: info.otherBusiness,
        // 付款方式
        payment: info.payment,
        // 窗口
        companyContact: info.companyContact
      }
    };

    return request(options);
  }
}

export default CompanyAPIs;
