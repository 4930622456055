import {
  makeObservable,
  observable,
  action,
  runInAction,
  computed
} from 'mobx';
import dayjs from 'dayjs';
import { diff } from 'deep-diff';
import { CASE_STATUS } from 'src/global/case';
import CaseServices from 'src/services/CaseServices';
import ErrorServices from 'src/services/ErrorServices';

class CaseHistoryViewModel {
  @observable case = null;
  @observable checkParams = null;
  @observable isAwait = false;

  onChanged = null;

  @computed
  get check() {
    const isStep12 = this.case.step === CASE_STATUS.S12.value;
    const remove
      = this.checkParams.isHandler && !this.checkParams.isProjectEnd && !isStep12;

    return {
      remove,
      hasLog: !!this.case?.log?.length
    };
  }

  @computed
  get logs() {
    return this.case.log.map((item, i) => {
      const visitorName = `訪員：${item.visitorName}`;
      const date = dayjs(item.date).format('YYYY.MM.DD HH:mm:ss');
      // 失敗, 撤案是黑色
      const isBlack = this.getBlack(item);
      const hasLine = this.getHasLine(item, i);
      // 圈圈的大小
      const isLargeCircle = i === 0;

      return {
        id: String(i),
        text: item.text,
        visitorName,
        date,
        isLargeCircle,
        isMiddleCircle: !isLargeCircle,
        isBlack,
        hasLine
      };
    });
  }

  constructor(props) {
    this.onChanged = props.onChanged;

    this.init(props);

    makeObservable(this);
  }

  @action
  init = (props) => {
    this.case = props.case;
    this.checkParams = props.checkParams;
  };

  didUpdate = (props, preProps) => {
    const diffCase = diff(props.case, preProps.case);

    if (diffCase) {
      this.init(props);
    }
  };

  // 有沒有右邊的線
  getHasLine = (data, i) => {
    // 是最後一個
    const isLast = i === this.case.log.length - 1;

    switch (data.step) {
      case CASE_STATUS.S10.value:
      case CASE_STATUS.S11.value:
      case CASE_STATUS.S12.value:
        return !isLast;

      default:
        return true;
    }
  };

  // 圈圈是不是黑色的
  getBlack = (log) => {
    switch (log.step) {
      case CASE_STATUS.S1.value:
      case CASE_STATUS.S11.value:
      case CASE_STATUS.S12.value:
        return true;

      default:
        return false;
    }
  };

  // 撤案
  onRemove = () => {
    this.postCasePushAPI(CASE_STATUS.S12);
  };

  @action
  postCasePushAPI = async (step) => {
    this.isAwait = true;

    try {
      const res = await CaseServices.postCaseAction({
        caseId: this.case.caseId,
        nextStep: step.value
      });

      this.onChanged();
    } catch (error) {
      const msg = ErrorServices.postCaseAction(error);

      console.log('PushButtonViewModel', 'postCaseAction', msg);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default CaseHistoryViewModel;
