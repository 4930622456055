import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import ICONS from 'src/global/icons';
import CustomModal from '../CustomModal';
import styles from './styles.module.scss';

function ImportErrorModal(props) {
  return (
    <CustomModal vm={props.vm}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.iconContainer}>
            <ICONS.Warning className={styles.icon} />
          </div>

          <div className={styles.title}>
            {`OOPS! 您匯入的資料中有 ${props.data.length} 筆資料匯入失敗，請檢查下列資料是否有缺漏、重複或格式錯誤：`}
          </div>
        </div>

        <div className={styles.content}>
          {props.data.map((item) => {
            const index = Math.floor(item.index + 2);

            return (
              <div className={styles.item} key={index}>
                <div className={styles.icon} />
                <div className={styles.text}>{`第${index}列`}</div>
                <div className={styles.text}>{item.errorMessage}</div>
              </div>
            );
          })}
        </div>

        <div className={styles.buttonContainer}>
          <Button
            className={styles.confirmButton}
            type="primary"
            onClick={props.onConfirm}
          >
            我知道了
          </Button>
        </div>
      </div>
    </CustomModal>
  );
}

ImportErrorModal.propTypes = {
  vm: PropTypes.object.isRequired,
  data: PropTypes.array,
  onConfirm: PropTypes.func.isRequired
};

ImportErrorModal.defaultProps = {
  data: []
};

export default observer(ImportErrorModal);
