import { makeObservable, observable, action, computed } from 'mobx';
import dayjs from 'dayjs';
import { v4 } from 'uuid';

class DateViewModel {
  id = v4();
  @observable dates = null;

  @computed
  get res() {
    if (this.dates) {
      return {
        startTime: this.dates[0].toISOString(),
        stopTime: this.dates[1].toISOString()
      };
    }

    return null;
  }

  constructor(props) {
    if (props?.startTime && props?.stopTime) {
      this.dates = [dayjs(props.startTime), dayjs(props.stopTime)];
    }

    makeObservable(this);
  }

  @action
  onChangeDates = (value) => {
    this.dates = value;
  };
}

export default DateViewModel;
