import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import withRouter from 'src/components/withRouter';
import PushButtonViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class PushButton extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new PushButtonViewModel({
      case: props.case,
      checkParams: props.checkParams,
      onChanged: props.onChanged
    });
  }

  componentDidUpdate(preProps) {
    this.vm.didUpdate(this.props, preProps);
  }

  render() {
    return this.vm.isShow ? (
      <div className={styles.container}>
        <Button
          type="primary"
          size="large"
          className={styles.button}
          onClick={this.vm.onNextStep}
          disabled={this.vm.isAwait}
        >
          {this.vm.label.button}
        </Button>
      </div>
    ) : null;
  }
}

PushButton.propTypes = {
  case: PropTypes.object.isRequired,
  checkParams: PropTypes.object.isRequired,
  onChanged: PropTypes.func.isRequired
};

PushButton.defaultProps = {};

export default PushButton;
