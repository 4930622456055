import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { observer } from 'mobx-react';
import StyleItem from 'src/components/StyleItem';
import TextAreaModal from 'src/components/TextAreaModal';
import DescriptionItemViewModel from './viewModel';

// 專案簡述
@observer
class DescriptionItem extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new DescriptionItemViewModel({
      project: props.project,
      checkParams: props.checkParams,
      onChanged: props.onChanged
    });
  }

  componentDidUpdate(preProps) {
    this.vm.didUpdate(this.props, preProps);
  }

  render() {
    return this.vm.isShow ? (
      <>
        <StyleItem>
          <span>專案簡述</span>

          <Button type="primary" onClick={this.vm.modalVM.show}>
            {this.vm.label.button}
          </Button>
        </StyleItem>

        <TextAreaModal
          vm={this.vm.modalVM}
          label="專案簡述"
          value={this.vm.value}
          onChange={this.vm.onChangeValue}
          onConfirm={this.vm.onConfirm}
          disabled={this.vm.disabled.modal}
          isAwait={this.vm.isAwait}
        />
      </>
    ) : null;
  }
}

DescriptionItem.propTypes = {
  project: PropTypes.object.isRequired,
  checkParams: PropTypes.object.isRequired,
  onChanged: PropTypes.func
};

DescriptionItem.defaultProps = {
  onChanged: () => {}
};

export default DescriptionItem;
