import UserAPIs from 'src/apis/UserAPIs';
import LoginDataModel from 'src/models/response/LoginDataModel';
import ProfileDataModel from 'src/models/response/ProfileDataModel';
import CookieServices from './CookieServices';

class UserServices {
  // 登入
  static async postUserLogin({ account, password }) {
    const res = await UserAPIs.postUserLogin({ account, password });
    const data = new LoginDataModel(res.data);

    // 保存 token
    CookieServices.setToken(data.token);

    return {
      data: {
        id: data.id,
        name: data.name,
        type: data.type,
        account: data.account
      }
    };
  }

  // 登出
  static async postUserLogout() {
    // 刪除 token
    CookieServices.removeToken();

    return {
      token: CookieServices.getToken()
    };
  }

  // 讀取自己的資料
  static async getProfile() {
    const res = await UserAPIs.getProfile(CookieServices.getToken());

    return {
      data: new ProfileDataModel(res.data.data)
    };
  }

  // 停用和啟用帳號
  static async putAccountDisable({ id, disable }) {
    const res = await UserAPIs.putAccountDisable({
      token: CookieServices.getToken(),
      id,
      disable
    });

    return {
      data: res.data
    };
  }

  // 新增帳號
  static async postAccountCreate({ type, data }) {
    const res = await UserAPIs.postAccountCreate({
      token: CookieServices.getToken(),
      type,
      info: data
    });

    return {};
  }

  static async putUserCaseCustomerViewable({ projectId, caseId, viewable }) {
    const res = await UserAPIs.putUserCaseCustomerViewable({
      token: CookieServices.getToken(),
      projectId,
      caseId,
      viewable
    });

    return {
      data: res.data
    };
  }

  static async putUserCaseCustomerViewableByIds({
    projectId,
    viewable = true,
    ids
  }) {
    const res = await UserAPIs.putUserCaseCustomerViewableByIds({
      token: CookieServices.getToken(),
      projectId,
      viewable,
      ids
    });

    return {
      data: res.data
    };
  }
}

export default UserServices;
