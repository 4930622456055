import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button, Input } from 'antd';
import withRouter from 'src/components/withRouter';
import CustomModal from 'src/components/CustomModal';
import AppendixViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class Appendix extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new AppendixViewModel({
      router: props.router,
      disabled: props.disabled,
      site: props.site,
      attached: props.attached
    });
  }

  componentDidUpdate(preProps) {
    this.vm.didUpdate(this.props, preProps);
  }

  render() {
    return (
      <div className={styles.mainPadding}>
        <div className={styles.container}>
          <div className={styles.mainContent}>
            <div className={styles.label}>附件雲端連結</div>

            <Button type="primary" onClick={this.vm.modalVM.show}>
              {this.vm.buttonLabel}
            </Button>
          </div>

          <CustomModal vm={this.vm.modalVM} width={500}>
            <div className={styles.modalContent}>
              <div className={styles.item}>
                <div className={styles.label}>附件說明</div>

                <div className={styles.form}>
                  <Input.TextArea
                    rows={2}
                    value={this.vm.description}
                    onChange={this.vm.onChangeDescription}
                    disabled={this.vm.disabledEdit}
                    maxLength={500}
                    showCount
                  />
                </div>
              </div>

              <div className={styles.item}>
                <div className={styles.label}>雲端連結</div>

                <div className={styles.form}>
                  <Input
                    value={this.vm.link}
                    onChange={this.vm.onChangeLink}
                    disabled={this.vm.disabledEdit}
                    maxLength={200}
                  />
                </div>
              </div>

              <div className={styles.buttons}>
                <Button
                  size="small"
                  className={styles.cancelButton}
                  onClick={this.vm.modalVM.hide}
                >
                  取消
                </Button>

                <Button
                  type="primary"
                  size="small"
                  onClick={this.vm.onConfirm}
                  disabled={this.vm.disabledEdit}
                >
                  儲存
                </Button>
              </div>
            </div>
          </CustomModal>
        </div>
      </div>
    );
  }
}

Appendix.propTypes = {
  router: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  site: PropTypes.string.isRequired,
  attached: PropTypes.object
};

Appendix.defaultProps = {
  attached: null
};

export default withRouter(Appendix);
