import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { DatePicker, Button } from 'antd';
import ICONS from 'src/global/icons';
import SelfFormItem from 'src/components/CustomForm/components/SelfFormItem';
import WaveTimeViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class WaveTime extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new WaveTimeViewModel();
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.tableContent}>{this.props.label}</div>

        <div className={styles.tableContent}>
          <SelfFormItem name={[this.props.item.name, 'startAt']}>
            <DatePicker
              size="small"
              placeholder="選擇日期"
              onChange={this.vm.onStartAtChange}
              disabledDate={this.vm.onStartAtFilter}
              disabled={this.props.disabled}
            />
          </SelfFormItem>
        </div>

        <div className={styles.tableContent}>
          <SelfFormItem name={[this.props.item.name, 'endAt']}>
            <DatePicker
              size="small"
              placeholder="選擇日期"
              onChange={this.vm.onEndAtChange}
              disabledDate={this.vm.onEndAtFilter}
              disabled={this.props.disabled}
            />
          </SelfFormItem>

          <Button
            type="primary"
            ghost
            className={styles.deleteButton}
            onClick={() => {
              this.props.onRemove(this.props.item.name);
            }}
            disabled={this.props.disabled}
          >
            <ICONS.Delete />
          </Button>
        </div>
      </div>
    );
  }
}

WaveTime.propTypes = {
  item: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

WaveTime.defaultProps = {
  disabled: false
};

export default WaveTime;
