import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import CustomDrawerViewModel from 'src/components/CustomDrawer/viewModel';
import CustomModalViewModel from 'src/components/CustomModal/viewModel';
import CheckFormServices from 'src/services/CheckFormServices';
import ErrorServices from 'src/services/ErrorServices';

class BringInCheckFormDrawerViewModel {
  @observable project = null;
  @observable search = null;
  @observable selectId = null;
  @observable data = [];
  @observable isAwait = false;
  @observable anchor = undefined;

  drawerVM = new CustomDrawerViewModel();
  warningModalVM = new CustomModalViewModel();

  onChanged = null;

  @computed
  get label() {
    const total = `${this.data.length} 筆資料`;
    const findProject = this.data.find(
      (item) => item.projectId === this.selectId
    );
    const selected = findProject
      ? `選擇查檢表：${findProject.projectName}`
      : null;

    return {
      total,
      selected
    };
  }

  @computed
  get disabled() {
    return {
      search: this.isAwait,
      save: this.isAwait || !this.selectId
    };
  }

  constructor(props) {
    makeObservable(this);
  }

  @action
  init = ({ project, onChanged }) => {
    this.project = project;
    this.onChanged = onChanged;
  };

  open = () => {
    this.drawerVM.show();
  }

  @action
  onChangeSearch = (event) => {
    this.search = event.target.value;
  };

  @action
  onChangeSelectId = (event, item) => {
    const checked = event.target.checked;

    if (checked) {
      this.selectId = item.projectId;
    } else {
      this.selectId = null;
    }
  };

  onScrollEnd = () => {
    if (!this.isAwait && this.anchor !== null) {
      this.getCheckFormListAPI();
    }
  };

  @action
  onSearch = () => {
    this.selectId = null;
    this.data = [];
    this.anchor = undefined;

    this.getCheckFormListAPI();
  };

  onSave = () => {
    this.warningModalVM.show();
  };

  onConfirmWarningModal = () => {
    this.postCheckFormItemImport();
  }

  @action
  reset = () => {
    this.selectId = null;
    this.search = null;
    this.data = [];
    this.anchor = undefined;
  }

  @action
  getCheckFormListAPI = async () => {
    this.isAwait = true;

    try {
      const keyword = this.search?.trim() ? this.search : undefined;
      const res = await CheckFormServices.getCheckFormList({
        anchor: this.anchor,
        keyword,
        completed: true
      });

      runInAction(() => {
        this.data = [...this.data, ...res.data];
        this.anchor = null;
      });
    } catch (error) {
      const msg = ErrorServices.getCheckFormList(error);

      console.log('BringInCheckFormDrawerViewModel', 'getCheckFormList', msg);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };

  @action
  postCheckFormItemImport = async () => {
    this.isAwait = true;

    try {
      const projectId = this.project.projectId;
      const res = await CheckFormServices.postCheckFormItemImport({
        projectId,
        sourceProjectId: Number(this.selectId)
      });

      this.onChanged();

      this.warningModalVM.hide();
      this.drawerVM.hide();
    } catch (error) {
      const msg = ErrorServices.postCheckFormItemImport(error);

      console.log(
        'BringInCheckFormDrawerViewModel',
        'postCheckFormItemImport',
        msg
      );
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default BringInCheckFormDrawerViewModel;
