// 案件狀態
export const CASE_STATUS = {
  S1: {
    value: 'S1',
    label: '未指派'
  },

  S2: {
    value: 'S2',
    label: '未指派(訪員拒接)'
  },

  S3: {
    value: 'S3',
    label: '已指派'
  },

  S4: {
    value: 'S4',
    label: '已承件'
  },

  S5: {
    value: 'S5',
    label: '開始稽核'
  },

  S6: {
    value: 'S6',
    label: '稽核結束'
  },

  S7: {
    value: 'S7',
    label: '待審核'
  },

  S8: {
    value: 'S8',
    label: '審核中'
  },

  // 退件
  S9: {
    value: 'S9',
    label: '已退件'
  },

  S10: {
    value: 'S10',
    label: '允收'
  },

  S11: {
    value: 'S11',
    label: '執案失敗'
  },

  S12: {
    value: 'S12',
    label: '已撤案'
  }
};

// 案件截止日期類型
export const CASE_END_DATE_TYPES = {
  upcoming: {
    value: 'upcoming',
    label: '快截止'
  },

  expired: {
    value: 'expired',
    label: '已過期'
  }
};

// 案件類型
export const CASE_TYPES = {
  web: {
    value: 'web',
    label: '網站'
  },

  phone: {
    value: 'phone',
    label: '電話'
  },

  app: {
    value: 'app',
    label: 'App'
  },

  social: {
    value: 'social',
    label: '社群'
  }
};
