import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Radio, Input } from 'antd';
import SelfRow from 'src/components/CustomForm/components/SelfRow';
import FormatCell from 'src/components/CustomForm/components/FormatCell';
import SelfFormItem from 'src/components/CustomForm/components/SelfFormItem';
import BorderRightBottom from 'src/components/CustomForm/components/BorderRightBottom';
import useOptions from '../../hooks/useOptions';
import styles from './styles.module.scss';

function Template1() {
  const options = useOptions();

  return (
    <div className={styles.container}>
      {/* 專案交付 */}
      <div className={styles.section}>
        <div className={styles.title}>專案交付</div>

        <BorderRightBottom>
          <div className={styles.group}>
            <div className={styles.groupLabel}>提供/服務</div>

            <div className={styles.groupContent}>
              <SelfRow>
                <FormatCell label="完成狀況" required>
                  <SelfFormItem name={['serve', 'completionStatus']} required>
                    <Radio.Group options={options.completionStatuses} />
                  </SelfFormItem>
                </FormatCell>
              </SelfRow>

              <SelfRow>
                <FormatCell label="補充說明">
                  <SelfFormItem name={['serve', 'description']}>
                    <Input.TextArea rows={4} maxLength={500} showCount />
                  </SelfFormItem>
                </FormatCell>
              </SelfRow>

              <SelfRow>
                <FormatCell label="附加檔案雲端連結">
                  <SelfFormItem name={['serve', 'cloudLink']}>
                    <Input size="small" maxLength={200} showCount />
                  </SelfFormItem>
                </FormatCell>
              </SelfRow>
            </div>
          </div>

          <div className={styles.group}>
            <div className={styles.groupLabel}>提交/交付物</div>

            <div className={styles.groupContent}>
              <SelfRow>
                <FormatCell label="完成狀況" required>
                  <SelfFormItem
                    name={['deliverable', 'completionStatus']}
                    required
                  >
                    <Radio.Group options={options.completionStatuses} />
                  </SelfFormItem>
                </FormatCell>
              </SelfRow>

              <SelfRow>
                <FormatCell label="補充說明">
                  <SelfFormItem name={['deliverable', 'description']}>
                    <Input.TextArea rows={4} maxLength={500} showCount />
                  </SelfFormItem>
                </FormatCell>
              </SelfRow>

              <SelfRow>
                <FormatCell label="附加檔案雲端連結">
                  <SelfFormItem name={['deliverable', 'cloudLink']}>
                    <Input size="small" maxLength={200} showCount />
                  </SelfFormItem>
                </FormatCell>
              </SelfRow>
            </div>
          </div>
        </BorderRightBottom>
      </div>

      {/* 待完成事項 */}
      <div className={styles.section}>
        <div className={styles.title}>待完成事項</div>

        <BorderRightBottom>
          <SelfRow>
            <FormatCell label="說明">
              <SelfFormItem name={['todo', 'description']}>
                <Input.TextArea rows={4} maxLength={500} showCount />
              </SelfFormItem>
            </FormatCell>
          </SelfRow>

          <SelfRow>
            <FormatCell label="附加檔案雲端連結">
              <SelfFormItem name={['todo', 'cloudLink']}>
                <Input size="small" maxLength={200} showCount />
              </SelfFormItem>
            </FormatCell>
          </SelfRow>
        </BorderRightBottom>
      </div>

      {/* 備註事項 */}
      <div className={styles.section}>
        <div className={styles.title}>備註事項</div>

        <BorderRightBottom>
          <SelfRow>
            <FormatCell label="說明">
              <SelfFormItem name={['remark', 'description']}>
                <Input.TextArea rows={4} maxLength={500} showCount />
              </SelfFormItem>
            </FormatCell>
          </SelfRow>

          <SelfRow>
            <FormatCell label="附加檔案雲端連結">
              <SelfFormItem name={['remark', 'cloudLink']}>
                <Input size="small" maxLength={200} showCount />
              </SelfFormItem>
            </FormatCell>
          </SelfRow>
        </BorderRightBottom>
      </div>
    </div>
  );
}

Template1.propTypes = {};

Template1.defaultProps = {};

export default Template1;
