import CaseAPIs from 'src/apis/CaseAPIs';
import CaseListItemDataModel from 'src/models/response/CaseListItemDataModel';
import CaseDetailDataModel from 'src/models/response/CaseDetailDataModel';
import CookieServices from './CookieServices';

// 案件

class CaseServices {
  // 讀取案件列表
  static async getCase({ projectId }) {
    const res = await CaseAPIs.getCase({
      token: CookieServices.getToken(),
      projectId
    });

    return {
      data: res.data.data.map((item) => new CaseListItemDataModel(item))
    };
  }

  // 新增案件
  static async postCaseCreate({ projectId, site, info }) {
    const res = await CaseAPIs.postCaseCreate({
      token: CookieServices.getToken(),
      projectId,
      site,
      info: {
        // 門市名稱 + 案件名稱
        name: info.name,
        // 案件類型
        caseType: info.caseType,
        // 門市地址
        address: info.address,
        // 所屬區域
        region: info.region,
        phone: info.phone,
        // 營業時間:info.name,
        businessHours: info.businessHours,
        startDate: info.startDate,
        expiryDate: info.expiryDate,
        // 上傳檔案位置
        uploadPath: info.uploadPath,
        // 案件說明
        directions: info.directions
      }
    });

    return {};
  }

  // 刪除案件
  static async deleteCase({ ids }) {
    const res = await CaseAPIs.deleteCase({
      token: CookieServices.getToken(),
      ids
    });

    return {
      data: res.data
    };
  }

  // 批次新增案件
  static async postCaseCreateBatch({ projectId, site, info }) {
    const res = await CaseAPIs.postCaseCreateBatch({
      token: CookieServices.getToken(),
      projectId,
      site,
      info
    });

    return {
      data: res.data
    };
  }

  // 更新案件
  static async putCase({ id, data }) {
    const res = await CaseAPIs.putCase({
      token: CookieServices.getToken(),
      id,
      data: {
        // 門市名稱 + 案件名稱
        name: data.name,
        // 案件類型
        caseType: data.caseType,
        // 門市地址
        address: data.address,
        // 所屬區域
        region: data.region,
        phone: data.phone,
        // 營業時間:data.name,
        businessHours: data.businessHours,
        startDate: data.startDate,
        expiryDate: data.expiryDate,
        // 上傳檔案位置
        uploadPath: data.uploadPath,
        // 案件說明
        directions: data.directions
      }
    });

    return {
      data: res.data
    };
  }

  // 案件推進(選擇派派遣訪員)
  static async postCaseAction({ caseId, nextStep, visitorId, message }) {
    const res = await CaseAPIs.postCaseAction({
      token: CookieServices.getToken(),
      caseId,
      nextStep,
      visitorId,
      message
    });

    return {
      data: res.data
    };
  }

  // 讀取案件詳情
  static async getCaseDetail({ caseId }) {
    const res = await CaseAPIs.getCaseDetail({
      token: CookieServices.getToken(),
      caseId
    });

    return {
      data: new CaseDetailDataModel(res.data.data)
    };
  }

  // pm 備註
  static async putCaseContent({ caseId, content }) {
    const res = await CaseAPIs.putCaseContent({
      token: CookieServices.getToken(),
      caseId,
      content
    });

    return {
      data: res.data
    };
  }
}

export default CaseServices;
