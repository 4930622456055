import { request, getHost } from './utils';

// 結案表
class ClosingSheetAPIs {
  // 讀取結案表
  static getClosingSheet({ token, projectId }) {
    const options = {
      method: 'get',

      url: `${getHost()}/v1/closingSheet/${projectId}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      }
    };

    return request(options);
  }

  // 更新結案表
  static postClosingSheet({ token, projectId, content }) {
    const options = {
      method: 'post',

      url: `${getHost()}/v1/closingSheet`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      data: {
        projectId, content
      }
    };

    return request(options);
  }
}

export default ClosingSheetAPIs;
