export const IS_DEV = true;

export const VISITOR_TYPE = 'visitor';

export const DEV_ROUTES = {
  reply: {
    value: 'reply'
  }
};

export const DEV_ROUTE_SEARCHES = {
  user: 'user',
  password: 'password'
};
