import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import {
  LICENSE,
  DRIVING_LICENSE,
  POLICE_CRIMINAL_RECORD_CERTIFICATE,
  GENDERS,
  AGES,
  INSURERS,
  ROLE_NEEDS,
  DEVICE_NEEDS
} from 'src/global/form';

class VisitorRequirementsViewModel {
  @computed
  get mapOptions() {
    return {
      license: Object.values(LICENSE),
      drivingLicense: Object.values(DRIVING_LICENSE),
      record: Object.values(POLICE_CRIMINAL_RECORD_CERTIFICATE),
      genders: Object.values(GENDERS),
      ages: Object.values(AGES),
      insurers: Object.values(INSURERS),
      roleNeeds: Object.values(ROLE_NEEDS),
      deviceNeeds: Object.values(DEVICE_NEEDS)
    };
  }

  constructor(props) {
    makeObservable(this);
  }
}

export default VisitorRequirementsViewModel;
