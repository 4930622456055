import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Input, Button } from 'antd';
import clsx from 'clsx';
import withRouter from 'src/components/withRouter';
import RejectFormViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class RejectForm extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new RejectFormViewModel({
      case: props.case,
      checkParams: props.checkParams,
      onChanged: props.onChanged
    });
  }

  componentDidUpdate(preProps) {
    this.vm.didUpdate(this.props, preProps);
  }

  render() {
    return this.vm.isShow ? (
      <div className={styles.container}>
        <div className={styles.title}>退件/不通過原因</div>

        <div className={styles.inputContainer}>
          <Input.TextArea
            rows={6}
            value={this.vm.reason}
            onChange={this.vm.onChangeReason}
            disabled={this.vm.isAwait}
            maxLength={2000}
            showCount
          />
        </div>

        <div className={styles.buttons}>
          <div className={styles.buttonItem}>
            <Button
              type="primary"
              className={styles.button}
              size="large"
              onClick={this.vm.onReturnStep}
              disabled={this.vm.isAwait}
            >
              退件
            </Button>

            <div className={styles.label}>請訪員修改查檢表</div>
          </div>

          <div className={styles.buttonItem}>
            <Button
              type="text"
              className={clsx([styles.button, styles.failButton])}
              size="large"
              onClick={this.vm.onReject}
              disabled={this.vm.isAwait}
            >
              不通過
            </Button>

            <div className={styles.label}>執案失敗</div>
          </div>
        </div>
      </div>
    ) : null;
  }
}

RejectForm.propTypes = {
  case: PropTypes.object.isRequired,
  checkParams: PropTypes.object.isRequired,
  onChanged: PropTypes.func.isRequired
};

RejectForm.defaultProps = {};

export default RejectForm;
