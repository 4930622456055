import {
  makeObservable,
  observable,
  action,
  computed
} from 'mobx';
import { ROUTES, USER_TYPES } from 'src/global/constants';
import { diff } from 'deep-diff';
import UserServices from 'src/services/UserServices';

class MainLayoutViewModel {
  @observable profile = null;
  router = null;
  // 登出需要使用
  contextActions = null;

  @computed
  get check() {
    return {
      hasProfile: !!this.profile,
      hasMenu: this.profile?.type !== USER_TYPES.company.value
    };
  }

  @computed
  get label() {
    return {
      profileName: this.profile?.name
    };
  }

  constructor(props) {
    this.profile = props.profile;
    this.router = props.router;
    makeObservable(this);
  }

  didMount = (props) => {
    this.contextActions = props.context.actions;
  };

  // 檢查 route
  didUpdate = (props) => {
    this.checkProfile(props);
  };

  @action
  checkProfile = (props) => {
    const diffFlag = diff(this.profile, props.profile);

    if (diffFlag) {
      this.profile = props.profile;
    }
  };

  // 登出
  onLogout = async () => {
    const route = `/${ROUTES.login.value}`;

    // 刪除 token
    await UserServices.postUserLogout();

    // 刪除 profile
    this.contextActions.updateProfile(null);

    // 跳轉至 login
    this.router.navigate(route, { replace: true });
  };
}

export default MainLayoutViewModel;
