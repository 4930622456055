import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button, Tag } from 'antd';
import clsx from 'clsx';
import { EVALUATORS } from 'src/global/constants';
import { PRACTICE_CITIES } from 'src/global/visitor';
import withRouter from 'src/components/withRouter';
import SimpleForm from 'src/components/SimpleForm';
import FormInput from './components/FormInput';
import FormTextArea from './components/FormTextArea';
import FormSelect from './components/FormSelect';
import FormDatePicker from './components/FormDatePicker';
import FormGreetingRecord from './components/FormGreetingRecord';
import FormMultiple from './components/FormMultiple';
import InterviewerDetailViewModel from './viewModel';
import styles from './styles.module.scss';
import './reset.scss';

@observer
class InterviewerDetail extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new InterviewerDetailViewModel({
      data: props.data,
      updated: props.updated,
      router: props.router
    });
  }

  componentDidMount() {
    this.vm.didMount(this.props);
  }

  // 基本資料
  renderBasicData = () => {
    return (
      <div className={styles.section}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>基本資料</div>
        </div>

        <div className={styles.descriptionContainer}>
          <div className={styles.row}>
            <div className={styles.item}>
              <div className={clsx([styles.label, styles.labelWidth1])}>
                身分證字號
              </div>

              <div className={styles.content}>{this.vm.label.idNumber}</div>
            </div>

            <div className={styles.item}>
              <div className={clsx([styles.label, styles.labelWidth1])}>
                姓名
              </div>

              <div className={styles.content}>
                <FormTextArea
                  maxLength={50}
                  isEdit={this.vm.isEdit}
                  name="name"
                  label={this.vm.data.name}
                  required={this.vm.required}
                />
              </div>
            </div>

            <div className={styles.item}>
              <div className={clsx([styles.label, styles.labelWidth1])}>
                代號
              </div>

              <div className={styles.content}>
                <FormTextArea
                  maxLength={50}
                  isEdit={this.vm.isEdit}
                  name="nickName"
                  label={this.vm.data.nickName}
                />
              </div>
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.item}>
              <div className={clsx([styles.label, styles.labelWidth1])}>
                性別
              </div>

              <div className={styles.content}>
                <FormSelect
                  isEdit={this.vm.isEdit}
                  name="gender"
                  options={this.vm.antOptions.gender}
                  label={this.vm.data.gender}
                />
              </div>
            </div>

            <div className={styles.item}>
              <div className={clsx([styles.label, styles.labelWidth1])}>
                生日
              </div>

              <div className={styles.content}>{this.vm.birthdayLabel}</div>
            </div>

            <div className={styles.item}>
              <div className={clsx([styles.label, styles.labelWidth1])}>
                婚姻
              </div>

              <div className={styles.content}>
                <FormSelect
                  isEdit={this.vm.isEdit}
                  name="marriage"
                  options={this.vm.antOptions.marriage}
                  label={this.vm.data.marriage}
                />
              </div>
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.item}>
              <div className={clsx([styles.label, styles.labelWidth1])}>
                Email
              </div>

              <div className={styles.content}>
                <FormTextArea
                  maxLength={50}
                  isEdit={this.vm.isEdit}
                  name="email"
                  label={this.vm.data.email}
                />
              </div>
            </div>

            <div className={styles.item}>
              <div className={clsx([styles.label, styles.labelWidth1])}>
                LineID
              </div>

              <div className={styles.content}>
                <FormTextArea
                  maxLength={50}
                  isEdit={this.vm.isEdit}
                  name="lineId"
                  label={this.vm.data.lineId}
                />
              </div>
            </div>

            <div className={styles.item}>
              <div className={clsx([styles.label, styles.labelWidth1])}>
                手機
              </div>

              <div className={styles.content}>
                <FormTextArea
                  maxLength={50}
                  isEdit={this.vm.isEdit}
                  name="phone"
                  label={this.vm.data.phone}
                />
              </div>
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.item}>
              <div className={clsx([styles.label, styles.labelWidth1])}>
                最高學歷
              </div>

              <div className={styles.content}>
                <FormTextArea
                  maxLength={50}
                  isEdit={this.vm.isEdit}
                  name="education"
                  label={this.vm.data.education}
                />
              </div>
            </div>

            <div className={styles.item}>
              <div className={clsx([styles.label, styles.labelWidth1])}>
                簽訂保密合約日
              </div>

              <div className={styles.content}>
                <FormDatePicker
                  isEdit={this.vm.isEdit}
                  name="signingDate"
                  label={this.vm.data.signingDate}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // 生日賀卡
  renderBirthdayCard = () => {
    return (
      <div className={styles.section}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>生日賀卡</div>
        </div>

        <div className={styles.descriptionContainer}>
          <div className={styles.row}>
            <div className={styles.item}>
              <div className={clsx([styles.label, styles.labelWidth1])}>
                寄送年度
              </div>

              <div className={styles.content}>
                <FormGreetingRecord
                  isEdit={this.vm.isEdit}
                  options={this.vm.antOptions.years}
                  name="greetingRecord"
                  label={this.vm.data.greetingRecord}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // 執案條件
  renderProfessionalCase = () => {
    return (
      <div className={styles.section}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>執案條件</div>
        </div>

        <div className={styles.descriptionContainer}>
          <div className={styles.row}>
            <div className={styles.item}>
              <div className={clsx([styles.label, styles.labelWidth1])}>
                現居地
              </div>

              <div className={styles.content}>
                <FormTextArea
                  maxLength={50}
                  isEdit={this.vm.isEdit}
                  name="address"
                  label={this.vm.data.address}
                  showCount
                />
              </div>
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.item}>
              <div className={clsx([styles.label, styles.labelWidth1])}>
                擁有汽車品牌
                <br />
                (無則免填)
              </div>

              <div className={styles.content}>
                <FormTextArea
                  maxLength={50}
                  isEdit={this.vm.isEdit}
                  name="carItem"
                  label={this.vm.data.carItem}
                />
              </div>
            </div>

            <div className={styles.item}>
              <div className={clsx([styles.label, styles.labelWidth1])}>
                擁有機車品牌
                <br />
                (無則免填)
              </div>

              <div className={styles.content}>
                <FormTextArea
                  maxLength={50}
                  isEdit={this.vm.isEdit}
                  name="motorItem"
                  label={this.vm.data.motorItem}
                />
              </div>
            </div>

            <div className={styles.item}>
              <div className={clsx([styles.label, styles.labelWidth1])}>
                汽車駕照
              </div>

              <div className={styles.content}>
                <FormSelect
                  isEdit={this.vm.isEdit}
                  options={this.vm.antOptions.withOrWithout}
                  name="carLicense"
                  label={this.vm.data.carLicense}
                />
              </div>
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.item}>
              <div className={clsx([styles.label, styles.labelWidth1])}>
                機車駕照
              </div>

              <div className={styles.content}>
                <FormSelect
                  isEdit={this.vm.isEdit}
                  options={this.vm.antOptions.withOrWithout}
                  name="motorLicense"
                  label={this.vm.data.motorLicense}
                />
              </div>
            </div>

            <div className={styles.item}>
              <div className={clsx([styles.label, styles.labelWidth1])}>
                壽險保戶
              </div>

              <div className={styles.content}>
                <FormTextArea
                  maxLength={50}
                  isEdit={this.vm.isEdit}
                  name="insurance"
                  label={this.vm.data.insurance}
                />
              </div>
            </div>

            <div className={styles.item}>
              <div className={clsx([styles.label, styles.labelWidth1])}>
                電信用戶
              </div>

              <div className={styles.content}>
                <FormSelect
                  isEdit={this.vm.isEdit}
                  options={this.vm.antOptions.telecom}
                  name="telecom"
                  label={this.vm.data.telecom}
                />
              </div>
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.item}>
              <div className={clsx([styles.label, styles.labelWidth1])}>
                側錄設備有無
              </div>

              <div className={styles.content}>
                <FormSelect
                  isEdit={this.vm.isEdit}
                  options={this.vm.antOptions.withOrWithout}
                  name="haveDevice"
                  label={this.vm.data.haveDevice}
                />
              </div>
            </div>

            <div className={clsx([styles.item, styles.flex2])}>
              <div className={clsx([styles.label, styles.labelWidth1])}>
                側錄設備品項
                <br />
                (無則免填)
              </div>

              <div className={styles.content}>
                <FormTextArea
                  maxLength={50}
                  isEdit={this.vm.isEdit}
                  name="deviceItem"
                  label={this.vm.data.deviceItem}
                />
              </div>
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.item}>
              <div className={clsx([styles.label, styles.labelWidth1])}>
                可跑區域
              </div>

              <div className={styles.content}>
                <FormMultiple
                  isEdit={this.vm.isEdit}
                  const={PRACTICE_CITIES}
                  options={this.vm.antOptions.cities}
                  name="practiceCity"
                  label={this.vm.data.practiceCity}
                  labelKey="cityName"
                />
              </div>
            </div>

            <div className={styles.item}>
              <div className={clsx([styles.label, styles.labelWidth1])}>
                手機品牌
              </div>

              <div className={styles.content}>
                <FormTextArea
                  maxLength={50}
                  isEdit={this.vm.isEdit}
                  name="phoneBrand"
                  label={this.vm.data.phoneBrand}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // 能力培訓
  renderTrain = () => {
    return (
      <div className={styles.section}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>能力培訓</div>
        </div>

        <div className={styles.descriptionContainer}>
          <div className={styles.row}>
            <div className={styles.item}>
              <div className={clsx([styles.label, styles.labelWidth1])}>
                證照有無及意願
              </div>

              <div className={styles.content}>
                <FormTextArea
                  isEdit={this.vm.isEdit}
                  name="licenseAndWillingness"
                  label={this.vm.data.licenseAndWillingness}
                />
              </div>
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.item}>
              <div className={clsx([styles.label, styles.labelWidth1])}>
                TSAA/發證日
              </div>

              <div className={styles.content}>
                <FormDatePicker
                  isEdit={this.vm.isEdit}
                  name="tssaDate"
                  label={this.vm.data.tssaDate}
                />
              </div>
            </div>

            <div className={styles.item}>
              <div className={clsx([styles.label, styles.labelWidth1])}>
                SGS/發證日
              </div>

              <div className={styles.content}>
                <FormDatePicker
                  isEdit={this.vm.isEdit}
                  name="sgsDate"
                  label={this.vm.data.sgsDate}
                />
              </div>
            </div>

            <div className={styles.item}>
              <div className={clsx([styles.label, styles.labelWidth1])}>
                顧問神秘客/發證日
              </div>

              <div className={styles.content}>
                <FormDatePicker
                  isEdit={this.vm.isEdit}
                  name="consultantDate"
                  label={this.vm.data.consultantDate}
                />
              </div>
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.item}>
              <div className={clsx([styles.label, styles.labelWidth2])}>
                神秘客培訓經歷(請填寫接受神秘客培訓的課程名稱)
              </div>

              <div className={styles.content}>
                <FormTextArea
                  isEdit={this.vm.isEdit}
                  name="trainingCaption"
                  label={this.vm.data.trainingCaption}
                />
              </div>
            </div>

            <div className={styles.item}>
              <div className={clsx([styles.label, styles.labelWidth1])}>
                課程完訓日期
              </div>

              <div className={styles.content}>
                <FormDatePicker
                  isEdit={this.vm.isEdit}
                  name="trainingDate"
                  label={this.vm.data.trainingDate}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // 執案履歷
  renderRunCase = () => {
    return (
      <div className={styles.section}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>執案履歷</div>
        </div>

        <div className={styles.descriptionContainer}>
          <div className={styles.row}>
            <div className={styles.item}>
              <div className={clsx([styles.label, styles.labelWidth1])}>
                產業
              </div>

              <div className={styles.content}>
                {this.vm.label.visitorIndustry.map((item) => {
                  return (
                    <Tag className={styles.tag} color="red" key={item.id}>
                      {item.label}
                    </Tag>
                  );
                })}
              </div>
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.item}>
              <div className={clsx([styles.label, styles.labelWidth1])}>
                同業
              </div>

              <div className={styles.content}>
                <FormTextArea
                  isEdit={this.vm.isEdit}
                  name="peerDetails"
                  label={this.vm.data.peerDetails}
                />
              </div>
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.item}>
              <div className={clsx([styles.label, styles.labelWidth1])}>
                評鑑
              </div>

              <div className={styles.content}>
                <FormMultiple
                  isEdit={this.vm.isEdit}
                  const={EVALUATORS}
                  options={this.vm.antOptions.evaluators}
                  name="evaluation"
                  label={this.vm.data.evaluation}
                  labelKey="evaluation"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // 績效紀錄
  renderPerformance = () => {
    return (
      <div className={styles.section}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>績效紀錄</div>
        </div>

        <div className={styles.descriptionContainer}>
          <div className={styles.row}>
            <div className={styles.item}>
              <div className={clsx([styles.label, styles.labelWidth1])}>
                特殊狀況
              </div>

              <div className={styles.content}>
                <FormTextArea
                  maxLength={50}
                  isEdit={this.vm.isEdit}
                  name="performanceStatus"
                  label={this.vm.data.performanceStatus}
                />
              </div>
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.item}>
              <div className={clsx([styles.label, styles.labelWidth1])}>
                狀況說明
              </div>

              <div className={styles.content}>
                <FormTextArea
                  isEdit={this.vm.isEdit}
                  name="performanceDirections"
                  label={this.vm.data.performanceDirections}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // 未分類的
  renderOther = () => {
    return (
      <div className={styles.other}>
        <div className={styles.row}>
          <div className={styles.item}>
            <div className={clsx([styles.label, styles.labelWidth1])}>
              實測通過日期
            </div>

            <div className={styles.content}>
              <FormDatePicker
                isEdit={this.vm.isEdit}
                name="passedDate"
                label={this.vm.data.passedDate}
              />
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.item}>
            <div className={clsx([styles.label, styles.labelWidth1])}>
              訪員註冊
            </div>

            <div className={styles.content}>
              <div className={styles.contentLabel}>
                {this.vm.label.visitorUpdated}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.item}>
            <div className={clsx([styles.label, styles.labelWidth1])}>備註</div>

            <div className={styles.content}>
              <FormTextArea
                isEdit={this.vm.isEdit}
                name="remark"
                label={this.vm.data.remark}
              />
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.item}>
            <div className={clsx([styles.label, styles.labelWidth1])}>
              評鑑註記
            </div>

            <div className={styles.content}>
              <FormTextArea
                isEdit={this.vm.isEdit}
                name="testFlag"
                label={this.vm.data.testFlag}
              />
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.item}>
            <div className={clsx([styles.label, styles.labelWidth1])}>
              登打者註記
            </div>

            <div className={styles.content}>{this.vm.updateLabel}</div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div id="interviewerDetail" className={styles.container}>
        {this.vm.check.hasData && (
          <div className={styles.mainContent}>
            <div className={styles.header}>
              <div className={styles.title}>{this.vm.label.title}</div>

              {this.props.hasEdit && (
                <Button
                  type="primary"
                  onClick={this.vm.onChangeEdit}
                  disabled={this.vm.isAwait}
                >
                  {this.vm.label.editLabel}
                </Button>
              )}
            </div>

            <div className={styles.formContainer}>
              <div className={styles.minWidth}>
                <div className={styles.borderRightBottom}>
                  <SimpleForm ref={this.vm.formRef}>
                    {this.renderBasicData()}

                    {this.renderBirthdayCard()}

                    {this.renderProfessionalCase()}

                    {this.renderTrain()}

                    {this.renderRunCase()}

                    {this.renderPerformance()}

                    {this.renderOther()}
                  </SimpleForm>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

InterviewerDetail.propTypes = {
  data: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  hasEdit: PropTypes.bool.isRequired,
  updated: PropTypes.func.isRequired
};

InterviewerDetail.defaultProps = {};

export default withRouter(InterviewerDetail);
