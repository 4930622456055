import AnnouncementAPIs from 'src/apis/AnnouncementAPIs';
import AnnouncementDataModel from 'src/models/response/AnnouncementDataModel';
import CookieServices from './CookieServices';

// 公告
class AnnouncementServices {
  // 讀取公告列表
  static async getAnnouncementList() {
    const res = await AnnouncementAPIs.getAnnouncementList(
      CookieServices.getToken()
    );

    return {
      data: res.data.data.map((item) => new AnnouncementDataModel(item))
    };
  }

  // 更新公告
  static async putAnnouncement({ id, title, content }) {
    const res = await AnnouncementAPIs.putAnnouncement({
      token: CookieServices.getToken(),
      id,
      title,
      content
    });

    return {
      data: res.data
    };
  }
}

export default AnnouncementServices;
