import React from 'react';
import PropTypes from 'prop-types';
import { observer, Observer } from 'mobx-react';
import { Table, Button } from 'antd';
import ICONS from 'src/global/icons';
import withRouter from 'src/components/withRouter';
import CustomTable from 'src/components/CustomTable';
import CustomLink from 'src/components/CustomLink';
import DeleteModal from 'src/components/DeleteModal';
import AddButton from 'src/components/AddButton';
import EmptyData from 'src/components/EmptyData';
import CaseEndDateTag from '../CaseEndDateTag';
import SiteTableViewModel from './viewModel';
import styles from './styles.module.scss';
import './reset.scss';

@observer
class SiteTable extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new SiteTableViewModel({
      type: props.type,
      onOneDeleted: props.onOneDeleted,
      onManyDeleted: props.onManyDeleted,
      router: props.router
    });
  }

  // 新增
  onCreate = () => {
    if (this.vm.check.onSite) {
      this.props.caseDrawerVM.onCreateOnSiteCase();
    }

    if (this.vm.check.offSite) {
      this.props.caseDrawerVM.onCreateOffSiteCase();
    }
  };

  // 批次新增
  onCreateMany = () => {
    if (this.vm.check.onSite) {
      this.props.importModalVM.onCreateOnSiteCase();
    }

    if (this.vm.check.offSite) {
      this.props.importModalVM.onCreateOffSiteCase();
    }
  };

  // 編輯
  onEdit = (vm) => {
    if (this.vm.check.onSite) {
      this.props.caseDrawerVM.onEditOnSiteCase(vm);
    }

    if (this.vm.check.offSite) {
      this.props.caseDrawerVM.onEditOffSiteCase(vm);
    }
  };

  renderOperateButtons = () => {
    return (
      <div className={styles.operateButtons}>
        <Button
          type="primary"
          className={styles.button}
          ghost
          onClick={this.vm.deleteSelectedModalVM.show}
          disabled={this.vm.disable.delete}
        >
          <ICONS.Delete />
          刪除案件
        </Button>

        <div className={styles.createButtons}>
          <AddButton label="新增案件" onClick={this.onCreate} />

          <div className={styles.space} />

          <AddButton label="批次匯入案件" onClick={this.onCreateMany} />
        </div>
      </div>
    );
  };

  // 現場案件表格
  renderOnSiteTable = () => {
    return (
      <>
        <Table.Column
          width={150}
          title="門市名稱"
          render={(vm) => {
            return <Observer>{() => vm.name}</Observer>;
          }}
        />

        <Table.Column
          width={150}
          title="門市地址"
          render={(vm) => {
            return (
              <Observer>
                {() => {
                  return vm.address;
                }}
              </Observer>
            );
          }}
        />

        <Table.Column
          width={140}
          title="門市區域"
          render={(vm) => {
            return (
              <Observer>
                {() => {
                  return vm.label.region;
                }}
              </Observer>
            );
          }}
        />

        <Table.Column
          width={130}
          title="電話"
          render={(vm) => {
            return (
              <Observer>
                {() => {
                  return vm.phone;
                }}
              </Observer>
            );
          }}
        />

        <Table.Column
          width={120}
          title="營業時間"
          render={(vm) => {
            return (
              <Observer>
                {() => {
                  return vm.businessHours;
                }}
              </Observer>
            );
          }}
        />
      </>
    );
  };

  // 非現場案件
  renderOffSiteTable = () => {
    return (
      <>
        <Table.Column
          width={240}
          title="案件名稱"
          render={(vm) => {
            return <Observer>{() => vm.name}</Observer>;
          }}
        />

        <Table.Column
          width={130}
          title="案件類型"
          render={(vm) => {
            return (
              <Observer>
                {() => {
                  return vm.label.type;
                }}
              </Observer>
            );
          }}
        />

        <Table.Column
          width={200}
          title="案件說明"
          render={(vm) => {
            return (
              <Observer>
                {() => {
                  return (
                    <div className={styles.directions}>{vm.directions}</div>
                  );
                }}
              </Observer>
            );
          }}
        />
      </>
    );
  };

  render() {
    return (
      <div id="caseTableSiteTable" className={styles.container}>
        {!this.props.disabled && this.renderOperateButtons()}

        {this.props.hasData ? (
          <div className={styles.tableContainer}>
            <CustomTable
              data={this.props.data}
              hasScrollX
              rowSelection={!this.props.disabled && this.vm.rowSelection}
            >
              <Table.Column
                width={100}
                title="狀態"
                render={(vm) => {
                  return <Observer>{() => vm.stepName}</Observer>;
                }}
                filters={this.vm.filterStatus}
                onFilter={this.vm.onFilterStatus}
              />

              {/* 現場案件 */}
              {this.vm.check.onSite && this.renderOnSiteTable()}

              {/* 非現場案件 */}
              {this.vm.check.offSite && this.renderOffSiteTable()}

              <Table.Column
                width={140}
                title="執案開始日"
                render={(vm) => {
                  return <Observer>{() => vm.label.startDate}</Observer>;
                }}
              />

              <Table.Column
                width={180}
                title="案件截止日"
                render={(vm) => {
                  return (
                    <Observer>
                      {() => {
                        return (
                          <div className={styles.endDateContainer}>
                            <div className={styles.date}>
                              {vm.label.expiryDate}
                            </div>

                            {/* 快截止 */}
                            {vm.check.isUpcoming && (
                              <CaseEndDateTag type="warning" />
                            )}

                            {/* 已過期 */}
                            {vm.check.isExpired && (
                              <CaseEndDateTag type="error" />
                            )}
                          </div>
                        );
                      }}
                    </Observer>
                  );
                }}
                filters={this.vm.filterEndDate}
                onFilter={this.vm.onFilterEndDate}
              />

              <Table.Column
                width={180}
                title="指派訪員"
                render={(vm) => {
                  return (
                    <Observer>
                      {() => {
                        const edit = !this.props.disabled && vm.check.isNobody;

                        return (
                          <div className={styles.interviewerContainer}>
                            <div className={styles.interviewer}>
                              {vm.label.visitorName}
                            </div>

                            {edit && (
                              <Button
                                type="primary"
                                size="small"
                                onClick={() => {
                                  this.props.interviewerDrawerVM.open({
                                    id: vm.id,
                                    onChanged: vm.onInterviewerChanged
                                  });
                                }}
                              >
                                指派訪員
                              </Button>
                            )}
                          </div>
                        );
                      }}
                    </Observer>
                  );
                }}
              />

              <Table.Column
                width={160}
                title="操作"
                render={(vm) => {
                  const link = this.vm.getCaseDetailLink(vm.id);

                  return (
                    <div className={styles.operateContainer}>
                      <div className={styles.link}>
                        <CustomLink to={link}>查看詳情</CustomLink>
                      </div>

                      {!this.props.disabled && (
                        <Button
                          type="link"
                          size="small"
                          onClick={() => {
                            this.onEdit(vm);
                          }}
                        >
                          編輯
                        </Button>
                      )}

                      {!this.props.disabled && (
                        <Button
                          type="link"
                          size="small"
                          disabled={this.props.disabled}
                          onClick={() => {
                            this.vm.setDeleteId(vm.id);
                            this.vm.deleteItemModalVM.show();
                          }}
                        >
                          <ICONS.Delete />
                        </Button>
                      )}
                    </div>
                  );
                }}
              />
            </CustomTable>
          </div>
        ) : (
          <div className={styles.emptyDataContainer}>
            <EmptyData label="目前沒有資料 請新增案件" />
          </div>
        )}

        {/* 刪除多個 */}
        <DeleteModal
          vm={this.vm.deleteSelectedModalVM}
          onConfirm={this.vm.onDeleteSelectedConfirm}
        />

        {/* 刪除一個 */}
        <DeleteModal
          vm={this.vm.deleteItemModalVM}
          onConfirm={this.vm.onDeleteItemConfirm}
        />
      </div>
    );
  }
}

SiteTable.propTypes = {
  router: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  data: PropTypes.array,
  hasData: PropTypes.bool,
  disabled: PropTypes.bool,
  onOneDeleted: PropTypes.func.isRequired,
  onManyDeleted: PropTypes.func.isRequired,
  interviewerDrawerVM: PropTypes.object.isRequired,
  caseDrawerVM: PropTypes.object.isRequired,
  importModalVM: PropTypes.object.isRequired
};

SiteTable.defaultProps = {
  data: [],
  disabled: false
};

export default withRouter(SiteTable);
