class CustomError extends Error {
  constructor(message) {
    super(message);

    this.message = message ?? '';
  }

  toString = () => this.message;

  static fromError(err) {
    if (err.response) {
      return new CustomError(
        `${err.response.status} ${err.response.data?.message}`
      );
    }

    return new CustomError(err?.message ?? err?.stack ?? err);
  }
}

export default CustomError;
