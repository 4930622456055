import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import {
  ROUTES,
  ROUTE_PARAMS,
  USER_TYPES,
  PROJECT_STATUS
} from 'src/global/constants';
import ProjectServices from 'src/services/ProjectServices';
import ErrorServices from 'src/services/ErrorServices';

class ProjectDetailPageViewModel {
  @observable profile = null;
  @observable data = null;
  @observable isAwait = false;
  projectId = null;
  router = null;

  @computed
  get hasData() {
    return !!this.data;
  }

  @computed
  get backRoute() {
    const pathName = this.router.location.pathname;
    const routes = Object.values(ROUTES).filter((item) => item.value);
    const route = routes.filter((item) => {
      const index = pathName.indexOf(item.value);

      return index !== -1;
    })[0];

    return route;
  }

  @computed
  get backLink() {
    return `/${this.backRoute.value}`;
  }

  @computed
  get routes() {
    if (this.backRoute) {
      return [
        {
          link: this.backLink,
          label: this.backRoute.label
        },
        {
          label: this.data.projectName
        }
      ];
    }

    return [];
  }

  @computed
  get checkParams() {
    // 是老闆
    const isBoss = this.profile.type === USER_TYPES.boss.value;
    // 是營管部
    const isOM = this.profile.type === USER_TYPES.operationsManagement.value;
    // 是 pm
    const isPM = this.profile.type === USER_TYPES.projectManager.value;
    // 是業務
    const isSa = this.profile.type === USER_TYPES.salesperson.value;
    // 是負責人
    const isHandler = this.profile.type === this.data.handler;
    // 專案結束
    const isEnd
      = this.data.step === PROJECT_STATUS.S9.value
      || this.data.step === PROJECT_STATUS.S10.value;

    return {
      isBoss,
      isOM,
      isPM,
      isSa,
      isHandlerOrBoss: isBoss || isHandler,
      isHandler,
      isEnd
    };
  }

  constructor(props) {
    this.profile = props.profile;
    this.router = props.router;

    this.redRoute(props);

    makeObservable(this);
  }

  redRoute = (props) => {
    const id = props.router.params[ROUTE_PARAMS.projectId];

    this.projectId = id;
  };

  didMount = async () => {
    if (!this.isAwait) {
      await this.getProjectDateAPI();
    }
  };

  @action
  onProjectNameChanged = (value) => {
    this.data = { ...this.data, projectName: value };
  };

  // PushButton 專案推進後
  @action
  onPushed = () => {
    this.getProjectDateAPI();
  };

  @action
  getProjectDateAPI = async () => {
    this.isAwait = true;

    try {
      const res = await ProjectServices.getProjectDetail({
        projectId: this.projectId
      });

      runInAction(() => {
        this.data = res.data;

        console.log('專案資料', res.data);
      });
    } catch (error) {
      const message = ErrorServices.getProjectDetail(error);

      console.log('ProjectDetailPageViewModel', 'getProjectDetail', message);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default ProjectDetailPageViewModel;
