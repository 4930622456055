import React from 'react';
import PropTypes from 'prop-types';
import { observer, Observer } from 'mobx-react';
import { Table, Button, Tag } from 'antd';
import { Waypoint } from 'react-waypoint';
import clsx from 'clsx';
import { ROUTES } from 'src/global/constants';
import FixedTable from 'src/components/FixedTable';
import CustomLink from 'src/components/CustomLink';
import CustomDropdown from 'src/components/CustomDropdown';
import styles from './styles.module.scss';

function VisitorTable(props) {
  return (
    <FixedTable
      dataSource={props.data}
      scrollX="max-content"
      scrollY
      labelHeight={109}
      flex
      hidden
      padding
    >
      <Table.Column
        title="操作"
        align="center"
        render={(vm, _, i) => {
          const route = `/${ROUTES.interviewer.value}/${vm.id}`;
          const isLast = i === props.data.length - 1;

          return (
            <Observer>
              {() => {
                return (
                  <div className={clsx([vm.isDisable && 'selected'])}>
                    <CustomLink to={route}>查看詳情</CustomLink>

                    {props.hasEdit && (
                      <CustomDropdown
                        items={vm.dropdownItems}
                        onClick={vm.onClickDropdownMenuItem}
                      >
                        <Button className={styles.operateButton} type="link">
                          操作
                        </Button>
                      </CustomDropdown>
                    )}

                    {isLast && (
                      <div className="waypoint">
                        <Waypoint onEnter={props.onScrollEnd} />
                      </div>
                    )}
                  </div>
                );
              }}
            </Observer>
          );
        }}
      />

      <Table.Column
        width={65}
        title="狀態"
        render={(vm) => {
          return (
            <Observer>
              {() => {
                return (
                  <div className={clsx([vm.isDisable && 'selected'])}>
                    {vm.disabledLabel}
                  </div>
                );
              }}
            </Observer>
          );
        }}
      />

      <Table.Column
        width={65}
        title="序號"
        render={(vm) => {
          return (
            <Observer>
              {() => {
                return (
                  <div className={clsx([vm.isDisable && 'selected'])}>
                    {vm.id}
                  </div>
                );
              }}
            </Observer>
          );
        }}
      />

      <Table.Column
        width={120}
        title="訪員註冊"
        render={(vm) => vm.visitorUpdatedLabel}
      />

      <Table.ColumnGroup title="基本資料">
        <Table.Column
          width={62}
          title="代號"
          render={(vm) => {
            return (
              <Observer>
                {() => {
                  return (
                    <div className={clsx([vm.isDisable && 'selected'])}>
                      {vm.nickName}
                    </div>
                  );
                }}
              </Observer>
            );
          }}
        />

        <Table.Column
          width={78}
          title="姓名"
          render={(vm) => {
            return (
              <Observer>
                {() => {
                  return (
                    <div className={clsx([vm.isDisable && 'selected'])}>
                      {vm.name}
                    </div>
                  );
                }}
              </Observer>
            );
          }}
        />

        <Table.Column
          width={64}
          title="性別"
          render={(vm) => {
            return (
              <Observer>
                {() => {
                  return (
                    <div className={clsx([vm.isDisable && 'selected'])}>
                      {vm.genderLabel}
                    </div>
                  );
                }}
              </Observer>
            );
          }}
        />
      </Table.ColumnGroup>

      <Table.ColumnGroup title="執案條件">
        <Table.Column
          width={191}
          title="現居地"
          render={(vm) => {
            return (
              <Observer>
                {() => {
                  return (
                    <div
                      className={clsx([
                        styles.breakAll,
                        vm.isDisable && 'selected'
                      ])}
                    >
                      {vm.address}
                    </div>
                  );
                }}
              </Observer>
            );
          }}
        />

        <Table.Column
          width={130}
          title="側錄設備"
          render={(vm) => {
            return (
              <Observer>
                {() => {
                  return (
                    <div
                      className={clsx([
                        styles.breakAll,
                        vm.isDisable && 'selected'
                      ])}
                    >
                      {vm.deviceItem}
                    </div>
                  );
                }}
              </Observer>
            );
          }}
        />

        <Table.Column
          width={166}
          title="可跑區域"
          render={(vm) => {
            return (
              <Observer>
                {() => {
                  return (
                    <div
                      className={clsx([
                        styles.tags,
                        vm.isDisable && 'selected'
                      ])}
                    >
                      {vm.practiceCityLabel.map((item) => {
                        return (
                          <Tag className={styles.tag} color="red" key={item}>
                            {item}
                          </Tag>
                        );
                      })}
                    </div>
                  );
                }}
              </Observer>
            );
          }}
        />
      </Table.ColumnGroup>

      <Table.ColumnGroup title="能力培訓">
        <Table.Column
          width={120}
          title="TSSA發證日"
          render={(vm) => {
            return (
              <Observer>
                {() => {
                  return (
                    <div className={clsx([vm.isDisable && 'selected'])}>
                      {vm.tssaDateLabel}
                    </div>
                  );
                }}
              </Observer>
            );
          }}
        />

        <Table.Column
          width={120}
          title="SGS/發證日"
          render={(vm) => {
            return (
              <Observer>
                {() => {
                  return (
                    <div className={clsx([vm.isDisable && 'selected'])}>
                      {vm.sgsDateLabel}
                    </div>
                  );
                }}
              </Observer>
            );
          }}
        />

        <Table.Column
          width={162}
          title="顧問神秘客/發證日"
          render={(vm) => {
            return (
              <Observer>
                {() => {
                  return (
                    <div className={clsx([vm.isDisable && 'selected'])}>
                      {vm.consultantDateLabel}
                    </div>
                  );
                }}
              </Observer>
            );
          }}
        />
      </Table.ColumnGroup>
    </FixedTable>
  );
}

VisitorTable.propTypes = {
  data: PropTypes.array,
  hasEdit: PropTypes.bool,
  onScrollEnd: PropTypes.func
};

VisitorTable.defaultProps = {
  data: [],
  hasEdit: false,
  onScrollEnd: null
};

export default observer(VisitorTable);
