import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Input, Select } from 'antd';
import { ROUTES } from 'src/global/constants';
import withProfile from 'src/components/withProfile';
import withRouter from 'src/components/withRouter';
import CustomHeader from 'src/components/CustomHeader';
import AddButton from 'src/components/AddButton';
import CreateModal from './components/CreateModal';
import ImportModal from './components/ImportModal';
import VisitorTable from './components/VisitorTable';
import InterviewerPageViewModel from './viewModel';
import styles from './styles.module.scss';

// 老闆, 營業部, PM, 可以編輯
// 業務, 只讀
@observer
class InterviewerPage extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new InterviewerPageViewModel({
      profile: props.profile,
      router: props.router
    });
  }

  componentDidMount() {
    this.vm.didMount(this.props);
  }

  renderCreateButtons = () => {
    return (
      <div className={styles.editButtons}>
        <AddButton label="新增訪員" onClick={this.vm.createModalVM.open} />

        <div className={styles.space} />

        <AddButton label="批次新增" onClick={this.vm.importModalVM.open} />
      </div>
    );
  };

  render() {
    return (
      <div className={styles.container}>
        <CustomHeader title={ROUTES.interviewer.label} paddingBottom />

        {!this.vm.isInit && (
          <>
            <div className={styles.editContainer}>
              <div className={styles.searchContainer}>
                <div className={styles.selectContainer}>
                  <div className={styles.selectLabel}>
                    可跑區域：
                  </div>

                  <Select
                    mode="multiple"
                    className={styles.searchSelect}
                    value={this.vm.temporaryWorkingCounty}
                    onChange={this.vm.onTemporaryWorkingCountyChange}
                    onBlur={this.vm.onSelectBlur}
                    onDeselect={this.vm.onSelectDelete}
                    onDropdownVisibleChange={this.vm.onSelectOpenChange}
                    disabled={this.vm.isAwait}
                    placeholder="請選擇"
                    options={this.vm.options.cities}
                  />
                </div>

                <div className={styles.inputContainer}>
                  <Input.Search
                    className={styles.searchInput}
                    value={this.vm.search}
                    onChange={this.vm.onSearchChange}
                    onSearch={this.vm.onSearch}
                    placeholder="請輸入名字、代號、側錄設備、現居地"
                    disabled={this.vm.isAwait}
                    maxLength={50}
                  />
                </div>
              </div>

              {this.vm.hasEdit && this.renderCreateButtons()}
            </div>

            <VisitorTable
              data={this.vm.data}
              hasEdit={this.vm.hasEdit}
              onScrollEnd={this.vm.onScrollEnd}
            />
          </>
        )}

        <CreateModal vm={this.vm.createModalVM} onCreated={this.vm.onCreated} />

        <ImportModal vm={this.vm.importModalVM} onCreated={this.vm.onCreated} />
      </div>
    );
  }
}

InterviewerPage.propTypes = {
  profile: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired
};

InterviewerPage.defaultProps = {};

export default withProfile(withRouter(InterviewerPage), true);
