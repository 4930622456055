import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { observer } from 'mobx-react';
import StyleItem from 'src/components/StyleItem';
import TextAreaModal from 'src/components/TextAreaModal';
import RemarkItemViewModel from './viewModel';

// 專案備註
@observer
class RemarkItem extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new RemarkItemViewModel({
      project: props.project,
      checkParams: props.checkParams
    });
  }

  componentDidUpdate(preProps) {
    this.vm.didUpdate(this.props, preProps);
  }

  render() {
    return this.vm.isShow ? (
      <>
        <StyleItem>
          <span>老闆核示專案總結備註事項</span>

          <Button type="primary" onClick={this.vm.modalVM.show}>
            {this.vm.label.button}
          </Button>
        </StyleItem>

        <TextAreaModal
          vm={this.vm.modalVM}
          label="老闆核示專案總結備註事項"
          value={this.vm.value}
          onChange={this.vm.onChangeValue}
          onConfirm={this.vm.onConfirm}
          disabled={this.vm.disabled.modal}
          isAwait={this.vm.isAwait}
        />
      </>
    ) : null;
  }
}

RemarkItem.propTypes = {
  project: PropTypes.object.isRequired,
  checkParams: PropTypes.object.isRequired
};

RemarkItem.defaultProps = {
};

export default RemarkItem;
