import { makeObservable, observable, action, computed } from 'mobx';
import dayjs from 'dayjs';
import { diff } from 'deep-diff';
import { SITE_TYPES } from 'src/global/constants';
import { CASE_TYPES } from 'src/global/case';

class DescTableViewModel {
  @observable case = null;

  @computed
  get check() {
    return {
      onSite: this.case.siteType === SITE_TYPES.onSite.value,
      offSite: this.case.siteType === SITE_TYPES.offSite.value
    };
  }

  @computed
  get tableData() {
    const data = {
      id: this.case.caseId,
      step: this.case.stepName,
      name: this.case.name,
      address: this.case.address,
      region: this.case.region ? JSON.parse(this.case.region).join('') : null,
      phone: this.case.phone,
      businessHours: this.case.businessHours,
      visitorName: this.case.visitorName || '尚未指派',
      caseType: this.case.caseType
        ? CASE_TYPES[this.case.caseType].label
        : null,
      directions: this.case.directions,
      startDate: dayjs(this.case.startDate).format('YYYY-MM-DD'),
      expiryDate: dayjs(this.case.expiryDate).format('YYYY-MM-DD')
    };

    return [data];
  }

  constructor(props) {
    this.init(props);
    makeObservable(this);
  }

  @action
  init = (props) => {
    this.case = props.case;

    console.log('case', this.case);
    console.log('tableData', this.tableData);
  }

  didUpdate = (props, preProps) => {
    const diffCase = diff(props.case, preProps.case);

    if (diffCase) {
      this.init(props);
    }
  }
}

export default DescTableViewModel;
