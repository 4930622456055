import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Input, Button } from 'antd';
import ICONS from 'src/global/icons';
import styles from './styles.module.scss';

// 專案件酬試算, 動態的項目
function DynamicItem(props) {
  return (
    <div className={styles.container}>
      <div className={styles.label}>
        <Input
          className={styles.input}
          value={props.vm.label}
          onChange={props.vm.onChangeLabel}
          disabled={props.disabled}
          maxLength={50}
        />
        :
      </div>

      <div className={styles.content}>
        <Input
          className={styles.input}
          value={props.vm.value}
          onChange={props.vm.onChangeValue}
          disabled={props.disabled}
          maxLength={50}
        />
      </div>

      <div className={styles.deleteButtonContainer}>
        <Button
          type="link"
          size="small"
          onClick={() => {
            props.onDelete(props.vm.id);
          }}
          disabled={props.disabled}
        >
          <ICONS.Delete />
        </Button>
      </div>
    </div>
  );
}

DynamicItem.propTypes = {
  vm: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

DynamicItem.defaultProps = {
  disabled: false
};

export default observer(DynamicItem);
