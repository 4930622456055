import { request, getHost } from './utils';

// 查檢表
class CheckFormAPIs {
  // 讀取查檢表所有題目
  static getCheckFormItem({ token, projectId }) {
    const options = {
      method: 'get',

      url: `${getHost()}/v1/checkForm/item/${projectId}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      }
    };

    return request(options);
  }

  // 新增查檢表題目
  static postCheckFormItem({
    token,
    projectId,
    type,
    facet,
    inferiorFacet,
    topic,
    content,
    site
  }) {
    const options = {
      method: 'post',

      url: `${getHost()}/v1/checkForm/item/${projectId}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      data: {
        type,
        facet,
        inferiorFacet,
        topic,
        content,
        site
      }
    };

    return request(options);
  }

  // 更新查檢表題目
  static putCheckFormItem({
    token,
    id,
    type,
    implementation,
    implementWeighted,
    satisfaction,
    satisWeighted,
    topic,
    content
  }) {
    const options = {
      method: 'put',

      url: `${getHost()}/v1/checkForm/item/${id}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      data: {
        type,
        implementation,
        implementWeighted,
        satisfaction,
        satisWeighted,
        topic,
        content
      }
    };

    return request(options);
  }

  // 刪除查檢表題目
  static deleteCheckFormItem({ token, id }) {
    const options = {
      method: 'delete',

      url: `${getHost()}/v1/checkForm/item/${id}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      }
    };

    return request(options);
  }

  // 查檢表完成
  static putCheckFormCompleted({ token, projectId }) {
    const options = {
      method: 'put',

      url: `${getHost()}/v1/checkForm/completed/${projectId}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      }
    };

    return request(options);
  }

  // 更新雲端連結
  static putCheckFormAttached({
    token,
    projectId,
    onSiteAttached,
    offSiteAttached
  }) {
    const options = {
      method: 'put',

      url: `${getHost()}/v1/checkForm/${projectId}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      data: {
        onSiteAttached,
        offSiteAttached
      }
    };

    return request(options);
  }

  // 匯出查檢表結果
  static getCheckFormExport({ token, projectId }) {
    const options = {
      method: 'get',

      url: `${getHost()}/v1/checkForm/export/${projectId}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      }
    };

    return request(options);
  }

  // 讀取查檢表列表
  static getCheckFormList({
    token,
    limit,
    anchor,
    projectStep,
    completed,
    keyword
  }) {
    const options = {
      method: 'get',

      url: `${getHost()}/v1/checkForm/list`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      params: {
        limit,
        anchor,
        proejctStep: projectStep,
        completed,
        keyword
      }
    };

    return request(options);
  }

  // 複製其他專案的查檢表
  static postCheckFormItemImport({ token, projectId, sourceProjectId }) {
    const options = {
      method: 'post',

      url: `${getHost()}/v1/checkForm/item/import/${projectId}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      data: {
        sourceProject: sourceProjectId
      }
    };

    return request(options);
  }

  // 讀取查檢表的答案
  static getCheckFormReply({ token, caseId }) {
    const options = {
      method: 'get',

      url: `${getHost()}/v1/checkForm/reply/${caseId}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      }
    };

    return request(options);
  }

  // 編輯查檢表答案
  static postCheckFormReplyAdmin({
    token,
    caseId,
    visitorId,
    reply
  }) {
    const options = {
      method: 'post',

      url: `${getHost()}/v1/checkForm/reply/admin`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      data: {
        caseId,
        visitorId,
        reply
      }
    };

    return request(options);
  }

  // 客戶統計使用的查檢表列表
  static getCheckFormReplyList({
    token,
    projectId,
    anchor,
    limit
  }) {
    const options = {
      method: 'get',

      url: `${getHost()}/v1/checkForm/reply/list/${projectId}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      params: {
        anchor,
        limit
      }
    };

    return request(options);
  }
}

export default CheckFormAPIs;
