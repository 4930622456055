import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import BigNumber from 'bignumber.js';
import { ALLOWANCES, INCOMES } from 'src/global/profitForm';
import AllowanceViewModel from './AllowanceViewModel';
import IncomeViewModel from './IncomeViewModel';

class DataViewModel {
  // 專案件酬試算
  @observable allowances = [];
  // 專案件酬試算, 動態項目
  @observable dynamicAllowances = [];
  // 專案損益表
  @observable incomes = [];
  // 現場案件數量
  @observable onSiteCaseLength = null;
  // 非現場案件數量
  @observable offSiteCaseLength = null;
  // 其他案件數量
  @observable otherCaseLength = null;
  // 專案收入
  @observable income = null;
  // 有人修改過
  @observable isEdit = false;

  // 專案件酬試算
  @computed
  get mapAllowances() {
    const before = this.allowances.slice(0, 9);
    const center = this.allowances.slice(9, 11);
    const after = this.allowances.slice(11, 18);

    return {
      before,
      center,
      after
    };
  }

  // 專案損益表
  @computed
  get mapIncomes() {
    const before = this.incomes.slice(0, 15);
    const after = this.incomes.slice(15);

    return {
      before,
      after
    };
  }

  // 是否顯示新增動態件酬的按鈕
  @computed
  get showAddDynamicAllowanceButton() {
    return this.dynamicAllowances.length < 8;
  }

  // 專案預估費用
  @computed
  get estimatedFee() {
    const bRes = this.incomes.reduce((bSum, item, i) => {
      const bValue = new BigNumber(item.expected);
      return bValue.isNaN() ? bSum : bSum.plus(bValue);
    }, new BigNumber(0));
    const label = bRes.isNaN() ? '無資料' : bRes.toFormat(0);

    return {
      value: bRes.toNumber(),
      label
    };
  }

  // 專案預估毛利 + 比例
  @computed
  get estimatedIncome() {
    const bIncome = new BigNumber(this.income);
    const bRes = bIncome.minus(this.estimatedFee.value);
    const bRatio = bRes
      .dividedBy(this.income)
      .multipliedBy(100)
      .decimalPlaces(2);
    // .decimalPlaces(2, BigNumber.ROUND_CEIL);
    const label = bRes.isNaN() ? '無資料' : bRes.toFormat(0);
    const ratioLabel = bRatio.isNaN() ? '無資料' : `${bRatio.toNumber()}%`;

    return {
      value: bRes.toNumber(),
      label,
      ratio: bRatio.toNumber(),
      ratioLabel
    };
  }

  // 專案結算費用
  @computed
  get resultFee() {
    const bRes = this.incomes.reduce((bSum, item, i) => {
      const bValue = new BigNumber(item.result);
      return bValue.isNaN() ? bSum : bSum.plus(bValue);
    }, new BigNumber(0));
    const label = bRes.isNaN() ? '無資料' : bRes.toFormat(0);

    return {
      value: bRes.toNumber(),
      label
    };
  }

  // 專案結算毛利 + 比例
  @computed
  get resultIncome() {
    const bIncome = new BigNumber(this.income);
    const bRes = bIncome.minus(this.resultFee.value);
    const bRatio = bRes
      .dividedBy(this.income)
      .multipliedBy(100)
      .decimalPlaces(2);
    // 無條件進位
    // .decimalPlaces(2, BigNumber.ROUND_CEIL);
    const label = bRes.isNaN() ? '無資料' : bRes.toFormat(0);
    const ratioLabel = bRatio.isNaN() ? '無資料' : `${bRatio.toNumber()}%`;

    return {
      value: bRes.toNumber(),
      label,
      ratio: bRatio.toNumber(),
      ratioLabel
    };
  }

  // 案件費用
  @computed
  get cases() {
    const items = this.allowances.slice(3).concat(this.dynamicAllowances);
    const bTotal = items.reduce((bSum, vm, i) => {
      const bFee = new BigNumber(vm.value);

      return bFee.toNumber() ? bSum.plus(bFee) : bSum;
    }, new BigNumber(0));

    const bOnSiteTotal = bTotal.multipliedBy(this.onSiteCaseLength);
    const onSiteTotal = bOnSiteTotal.toNumber()
      ? bOnSiteTotal.toFormat(0)
      : '0';

    const bOffSiteTotal = bTotal.multipliedBy(this.offSiteCaseLength);
    const offSiteTotal = bOffSiteTotal.toNumber()
      ? bOffSiteTotal.toFormat(0)
      : '0';

    const bOtherTotal = bTotal.multipliedBy(this.otherCaseLength);
    const otherTotal = bOtherTotal.toNumber() ? bOtherTotal.toFormat(0) : '0';

    return {
      onSite: {
        length: this.onSiteCaseLength,
        total: onSiteTotal
      },
      offSite: {
        length: this.offSiteCaseLength,
        total: offSiteTotal
      },
      other: {
        length: this.otherCaseLength,
        total: otherTotal
      }
    };
  }

  constructor() {
    makeObservable(this);
  }

  // 專案件酬項目
  @action
  setAllowances = (value = ALLOWANCES) => {
    this.allowances = value.map((item) => {
      return new AllowanceViewModel({ data: item, setIsEdit: this.setIsEdit });
    });
  };

  @action
  setDynamicAllowances = (value = ALLOWANCES) => {
    this.dynamicAllowances = value.map((item) => {
      return new AllowanceViewModel({ data: item, setIsEdit: this.setIsEdit });
    });
  };

  // 專案損益表項目
  @action
  setIncomes = (value = INCOMES) => {
    this.incomes = value.map(
      (item) =>
        new IncomeViewModel({
          data: item,
          store: this,
          // 損益表修改過
          setIsEdit: this.setIsEdit
        })
    );
  };

  // 專案收入
  @action
  setIncome = (value) => {
    this.income = value;

    // 損益表修改過
    this.setIsEdit(true);
  };

  // 現場案件數量
  @action
  setOnSiteCaseLength = (value) => {
    this.onSiteCaseLength = value;

    // 損益表修改過
    this.setIsEdit(true);
  };

  // 非現場案件數量
  @action
  setOffSiteCaseLength = (value) => {
    this.offSiteCaseLength = value;

    // 損益表修改過
    this.setIsEdit(true);
  };

  // 其他案件數量
  @action
  setOtherCaseLength = (value) => {
    this.otherCaseLength = value;

    // 損益表修改過
    this.setIsEdit(true);
  };

  @action
  setIsEdit = (value) => {
    this.isEdit = value;
  };

  // 新增動態件酬
  @action
  addAllowance = () => {
    this.dynamicAllowances = [
      ...this.dynamicAllowances,
      new AllowanceViewModel({ setIsEdit: this.setIsEdit })
    ];
  };

  // 刪除動態件酬
  @action
  deleteAllowance = (id) => {
    this.dynamicAllowances = this.dynamicAllowances.filter(
      (item) => item.id !== id
    );
  };

  // 離開頁面時重置, 可能會使用
  @action
  reset = () => {
    this.allowances = [];
    this.dynamicAllowances = [];
    this.incomes = [];

    this.onSiteCaseLength = null;
    this.offSiteCaseLength = null;
    this.otherCaseLength = null;

    this.income = null;
  };
}

export default DataViewModel;
