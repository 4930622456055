import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import dayjs from 'dayjs';
import {
  ROUTES,
  ROUTE_PARAMS,
  CHECK_STATUS,
  PROJECT_STATUS
} from 'src/global/constants';
import { downloadCSV } from 'src/global/methods';
import { exportCheckForm } from 'src/global/export';
import CheckFormServices from 'src/services/CheckFormServices';
import ErrorServices from 'src/services/ErrorServices';
import CustomAnchorViewModel from 'src/components/CustomAnchor/viewModel';

class ChecklistPageViewModel {
  @observable data = null;
  @observable searchCheckStatus = 'all';
  @observable searchProjectStatus = 'all';
  @observable search = null;
  @observable isInit = true;
  @observable isAwait = false;
  anchor = undefined;

  router = null;

  anchorVM = null;

  @computed
  get options() {
    const defaultCheckFormStatus = {
      value: 'all',
      label: '查檢表狀態選擇'
    };
    const defaultProjectStatus = {
      value: 'all',
      label: '專案狀態選擇'
    };
    const projectStatus = [
      PROJECT_STATUS.S4,
      PROJECT_STATUS.S5,
      PROJECT_STATUS.S6,
      PROJECT_STATUS.S7,
      PROJECT_STATUS.S8,
      PROJECT_STATUS.S9
    ];

    return {
      checkFormStatus: [defaultCheckFormStatus, ...Object.values(CHECK_STATUS)],
      projectStatus: [defaultProjectStatus, ...projectStatus]
    };
  }

  @computed
  get searches() {
    const projectStep
      = this.searchProjectStatus === 'all' ? undefined : this.searchProjectStatus;
    const checkFormCompleted
      = this.searchCheckStatus === 'all' ? undefined : this.searchCheckStatus;
    const search = this.search?.trim() ? this.search : undefined;

    return {
      projectStep,
      checkFormCompleted,
      search
    };
  }

  constructor(props) {
    this.router = props.router;
    this.anchorVM = new CustomAnchorViewModel({
      api: this.getCheckFormListAPI
    });

    makeObservable(this);
  }

  didMount = () => {
    if (!this.isAwait) {
      this.anchorVM.reset();
    }
  };

  @action
  onChangeSearchCheckStatus = (value) => {
    this.searchCheckStatus = value;

    this.anchorVM.reset();
  };

  @action
  onChangeSearchProjectStatus = (value) => {
    this.searchProjectStatus = value;

    this.anchorVM.reset();
  };

  @action
  onChangeSearch = (event) => {
    this.search = event.target.value;
  };

  onSearch = () => {
    this.anchorVM.reset();
  };

  onToClientClick = ({ clientId, projectId }) => {
    const route = `/${ROUTES.client.value}/${clientId}`;
    const state = {};

    state[ROUTE_PARAMS.projectId] = projectId;

    this.router.navigate(route, { state });
  };

  onExport = (project) => {
    this.getCheckFormExport(project);
  };

  @action
  getCheckFormListAPI = async ({ anchor }) => {
    this.isAwait = true;

    const res = await CheckFormServices.getCheckFormList({
      anchor,
      projectStep: this.searches.projectStep,
      completed: this.searches.checkFormCompleted,
      keyword: this.searches.search
    });

    runInAction(() => {
      this.isInit = false;
      this.isAwait = false;
    });

    return res;
  };

  // 匯出查檢表結果
  @action
  getCheckFormExport = async (project) => {
    this.isAwait = true;

    try {
      const res = await CheckFormServices.getCheckFormExport({
        anchor: this.anchor,
        projectId: project.projectId
      });

      runInAction(() => {
        const formatData = exportCheckForm({
          data: res.data,
          status: true,
          visitorName: true,
          visitorNickName: true,
          auditStartTime: true,
          auditEndTime: true,
          implementationTotal: true,
          satisfactionTotal: true
        });
        const today = dayjs().format('YYYYMMDD');
        const fileName = `${project.projectName}_${today}`;

        downloadCSV({ data: formatData, fileName });
      });
    } catch (error) {
      const msg = ErrorServices.getCheckFormExport(error);

      console.log('ChecklistPageViewModel', 'getCheckFormExport', msg);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default ChecklistPageViewModel;
