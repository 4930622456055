import React from 'react';
import PropTypes from 'prop-types';
import { Observer, observer } from 'mobx-react';
import { Form, Select, Input, Checkbox, Button, Table } from 'antd';
import SimpleForm from 'src/components/SimpleForm';
import ICONS from 'src/global/icons';
import CustomDrawer from 'src/components/CustomDrawer';
import CustomTable from 'src/components/CustomTable';
import styles from './styles.module.scss';

@observer
class InterviewerDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
  }

  render() {
    return (
      <CustomDrawer vm={this.vm.drawerVM} title="選擇訪員">
        <div className={styles.container}>
          <div className={styles.selectsContainer}>
            <div className={styles.title}>篩選條件</div>

            {/* 篩選表單 */}
            <SimpleForm ref={this.vm.formRef} disabled={this.vm.isAwait}>
              <div className={styles.formContainer}>
                <div className={styles.flexItem}>
                  <div className={styles.formItem}>
                    <div className={styles.label}>訪員證照:</div>
                    <Form.Item name="license" className={styles.expand}>
                      <Select
                        mode="multiple"
                        optionFilterProp="label"
                        dropdownMatchSelectWidth={false}
                        options={this.vm.options.licenses}
                        placeholder="請選擇"
                      />
                    </Form.Item>
                  </div>

                  <div className={styles.formItem}>
                    <div className={styles.label}>執案區域:</div>
                    <Form.Item name="workingCounty" className={styles.expand}>
                      <Select
                        mode="multiple"
                        optionFilterProp="label"
                        dropdownMatchSelectWidth={false}
                        options={this.vm.options.cities}
                        placeholder="請選擇"
                      />
                    </Form.Item>
                  </div>

                  <div className={styles.formItem}>
                    <div className={styles.label}>錄影設備:</div>
                    <Form.Item name="haveDevice" className={styles.expand}>
                      <Select
                        className={styles.select}
                        dropdownMatchSelectWidth={false}
                        options={this.vm.options.videoEquipment}
                        placeholder="請選擇"
                      />
                    </Form.Item>
                  </div>

                  <div className={styles.formItem}>
                    <div className={styles.label}>電信用戶:</div>
                    <Form.Item name="telecom" className={styles.expand}>
                      <Select
                        mode="multiple"
                        optionFilterProp="label"
                        dropdownMatchSelectWidth={false}
                        options={this.vm.options.telecoms}
                        placeholder="請選擇"
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className={styles.space} />

                <div className={styles.flexItem}>
                  <div className={styles.formItem}>
                    <div className={styles.label}>性別:</div>
                    <Form.Item name="gender" className={styles.expand}>
                      <Select
                        className={styles.select}
                        dropdownMatchSelectWidth={false}
                        options={this.vm.options.genders}
                        placeholder="請選擇"
                      />
                    </Form.Item>
                  </div>

                  <div className={styles.formItem}>
                    <div className={styles.label}>年齡:</div>
                    <Form.Item name="age" className={styles.expand}>
                      <Select
                        className={styles.select}
                        dropdownMatchSelectWidth={false}
                        options={this.vm.options.ages}
                        placeholder="請選擇"
                      />
                    </Form.Item>
                  </div>

                  <div className={styles.formItem}>
                    <div className={styles.label}>駕照:</div>
                    <Form.Item name="drvingLicense" className={styles.expand}>
                      <Select
                        mode="multiple"
                        optionFilterProp="label"
                        dropdownMatchSelectWidth={false}
                        options={this.vm.options.drivingLicenses}
                        placeholder="請選擇"
                      />
                    </Form.Item>
                  </div>

                  <div className={styles.formItem}>
                    <div className={styles.label}>關鍵字搜尋:</div>
                    <Form.Item name="keyword" className={styles.expand}>
                      <Input placeholder="請輸入訪員姓名、代號" />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </SimpleForm>

            <Button
              className={styles.filterButton}
              onClick={this.vm.onSearch}
              disabled={this.vm.isAwait}
            >
              <ICONS.RightArrow />
              進行篩選
            </Button>
          </div>

          <div className={styles.resContainer}>
            <div className={styles.title}>篩選結果</div>
            <div className={styles.resDesc}>{this.vm.label.dataLength}</div>
          </div>

          <CustomTable
            data={this.vm.data}
            onScrollEnd={this.vm.onScrollEnd}
          >
            <Table.Column
              title="選擇"
              render={(item) => {
                return (
                  <Observer>
                    {() => {
                      const checked = item.id === this.vm.visitorId;

                      return (
                        <Checkbox
                          checked={checked}
                          onChange={(event) => {
                            this.vm.onChangeVisitorId(event, item.id);
                          }}
                        />
                      );
                    }}
                  </Observer>
                );
              }}
            />

            <Table.Column title="名字" dataIndex="name" />

            <Table.Column title="ID" dataIndex="id" />

            <Table.Column title="代號" dataIndex="nickName" />
          </CustomTable>

          <div className={styles.operateContainer}>
            <div className={styles.label}>{this.vm.label.visitorName}</div>

            <Button
              className={styles.button}
              type="primary"
              onClick={this.vm.onSave}
              disabled={this.vm.disabled.save}
            >
              指派
            </Button>
          </div>
        </div>
      </CustomDrawer>
    );
  }
}

InterviewerDrawer.propTypes = {
  vm: PropTypes.object.isRequired
};

InterviewerDrawer.defaultTypes = {};

export default InterviewerDrawer;
