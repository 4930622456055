// 案件詳情
import dayjs from 'dayjs';

class CaseDetailDataModel {
  constructor(props) {
    this.name = props.name;

    this.siteType = props.siteType;

    // 開始與結束時間
    this.startDate = props.startDate;
    this.expiryDate = props.expiryDate;

    // 類型
    this.caseType = props.caseType;

    // 地址
    this.address = props.address;

    // 區域
    this.region = props.region;

    // 電話
    this.phone = props.phone;

    // 營業時間
    this.businessHours = props.businessHours;

    // 說明
    this.directions = props.directions;

    // 訪員
    this.visitorId = String(props.visitorId);
    this.visitorName = props.visitorName;

    // pm 備註
    this.pmContent = props.pmContent;

    // 退件原因
    this.message = props.message;

    // 案件狀態
    this.step = props.step;
    this.stepName = props.stepName;

    // 訪員留言
    this.visitorReply = props.visitorReply;
    
    // 案件紀錄
    this.log = props.log.map((item) => {
      return {
        date: item.date,
        text: item.text,
        visitorName: item.visitorName,
        step: item.step
      };
    });

    // 上傳檔案位置
    this.uploadPath = props.uploadPath;
  }
}

export default CaseDetailDataModel;
