import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction
} from 'mobx';
import { RE_NUMBER } from 'src/global/constants';
import { TOPIC_TYPES } from 'src/global/topic';
import BigNumber from 'bignumber.js';

class TopicViewModel {
  @observable itemId = null;
  @observable projectId = null;
  @observable caseId = null;
  @observable type = null;
  @observable site = null;
  @observable facet = null;
  @observable inferiorFacet = null;
  @observable topic = null;
  @observable content = null;

  // 落實
  @observable implementation = null;
  @observable implementWeighted = null;

  // 滿意
  @observable satisfaction = null;
  @observable satisWeighted = null;

  // 答案
  @observable reply = null;
  @observable replyId = null;
  @observable replyImplementScore = null;
  @observable replySatisScore = null;
  @observable replyContent = null;

  // 落實度分數判斷
  @computed
  get getImplementScore() {
    switch (this.implementation) {
      // 自訂分數
      case '0': {
        return this.replyImplementScore;
      }

      case '1': {
        return this.replyImplementScore === '0' ? 0 : this.implementWeighted;
      }

      default:
        return 0;
    }
  }

  // 滿意度分數判斷
  @computed
  get getSatisScore() {
    switch (this.satisfaction) {
      // 自訂分數
      case '0': {
        return this.replySatisScore || 0;
      }

      // 1 ~ 5
      case '1': {
        return new BigNumber(this.satisWeighted)
          .dividedBy(5)
          .multipliedBy(this.replySatisScore || 0)
          .toNumber();
      }

      // 0 ~ 1
      case '2':
        return new BigNumber(this.satisWeighted)
          .multipliedBy(this.replySatisScore || 0)
          .toNumber();

      default:
        return 0;
    }
  }

  // 分數
  @computed
  get fraction() {
    // 簡答題以外
    if (this.type !== TOPIC_TYPES.shortAnswer.value) {
      return {
        implementWeighted: this.implementWeighted || 0,
        implementScore: this.getImplementScore,
        satisWeighted: this.satisWeighted || 0,
        satisScore: this.getSatisScore
      };
    }

    return null;
  }

  @computed
  get check() {
    return {
      hasImplementation: !!this.implementation,
      hasSatisfaction: !!this.satisfaction,
      isFraction: this.type === TOPIC_TYPES.fraction.value,
      isBonus: this.type === TOPIC_TYPES.bonus.value
    };
  }

  @computed
  get res() {
    const implementScore = this.check.hasImplementation
      ? this.replyImplementScore
      : null;
    const satisScore = this.check.hasSatisfaction ? this.replySatisScore : null;

    return {
      itemId: this.itemId,
      implementScore,
      satisScore,
      content: this.replyContent?.trim()
    };
  }

  constructor(props) {
    this.init(props);
    makeObservable(this);
  }

  @action
  init = (props) => {
    const reply = props.checkFormReply[0];

    this.itemId = props.id;
    this.projectId = props.projectId;
    this.caseId = reply?.caseId;
    this.type = props.type;
    this.site = props.site;
    this.facet = props.facet;
    this.inferiorFacet = props.inferiorFacet;
    this.topic = props.topic;
    this.content = props.content;

    // 落實
    this.implementation = new BigNumber(props.implementation).isNaN()
      ? null
      : props.implementation;
    this.implementWeighted = new BigNumber(props.implementWeighted).isNaN()
      ? null
      : props.implementWeighted;

    // 滿意
    this.satisfaction = new BigNumber(props.satisfaction).isNaN()
      ? null
      : props.satisfaction;
    this.satisWeighted = new BigNumber(props.satisWeighted).isNaN()
      ? null
      : props.satisWeighted;

    // 答案
    this.reply = reply;
    this.replyId = reply?.id;
    this.replyImplementScore = new BigNumber(reply?.implementScore).isNaN()
      ? null
      : String(reply.implementScore);
    this.replySatisScore = new BigNumber(reply?.satisScore).isNaN()
      ? null
      : String(reply.satisScore);
    this.replyContent = reply?.content;
  };

  // 落實度
  @action
  onChangeReplyImplementScoreInput = (event) => {
    const value = event.target.value;
    const isNumber = RE_NUMBER.test(value);
    const exceed = Number(value) > Number(this.implementWeighted);
    const isZero = !value;

    if (isNumber) {
      this.replyImplementScore = value;
    }

    if (exceed) {
      this.replyImplementScore = this.implementWeighted;
    }

    if (isZero) {
      this.replyImplementScore = '0';
    }

    console.log('replyImplementScore', this.replyImplementScore);
  };

  @action
  onChangeReplyImplementScoreRadio = (event) => {
    const value = event.target.value;

    this.replyImplementScore = value;

    console.log('replyImplementScore', this.replyImplementScore);
  };

  // 滿意度
  @action
  onChangeReplySatisScoreInput = (event) => {
    const value = event.target.value;
    const isNumber = RE_NUMBER.test(value);
    const exceed = Number(value) > Number(this.satisWeighted);
    const isZero = !value;

    if (isNumber) {
      this.replySatisScore = value;
    }

    if (exceed) {
      this.replySatisScore = this.satisWeighted;
    }

    if (isZero) {
      this.replySatisScore = '0';
    }

    console.log('replySatisScore', this.replySatisScore);
  };

  @action
  onChangeReplySatisScoreRadio = (event) => {
    const value = event.target.value;

    this.replySatisScore = value;

    console.log('replySatisScore', this.replySatisScore);
  };

  @action
  onChangeReplyContent = (event) => {
    const value = event.target.value;

    this.replyContent = value;
  };
}

export default TopicViewModel;
