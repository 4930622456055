import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import StyleItem from 'src/components/StyleItem';
import TextAreaModal from 'src/components/TextAreaModal';
import VisitorReplyMsgViewModel from './viewModel';

@observer
class VisitorReplyMsg extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new VisitorReplyMsgViewModel({
      case: props.case,
      checkParams: props.checkParams
    });
  }

  componentDidUpdate(preProps) {
    this.vm.didUpdate(this.props, preProps);
  }

  render() {
    return this.vm.isShow ? (
      <>
        <StyleItem>
          <div>訪員留言</div>

          <Button type="primary" onClick={this.vm.modalVM.show}>
            {this.vm.label.button}
          </Button>
        </StyleItem>

        <TextAreaModal
          vm={this.vm.modalVM}
          label="訪員留言"
          value={this.vm.remark}
          disabled={this.vm.disabled.textArea}
          isAwait={this.vm.isAwait}
          maxLength={2000}
        />
      </>
    ) : null;
  }
}

VisitorReplyMsg.propTypes = {
  case: PropTypes.object.isRequired,
  checkParams: PropTypes.object.isRequired
};

VisitorReplyMsg.defaultProps = {};

export default VisitorReplyMsg;
