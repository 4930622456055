import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { observer } from 'mobx-react';
import styles from './styles.module.scss';

function FormInput(props) {
  return props.isEdit ? (
    <Form.Item
      className={styles.formItem}
      name={props.name}
      rules={props.required}
    >
      <Input
        size="small"
        maxLength={props.maxLength}
        showCount={props.showCount}
      />
    </Form.Item>
  ) : (
    props.label
  );
}

FormInput.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.array,
  maxLength: PropTypes.number,
  showCount: PropTypes.bool
};

FormInput.defaultProps = {
  label: null,
  required: [],
  maxLength: 50,
  showCount: false
};

export default observer(FormInput);
