import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import FormModal from '../FormModal';
import Facet from './components/Facet';
import styles from './styles.module.scss';

@observer
class CheckFormModal extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
  }

  render() {
    return (
      <FormModal
        vm={this.vm.modalVM}
        title="查檢表"
        onSave={this.props.onSave}
        disableEdit={!this.props.edit}
        isAwait={this.props.disabled}
      >
        {this.vm.check.topics ? (
          <div className={styles.container}>
            <div className={styles.minWidth}>
              <div className={styles.totals}>
                <div className={styles.item}>{this.vm.label.topicsLength}</div>

                <div className={styles.item}>
                  {this.vm.label.implementation}
                </div>

                <div className={styles.item}>{this.vm.label.satisfaction}</div>
              </div>

              <div className={styles.times}>
                <div className={styles.item}>
                  {this.vm.label.auditStartTime}
                </div>

                <div className={styles.item}>
                  {this.vm.label.auditEndTime}
                </div>
              </div>

              <div className={styles.mainContent}>
                {this.vm.topicsFormat.map((item) => {
                  return (
                    <div className={styles.itemContainer} key={item.value}>
                      <Facet
                        data={item}
                        edit={this.props.edit}
                        disabled={this.props.disabled}
                        detail={this.props.detail}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : null}
      </FormModal>
    );
  }
}

CheckFormModal.propTypes = {
  vm: PropTypes.object.isRequired,
  edit: PropTypes.bool,
  disabled: PropTypes.bool,
  detail: PropTypes.bool,
  onSave: PropTypes.func
};

CheckFormModal.defaultProps = {
  edit: false,
  disabled: false,
  detail: false,
  onSave: () => {}
};

export default CheckFormModal;
