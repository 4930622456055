import { makeObservable, observable, action, computed } from 'mobx';
import { ROUTE_PARAMS, SITE_TYPES } from 'src/global/constants';
import { CASE_STATUS, CASE_END_DATE_TYPES } from 'src/global/case';
import CustomModalViewModel from 'src/components/CustomModal/viewModel';

class SiteTableViewModel {
  @observable type = null;

  @observable projectId = null;

  @observable deleteId = null;

  // 選擇的案件
  @observable selectKeys = [];

  deleteSelectedModalVM = new CustomModalViewModel();
  deleteItemModalVM = new CustomModalViewModel();

  // 刪除案件
  onOneDeleted = null;
  onManyDeleted = null;

  // react router
  router = null;

  @computed
  get check() {
    return {
      onSite: this.type === SITE_TYPES.onSite.value,
      offSite: this.type === SITE_TYPES.offSite.value
    };
  }

  @computed
  get disable() {
    return {
      delete: !this.selectKeys.length
    };
  }

  @computed
  get rowSelection() {
    return {
      selectedRowKeys: this.selectedRowKeys,
      onChange: this.onChangeSelectKeys
    };
  }

  @computed
  get filterStatus() {
    return Object.values(CASE_STATUS).map((item) => {
      return { value: item.value, text: item.label };
    });
  }

  @computed
  get filterEndDate() {
    return Object.values(CASE_END_DATE_TYPES).map((item) => {
      return { value: item.value, text: item.label };
    });
  }

  constructor(props) {
    this.type = props.type;

    this.onOneDeleted = props.onOneDeleted;

    this.onManyDeleted = props.onManyDeleted;

    this.router = props.router;

    this.projectId = props.router.params[ROUTE_PARAMS.projectId];

    makeObservable(this);
  }

  getCaseDetailLink = (id) => {
    const pathname = this.router.location.pathname;
    const link = `${pathname}/case/${id}`;

    return link;
  }

  @action
  setDeleteId = (id) => {
    this.deleteId = id;
  };

  @action
  onChangeSelectKeys = (value) => {
    this.selectKeys = value;
  };

  // 刪除多個案件
  @action
  onDeleteSelectedConfirm = () => {
    this.onManyDeleted(this.selectKeys);
    this.deleteSelectedModalVM.hide();
    this.selectKeys = [];
  };

  // 刪除 1 個案件
  @action
  onDeleteItemConfirm = () => {
    this.onOneDeleted(this.deleteId);

    this.selectKeys = this.selectKeys.filter((key) => {
      return key !== this.deleteId;
    });

    this.deleteId = null;

    this.deleteItemModalVM.hide();
  };

  // 篩選狀態
  onFilterStatus = (value, vm) => {
    return vm.step === value;
  };

  // 篩選截止日期
  onFilterEndDate = (value, vm) => {
    return vm.check.type === value;
  };

  toCaseDetail = (id) => {
    const link = `/${this.route.value}/${this.projectId}/case/${id}`;

    this.router.navigate(link);
  };
}

export default SiteTableViewModel;
