import { request, getHost } from './utils';

// 通知
class MessageAPIs {
  // 讀取通知列表
  static getMessageList({ token, anchor }) {
    const options = {
      method: 'get',

      url: `${getHost()}/v1/message/list`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      params: {
        anchor
      }
    };

    return request(options);
  }

  // 通知已讀
  static putMessageRead({ token, messageId }) {
    const options = {
      method: 'put',

      url: `${getHost()}/v1/message/readed/${messageId}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      }
    };

    return request(options);
  }
}

export default MessageAPIs;
