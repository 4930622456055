import { makeObservable, observable, action, computed } from 'mobx';
import { diff } from 'deep-diff';
import { ROUTES, ROUTE_PARAMS, PROJECT_STATUS } from 'src/global/constants';

class CheckItemViewModel {
  @observable data = null;
  @observable checkParams = null;
  router = null;

  @computed
  get isShow() {
    switch (this.data.step) {
      case PROJECT_STATUS.S4.value:
      case PROJECT_STATUS.S5.value:
      case PROJECT_STATUS.S6.value:
      case PROJECT_STATUS.S7.value:
      case PROJECT_STATUS.S8.value:
      case PROJECT_STATUS.S9.value: {
        return this.checkParams.isHandlerOrBoss;
      }

      default:
        return false;
    }
  }

  @computed
  get isFinish() {
    // 專案是否已設定查檢表
    return this.data.checkFormCompleted;
  }

  @computed
  get itemLabel() {
    return this.isFinish ? '查檢表' : '查檢表（未完成亦可送出）';
  }

  constructor(props) {
    this.router = props.router;

    this.init(props);

    makeObservable(this);
  }

  @action
  init = (props) => {
    this.data = props.data;
    this.checkParams = props.checkParams;
  }

  didUpdate = (props, preProps) => {
    const diffData = diff(props.data, preProps.data);
    const diffCheckParams = diff(props.checkParams, preProps.checkParams);

    if (diffData || diffCheckParams) {
      this.init(props);
    }
  };

  onToCheck = () => {
    const projectId = this.router.params[ROUTE_PARAMS.projectId];
    const route = `/${ROUTES.checklist.value}/${projectId}`;

    this.router.navigate(route);
  };
}

export default CheckItemViewModel;
