import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Form, Select } from 'antd';
import styles from './styles.module.scss';

function FormSelect(props) {
  const find = props.options.find((item) => item.value === props.label);
  const label = props.label === null ? props.label : find.label;

  return props.isEdit ? (
    <Form.Item
      className={styles.formItem}
      name={props.name}
      rules={props.required}
    >
      <Select
        size="small"
        className={styles.select}
        options={props.options}
        dropdownMatchSelectWidth={false}
      />
    </Form.Item>
  ) : (
    <div className={styles.label}>{label}</div>
  );
}

FormSelect.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  required: PropTypes.array
};

FormSelect.defaultProps = {
  label: null,
  required: []
};

export default observer(FormSelect);
