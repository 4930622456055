import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import ICONS from 'src/global/icons';
import { Input, Button, Divider } from 'antd';
import Fraction from '../Fraction';
import styles from './styles.module.scss';

function FractionSection(props) {
  return (
    <div className={styles.container}>
      <div className={styles.mainTotal}>
        <div className={styles.paddingBottom}>
          <div className={styles.totalContainer}>
            <Fraction
              label="平均總分"
              fraction={props.dataVM.totalFraction}
            />
          </div>
        </div>

        <div className={styles.paddingBottom}>
          <div className={styles.item}>
            <div className={styles.label}>
              <span className={styles.star}>*</span>

              <span>落實度權重:</span>
            </div>

            <div className={styles.inputContainer}>
              <Input
                size="small"
                className={styles.input}
                value={props.dataVM.weights.implement}
                onChange={props.dataVM.onChangeWeightsImplement}
              />

              <span className={styles.ratio}>%</span>
            </div>
          </div>
        </div>

        <div className={styles.paddingBottom}>
          <div className={styles.item}>
            <div className={styles.label}>
              <span className={styles.star}>*</span>

              <span>滿意度權重:</span>
            </div>

            <div className={styles.inputContainer}>
              <Input
                size="small"
                className={styles.input}
                value={props.dataVM.weights.satisfaction}
                onChange={props.dataVM.onChangeWeightsSatisfaction}
              />

              <span className={styles.ratio}>%</span>
            </div>
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <Button
            className={styles.button}
            size="small"
            onClick={props.onClickWeights}
            disabled={props.disabledWeights}
          >
            <ICONS.RightArrow className={styles.icon} />
            <span className={styles.content}>套用權重至圖表</span>
          </Button>
        </div>
      </div>

      <div className={styles.typesTotal}>
        <div className={styles.paddingBottom}>
          <Fraction
            label="平均落實度"
            fraction={props.implement}
          />
        </div>

        <Fraction
          label="平均滿意度"
          fraction={props.satisfaction}
        />
      </div>

      <div className={styles.facetsTotal}>
        <div className={styles.title}>構面平均落實度</div>

        <div className={styles.items}>
          <div className={styles.label}>現場</div>

          {props.dataVM.inferiorFacets.onSite.map((item) => {
            return (
              <div className={styles.paddingRight} key={item.id}>
                <Fraction
                  size="small"
                  label={item.label}
                  fraction={item.fraction}
                />
              </div>
            );
          })}
        </div>

        <Divider className={styles.divider} />

        <div className={styles.items}>
          <div className={styles.label}>非現場</div>

          {props.dataVM.inferiorFacets.offSite.map((item) => {
            return (
              <div className={styles.paddingRight} key={item.id}>
                <Fraction
                  size="small"
                  label={item.label}
                  fraction={item.fraction}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

FractionSection.propTypes = {
  dataVM: PropTypes.object.isRequired,
  implement: PropTypes.string,
  satisfaction: PropTypes.string,
  onClickWeights: PropTypes.func.isRequired,
  disabledWeights: PropTypes.bool.isRequired
};

FractionSection.defaultProps = {
  implement: '',
  satisfaction: ''
};

export default observer(FractionSection);
