import React from 'react';
import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction
} from 'mobx';
import { ROUTES } from 'src/global/constants';
import CustomModalViewModel from 'src/components/CustomModal/viewModel';
import ProjectServices from 'src/services/ProjectServices';
import CompanyServices from 'src/services/CompanyServices';
import ErrorServices from 'src/services/ErrorServices';

class CreateModalViewModel {
  @observable clients = [];
  @observable isAwait = false;

  formRef = React.createRef();

  modalVM = new CustomModalViewModel();

  onCreated = null;
  router = null;

  @computed
  get required() {
    return [
      {
        required: true,
        message: '請輸入'
      }
    ];
  }

  constructor() {
    makeObservable(this);
  }

  didMount = () => {
    if (!this.isAwait) {
      this.postCompanyListLiteAPI();
    }
  };

  // 接收 jsx 的 props
  init = ({ onCreated, router }) => {
    this.onCreated = onCreated;
    this.router = router;
  };

  @action
  open = () => {
    this.modalVM.show();
  };

  onFilterOption = (val, item) =>
    item.label.toLowerCase().includes(val.toLowerCase());

  onConfirm = () => {
    this.putProjectCreateAPI();
  };

  @action
  postCompanyListLiteAPI = async () => {
    this.isAwait = true;

    try {
      const res = await CompanyServices.postCompanyListLite({});

      runInAction(() => {
        this.clients = res.data.map((item) => {
          return {
            value: item.accountId,
            label: item.name
          };
        });
      });
    } catch (error) {
      const message = ErrorServices.getCompanyList(error);

      console.log('CreateModalViewModel', 'getCompanyList', message);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };

  // 新增專案
  @action
  putProjectCreateAPI = async () => {
    this.isAwait = true;

    try {
      const data = await this.formRef.current.validateFields();
      const res = await ProjectServices.putProjectCreate({
        projectName: data.projectName,
        companyId: String(data.companyId)
      });

      runInAction(() => {
        const projectId = res.data.projectId;
        const route = `/${ROUTES.proposal.value}/${projectId}`;

        this.router.navigate(route);

        this.modalVM.hide();

        console.log(res);
      });
    } catch (error) {
      const message = ErrorServices.putProjectCreate(error);

      console.log('CreateModalViewModel', 'putProjectCreate', message);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default CreateModalViewModel;
