import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import StyleItem from 'src/components/StyleItem';
import CustomModal from 'src/components/CustomModal';
import UploadPathItemViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class UploadPathItem extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new UploadPathItemViewModel({
      case: props.case,
      checkParams: props.checkParams
    });
  }

  componentDidUpdate(preProps) {
    this.vm.didUpdate(this.props, preProps);
  }

  renderModalContent = () => {
    if (this.vm.check.uploadPath) {
      return (
        <a href={this.vm.modalContent.link}>{this.vm.modalContent.label}</a>
      );
    }

    return this.vm.modalContent.label;
  };

  render() {
    return this.vm.isShow ? (
      <>
        <StyleItem>
          <div>附件</div>

          <Button type="primary" onClick={this.vm.modalVM.show}>
            查看
          </Button>
        </StyleItem>

        <CustomModal vm={this.vm.modalVM}>
          <div className={styles.modalContent}>
            <div className={styles.title}>附件</div>

            <div className={styles.content}>
              上傳檔案位置:
              {this.renderModalContent()}
            </div>

            <div className={styles.buttonContainer}>
              <Button
                type="primary"
                size="small"
                onClick={this.vm.modalVM.hide}
              >
                關閉
              </Button>
            </div>
          </div>
        </CustomModal>
      </>
    ) : null;
  }
}

UploadPathItem.propTypes = {
  case: PropTypes.object.isRequired,
  checkParams: PropTypes.object.isRequired
};

UploadPathItem.defaultProps = {};

export default UploadPathItem;
