// 範例
export const onSiteExample = [
  {
    門市名稱: '長春門市',
    門市地址: '長春路1號',
    所屬縣市: '臺北市',
    所屬地區: '中山區',
    電話: '02-11111111',
    營業時間: '00:00-24:00',
    執案開始日: '2023-02-01',
    案件截止日: '2023-02-01',
    上傳檔案位置: 'https://www.google.com.tw'
  }
];

export const offSiteExample = [
  {
    案件名稱: 'FB粉專稽查',
    案件類型: '網站',
    案件說明: '網址：http://aaaaaaaaaaaaa...',
    執案開始日: '2023-02-01',
    案件截止日: '2023-02-01',
    上傳檔案位置: 'https://www.google.com.tw'
  }
];
