import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction
} from 'mobx';
import { diff } from 'deep-diff';
import { ROUTES, PROJECT_STATUS } from 'src/global/constants';
import ProjectServices from 'src/services/ProjectServices';
import ErrorServices from 'src/services/ErrorServices';
import { message } from 'antd';
import CustomModalViewModel from 'src/components/CustomModal/viewModel';
import styles from './styles.module.scss';

class PushButtonViewModel {
  @observable data = null;
  @observable checkParams = null;
  @observable isAwait = false;

  projectId = null;

  router = null;

  onChanged = null;

  modalVM = new CustomModalViewModel();

  @computed
  get buttonStyle() {
    switch (this.data.step) {
      case PROJECT_STATUS.S8.value:
        return styles.step8;

      default:
        return styles.defaultButton;
    }
  }

  @computed
  get isShow() {
    switch (this.data.step) {
      case PROJECT_STATUS.S1.value:
      case PROJECT_STATUS.S2.value:
      case PROJECT_STATUS.S4.value:
      case PROJECT_STATUS.S5.value:
      case PROJECT_STATUS.S6.value:
      case PROJECT_STATUS.S7.value:
        return this.checkParams.isHandler;
      case PROJECT_STATUS.S8.value:
        return this.checkParams.isBoss;

      default:
        return false;
    }
  }

  @computed
  get disabled() {
    // 步驟 2 時檢查, 需完成損益表
    const checkStep2
      = this.data.step === PROJECT_STATUS.S2.value
      && this.data.profitFormCompleted;

    return this.isAwait || checkStep2;
  }

  @computed
  get label() {
    switch (this.data.step) {
      case PROJECT_STATUS.S5.value:
        return '申請結案';

      case PROJECT_STATUS.S8.value:
        return '核准';

      default:
        return '送出';
    }
  }

  constructor(props) {
    this.router = props.router;
    this.onChanged = props.onChanged;

    this.init(props);

    makeObservable(this);
  }

  @action
  init = (props) => {
    this.data = props.data;
    this.checkParams = props.checkParams;

    this.projectId = props.data.projectId;
  };

  didUpdate = (props, preProps) => {
    const diffData = diff(props.data, preProps.data);
    const diffCheckParams = diff(props.checkParams, preProps.checkParams);

    if (diffData || diffCheckParams) {
      this.init(props);
    }
  };

  onConfirm = () => {
    this.postPushProjectStatusAPI();
  };

  getNextStep = () => {
    switch (this.data.step) {
      case PROJECT_STATUS.S1.value:
        return PROJECT_STATUS.S2.value;

      case PROJECT_STATUS.S2.value:
        return PROJECT_STATUS.S3.value;

      case PROJECT_STATUS.S4.value:
        return PROJECT_STATUS.S5.value;

      case PROJECT_STATUS.S5.value:
        return PROJECT_STATUS.S6.value;

      case PROJECT_STATUS.S6.value:
        return PROJECT_STATUS.S7.value;

      case PROJECT_STATUS.S7.value:
        return PROJECT_STATUS.S8.value;

      case PROJECT_STATUS.S8.value:
        return PROJECT_STATUS.S9.value;

      default:
        return null;
    }
  };

  // 可以推進專案的人, 都可以看到下一個 route 分組, 目前是這樣...
  // 需要根據專案推進, 選擇是重新整理(頁面或是專案資料), 或是跳頁
  @action
  postPushProjectStatusAPI = async () => {
    this.isAwait = true;

    try {
      const nextStep = this.getNextStep();
      const res = await ProjectServices.postProjectAction({
        projectId: this.projectId,
        nextStep
      });

      // 每前往新的步驟, 老闆要前往專案查詢
      if (this.checkParams.isBoss) {
        const route = `/${ROUTES.projectSearch.value}`;
        this.router.navigate(route, { replace: true });
      } else {
        switch (nextStep) {
          case PROJECT_STATUS.S6.value: {
            // 前往結案管理
            const route = `/${ROUTES.close.value}/${this.projectId}`;
            this.router.navigate(route);
            break;
          }

          case PROJECT_STATUS.S9.value: {
            // 前往專案管理
            const route = `/${ROUTES.projectSearch.value}/${this.projectId}`;
            this.router.navigate(route);
            break;
          }

          default:
            this.onChanged();
            break;
        }
      }

      this.modalVM.hide();
    } catch (error) {
      const msg = ErrorServices.postProjectAction(error);

      if (error.response?.status === 400) {
        message.error(msg, 5);
      }

      console.log('PushButtonViewModel', 'postProjectAction', msg);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default PushButtonViewModel;
