import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styles from './styles.module.scss';

function HintLabel(props) {
  return (
    <div className={styles.container}>
      <div className={styles.label}>{props.label}</div>

      {props.showIcon && (
        <div className={styles.iconContainer}>
          <div className={styles.icon} />
        </div>
      )}
    </div>
  );
}

HintLabel.propTypes = {
  label: PropTypes.string,
  showIcon: PropTypes.bool
};

HintLabel.defaultProps = {
  label: '標題',
  showIcon: false
};

export default observer(HintLabel);
