// 損益表

class ProfitFormDataModel {
  constructor(props) {
    this.projectId = props.projectId;

    this.industry = props.industry;

    this.place = props.place;

    // 專業件酬試算
    this.pieceFee = props.pieceFee ? JSON.parse(props.pieceFee) : null;

    // 預估損益
    this.estimatedProfit = props.estimatedProfit
      ? JSON.parse(props.estimatedProfit)
      : null;

    // 結算損益
    this.resultProfit = props.resultProfit
      ? JSON.parse(props.resultProfit)
      : null;

    // 是否完成
    this.complete = props.complete;

    // 編輯狀態
    this.editStatus = props.editStatus;

    // 專案收入
    this.income = props.income;
  }
}

export default ProfitFormDataModel;
