import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction
} from 'mobx';
import { diff } from 'deep-diff';
import { ROUTES, PROJECT_STATUS } from 'src/global/constants';
import ProjectServices from 'src/services/ProjectServices';
import ErrorServices from 'src/services/ErrorServices';
import CustomModalViewModel from 'src/components/CustomModal/viewModel';
import styles from './styles.module.scss';

class RejectFormViewModel {
  @observable data = null;
  @observable checkParams = null;
  @observable isAwait = null;

  nextStep = null;
  @observable warningContent = '';

  router = null;

  projectId = null;

  onChanged = null;

  modalVM = new CustomModalViewModel();

  @computed
  get buttonsStyle() {
    switch (this.data.step) {
      case PROJECT_STATUS.S3.value:
        return styles.step3;

      case PROJECT_STATUS.S8.value:
        return styles.step8;

      default:
        return undefined;
    }
  }

  @computed
  get isShow() {
    switch (this.data.step) {
      case PROJECT_STATUS.S3.value:
      case PROJECT_STATUS.S8.value:
        return this.checkParams.isBoss;

      default:
        return false;
    }
  }

  @computed
  get buttons() {
    switch (this.data.step) {
      case PROJECT_STATUS.S3.value: {
        const S2 = {
          value: PROJECT_STATUS.S2.value,
          label: `退回「${PROJECT_STATUS.S2.label}」`,
          message: `確認要退回「${PROJECT_STATUS.S2.label}」嗎？`
        };

        const S1 = {
          value: PROJECT_STATUS.S1.value,
          label: `退回「${PROJECT_STATUS.S1.label}」`,
          message: `確認要退回「${PROJECT_STATUS.S1.label}」嗎？`
        };

        const S10 = {
          value: PROJECT_STATUS.S10.value,
          label: '不核准',
          message: '「不核准」後此專案即會關閉，無法執行，確定要繼續嗎？'
        };

        return [S2, S1, S10];
      }

      case PROJECT_STATUS.S8.value: {
        const S7 = {
          value: PROJECT_STATUS.S7.value,
          label: '退回「專案結算損益」',
          message: `確認要退回「${PROJECT_STATUS.S7.label}」嗎？`
        };

        const S6 = {
          value: PROJECT_STATUS.S6.value,
          label: `退回「${PROJECT_STATUS.S6.label}」`,
          message: `確認要退回「${PROJECT_STATUS.S6.label}」嗎？`
        };

        return [S7, S6];
      }

      default:
        return [];
    }
  }

  @computed
  get disabled() {
    return {
      warningModalConfirm: this.isAwait,
      stepButton: this.isAwait
    };
  }

  constructor(props) {
    this.router = props.router;
    this.onChanged = props.onChanged;

    this.init(props);

    makeObservable(this);
  }

  @action
  init = (props) => {
    this.data = props.data;
    this.projectId = props.data.projectId;

    this.checkParams = props.checkParams;
  };

  didUpdate = (props, preProps) => {
    const diffData = diff(props.data, preProps.data);
    const diffCheckParams = diff(props.checkParams, preProps.checkParams);

    if (diffData || diffCheckParams) {
      this.init(props);
    }
  };

  @action
  onStepClick = (item) => {
    this.nextStep = item.value;
    this.warningContent = item.message;

    this.modalVM.show();
  };

  onWarningModalConfirm = () => {
    this.postPushProjectStatusAPI();
  };

  // 推進或退回專案
  @action
  postPushProjectStatusAPI = async () => {
    this.isAwait = true;

    try {
      const res = await ProjectServices.postProjectAction({
        projectId: this.projectId,
        nextStep: this.nextStep
      });

      // 每前往新的步驟, 老闆要前往專案查詢
      if (this.checkParams.isBoss) {
        const route = `/${ROUTES.projectSearch.value}`;
        this.router.navigate(route, { replace: true });
      } else {
        this.onChanged();
      }
    } catch (error) {
      const msg = ErrorServices.postProjectAction(error);

      console.log('PushButtonViewModel', 'postProjectAction', msg);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default RejectFormViewModel;
