import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import CustomUpload from 'src/components/CustomUpload';
import CustomModal from 'src/components/CustomModal';
import ImportErrorModal from 'src/components/ImportErrorModal';

@observer
class ImportModal extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({ onCreated: props.onCreated, project: props.project });
  }

  render() {
    return (
      <>
        <CustomModal
          vm={this.vm.modalVM}
          hasClose
          hasHeader
          title="批次新增"
          hasPadding
          hasFooter
          hasCancel
          // 確認
          disabledConfirm={this.vm.disabled.confirm}
          onConfirm={this.vm.onConfirm}
          confirmType="primary"
          // 取消
          disabledCancel={this.vm.isAwait}
          onCancel={this.vm.close}
        >
          <CustomUpload
            fileList={this.vm.files}
            // 覆蓋預設上傳行為
            customRequest={this.vm.onCustomRequest}
            // 刪除上傳選項
            onRemove={this.vm.onRemove}
            disabled={this.vm.isAwait}
            onDownloadExample={this.vm.onDownload}
          />
        </CustomModal>

        <ImportErrorModal
          vm={this.vm.importErrorModalVM}
          data={this.vm.filterData.invalid}
          onConfirm={this.vm.onConfirmImportErrorModal}
        />
      </>
    );
  }
}

ImportModal.propTypes = {
  vm: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  onCreated: PropTypes.func.isRequired
};

ImportModal.defaultProps = {};

export default ImportModal;
