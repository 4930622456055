import { makeObservable, observable, action, computed } from 'mobx';
import dayjs from 'dayjs';
import { v4 } from 'uuid';
import { SITE_TYPES } from 'src/global/constants';
import { CASE_STATUS, CASE_END_DATE_TYPES, CASE_TYPES } from 'src/global/case';

class CaseViewModel {
  @observable id = null;
  // 案件名稱 + 門市名稱
  @observable name = null;
  @observable siteType = null;
  // 案件類型
  @observable caseType = null;
  // 案件狀態
  @observable step = null;
  @observable stepName = null;
  // 案件說明
  @observable directions = null;
  // 門市地址
  @observable address = null;
  // 所屬地區
  @observable region = null;
  @observable phone = null;
  // 營業時間
  @observable businessHours = null;
  // 案件開始時間
  @observable startDate = null;
  // 案件截止時間
  @observable expiryDate = null;
  // 上傳檔案位置
  @observable uploadPath = null;
  // 訪員資料
  @observable visitorId = null;
  @observable visitorName = null;

  @computed
  get label() {
    const site = SITE_TYPES[this.siteType]?.label || null;
    const type = CASE_TYPES[this.caseType]?.label || null;
    // string or null
    const region = this.region ? JSON.parse(this.region).join('') : null;
    const startDate = dayjs(this.startDate).format('YYYY-MM-DD');
    const expiryDate = dayjs(this.expiryDate).format('YYYY-MM-DD');

    return {
      site,
      type,
      region,
      startDate,
      expiryDate,
      visitorName: this.visitorName || '尚未指定'
    };
  }

  @computed
  get check() {
    const expiryDateIns = dayjs(this.expiryDate).startOf('day');
    const todayIns = dayjs().startOf('day');
    const days = expiryDateIns.diff(todayIns, 'day', true);
    const isUpcoming = days >= 0 && days <= 5;
    const isExpired = days < 0;
    let type = null;

    if (isUpcoming) {
      type = CASE_END_DATE_TYPES.upcoming.value;
    }

    if (isExpired) {
      type = CASE_END_DATE_TYPES.expired.value;
    }

    return {
      isUpcoming,
      isExpired,
      type,
      isNobody:
        this.step === CASE_STATUS.S1.value
        || this.step === CASE_STATUS.S2.value,
      hasVisitorName: !!this.visitorName
    };
  }

  constructor(props) {
    // 固定的資料
    this.id = props.id;
    this.siteType = props.siteType;

    // 會改動的資料
    this.update(props);

    makeObservable(this);
  }

  @action
  update = (data) => {
    // 案件名稱 + 門市名稱
    this.name = data.name;

    // 案件類型
    this.caseType = data.caseType || null;

    // 案件狀態
    if (data.step && data.stepName) {
      this.step = data.step;
      this.stepName = data.stepName;
    }

    // 案件說明
    this.directions = data.directions || null;

    // 門市地址
    this.address = data.address || null;

    // 所屬地區
    this.region = data.region || null;

    this.phone = data.phone || null;

    // 營業時間
    this.businessHours = data.businessHours || null;

    // 案件開始時間
    this.startDate = data.startDate;

    // 案件截止時間
    this.expiryDate = data.expiryDate;

    // 檔案上傳位置
    this.uploadPath = data.uploadPath;

    // 訪員資料
    if (data.visitorId && data.visitorName) {
      this.visitorId = data.visitorId;
      this.visitorName = data.visitorName;
    }
  };

  @action
  onInterviewerChanged = ({ step, visitorId, visitorName }) => {
    this.step = step.value;
    this.stepName = step.label;
    this.visitorId = visitorId;
    this.visitorName = visitorName;
  };
}

export default CaseViewModel;
