import dayjs from 'dayjs';

// 通知

class MessageDataModel {
  constructor(props) {
    this.id = String(props.id);

    this.projectId = props.projectId;

    // 通知類型
    this.behavior = props.behavior;

    this.title = props.title;

    this.content = props.content;

    // 時間
    this.createTime = props.createTime;
    this.createTimeFormat = dayjs(props.createTime).format('YYYY.MM.DD');

    // 已讀
    this.isRead = props.isReas;
  }
}

export default MessageDataModel;
