import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Dropdown } from 'antd';

function CustomDropdown(props) {
  return (
    <Dropdown
      menu={{
        items: props.items.map((item) => {
          return {
            key: item.key,
            disabled: !!item.disabled || false,
            label: item.label
          };
        }),
        onClick: props.onClick
      }}
      placement={props.position}
    >
      {props.children}
    </Dropdown>
  );
}

CustomDropdown.propTypes = {
  items: PropTypes.array,
  onClick: PropTypes.func,
  position: PropTypes.string,
  children: PropTypes.node
};

CustomDropdown.defaultProps = {
  items: [],
  onClick: () => {},
  position: 'bottom',
  children: null
};

export default observer(CustomDropdown);
