class LoginDataModel {
  constructor(data) {
    this.id = data.id;

    this.type = data.type;

    this.name = data.name;

    this.account = data.account;

    this.token = data.token;
  }
}

export default LoginDataModel;
