import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Modal, Button } from 'antd';
import clsx from 'clsx';
import styles from './styles.module.scss';
import './reset.scss';

@observer
class CustomModal extends React.Component {
  renderHeader = () => {
    return (
      <div className={styles.header}>
        <div className={styles.title}>{this.props.title}</div>
      </div>
    );
  };

  renderFooter = () => {
    return (
      <div className={clsx(['customModalFooter', styles.footer])}>
        <div className={styles.buttonRightMargin}>
          {this.props.hasCancel && (
            <Button
              type={this.props.cancelType}
              onClick={this.props.onCancel}
              disabled={this.props.disabledCancel}
            >
              {this.props.cancelText}
            </Button>
          )}
        </div>

        <Button
          type={this.props.confirmType}
          onClick={this.props.onConfirm}
          disabled={this.props.disabledConfirm}
        >
          {this.props.confirmText}
        </Button>
      </div>
    );
  };

  render() {
    return (
      <div id="customModal">
        <Modal
          className={this.props.className}
          open={this.props.vm.isShow}
          width={this.props.width}
          onCancel={this.props.vm.onCancel}
          // 垂直居中
          centered
          footer={null}
          destroyOnClose={this.props.destroyOnClose}
          getContainer={false}
          closable={this.props.hasClose}
        >
          {this.props.hasHeader && this.renderHeader()}

          {this.props.hasHeader && (
            <div className={styles.divider}>
              <div className={styles.line} />
            </div>
          )}

          {/* 預備 scroll */}
          <div
            className={clsx([this.props.hasPadding && styles.contentPadding])}
          >
            {this.props.children}
          </div>

          {this.props.hasFooter && this.renderFooter()}
        </Modal>
      </div>
    );
  }
}

CustomModal.propTypes = {
  className: PropTypes.string,
  vm: PropTypes.object.isRequired,
  width: PropTypes.number,
  hasClose: PropTypes.bool,
  hasHeader: PropTypes.bool,
  title: PropTypes.string,
  hasPadding: PropTypes.bool,
  hasFooter: PropTypes.bool,
  // 確認按鈕
  onConfirm: PropTypes.func,
  disabledConfirm: PropTypes.bool,
  confirmText: PropTypes.string,
  confirmType: PropTypes.string,
  // 取消按鈕
  hasCancel: PropTypes.bool,
  onCancel: PropTypes.func,
  disabledCancel: PropTypes.bool,
  cancelText: PropTypes.string,
  cancelType: PropTypes.string,
  // model 內容
  children: PropTypes.node,
  destroyOnClose: PropTypes.bool
};

CustomModal.defaultProps = {
  className: undefined,
  width: 500,
  hasClose: false,
  hasHeader: false,
  title: '',
  hasPadding: false,
  hasFooter: false,
  onConfirm: () => {},
  disabledConfirm: false,
  confirmText: '確認',
  confirmType: 'default',
  hasCancel: false,
  onCancel: () => {},
  disabledCancel: false,
  cancelText: '取消',
  cancelType: 'default',
  children: null,
  destroyOnClose: true
};

export default CustomModal;
