import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';
import ICONS from 'src/global/icons';
import styles from './styles.module.scss';
import './reset.scss';

const types = {
  warning: {
    className: styles.warning,
    text: '快截止'
  },

  error: {
    className: styles.error,
    text: '已過期'
  }
};

function CaseEndDateTag(props) {
  const data = types[props.type];

  return (
    <div id="caseEndDateTag">
      <Tag className={data.className} icon={<ICONS.Warning />}>
        {data.text}
      </Tag>
    </div>
  );
}

CaseEndDateTag.propTypes = {
  type: PropTypes.string
};

CaseEndDateTag.defaultProps = {
  type: 'warning'
};

export default CaseEndDateTag;
