import React from 'react';
import PropTypes from 'prop-types';
import { observer, Observer } from 'mobx-react';
import { Select, Input, Table, Button } from 'antd';
import { ROUTES } from 'src/global/constants';
import withProfile from 'src/components/withProfile';
import withRouter from 'src/components/withRouter';
import CustomHeader from 'src/components/CustomHeader';
import FixedTable from 'src/components/FixedTable';
import CustomAnchor from 'src/components/CustomAnchor';
import QuestionnairePageViewModel from './viewModel';
import styles from './styles.module.scss';

// 訪客問卷管理
@observer
class QuestionnairePage extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new QuestionnairePageViewModel({ router: props.router });
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return (
      <div className={styles.container}>
        <CustomHeader title={ROUTES.questionnaire.label} paddingBottom />

        {!this.vm.isInit && (
          <>
            <div className={styles.searchContainer}>
              <div className={styles.selectContainer}>
                <Select
                  options={this.vm.options.projectSteps}
                  value={this.vm.searchProjectStep}
                  onChange={this.vm.onChangeSearchProjectStep}
                  disabled={this.vm.isAwait}
                  bordered={false}
                  dropdownMatchSelectWidth={false}
                />
              </div>

              <div className={styles.inputContainer}>
                <Input.Search
                  className={styles.searchInput}
                  value={this.vm.search}
                  onChange={this.vm.onChangeSearch}
                  disabled={this.vm.isAwait}
                  onSearch={this.vm.onSearch}
                  placeholder="請輸入專案名稱"
                  maxLength={50}
                />
              </div>
            </div>

            <FixedTable
              dataSource={this.vm.tableData}
              scrollY
              labelHeight={55}
              flex
              hidden
              padding
            >
              <Table.Column
                width={250}
                title="專案名稱"
                dataIndex="projectName"
              />

              <Table.Column
                width={200}
                title="專案狀態"
                render={(item) => {
                  return item.label.step;
                }}
              />

              <Table.Column
                width={200}
                title="企業名稱"
                render={(vm) => {
                  return (
                    <Button
                      type="link"
                      size="small"
                      onClick={() =>
                        this.vm.onToClientClick({
                          projectId: vm.id,
                          clientId: vm.companyId
                        })}
                    >
                      {vm.companyName}
                    </Button>
                  );
                }}
              />

              <Table.Column
                width={400}
                title="問卷連結"
                render={(vm) => {
                  return (
                    <Observer>
                      {() => {
                        const checkEdit = this.vm.checkEdit(vm.id);

                        return checkEdit ? (
                          <Input
                            value={vm.questPath}
                            onChange={vm.onChangeQuestPath}
                            disabled={this.vm.isAwait}
                            placeholder="請輸入問卷連結"
                            maxLength={200}
                          />
                        ) : (
                          <a
                            href={vm.questPath}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {vm.questPath}
                          </a>
                        );
                      }}
                    </Observer>
                  );
                }}
              />

              <Table.Column
                width={220}
                title="問卷最後更新時間"
                render={(vm) => vm.label.questUpdateTime}
              />

              <Table.Column
                width={100}
                align="center"
                title="操作"
                render={(vm) => {
                  return (
                    <Observer>
                      {() => {
                        const checkEdit = this.vm.checkEdit(vm.id);

                        return checkEdit ? (
                          <Button
                            type="link"
                            onClick={() => {
                              this.vm.onSave(vm);
                            }}
                            disabled={this.vm.isAwait}
                          >
                            儲存
                          </Button>
                        ) : (
                          <Button
                            type="link"
                            onClick={() => {
                              this.vm.setEditId(vm.id);
                            }}
                            disabled={this.vm.check.hasEditId}
                          >
                            編輯
                          </Button>
                        );
                      }}
                    </Observer>
                  );
                }}
              />
            </FixedTable>

            <CustomAnchor vm={this.vm.anchorVM} />
          </>
        )}
      </div>
    );
  }
}

QuestionnairePage.propTypes = {
  router: PropTypes.object.isRequired
};

QuestionnairePage.defaultProps = {};

export default withProfile(withRouter(QuestionnairePage));
