import {
  makeObservable,
  observable,
  computed,
  action
} from 'mobx';
import { diff } from 'deep-diff';
import { CASE_STATUS } from 'src/global/case';

class RejectItemViewModel {
  @observable case = null;
  @observable checkParams = null;

  @computed
  get isShow() {
    switch (this.case.step) {
      // case CASE_STATUS.S8.value:
      // case CASE_STATUS.S9.value:
      // case CASE_STATUS.S11.value: {
      //   const hasMessage = !!this.case.message;

      //   return hasMessage;
      // }

      default:
        return !!this.case.message;
    }
  }

  @computed
  get label() {
    const title1 = '失敗原因';
    const title2 = '退回原因';

    return {
      title: this.case.step === CASE_STATUS.S11.value ? title1 : title2,
      content: this.case.message || null
    };
  }

  constructor(props) {
    this.init(props);
    makeObservable(this);
  }

  @action
  init = (props) => {
    this.case = props.case;
    this.checkParams = props.checkParams;
  }

  didUpdate = (props, preProps) => {
    const diffCase = diff(props.case, preProps.case);

    if (diffCase) {
      this.init(props);
    }
  }
}

export default RejectItemViewModel;
