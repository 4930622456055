import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction
} from 'mobx';
import Papa from 'papaparse';
import dayjs from 'dayjs';
import CustomModalViewModel from 'src/components/CustomModal/viewModel';
import CaseServices from 'src/services/CaseServices';
import ErrorServices from 'src/services/ErrorServices';
import { papaConfig } from 'src/global/config';
import {
  checkImportData,
  replaceCounty,
  downloadCSV
} from 'src/global/methods';
import { SITE_TYPES, IMPORT_DATE_FORMATS } from 'src/global/constants';
import { CASE_TYPES } from 'src/global/case';
import { CITIES } from 'src/global/cities';
import { message } from 'antd';
import { onSiteExample, offSiteExample } from './fakeData';

class ImportModalViewModel {
  @observable project = null;
  @observable site = null;
  // 上傳的檔案
  @observable files = [];
  @observable data = [];
  @observable isAwait = false;

  // 彈窗 vm
  modalVM = new CustomModalViewModel();
  importErrorModalVM = new CustomModalViewModel();

  // 批次更新完成後執行
  onCreated = null;

  @computed
  get check() {
    return {
      onSite: this.site === SITE_TYPES.onSite.value,
      offSite: this.site === SITE_TYPES.offSite.value
    };
  }

  @computed
  get filterData() {
    // 排除全部空白的行
    const invalid = [];
    const valid = [];

    this.data.forEach((item) => {
      const res = this.checkForm(item);

      if (res.check) {
        valid.push(item);
      } else {
        invalid.push(item);
      }
    });

    const mapInvalid = invalid.map((item) => {
      const res = this.checkForm(item);

      return { ...item, errorMessage: res.errorMessage };
    });

    const mapValidData = valid.map((item) => {
      const findCaseType = this.options.caseTypes.find((ct) => {
        return ct.label === item.caseType;
      });
      const hasRegion = item.county && item.region;
      const region = hasRegion
        ? JSON.stringify([item.county, item.region])
        : undefined;

      return {
        name: item.name,
        address: item.address?.trim() || null,
        region,
        phone: item.phone?.trim() || null,
        businessHours: item.businessHours?.trim() || null,
        startDate: item.startDate,
        expiryDate: item.expiryDate,
        // 只有非現場案件有案件類型
        caseType: findCaseType?.value,
        directions: item.directions?.trim() || null,
        uploadPath: item.uploadPath?.trim() || null
      };
    });

    return {
      invalid: mapInvalid,
      valid: mapValidData
    };
  }

  @computed
  get disabled() {
    return {
      confirm: !this.files.length || this.isAwait
    };
  }

  @computed
  get options() {
    return {
      siteTypes: Object.values(SITE_TYPES),
      caseTypes: Object.values(CASE_TYPES)
    };
  }

  constructor() {
    makeObservable(this);
  }

  @action
  init = ({ onCreated, project }) => {
    this.onCreated = onCreated;
    this.project = project;
  };

  @action
  onCreateOnSiteCase = () => {
    this.site = SITE_TYPES.onSite.value;

    this.reset();
    this.modalVM.show();
  };

  @action
  onCreateOffSiteCase = () => {
    this.site = SITE_TYPES.offSite.value;

    this.reset();
    this.modalVM.show();
  };

  @action
  close = () => {
    this.modalVM.hide();
  };

  @action
  reset = () => {
    this.files = [];
    this.data = [];
  };

  // 下載範例
  onDownload = () => {
    if (this.check.onSite) {
      downloadCSV({ data: onSiteExample, fileName: '現場案件範本' });
    }

    if (this.check.offSite) {
      downloadCSV({ data: offSiteExample, fileName: '非現場案件範本' });
    }
  };

  // 讀取 csv 資料
  getCSVData = (file) => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        ...papaConfig.import,
        complete: (results) => {
          resolve(results.data);
        }
      });
    });
  };

  // 檢查格式
  checkForm = (data) => {
    // 門市名稱 or 案件名稱
    const checkName = data.name?.trim();
    // 執案開始日
    const checkStartDate = dayjs(data.startDate, 'YYYY-MM-DD', true).isValid();
    // 值案結束日
    const checkExpiryDate = dayjs(
      data.expiryDate,
      'YYYY-MM-DD',
      true
    ).isValid();

    if (!checkName) {
      return {
        check: false,
        errorMessage: '門市/案件名稱'
      };
    }

    if (!checkStartDate) {
      return {
        check: false,
        errorMessage: '執案開始日'
      };
    }

    if (!checkExpiryDate) {
      return {
        check: false,
        errorMessage: '案件截止日'
      };
    }

    // 檢查現場案件
    if (this.check.onSite) {
      // 門市區域
      // 現場所屬地區
      // 現場城市與地區
      const county = data.county?.trim();
      const findCounty = CITIES.county.find((item) => item.value === county);
      const region = data.region?.trim();
      const findRegion = findCounty?.children.find(
        (item) => item.value === region
      );

      // 現場案件, 只要有輸入縣市或地區其中一個, 就會檢查輸入的內容
      if ((county || region) && !(findCounty && findRegion)) {
        return {
          check: false,
          errorMessage: '門市區域'
        };
      }
    }

    if (this.check.offSite) {
      // 非現場案件類型
      const checkCaseType = data.caseType?.trim();
      // 尋找案件類型
      const findCaseType = this.options.caseTypes.find((item) => {
        return item.label === data.caseType;
      });

      if (!checkCaseType || !findCaseType) {
        return {
          check: false,
          errorMessage: '案件類型'
        };
      }
    }

    return {
      check: true
    };
  };

  formatDate = (date) => {
    for (let i = 0; i < IMPORT_DATE_FORMATS.length; i += 1) {
      const format = IMPORT_DATE_FORMATS[i];
      const flag = dayjs(date, format, true).isValid();

      if (flag) {
        return dayjs(date, format).format('YYYY-MM-DD');
      }
    }

    return null;
  };

  // 覆蓋預設上傳行為
  @action
  onCustomRequest = async (event) => {
    this.isAwait = true;

    const files = [event.file];
    const data = await Promise.all(
      files.map((item) => {
        return this.getCSVData(item);
      })
    );

    runInAction(() => {
      const mapData = data
        .flat()
        .map((item, i) => {
          const startDate = this.formatDate(item['執案開始日']);
          const expiryDate = this.formatDate(item['案件截止日']);

          console.log('onCustomRequest', item);

          return {
            index: i,
            name: item['門市名稱'] || item['案件名稱'],
            address: item['門市地址'],
            county: replaceCounty(item['所屬縣市']),
            region: replaceCounty(item['所屬地區']),
            phone: item['電話'],
            businessHours: item['營業時間'],
            startDate,
            expiryDate,
            caseType: item['案件類型'],
            directions: item['案件說明'],
            uploadPath: item['上傳檔案位置']
          };
        })
        .filter((item) => checkImportData(item));

      if (mapData.length) {
        this.files = files;
        this.data = mapData;
      } else {
        this.files = [];
        this.data = [];

        message.error('資料格式錯誤, 請重新檢視', 5);
      }

      event.onSuccess();

      this.isAwait = false;
    });
  };

  // 刪除上傳選項
  @action
  onRemove = (file) => {
    const uid = file.uid;

    this.files = this.files.filter((item) => item.uid !== uid);

    return true;
  };

  // 確認
  @action
  onConfirm = async () => {
    this.postVisitorCreateBatchAPI();
  };

  // 無效資料彈窗, 確認按鈕
  @action
  onConfirmImportErrorModal = () => {
    this.reset();
    this.onCreated();
    this.importErrorModalVM.hide();
  };

  // create api
  @action
  postVisitorCreateBatchAPI = async () => {
    this.isAwait = true;

    try {
      const projectId = this.project.projectId;
      const res = await CaseServices.postCaseCreateBatch({
        projectId,
        site: this.site,
        info: this.filterData.valid
      });

      runInAction(() => {
        this.close();

        // 有無效資料
        if (this.filterData.invalid.length) {
          this.importErrorModalVM.show();
        } else {
          this.reset();
          this.onCreated();
        }
      });
    } catch (error) {
      const meg = ErrorServices.postCaseCreateBatch(error);

      console.log('CreateModalViewModel', 'postCaseCreateBatch', meg);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default ImportModalViewModel;
