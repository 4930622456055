import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import { SITE_TYPES } from './constants';
import {
  TOPIC_FACETS,
  TOPIC_INFERIOR_FACETS,
  ON_SITE_TOPIC_FORMAT,
  OFF_SITE_TOPIC_FORMAT
} from './topic';

// 題目排序
function getSortTopics(topics) {
  const topicValues = Object.values(TOPIC_FACETS);

  const onSiteTopics = topics.filter(
    (topic) => topic.site === SITE_TYPES.onSite.value
  );

  const offSiteTopics = topics.filter(
    (topic) => topic.site === SITE_TYPES.offSite.value
  );

  onSiteTopics.sort((topic1, topic2) => {
    const topic1Index = topicValues.findIndex((item) => {
      return item.value === topic1.facet;
    });
    const topic2Index = topicValues.findIndex((item) => {
      return item.value === topic2.facet;
    });

    return topic1Index - topic2Index;
  });

  offSiteTopics.sort((topic1, topic2) => {
    const topic1Index = topicValues.findIndex((item) => {
      return item.value === topic1.facet;
    });
    const topic2Index = topicValues.findIndex((item) => {
      return item.value === topic2.facet;
    });

    return topic1Index - topic2Index;
  });

  const concatTopics = onSiteTopics.concat(offSiteTopics);

  // 過濾沒有答案的題目, 例如匯出現場案件的答案時, 過濾非現場的題目
  const filterRes = concatTopics.filter(
    (topic) => !!topic.checkFormReply.length
  );

  return filterRes;
}

// 選擇需要的欄位
function getBasic({
  status = false,
  visitorName = false,
  visitorNickName = false,
  auditStartTime = false,
  auditEndTime = false,
  implementationTotal = false,
  satisfactionTotal = false
}) {
  // 基本的Y軸
  const statuses = { '案件/門市名稱': '案件狀態' };
  const visitorNames = { '案件/門市名稱': '訪員姓名' };
  const visitorNickNames = { '案件/門市名稱': '代號' };
  const auditStartTimes = { '案件/門市名稱': '開始稽查時間' };
  const auditEndTimes = { '案件/門市名稱': '結束稽查時間' };
  const implementTotals = {
    '案件/門市名稱': '落實度總分'
  };
  const satisfactionTotals = {
    '案件/門市名稱': '滿意度總分'
  };

  const res = {};

  // 選擇需要的欄位
  if (status) {
    res.status = statuses;
  }

  if (visitorName) {
    res.visitorName = visitorNames;
  }

  if (visitorNickName) {
    res.visitorNickName = visitorNickNames;
  }

  if (auditStartTime) {
    res.auditStartTime = auditStartTimes;
  }

  if (auditEndTime) {
    res.auditEndTime = auditEndTimes;
  }

  if (implementationTotal) {
    res.implementationTotal = implementTotals;
  }

  if (satisfactionTotal) {
    res.satisfactionTotal = satisfactionTotals;
  }

  return res;
}

// 判斷落實度分數
function getImplementationScore({ type, weighted, reply }) {
  switch (String(type)) {
    // 加分題
    case '0': {
      return reply || 0;
    }

    // 0 分或滿分
    case '1': {
      return String(reply) === '0' ? 0 : weighted;
    }

    default: {
      return 0;
    }
  }
}

// 判斷滿意度分數
function getSatisfactionScore({ type, weighted, reply }) {
  switch (String(type)) {
    // 加分題
    case '0': {
      return reply || 0;
    }

    // 1 ~ 5
    case '1': {
      return new BigNumber(weighted)
        .dividedBy(5)
        .multipliedBy(reply || 0)
        .toNumber();
    }

    // 0 ~ 1
    case '2':
      return new BigNumber(weighted).multipliedBy(reply || 0).toNumber();

    default:
      return 0;
  }
}

// 期待的資料格式
// const fake = [
//   {
//     topic:
//       '客戶進門時，迎賓服務人員能主動致上歡迎詞，過程中面帶微笑且能適度目視客戶。',
//     content: null,
//     facet: 'basic',
//     inferiorFacet: 'welcomeService',
//     type: 'fraction',
//     site: 'onSite',
//     implementation: 1, // 落實度類型
//     implementWeighted: 4, // 落實度加權分數
//     satisfaction: null, // 滿意度類型
//     satisWeighted: null, // 滿意度加權分數
//     checkFormReply: [
//       {
//         auditStartTime: '2023-08-14 15:18:56',
//         auditEndTime: '2023-08-14 15:23:47',
//         visitorId: 214,
//         visitorName: 'SD測試', // 訪員名字
//         visitorNickName: '假SD', // 訪員暱稱
//         implementScore: 0,
//         satisScore: 0,
//         content: '有微笑，沒目視',
//         targetName: '中山服務中心', // 案件名稱
//         caseStepName: '允收' // 案件狀態
//       },
//       {
//         auditStartTime: '2023-08-14 16:20:14',
//         auditEndTime: '2023-08-14 16:29:57',
//         visitorId: 168,
//         visitorName: '李欣芫',
//         visitorNickName: 'SD',
//         implementScore: 0,
//         satisScore: null,
//         content: '',
//         targetName: '松山分公司',
//         caseStepName: '允收'
//       },
//       {
//         auditStartTime: '2023-08-14 17:07:36',
//         auditEndTime: '2023-08-14 17:27:54',
//         visitorId: 222,
//         visitorName: 'EE',
//         visitorNickName: 'EE',
//         implementScore: 1,
//         satisScore: null,
//         content: '主动上前，面带璨烂微笑。',
//         targetName: '陽明服務中心',
//         caseStepName: '允收'
//       }
//     ]
//   }
// ];

export const exportCheckForm = ({
  data,
  status = false,
  visitorName = false,
  visitorNickName = false,
  auditStartTime = false,
  auditEndTime = false,
  implementationTotal = false,
  satisfactionTotal = false
}) => {
  console.log('匯出查檢表', '新版', '題目', data);

  const topics = getSortTopics(data);

  // 基本的 Y 軸
  const basic = getBasic({
    status,
    visitorName,
    visitorNickName,
    auditStartTime,
    auditEndTime,
    implementationTotal,
    satisfactionTotal
  });

  // 題目的 Y 軸
  const basicTopics = [];

  // csv 表格的 X 軸需要不同的 key, 避免資料覆蓋
  // 非現場案件的起始編號
  const onSiteRepliesLength = topics[0].checkFormReply.length;

  // 訂出題目需要的記號, replies 迴圈時會使用, 匯出前需要刪除
  const implementationType = 'implementation';
  const satisfactionType = 'satisfaction';
  const descriptionType = 'description';

  // 在 Y 軸新增所有題目
  // eslint-disable-next-line consistent-return
  topics.forEach((topic, i) => {
    const facets
      = topic.site === SITE_TYPES.onSite.value
        ? ON_SITE_TOPIC_FORMAT
        : OFF_SITE_TOPIC_FORMAT;
    const facet = facets[topic.facet];
    const inferiorFacet = facet?.inferiorFacets[topic.inferiorFacet];

    if (!facet || !inferiorFacet) {
      return false;
    }

    // 判斷是否有落實度和滿意度
    const checkImplementation = topic.implementation !== null;
    const checkSatisfaction = topic.satisfaction !== null;

    // 這題有找到構面和次構面, 並且有落實度
    if (checkImplementation) {
      basicTopics.push({
        '案件/門市名稱': `${facet.label}-${inferiorFacet.label}-${topic.topic}-落實度`,

        // 訂出題目需要的記號, replies 迴圈時會使用, 匯出前需要刪除
        facet: facet.value,
        inferiorFacet: inferiorFacet.value,
        type: `${implementationType}-${i}`
      });
    }

    // 這題有找到構面和次構面, 並且有滿意度
    if (checkSatisfaction) {
      basicTopics.push({
        '案件/門市名稱': `${facet.label}-${inferiorFacet.label}-${topic.topic}-滿意度`,

        // 訂出題目需要的記號, replies 迴圈時會使用, 匯出前需要刪除
        facet: facet.value,
        inferiorFacet: inferiorFacet.value,
        type: `${satisfactionType}-${i}`
      });
    }

    // 描述
    basicTopics.push({
      '案件/門市名稱': `${facet.label}-${inferiorFacet.label}-${topic.topic}-描述`,

      // 訂出題目需要的記號, replies 迴圈時會使用, 匯出前需要刪除
      facet: facet.value,
      inferiorFacet: inferiorFacet.value,
      type: `${descriptionType}-${i}`
    });
  });

  // 新增案件的基本資料
  // eslint-disable-next-line consistent-return
  topics.forEach((topic) => {
    const facets
      = topic.site === SITE_TYPES.onSite.value
        ? ON_SITE_TOPIC_FORMAT
        : OFF_SITE_TOPIC_FORMAT;
    const facet = facets[topic.facet];
    const inferiorFacet = facet?.inferiorFacets[topic.inferiorFacet];

    if (!facet || !inferiorFacet) {
      return false;
    }

    topic.checkFormReply.forEach((reply, i) => {
      const index
        = topic.site === SITE_TYPES.onSite.value
          ? i + 1
          : i + 1 + onSiteRepliesLength;
      const replyKey = `${reply.targetName}-${index}`;

      if (status) {
        basic.status[replyKey] = reply.caseStepName;
      }

      if (visitorName) {
        basic.visitorName[replyKey] = reply.visitorName;
      }

      if (visitorNickName) {
        basic.visitorNickName[replyKey] = reply.visitorNickName;
      }

      if (auditStartTime) {
        basic.auditStartTime[replyKey] = dayjs(reply.auditStartTime).format(
          'YYYY-MM-DD HH:mm:ss'
        );
      }

      if (auditEndTime) {
        basic.auditEndTime[replyKey] = dayjs(reply.auditEndTime).format(
          'YYYY-MM-DD HH:mm:ss'
        );
      }

      if (implementationTotal) {
        basic.implementationTotal[replyKey] = [];
      }

      if (satisfactionTotal) {
        basic.satisfactionTotal[replyKey] = [];
      }
    });
  });

  // 新增案件的查檢表分數
  // eslint-disable-next-line consistent-return
  topics.forEach((topic, topicIndex) => {
    const facets
      = topic.site === SITE_TYPES.onSite.value
        ? ON_SITE_TOPIC_FORMAT
        : OFF_SITE_TOPIC_FORMAT;
    const facet = facets[topic.facet];
    const inferiorFacet = facet?.inferiorFacets[topic.inferiorFacet];

    if (!facet || !inferiorFacet) {
      return false;
    }

    const findImplementationItem = basicTopics.find((item) => {
      return (
        item.facet === facet.value
        && item.inferiorFacet === inferiorFacet.value
        && item.type === `${implementationType}-${topicIndex}`
      );
    });

    const findSatisfactionItem = basicTopics.find((item) => {
      return (
        item.facet === facet.value
        && item.inferiorFacet === inferiorFacet.value
        && item.type === `${satisfactionType}-${topicIndex}`
      );
    });

    const findDescriptionItem = basicTopics.find((item) => {
      return (
        item.facet === facet.value
        && item.inferiorFacet === inferiorFacet.value
        && item.type === `${descriptionType}-${topicIndex}`
      );
    });

    topic.checkFormReply.forEach((reply, replyIndex) => {
      const index
        = topic.site === SITE_TYPES.onSite.value
          ? replyIndex + 1
          : replyIndex + 1 + onSiteRepliesLength;
      const replyKey = `${reply.targetName}-${index}`;

      // 落實度
      if (topic.implementation !== null && findImplementationItem) {
        const score = getImplementationScore({
          type: topic.implementation,
          weighted: topic.implementWeighted,
          reply: reply.implementScore
        });

        findImplementationItem[replyKey] = reply.implementScore;

        if (implementationTotal) {
          basic.implementationTotal[replyKey].push(score);
        }
      }

      // 滿意度
      if (topic.satisfaction !== null && findSatisfactionItem) {
        const score = getSatisfactionScore({
          type: topic.satisfaction,
          weighted: topic.satisWeighted,
          reply: reply.satisScore
        });

        findSatisfactionItem[replyKey] = reply.satisScore;

        if (satisfactionTotal) {
          basic.satisfactionTotal[replyKey].push(score);
        }
      }

      // 描述
      if (findDescriptionItem) {
        findDescriptionItem[replyKey] = reply.content;
      }
    });
  });

  // 總分, 把 bigNumber 轉為數值
  if (implementationTotal) {
    const item = { '案件/門市名稱': '落實度總分' };
    // 最少要有一個案件有落實度分數, 才會保留落實度總分
    let flag = false;

    Object.keys(basic.implementationTotal).forEach((key) => {
      const total = basic.implementationTotal[key];

      // 排除案件/門市名稱
      if (total instanceof Array) {
        const count = total.reduce((t, val) => t.plus(val), new BigNumber(0));

        item[key] = count.toNumber();

        if (total.length) {
          flag = true;
        }
      }
    });

    if (flag) {
      basic.implementationTotal = item;
    } else {
      delete basic.implementationTotal;
    }
  }

  if (satisfactionTotal) {
    const item = { '案件/門市名稱': '滿意度總分' };
    // 最少要有一個案件有落實度分數, 才會保留落實度總分
    let flag = false;

    Object.keys(basic.satisfactionTotal).forEach((key) => {
      const total = basic.satisfactionTotal[key];

      if (total instanceof Array) {
        const count = total.reduce((t, val) => t.plus(val), new BigNumber(0));

        item[key] = count.toNumber();

        if (total.length) {
          flag = true;
        }
      }
    });

    if (flag) {
      basic.satisfactionTotal = item;
    } else {
      delete basic.satisfactionTotal;
    }
  }

  basicTopics.forEach((topic) => {
    // eslint-disable-next-line no-param-reassign
    delete topic.type;
    // eslint-disable-next-line no-param-reassign
    delete topic.facet;
    // eslint-disable-next-line no-param-reassign
    delete topic.inferiorFacet;
  });

  const res = Object.values(basic).concat(basicTopics);

  console.log('匯出查檢表', '新版', '結果', res);

  return res;
};

// 匯出查檢表結果, 舊版
// export const checkFormFormat = (data) => {
//   const topics = getSortTopics(data);

//   console.log('匯出查檢表 題目', topics);

//   // 組合表格的標題(案件狀態, 訪員姓名姓名, 代號)(Y軸前 6 個的標題)
//   const caseStatus = { '案件/門市名稱': '案件狀態' };
//   const visitorNames = { '案件/門市名稱': '訪員姓名' };
//   const visitorNickNames = { '案件/門市名稱': '代號' };
//   const auditStartTime = { '案件/門市名稱': '開始稽查時間' };
//   const auditEndTime = { '案件/門市名稱': '結束稽查時間' };
//   const implementTotal = { '案件/門市名稱': '落實度總分' };
//   const satisScoreTotal = { '案件/門市名稱': '滿意度總分' };

//   // 每個 X 軸的 Y 軸前6個

//   // 現場的答案
//   const onSiteReply
//     = topics.find((item) => item.site === SITE_TYPES.onSite.value)
//       ?.checkFormReply || [];
//   // 非現場的答案
//   const offSiteReply
//     = topics.find((item) => item.site === SITE_TYPES.offSite.value)
//       ?.checkFormReply || [];

//   console.log('onSiteReply', onSiteReply);
//   console.log('offSiteReply', offSiteReply);

//   // 非現場的索引, 從現場的的長度開始
//   const concatReplies = onSiteReply.concat(offSiteReply);

//   concatReplies.forEach((item, i) => {
//     const index = i + 1;
//     const key = `${item.targetName}-${index}`;

//     caseStatus[key] = item.caseStepName;
//     visitorNames[key] = item.visitorName;
//     visitorNickNames[key] = item.visitorNickName;
//     auditStartTime[key] = item.auditStartTime;
//     auditEndTime[key] = item.auditEndTime;
//     implementTotal[key] = null;
//     satisScoreTotal[key] = null;
//   });

//   console.log('匯出查檢表', 'Keys', caseStatus);

//   // 所有題目
//   const replies = topics
//     .map((item) => {
//       const facet = TOPIC_FACETS[item.facet];
//       const inferiorFacet = TOPIC_INFERIOR_FACETS[item.inferiorFacet];
//       const isOffSite = item.site === SITE_TYPES.offSite.value;
//       const res = [];

//       let implementation;
//       let satisfaction;

//       console.log('匯出查檢表', '題目', item);

//       if (!facet) {
//         console.log('匯出查檢表錯誤', '主構面', item);
//         return res;
//       }

//       if (!inferiorFacet) {
//         console.log('匯出查檢表錯誤', '次構面', item);
//         return res;
//       }

//       // 落實度
//       if (item.implementWeighted !== null) {
//         implementation = {
//           '案件/門市名稱': `${facet.label}-${inferiorFacet.label}-${item.topic}-落實度`
//         };
//       }

//       // 滿意度
//       if (item.satisWeighted !== null) {
//         satisfaction = {
//           '案件/門市名稱': `${facet.label}-${inferiorFacet.label}-${item.topic}-滿意度`
//         };
//       }

//       // 描述
//       const content = {
//         '案件/門市名稱': `${facet.label}-${inferiorFacet.label}-${item.topic}-描述`
//       };

//       // 查檢表答案
//       item.checkFormReply.forEach((reply, i) => {
//         // 非現場的索引, 從現場的的長度開始
//         const index = isOffSite ? i + 1 + onSiteReply.length : i + 1;
//         const key = `${reply.targetName}-${index}`;

//         // 落實度
//         if (implementation) {
//           implementation[key] = reply.implementScore;

//           // 總分計算
//           const total = implementTotal[key] === null ? 0 : implementTotal[key];
//           const score = getImplementScore({
//             type: item.implementation,
//             weighted: item.implementWeighted,
//             reply: reply.implementScore
//           });

//           implementTotal[key] = new BigNumber(total).plus(score).toNumber();
//         }

//         // 滿意度
//         if (satisfaction) {
//           satisfaction[key] = reply.satisScore;

//           // 總分計算
//           const total
//             = satisScoreTotal[key] === null ? 0 : satisScoreTotal[key];
//           const score = getSatisScore({
//             type: item.satisfaction,
//             weighted: item.satisWeighted,
//             reply: reply.satisScore
//           });

//           satisScoreTotal[key] = new BigNumber(total).plus(score).toNumber();
//         }

//         content[key] = reply.content;
//       });

//       // 簡答題不要落實度與滿意度
//       if (item.type === TOPIC_TYPES.shortAnswer.value) {
//         return [content];
//       }

//       // 這個題目有落實度
//       if (implementation) {
//         res.push(implementation);
//       }

//       // 這個題目有滿意度
//       if (satisfaction) {
//         res.push(satisfaction);
//       }

//       res.push(content);

//       return res;
//     })
//     .flat();

//   // 判斷有沒有落實度和滿意度, 決定顯示總分
//   const res = [
//     caseStatus,
//     visitorNames,
//     visitorNickNames,
//     auditStartTime,
//     auditEndTime
//   ];

//   // 檢查落實度
//   const checkImplement = Object.values(implementTotal).some((item) => {
//     // 排除標題
//     if (item === '落實度總分') {
//       return false;
//     }

//     // 要找到一個不是 null 的
//     return item !== null;
//   });

//   if (checkImplement) {
//     res.push(implementTotal);
//   }

//   // 檢查滿意度
//   const checkSatisScore = Object.values(satisScoreTotal).some((item) => {
//     // 排除標題
//     if (item === '滿意度總分') {
//       return false;
//     }

//     return item !== null;
//   });

//   if (checkSatisScore) {
//     res.push(satisScoreTotal);
//   }

//   const format = [...res, ...replies];

//   console.log('匯出查檢表', '結果', format);

//   return format;
// };
