import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import withForm from 'src/components/withForm';
import FormModal from 'src/components/FormModal';
import StyleItem from 'src/components/StyleItem';
import CustomForm from 'src/components/CustomForm';
import clsx from 'clsx';
import Template1 from './components/Template1';
import FormatItemViewModel from './viewModel';
import styles from './styles.module.scss';

// 專案總規格
@observer
class FormatItem extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new FormatItemViewModel({
      data: props.data,
      checkParams: props.checkParams,
      onChanged: props.onChanged,
      form: props.form
    });
  }

  componentDidMount() {
    this.vm.didMount();
  }

  componentDidUpdate(preProps) {
    this.vm.didUpdate(this.props, preProps);
  }

  renderForm = () => {
    switch (this.vm.version) {
      case '1.0':
        return (
          <Template1
            project={this.props.data}
            disabled={this.vm.disabled.form}
            form={this.vm.form}
            isEdit={this.vm.isEdit}
          />
        );

      default:
        return null;
    }
  };

  render() {
    return (
      <div className={styles.container}>
        <StyleItem>
          <span>專案總規格</span>

          <Button type="primary" onClick={this.vm.modalVM.show}>
            {this.vm.label.button}
          </Button>
        </StyleItem>

        <FormModal
          vm={this.vm.modalVM}
          title="專案總規格"
          onSave={this.vm.onSave}
          disableEdit={this.vm.disabled.modal}
          isAwait={this.vm.isAwait}
        >
          <CustomForm form={this.vm.form} disabled={this.vm.disabled.form}>
            <div className={styles.classification}>
              <div className={styles.title}>※ 請注意各自需填寫的範圍</div>

              <div className={styles.item}>
                <div className={clsx([styles.icon, styles.saIcon])} />
                <div className={styles.label}>業務</div>
              </div>

              <div className={styles.item}>
                <div className={clsx([styles.icon, styles.pmIcon])} />
                <div className={styles.label}>PM</div>
              </div>

              <div className={styles.item}>
                <div className={clsx([styles.icon, styles.omIcon])} />
                <div className={styles.label}>營管部</div>
              </div>
            </div>

            {this.renderForm()}
          </CustomForm>
        </FormModal>
      </div>
    );
  }
}

FormatItem.propTypes = {
  data: PropTypes.object.isRequired,
  checkParams: PropTypes.object.isRequired,
  onChanged: PropTypes.func,
  form: PropTypes.object.isRequired
};

FormatItem.defaultProps = {
  onChanged: () => {}
};

export default withForm(FormatItem);
