import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { message } from 'antd';
import {
  RE_NUMBER,
  PROJECT_STATUS,
  ROUTES,
  ROUTE_PARAMS,
  USER_TYPES
} from 'src/global/constants';
import { INDUSTRIES, PLACES } from 'src/global/profitForm';
import ProjectServices from 'src/services/ProjectServices';
import ProfitFormServices from 'src/services/ProfitFormServices';
import ErrorServices from 'src/services/ErrorServices';
import CustomModalViewModel from 'src/components/CustomModal/viewModel';
import DataViewModel from './viewModels/DataViewModel';
// 申請修改紀錄彈窗
import ApplyModalViewModel from './components/ApplyModal/viewModel';
import ApplyViewModel from './viewModels/ApplyViewModel';

// 太多元件需要共用資料計算, 建議使用局部 dataVM

class BudgetDetailPageViewModel {
  @observable profile = null;
  @observable project = null;
  @observable formData = null;
  // 申請修改紀錄
  @observable eventList = [];
  @observable isAwait = false;
  @observable editStatus = null;
  @observable hasApplyEvent = false;

  projectId = null;

  finishModalVM = new CustomModalViewModel();
  dataVM = new DataViewModel();
  applyModalVM = new ApplyModalViewModel();

  router = null;

  form = null;

  @computed
  get check() {
    // 帳號角色
    const profileType = this.profile.type;
    const isBoss = profileType === USER_TYPES.boss.value;
    // 專案擁有者
    const isOM = profileType === USER_TYPES.operationsManagement.value;

    return {
      project: !!this.project,
      formData: !!this.formData,
      isShow: !!this.project && !!this.formData,
      isBoss,
      isHandler: isBoss || isOM,
      isShowOperateButtons: !isBoss
    };
  }

  @computed
  get disabled() {
    const isExpected = this.editStatus === 0 || this.editStatus === 1;
    const isResult = this.editStatus === 0 || this.editStatus === 2;
    const isComplete = this.editStatus === 3;
    const isS7 = this.project.step === PROJECT_STATUS.S7.value;
    const isBoss = this.check.isBoss;
    const applyDisabled = !isS7 || this.hasApplyEvent;

    return {
      // 儲存按鈕
      save: this.isAwait || isComplete || !this.dataVM.isEdit,
      // 完成按鈕
      finish: this.isAwait || isComplete || this.hasApplyEvent,
      applyRevise: applyDisabled,
      // 專案件酬試算
      allowances:
        this.isAwait
        || isComplete
        || !this.check.isHandler
        || !isExpected
        || isBoss,
      // 專案收入
      income:
        this.isAwait
        || isComplete
        || !this.check.isHandler
        || !isExpected
        || isBoss,
      // 預估損益
      estimated:
        this.isAwait
        || isComplete
        || !this.check.isHandler
        || !isExpected
        || isBoss,
      // 結算損益
      result:
        this.isAwait
        || isComplete
        || !this.check.isHandler
        || !isResult
        || isBoss
    };
  }

  @computed
  get routes() {
    if (this.check.project) {
      return [
        {
          label: ROUTES.budget.label,
          link: `/${ROUTES.budget.value}`
        },
        {
          label: this.project.projectName
        }
      ];
    }

    return [];
  }

  @computed
  get backLink() {
    return `/${ROUTES.budget.value}`;
  }

  @computed
  get required() {
    return [
      {
        required: true,
        message: '請輸入'
      }
    ];
  }

  @computed
  get options() {
    return {
      industries: Object.values(INDUSTRIES),
      places: Object.values(PLACES)
    };
  }

  constructor(props) {
    this.profile = props.profile;
    this.router = props.router;
    this.form = props.form;

    this.readRoute(props);

    makeObservable(this);
  }

  readRoute = (props) => {
    const id = props.router.params[ROUTE_PARAMS.projectId];
    this.projectId = id;
  };

  didMount = async () => {
    if (!this.isAwait) {
      this.getProjectDetailAPI();
      this.getProfitFormAPI();
    }
  };

  // 儲存
  onSave = async () => {
    this.postProfitFormUpsertAPI(false);
  };

  onFinish = async () => {
    try {
      await this.form.validateFields();
      this.finishModalVM.show();
    } catch (error) {
      message.error('必填欄位尚未填寫完成，請重新檢視');
      console.log('error', error);
    }
  };

  // 完成的彈窗確認
  onFinishConfirm = () => this.postProfitFormUpsertAPI(true);

  // 申請修改, 申請完成
  onApplied = () => {
    this.getProfitFormAPI();
  };

  // 接受申請修改
  onAccepted = () => {
    this.getProfitFormAPI();
  };

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 業種, 場域改變後可以儲存

  @action
  onSelectChange = () => this.dataVM.setIsEdit(true);

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 編輯案件數量的 on change

  @action
  onChangeOnSiteCaseLength = (event) => {
    const value = event.target.value;
    const isNumber = RE_NUMBER.test(value);
    const check = !value || isNumber;

    if (check) {
      this.dataVM.setOnSiteCaseLength(value);
    }
  };

  @action
  onChangeOffSiteCaseLength = (event) => {
    const value = event.target.value;
    const isNumber = RE_NUMBER.test(value);
    const check = !value || isNumber;

    if (check) {
      this.dataVM.setOffSiteCaseLength(value);
    }
  };

  @action
  onChangeOtherCaseLength = (event) => {
    const value = event.target.value;
    const isNumber = RE_NUMBER.test(value);
    const check = !value || isNumber;

    if (check) {
      this.dataVM.setOtherCaseLength(value);
    }
  };

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 編輯專案收入的 on change

  @action
  onChangeIncome = (value) => {
    this.dataVM.setIncome(value);
  };

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 將畫面資料轉為 api 資料
  getUpdateData = async (finish) => {
    let formData = null;

    // 完成
    if (finish) {
      // 檢查必填
      formData = await this.form.validateFields();
    } else {
      try {
        // 儲存
        // 讀資料
        formData = this.form.getFieldsValue();
        // 檢查必填, 使用 try catch 確保檢查後還能 call api
        await this.form.validateFields();
      } catch (error) {
        console.log('error');
      }
    }

    // 專案件酬試算
    const allowances = [];
    const dynamicAllowances = [];
    const cases = {
      onSite: { length: this.dataVM.cases.onSite.length },
      offSite: { length: this.dataVM.cases.offSite.length },
      other: { length: this.dataVM.cases.other.length }
    };
    // 專案損益表, 預估損益
    const expects = [];
    // 專案損益表, 結算損益
    const results = [];

    for (let i = 0; i < this.dataVM.allowances.length; i += 1) {
      const item = this.dataVM.allowances[i];
      allowances.push(item.res);
    }

    for (let i = 0; i < this.dataVM.dynamicAllowances.length; i += 1) {
      const item = this.dataVM.dynamicAllowances[i];
      dynamicAllowances.push(item.res);
    }

    for (let i = 0; i < this.dataVM.incomes.length; i += 1) {
      const vm = this.dataVM.incomes[i];
      expects.push(vm.resExpected);
      results.push(vm.resResult);
    }

    const resData = {
      // 業種(行業別)
      industry: formData.industry,
      // 場域選擇
      place: formData.place,
      // 專案件酬試算
      pieceFee: {
        cases,
        allowances,
        dynamicAllowances
      },
      // 專案收入
      income: this.dataVM.income,
      // 預估損益
      estimatedProfit: expects,
      // 結算損益
      resultProfit: results
    };

    console.log('getUpdateData', 'resData', resData);

    switch (this.editStatus) {
      case 0:
        return {
          projectId: this.projectId,
          // 業種(行業別)
          industry: formData.industry,
          // 場域選擇
          place: formData.place,
          // 專案件酬試算
          pieceFee: JSON.stringify(resData.pieceFee),
          // 專案收入
          income: this.dataVM.income,
          // 預估損益
          estimatedProfit: JSON.stringify(expects),
          // 結算損益
          resultProfit: JSON.stringify(results)
        };

      case 1:
        return {
          projectId: this.projectId,
          // 業種(行業別)
          industry: formData.industry,
          // 場域選擇
          place: formData.place,
          // 專案件酬試算
          pieceFee: JSON.stringify(resData.pieceFee),
          // 專案收入
          income: this.dataVM.income,
          // 預估損益
          estimatedProfit: JSON.stringify(expects)
        };

      case 2:
        return {
          projectId: this.projectId,
          // 結算損益
          resultProfit: JSON.stringify(results)
        };

      default:
        return {};
    }
  };

  // 將 api 資料轉為畫面資料
  @action
  setFormData = (formData) => {
    const {
      editStatus,
      industry,
      place,
      pieceFee,
      income,
      estimatedProfit,
      resultProfit
    } = formData;

    // 編輯狀態
    this.editStatus = editStatus;

    // 業種(行業別), 場域選擇
    this.form.setFieldsValue({ industry, place, income });

    // 專案預估損益申請修改紀錄;
    const cases = pieceFee.cases;

    this.dataVM.setAllowances(pieceFee.allowances);
    this.dataVM.setDynamicAllowances(pieceFee.dynamicAllowances);

    this.dataVM.setOnSiteCaseLength(cases.onSite.length);
    this.dataVM.setOffSiteCaseLength(cases.offSite.length);
    this.dataVM.setOtherCaseLength(cases.other.length);

    // 專案收入
    this.dataVM.setIncome(income);

    // 預估損益, 結算損益
    const incomes = estimatedProfit.map((item, index) => {
      const resultItem = resultProfit ? resultProfit[index] : undefined;
      const result = resultProfit ? resultItem?.fee : undefined;
      const resultDesc = resultProfit ? resultItem?.desc : undefined;

      return {
        id: item.id,
        label: item.label,
        expected: item.fee,
        expectedDesc: item.desc,
        result,
        resultDesc
      };
    });

    this.dataVM.setIncomes(incomes);
  };

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 損益表完成時, 前往的頁面
  getProjectDetailRoute = () => {
    switch (this.project.step) {
      // 立案
      case PROJECT_STATUS.S1.value:
      case PROJECT_STATUS.S2.value:
      case PROJECT_STATUS.S3.value:
        return ROUTES.proposal.value;

      // 派案
      case PROJECT_STATUS.S4.value:
      case PROJECT_STATUS.S5.value:
        return ROUTES.execute.value;

      // 結案
      case PROJECT_STATUS.S6.value:
      case PROJECT_STATUS.S7.value:
      case PROJECT_STATUS.S8.value:
      case PROJECT_STATUS.S9.value:
      case PROJECT_STATUS.S10.value:
        return ROUTES.close.value;

      default:
        return '';
    }
  };

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 讀取專案資料
  @action
  getProjectDetailAPI = async () => {
    this.isAwait = true;

    try {
      const res = await ProjectServices.getProjectDetail({
        projectId: this.projectId
      });

      runInAction(() => {
        this.project = res.data;

        console.log('project', this.project);
      });
    } catch (error) {
      const msg = ErrorServices.getProjectDetail(error);

      console.log('BudgetDetailPageViewModel', 'getProjectDetail', msg);
    }
  };

  // 讀取損益表
  @action
  getProfitFormAPI = async () => {
    this.isAwait = true;

    try {
      const res = await ProfitFormServices.getProfitForm({
        projectId: this.projectId
      });

      console.log('BudgetDetailPageViewModel', 'getProfitFormAPI', res);

      runInAction(() => {
        this.setFormData(res.formData);

        // 損益表尚未修改過
        this.dataVM.setIsEdit(false);

        this.formData = true;

        res.eventList.forEach(element => {
          if (element.status == 'apply') {
            this.hasApplyEvent = true
          }
        })

        this.eventList = res.eventList.map(
          (item) =>
            new ApplyViewModel({
              data: item,
              onAccepted: this.onAccepted
            })
        );

        this.isAwait = false;

        console.log('讀取損益表', res);
      });
    } catch (error) {
      const msg = ErrorServices.getProfitForm(error);

      if (error.response?.status === 404) {
        runInAction(() => {
          // 讀取總規格表
          this.getProjectSpecificationAPI();
        });
      }

      console.log('BudgetDetailPageViewModel', 'getProfitForm', msg);
    }
  };

  // 更新損益表
  @action
  postProfitFormUpsertAPI = async (finish = false) => {
    this.isAwait = true;

    try {
      const data = await this.getUpdateData(finish);
      const res = await ProfitFormServices.postProfitFormUpsert({
        ...data,
        complete: finish
      });

      // 完成跳轉至 project detail
      if (finish) {
        const route = this.getProjectDetailRoute();
        const detailRoute = `/${route}/${this.projectId}`;

        this.router.navigate(detailRoute);
      } else {
        // 讀取損益表
        this.getProfitFormAPI();
      }

      console.log('更新損益表完成', res.data);
    } catch (error) {
      const msg = ErrorServices.getProfitForm(error);

      console.log('BudgetDetailPageViewModel', 'getProfitForm', msg);
    } finally {
      runInAction(() => {
        this.isAwait = false;
        this.finishModalVM.hide();
      });
    }
  };

  // 讀取總規格表
  @action
  getProjectSpecificationAPI = async () => {
    this.isAwait = true;

    try {
      const res = await ProjectServices.getProjectSpecification({
        projectId: this.projectId
      });

      runInAction(() => {
        // 新增損益表
        this.editStatus = 1;

        const caseAmount = res.caseAmount;

        this.dataVM.setOnSiteCaseLength(caseAmount.onSite);
        this.dataVM.setOffSiteCaseLength(caseAmount.offSite);
        this.dataVM.setOtherCaseLength(caseAmount.other);

        // 專案件酬試算
        this.dataVM.setAllowances();
        // 專案損益表
        this.dataVM.setIncomes();

        this.formData = true;
      });
    } catch (error) {
      const msg = ErrorServices.getProjectSpecification(error);

      console.log('FormatItemViewModel', 'getProjectSpecification', msg);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default BudgetDetailPageViewModel;
