import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import dayjs from 'dayjs';
import { SITE_TYPES } from 'src/global/constants';
import { ON_SITE_TOPIC_FORMAT, OFF_SITE_TOPIC_FORMAT } from 'src/global/topic';
import SimpleModalViewModel from '../SimpleModal/viewModel';
import TopicViewModel from './components/Topic/viewModel';

class CheckFormModalViewModel {
  @observable sourceTopics = [];
  @observable topics = [];
  @observable auditStartTime = null;
  @observable auditEndTime = null;

  modalVM = new SimpleModalViewModel();

  @computed
  get check() {
    return {
      topics: !!this.topics.length
    };
  }

  @computed
  get facet() {
    const findTopic = this.sourceTopics.find(
      (item) => item.checkFormReply.length
    );
    const facet
      = findTopic.site === SITE_TYPES.onSite.value
        ? ON_SITE_TOPIC_FORMAT
        : OFF_SITE_TOPIC_FORMAT;

    return facet;
  }

  @computed
  get topicsFormat() {
    const cloneFormat = JSON.parse(JSON.stringify(this.facet));

    for (let i = 0; i < this.topics.length; i += 1) {
      const item = this.topics[i];
      const facet = cloneFormat[item.facet];
      const inferiorFacet = facet?.inferiorFacets[item.inferiorFacet];

      if (!facet || !inferiorFacet) {
        // console.log('-----錯誤的組合-----');
        // console.log(item.site);
        // console.log(item.facet);
        // console.log(item.inferiorFacet);
        // console.log('------------------');
      } else {
        inferiorFacet.topics.push(item);
      }
    }

    const facets = Object.values(cloneFormat).map((facet) => {
      // 次構面
      const inferiorFacets = Object.values(facet.inferiorFacets);

      // 構面
      return {
        value: facet.value,
        label: facet.label,
        // 次構面
        inferiorFacets
      };
    });

    // 刪除沒有題目的構面
    const filterFacets = facets.filter((facet) => {
      const inferiorFacets = facet.inferiorFacets.filter(
        (inferiorFacet) => inferiorFacet.topics.length
      );

      return inferiorFacets.length;
    });

    // 刪除沒有題目的次構面
    const mapFacets = filterFacets.map((facet) => {
      const inferiorFacets = facet.inferiorFacets.filter(
        (inferiorFacet) => inferiorFacet.topics.length
      );

      return { ...facet, inferiorFacets };
    });

    return mapFacets;
  }

  @computed
  get total() {
    const topics = this.topics;

    let implementWeighted = 0;
    let implementScore = 0;
    let satisWeighted = 0;
    let satisScore = 0;

    for (let i = 0; i < topics.length; i += 1) {
      const item = topics[i];
      const fraction = item.fraction;

      if (fraction && fraction.implementWeighted) {
        implementWeighted += Number(fraction.implementWeighted);
      }

      if (fraction && fraction.implementScore) {
        implementScore += Number(fraction.implementScore);
      }

      if (fraction && fraction.satisWeighted) {
        satisWeighted += Number(fraction.satisWeighted);
      }

      if (fraction && fraction.satisScore) {
        satisScore += Number(fraction.satisScore);
      }
    }

    return {
      implementWeighted,
      implementScore,
      satisWeighted,
      satisScore
    };
  }

  @computed
  get label() {
    const startAt = dayjs(this.auditStartTime).format('YYYY/M/DD HH:mm');
    const endAt = dayjs(this.auditEndTime).format('YYYY/M/DD HH:mm');

    return {
      topicsLength: `題目總數：${this.topics.length}`,
      implementation: `落實度總分數(含加分題)：${this.total.implementScore}/${this.total.implementWeighted}`,
      satisfaction: `滿意度總分(含加分題)：${this.total.satisScore}/${this.total.satisWeighted}`,
      auditStartTime: `開始稽查時間：${startAt}`,
      auditEndTime: `結束稽查時間：${endAt}`
    };
  }

  @computed
  get res() {
    const reply = this.topics.map((vm) => vm.res);

    return {
      reply
    };
  }

  constructor(props) {
    makeObservable(this);
  }

  // 範例參考 ./example.js
  @action
  open = ({ topics = [], auditStartTime = null, auditEndTime = null }) => {
    this.sourceTopics = topics;
    this.topics = topics.map((item) => new TopicViewModel(item));

    this.auditStartTime = auditStartTime;
    this.auditEndTime = auditEndTime;

    this.modalVM.show();

    console.log('查檢表答案', topics);
  };

  @action
  close = () => {
    this.sourceTopics = [];
    this.topics = [];

    this.modalVM.hide();
  };
}

export default CheckFormModalViewModel;
