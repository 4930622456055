import { makeObservable, observable, action, computed } from 'mobx';

class FormDetailViewModel {
  @observable link = '';

  @computed
  get check() {
    return {
      link: !!this.link.trim()
    };
  }

  constructor(props) {
    this.form = props.form;
    this.link = props.form.getFieldValue(['projectDetail', 'quotation']) || '';

    console.log('linklinklinklink', this.link);

    makeObservable(this);
  }

  @action
  onLinkChange = (event) => {
    this.link = event.target.value;
  }
}

export default FormDetailViewModel;
