import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Radio, Checkbox, Input } from 'antd';
import { USER_TYPES } from 'src/global/constants';
import SelfRow from 'src/components/CustomForm/components/SelfRow';
import FormatCell from 'src/components/CustomForm/components/FormatCell';
import SelfFormItem from 'src/components/CustomForm/components/SelfFormItem';
import BorderRightBottom from 'src/components/CustomForm/components/BorderRightBottom';
import VisitorWorksViewModel from './viewModel';
import styles from './styles.module.scss';

// 訪員執案要求
@observer
class VisitorWorks extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new VisitorWorksViewModel(props);
  }

  render() {
    return (
      <BorderRightBottom>
        <SelfRow>
          <FormatCell
            label="消費要求"
            role={USER_TYPES.salesperson.value}
            required
          >
            <SelfFormItem
              name={['visitorWorks', 'shoppingNeeds', 'value']}
              required
            >
              <Checkbox.Group
                size="small"
                options={this.vm.mapOptions.shoppingNeeds}
              />
            </SelfFormItem>

            <div className={styles.shoppingNeedsCommodity}>
              <SelfFormItem
                name={['visitorWorks', 'shoppingNeeds', 'commodity']}
              >
                <Input size="small" maxLength={50} />
              </SelfFormItem>
            </div>

            <div className={styles.shoppingNeedsSpend}>
              <SelfFormItem name={['visitorWorks', 'shoppingNeeds', 'spend']}>
                <Input size="small" maxLength={50} />
              </SelfFormItem>
            </div>
          </FormatCell>

          <FormatCell
            label="影音紀錄要求"
            role={USER_TYPES.salesperson.value}
            required
            offsetBorderLeft
          >
            <SelfFormItem
              name={['visitorWorks', 'mediaRecordNeeds', 'value']}
              required
            >
              <Checkbox.Group
                size="small"
                options={this.vm.mapOptions.mediaRecordNeeds}
              />
            </SelfFormItem>

            <div className={styles.mediaRecordNeedsOtherDesc}>
              <SelfFormItem
                name={['visitorWorks', 'mediaRecordNeeds', 'otherDesc']}
              >
                <Input size="small" maxLength={50} />
              </SelfFormItem>
            </div>
          </FormatCell>
        </SelfRow>

        <SelfRow>
          <FormatCell
            label="稽查消費付款方式"
            role={USER_TYPES.projectManager.value}
          >
            <SelfFormItem name={['visitorWorks', 'paymentMethod', 'value']}>
              <Radio.Group
                size="small"
                options={this.vm.mapOptions.paymentMethods}
              />
            </SelfFormItem>

            <div className={styles.paymentMethodsDesc}>
              <SelfFormItem
                name={['visitorWorks', 'paymentMethod', 'otherDesc']}
              >
                <Input size="small" maxLength={50} />
              </SelfFormItem>
            </div>
          </FormatCell>

          <FormatCell label="統一編號" role={USER_TYPES.projectManager.value}>
            <SelfFormItem name={['visitorWorks', 'uniformNumber', 'value']}>
              <Radio.Group
                size="small"
                options={this.vm.mapOptions.uniformNumberTypes}
              />
            </SelfFormItem>

            <div className={styles.uniformNumber}>
              <SelfFormItem name={['visitorWorks', 'uniformNumber', 'number']}>
                <Input size="small" maxLength={50} />
              </SelfFormItem>
            </div>
          </FormatCell>

          <FormatCell label="退換貨" role={USER_TYPES.projectManager.value}>
            <SelfFormItem name={['visitorWorks', 'returnMethods', 'value']}>
              <Radio.Group
                size="small"
                options={this.vm.mapOptions.returnMethods}
              />
            </SelfFormItem>
          </FormatCell>
        </SelfRow>

        <SelfRow>
          <FormatCell label="稽查時間" role={USER_TYPES.projectManager.value}>
            <SelfFormItem name={['visitorWorks', 'checkDates', 'value']}>
              <Checkbox.Group
                size="small"
                options={this.vm.mapOptions.checkDateTypes}
              />
            </SelfFormItem>

            <div className={styles.checkDatesDescSame}>
              <div className={styles.item}>
                <SelfFormItem
                  name={['visitorWorks', 'checkDates', 'specifiedDateDesc']}
                >
                  <Input size="small" maxLength={50} />
                </SelfFormItem>
              </div>

              <div className={styles.item}>
                <SelfFormItem
                  name={['visitorWorks', 'checkDates', 'doNotCheckDateDesc']}
                >
                  <Input size="small" maxLength={50} />
                </SelfFormItem>
              </div>

              <div className={styles.item}>
                <SelfFormItem
                  name={['visitorWorks', 'checkDates', 'specifiedTimeDesc']}
                >
                  <Input size="small" maxLength={50} />
                </SelfFormItem>
              </div>

              <div className={styles.item}>
                <SelfFormItem
                  name={['visitorWorks', 'checkDates', 'limitTimeDesc']}
                >
                  <Input size="small" maxLength={50} />
                </SelfFormItem>
              </div>
            </div>

            <div className={styles.checkDatesDescAlone}>
              <div className={styles.item}>
                <SelfFormItem
                  name={['visitorWorks', 'checkDates', 'dwellTimeDesc']}
                >
                  <Input size="small" maxLength={50} />
                </SelfFormItem>
              </div>
            </div>
          </FormatCell>

          <FormatCell
            label="受稽人員要求"
            role={USER_TYPES.projectManager.value}
          >
            <SelfFormItem
              name={['visitorWorks', 'inspectedPersonNeed', 'value']}
            >
              <Radio.Group
                size="small"
                options={this.vm.mapOptions.inspectedPersonNeedTypes}
              />
            </SelfFormItem>

            <div className={styles.inspectedPersonNeedDesc}>
              <SelfFormItem
                name={['visitorWorks', 'inspectedPersonNeed', 'specifyDesc']}
              >
                <Input size="small" maxLength={50} />
              </SelfFormItem>

              <SelfFormItem
                name={['visitorWorks', 'inspectedPersonNeed', 'limitDesc']}
              >
                <Input size="small" maxLength={50} />
              </SelfFormItem>
            </div>
          </FormatCell>
        </SelfRow>

        <SelfRow>
          <FormatCell label="投訴要求" role={USER_TYPES.projectManager.value}>
            <SelfFormItem
              name={['visitorWorks', 'complaintRequirements', 'value']}
            >
              <Checkbox.Group
                size="small"
                options={this.vm.mapOptions.complaintRequirements}
              />
            </SelfFormItem>

            <div className={styles.complaintRequirementsDesc}>
              <SelfFormItem
                name={['visitorWorks', 'complaintRequirements', 'onSiteDesc']}
              >
                <Input size="small" maxLength={50} />
              </SelfFormItem>

              <SelfFormItem
                name={['visitorWorks', 'complaintRequirements', 'phoneDesc']}
              >
                <Input size="small" maxLength={50} />
              </SelfFormItem>

              <SelfFormItem
                name={['visitorWorks', 'complaintRequirements', 'networkDesc']}
              >
                <Input size="small" maxLength={50} />
              </SelfFormItem>
            </div>
          </FormatCell>

          <FormatCell
            label="投訴對象要求"
            role={USER_TYPES.projectManager.value}
          >
            <SelfFormItem
              name={['visitorWorks', 'complainantRequirements', 'value']}
            >
              <Radio.Group
                size="small"
                options={this.vm.mapOptions.complainantRequirements}
              />
            </SelfFormItem>

            <div className={styles.complainantRequirementsDesc}>
              <SelfFormItem
                name={[
                  'visitorWorks',
                  'complainantRequirements',
                  'specifyDesc'
                ]}
              >
                <Input size="small" maxLength={50} />
              </SelfFormItem>

              <SelfFormItem
                name={['visitorWorks', 'complainantRequirements', 'limitDesc']}
              >
                <Input size="small" maxLength={50} />
              </SelfFormItem>
            </div>
          </FormatCell>
        </SelfRow>

        <SelfRow>
          <FormatCell label="指定索取物" role={USER_TYPES.projectManager.value}>
            <SelfFormItem name={['visitorWorks', 'claims', 'value']}>
              <Checkbox.Group
                size="small"
                options={this.vm.mapOptions.claims}
              />
            </SelfFormItem>

            <div className={styles.claimsDmDesc}>
              <SelfFormItem name={['visitorWorks', 'claims', 'dmDesc']}>
                <Input size="small" maxLength={50} />
              </SelfFormItem>
            </div>

            <div className={styles.claimsDescSame}>
              <SelfFormItem
                name={['visitorWorks', 'claims', 'businessCardDesc']}
              >
                <Input size="small" maxLength={50} />
              </SelfFormItem>

              <SelfFormItem name={['visitorWorks', 'claims', 'giveawayDesc']}>
                <Input size="small" maxLength={50} />
              </SelfFormItem>

              <SelfFormItem name={['visitorWorks', 'claims', 'otherDesc']}>
                <Input size="small" maxLength={50} />
              </SelfFormItem>
            </div>
          </FormatCell>

          <FormatCell
            label="二次稽查需求"
            role={USER_TYPES.projectManager.value}
          >
            <SelfFormItem name={['visitorWorks', 'againCheckNeeds']}>
              <Input.TextArea size="small" rows={4} maxLength={500} showCount />
            </SelfFormItem>
          </FormatCell>
        </SelfRow>

        <SelfRow>
          <FormatCell
            label="複數場域稽查"
            role={USER_TYPES.projectManager.value}
          >
            <SelfFormItem name={['visitorWorks', 'checkAreas']}>
              <Input.TextArea size="small" rows={6} maxLength={500} showCount />
            </SelfFormItem>
          </FormatCell>
        </SelfRow>
      </BorderRightBottom>
    );
  }
}

VisitorWorks.propTypes = {};

VisitorWorks.defaultProps = {};

export default VisitorWorks;
