import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Tabs } from 'antd';
import withProfile from 'src/components/withProfile';
import withRouter from 'src/components/withRouter';
import CustomHeader from 'src/components/CustomHeader';
import CustomBreadcrumb from 'src/components/CustomBreadcrumb';
import InterviewerDetailPageViewModel from './viewModel';
import styles from './styles.module.scss';
import './reset.scss';

// 老闆, 營業部, PM, 可以編輯
// 業務, 只讀
@observer
class InterviewerDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new InterviewerDetailPageViewModel({
      profile: props.profile,
      router: props.router
    });
  }

  componentDidMount() {
    this.vm.didMount(this.props);
  }

  render() {
    return this.vm.hasData ? (
      <div id="interviewerDetailPage" className={styles.container}>
        <CustomBreadcrumb routes={this.vm.routes} />

        <CustomHeader
          title={this.vm.data.name}
          hasBack
          link={this.vm.backLink}
        />

        <div className={styles.tabContainer}>
          <Tabs items={this.vm.pages} />
        </div>
      </div>
    ) : null;
  }
}

InterviewerDetailPage.propTypes = {
  profile: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired
};

InterviewerDetailPage.defaultProps = {};

export default withProfile(withRouter(InterviewerDetailPage), true);
