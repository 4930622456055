import { makeObservable, observable, action, computed } from 'mobx';
import BigNumber from 'bignumber.js';
import { SITE_TYPES } from 'src/global/constants';
import {
  TOPIC_TYPES,
  ON_SITE_TOPIC_FORMAT,
  OFF_SITE_TOPIC_FORMAT
} from 'src/global/topic';
import FractionAnswerViewModel from '../components/FractionAnswer/viewModel';
import ShortAnswerViewModel from '../components/ShortAnswer/viewModel';

class DataViewModel {
  @observable topics = [];

  // 用場地分類題目
  @computed
  get groupSiteTopics() {
    const onSite = [];
    const offSite = [];

    for (let i = 0; i < this.topics.length; i += 1) {
      const item = this.topics[i];

      if (item.site === SITE_TYPES.onSite.value) {
        onSite.push(item);
      }

      if (item.site === SITE_TYPES.offSite.value) {
        offSite.push(item);
      }
    }

    return {
      onSite,
      offSite
    };
  }

  // 現場題目資料
  @computed
  get onSiteData() {
    const topics = this.groupSiteTopics.onSite;
    const facets = this.groupFacet(topics, ON_SITE_TOPIC_FORMAT);

    return {
      topics,
      facets
    };
  }

  // 非現場題目資料
  @computed
  get offSiteData() {
    const topics = this.groupSiteTopics.offSite;
    const facets = this.groupFacet(topics, OFF_SITE_TOPIC_FORMAT);

    return {
      topics,
      facets
    };
  }


  constructor() {
    makeObservable(this);
  }

  // 選擇使用哪個 vm
  switchTopicVM(item) {
    switch (item.type) {
      case TOPIC_TYPES.fraction.value:
      case TOPIC_TYPES.bonus.value:
        return new FractionAnswerViewModel({
          data: item,
          onDeleted: this.onDeleted
        });

      case TOPIC_TYPES.shortAnswer.value:
        return new ShortAnswerViewModel({
          data: item,
          onDeleted: this.onDeleted
        });

      default:
        return undefined;
    }
  }

  // 輸入多個題目
  @action
  setTopics = (data) => {
    this.topics = data.map((item) => {
      return this.switchTopicVM(item);
    });
  };

  // 依照 facet 分類
  groupFacet = (data, format) => {
    const cloneFormat = JSON.parse(JSON.stringify(format));

    for (let i = 0; i < data.length; i += 1) {
      const item = data[i];
      const facet = cloneFormat[item.facet];
      const inferiorFacet = facet?.inferiorFacets[item.inferiorFacet];

      if (!facet || !inferiorFacet) {
        console.log('-----錯誤的組合-----');
        console.log(item.site);
        console.log(item.facet);
        console.log(item.inferiorFacet);
        console.log('------------------');
      } else {
        inferiorFacet.topics.push(item);
      }
    }

    return Object.values(cloneFormat).map((facet) => {
      // 次構面
      const inferiorFacets = Object.values(facet.inferiorFacets).map((item) => {
        return { ...item, key: `${facet.value}-${item.value}` };
      });

      // 構面
      return {
        value: facet.value,
        label: facet.label,
        // 次構面
        inferiorFacets
      };
    });
  };

  // 新增一個題目
  @action
  onCreated = (data) => {
    const topicVM = this.switchTopicVM(data);

    this.topics.push(topicVM);
  };

  @action
  onDeleted = (id) => {
    this.topics = this.topics.filter((item) => item.id !== id);
  };

  // 檢查題目, 保留錯誤的
  getInvalidTopics = () => {
    console.log('getInvalidTopics');

    return this.topics.filter((topic) => {
      return topic.invalid;
    });
  };
}

export default DataViewModel;
