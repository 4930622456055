import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Form, Input } from 'antd';
import withForm from 'src/components/withForm';
import withRouter from 'src/components/withRouter';
import CustomModal from 'src/components/CustomModal';
import SimpleForm from 'src/components/SimpleForm';
import styles from './styles.module.scss';
import './reset.scss';

@observer
class ApplyModal extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({
      eventId: props.eventId,
      editMode: props.editMode,
      form: props.form,
      router: props.router,
      onConfirmed: props.onConfirmed
    });
  }

  render() {
    return (
      <CustomModal
        className={styles.modal}
        vm={this.vm.modalVM}
        hasHeader
        title="申請修改預估損益"
        hasPadding
        hasFooter
        hasCancel
        confirmText="申請"
        confirmType="primary"
        onConfirm={this.vm.onConfirm}
        disabledConfirm={this.vm.disabled.confirm}
        onCancel={this.vm.modalVM.hide}
      >
        <div id="budgetDetailPageApplyModal">
          <div className={styles.label}>
            <span className={styles.star}>*</span>
            <span>留言（請務必註明「留言人、留言日期時間」）</span>
            <span className={styles.colon}>:</span>
          </div>

          <div className={styles.textAreaContainer}>
            <SimpleForm form={this.vm.form}>
              <Form.Item name="reason" rules={this.vm.required}>
                <Input.TextArea
                  rows={8}
                  disabled={this.props.disabled}
                  maxLength={500}
                  showCount
                />
              </Form.Item>
            </SimpleForm>
          </div>
        </div>
      </CustomModal>
    );
  }
}

ApplyModal.propTypes = {
  vm: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  onConfirmed: PropTypes.func,
  eventId: PropTypes.number,
  editMode: PropTypes.bool,
  disabled: PropTypes.bool.isRequired
};

ApplyModal.defaultProps = {
  onConfirmed: () => {}
};

export default withRouter(withForm(ApplyModal));
