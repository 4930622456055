import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Form, Input, Checkbox, Button } from 'antd';
import ICONS from 'src/global/icons';
import withRouter from 'src/components/withRouter';
import logo from 'src/assets/logo.png';
import LoginPageViewModel from './viewModel';
import styles from './styles.module.scss';
import './reset.scss';

@observer
class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new LoginPageViewModel({
      context: props.context,
      router: props.router
    });
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return (
      <div id="loginPage" className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>柯南國際-神秘客專案管理系統</div>
        </div>

        <div className={styles.mainContent}>
          <div className={styles.topSpace} />

          <div className={styles.logoContainer}>
            <img src={logo} alt="logo" className={styles.logo} />
          </div>

          <div className={styles.formContainer}>
            <Form
              ref={this.vm.form}
              onFinish={this.vm.onFinish}
              disabled={this.vm.isAwait}
            >
              <Form.Item
                name="account"
                rules={this.vm.required.user}
                validateStatus={this.vm.serverError.validateStatus}
                help={this.vm.serverError.help}
              >
                <Input
                  onChange={this.vm.onChangeInput}
                  placeholder="請輸入帳號"
                  prefix={<ICONS.Account className={styles.prefixIcon} />}
                  size="large"
                  maxLength={50}
                />
              </Form.Item>

              <Form.Item
                name="password"
                rules={this.vm.required.password}
                validateStatus={this.vm.serverError.validateStatus}
                help={this.vm.serverError.help}
              >
                <Input
                  type="password"
                  onChange={this.vm.onChangeInput}
                  placeholder="請輸入密碼"
                  prefix={<ICONS.PassWordHide className={styles.prefixIcon} />}
                  size="large"
                  maxLength={50}
                />
              </Form.Item>

              <Form.Item name="remember" valuePropName="checked">
                <Checkbox>
                  <div className={styles.rememberLabel}>記住我</div>
                </Checkbox>
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className={styles.logoInButton}
                  size="large"
                >
                  登入
                </Button>
              </Form.Item>
            </Form>
          </div>

          <div className={styles.bottomSpace} />
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  context: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired
};

LoginPage.defaultProps = {

};

export default withRouter(LoginPage);
