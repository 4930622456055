import {
  makeObservable,
  observable,
  computed,
  action
} from 'mobx';
import { diff } from 'deep-diff';
import CustomModalViewModel from 'src/components/CustomModal/viewModel';
import { CASE_STATUS } from 'src/global/case';

class VisitorReplyMsgViewModel {
  @observable case = null;
  @observable checkParams = null;
  @observable remark = null;
  @observable isAwait = false;

  modalVM = new CustomModalViewModel();

  @computed
  get isShow() {
    switch (this.case.step) {
      case CASE_STATUS.S8.value:
      case CASE_STATUS.S9.value:
      case CASE_STATUS.S10.value:
      case CASE_STATUS.S11.value:
      case CASE_STATUS.S12.value: {
        return true;
      }

      default:
        return false;
    }
  }

  @computed
  get disabled() {

    return {
      textArea: true
    };
  }

  @computed
  get label() {
    return { button: '查看' };
  }

  constructor(props) {
    this.init(props);
    makeObservable(this);
  }

  @action
  init = (props) => {
    this.case = props.case;
    this.checkParams = props.checkParams;
    this.remark = props.case.visitorReply || '無';
  }

  didUpdate = (props, preProps) => {
    const diffCase = diff(props.case, preProps.case);

    if (diffCase) {
      this.init(props);
    }
  }

}

export default VisitorReplyMsgViewModel;
