import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import CustomModal from 'src/components/CustomModal';
import styles from './styles.module.scss';

@observer
class ReasonModal extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
  }

  render() {
    return (
      <CustomModal
        vm={this.props.vm.modalVM}
        hasHeader
        title="申請修改預估損益"
        hasPadding
        hasClose
      >
        <div className={styles.mainContent}>
          <div className={styles.label}>
            <span className={styles.star}>*</span>
            <span>留言（請務必註明「留言人、留言日期時間」）</span>
            <span className={styles.colon}>:</span>
          </div>

          <div className={styles.content}>
            {this.vm.reason}
          </div>
        </div>
      </CustomModal>
    );
  }
}

ReasonModal.propTypes = {
  vm: PropTypes.object.isRequired
};

ReasonModal.defaultProps = {};

export default ReasonModal;
