import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Radio, Input, Checkbox } from 'antd';
import { USER_TYPES } from 'src/global/constants';
import SelfRow from 'src/components/CustomForm/components/SelfRow';
import SelfFormItem from 'src/components/CustomForm/components/SelfFormItem';
import FormatCell from 'src/components/CustomForm/components/FormatCell';
import BorderRightBottom from 'src/components/CustomForm/components/BorderRightBottom';
import VisitorRequirementsViewModel from './viewModel';
import styles from './styles.module.scss';

// 訪員條件
@observer
class VisitorRequirements extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new VisitorRequirementsViewModel(props);
  }

  render() {
    return (
      <BorderRightBottom>
        <SelfRow>
          <FormatCell label="證照" role={USER_TYPES.salesperson.value} required>
            <SelfFormItem name={['visitorRequirements', 'license']} required>
              <Radio.Group size="small" options={this.vm.mapOptions.license} />
            </SelfFormItem>
          </FormatCell>

          <FormatCell label="駕照" role={USER_TYPES.projectManager.value}>
            <SelfFormItem name={['visitorRequirements', 'drivingLicense']}>
              <Radio.Group
                size="small"
                options={this.vm.mapOptions.drivingLicense}
              />
            </SelfFormItem>
          </FormatCell>
        </SelfRow>

        <SelfRow>
          <FormatCell label="良民證" role={USER_TYPES.projectManager.value}>
            <SelfFormItem
              name={['visitorRequirements', 'policeCriminalRecordCertificate']}
            >
              <Radio.Group size="small" options={this.vm.mapOptions.record} />
            </SelfFormItem>
          </FormatCell>

          <FormatCell label="其他" role={USER_TYPES.projectManager.value}>
            <SelfFormItem name={['visitorRequirements', 'other']}>
              <Input size="small" maxLength={50} />
            </SelfFormItem>
          </FormatCell>
        </SelfRow>

        <SelfRow>
          <FormatCell label="性別" role={USER_TYPES.projectManager.value}>
            <SelfFormItem name={['visitorRequirements', 'gender', 'value']}>
              <Radio.Group size="small" options={this.vm.mapOptions.genders} />
            </SelfFormItem>

            <div className={styles.genderOtherInput}>
              <SelfFormItem
                name={['visitorRequirements', 'gender', 'otherDesc']}
              >
                <Input size="small" maxLength={50} />
              </SelfFormItem>
            </div>
          </FormatCell>

          <FormatCell label="年齡" role={USER_TYPES.projectManager.value}>
            <SelfFormItem name={['visitorRequirements', 'age', 'value']}>
              <Radio.Group size="small" options={this.vm.mapOptions.ages} />
            </SelfFormItem>

            <div className={styles.ageDescriptions}>
              <div className={styles.item}>
                <SelfFormItem
                  name={['visitorRequirements', 'age', 'greaterThanDesc']}
                >
                  <Input size="small" addonAfter="歲" maxLength={50} />
                </SelfFormItem>
              </div>

              <div className={styles.item}>
                <SelfFormItem
                  name={['visitorRequirements', 'age', 'lessThanDesc']}
                >
                  <Input size="small" addonAfter="歲" maxLength={50} />
                </SelfFormItem>
              </div>

              <div className={styles.item}>
                <SelfFormItem
                  name={['visitorRequirements', 'age', 'equalToDesc']}
                >
                  <Input size="small" addonAfter="歲" maxLength={50} />
                </SelfFormItem>
              </div>

              <div className={styles.item}>
                <SelfFormItem
                  name={['visitorRequirements', 'age', 'betweenDesc1']}
                >
                  <Input size="small" addonAfter="歲" maxLength={50} />
                </SelfFormItem>

                <div className={styles.label}>到</div>

                <SelfFormItem
                  name={['visitorRequirements', 'age', 'betweenDesc2']}
                >
                  <Input size="small" addonAfter="歲" maxLength={50} />
                </SelfFormItem>
              </div>

              <div className={styles.item}>
                <SelfFormItem
                  name={['visitorRequirements', 'age', 'otherDesc']}
                >
                  <Input size="small" maxLength={50} />
                </SelfFormItem>
              </div>
            </div>
          </FormatCell>
        </SelfRow>

        <SelfRow>
          <FormatCell label="保戶" role={USER_TYPES.projectManager.value}>
            <SelfFormItem name={['visitorRequirements', 'insurers', 'value']}>
              <Checkbox.Group
                size="small"
                options={this.vm.mapOptions.insurers}
              />
            </SelfFormItem>

            <div className={styles.insurerOtherDesc}>
              <SelfFormItem
                name={['visitorRequirements', 'insurers', 'otherDesc']}
              >
                <Input size="small" maxLength={50} />
              </SelfFormItem>
            </div>
          </FormatCell>

          <FormatCell label="會員" role={USER_TYPES.projectManager.value}>
            <SelfFormItem name={['visitorRequirements', 'member']}>
              <Input.TextArea rows={10} maxLength={500} showCount />
            </SelfFormItem>
          </FormatCell>
        </SelfRow>

        <SelfRow>
          <FormatCell label="角色需求" role={USER_TYPES.projectManager.value}>
            <SelfFormItem name={['visitorRequirements', 'roleNeeds', 'value']}>
              <Checkbox.Group
                size="small"
                options={this.vm.mapOptions.roleNeeds}
              />
            </SelfFormItem>

            <div className={styles.roleNeedDescriptions}>
              <div className={styles.item}>
                <SelfFormItem
                  name={['visitorRequirements', 'roleNeeds', 'jobDesc']}
                >
                  <Input size="small" maxLength={50} />
                </SelfFormItem>
              </div>

              <div className={styles.item}>
                <SelfFormItem
                  name={['visitorRequirements', 'roleNeeds', 'characterDesc']}
                >
                  <Input size="small" maxLength={50} />
                </SelfFormItem>
              </div>

              <div className={styles.item}>
                <SelfFormItem
                  name={['visitorRequirements', 'roleNeeds', 'mateDesc']}
                >
                  <Input size="small" maxLength={50} />
                </SelfFormItem>
              </div>
            </div>
          </FormatCell>

          <FormatCell label="具備產品要求" role={USER_TYPES.projectManager.value}>
            <SelfFormItem name={['visitorRequirements', 'deviceNeeds', 'value']}>
              <Checkbox.Group
                size="small"
                options={this.vm.mapOptions.deviceNeeds}
              />
            </SelfFormItem>

            <div className={styles.deviceNeedDescriptions}>
              <div className={styles.item}>
                <SelfFormItem
                  name={['visitorRequirements', 'deviceNeeds', 'phoneDesc']}
                >
                  <Input size="small" maxLength={50} />
                </SelfFormItem>
              </div>

              <div className={styles.item}>
                <SelfFormItem
                  name={['visitorRequirements', 'deviceNeeds', 'carDesc']}
                >
                  <Input size="small" maxLength={50} />
                </SelfFormItem>
              </div>

              <div className={styles.item}>
                <SelfFormItem
                  name={['visitorRequirements', 'deviceNeeds', 'motorDesc']}
                >
                  <Input size="small" maxLength={50} />
                </SelfFormItem>
              </div>

              <div className={styles.item}>
                <SelfFormItem
                  name={['visitorRequirements', 'deviceNeeds', 'otherDesc']}
                >
                  <Input size="small" maxLength={50} />
                </SelfFormItem>
              </div>
            </div>
          </FormatCell>
        </SelfRow>
      </BorderRightBottom>
    );
  }
}

VisitorRequirements.propTypes = {};

VisitorRequirements.defaultProps = {};

export default VisitorRequirements;
