import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction
} from 'mobx';
import dayjs from 'dayjs';
import { diff } from 'deep-diff';
import { PROJECT_STATUS } from 'src/global/constants';
import CustomModalViewModel from 'src/components/CustomModal/viewModel';
import ProjectServices from 'src/services/ProjectServices';
import ErrorServices from 'src/services/ErrorServices';
import BigNumber from 'bignumber.js';
import { message } from 'antd';

class FormatItemViewModel {
  @observable data = null;
  @observable checkParams = null;
  @observable isAwait = false;
  @observable version = '1.0';

  onChanged = null;

  modalVM = new CustomModalViewModel();

  form = null;

  @computed
  get isEdit() {
    switch (this.data.step) {
      case PROJECT_STATUS.S1.value:
      case PROJECT_STATUS.S2.value:
      case PROJECT_STATUS.S4.value:
      case PROJECT_STATUS.S5.value:
      case PROJECT_STATUS.S6.value:
      case PROJECT_STATUS.S7.value:
        return this.checkParams.isHandlerOrBoss;

      default:
        return false;
    }
  }

  @computed
  get label() {
    return {
      button: this.isEdit ? '編輯' : '查看'
    };
  }

  @computed
  get disabled() {
    return {
      form: this.isAwait || this.checkParams.isEnd || !this.isEdit,
      modal: this.checkParams.isEnd || !this.isEdit
    };
  }

  constructor(props) {
    this.router = props.router;
    this.form = props.form;
    this.onChanged = props.onChanged;

    this.init(props);

    makeObservable(this);
  }

  @action
  init = (props) => {
    // 專案資料
    this.data = props.data;
    // 共用判斷資料
    this.checkParams = props.checkParams;
  };

  didMount = () => {
    if (!this.isAwait) {
      this.getProjectSpecificationAPI();
    }
  };

  didUpdate = (props, preProps) => {
    const diffData = diff(props.data, preProps.data);
    const diffCheckParams = diff(props.checkParams, preProps.checkParams);

    if (diffData || diffCheckParams) {
      this.init(props);
    }
  };

  onSave = async () => {
    this.postProjectSpecificationAPI();
  };

  @action
  setFormData = (props) => {
    const data = props.data;
    const version = props.version;

    this.version = version || this.version;

    switch (this.version) {
      case '1.0': {
        const formData = data;
        const overallDate = formData?.projectDetail?.overallDate;
        const projectDetail = {
          ...formData?.projectDetail,
          overallDate: overallDate?.map((item) => dayjs(item))
        };
        const waveTimes = formData?.waveTimes?.map((item) => {
          const startAt = item.startAt ? dayjs(item.startAt) : null;
          const endAt = item.endAt ? dayjs(item.endAt) : null;

          return {
            startAt,
            endAt
          };
        });

        const waveDetails = formData?.waveDetails?.map((waveDetail) => {
          const runDate = waveDetail.runDate?.map((item) => dayjs(item));
          const wOverallDate = waveDetail.overallDate
            ? dayjs(waveDetail.overallDate)
            : null;
          const handOverDeadlineDate = waveDetail.handOverDeadlineDate
            ? dayjs(waveDetail.handOverDeadlineDate)
            : null;

          return {
            ...waveDetail,
            runDate,
            overallDate: wOverallDate,
            handOverDeadlineDate,
            handOverItemDates: waveDetail.handOverItemDates?.map((item) => {
              const date = item.date ? dayjs(item.date) : null;

              return { ...item, date };
            })
          };
        });

        this.form.setFieldsValue({
          ...formData,
          projectDetail,
          waveTimes,
          waveDetails
        });

        break;
      }

      default:
    }
  };

  getPostData = async () => {
    const formData = await this.form.validateFields();

    const overallDate = formData.projectDetail.overallDate;
    const projectDetail = {
      ...formData.projectDetail,
      overallDate: overallDate.map((item) => item.toISOString())
    };

    const waveTimes = formData.waveTimes?.map((item) => {
      return {
        startAt: item.startAt?.toISOString(),
        endAt: item.endAt?.toISOString()
      };
    });

    const waveDetails = formData.waveDetails?.map((waveDetail) => {
      return {
        ...waveDetail,
        runDate: waveDetail.runDate?.map((item) => dayjs(item).toISOString()),
        overallDate: waveDetail.overallDate?.toISOString(),
        handOverDeadlineDate: waveDetail.handOverDeadlineDate?.toISOString(),
        handOverItemDates: waveDetail.handOverItemDates?.map((item) => {
          return { ...item, date: item.date?.toISOString() };
        })
      };
    });

    const content = {
      version: this.version,
      data: { ...formData, projectDetail, waveTimes, waveDetails }
    };

    const caseAmount = this.getCaseAmount(formData.caseAmount);

    console.log('formData', content);

    return {
      projectId: this.data.projectId,
      startTime: formData.projectDetail.overallDate[0].format('YYYY-MM-DD'),
      stopTime: formData.projectDetail.overallDate[1].format('YYYY-MM-DD'),
      content: JSON.stringify(content),
      caseAmount
    };
  };

  getFormErrorMessage = (error) => {
    switch (error.errorFields[0].name[0]) {
      case 'projectDetail':
        return '專案總規格尚未填寫完成, 請重新檢視';

      case 'visitorRequirements':
        return '訪員條件尚未填寫完成, 請重新檢視';

      case 'visitorWorks':
        return '訪員執案要求尚未填寫完成, 請重新檢視';

      default:
        return '有必填欄位尚未填寫完成, 請重新檢視';
    }
  };

  // 案件數量
  getCaseAmount = (data) => {
    const onSite = data.onSite ? Number(data.onSite) : 0;
    const offSite = data.offSite ? Number(data.offSite) : 0;
    const other = data.other ? Number(data.other) : 0;

    return {
      onSite,
      offSite,
      other
    };
  };

  // 讀取總規格表
  @action
  getProjectSpecificationAPI = async () => {
    this.isAwait = true;

    try {
      const projectId = this.data.projectId;
      const res = await ProjectServices.getProjectSpecification({ projectId });

      runInAction(() => {
        const data = JSON.parse(res.data);

        this.setFormData(data);

        console.log('getProjectSpecification', data);
      });
    } catch (error) {
      const msg = ErrorServices.getProjectSpecification(error);

      console.log('FormatItemViewModel', 'getProjectSpecification', msg);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };

  @action
  postProjectSpecificationAPI = async () => {
    this.isAwait = true;

    try {
      const data = await this.getPostData();
      const res = await ProjectServices.postProjectSpecification(data);

      this.modalVM.hide();
    } catch (error) {
      const msg = ErrorServices.postProjectSpecification(error);

      if (error.errorFields?.length) {
        const formMsg = this.getFormErrorMessage(error);

        message.error(formMsg, 5);
      }

      console.log(
        'FormatItemViewModel',
        'postProjectSpecification',
        msg,
        error
      );
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default FormatItemViewModel;
