import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Form, Button, Input, Select } from 'antd';
import withForm from 'src/components/withForm';
import CustomDrawer from 'src/components/CustomDrawer';
import SimpleForm from 'src/components/SimpleForm';
import AddButton from 'src/components/AddButton';
import ContactsTable from '../ContactsTable';
import styles from './styles.module.scss';

@observer
class ClientDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({
      form: props.form,
      onCreated: props.onCreated,
      profile: props.profile
    });
  }

  renderDisabledButton = () => {
    switch (this.vm.disabledClient) {
      case true:
        return (
          <Button
            type="primary"
            className={styles.successButton}
            onClick={this.vm.onUnDisabled}
            disabled={this.vm.disabled.disabled}
          >
            啟用
          </Button>
        );

      default:
        return (
          <Button
            type="primary"
            onClick={this.vm.onDisabled}
            disabled={this.vm.disabled.disabled}
          >
            停用
          </Button>
        );
    }
  };

  renderEditButton = () => {
    switch (this.vm.isEditing) {
      case true:
        return (
          <Button
            type="primary"
            onClick={this.vm.onSave}
            disabled={this.vm.isAwait}
          >
            儲存
          </Button>
        );

      default:
        return (
          <Button
            type="primary"
            onClick={this.vm.onEditing}
            disabled={this.vm.isAwait}
          >
            編輯
          </Button>
        );
    }
  };

  render() {
    return (
      <CustomDrawer
        vm={this.vm.drawerVM}
        title={this.vm.label.title}
        width={1240}
        destroyOnClose
      >
        <div className={styles.mainContent}>
          <div className={styles.mainOperate}>{this.renderEditButton()}</div>

          <div className={styles.formContainer}>
            <SimpleForm
              form={this.vm.form}
              labelCol={4}
              disabled={this.vm.disabled.form}
            >
              <Form.Item
                label="企業簡稱"
                name="companyNumber"
                rules={this.vm.rules.required}
              >
                <Input placeholder="請輸入" maxLength={50} />
              </Form.Item>

              <Form.Item
                label="企業全名"
                name="companyName"
                rules={this.vm.rules.required}
              >
                <Input placeholder="請輸入" maxLength={50} />
              </Form.Item>

              <Form.Item
                label="產業類別"
                name="propertyType"
                rules={this.vm.rules.required}
              >
                <Select options={this.vm.options.types} placeholder="請輸入" />
              </Form.Item>

              <Form.Item
                label="密碼"
                name="password"
                rules={this.vm.rules.password}
              >
                <Input placeholder="請輸入" maxLength={50} />
              </Form.Item>

              <Form.Item label="登記地址" name="address">
                <Input placeholder="請輸入" maxLength={50} />
              </Form.Item>

              <Form.Item
                label="代表電話"
                name="phone"
                rules={this.vm.rules.required}
              >
                <Input placeholder="請輸入" maxLength={50} />
              </Form.Item>

              <Form.Item label="網址" name="path">
                <Input placeholder="請輸入" maxLength={200} />
              </Form.Item>

              <Form.Item
                label="負責人"
                name="principalName"
                rules={this.vm.rules.required}
              >
                <Input placeholder="請輸入" maxLength={50} />
              </Form.Item>

              <div className={styles.disabledItem}>
                <Form.Item
                  label="統一編號"
                  name="taxId"
                  rules={this.vm.rules.number}
                >
                  <Input
                    disabled={this.vm.check.hasClientId}
                    placeholder="請輸入"
                    maxLength={50}
                  />
                </Form.Item>

                {this.vm.check.hasClientId && (
                  <div className={styles.buttonContainer}>
                    {this.renderDisabledButton()}
                  </div>
                )}
              </div>

              <Form.Item label="合作業務" name="business">
                <Input placeholder="請輸入" maxLength={50} />
              </Form.Item>

              <Form.Item label="其他合作業務" name="otherBusiness">
                <Input placeholder="請輸入" maxLength={50} />
              </Form.Item>

              <Form.Item label="付款方式" name="payment">
                <Input placeholder="請輸入" maxLength={50} />
              </Form.Item>
            </SimpleForm>
          </div>

          <div className={styles.windowsContainer}>
            <div className={styles.header}>
              <div className={styles.label}>往來窗口:</div>

              <AddButton
                label="新增窗口"
                ghost
                onClick={this.vm.onCreateContact}
                disabled={this.vm.disabled.contacts}
              />
            </div>

            <ContactsTable
              data={this.vm.contacts}
              onDeleteContact={this.vm.onDeleteContact}
              disabled={this.vm.disabled.contacts}
            />
          </div>
        </div>
      </CustomDrawer>
    );
  }
}

ClientDrawer.propTypes = {
  vm: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  onCreated: PropTypes.func.isRequired,
  profile: PropTypes.func.isRequired
};

ClientDrawer.defaultProps = {};

export default withForm(ClientDrawer);
