import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import withRouter from 'src/components/withRouter';
import FractionSection from '../FractionSection';
import BarCharts from '../BarCharts';
import SelfLineChart from '../SelfLineChart';
import DateDrawer from '../DateDrawer';
import DetailViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class Detail extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new DetailViewModel({
      router: props.router,
      profile: props.profile,
      projectId: props.projectId
    });
  }

  componentDidMount() {
    this.vm.didMount();
  }

  componentDidUpdate(preProps) {
    this.vm.didUpdate(this.props, preProps);
  }

  render() {
    return (
      <>
        <div className={styles.container}>
          <FractionSection
            dataVM={this.vm.dataVM}
            implement={this.vm.label.total.implement}
            satisfaction={this.vm.label.total.satisfaction}
            onClickWeights={this.vm.onClickWeights}
            disabledWeights={this.vm.dataVM.disabled.weights}
          />

          <div className={styles.intervalButtonContainer}>
            <Button type="primary" onClick={this.vm.onOpenDateDrawer}>
              設定日期區段
            </Button>
          </div>

          {this.vm.check.hasDates && (
            <BarCharts
              barCharts={this.vm.dataVM.barChartsData}
              fullMarks={this.vm.dataVM.fullMarks}
              tickCount={this.vm.dataVM.tickCount}
            />
          )}

          {this.vm.check.hasDates && (
            <div className={styles.lineChartContainer}>
              <div className={styles.title}>
                平均總分、落實度、滿意度與構面落實度折線圖
              </div>

              <div className={styles.chartContainer}>
                <SelfLineChart
                  data={this.vm.dataVM.barLineData}
                  lineChartWidth={this.vm.dataVM.lineChartWidth}
                  fullMarks={this.vm.dataVM.fullMarks}
                  tickCount={this.vm.dataVM.tickCount}
                />
              </div>
            </div>
          )}
        </div>

        <DateDrawer vm={this.vm.dateDrawerVM} onChanged={this.vm.reset} />
      </>
    );
  }
}

Detail.propTypes = {
  router: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  projectId: PropTypes.string
};

Detail.defaultProps = {
  projectId: null
};

export default withRouter(Detail);
