import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import dayjs from 'dayjs';
import { exportCheckForm } from 'src/global/export';
import { downloadCSV } from 'src/global/methods';
import { USER_TYPES } from 'src/global/constants';
import CheckFormServices from 'src/services/CheckFormServices';
import UserServices from 'src/services/UserServices';
import ErrorServices from 'src/services/ErrorServices';
import CheckFormModalViewModel from 'src/components/CheckFormModal/viewModel';
import CheckFormViewModel from './viewModels/CheckFormViewModel';

class CheckFormsViewModel {
  project = null;

  @observable profile = null;
  @observable topics = [];
  @observable data = [];

  @observable isAwait = false;

  checkFormModalVM = new CheckFormModalViewModel();

  @computed
  get check() {
    return {
      edit: this.profile?.type === USER_TYPES.salesperson.value
    };
  }

  @computed
  get disabled() {
    const checkActive = this.data.filter((vm) => vm.isActive);

    return {
      export: this.isAwait || checkActive.length < 2,
      toggleAll: this.isAwait
    };
  }

  @computed
  get disabledViewable() {
    const checkActive = this.data.filter((vm) => vm.isActive);
    const checkViewable = checkActive.filter((vm) => !vm.isCustomerViewable);

    return this.isAwait || checkActive.length < 2 || !checkViewable.length;
  }

  @computed
  get toggleAll() {
    if (!this.data.length) {
      return false;
    }

    return this.data.every((item) => item.isActive);
  }

  @computed
  get formExport() {
    const topics = JSON.parse(JSON.stringify(this.topics)).map((item) => {
      return { ...item, checkFormReply: [] };
    });
    const filterCheckForms = this.data.filter((item) => item.isActive);

    filterCheckForms.forEach((vm) => {
      const replies = vm.checkFormReply;

      replies.forEach((reply) => {
        const findTopic = topics.find((topic) => topic.id === reply.itemId);

        if (findTopic) {
          const cloneReply = {
            ...reply,
            targetName: vm.name,
            auditStartTime: vm.auditStartTime,
            auditEndTime: vm.auditEndTime
          };

          findTopic.checkFormReply.push(cloneReply);
        }
      });
    });

    return topics;
  }

  constructor(props) {
    this.profile = props.profile;
    this.project = props.project;

    makeObservable(this);
  }

  didMount = async () => {
    if (!this.isAwait) {
      await this.getCheckFormAPI();
      await this.getListLoop();
    }
  };

  @action
  didUpdate = (props, preProps) => {
    if (props.project && props.project !== preProps.project) {
      this.project = props.project;
      this.reset();
    }
  };

  onExport = () => {
    const dataFormat = exportCheckForm({
      data: this.formExport,
      auditStartTime: true,
      auditEndTime: true,
      implementationTotal: true,
      satisfactionTotal: true
    });
    const today = dayjs().format('YYYYMMDD');
    const fileName = `${this.project.name}_${today}`;

    downloadCSV({ data: dataFormat, fileName });
  };

  @action
  onDisabled = async () => {
    this.putUserCaseCustomerViewableByIdsAPI();
  };

  @action
  onToggleAllChange = (event) => {
    this.data.forEach((vm) => vm.setActive(event.target.checked));
  };

  onCheckFormModalOpen = (vm) => {
    this.checkFormModalVM.open({
      topics: vm.mapTopics,
      auditStartTime: vm.auditStartTime,
      auditEndTime: vm.auditEndTime
    });
  };

  @action
  reset = async () => {
    this.topics = [];
    this.data = [];

    await this.getCheckFormAPI();
    await this.getListLoop();
  };

  @action
  getCheckFormAPI = async () => {
    this.isAwait = true;

    try {
      const res = await CheckFormServices.getCheckFormItem({
        projectId: this.project.id
      });

      runInAction(() => {
        this.topics = res.data;
      });
    } catch (error) {
      const msg = ErrorServices.getCheckFormItem(error);

      console.log('getCheckFormItem', error);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };

  @action
  getListLoop = async () => {
    this.isAwait = true;

    try {
      const res = await this.getListLoopAPI();

      runInAction(() => {
        this.data = res.data;
      });
    } catch (error) {
      const msg = ErrorServices.getCheckFormReplyList(error);

      console.log('getCheckFormReplyList', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };

  getListLoopAPI = async (props) => {
    const anchor = props ? props.anchor : undefined;
    const data = props ? props.data : [];

    const res = await CheckFormServices.getCheckFormReplyList({
      projectId: this.project.id,
      anchor
    });

    const mapData = res.data.map(
      (item) =>
        new CheckFormViewModel({
          data: item,
          topics: this.topics,
          project: this.project
        })
    );

    const concatData = data.concat(mapData);

    if (res.anchor !== null) {
      const loop = await this.getListLoopAPI({
        anchor: res.anchor,
        data: concatData
      });

      return loop;
    }

    return {
      data: concatData
    };
  };

  @action
  putUserCaseCustomerViewableByIdsAPI = async () => {
    this.isAwait = true;

    const filterData = this.data.filter(
      (item) => item.isActive && !item.isCustomerViewable
    );
    const ids = filterData.map((item) => item.id);

    try {
      const res = await UserServices.putUserCaseCustomerViewableByIds({
        projectId: this.project.id,
        ids
      });

      filterData.forEach((item) => item.setCustomerViewable(true));
    } catch (error) {
      console.log(error);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };
}

export default CheckFormsViewModel;
