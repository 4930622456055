import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import withRouter from 'src/components/withRouter';
import TagItem from '../TagItem';
import BudgetItemViewModel from './viewModel';

// 專案損益表
@observer
class BudgetItem extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new BudgetItemViewModel({
      data: props.data,
      checkParams: props.checkParams,
      router: props.router
    });
  }

  componentDidUpdate(preProps) {
    this.vm.didUpdate(this.props, preProps);
  }

  render() {
    return this.vm.isShow ? (
      <TagItem
        label="專案損益表（含件酬試算）"
        isFinish={this.vm.isFinish}
        buttonText="前往專案預算管理"
        onClick={this.vm.onToBudget}
      />
    ) : null;
  }
}

BudgetItem.propTypes = {
  data: PropTypes.object.isRequired,
  checkParams: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired
};

export default withRouter(BudgetItem);
