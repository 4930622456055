import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Select, Tabs } from 'antd';
import clsx from 'clsx';
import withRouter from 'src/components/withRouter';
import withProfile from 'src/components/withProfile';
import CustomHeader from 'src/components/CustomHeader';
import ClientDetailPageViewModel from './viewModel';
import EmptyData from '../../components/EmptyData';
import styles from './styles.module.scss';
import './reset.scss';

@observer
class ClientDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new ClientDetailPageViewModel({
      router: props.router,
      profile: props.profile
    });
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return this.vm.isInit ? null : (
      <div className={clsx(['clientDetailPage', styles.container])}>
        <CustomHeader title="客戶管理" paddingBottom />

        {this.vm.check.hasProject ? (
          <div className={styles.selectContainer}>
            <div className={styles.label}>
              <div className={styles.star}>*</div>
              <div className={styles.content}>專案選擇 :</div>
            </div>

            <Select
              dropdownMatchSelectWidth={false}
              options={this.vm.mapProjects}
              value={this.vm.projectId}
              onChange={this.vm.onChangeProjectId}
              disabled={this.vm.isAwait}
            />
          </div>
        ) : null}

        {this.vm.check.hasProject ? (
          <Tabs items={this.vm.pages} />
        ) : (
          <div className={styles.emptyDataContainer}>
            <EmptyData />
          </div>
        )}
      </div>
    );
  }
}

ClientDetailPage.propTypes = {
  router: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired
};

ClientDetailPage.defaultProps = {};

export default withProfile(withRouter(ClientDetailPage), true);
