import dayjs from 'dayjs';

// 查檢表列表

class CheckFormListDataModel {
  constructor(props) {
    // 專案
    this.projectId = String(props.projectId);
    this.projectName = props.projectName;

    // 專案狀態
    this.step = props.step;
    this.stepName = props.stepName;

    // 企業
    this.companyId = String(props.companyId);
    this.companyName = props.companyName;

    // 查檢表是否完成
    this.checkFormCompleted = props.checkFormCompleted;

    // 最後更新時間
    this.checkFormUpdateAt = props.checkFormUpdateAt;

    // ui 使用
    this.id = String(props.projectId);

    this.checkFormUpdateAtFormat = dayjs(props.checkFormUpdateAt).format(
      'YYYY-MM-DD HH:mm:ss'
    );
  }
}

export default CheckFormListDataModel;
