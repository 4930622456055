import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Select, Button } from 'antd';
import withRouter from 'src/components/withRouter';
import WarningModal from 'src/components/WarningModal';
import SelectPMViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class SelectPM extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new SelectPMViewModel({
      data: props.data,
      checkParams: props.checkParams,
      router: props.router
    });
  }

  componentDidMount() {
    this.vm.didMount();
  }

  componentDidUpdate(preProps) {
    this.vm.didUpdate(this.props, preProps);
  }

  render() {
    return this.vm.check.isShow ? (
      <div className={styles.container}>
        <div className={styles.label}>專案PM:</div>

        <Select
          className={styles.select}
          options={this.vm.antList}
          value={this.vm.value}
          onChange={this.vm.onChangeValue}
          optionFilterProp="label"
          showSearch
          disabled={this.vm.disabled.selectPM}
        />

        <Button
          type="primary"
          size="large"
          className={styles.resolveButton}
          onClick={this.vm.onResolve}
          disabled={this.vm.disabled.resolve}
        >
          核准執行
        </Button>

        <WarningModal
          vm={this.vm.modalVM}
          content="送出後無法收回, 確定要送出嗎?"
          onConfirm={this.vm.onConfirm}
        />
      </div>
    ) : null;
  }
}

SelectPM.propTypes = {
  data: PropTypes.object.isRequired,
  checkParams: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired
};

export default withRouter(SelectPM);
