import React from 'react';
import PropTypes from 'prop-types';
import { observer, Observer } from 'mobx-react';
import { Cascader, Input, Table, Checkbox, Popover, Button } from 'antd';
import { TOPIC_TYPES } from 'src/global/topic';
import CustomDrawer from 'src/components/CustomDrawer';
import CustomTable from 'src/components/CustomTable';
import CustomPopover from 'src/components/CustomPopover';
import styles from './styles.module.scss';

@observer
class CreateTopicDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({ projectId: props.projectId });
  }

  render() {
    return (
      <CustomDrawer vm={this.vm.drawerVM} title="從題庫中選擇">
        <div className={styles.minContent}>
          <div className={styles.cascaderSearch}>
            <div className={styles.label}>
              <div className={styles.star}>*</div>
              <div className={styles.content}>搜尋類型與構面: </div>
            </div>

            <div className={styles.cascaderContainer}>
              <Cascader
                className={styles.cascader}
                options={this.vm.antCascader}
                value={this.vm.select}
                onChange={this.vm.onChangeSelect}
                disabled={this.vm.isAwait}
              />
            </div>
          </div>

          <div className={styles.inputSearch}>
            <div className={styles.inputContainer}>
              <Input.Search
                className={styles.search}
                value={this.vm.search}
                onChange={this.vm.onChangeSearch}
                onSearch={this.vm.onSearch}
                disabled={this.vm.isAwait}
                placeholder="請輸入題目名稱"
              />
            </div>
          </div>

          <div className={styles.resContainer}>
            <div>搜尋結果</div>
            <div>{this.vm.label.total}</div>
          </div>

          <CustomTable data={this.vm.searchData} hasOverflow>
            <Table.Column
              width={100}
              title="選擇"
              align="center"
              render={(item) => {
                return (
                  <Observer>
                    {() => {
                      const checked = this.vm.createId === item.id;

                      return (
                        <Checkbox
                          checked={checked}
                          onChange={(event) => {
                            this.vm.onChangeChecked(event, item.id);
                          }}
                          disabled={this.vm.isAwait}
                        />
                      );
                    }}
                  </Observer>
                );
              }}
            />

            <Table.Column
              width={360}
              title="題目名稱"
              render={(item) => {
                return <div className={styles.topic}>{item.topic}</div>;
              }}
            />

            <Table.Column
              width={100}
              title="題目型態"
              render={(item) => {
                return TOPIC_TYPES[item.type].label;
              }}
            />

            <Table.Column
              width={150}
              title="操作"
              render={(item) => {
                return (
                  <CustomPopover
                    placement="bottomRight"
                    title={item.topic}
                    content={item.content}
                    trigger="click"
                  >
                    <Button>查看題目詳情</Button>
                  </CustomPopover>
                );
              }}
            />
          </CustomTable>

          <div className={styles.submitContainer}>
            <Button
              className={styles.button}
              type="primary"
              onClick={this.vm.onCreate}
              disabled={this.vm.disabledCreate}
            >
              帶入
            </Button>
          </div>
        </div>
      </CustomDrawer>
    );
  }
}

CreateTopicDrawer.propTypes = {
  vm: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired
};

CreateTopicDrawer.defaultProps = {};

export default CreateTopicDrawer;
