import qs from 'qs';
import BigNumber from 'bignumber.js';
import Papa from 'papaparse';
import dayjs from 'dayjs';
import { papaConfig } from './config';
import {
  ROUTES,
  ROUTE_SEARCH_KEYS,
  RE_INTERVIEWER_ACCOUNT,
  RE_STAFF_ACCOUNT,
  RE_CLIENT_ACCOUNT,
  USER_TYPES,
  SITE_TYPES
} from './constants';
import {
  TOPIC_TYPES,
  TOPIC_FACETS,
  TOPIC_INFERIOR_FACETS,
  ON_SITE_TOPIC_FORMAT,
  OFF_SITE_TOPIC_FORMAT
} from './topic';
import { DEV_ROUTES, VISITOR_TYPE } from './dev';

// route function
export const routerMethods = {
  /**
   *  讀取 route 的 search 的資料
   * @param {object} router react-router
   * @param {string[]} ang ROUTE_SEARCH_KEYS
   * @returns {object} route data
   */
  getSearch(router, ang) {
    const res = {};

    ang.forEach((key) => {
      // value || null
      const value = router.searchParams[0]?.get(key);

      // 當前表格第幾頁
      if (key === ROUTE_SEARCH_KEYS.anchor) {
        res[ROUTE_SEARCH_KEYS.anchor] = value || 0;
      } else {
        // 其他 search
        res[key] = value;
      }
    });

    return res;
  },

  /**
   * 將 search 資料放到 route 上
   * @param {object} router react-router
   * @param {string} route ROUTES
   * @param {object} data {SEARCH.key: value}
   */
  setSearch({ router, route = undefined, data, replace = false }) {
    const resRoute = route ? `/${route}` : undefined;
    const search = qs.stringify(data);

    router.navigate({ route: resRoute, search, replace });
  },

  // 主要路徑
  getMainRoute(route) {
    return route.split('/').filter((item) => !!item)[0];
  },

  // 麵包屑
  getBreadcrumb(router) {}
};

// 驗證 function
export const reMethods = {
  /**
   * 訪員帳號驗證
   */
  ia(value) {
    return RE_INTERVIEWER_ACCOUNT.test(value);
  },

  /**
   * 員工帳號驗證
   */
  sa(value) {
    return RE_STAFF_ACCOUNT.test(value);
  },

  /**
   * 客戶(企業)帳號驗證
   */
  ca(value) {
    return RE_CLIENT_ACCOUNT.test(value);
  },

  /**
   * 必填
   */
  req(value) {
    const trimValue = value.trim();

    if (!trimValue) {
      return false;
    }

    return true;
  }
};

// big number methods
export const bigNumberMethods = {
  /**
   * 千分位符號
   */
  toFormat(value, fixed = 2) {
    return BigNumber(value, BigNumber.ROUND_CEIL).toFormat(fixed);
  },

  /**
   * 刪除小數
   */
  toFixed: (value, fixed = 0) => {
    const b = new BigNumber(value);

    return b.toFixed(fixed);
  }
};

// 本地資料存取
export const localStorageMethods = {
  get(key) {
    return JSON.parse(localStorage.getItem(key));
  },

  set(key, value) {
    const resValue = JSON.stringify(value);

    localStorage.setItem(key, resValue);
  },

  remove(key) {
    localStorage.removeItem(key);
  }
};

export const downloadCSV = ({ data = [], fileName = 'export' }) => {
  // 將 json 轉為 csv 格式.
  const csv = Papa.unparse(data, papaConfig.export);

  const file = new Blob(['\ufeff', csv], { type: 'text/csv' });
  const element = document.createElement('a');

  element.href = URL.createObjectURL(file);
  element.download = `${fileName}.csv`;
  element.click();
};

// 依照角色顯示頁面
export const getRoutes = (type) => {
  switch (type) {
    // 老闆
    case USER_TYPES.boss.value:
      return (
        Object.values(ROUTES)
          // 刪除 login
          .filter((item) => item.value)
      );

    // 營管部
    case USER_TYPES.operationsManagement.value:
      return [
        // 公告
        ROUTES.announcement,
        // 訪員管理
        ROUTES.interviewer,
        // 訪員管理, 訪員詳情
        ROUTES.interviewerDetail,
        // 員工管理
        ROUTES.staff,
        // 立案申請
        ROUTES.proposal,
        // 立案申請與管理, 專案詳情
        ROUTES.proposalDetail,
        // 派案與執行管理
        ROUTES.execute,
        // 派案與執行管理, 專案(project)詳情
        ROUTES.executeProjectDetail,
        // 派案與執行管理, 案件(case)詳情
        ROUTES.executeCaseDetail,
        // 結案管理
        ROUTES.close,
        // 結案管理, 專案(project)詳情
        ROUTES.closeProjectDetail,
        // 結案管理, 案件(case)詳情
        ROUTES.closeCaseDetail,
        // 專案請款與預算管理,
        ROUTES.budget,
        // 專案請款與預算管理, 預算詳情
        ROUTES.budgetDetail,
        // 專案查詢
        ROUTES.projectSearch,
        // 專案查詢, 專案(project)詳情
        ROUTES.projectSearchProjectDetail,
        // 專案查詢, 案件(case)詳情
        ROUTES.projectSearchCaseDetail,
        // 客戶管理
        ROUTES.client,
        // 客戶管理, 統計圖表
        ROUTES.clientDetail,
        // 專案通知
        ROUTES.notification
      ];

    // 業務
    case USER_TYPES.salesperson.value:
      return [
        // 公告
        ROUTES.announcement,
        // 訪員管理
        ROUTES.interviewer,
        // 訪員管理, 訪員詳情
        ROUTES.interviewerDetail,
        // 立案申請
        ROUTES.proposal,
        // 立案申請與管理, 專案詳情
        ROUTES.proposalDetail,
        // 專案查詢
        ROUTES.projectSearch,
        // 專案查詢, 專案詳情
        ROUTES.projectSearchProjectDetail,
        // 專案查詢, 案件詳情
        ROUTES.projectSearchCaseDetail,
        // 檢查表管理
        ROUTES.checklist,
        // 檢查表管理, 檢查表詳情
        ROUTES.checklistDetail,
        // 訪客問卷管理
        ROUTES.questionnaire,
        // 客戶管理
        ROUTES.client,
        // 客戶管理, 統計圖表
        ROUTES.clientDetail,
        // 專案通知
        ROUTES.notification
      ];

    // PM
    case USER_TYPES.projectManager.value:
      return [
        // 公告
        ROUTES.announcement,
        // 訪員管理
        ROUTES.interviewer,
        // 訪員管理, 訪員詳情
        ROUTES.interviewerDetail,
        // 派案與執行管理
        ROUTES.execute,
        // 派案與執行管理, 專案(project)詳情
        ROUTES.executeProjectDetail,
        // 派案與執行管理, 案件(case)詳情
        ROUTES.executeCaseDetail,
        // 結案管理
        ROUTES.close,
        // 結案管理, 專案(project)詳情
        ROUTES.closeProjectDetail,
        // 結案管理, 案件(case)詳情
        ROUTES.closeCaseDetail,
        // 專案查詢
        ROUTES.projectSearch,
        // 專案查詢, 專案詳情
        ROUTES.projectSearchProjectDetail,
        // 專案查詢, 案件詳情
        ROUTES.projectSearchCaseDetail,
        // 檢查表管理
        ROUTES.checklist,
        // 檢查表管理, 檢查表詳情
        ROUTES.checklistDetail,
        // 訪客問卷管理
        ROUTES.questionnaire,
        // 客戶管理
        ROUTES.client,
        // 客戶管理, 統計圖表
        ROUTES.clientDetail,
        // 專案通知
        ROUTES.notification
      ];

    // 企業
    case USER_TYPES.company.value:
      return [
        // 客戶管理, 統計圖表
        ROUTES.clientDetail
      ];

    // 訪客
    case VISITOR_TYPE:
      return [DEV_ROUTES.reply];

    default:
      return [];
  }
};

// 匯入時, 最少一個欄位有資料
export const checkImportData = (data) => {
  return !!Object.values(data).find((item) => {
    const isString = typeof item === 'string';

    return isString ? !!item.trim() : false;
  });
};

// 縣市地區換字
export const replaceCounty = (val = '') => {
  const checkVal = !!val?.trim();

  if (checkVal) {
    const reg = /台/g;
    const rev = '臺';

    return val.replace(reg, rev);
  }

  return null;
};
