class CompanyDetailDataModel {
  constructor(props) {
    // 停用狀態
    this.isDisabled = props.isDisabled;

    this.accountId = props.accountId;

    // 企業編號
    this.enterpriseNumber = props.enterpriseNumber;

    // 企業全名
    this.name = props.name;

    // 產業類別
    this.type = props.type;

    // 地址
    this.address = props.address;

    // 代表電話
    this.phone = props.phone;

    // 網址
    this.path = props.path;

    // 負責人
    this.principalName = props.principalName;

    // 統一編號
    this.taxId = props.taxId;

    // 合作業務
    this.business = props.business;
    this.otherBusiness = props.otherBusiness;

    // 付款方式
    this.payment = props.payment;

    // 窗口
    this.companyContact = props.companyContact.map((item) => {
      return {
        // 公司?
        companyId: item.companyId,
        id: String(item.id),
        // 類別
        type: item.type,
        // 部門單位
        sector: item.sector,
        name: item.name,
        // 職稱
        title: item.title,
        telephone: item.telephone,
        phone: item.phone,
        email: item.email
      };
    });

    // 專案列表
    this.project = props.project.map((item) => {
      return {
        id: String(item.id),
        name: item.name
      };
    });
  }
}


export default CompanyDetailDataModel;
