import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { observer } from 'mobx-react';
import SimpleTextArea from 'src/components/SimpleTextArea';
import styles from './styles.module.scss';

function FormTextArea(props) {
  return props.isEdit ? (
    <Form.Item
      className={styles.formItem}
      name={props.name}
      rules={props.required}
    >
      <SimpleTextArea
        autoSize
        maxLength={props.maxLength}
        showCount
      />
    </Form.Item>
  ) : (
    <div className={styles.label}>{props.label}</div>
  );
}

FormTextArea.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.array,
  maxLength: PropTypes.number
};

FormTextArea.defaultProps = {
  label: null,
  required: [],
  maxLength: 500
};

export default observer(FormTextArea);
