import React from 'react';
import PropTypes from 'prop-types';
import { Observer, observer } from 'mobx-react';
import { ROUTES } from 'src/global/constants';
import { Input, Button, Table, Divider } from 'antd';
import withProfile from 'src/components/withProfile';
import CustomHeader from 'src/components/CustomHeader';
import FixedTable from 'src/components/FixedTable';
import CustomLink from 'src/components/CustomLink';
import CustomAnchor from 'src/components/CustomAnchor';
import AddButton from 'src/components/AddButton';
import ClientDrawer from './components/ClientDrawer';
import ClientPageViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class ClientPage extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new ClientPageViewModel({ profile: props.profile });
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return (
      <div className={styles.container}>
        <CustomHeader title={ROUTES.client.label} paddingBottom />

        {!this.vm.isInit && (
          <>
            <div className={styles.functionContainer}>
              <div className={styles.searchContainer}>
                <Input.Search
                  value={this.vm.search}
                  onChange={this.vm.onChangeSearch}
                  onSearch={this.vm.onSearch}
                  placeholder="請輸入企業名稱"
                />
              </div>

              {this.vm.check.create && (
                <AddButton
                  label="新增客戶"
                  onClick={this.vm.clientDrawerVM.onCreateOpen}
                />
              )}
            </div>

            <FixedTable
              dataSource={this.vm.tableData}
              scrollY
              labelHeight={55}
              flex
              hidden
              padding
            >
              <Table.Column
                title="狀態"
                width={65}
                render={(vm) => {
                  return <Observer>{() => vm.label.disabled}</Observer>;
                }}
              />

              <Table.Column
                title="企業簡稱"
                width={140}
                render={(vm) => {
                  return <Observer>{() => vm.companyNumber}</Observer>;
                }}
              />

              <Table.Column
                title="企業全名"
                width={200}
                render={(vm) => {
                  return <Observer>{() => vm.companyName}</Observer>;
                }}
              />

              <Table.Column
                title="統一編號"
                width={130}
                render={(vm) => {
                  return <Observer>{() => vm.taxId}</Observer>;
                }}
              />

              <Table.Column
                title="登記地址"
                width={250}
                render={(vm) => {
                  return <Observer>{() => vm.address}</Observer>;
                }}
              />

              <Table.Column
                title="負責人"
                width={140}
                render={(vm) => {
                  return <Observer>{() => vm.principalName}</Observer>;
                }}
              />

              <Table.Column
                title="聯繫電話"
                width={140}
                render={(vm) => {
                  return <Observer>{() => vm.phone}</Observer>;
                }}
              />

              <Table.Column
                title="付款方式"
                width={140}
                render={(vm) => {
                  return <Observer>{() => vm.payment}</Observer>;
                }}
              />

              <Table.Column
                title="最近更新時間"
                width={140}
                render={(vm) => {
                  return <Observer>{() => vm.label.updatedAtFormat}</Observer>;
                }}
              />

              <Table.Column
                title="操作"
                width={190}
                render={(vm) => {
                  return (
                    <div className={styles.operate}>
                      <CustomLink to={vm.label.link}>專案統計</CustomLink>

                      <Divider type="vertical" />

                      <Button
                        type="link"
                        className={styles.button}
                        onClick={() => {
                          this.vm.clientDrawerVM.onEditOpen(vm);
                        }}
                      >
                        客戶詳情
                      </Button>
                    </div>
                  );
                }}
              />
            </FixedTable>

            <CustomAnchor vm={this.vm.anchorVM} />
          </>
        )}

        <ClientDrawer
          vm={this.vm.clientDrawerVM}
          onCreated={this.vm.onCreated}
          profile={this.props.profile}
        />
      </div>
    );
  }
}

ClientPage.propTypes = {
  profile: PropTypes.object.isRequired
};

ClientPage.defaultProps = {};

export default withProfile(ClientPage, true);
