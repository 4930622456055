import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button, Form, Radio, Input } from 'antd';
import withForm from 'src/components/withForm';
import StyleItem from 'src/components/StyleItem';
import FormModal from 'src/components/FormModal';
import CustomForm from 'src/components/CustomForm';
import SelfRow from 'src/components/CustomForm/components/SelfRow';
import FormatCell from 'src/components/CustomForm/components/FormatCell';
import SelfFormItem from 'src/components/CustomForm/components/SelfFormItem';
import BorderRightBottom from 'src/components/CustomForm/components/BorderRightBottom';
import Template1 from './components/Template1';
import CloseItemViewModel from './viewModel';
import styles from './styles.module.scss';

// 專案結案表
@observer
class CloseItem extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new CloseItemViewModel({
      project: props.project,
      checkParams: props.checkParams,
      form: props.form
    });
  }

  componentDidMount() {
    this.vm.didMount();
  }

  componentDidUpdate(preProps) {
    this.vm.didUpdate(this.props, preProps);
  }

  renderTemplate = () => {
    switch (this.vm.version) {
      case '1.0':
        return <Template1 />;

      default:
        return null;
    }
  }

  render() {
    return this.vm.isShow ? (
      <>
        <StyleItem>
          <span>專案結案表</span>

          <Button type="primary" onClick={this.vm.modalVM.show}>
            {this.vm.label.button}
          </Button>
        </StyleItem>

        <FormModal
          vm={this.vm.modalVM}
          title="專案結案表"
          onSave={this.vm.onSave}
          disableEdit={this.vm.disabled.formModal}
          isAwait={this.vm.isAwait}
        >
          <CustomForm form={this.vm.form} disabled={this.vm.disabled.form}>
            {this.renderTemplate()}
          </CustomForm>
        </FormModal>
      </>
    ) : null;
  }
}

CloseItem.propTypes = {
  project: PropTypes.object.isRequired,
  checkParams: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

CloseItem.defaultProps = {};

export default withForm(CloseItem);
