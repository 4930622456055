import { makeObservable, observable, action, computed } from 'mobx';
import { v4 } from 'uuid';
import BigNumber from 'bignumber.js';
import { RE_NUMBER } from 'src/global/constants';

class IncomeViewModel {
  @observable id = null;
  @observable label = null;

  // 預期損益
  @observable expected = null;
  @observable expectedDesc = '';

  // 結算損益
  @observable result = null;
  @observable resultDesc = '';

  // 外層 store
  store = null;

  // 損益表修改過
  setIsEdit = null;

  // 預估佔比
  @computed
  get expectedRatio() {
    const bRes = new BigNumber(this.expected)
      .dividedBy(this.store.estimatedFee.value)
      .multipliedBy(100)
      .decimalPlaces(2);

    return bRes.isNaN() ? '' : `${bRes.toNumber()}%`;
  }

  // 結算佔比
  @computed
  get resultRatio() {
    const bRes = new BigNumber(this.result)
      .dividedBy(this.store.resultFee.value)
      .multipliedBy(100)
      .decimalPlaces(2);
    return bRes.isNaN() ? '' : `${bRes.toNumber()}%`;
  }

  @computed
  get resExpected() {
    return {
      id: this.id,
      label: this.label,
      fee: this.expected,
      desc: this.expectedDesc
    };
  }

  @computed
  get resResult() {
    return {
      id: this.id,
      label: this.label,
      fee: this.result,
      desc: this.resultDesc
    };
  }

  constructor({ data, store, setIsEdit }) {
    this.id = data.id || v4();
    this.label = data.label;

    this.expected = data.expected || null;
    this.expectedDesc = data.expectedDesc || '';

    this.result = data.result || null;
    this.resultDesc = data.resultDesc || '';

    this.store = store;

    this.setIsEdit = setIsEdit;

    makeObservable(this);
  }

  @action
  onChangeExpected = (event) => {
    const value = event.target.value;
    const isNumber = RE_NUMBER.test(value);
    const check = !value || isNumber;

    if (check) {
      this.expected = event.target.value;

      // 損益表修改過
      this.setIsEdit(true);
    }
  };

  @action
  onExpectedDescChange = (event) => {
    this.expectedDesc = event.target.value;
    this.setIsEdit(true);
  }

  @action
  onChangeResult = (event) => {
    const value = event.target.value;
    const isNumber = RE_NUMBER.test(value);
    const check = !value || isNumber;

    if (check) {
      this.result = event.target.value;

      // 損益表修改過
      this.setIsEdit(true);
    }
  };

  @action
  onResultDescChange = (event) => {
    this.resultDesc = event.target.value;
    this.setIsEdit(true);
  }
}

export default IncomeViewModel;
