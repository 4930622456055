import EmployeeAPIs from 'src/apis/EmployeeAPIs';
import { LIMIT } from 'src/global/constants';
import EmployeeDataModel from 'src/models/response/EmployeeDataModel';
import CookieServices from './CookieServices';

class EmployeeServices {
  // 讀取員工列表
  static async postEmployeeList({ limit = LIMIT, anchor }) {
    const res = await EmployeeAPIs.postEmployeeList({
      token: CookieServices.getToken(),
      limit,
      anchor
    });

    return {
      data: res.data.data.map((item) => new EmployeeDataModel(item)),
      anchor: res.data.anchor
    };
  }

  // 更新員工
  static async putEmployeeUpdate({ id, data }) {
    const res = await EmployeeAPIs.putEmployeeUpdate({
      token: CookieServices.getToken(),
      id,
      data
    });

    return {
      data: res.data
    };
  }
}

export default EmployeeServices;
