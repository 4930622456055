import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction
} from 'mobx';
import UserServices from 'src/services/UserServices';
import { GENDER_TYPES, YES_OR_NO } from 'src/global/constants';
import { PRACTICE_CITIES } from 'src/global/visitor';
import dayjs from 'dayjs';
import ErrorServices from 'src/services/ErrorServices';

class InterviewerViewModel {
  @observable isDisable = false;
  id = null;
  nickName = null;
  name = null;
  @observable gender = null;
  phone = null;
  email = null;
  lineId = null;
  address = null;
  deviceItem = null;
  @observable practiceCity = [];
  @observable tssaDate = null;
  @observable sgsDate = null;
  @observable consultantDate = null;

  @observable visitorUpdated = false;

  @observable isAwait = false;

  @computed
  get disabledLabel() {
    return this.isDisable ? '停用' : '啟用';
  }

  @computed
  get visitorUpdatedLabel() {
    return this.visitorUpdated ? YES_OR_NO.true.label : YES_OR_NO.false.label;
  }

  @computed
  get genderLabel() {
    if (this.gender) {
      const isOthers = this.gender === GENDER_TYPES.others.value;

      return isOthers ? null : GENDER_TYPES[this.gender].label;
    }

    return null;
  }

  @computed
  get tssaDateLabel() {
    if (this.tssaDate) {
      return dayjs(this.tssaDate).format('YYYY-MM-DD');
    }

    return null;
  }

  @computed
  get sgsDateLabel() {
    if (this.sgsDate) {
      return dayjs(this.sgsDate).format('YYYY-MM-DD');
    }

    return null;
  }

  @computed
  get consultantDateLabel() {
    if (this.consultantDate) {
      return dayjs(this.consultantDate).format('YYYY-MM-DD');
    }

    return null;
  }

  @computed
  get dropdownItems() {
    return [
      { key: '0', label: '停用', disabled: this.isDisable },
      {
        key: '1',
        label: '啟用',
        disabled: !this.isDisable
      }
    ];
  }

  @computed
  get practiceCityLabel() {
    return this.practiceCity.map((item) => {
      return PRACTICE_CITIES[item].label;
    });
  }

  constructor(props) {
    this.isDisable = props.isDisable;
    this.id = props.accountId;
    this.nickName = props.nickName || null;
    this.name = props.name || null;
    this.gender = props.gender || null;
    this.phone = props.phone || null;
    this.email = props.email || null;
    this.lineId = props.lineId || null;
    this.address = props.address || null;
    this.deviceItem = props.deviceItem || null;
    this.practiceCity = props.practiceCity || null;
    this.tssaDate = props.tssaDate || null;
    this.sgsDate = props.sgsDate || null;
    this.consultantDate = props.consultantDate || null;
    this.visitorUpdated = props.visitorUpdated || false;

    makeObservable(this);
  }

  @action
  onClickDropdownMenuItem = ({ item, key }) => {
    if (key === '0') {
      this.putAccountDisableAPI(true);
    }

    if (key === '1') {
      this.putAccountDisableAPI(false);
    }
  };

  // 停用和啟用
  @action
  putAccountDisableAPI = async (disable) => {
    this.isAwait = true;

    try {
      const res = await UserServices.putAccountDisable({
        id: this.id,
        disable
      });

      runInAction(() => {
        this.isDisable = disable;
      });
    } catch (error) {
      const message = ErrorServices.putAccountDisable(error);

      console.log('InterviewerViewModel', 'putAccountDisable', message);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default InterviewerViewModel;
