import AppAPIs from 'src/apis/AppAPIs';
import UserCaseDataModel from 'src/models/response/UserCaseDataModel';
import CheckFormReplyDataModel from 'src/models/response/CheckFormReplyDataModel';
import CookieServices from './CookieServices';

class AppServices {
  // 讀取訪員案件
  static async getUserCase({ folder, limit = 1000, anchor = null }) {
    const res = await AppAPIs.getUserCase({
      token: CookieServices.getToken(),
      folder,
      limit,
      anchor
    });

    return {
      data: res.data.data.map((item) => new UserCaseDataModel(item))
    };
  }

  // 訪員接受案件
  static async putUserCaseAccept({ projectId }) {
    const res = await AppAPIs.putUserCaseAccept({
      token: CookieServices.getToken(),
      projectId
    });

    return {
      data: res.data
    };
  }

  // 訪員拒絕案件
  static async putUserCaseReject({ projectId }) {
    const res = await AppAPIs.putUserCaseReject({
      token: CookieServices.getToken(),
      projectId
    });

    return {
      data: res.data
    };
  }

  // 訪員推進案件
  static async putUserCaseAction({ caseId }) {
    const res = await AppAPIs.putUserCaseAction({
      token: CookieServices.getToken(),
      caseId
    });

    return {
      data: res.data
    };
  }

  // 訪員讀取查檢表答案
  static async getCheckFormReply({ caseId }) {
    const res = await AppAPIs.getCheckFormReply({
      token: CookieServices.getToken(),
      caseId
    });

    console.log(res.data);

    return {
      data: new CheckFormReplyDataModel(res.data.data)
    };
  }

  // 訪員回答問題
  static async postCheckFormReply({
    caseId,
    itemId,
    implementScore,
    satisScore,
    content
  }) {
    const res = await AppAPIs.postCheckFormReply({
      token: CookieServices.getToken(),
      caseId,
      itemId,
      implementScore,
      satisScore,
      content
    });

    return {
      data: res.data
    };
  }
}

export default AppServices;
