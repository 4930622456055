import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';

class SimpleModalViewModel {
  @observable isShow = false;

  constructor(props) {
    makeObservable(this);
  }

  @action
  show = () => {
    this.isShow = true;
  }

  @action
  hide = () => {
    this.isShow = false;
  }
}

export default SimpleModalViewModel;
