import { request, getHost } from './utils';

class ProjectAPIs {
  // 讀取專案列表
  static postProjectList({ token, limit, anchor, handler, steps, keyword }) {
    const options = {
      method: 'post',

      url: `${getHost()}/v1/project/list`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      params: {
        limit,
        anchor,
        keyword
      },

      data: {
        steps,
        handler
      }
    };

    return request(options);
  }

  // 新增專案
  static putProjectCreate({ token, projectName, companyId }) {
    const options = {
      method: 'put',

      url: `${getHost()}/v1/project/create`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      data: {
        projectName,
        companyId
      }
    };

    return request(options);
  }

  // 讀取專案基本 detail
  static getProjectDetail({ token, projectId }) {
    const options = {
      method: 'get',

      url: `${getHost()}/v1/project/detail/${projectId}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      }
    };

    return request(options);
  }

  // 更新專案名稱
  static postProjectUpdateName({ token, projectId, name }) {
    const options = {
      method: 'post',

      url: `${getHost()}/v1/project/update/name/${projectId}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      data: {
        name
      }
    };

    return request(options);
  }

  // 讀取總規格表
  static getProjectSpecification({ token, projectId }) {
    const options = {
      method: 'get',

      url: `${getHost()}/v1/project/specification/${projectId}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      }
    };

    return request(options);
  }

  // 更新總規格表
  static postProjectSpecification({
    token,
    projectId,
    startTime,
    stopTime,
    content,
    caseAmount
  }) {
    const options = {
      method: 'post',

      url: `${getHost()}/v1/project/specification`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      data: {
        projectId,
        startTime,
        stopTime,
        content,
        caseAmount
      }
    };

    return request(options);
  }

  // 更新專案簡述
  static postProjectUpdateAbout({ token, projectId, about }) {
    const options = {
      method: 'post',

      url: `${getHost()}/v1/project/update/about/${projectId}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      data: {
        about
      }
    };

    return request(options);
  }

  // 更新專案備註
  static postProjectUpdateComment({ token, projectId, content }) {
    const options = {
      method: 'post',

      url: `${getHost()}/v1/project/update/comment/${projectId}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      data: {
        content
      }
    };

    return request(options);
  }

  // 更新執案指導資訊
  static postProjectUpdateGuidance({ token, projectId, guidance }) {
    const options = {
      method: 'post',

      url: `${getHost()}/v1/project/update/guidance/${projectId}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      data: {
        guidance
      }
    };

    return request(options);
  }

  // 更新專案溝通與說明紀錄
  static postProjectUpdateMessage({ token, projectId, content }) {
    const options = {
      method: 'post',

      url: `${getHost()}/v1/project/update/message/${projectId}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      data: {
        content
      }
    };

    return request(options);
  }

  // 專案推進或是退回
  static postProjectAction({ token, projectId, nextStep, pmId, message }) {
    const options = {
      method: 'post',

      url: `${getHost()}/v1/project/action/${projectId}/${nextStep}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      params: {
        pmId
      },

      data: {
        message
      }
    };

    return request(options);
  }

  // 更新專案訪客問卷
  static postProjectUpdateQuest({ token, projectId, questPath }) {
    const options = {
      method: 'post',

      url: `${getHost()}/v1/project/update/quest/${projectId}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      data: {
        questPath
      }
    };

    return request(options);
  }
}

export default ProjectAPIs;
