import dayjs from 'dayjs';
import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction
} from 'mobx';
import { USER_TYPES } from 'src/global/constants';
import StaffEditModalViewModel from 'src/pages/StaffPage/components/StaffEditModal/viewModel';
import ErrorServices from 'src/services/ErrorServices';
import UserServices from 'src/services/UserServices';

class StaffViewModel {
  @observable isAwait = false;
  @observable isDisabled = false;
  @observable data = null;

  id = null;

  editModalVM = new StaffEditModalViewModel();

  @computed
  get check() {
    return {
      isBoss: this.data.type === USER_TYPES.boss.value
    };
  }

  @computed
  get label() {
    return {
      disabled: this.isDisabled ? '停用' : '啟用',
      type: USER_TYPES[this.data.type].label,
      idNumber: this.data.idNumber.toLocaleUpperCase(),
      birthday: dayjs(this.data.birthday).format('YYYY/MM/DD'),
      // 到職日期
      employmentDate: dayjs(this.data.employmentDate).format('YYYY/MM/DD'),
      // 離職日期
      resignationDate: this.data.resignationDate
        ? dayjs(this.data.resignationDate).format('YYYY/MM/DD')
        : null,
      // 保險日期
      insuranceDate: this.data.insuranceDate
        ? dayjs(this.data.insuranceDate).format('YYYY/MM/DD')
        : null,
      // 退保險日
      disInsuranceDate: this.data.disInsuranceDate
        ? dayjs(this.data.disInsuranceDate).format('YYYY/MM/DD')
        : null
    };
  }

  @computed
  get dropdownItems() {
    return [
      { key: '0', label: '編輯' },
      { key: '1', label: '停用', disabled: this.isDisabled },
      { key: '2', label: '啟用', disabled: !this.isDisabled }
    ];
  }

  constructor(props) {
    this.id = props.accountId;
    this.data = props;
    this.isDisabled = props.isDisabled;

    makeObservable(this);
  }

  // modal 更新完後, vm 更新
  @action
  edited = (data) => {
    this.data = data;
  };

  @action
  onEdit = () => {
    this.editModalVM.onOpenEdit({
      id: this.id,
      data: this.data,
      edited: this.edited
    });
  };

  // 停用
  onDisable = () => {
    this.putAccountDisableAPI(true);
  };

  // 啟用
  onEnable = () => {
    this.putAccountDisableAPI(false);
  };

  @action
  onClickDropdownItem = (item) => {
    switch (item.key) {
      case '0':
        this.onEdit();
        break;

      case '1':
        this.onDisable();
        break;

      case '2':
        this.onEnable();
        break;

      default:
    }
  };

  // 停用或啟用帳號
  @action
  putAccountDisableAPI = async (value) => {
    this.isAwait = true;

    try {
      const res = await UserServices.putAccountDisable({
        id: this.id,
        disable: value
      });

      runInAction(() => {
        this.isDisabled = value;
      });
    } catch (error) {
      const message = ErrorServices.putAccountDisable(error);

      console.log('StaffViewModel', 'putAccountDisable', message);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default StaffViewModel;
