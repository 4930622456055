import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Menu } from 'antd';
import withRouter from 'src/components/withRouter';
import CustomMenuViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class CustomMenu extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new CustomMenuViewModel({
      profile: props.profile,
      router: props.router
    });
  }

  componentDidMount() {
    this.vm.didMount();
  }

  componentDidUpdate(preProps) {
    this.vm.didUpdate(this.props, preProps);
  }

  componentWillUnmount() {
    this.vm.willUnmount();
  }

  render() {
    return (
      <div id="menu" className={styles.container}>
        <Menu
          className={styles.menu}
          mode="inline"
          selectedKeys={this.vm.itemKey}
          openKeys={this.vm.groupKeys}
          items={this.vm.menu}
          onClick={this.vm.onClickItem}
          onOpenChange={this.vm.onClickGroup}
        />
      </div>
    );
  }
}

CustomMenu.propTypes = {
  profile: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired
};

CustomMenu.defaultProps = {};

export default withRouter(CustomMenu);
