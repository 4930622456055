import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction
} from 'mobx';
import { v4 } from 'uuid';
import dayjs from 'dayjs';
import { exportCheckForm } from 'src/global/export';
import { downloadCSV } from 'src/global/methods';
import UserServices from 'src/services/UserServices';
import ErrorServices from 'src/services/ErrorServices';

class CheckFormViewModel {
  project = null;

  @observable data = null;
  @observable topics = [];

  // 多選
  @observable isActive = false;

  // 是否顯示給其他使用者
  @observable isCustomerViewable = false;

  @observable isAwait = false;

  @computed
  get disabled() {
    return {
      active: this.isAwait,
      customerViewable: this.isAwait,
      checkForm: this.isAwait,
      export: this.isAwait
    };
  }

  @computed
  get id() {
    return this.data.id || v4();
  }

  @computed
  get auditStartTime() {
    return this.data.auditStartTime;
  }

  @computed
  get auditEndTime() {
    return this.data.auditEndTime;
  }

  @computed
  get checkFormReply() {
    return this.data.checkFormReply;
  }

  @computed
  get name() {
    return this.data.name;
  }

  @computed
  get targetName() {
    return this.data.targetName;
  }

  @computed
  get mapTopics() {
    return this.topics
      .map((topic) => {
        const checkFormReply = [];
        const replies = this.data.checkFormReply;

        replies.forEach((reply) => {
          if (reply.itemId === topic.id) {
            checkFormReply.push(reply);
          }
        });

        return { ...topic, checkFormReply };
      })
      .filter((topic) => topic.checkFormReply.length);
  }

  @computed
  get mapExport() {
    return this.topics.map((topic) => {
      const checkFormReply = this.data.checkFormReply.filter(
        (reply) => reply.itemId === topic.id
      );
      const mapCheckFormReply = checkFormReply.map((reply) => {
        return {
          ...reply,
          targetName: this.data.name,
          auditStartTime: this.auditStartTime,
          auditEndTime: this.auditEndTime
        };
      });

      return { ...topic, checkFormReply: mapCheckFormReply };
    });
  }

  constructor(props) {
    this.data = props.data;
    this.isCustomerViewable = props.data.isCustomerViewable;

    this.topics = props.topics;

    this.project = props.project;

    makeObservable(this);
  }

  @action
  setActive = (val) => {
    this.isActive = val;
  };

  @action
  setCustomerViewable = (val) => {
    this.isCustomerViewable = val;
  }

  @action
  onActiveChange = (event) => {
    this.isActive = event.target.checked;
  };

  @action
  onCustomerViewableChange = (checked) => {
    this.isCustomerViewable = checked;
    this.putUserCaseCustomerViewable();
  };

  onCheckFormExport = () => {
    const dataFormat = exportCheckForm({
      data: this.mapExport,
      auditStartTime: true,
      auditEndTime: true,
      implementationTotal: true,
      satisfactionTotal: true
    });

    const today = dayjs().format('YYYYMMDD');
    const fileName = `${this.project.name}_${this.data.name}_${today}`;

    downloadCSV({ data: dataFormat, fileName });
  };

  @action
  putUserCaseCustomerViewable = async () => {
    this.isAwait = true;

    try {
      const res = await UserServices.putUserCaseCustomerViewable({
        projectId: this.project.id,
        caseId: this.data.id,
        viewable: this.isCustomerViewable
      });
    } catch (error) {
      const msg = ErrorServices.putUserCaseCustomerViewable(error);

      console.log('putUserCaseCustomerViewable', msg);
    }

    runInAction(() => {
      this.isAwait = false;
    });
  };
}

export default CheckFormViewModel;
