import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { diff } from 'deep-diff';
import { CASE_STATUS } from 'src/global/case';
import CaseServices from 'src/services/CaseServices';
import ErrorServices from 'src/services/ErrorServices';

class PushButtonViewModel {
  @observable case = null;
  @observable checkParams = null;
  @observable isAwait = false;
  onChanged = null;

  @computed
  get isShow() {
    switch (this.case.step) {
      case CASE_STATUS.S7.value:
      case CASE_STATUS.S8.value:
        return this.checkParams.isHandler;

      default:
        return false;
    }
  }

  @computed
  get label() {
    const buttonLabel
      = this.case.step === CASE_STATUS.S7.value ? '開始審核' : '通過';

    return {
      button: buttonLabel
    };
  }

  constructor(props) {
    this.onChanged = props.onChanged;

    this.init(props);

    makeObservable(this);
  }

  @action
  init = (props) => {
    this.case = props.case;
    this.checkParams = props.checkParams;
  }

  didUpdate = (props, preProps) => {
    const diffCase = diff(props.case, preProps.case);

    if (diffCase) {
      this.init(props);
    }
  }

  getNextStep = () => {
    switch (this.case.step) {
      case CASE_STATUS.S7.value:
        return CASE_STATUS.S8.value;

      case CASE_STATUS.S8.value:
        return CASE_STATUS.S10.value;

      case CASE_STATUS.S9.value:
        return CASE_STATUS.S8.value;

      default:
        return null;
    }
  }

  onNextStep = () => {
    this.postCasePushAPI();
  };

  @action
  postCasePushAPI = async () => {
    this.isAwait = true;

    try {
      const nextStep = this.getNextStep();
      const res = await CaseServices.postCaseAction({
        caseId: this.case.caseId, nextStep
      });

      this.onChanged();
    } catch (error) {
      const message = ErrorServices.postCaseAction(error);

      console.log('PushButtonViewModel', 'postCaseAction', message);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default PushButtonViewModel;
