import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction
} from 'mobx';
import { diff } from 'deep-diff';
import { ROUTES, ROUTE_PARAMS, PROJECT_STATUS, USER_TYPES } from 'src/global/constants';
import ProjectServices from 'src/services/ProjectServices';
import ErrorServices from 'src/services/ErrorServices';

class DescTableViewModel {
  @observable checkParams = null;
  @observable project = null;
  @observable projectName = null;
  @observable isEdit = false;
  @observable isAwait = false;

  router = null;

  onProjectNameChanged = null;

  @computed
  get hasEdit() {
    switch (this.project.step) {
      // 只有 S1 時可以更新專案名稱
      case PROJECT_STATUS.S1.value: {
        return this.checkParams.isBoss || this.checkParams.isHandlerOrBoss;
      }

      default:
        return false;
    }
  }

  @computed
  get label() {
    return {
      projectName: this.project.projectName,
      projectStep: PROJECT_STATUS[this.project.step].label,
      projectHandler: USER_TYPES[this.project.handler].label,
      projectCompanyName: this.project.companyName
    };
  }

  constructor(props) {
    this.init(props);
    makeObservable(this);
  }

  @action
  init = (props) => {
    this.router = props.router;
    this.project = props.project;
    this.checkParams = props.checkParams;

    // 可編輯的專案名稱
    this.projectName = props.project.projectName;

    this.onProjectNameChanged = props.onProjectNameChanged;
  };

  didUpdate = (props, preProps) => {
    const diffProject = diff(props.project, preProps.project);
    const diffCheckParams = diff(props.checkParams, preProps.checkParams);

    if (diffProject || diffCheckParams) {
      this.init(props);
    }
  };

  @action
  onChangeProjectName = (event) => {
    this.projectName = event.target.value;
  };

  @action
  onEdit = () => {
    this.isEdit = true;
  };

  @action
  onSave = () => {
    const checkProjectName = this.projectName === this.project.projectName;

    if (!checkProjectName) {
      this.postUpdateProjectNameAPI();
    } else {
      this.isEdit = false;
    }
  };

  onToClientClick = () => {
    const route = `/${ROUTES.client.value}/${this.project.companyId}`;
    const state = {};

    state[ROUTE_PARAMS.projectId] = this.project.projectId;

    this.router.navigate(route, { state });
  }

  @action
  postUpdateProjectNameAPI = async () => {
    this.isAwait = true;

    try {
      const projectId = this.project.projectId;
      const res = await ProjectServices.postProjectUpdateName({
        projectId,
        projectName: this.projectName
      });

      runInAction(() => {
        this.isEdit = false;
        this.onProjectNameChanged(this.projectName);
      });
    } catch (error) {
      const message = ErrorServices.postProjectUpdateName(error);

      console.log('DescTableViewModel', 'postProjectUpdateName', message);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default DescTableViewModel;
