import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { Collapse, Tabs } from 'antd';
import clsx from 'clsx';
import InferiorFacetTab from '../InferiorFacetTab';
import styles from './styles.module.scss';
import './reset.scss';

@observer
class Facet extends React.Component {
  @computed
  get inferiorFacets() {
    return this.props.data.inferiorFacets;
  }

  @computed
  get topics() {
    const topics = this.inferiorFacets.map((item) => item.topics).flat();

    return topics;
  }

  @computed
  get total() {
    let implementWeighted = 0;
    let satisWeighted = 0;

    for (let i = 0; i < this.topics.length; i += 1) {
      const item = this.topics[i];
      // 是分數題
      const isFraction = item.check.isFraction;
      const fraction = item.fraction;

      if (fraction && isFraction && fraction.implementWeighted) {
        implementWeighted += Number(fraction.implementWeighted);
      }

      if (fraction && isFraction && fraction.satisWeighted) {
        satisWeighted += Number(fraction.satisWeighted);
      }
    }

    return {
      implementWeighted,
      satisWeighted
    };
  }

  @computed
  get label() {
    return {
      topicsLength: `題目數量：${this.topics.length}`,
      implementWeighted: `落實度分數加權佔比：${this.total.implementWeighted}%`,
      satisWeighted: `滿意度分數加權佔比：${this.total.satisWeighted}%`
    };
  }

  @computed
  get tabs() {
    return this.inferiorFacets.map((item) => {
      return {
        key: item.value,
        label: item.label,
        children: (
          <InferiorFacetTab
            data={item.topics}
            edit={this.props.edit}
            disabled={this.props.disabled}
          />
        )
      };
    });
  }

  render() {
    return (
      <div className={clsx(['checkItemFacet', styles.container])}>
        <div className={styles.sumContainer}>
          <div className={clsx([styles.item, styles.lengthItem])}>
            {this.label.topicsLength}
          </div>

          {this.props.detail ? (
            <>
              <div
                className={clsx([styles.item, styles.implementWeightedItem])}
              >
                {this.label.implementWeighted}
              </div>

              <div className={clsx([styles.item, styles.satisWeightedItem])}>
                {this.label.satisWeighted}
              </div>
            </>
          ) : null}
        </div>

        <Collapse>
          <Collapse.Panel header={this.props.data.label}>
            <div className={styles.tabContainer}>
              <Tabs type="card" items={this.tabs} />
            </div>
          </Collapse.Panel>
        </Collapse>
      </div>
    );
  }
}

Facet.propTypes = {
  data: PropTypes.object.isRequired,
  edit: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  detail: PropTypes.bool.isRequired
};

Facet.defaultProps = {};

export default Facet;
