import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction
} from 'mobx';
import { ROUTE_PARAMS, LIMIT } from 'src/global/constants';
import CustomAnchorViewModel from 'src/components/CustomAnchor/viewModel';
import VisitorServices from 'src/services/VisitorServices';
import ErrorServices from 'src/services/ErrorServices';

class InterviewerMissionViewModel {
  @observable id = '';
  @observable isAwait = 0;
  router = null;

  anchorVM =null;

  @computed
  get label() {
    const id = this.id.padStart(4, '0');

    return {
      id: `ID：${id}`
    };
  }

  constructor(props) {
    this.router = props.router;
    this.id = props.router.params[ROUTE_PARAMS.interviewerId];

    this.anchorVM = new CustomAnchorViewModel({ api: this.getVisitorCaseAPI });

    makeObservable(this);
  }

  didMount = () => {
    if (!this.isAwait) {
      this.anchorVM.reset();
    }
  };

  @action
  getVisitorCaseAPI = async ({ anchor }) => {
    this.isAwait = true;

    const res = await VisitorServices.getVisitorCase({
      visitorId: this.id,
      anchor
    });

    runInAction(() => {
      this.isAwait = false;
    });

    return res;
  }
}

export default InterviewerMissionViewModel;
