class CheckFormReplyListItemDataModel {
  constructor(props) {
    this.id = props.id;

    this.name = props.name;

    this.startDate = props.startDate;

    this.auditEndTime = props.AuditEndTime;
    this.auditStartTime = props.AuditStartTime;

    this.isCustomerViewable = props.isCustomerViewable;

    this.checkFormReply = props.checkFormReply.map((item) => {
      return {
        itemId: item.itemId,
        implementScore: item.implementScore,
        satisScore: item.satisScore,
        content: item.content,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt
      };
    });
  }
}

export default CheckFormReplyListItemDataModel;
