import ProfitFormAPIs from 'src/apis/ProfitFormAPIs';
import ProfitFormDataModel from 'src/models/response/ProfitFormDataModel';
import EventListDataModel from 'src/models/response/EventListDataModel';
import CookieServices from './CookieServices';

class ProfitFormServices {
  // 讀取損益表
  static async getProfitForm({ projectId }) {
    const res = await ProfitFormAPIs.getProfitForm({
      token: CookieServices.getToken(),
      projectId
    });

    return {
      eventList: res.data.eventList.map((item) => new EventListDataModel(item)),
      formData: new ProfitFormDataModel(res.data.formData)
    };
  }

  // 更新損益表
  static async postProfitFormUpsert({
    projectId,
    industry,
    place,
    pieceFee,
    estimatedProfit,
    resultProfit,
    complete,
    income
  }) {
    const res = await ProfitFormAPIs.postProfitFormUpsert({
      token: CookieServices.getToken(),
      projectId,
      industry,
      place,
      pieceFee,
      estimatedProfit,
      resultProfit,
      complete,
      income
    });

    console.log(res.data);

    return {
      data: res.data
    };
  }

  // 申請損益表修改
  static async postCreateProfitFormEven({ projectId, content }) {
    const res = await ProfitFormAPIs.postCreateProfitFormEven({
      token: CookieServices.getToken(),
      projectId,
      content
    });

    return {
      data: res.data
    };
  }

  // 修改損益表修改申請內容
  static async postUpdateProfitFormEventContent({ eventId, content }) {
    const res = await ProfitFormAPIs.postUpdateProfitFormEventContent({
      token: CookieServices.getToken(),
      eventId,
      content
    });

    return {
      data: res.data
    };
  }

  // 接受損益表修改要求
  static async postAcceptProfitFormEven({ eventId }) {
    const res = await ProfitFormAPIs.postAcceptProfitFormEven({
      token: CookieServices.getToken(),
      eventId
    });

    return {
      data: res.data
    };
  }

  // 拒絕損益表修改要求
  static async postRejectProfitFormEven({ eventId }) {
    const res = await ProfitFormAPIs.postRejectProfitFormEven({
      token: CookieServices.getToken(),
      eventId
    });

    return {
      data: res.data
    };
  }
}

export default ProfitFormServices;
