import { USER_TYPES } from 'src/global/constants';

class ProfileDataModel {
  constructor(props) {
    this.id = props.id;

    this.type = props.type;
    // this.type = USER_TYPES.operationsManagement.value;

    this.name = props.name;

    this.account = props.account;
  }
}

export default ProfileDataModel;
