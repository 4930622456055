import React from 'react';
import PropTypes from 'prop-types';
import { observer, Observer } from 'mobx-react';
import { Table, Input } from 'antd';
import CustomTable from 'src/components/CustomTable';

function IncomeDetailTable(props) {
  return (
    <CustomTable data={props.data}>
      <Table.Column width={120} title="項目" dataIndex="label" />

      <Table.Column
        width={200}
        title="預估損益"
        render={(vm) => {
          return (
            <Observer>
              {() => {
                return (
                  <Input
                    size="small"
                    value={vm.expected}
                    onChange={vm.onChangeExpected}
                    disabled={props.disabledExpected}
                    maxLength={50}
                  />
                );
              }}
            </Observer>
          );
        }}
      />

      <Table.Column
        width={120}
        title="預估佔比"
        render={(vm) => {
          return (
            <Observer>
              {() => {
                return vm.expectedRatio;
              }}
            </Observer>
          );
        }}
      />

      <Table.Column
        width={400}
        title="預估損益說明"
        render={(vm) => {
          return (
            <Observer>
              {() => {
                return (
                  <Input
                    size="small"
                    disabled={props.disabledExpected}
                    value={vm.expectedDesc}
                    onChange={vm.onExpectedDescChange}
                    maxLength={200}
                  />
                );
              }}
            </Observer>
          );
        }}
      />

      <Table.Column
        width={200}
        title="結算損益"
        render={(vm) => {
          return (
            <Observer>
              {() => {
                return (
                  <Input
                    size="small"
                    value={vm.result}
                    onChange={vm.onChangeResult}
                    disabled={props.disabledResult}
                    maxLength={50}
                  />
                );
              }}
            </Observer>
          );
        }}
      />

      <Table.Column
        width={120}
        title="結算佔比"
        render={(vm) => {
          return (
            <Observer>
              {() => {
                return vm.resultRatio;
              }}
            </Observer>
          );
        }}
      />

      <Table.Column
        width={400}
        title="結算損益說明"
        render={(vm) => {
          return (
            <Observer>
              {() => {
                return (
                  <Input
                    size="small"
                    disabled={props.disabledResult}
                    value={vm.resultDesc}
                    onChange={vm.onResultDescChange}
                    maxLength={200}
                  />
                );
              }}
            </Observer>
          );
        }}
      />
    </CustomTable>
  );
}

IncomeDetailTable.propTypes = {
  data: PropTypes.array,
  // 預估損益
  disabledExpected: PropTypes.bool.isRequired,
  // 結算損益
  disabledResult: PropTypes.bool.isRequired
};

IncomeDetailTable.defaultProps = {
  data: []
};

export default observer(IncomeDetailTable);
