import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction
} from 'mobx';
import { message } from 'antd';
import { diff } from 'deep-diff';
import { PROJECT_STATUS, ROUTES, USER_TYPES } from 'src/global/constants';
import EmployeeServices from 'src/services/EmployeeServices';
import ProjectServices from 'src/services/ProjectServices';
import ErrorServices from 'src/services/ErrorServices';
import CustomModalViewModel from 'src/components/CustomModal/viewModel';

class SelectPMViewModel {
  @observable data = null;
  @observable checkParams = null;
  @observable list = [];
  @observable value = null;
  @observable isAwait = null;

  projectId = null;

  anchor = undefined;
  router = null;

  modalVM = new CustomModalViewModel();

  @computed
  get check() {
    const isS3 = this.data.step === PROJECT_STATUS.S3.value;

    return {
      isShow: this.checkParams.isBoss && isS3
    };
  }

  @computed
  get disabled() {
    return {
      selectPM: this.isAwait,
      resolve: this.isAwait
    };
  }

  @computed
  get antList() {
    return this.list
      .filter((item) => item.type === USER_TYPES.projectManager.value)
      .map((item) => {
        return { value: item.accountId, label: item.name };
      });
  }

  constructor(props) {
    this.router = props.router;

    this.init(props);

    makeObservable(this);
  }

  @action
  init = (props) => {
    this.data = props.data;
    this.checkParams = props.checkParams;
    this.projectId = props.data.projectId;
  };

  didMount = () => {
    if (!this.isAwait && this.check.isShow) {
      this.getProjectManagements();
    }
  };

  didUpdate = (props, preProps) => {
    const diffData = diff(props.data, preProps.data);
    const diffCheckParams = diff(props.checkParams, preProps.checkParams);

    if (diffData || diffCheckParams) {
      this.init(props);
      this.didMount();
    }
  };

  @action
  onChangeValue = (value) => {
    this.value = value;
  };

  // 核准
  onResolve = () => {
    const checkPMId = !!this.value;
    const errorMessage = '請選擇專案PM';

    if (checkPMId) {
      this.modalVM.show();
    } else {
      message.error(errorMessage, 5);
    }
  };

  onConfirm = () => {
    this.postProjectActionAPI();
  }

  // 遞迴讀取員工列表
  @action
  getProjectManagements = async () => {
    this.isAwait = true;

    if (this.anchor === null) {
      this.isAwait = false;
    } else {
      await this.postEmployeeListAPI();
      this.getProjectManagements();
    }
  };

  // 讀取員工列表, 需要 pm
  @action
  postEmployeeListAPI = async () => {
    try {
      const res = await EmployeeServices.postEmployeeList({
        anchor: this.anchor
      });

      runInAction(() => {
        this.list = [...this.list, ...res.data];
        this.anchor = res.anchor;
      });
    } catch (error) {
      const msg = ErrorServices.postEmployeeList(error);

      console.log('SelectPMViewModel', 'postEmployeeList', msg);
    }
  };

  // 專案推進
  @action
  postProjectActionAPI = async () => {
    this.isAwait = true;

    try {
      const res = await ProjectServices.postProjectAction({
        projectId: this.projectId,
        nextStep: PROJECT_STATUS.S4.value,
        pmId: this.value,
        // 通過, 清除退件原因
        message: null
      });

      // 每前往新的步驟, 老闆要前往專案查詢
      if (this.checkParams.isBoss) {
        const route = `/${ROUTES.projectSearch.value}`;
        this.router.navigate(route, { replace: true });
      } else {
        const route = `/${ROUTES.execute.value}/${this.projectId}`;
        this.router.navigate(route, { replace: true });
      }
    } catch (error) {
      const msg = ErrorServices.postProjectAction(error);

      console.log('SelectPMViewModel', 'postProjectAction', msg);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default SelectPMViewModel;
