import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import StyleItem from 'src/components/StyleItem';
import TextAreaModal from 'src/components/TextAreaModal';
import GuideItemViewModel from './viewModel';

@observer
class GuideItem extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new GuideItemViewModel({
      project: props.project,
      checkParams: props.checkParams
    });
  }

  componentDidUpdate(preProps) {
    this.vm.didUpdate(this.props, preProps);
  }

  render() {
    return this.vm.isShow ? (
      <>
        <StyleItem>
          <span>執案指導資訊</span>

          <Button type="primary" onClick={this.vm.modalVM.show}>
            {this.vm.label.button}
          </Button>
        </StyleItem>

        <TextAreaModal
          vm={this.vm.modalVM}
          label="執案指導資訊"
          value={this.vm.guideInfo}
          onChange={this.vm.onChangeGuideInfo}
          onConfirm={this.vm.onConfirm}
          disabled={this.vm.disabled.modal}
          isAwait={this.vm.isAwait}
        />
      </>
    ) : null;
  }
}

GuideItem.propTypes = {
  project: PropTypes.object.isRequired,
  checkParams: PropTypes.object.isRequired
};

GuideItem.defaultProps = {};

export default GuideItem;
