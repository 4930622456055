import { request, getHost } from './utils';

class AnnouncementAPIs {
  // 讀取公告列表
  static getAnnouncementList(token) {
    const options = {
      method: 'get',

      url: `${getHost()}/v1/announcement/list`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      }
    };

    return request(options);
  }

  // 更新公告
  static putAnnouncement({ token, id, title, content }) {
    const options = {
      method: 'put',

      url: `${getHost()}/v1/announcement/${id}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      data: {
        title,
        content
      }
    };

    return request(options);
  }
}

export default AnnouncementAPIs;
