import { makeObservable, observable, action, computed } from 'mobx';

class WaveTimeViewModel {
  @observable startAt = null;
  @observable endAt = null;

  constructor() {
    makeObservable(this);
  }

  @action onStartAtChange = (val) => {
    this.startAt = val;
  }

  @action onEndAtChange = (val) => {
    this.endAt = val;
  }

  onStartAtFilter = (val) => {
    if (this.endAt) {
      return val.valueOf() > this.endAt.valueOf();
    }

    return false;
  }

  onEndAtFilter = (val) => {
    if (this.startAt) {
      return val.valueOf() < this.startAt.valueOf();
    }

    return false;
  }
}

export default WaveTimeViewModel;
