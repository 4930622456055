import ClosingSheetAPIs from 'src/apis/ClosingSheetAPIs';
import CookieServices from './CookieServices';

// 結案表
class ClosingSheetServices {
  // 讀取結案表
  static async getClosingSheet({ projectId }) {
    const res = await ClosingSheetAPIs.getClosingSheet({
      token: CookieServices.getToken(),
      projectId
    });

    return {
      data: {
        content: res.data.content
      }
    };
  }

  // 更新結案表
  static async postClosingSheet({ projectId, content }) {
    const res = await ClosingSheetAPIs.postClosingSheet({
      token: CookieServices.getToken(),
      projectId,
      content
    });

    return {
      data: res.data
    };
  }
}

export default ClosingSheetServices;
