import VisitorAPIs from 'src/apis/VisitorAPIs';
import UserAPIs from 'src/apis/UserAPIs';
import { LIMIT } from 'src/global/constants';
import VisitorListItemDataModel from 'src/models/response/VisitorListItemDataModel';
import VisitorDataModel from 'src/models/response/VisitorDataModel';
import VisitorCaseListItemDataModel from 'src/models/response/VisitorCaseListItemDataModel';
import CookieServices from './CookieServices';

// 訪員
class VisitorServices {
  // 讀取訪員列表
  static async postVisitorList({
    limit = LIMIT,
    anchor,
    keyword,
    filter = {}
  }) {
    const res = await VisitorAPIs.postVisitorList({
      token: CookieServices.getToken(),
      limit,
      anchor,
      keyword,
      filter
    });

    return {
      data: res.data.data.map((item) => new VisitorListItemDataModel(item)),
      anchor: res.data.anchor,
      totalCount: res.data.totalCount
    };
  }

  // 新增訪員
  static async postVisitorCreateBatch(data) {
    const res = await VisitorAPIs.postVisitorCreateBatch({
      token: CookieServices.getToken(),
      info: data
    });

    return { data: res.data };
  }

  // 讀取訪員詳細資料
  static async getAccountProfile({ id }) {
    const res = await UserAPIs.getAccountProfile({
      token: CookieServices.getToken(),
      id
    });

    return {
      data: new VisitorDataModel(res.data.data)
    };
  }

  // 更新訪員
  static async putVisitorUpdate({ id, data }) {
    const res = await VisitorAPIs.putVisitorUpdate({
      token: CookieServices.getToken(),
      id,
      data
    });

    return {
      data: res.data
    };
  }

  // 讀取訪員任務
  static async getVisitorCase({ visitorId, limit = LIMIT, anchor }) {
    const res = await VisitorAPIs.getVisitorCase({
      token: CookieServices.getToken(),
      visitorId,
      limit,
      anchor
    });

    return {
      data: res.data.data.map((item) => new VisitorCaseListItemDataModel(item)),
      anchor: res.data.anchor
    };
  }
}

export default VisitorServices;
