import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styles from './styles.module.scss';

function BorderRightBottom(props) {
  return (
    <div className={styles.container}>
      {props.children}
    </div>
  );
}

BorderRightBottom.propTypes = {
  children: PropTypes.node
};

BorderRightBottom.defaultProps = {
  children: null
};

export default BorderRightBottom;
