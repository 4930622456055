class UserCaseDataModel {
  constructor(props) {
    this.projectId = props.projectId;
    this.projectName = props.projectName;
    this.cases = props.case.map((item) => {
      return {
        caseId: item.id,
        caseName: item.name
      };
    });
  }
}

export default UserCaseDataModel;
