import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Checkbox, Input } from 'antd';
import clsx from 'clsx';
import SelfFormItem from 'src/components/CustomForm/components/SelfFormItem';
import FormatCell from 'src/components/CustomForm/components/FormatCell';
import BorderRightBottom from 'src/components/CustomForm/components/BorderRightBottom';
import useOptions from './hooks/useOptions';
import styles from './styles.module.scss';

function ChannelStore() {
  const options = useOptions();

  return (
    <BorderRightBottom>
      <FormatCell label="通路區別">
        <SelfFormItem name={['channelsModel', 'value']}>
          <Checkbox.Group options={options.channels} />
        </SelfFormItem>

        <div className={styles.desc}>
          <SelfFormItem name={['channelsModel', 'directSellingStoreDesc']}>
            <Input size="small" maxLength={50} />
          </SelfFormItem>

          <div className={styles.label}>加盟</div>

          <SelfFormItem name={['channelsModel', 'franchiseStoreDesc']}>
            <Input size="small" maxLength={50} />
          </SelfFormItem>
        </div>
      </FormatCell>
    </BorderRightBottom>
  );
}

ChannelStore.propTypes = {};

ChannelStore.defaultProps = {};

export default ChannelStore;
