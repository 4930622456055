import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Form, Input, DatePicker } from 'antd';
import CustomModal from 'src/components/CustomModal';
import SimpleForm from 'src/components/SimpleForm';
import styles from './styles.module.scss';
import './reset.scss';

@observer
class CreateModal extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({ onCreated: props.onCreated });
  }

  render() {
    return (
      <div id="interviewerCreateModal">
        <CustomModal
          className={styles.modalContainer}
          vm={this.props.vm.modalVM}
          hasHeader
          title="新增"
          hasPadding
          hasFooter
          hasCancel
          // 確認
          disabledConfirm={this.props.vm.isAwait}
          onConfirm={this.props.vm.onConfirm}
          confirmType="primary"
          // 取消
          onCancel={this.props.vm.close}
        >
          <SimpleForm ref={this.props.vm.formRef} labelCol={7}>
            <Form.Item label="姓名" name="name" rules={this.props.vm.rules.required}>
              <Input
                disabled={this.props.vm.isAwait}
                placeholder="請輸入"
                maxLength={50}
              />
            </Form.Item>

            <Form.Item
              label="身分證字號"
              name="idNumber"
              rules={this.props.vm.rules.idNumber}
            >
              <Input
                disabled={this.props.vm.isAwait}
                placeholder="請輸入"
                maxLength={50}
              />
            </Form.Item>

            <Form.Item
              label="生日"
              name="birthday"
              rules={this.props.vm.rules.required}
            >
              <DatePicker className={styles.expanded} placeholder="選擇日期" />
            </Form.Item>

            <Form.Item
              label="通訊電話"
              name="phone"
            >
              <Input
                disabled={this.props.vm.isAwait}
                placeholder="請輸入"
                maxLength={50}
              />
            </Form.Item>

            <Form.Item
              label="備註"
              name="remark"
            >
              <Input
                disabled={this.props.vm.isAwait}
                placeholder="請輸入"
                maxLength={50}
              />
            </Form.Item>
          </SimpleForm>
        </CustomModal>
      </div>
    );
  }
}

CreateModal.propTypes = {
  vm: PropTypes.object.isRequired,
  onCreated: PropTypes.func.isRequired
};

CreateModal.defaultProps = {};

export default CreateModal;
