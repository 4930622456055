import { makeObservable, observable, action, computed } from 'mobx';
import dayjs from 'dayjs';
import { ROUTES } from 'src/global/constants';

class ClientViewModel {
  // ui 使用
  @observable id = null;

  @observable accountId = null;

  // 企業狀態, 停用 or 啟用
  @observable isDisabled = null;

  // 企業編碼
  @observable companyNumber = null;

  // 企業名稱
  @observable companyName = null; // 產業類別?
  @observable type = null; // 登記地址
  @observable address = null;
  // 代表電話
  @observable phone = null; // 網址
  @observable path = null; // 企業負責人
  @observable principalName = null;

  // 統一編碼
  @observable taxId = null; // 業務
  @observable business = null;
  // 其他合作業務
  @observable otherBusiness = null;

  // 付款方式
  @observable payment = null;
  // 更新時間
  @observable updatedAt = null;

  @computed
  get label() {
    return {
      disabled: this.isDisabled ? '停用' : '啟用',
      link: `/${ROUTES.client.value}/${this.id}`,
      updatedAtFormat: this.updatedAt
        ? dayjs(this.updatedAt).format('YYYY.MM.DD')
        : null
    };
  }

  constructor(props) {
    this.init(props);
    makeObservable(this);
  }

  @action
  init = (props) => {
    // ui 使用
    this.id = props.accountId;

    this.accountId = props.accountId;

    // 企業狀態, 停用 or 啟用
    this.isDisabled = props.isDisabled;

    // 企業編碼
    this.companyNumber = props.enterpriseNumber;

    // 企業名稱
    this.companyName = props.name;

    // 產業類別?
    this.type = props.type;

    // 登記地址
    this.address = props.address;

    // 代表電話
    this.phone = props.phone;

    // 網址
    this.path = props.path;

    // 企業負責人
    this.principalName = props.principalName;

    // 統一編碼
    this.taxId = props.taxId;

    // 業務
    this.business = props.business;
    // 其他合作業務
    this.otherBusiness = props.otherBusiness;

    // 付款方式
    this.payment = props.payment;

    // 更新時間
    this.updatedAt = props.updatedAt;
  };

  @action
  onDetailUpdated = (props) => {
    // 企業狀態, 停用 or 啟用
    this.isDisabled = props.isDisabled;

    // 企業編碼
    this.companyNumber = props.companyNumber;

    // 企業名稱
    this.companyName = props.companyName;

    // 產業類別?
    this.type = props.propertyType;

    // 登記地址
    this.address = props.address;

    // 代表電話
    this.phone = props.phone;

    // 網址
    this.path = props.path;

    // 企業負責人
    this.principalName = props.principalName;

    // 業務
    this.business = props.business;
    // 其他合作業務
    this.otherBusiness = props.otherBusiness;

    // 付款方式
    this.payment = props.payment;

    // 更新時間
    this.updatedAt = dayjs().toISOString();

    console.log('ClientViewModel', 'onUpdated', props);
  };

  @action
  onDisabledUpdated = (value) => {
    this.isDisabled = value;
  }
}

export default ClientViewModel;
