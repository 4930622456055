import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import ICONS from 'src/global/icons';
import CustomModal from '../CustomModal';
import styles from './styles.module.scss';

@observer
class WarningModal extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
  }

  render() {
    return (
      <CustomModal vm={this.vm} width={260}>
        <div className={styles.container}>
          <div className={styles.mainContent}>
            <div className={styles.iconContainer}>
              <ICONS.Warning className={styles.icon} />
            </div>

            <div className={styles.content}>{this.props.content}</div>
          </div>

          <div className={styles.buttons}>
            <Button
              className={styles.button}
              size="small"
              onClick={this.vm.hide}
            >
              No
            </Button>

            <Button
              className={styles.button}
              type="primary"
              size="small"
              onClick={this.props.onConfirm}
              disabled={this.props.disabledConfirm}
            >
              Yes
            </Button>
          </div>
        </div>
      </CustomModal>
    );
  }
}

WarningModal.propTypes = {
  vm: PropTypes.object.isRequired,
  content: PropTypes.string,
  onConfirm: PropTypes.func,
  disabledConfirm: PropTypes.bool
};

WarningModal.defaultProps = {
  content: '',
  onConfirm: () => {},
  disabledConfirm: false
};

export default WarningModal;
