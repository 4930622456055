import { request, getHost } from './utils';

class StatisticsAPIs {
  // 新增統計列表
  static postStatisticsTimeList({ token, projectId, timeList }) {
    const options = {
      method: 'post',

      url: `${getHost()}/v1/statistics/timeList`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      },

      data: {
        projectId,
        timeList
      }
    };

    return request(options);
  }

  // 讀取統計列表
  static getStatisticsTimeList({ token, projectId }) {
    const options = {
      method: 'get',

      url: `${getHost()}/v1/statistics/timeList/${projectId}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      }
    };

    return request(options);
  }

  // 讀取全部統計資料
  static getStatisticsData({ token, projectId }) {
    const options = {
      method: 'get',

      url: `${getHost()}/v1/statistics/data/${projectId}`,

      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`
      }
    };

    return request(options);
  }
}

export default StatisticsAPIs;
