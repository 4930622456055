import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import {
  ACCOUNT_CREATE_TYPES,
  RE_ALPHABET_NUMBER,
  USER_TYPES
} from 'src/global/constants';
import { INDUSTRIES } from 'src/global/profitForm';
import CustomDrawerViewModel from 'src/components/CustomDrawer/viewModel';
import CompanyServices from 'src/services/CompanyServices';
import UserServices from 'src/services/UserServices';
import ErrorServices from 'src/services/ErrorServices';
import ContactViewModel from '../../viewModels/ContactViewModel';

class ClientDrawerViewModel {
  @observable profile = null;

  @observable clientId = null;
  clientVM = null;

  @observable isCreate = false;
  @observable isEditing = false;
  // 是否停用
  @observable disabledClient = false;
  @observable contacts = [];
  // 窗口編輯
  @observable contactEitId = null;
  @observable isAwait = false;

  drawerVM = new CustomDrawerViewModel();

  form = null;
  onCreated = null;

  @computed
  get check() {
    const boos = this.profile.type === USER_TYPES.boss.value;
    const salesperson = this.profile.type === USER_TYPES.salesperson.value;

    return {
      hasClientId: !!this.clientId,
      editForm: boos || salesperson
    };
  }

  @computed
  get disabled() {
    return {
      form: this.isAwait || !this.check.editForm || !this.isEditing,
      disabled: this.isAwait || !this.check.editForm,
      contacts: this.isAwait || !this.isEditing
    };
  }

  @computed
  get label() {
    return {
      title: this.check.hasClientId ? '客戶詳情' : '新增'
    };
  }

  @computed
  get rules() {
    const required = {
      required: true,
      message: '請輸入'
    };

    return {
      required: [required],
      number: [
        required,
        {
          transform: (val) => Number(val),
          type: 'number',
          message: '請輸入數字'
        }
      ],
      password: [
        {
          required: this.isCreate,
          message: '請輸入'
        },
        {
          pattern: RE_ALPHABET_NUMBER,
          message: '密碼格式錯誤'
        }
      ]
    };
  }

  @computed
  get options() {
    return {
      types: Object.values(INDUSTRIES)
    };
  }

  constructor(props) {
    makeObservable(this);
  }

  @action
  init = (props) => {
    this.form = props.form;
    this.onCreated = props.onCreated;
    this.profile = props.profile;
  };

  @action
  onCreateOpen = () => {
    this.reset();

    this.isCreate = true;
    // 開啟編輯
    this.isEditing = true;

    this.drawerVM.show();
  };

  @action
  onEditOpen = (vm) => {
    this.reset();

    this.isCreate = false;
    this.isEditing = false;

    this.clientId = vm.id;
    this.clientVM = vm;

    this.getAccountProfileAPI();

    this.drawerVM.show();

    console.log('onEditOpen', 'vm', vm);
  };

  // 設置 form data
  @action
  setFormData = (data) => {
    this.disabledClient = data.isDisabled || false;

    this.contacts
      = data.companyContact?.map((item) => {
        return new ContactViewModel(item);
      }) || [];

    this.form.setFieldsValue({
      // 企業編碼
      companyNumber: data.enterpriseNumber || null,
      // 企業全名
      companyName: data.name || null,
      // 展業類別
      propertyType: data.type || null,
      password: data.password || null,
      // 登記地址
      address: data.address || null,
      // 代表電話
      phone: data.phone || null,
      // 網址
      path: data.path || null,
      // 負責人
      principalName: data.principalName || null,
      // 統一編號
      taxId: data.taxId || null,
      // 合作業務
      business: data.business || null,
      // 其他合作業務
      otherBusiness: data.otherBusiness || null,
      // 付款方式
      payment: data.payment || null
    });
  };

  getFormData = async () => {
    const companyContact = this.contacts
      .map((vm) => vm.getData())
      .filter((item) => item);

    if (this.check.editForm) {
      const formData = await this.form.validateFields();
      const password = formData.password?.trim() || undefined;

      return {
        ...formData,
        // 企業編碼 + 名字
        password: password || undefined,
        // 統一編號
        taxId: this.check.hasClientId ? undefined : formData.taxId?.trim(),
        // 窗口
        companyContact
      };
    }

    return {
      companyContact
    };
  };

  // 重置狀態
  @action
  reset = () => {
    this.clientId = null;
    this.ClientId = null;

    // 是否開啟編輯
    this.isEditing = false;

    // 停用切換
    this.disabledClient = false;

    // 窗口
    this.contacts = [];

    // 正在編輯的窗口
    this.editId = null;

    this.isAwait = false;

    this.setFormData({});
  };

  // 停用
  @action
  onDisabled = () => {
    this.putUpdateClientDisabledAPI(true);
  };

  // 啟用
  @action
  onUnDisabled = () => {
    this.putUpdateClientDisabledAPI(false);
  };

  // 開啟編輯
  @action
  onEditing = () => {
    this.isEditing = true;
  };

  // 儲存
  onSave = () => {
    if (this.check.hasClientId) {
      // 更新
      this.putUpdateClientDetailAPI();
    } else {
      // 新增
      this.postCreateClientAPI();
    }
  };

  // 新增窗口?
  @action
  onCreateContact = () => {
    this.contacts = [...this.contacts, new ContactViewModel({})];
  };

  // 設定正在編輯的窗口
  @action
  setContactEditId = (id) => {
    this.contactEitId = id;
  };

  // 關閉編輯窗口
  @action
  clearContactEditId = () => {
    this.contactEitId = null;
  };

  // 刪除窗口
  @action
  onDeleteContact = (id) => {
    this.contacts = this.contacts.filter((item) => item.id !== id);
  };

  // 新增
  @action
  postCreateClientAPI = async () => {
    this.isAwait = true;

    try {
      const formData = await this.getFormData();
      const res = await UserServices.postAccountCreate({
        type: ACCOUNT_CREATE_TYPES.company,
        data: formData
      });

      this.onCreated();

      this.drawerVM.hide();
    } catch (error) {
      const msg = ErrorServices.postAccountCreate(error, 'client');

      console.log('ClientDrawerViewModel', 'postAccountCreate', msg);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };

  // 讀取客戶詳細資料
  @action
  getAccountProfileAPI = async () => {
    this.isAwait = true;

    try {
      const res = await CompanyServices.getAccountProfile({
        id: this.clientId
      });

      this.setFormData(res.data);
    } catch (error) {
      const msg = ErrorServices.getAccountProfile(error);

      console.log('ClientDrawerViewModel', 'getAccountProfile', msg);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };

  // 更新
  @action
  putUpdateClientDetailAPI = async () => {
    this.isAwait = true;

    try {
      const formData = await this.getFormData();
      const res = await CompanyServices.putCompanyUpdate({
        clientId: this.clientVM.id,
        info: formData
      });

      runInAction(() => {
        this.clientVM.onDetailUpdated(formData);
        this.drawerVM.hide();
      });
    } catch (error) {
      const msg = ErrorServices.putCompanyUpdate(error);

      console.log('ClientDrawerViewModel', 'putCompanyUpdate', msg);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };

  // 停用或啟用
  @action
  putUpdateClientDisabledAPI = async (value) => {
    this.isAwait = true;

    try {
      const res = await UserServices.putAccountDisable({
        id: this.clientVM.id,
        disable: value
      });

      runInAction(() => {
        this.disabledClient = value;
        this.clientVM.onDisabledUpdated(value);
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default ClientDrawerViewModel;
