import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction
} from 'mobx';
import { diff } from 'deep-diff';
import { PROJECT_STATUS } from 'src/global/constants';
import CustomModalViewModel from 'src/components/CustomModal/viewModel';
import ProjectServices from 'src/services/ProjectServices';
import ErrorServices from 'src/services/ErrorServices';

class RemarkItemViewModel {
  @observable project = null;
  @observable checkParams = null;
  @observable value = '';
  @observable isAwait = false;
  onChanged = null;
  modalVM = new CustomModalViewModel();

  @computed
  get isShow() {
    switch (this.project.step) {
      case PROJECT_STATUS.S8.value:
      case PROJECT_STATUS.S9.value:
        return true;

      default:
        return false;
    }
  }

  @computed
  get disabled() {
    return {
      modal: this.checkParams.isEnd || !this.checkParams.isHandlerOrBoss
    };
  }

  @computed
  get label() {
    return {
      button: this.disabled.modal ? '查看' : '編輯'
    };
  }

  constructor(props) {
    this.init(props);
    makeObservable(this);
  }

  @action
  init = (props) => {
    this.project = props.project;
    this.checkParams = props.checkParams;

    this.value = props.project.comment || '';
  };

  didUpdate = (props, preProps) => {
    const diffProject = diff(props.project, preProps.project);
    const diffCheckParams = diff(props.checkParams, preProps.checkParams);

    if (diffProject || diffCheckParams) {
      this.init(props);
    }
  };

  @action
  onChangeValue = (event) => {
    this.value = event.target.value;
  };

  onConfirm = () => {
    this.postUpdateRemarkAPI();
  };

  @action
  postUpdateRemarkAPI = async () => {
    this.isAwait = true;

    try {
      const projectId = this.project.projectId;
      const res = await ProjectServices.postProjectUpdateComment({
        projectId,
        content: this.value
      });

      this.modalVM.hide();
    } catch (error) {
      const message = ErrorServices.postProjectUpdateMessage(error);

      console.log('RemarkItemViewModel', 'postUpdateRemarkAPI', message);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default RemarkItemViewModel;
