import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction
} from 'mobx';
import { USER_TYPES } from 'src/global/constants';
import AnnouncementServices from 'src/services/AnnouncementServices';
import ErrorServices from 'src/services/ErrorServices';
import AnnouncementViewModel from './viewModels/AnnouncementViewModel';

class AnnouncementPageViewModel {
  @observable profile = null;
  @observable data = [];
  @observable edit = null;
  @observable isInit = true;
  @observable isAwait = false;

  // 判斷 profile, 可不可以編輯 table
  @computed
  get hasEdit() {
    switch (this.profile.type) {
      case USER_TYPES.boss.value:
      case USER_TYPES.salesperson.value:
        return true;

      default:
        return false;
    }
  }

  // 鎖定編輯按鈕, api await 時, 有人正在編輯時
  @computed
  get disableEdit() {
    return this.isAwait || !!this.edit;
  }

  constructor(props) {
    this.profile = props.profile;
    makeObservable(this);
  }

  didMount = () => {
    this.getAnnouncementListAPI();
  };

  checkEdit = (data) => {
    return this.edit?.id === data.id;
  };

  @action
  onEdit = (data) => {
    this.edit = data;
  };

  @action
  onSave = (vm) => {
    this.putAnnouncementAPI(vm);
  };

  // get data api
  @action
  getAnnouncementListAPI = async () => {
    this.isAwait = true;

    try {
      const res = await AnnouncementServices.getAnnouncementList();

      runInAction(() => {
        this.data = res.data.map((item) => new AnnouncementViewModel(item));
      });
    } catch (error) {
      const message = ErrorServices.getAnnouncementList(error);

      console.log('AnnouncementPageViewModel', 'getAnnouncementList', message);
    } finally {
      runInAction(() => {
        this.isInit = false;
        this.isAwait = false;
      });
    }
  };

  // 儲存
  @action
  putAnnouncementAPI = async (vm) => {
    this.isAwait = true;

    try {
      const res = vm.res;
      await AnnouncementServices.putAnnouncement({
        id: res.id,
        title: res.title,
        content: res.content
      });

      runInAction(() => {
        this.edit = null;
      });
    } catch (error) {
      const message = ErrorServices.putAnnouncement(error);

      console.log('AnnouncementPageViewModel', 'getAnnouncementList', message);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default AnnouncementPageViewModel;
