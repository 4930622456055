import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button, DatePicker, Checkbox, Input, Select } from 'antd';
import { DELIVERABLES } from 'src/global/form';
import ICONS from 'src/global/icons';
import clsx from 'clsx';
import FormatCell from 'src/components/CustomForm/components/FormatCell';
import SelfFormItem from 'src/components/CustomForm/components/SelfFormItem';
import styles from './styles.module.scss';

class WaveDetail extends React.Component {
  get deliverables() {
    return Object.values(DELIVERABLES);
  }

  get title() {
    const index = this.props.index + 1;
    return `第${index}波`;
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>{this.title}</div>

          <Button
            type="primary"
            ghost
            className={styles.deleteButton}
            onClick={() => {
              this.props.onRemove(this.props.item.name);
            }}
          >
            <ICONS.Delete className={styles.icon} />

            <div className={styles.label}>刪除此波段</div>
          </Button>
        </div>

        <div className={styles.mainContent}>
          <div className={styles.row}>
            <div className={styles.bItem}>
              <FormatCell label="波段執案時間">
                <SelfFormItem name={[this.props.item.name, 'runDate']}>
                  <DatePicker.RangePicker size="small" />
                </SelfFormItem>
              </FormatCell>
            </div>

            <div className={styles.sItem}>
              <FormatCell label="本波派案期限">
                <SelfFormItem name={[this.props.item.name, 'overallDate']}>
                  <DatePicker size="small" />
                </SelfFormItem>
              </FormatCell>
            </div>

            <div className={styles.sItem}>
              <FormatCell label="本波訪員繳交期限">
                <SelfFormItem
                  name={[this.props.item.name, 'handOverDeadlineDate']}
                >
                  <DatePicker size="small" />
                </SelfFormItem>
              </FormatCell>
            </div>
          </div>

          <div className={styles.row}>
            <FormatCell label="波段專案交付物">
              <SelfFormItem name={[this.props.item.name, 'deliverables']}>
                <Checkbox.Group options={this.deliverables} />
              </SelfFormItem>

              {/* 查檢表 */}
              <div className={clsx([styles.desc, styles.checkFormDesc])}>
                <SelfFormItem name={[this.props.item.name, 'checkFormDesc']}>
                  <Input size="small" maxLength={50} />
                </SelfFormItem>
              </div>

              {/* 稽查原始報告 */}
              <div className={clsx([styles.desc, styles.originalReportDesc])}>
                <div
                  className={clsx([
                    styles.label,
                    this.props.disabled && styles.disabled
                  ])}
                >
                  , 檔案格式
                </div>

                <SelfFormItem
                  name={[this.props.item.name, 'originalReportDesc']}
                >
                  <Input size="small" maxLength={50} />
                </SelfFormItem>
              </div>

              {/* 統計 */}
              <div className={clsx([styles.desc, styles.statisticsFileDesc])}>
                <SelfFormItem
                  name={[this.props.item.name, 'statisticsFileDesc']}
                >
                  <Input size="small" maxLength={50} />
                </SelfFormItem>
              </div>

              {/* 分析報告 */}
              <div className={clsx([styles.desc, styles.analysisReportDesc])}>
                <SelfFormItem
                  name={[this.props.item.name, 'analysisReportDesc']}
                >
                  <Input size="small" maxLength={50} />
                </SelfFormItem>
              </div>

              {/* 簡報 */}
              <div className={clsx([styles.desc, styles.powerPointFileDesc])}>
                <SelfFormItem
                  name={[this.props.item.name, 'powerPointFileDesc']}
                >
                  <Input size="small" maxLength={50} />
                </SelfFormItem>
              </div>

              {/* 照片 */}
              <div className={clsx([styles.desc, styles.photosFileDesc])}>
                <SelfFormItem name={[this.props.item.name, 'photosFileDesc']}>
                  <Input size="small" maxLength={50} />
                </SelfFormItem>
              </div>

              {/* 影片 */}
              <div className={clsx([styles.desc, styles.videoFileDesc])}>
                <SelfFormItem name={[this.props.item.name, 'videoFileDesc']}>
                  <Input size="small" maxLength={50} />
                </SelfFormItem>
              </div>

              {/* 後製影片檔 */}
              <div
                className={clsx([
                  styles.desc,
                  styles.postProductionVideoFileDesc
                ])}
              >
                <SelfFormItem
                  name={[this.props.item.name, 'postProductionVideoFileDesc']}
                >
                  <Input size="small" maxLength={50} />
                </SelfFormItem>
              </div>

              {/* 錄音 */}
              <div className={clsx([styles.desc, styles.recordingFileDesc])}>
                <SelfFormItem
                  name={[this.props.item.name, 'recordingFileDesc']}
                >
                  <Input size="small" maxLength={50} />
                </SelfFormItem>
              </div>

              {/* 後製錄音檔 */}
              <div
                className={clsx([
                  styles.desc,
                  styles.postProductionRecordingFileDesc
                ])}
              >
                <SelfFormItem
                  name={[
                    this.props.item.name,
                    'postProductionRecordingFileDesc'
                  ]}
                >
                  <Input size="small" maxLength={50} />
                </SelfFormItem>
              </div>

              {/* 文件物品 */}
              <div className={clsx([styles.desc, styles.thingFileDesc])}>
                <SelfFormItem name={[this.props.item.name, 'thingFileDesc']}>
                  <Input size="small" maxLength={50} />
                </SelfFormItem>
              </div>

              {/* 商品 */}
              <div className={clsx([styles.desc, styles.commodityDesc])}>
                <SelfFormItem name={[this.props.item.name, 'commodityDesc']}>
                  <Input size="small" maxLength={50} />
                </SelfFormItem>
              </div>

              {/* 其他 */}
              <div className={clsx([styles.desc, styles.otherDesc])}>
                <SelfFormItem name={[this.props.item.name, 'otherDesc']}>
                  <Input size="small" maxLength={50} />
                </SelfFormItem>
              </div>
            </FormatCell>
          </div>

          <div className={styles.row}>
            <FormatCell label="波段交付物預知期限">
              <div className={styles.timesTable}>
                <div className={styles.row}>
                  <div className={clsx([styles.tableTitle, styles.sItem])}>
                    期限
                  </div>
                  <div className={clsx([styles.tableTitle, styles.bItem])}>
                    交付物
                  </div>
                  <div className={clsx([styles.tableTitle, styles.bItem])}>
                    其他交付物
                  </div>
                </div>

                <Form.List name={[this.props.item.name, 'handOverItemDates']}>
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        {fields.map((item) => {
                          return (
                            <div className={styles.row} key={item.key}>
                              <div
                                className={clsx([
                                  styles.tableContent,
                                  styles.sItem
                                ])}
                              >
                                <SelfFormItem name={[item.name, 'date']}>
                                  <DatePicker size="small" />
                                </SelfFormItem>
                              </div>

                              <div
                                className={clsx([
                                  styles.tableContent,
                                  styles.bItem
                                ])}
                              >
                                <SelfFormItem name={[item.name, 'items']}>
                                  <Select
                                    size="small"
                                    className={styles.select}
                                    mode="multiple"
                                    dropdownMatchSelectWidth={false}
                                    options={this.deliverables}
                                  />
                                </SelfFormItem>
                              </div>

                              <div
                                className={clsx([
                                  styles.tableContent,
                                  styles.bItem
                                ])}
                              >
                                <SelfFormItem name={[item.name, 'other']}>
                                  <Input size="small" maxLength={50} />
                                </SelfFormItem>

                                <Button
                                  type="primary"
                                  ghost
                                  size="small"
                                  className={
                                    styles.deleteHandOverItemDateButton
                                  }
                                  onClick={() => {
                                    remove(item.name);
                                  }}
                                >
                                  <ICONS.Delete className={styles.deleteIcon} />
                                </Button>
                              </div>
                            </div>
                          );
                        })}

                        <div className={styles.addButtonContainer}>
                          <Button
                            className={styles.addTimeButton}
                            onClick={() => add()}
                          >
                            新增期限
                          </Button>
                        </div>
                      </>
                    );
                  }}
                </Form.List>
              </div>
            </FormatCell>
          </div>
        </div>
      </div>
    );
  }
}

WaveDetail.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

WaveDetail.defaultProps = {};

export default WaveDetail;
