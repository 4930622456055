import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from './styles.module.scss';

function MenuIcon(props) {
  return (
    <div className={clsx([styles.container, props.isActive && styles.active])}>
      {props.children}
    </div>
  );
}

MenuIcon.propTypes = {
  children: PropTypes.node,
  isActive: PropTypes.bool
};

MenuIcon.defaultProps = {
  children: null,
  isActive: false
};

export default MenuIcon;
