import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Cascader, Button, Input } from 'antd';
import withRouter from 'src/components/withRouter';
import EmptyData from 'src/components/EmptyData';
import CheckFormItem from './components/CheckFormItem';
import ReplyPageViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class ReplyPage extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new ReplyPageViewModel({
      router: props.router
    });
  }

  componentDidMount() {
    this.vm.didMount(this.props);
  }

  render() {
    return this.vm.isInit ? null : (
      <div className={styles.container}>
        {!this.vm.check.hasCases && (
          <div className={styles.emptyContainer}>
            <EmptyData />
          </div>
        )}

        {this.vm.check.hasCases && (
          <div className={styles.mainContent}>
            <div className={styles.basic}>
              <div className={styles.item}>
                <div className={styles.select}>
                  <div className={styles.label}>被指派的案件</div>

                  <Cascader
                    options={this.vm.options.assigned}
                    value={this.vm.assignedSelect}
                    onChange={this.vm.onChangeAssignedSelect}
                    disabled={this.vm.isAwait}
                  />
                </div>

                <div className={styles.buttons}>
                  <Button
                    className={styles.leftButton}
                    onClick={this.vm.onReject}
                    disabled={this.vm.disabled.reject}
                  >
                    拒絕
                  </Button>

                  <Button
                    type="primary"
                    onClick={this.vm.onAccept}
                    disabled={this.vm.disabled.resolve}
                  >
                    接受
                  </Button>
                </div>
              </div>

              <div className={styles.item}>
                <div className={styles.select}>
                  <div className={styles.label}>已接受的案件</div>

                  <Cascader
                    options={this.vm.options.accepted}
                    value={this.vm.acceptedSelect}
                    onChange={this.vm.onChangeAcceptedSelect}
                    disabled={this.vm.isAwait}
                  />
                </div>

                <div className={styles.buttons}>
                  <Button
                    type="primary"
                    onClick={this.vm.onCaseAction}
                    disabled={this.vm.disabled.accepted}
                  >
                    開始稽查
                  </Button>
                </div>
              </div>

              <div className={styles.item}>
                <div className={styles.select}>
                  <div className={styles.label}>稽查中的案件</div>

                  <Cascader
                    options={this.vm.options.progress}
                    value={this.vm.progressSelect}
                    onChange={this.vm.onChangeProgressSelect}
                    disabled={this.vm.isAwait}
                  />
                </div>
              </div>

              <div className={styles.item}>
                <div className={styles.select}>
                  <div className={styles.label}>退件的案件</div>

                  <Cascader
                    options={this.vm.options.returned}
                    value={this.vm.returnedSelect}
                    onChange={this.vm.onChangeReturnedSelect}
                    disabled={this.vm.isAwait}
                  />
                </div>
              </div>
            </div>

            {/* 查檢表題目與答案 */}
            {this.vm.check.hasCheckFormItems && (
              <div className={styles.checkFormItems}>
                {this.vm.checkFormItems.map((vm) => {
                  return (
                    <CheckFormItem
                      vm={vm}
                      key={vm.id}
                      disabled={this.vm.disabled.checkFormItem}
                    />
                  );
                })}

                <div className={styles.buttons}>
                  <Button
                    className={styles.save}
                    type="primary"
                    onClick={this.vm.onSave}
                    disabled={this.vm.isAwait}
                  >
                    儲存答案
                  </Button>

                  <Button
                    onClick={this.vm.onReplyEnd}
                    disabled={this.vm.isAwait}
                  >
                    開始審核
                  </Button>
                </div>
              </div>
            )}

            <div className={styles.repliedContainer}>
              <div className={styles.from}>
                <div className={styles.label}>請輸入案件 id</div>

                <div className={styles.inputContainer}>
                  <Input
                    value={this.vm.repliedCaseId}
                    onChange={this.vm.onChangeRepliedCaseId}
                    disabled={this.vm.isAwait}
                  />
                </div>

                <div className={styles.buttonContainer}>
                  <Button
                    type="primary"
                    onClick={this.vm.onReplied}
                    disabled={this.vm.replied}
                  >
                    開始查詢
                  </Button>
                </div>
              </div>

              <div className={styles.checkFormItems}>
                {this.vm.repliedCheckFormItems.map((vm) => {
                  return <CheckFormItem vm={vm} key={vm.id} disabled />;
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

ReplyPage.propTypes = {
  router: PropTypes.object.isRequired
};

ReplyPage.defaultProps = {};

export default withRouter(ReplyPage);
