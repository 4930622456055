import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Modal } from 'antd';
import clsx from 'clsx';
import styles from './styles.module.scss';
import './reset.scss';

@observer
class SimpleModal extends React.Component {
  render() {
    const { vm, children, wrapClassName, ...ps } = this.props;

    return (
      <Modal
        {...ps}
        wrapClassName={clsx(['simpleModal', wrapClassName && wrapClassName])}
        open={vm.isShow}
      >
        {children}
      </Modal>
    );
  }
}

SimpleModal.propTypes = {
  vm: PropTypes.object.isRequired,
  children: PropTypes.node,
  wrapClassName: PropTypes.string,
  centered: PropTypes.bool,
  maskClosable: PropTypes.bool
};

SimpleModal.defaultProps = {
  children: null,
  wrapClassName: null,
  centered: true,
  maskClosable: false
};

export default SimpleModal;
