import { message } from 'antd';
import CustomError from './CustomError';

class ErrorServices {
  // 登入
  static postAccountLogin(error) {
    switch (error.response?.status) {
      case 403: {
        switch (error.response?.data?.code) {
          case '0x1002': {
            const msg = '帳號已被停用';

            message.error(msg, 5);

            return msg;
          }

          default:
            return CustomError.fromError('其他錯誤').toString();
        }
      }

      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 讀取自己的資料
  static getProfile(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 停用和啟用帳號
  static putAccountDisable(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 讀取指定帳號資料
  static getAccountProfile(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 新增帳號
  static postAccountCreate(error, type = 'user') {
    switch (error.response?.status) {
      case 400: {
        const msg = '有欄位格式錯誤, 請重新檢視';

        message.error(msg);

        return msg;
      }

      case 409: {
        switch (error.response?.data?.code) {
          case '0x2003': {
            const userMsg = '身分證已經存在, 請重新檢視';
            const clientMsg = '企業帳號已存在，請重新檢視';
            const msg = type === 'user' ? userMsg : clientMsg;

            message.error(msg);

            return msg;
          }

          default: {
            const msg = '其他錯誤';

            message.error(msg);

            return msg;
          }
        }
      }

      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  static putUserCaseCustomerViewable(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 讀取公告列表
  static getAnnouncementList(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 更新公告
  static putAnnouncement(error) {
    switch (error.response?.status) {
      case 400: {
        const msg = '已達字數上限';

        message.error(msg, 5);

        return msg;
      }

      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 讀取訪員列表
  static postVisitorList(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 新增訪員
  static postVisitorCreateBatch(error) {
    switch (error.response?.status) {
      case 409: {
        const msg
          = '新增/匯入失敗！您新增/匯入的訪員資料中有已存在的帳號，請確認後再操作';

        message.error(msg, 5);

        return msg;
      }

      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 更新訪員
  static putVisitorUpdate(error) {
    switch (error.response?.status) {
      case 500: {
        const msg = '有欄位資料過多, 請重新檢視';

        message.error(msg, 5);

        return msg;
      }

      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 讀取訪員任務
  static getVisitorCase(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 讀取員工列表
  static postEmployeeList(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 更新員工
  static putEmployeeUpdate(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 讀取專案列表
  static postProjectList(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 新增專案
  static putProjectCreate(error) {
    switch (error.response?.status) {
      case 409: {
        const msg = '專案名稱已存在';

        message.error(msg, 5);

        return msg;
      }

      case 500: {
        switch (error.response?.data?.code) {
          case '0x5': {
            const msg = '專案名稱過長';

            message.error(msg, 5);

            return msg;
          }

          default: {
            const msg = '其他錯誤';

            return msg;
          }
        }
      }

      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 讀取專案基本 detail
  static getProjectDetail(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 更新專案名稱
  static postProjectUpdateName(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 讀取總規格表
  static getProjectSpecification(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 更新總規格表
  static postProjectSpecification(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 更新專案簡述
  static postProjectUpdateAbout(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 更新專案備註
  static postProjectUpdateComment(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 更新執案指導資訊
  static postProjectUpdateGuidance(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 更新專案溝通與說明紀錄
  static postProjectUpdateMessage(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 專案推進或是退回
  static postProjectAction(error) {
    switch (error.response?.status) {
      case 400:
        switch (error.response?.data?.code) {
          case '0x7006':
            return '專案總規格表尚未完成, 請重新檢視';

          case '0x7010':
            return '專案損益表尚未完成, 請重新檢視';

          case '0x7011':
            return '案件尚未全部完成, 請重新檢視';

          case '0x7012':
            return '專案結案表尚未完成, 請重新檢視';

          default:
            return '有表單尚未完成, 請重新檢視';
        }

      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 更新專案訪客問卷
  static postProjectUpdateQuest(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 讀取企業(客戶)列表
  static postCompanyList(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 讀取企業(客戶)列表, 精簡版(作為選項使用)
  static postCompanyListLite(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 更新企業
  static putCompanyUpdate(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 讀取損益表
  static getProfitForm(error) {
    switch (error.response?.status) {
      case 404:
        return '尚未建立損益表';

      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 更新損益表
  static postProfitFormUpsert(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 申請損益表修改
  static postCreateProfitFormEven(error) {
    switch (error.response?.status) {
      case 400: {
        switch (error.response?.data?.code) {
          case '0x8005': {
            const msg = '已有申請紀錄';

            message.error(msg, 5);

            return msg;
          }

          default: {
            return CustomError.fromError('其他錯誤').toString();
          }
        }
      }

      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 接受損益表修改要求
  static postAcceptProfitFormEven(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 接受損益表修改要求
  static postRejectProfitFormEven(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 查檢表

  // 讀取查檢表所有題目
  static getCheckFormItem(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 新增查檢表題目
  static postCheckFormItem(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 更新查檢表題目
  static putCheckFormItem(error) {
    switch (error.response?.status) {
      case 500: {
        switch (error.response?.data?.code) {
          case '0x5': {
            const msg = '已超過後端設定的上限';

            message.error(msg);

            return msg;
          }

          default:
            console.log('error: ', error);

            return CustomError.fromError('其他錯誤').toString();
        }
      }

      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 刪除查檢表題目
  static deleteCheckFormItem(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 查檢表完成
  static putCheckFormCompleted(error) {
    switch (error.response?.status) {
      case 400:
        return '加權佔比設定有誤，請檢視並調整';

      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 更新雲端連結
  static putCheckFormAttached(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 會出查檢表結果
  static getCheckFormExport(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 讀取查檢表列表
  static getCheckFormList(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 複製其他專案的查檢表
  static postCheckFormItemImport(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 修改查檢表答案
  static postCheckFormReplyAdmin(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 客戶統計使用的查檢表列表
  static getCheckFormReplyList(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 結案表

  // 讀取結案表
  static getClosingSheet(error) {
    switch (error.response?.status) {
      case 404:
        return '尚未建立結案表';

      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 更新結案表
  static postClosingSheet(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 案件

  // 讀取案件列表
  static getCase(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 新增案件
  static postCaseCreate(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 批次新增案件
  static postCaseCreateBatch(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 刪除案件
  static deleteCase(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 更新案件
  static putCase(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 案件推進(選擇派派遣訪員)
  static postCaseAction(error) {
    switch (error.response?.status) {
      case 400: {
        switch (error.response?.data?.code) {
          case '0xa011': {
            const msg = '查檢表尚未完成, 請重新檢視';

            message.error(msg, 5);

            return msg;
          }

          default:
            console.log('error: ', error);

            return CustomError.fromError('其他錯誤').toString();
        }
      }

      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 讀取案件詳情
  static getCaseDetail(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // pm 備註
  static putCaseContent(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 讀取通知列表
  static getMessageList(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 通知已讀
  static putMessageRead(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 統計相關

  // 新增統計列表
  static postStatisticsTimeList(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 讀取統計列表
  static getStatisticsTimeList(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 讀取全部統計資料
  static getStatisticsData(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 讀取訪員案件
  static getUserCase(error) {
    switch (error.response?.status) {
      case 404: {
        const msg = '沒有案件資料';

        message.error(msg, 5);

        return msg;
      }

      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 訪員接受案件
  static putUserCaseAccept(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 訪員拒絕案件
  static putUserCaseReject(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 訪員推進案件
  static putUserCaseAction(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 訪員讀取查檢表答案
  static getCheckFormReply(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // 訪員回答問題
  static postCheckFormReply(error) {
    switch (error.response?.status) {
      default:
        console.log('error: ', error);

        return CustomError.fromError('其他錯誤').toString();
    }
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
}

export default ErrorServices;
