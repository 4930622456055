import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { Tabs, Button } from 'antd';
import { SITE_TYPES } from 'src/global/constants';
import withProfile from 'src/components/withProfile';
import withRouter from 'src/components/withRouter';
import CustomBreadcrumb from 'src/components/CustomBreadcrumb';
import CustomHeader from 'src/components/CustomHeader';
import WarningModal from 'src/components/WarningModal';
import SiteTab from './components/SiteTab';
import CheckDetailPageViewModel from './viewModel';
import CreateTopicDrawer from './components/CreateTopicDrawer';
import BringInCheckFormDrawer from './components/BringInCheckFormDrawer';
import HintLabel from './components/HintLabel';
import styles from './styles.module.scss';
import './reset.scss';

@observer
class CheckDetailPage extends React.Component {
  @computed
  get tabs() {
    return [
      {
        key: SITE_TYPES.onSite.value,
        label: (
          <HintLabel label="現場稽查" showIcon={this.vm.invalids.onSite} />
        ),
        children: (
          <SiteTab
            data={this.vm.dataVM.onSiteData}
            site={SITE_TYPES.onSite.value}
            createDrawerVM={this.vm.createDrawerVM}
            onCreated={this.vm.dataVM.onCreated}
            disabled={this.vm.disabled.edit}
            attached={this.vm.onSiteAttached}
          />
        )
      },
      {
        key: SITE_TYPES.offSite.value,
        label: (
          <HintLabel label="非現場稽查" showIcon={this.vm.invalids.offSite} />
        ),
        children: (
          <SiteTab
            data={this.vm.dataVM.offSiteData}
            site={SITE_TYPES.offSite.value}
            createDrawerVM={this.vm.createDrawerVM}
            onCreated={this.vm.dataVM.onCreated}
            disabled={this.vm.disabled.edit}
            attached={this.vm.offSiteAttached}
          />
        )
      }
    ];
  }

  constructor(props) {
    super(props);
    this.vm = new CheckDetailPageViewModel({
      profile: props.profile,
      router: props.router
    });
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return this.vm.check.isShow ? (
      <div id="checkDetailPage" className={styles.container}>
        <CustomBreadcrumb routes={this.vm.routes} />

        <CustomHeader title={this.vm.title} hasBack link={this.vm.backLink} />

        <div className={styles.mainContent}>
          {!this.vm.disabled.edit && (
            <div className={styles.bringInContainer}>
              <BringInCheckFormDrawer
                vm={this.vm.bringInCheckFormDrawerVM}
                project={this.vm.project}
                onChanged={this.vm.onChanged}
              />
            </div>
          )}

          <Tabs items={this.tabs} />

          {!this.vm.disabled.edit && (
            <div className={styles.saveButtonContainer}>
              <Button
                type="primary"
                onClick={this.vm.onFinish}
                disabled={this.vm.isAwait}
              >
                發布
              </Button>

              <div className={styles.label}>
                發布後便不可再修改查檢表，請謹慎確認後再點擊發布！
              </div>
            </div>
          )}
        </div>

        <CreateTopicDrawer
          vm={this.vm.createDrawerVM}
          projectId={this.vm.projectId}
        />

        <WarningModal
          vm={this.vm.finishModalVM}
          content="「發布」後查檢表便不可再編輯，確定要繼續嗎？"
          onConfirm={this.vm.onFinishModalConfirm}
          disabledConfirm={this.vm.isAwait}
        />
      </div>
    ) : null;
  }
}

CheckDetailPage.propTypes = {
  profile: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired
};

CheckDetailPage.defaultProps = {};

export default withProfile(withRouter(CheckDetailPage), true);
