import { makeObservable, observable, action } from 'mobx';

class CustomModalViewModel {
  @observable isShow = false;

  constructor() {
    makeObservable(this);
  }

  @action
  show = () => {
    this.isShow = true;
  };

  @action
  hide = () => {
    this.isShow = false;
  };

  @action
  onCancel = (e) => {
    const tagName = e.target.tagName;
    const isClose
      = tagName === 'svg' || tagName === 'SPAN' || tagName === 'path';

    // console.log('modal', 'onCancel', 'tagName', tagName);

    if (isClose) {
      this.hide();
    }
  };
}

export default CustomModalViewModel;
