// 損益表

// 專案件酬試算, 固定加給
export const ALLOWANCES = [
  {
    label: '基數'
  },

  {
    label: '跨級基數'
  },

  {
    label: '其他門檻基數'
  },

  {
    label: '單照加給'
  },

  {
    label: 'TSAA+SGS加給'
  },

  {
    label: 'TSAA+SGS＋CMA三選2加給'
  },

  {
    label: '其他證照資格加給'
  },

  {
    label: '其他證照資格組合加給'
  },

  {
    label: '自備攝影器材加給'
  },

  {
    label: '自備錄音器材加給'
  },

  {
    label: '其他專案條件加給',
    placeholder: 'ex:保戶/車主/雙人…'
  },

  {
    label: '交通補貼上限'
  },

  {
    label: '交通補貼定額'
  },

  {
    label: '消費補貼上限'
  },

  {
    label: '消費補貼定額'
  },

  {
    label: '通話補貼定額'
  },

  {
    label: '回寄郵資補貼定額'
  },

  {
    label: '其他補貼'
  }
];

// 專案損益表, 固定項目
export const INCOMES = [
  { label: '訪員件酬' },
  { label: '消費補助' },
  { label: '交通補助' },
  { label: '電話補助' },
  { label: '報告偵錯' },
  { label: '統計分析' },
  { label: '製作報告' },
  { label: '影片品管' },
  { label: '影片後製' },
  { label: '光碟燒錄' },
  { label: '雲端整理' },
  { label: '退換郵資' },
  { label: '報告印製' },
  { label: '顧問簡報' },
  { label: '稽後輔導' },
  { label: '講師費用' },
  { label: '場租費用' },
  { label: '器材費用' },
  { label: '助教費用' },
  { label: '住宿費用' },
  { label: '交通費用' },
  { label: '郵資費用' },
  { label: '失敗費用' },
  { label: '雜項費用' },
  { label: '交際費' },
  { label: '回饋金' },
  { label: '印花稅' },
  { label: '保險費' },
  { label: '獎金' }
];

// 專案損益表, 申請修改預算的狀態
export const APPLY_EDIT_INCOME_STATUS = {
  apply: {
    value: 'apply',
    label: '變更預算審核'
  },

  accept: {
    value: 'accept',
    label: '申請通過'
  },

  reject: {
    value: 'reject',
    label: '申請失敗'
  },

  cancel: {
    value: 'cancel',
    label: '申請取消'
  }
};

// 行業別
export const INDUSTRIES = {
  hypermarket: { value: 'hypermarket', label: '大型量販店' },
  amusementCenter: { value: 'amusementCenter', label: '大型遊樂中心' },
  shoppingSite: { value: 'shoppingSite', label: '購物網站' },
  departmentStore: { value: 'departmentStore', label: '百貨/購物中心' },
  carRental: { value: 'carRental', label: '汽車租賃' },
  carShop: { value: 'carShop', label: '汽車賣場' },
  familyLife: { value: 'familyLife', label: '居家生活' },
  directSelling: { value: 'directSelling', label: '直銷' },
  convenienceStore: { value: 'convenienceStore', label: '便利商店' },
  fastFood: { value: 'fastFood', label: '中/日式速食' },
  hotPot: { value: 'hotPot', label: '火鍋' },
  westernBreakfast: { value: 'westernBreakfast', label: '西式早餐' },
  westernFastFood: { value: 'westernFastFood', label: '西式速食' },
  coffee: { value: 'coffee', label: '咖啡' },
  housingAgency: { value: 'housingAgency', label: '房仲' },
  apparel: { value: 'apparel', label: '服飾' },
  restaurant: { value: 'restaurant', label: '餐廳' },
  glasses: { value: 'glasses', label: '眼鏡' },
  supermarket: { value: 'supermarket', label: '超商' },
  telecommunications: { value: 'telecommunications', label: '電信' },
  cosmeceutical: { value: 'cosmeceutical', label: '藥妝/局' },
  hotel: { value: 'hotel', label: '飯店' },
  insurance: { value: 'insurance', label: '壽險' },
  bank: { value: 'bank', label: '銀行' },
  hospital: { value: 'hospital', label: '醫院' },
  securities: { value: 'securities', label: '證券' },
  electricLocomotive: { value: 'electricLocomotive', label: '電動機車' },
  bake: { value: 'bake', label: '烘焙' },
  aviation: { value: 'aviation', label: '航空' },
  government: { value: 'government', label: '政府' },
  evaluation: { value: 'evaluation', label: '評鑑' },
  other: { value: 'other', label: '其他' }
};

// 場域選擇
export const PLACES = {
  onSite: {
    value: 'onSite',
    label: '現場'
  },
  telephone: { value: 'telephone', label: '電話' },
  telephoneInterview: { value: 'telephoneInterview', label: '市調電訪' },
  network: { value: 'network', label: '網路' },
  app: { value: 'app', label: 'APP' },
  community: { value: 'community', label: '社群' },
  other: { value: 'other', label: '其他' }
};
