import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction
} from 'mobx';
import { diff } from 'deep-diff';
import { CASE_STATUS } from 'src/global/case';
import CheckFormServices from 'src/services/CheckFormServices';
import ErrorServices from 'src/services/ErrorServices';
import CheckFormModalViewModel from 'src/components/CheckFormModal/viewModel';

class CheckItemViewModel {
  @observable project = null;
  @observable case = null;
  @observable checkParams = null;

  @observable topics = [];

  @observable isAwait = false;

  router = null;

  checkFormModalVM = new CheckFormModalViewModel();

  @computed
  get isShow() {
    switch (this.case.step) {
      case CASE_STATUS.S7.value:
      case CASE_STATUS.S8.value:
      case CASE_STATUS.S9.value:
      case CASE_STATUS.S10.value:
      case CASE_STATUS.S11.value:
      case CASE_STATUS.S12.value: {
        // 是營管部
        const isOM = this.checkParams.isOM;

        return !isOM;
      }

      default:
        return false;
    }
  }

  @computed
  get checkFormEdit() {
    switch (this.case.step) {
      case CASE_STATUS.S8.value:
      case CASE_STATUS.S10.value:
        return this.checkParams.isBoss || this.checkParams.isPM;

      default:
        return false;
    }
  }

  @computed
  get disabled() {
    return {
      checkForm: this.isAwait,
      button: this.isAwait
    };
  }

  @computed
  get label() {
    return {
      itemButton: this.checkFormEdit ? '編輯' : '查看'
    };
  }

  constructor(props) {
    this.project = props.project;
    this.router = props.router;

    this.case = props.case;
    this.checkParams = props.checkParams;

    makeObservable(this);
  }

  didMount = () => {
    this.getCheckFormReplyAPI();
  }

  @action
  didUpdate = (props, preProps) => {
    const diffCase = diff(props.case, preProps.case);

    if (diffCase) {
      this.case = props.case;
    }
  };

  onModalOpen = async () => {
    await this.getCheckFormReplyAPI();

    this.checkFormModalVM.open({
      topics: this.topics,
      auditStartTime: this.auditStartTime,
      auditEndTime: this.auditEndTime
    });
  };

  onSave = () => {
    this.postCheckFormReplyAdminAPI();
  };

  // 讀取茶查檢表答案
  @action
  getCheckFormReplyAPI = async () => {
    this.isAwait = true;

    try {
      const res = await CheckFormServices.getCheckFormReply({
        caseId: this.case.caseId
      });

      runInAction(() => {
        this.topics = res.data.checkFormItem;

        this.auditStartTime = res.auditStartTime;
        this.auditEndTime = res.auditEndTime;
      });
    } catch (error) {
      const msg = ErrorServices.getCheckFormReply(error);

      console.log('CheckItemViewModel', 'getCheckFormReply', msg);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };

  // 更新查檢表答案
  @action
  postCheckFormReplyAdminAPI = async () => {
    this.isAwait = true;

    try {
      const res = await CheckFormServices.postCheckFormReplyAdmin({
        caseId: this.case.caseId,
        visitorId: this.case.visitorId,
        reply: this.checkFormModalVM.res.reply
      });

      this.checkFormModalVM.close();
    } catch (error) {
      const msg = ErrorServices.postCheckFormReplyAdmin(error);

      console.log('CheckItemViewModel', 'postCheckFormReplyAdmin', msg);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default CheckItemViewModel;
