import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { RE_NUMBER_DECIMAL } from 'src/global/constants';
import {
  TOPIC_TYPES,
  IMPLEMENTATION_TYPES,
  SATISFACTION_TYPES
} from 'src/global/topic';
import CustomModalViewModel from 'src/components/CustomModal/viewModel';
import CheckFormServices from 'src/services/CheckFormServices';
import ErrorServices from 'src/services/ErrorServices';

const RES = {
  number: /^[0-9]{1,}$/,
  numberDecimal: /^[0-9]{1,}.([0-9]{1,2})?$/
};

// 分數題 vm
class FractionAnswerViewModel {
  data = null;

  // id + 資料
  id = null;
  projectId = null;
  site = null;
  facet = null;
  inferiorFacet = null;

  // 類型
  @observable type = null;

  // 問題與內容
  @observable topic = null;
  @observable content = null;

  // 落實度
  @observable implementation = null;
  @observable implementWeighted = null;

  // 滿意度
  @observable satisfaction = null;
  @observable satisWeighted = null;

  deleteModalVM = new CustomModalViewModel();

  onDeleted = null;

  // 加分題
  @computed
  get isBonus() {
    return this.type === TOPIC_TYPES.bonus.value;
  }

  @computed
  get check() {
    return {
      hasImplementWeighted: !!this.implementWeighted?.trim(),
      hasSatisWeighted: !!this.satisWeighted?.trim()
    };
  }

  // 這個題目是不是無效
  @computed
  get invalid() {
    const res = [];
    const topic = {
      label: 'topic',
      key: 'topic',
      value: this.topic
    };
    const implementation = {
      label: 'implementation',
      key: this.implementation,
      value: this.implementWeighted
    };
    const satisfaction = {
      label: 'satisfaction',
      key: this.satisfaction,
      value: this.satisWeighted
    };

    // 檢查題目
    res.push(topic);

    // 落實度
    if (this.implementation) {
      res.push(implementation);
    }

    // 滿意度
    if (this.satisfaction) {
      res.push(satisfaction);
    }

    // 題目 + 分數, 至少會有兩個
    if (res.length < 2) {
      return true;
    }

    // 檢查題目或分數要填完整
    const findFinish = res.find((item) => {
      // null or string
      return !item.value?.trim();
    });

    if (findFinish) {
      return true;
    }

    return false;
  }

  @computed
  get options() {
    const implementation = [IMPLEMENTATION_TYPES[1]];
    const satisfaction = [SATISFACTION_TYPES[1], SATISFACTION_TYPES[2]];

    if (this.isBonus) {
      return {
        implementation: [...implementation, IMPLEMENTATION_TYPES[0]],
        satisfaction: [...satisfaction, SATISFACTION_TYPES[0]]
      };
    }

    return {
      implementation,
      satisfaction
    };
  }

  // 場地分數
  @computed
  get siteFraction() {
    const checkImplementWeighted = !!this.implementWeighted;
    const implementWeighted = checkImplementWeighted
      ? this.implementWeighted
      : 0;

    const checkSatisWeighted = !!this.satisWeighted;
    const satisWeighted = checkSatisWeighted ? this.satisWeighted : 0;

    return {
      isBonus: this.isBonus,
      implementWeighted,
      satisWeighted
    };
  }

  // 構面分數
  @computed
  get facetFraction() {
    const checkImplementWeighted
      = !this.isBonus && this.checkNumber(this.implementWeighted);
    const implementWeighted = checkImplementWeighted
      ? this.implementWeighted
      : 0;

    const checkSatisWeighted
      = !this.isBonus && this.checkNumber(this.satisWeighted);
    const satisWeighted = checkSatisWeighted ? this.satisWeighted : 0;

    return {
      implementWeighted,
      satisWeighted
    };
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  constructor(props) {
    this.init(props);
    makeObservable(this);
  }

  @action
  init = (props) => {
    const data = props.data;
    // 必定會有
    const onDeleted = props.onDeleted;

    // 保留資料
    this.data = data;

    // 必定會有
    this.id = data.id;
    this.projectId = data.projectId;
    this.type = data.type;
    this.site = data.site;
    this.facet = data.facet;
    this.inferiorFacet = data.inferiorFacet;

    this.topic = data.topic || null;
    this.content = data.content || null;

    this.implementation = data.implementation || null;
    this.implementWeighted = data.implementWeighted || null;

    this.satisfaction = data.satisfaction || null;
    this.satisWeighted = data.satisWeighted || null;

    this.onDeleted = onDeleted;
  };

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  checkNumber = (value) => {
    return !!value;
  };

  // 加分題
  @action
  onChangeBonus = (event) => {
    const checked = event.target.checked;

    this.bonus = event.target.checked;

    // 刪除自訂輸入
    if (!checked) {
      this.bonusRun = false;
      this.bonusRunRatio = null;

      this.bonusSatisfy = false;
      this.bonusSatisfyRatio = null;
    }
  };

  @action
  onChangeTopic = (event) => {
    this.topic = event.target.value;
  };

  @action
  onChangeContent = (event) => {
    this.content = event.target.value;
  };

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 加分題
  @action
  setBonus = (event) => {
    const checked = event.target.checked;

    if (checked) {
      this.type = TOPIC_TYPES.bonus.value;
    } else {
      // 取消加分題
      this.type = TOPIC_TYPES.fraction.value;

      // 刪除自訂配分
      if (this.implementation === IMPLEMENTATION_TYPES[0].value) {
        this.implementation = null;
        this.implementWeighted = null;
      }

      // 刪除自訂配分
      if (this.satisfaction === SATISFACTION_TYPES[0].value) {
        this.satisfaction = null;
        this.satisWeighted = null;
      }
    }
  };

  // 落實度
  @action
  setImplementation = (value) => {
    const check = this.implementation === value;

    if (check) {
      this.implementation = null;
    } else {
      this.implementation = value;
    }

    this.implementWeighted = null;

    this.postUpdateDataAPI(true, false);
  };

  @action
  onChangeImplementWeighted = (event) => {
    const value = event.target.value;
    const check1 = RES.number.test(value);
    const check2 = RES.numberDecimal.test(value);
    const check3 = !value;
    const inMax = Number(value) <= 255;

    if (check1 || check2 || check3) {
      this.implementWeighted = inMax ? value : '255';
    }
  };

  // 滿意度
  @action
  setSatisfaction = (value) => {
    const check = this.satisfaction === value;

    if (check) {
      this.satisfaction = null;
    } else {
      this.satisfaction = value;
    }

    this.satisWeighted = null;

    this.postUpdateDataAPI(false, true);
  };

  @action
  onChangeSatisWeighted = (event) => {
    const value = event.target.value;
    const check1 = RES.number.test(value);
    const check2 = RES.numberDecimal.test(value);
    const check3 = !value;
    const inMax = Number(value) <= 255;

    if (check1 || check2 || check3) {
      this.satisWeighted = inMax ? value : '255';
    }
  };

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  @action
  onBlur = () => {
    if (!this.implementWeighted || Number(this.implementWeighted) === 0) {
      this.implementWeighted = null;
      this.implementation = null;
    }

    if (!this.satisWeighted || Number(this.satisWeighted) === 0) {
      this.satisWeighted = null;
      this.satisfaction = null;
    }

    // 避免分數最後一個字元是小數點
    if (this.implementWeighted) {
      const isNaN = Number.isNaN(Number(this.implementWeighted));
      const val = isNaN ? null : String(Number(this.implementWeighted));

      this.implementWeighted = val;
    }

    if (this.satisWeighted) {
      const isNaN = Number.isNaN(Number(this.satisWeighted));
      const val = isNaN ? null : String(Number(this.satisWeighted));

      this.satisWeighted = val;
    }

    this.postUpdateDataAPI();
  };

  onDeleteConfirm = () => {
    this.deleteCheckFormItemAPI();
  };

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 更新題目
  postUpdateDataAPI = async (
    clearImplement = false,
    clearSatisfaction = false
  ) => {
    try {
      const res = await CheckFormServices.putCheckFormItem({
        id: this.id,
        type: this.type,
        implementation: clearImplement ? null : this.implementation,
        implementWeighted: clearImplement ? null : this.implementWeighted,
        satisfaction: clearSatisfaction ? null : this.satisfaction,
        satisWeighted: clearSatisfaction ? null : this.satisWeighted,
        topic: this.topic,
        content: this.content
      });

      console.log(res);
    } catch (error) {
      const message = ErrorServices.putCheckFormItem(error);

      console.log('FractionAnswerViewModel', 'putCheckFormItem', message);
    }
  };

  // 刪除題目
  @action
  deleteCheckFormItemAPI = async () => {
    try {
      const res = await CheckFormServices.deleteCheckFormItem({ id: this.id });

      this.deleteModalVM.hide();
      this.onDeleted(this.id);
    } catch (error) {
      const message = ErrorServices.deleteCheckFormItem(error);

      console.log('FractionAnswerViewModel', 'deleteCheckFormItem', message);
    }
  };
}

export default FractionAnswerViewModel;
