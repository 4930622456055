import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Input, InputNumber, Radio } from 'antd';
import clsx from 'clsx';
import styles from './styles.module.scss';

@observer
class CheckFormItem extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
  }

  // 落實度
  renderImplementScore = () => {
    switch (this.vm.implementation) {
      case '0':
        return (
          <InputNumber
            value={this.vm.implementScore}
            onChange={this.vm.onChangeImplementScoreInputNumber}
            disabled={this.props.disabled}
            addonAfter={this.vm.inputNumber.implementWeighted}
            max={this.vm.inputNumber.implementWeighted}
            min={0}
          />
        );

      case '1':
        return (
          <Radio.Group
            value={this.vm.implementScore}
            onChange={this.vm.onChangeImplementScore}
            disabled={this.props.disabled}
          >
            <Radio value="0">0</Radio>
            <Radio value="1">1</Radio>
          </Radio.Group>
        );

      default:
        return null;
    }
  };

  // 滿意度
  renderSatisScore = () => {
    switch (this.vm.satisfaction) {
      case '0':
        return (
          <InputNumber
            value={this.vm.satisScore}
            onChange={this.vm.onChangeSatisScoreInputNumber}
            disabled={this.props.disabled}
            addonAfter={this.vm.inputNumber.satisWeighted}
            max={this.vm.inputNumber.satisWeighted}
            min={0}
          />
        );

      case '1':
        return (
          <Radio.Group
            value={this.vm.satisScore}
            onChange={this.vm.onChangeSatisScore}
            disabled={this.props.disabled}
          >
            <Radio value="1">1</Radio>
            <Radio value="2">2</Radio>
            <Radio value="3">3</Radio>
            <Radio value="4">4</Radio>
            <Radio value="5">5</Radio>
          </Radio.Group>
        );

      case '2':
        return (
          <Radio.Group
            value={this.vm.satisScore}
            onChange={this.vm.onChangeSatisScore}
            disabled={this.props.disabled}
          >
            <Radio value="0">0</Radio>
            <Radio value="0.25">0.25</Radio>
            <Radio value="0.5">0.5</Radio>
            <Radio value="0.75">0.75</Radio>
            <Radio value="1">1</Radio>
          </Radio.Group>
        );

      default:
        return null;
    }
  };

  render() {
    return (
      <div className={styles.container}>
        <div
          className={clsx([
            styles.borderContainer,
            this.vm.check.idBonus && styles.bonusBorderContainer
          ])}
        >
          <div className={styles.item}>
            <div className={styles.label}>場地</div>

            <div className={styles.content}>{this.vm.label.site}</div>
          </div>

          <div className={styles.item}>
            <div className={styles.label}>構面</div>

            <div className={styles.content}>{this.vm.label.facet}</div>
          </div>

          <div className={styles.item}>
            <div className={styles.label}>次構面</div>

            <div className={styles.content}>{this.vm.label.inferiorFacet}</div>
          </div>

          <div className={styles.item}>
            <div className={styles.label}>題目類型</div>

            <div className={styles.content}>{this.vm.label.type}</div>
          </div>

          <div className={styles.item}>
            <div className={styles.label}>題目</div>

            <div className={styles.content}>{this.vm.label.topic}</div>
          </div>

          <div className={styles.item}>
            <div className={styles.label}>說明</div>

            <div className={styles.content}>{this.vm.label.content}</div>
          </div>

          <div className={styles.item}>
            <div className={styles.label}>
              {this.vm.label.implementWeighted}
            </div>

            <div className={styles.content}>{this.renderImplementScore()}</div>
          </div>

          <div className={styles.item}>
            <div className={styles.label}>{this.vm.label.satisWeighted}</div>

            <div className={styles.content}>{this.renderSatisScore()}</div>
          </div>

          <div className={styles.item}>
            <div className={styles.label}>答案</div>

            <div className={styles.content}>
              <Input.TextArea
                rows={4}
                maxLength={500}
                showCount
                value={this.vm.content}
                onChange={this.vm.onChangeContent}
                disabled={this.props.disabled}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CheckFormItem.propTypes = {
  vm: PropTypes.object.isRequired,
  disabled: PropTypes.bool
};

CheckFormItem.defaultProps = {
  disabled: false
};

export default CheckFormItem;
