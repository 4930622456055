import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import clsx from 'clsx';
import withProfile from 'src/components/withProfile';
import withRouter from 'src/components/withRouter';
import CustomHeader from 'src/components/CustomHeader';
import CustomTable from 'src/components/CustomTable';
import CustomAnchor from 'src/components/CustomAnchor';
import NotificationPageViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class NotificationPage extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new NotificationPageViewModel({
      profile: props.profile,
      router: props.router
    });
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return (
      <div className={styles.container}>
        <CustomHeader title="專案通知" paddingBottom />

        <div className={styles.tableContainer}>
          <CustomTable
            data={this.vm.anchorVM.sliceData}
            onRow={(record, index) => {
              return {
                onClick: this.vm.onClickRow(record, index)
              };
            }}
          >
            <Table.Column
              width={300}
              title="標題"
              render={(item) => {
                return (
                  <div
                    className={clsx([
                      styles.titleContainer,
                      !item.isRead && 'selected'
                    ])}
                  >
                    {!item.isRead && <div className={styles.icon} />}
                    {item.title}
                  </div>
                );
              }}
            />

            <Table.Column
              title="內容"
              render={(item) => {
                return (
                  <div className={clsx([!item.isRead && 'selected'])}>
                    {item.content}
                  </div>
                );
              }}
            />

            <Table.Column
              width={160}
              title="時間"
              render={(item) => {
                return (
                  <div className={clsx([!item.isRead && 'selected'])}>
                    {item.createTimeFormat}
                  </div>
                );
              }}
            />
          </CustomTable>
        </div>

        <CustomAnchor vm={this.vm.anchorVM} />
      </div>
    );
  }
}

NotificationPage.propTypes = {
  profile: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired
};

NotificationPage.defaultProps = {};

export default withProfile(withRouter(NotificationPage), true);
