import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { USER_TYPES } from 'src/global/constants';
import CompanyServices from 'src/services/CompanyServices';
import ErrorServices from 'src/services/ErrorServices';
import CustomAnchorViewModel from 'src/components/CustomAnchor/viewModel';
import ClientViewModel from './viewModels/ClientViewModel';
import ClientDrawerViewModel from './components/ClientDrawer/viewModel';

class ClientPageViewModel {
  @observable profile = null;
  @observable data = [];
  @observable search = null;
  @observable isInit = true;
  @observable isAwait = false;

  clientDrawerVM = new ClientDrawerViewModel();
  anchorVM = null;

  @computed
  get check() {
    const boos = this.profile.type === USER_TYPES.boss.value;
    const salesperson = this.profile.type === USER_TYPES.salesperson.value;

    return {
      create: boos || salesperson
    };
  }

  @computed
  get tableData() {
    return this.anchorVM.sliceData.map((item) => new ClientViewModel(item));
  }

  @computed
  get searches() {
    const keyword = this.search?.trim() ? this.search : undefined;

    return {
      keyword
    };
  }

  constructor(props) {
    this.profile = props.profile;
    this.anchorVM = new CustomAnchorViewModel({ api: this.postCompanyListAPI });

    makeObservable(this);
  }

  didMount = () => {
    if (!this.isAwait) {
      this.anchorVM.reset();
    }
  }

  @action
  onChangeSearch = (event) => {
    this.search = event.target.value;
  }

  onSearch = () => {
    this.anchorVM.reset();
  }

  @action
  onCreated = () => {
    this.anchorVM.reset();
  }

  @action
  postCompanyListAPI = async ({ anchor }) => {
    this.isAwait = true;

    const res = await CompanyServices.postCompanyList({
      anchor,
      keyword: this.searches.keyword
    });

    runInAction(() => {
      this.isInit = false;
      this.isAwait = false;
    });

    return res;
  }
}

export default ClientPageViewModel;
