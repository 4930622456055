import dayjs from 'dayjs';

// 訪員任務一懶
class VisitorCaseListItemDataModel {
  constructor(props) {
    const startDateFormat = dayjs(props.startDate).format('YYYY.MM.DD');
    const expiryDateFormat = dayjs(props.expiryDate).format('YYYY.MM.DD');

    // 專案 id
    this.projectId = props.projectId;
    // 專案名稱
    this.projectName = props.name;
    // 專案開始時間
    this.startDate = props.startDate;
    // 專案結束時間
    this.expiryDate = props.expiryDate;

    // 案件總數
    this.totalCount = props.totalCount;

    // 現場案件總數
    this.onSiteCount = props.onSiteCount;
    // 現場案件, 拒絕總數
    this.onSiteRejected = props.onSiteRejected;
    // 現場案件, 接受總數
    this.onSiteAccepted = props.OnSiteAccepted;
    // 現場案件, 開始總數
    this.onSiteStarted = props.onSiteStarted;
    // 現場案件, 成功總數
    this.onSiteSucceed = props.onSiteSuccessed;

    // 非現場案件總數
    this.offSiteCount = props.offSiteCount;
    // 非現場案件, 拒絕總數
    this.offSiteRejected = props.offSiteRejected;
    // 非現場案件, 接受總數
    this.offSiteAccepted = props.OffSiteAccepted;
    // 非現場案件, 開始總數
    this.offSiteStarted = props.offSiteStarted;
    // 非現場案件, 成功總數
    this.offSiteSucceed = props.offSiteSuccessed;

    // ui 使用
    this.id = props.projectId;
    // 起迄日期
    this.startEndDate = `${startDateFormat}-${expiryDateFormat}`;
  }
}

export default VisitorCaseListItemDataModel;
