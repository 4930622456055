import dayjs from 'dayjs';

class ProjectListItemDataModel {
  constructor(props) {
    this.projectId = String(props.projectId);

    this.projectName = props.projectName;

    this.step = props.step;

    this.stepName = props.stpeName;

    this.handler = props.handler;

    this.companyId = props.companyId;

    this.companyName = props.companyName;

    this.updatedAt = props.updatedAt;

    this.pmName = props.pmName;

    // 問卷相關
    this.questPath = props.questPath;
    this.questUpdateTime = props.questUpdateTime;

    // ui 使用
    this.id = String(props.projectId);
    this.updatedAtFormat = dayjs(props.updatedAt).format('YYYY.MM.DD');
  }
}

export default ProjectListItemDataModel;
