import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Input } from 'antd';
import ICONS from 'src/global/icons';
import clsx from 'clsx';
import styles from './styles.module.scss';
import './reset.scss';

function SimpleTextArea(props) {
  const { size, ...ps } = props;
  const isSmall = size === 'small';

  return (
    <div id="simpleTextArea">
      <Input.TextArea
        className={clsx([isSmall && styles.small])}
        size={size}
        {...ps}
      />
    </div>
  );
}

SimpleTextArea.propTypes = {
  size: PropTypes.string
};

SimpleTextArea.defaultProps = {
  size: 'small'
};

export default observer(SimpleTextArea);
