import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import {
  SHOPPING_NEEDS,
  MEDIA_RECORD_NEEDS,
  PAYMENT_METHODS,
  UNIFORM_NUMBER_TYPES,
  RETURN_METHODS,
  CHECK_DATE_TYPES,
  INSPECTED_PERSON_NEED_TYPES,
  COMPLAINT_REQUIREMENTS,
  COMPLAINANT_REQUIREMENTS,
  CLAIMS
} from 'src/global/form';

class VisitorWorksViewModel {
  @computed
  get mapOptions() {
    return {
      shoppingNeeds: Object.values(SHOPPING_NEEDS),
      mediaRecordNeeds: Object.values(MEDIA_RECORD_NEEDS),
      paymentMethods: Object.values(PAYMENT_METHODS),
      uniformNumberTypes: Object.values(UNIFORM_NUMBER_TYPES),
      returnMethods: Object.values(RETURN_METHODS),
      checkDateTypes: Object.values(CHECK_DATE_TYPES),
      inspectedPersonNeedTypes: Object.values(INSPECTED_PERSON_NEED_TYPES),
      complaintRequirements: Object.values(COMPLAINT_REQUIREMENTS),
      complainantRequirements: Object.values(COMPLAINANT_REQUIREMENTS),
      claims: Object.values(CLAIMS)
    };
  }

  constructor(props) {
    makeObservable(this);
  }
}

export default VisitorWorksViewModel;
