/* eslint-disable */
export default [
  {
    "id": "4227b487-2af9-462d-b092-7299c80c064b",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "入店迎賓",
    "type": "fraction",
    "topic": "服務人員時刻留意店內狀況，隨時注意入店顧客。",
    "content": ""
  },
  {
    "id": "0ad45183-f30d-46fe-b85e-e3734b9868d7",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "入店迎賓",
    "type": "fraction",
    "topic": "服務人員未聚集結帳櫃檯",
    "content": "未服務顧客時，應在賣場而非櫃檯內"
  },
  {
    "id": "ad5c6f74-f39e-4943-adf7-c637e9fc76ae",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "入店迎賓",
    "type": "fraction",
    "topic": "迎賓、接待人員無擋住店門口或通道的狀況。",
    "content": ""
  },
  {
    "id": "cc775e3a-e866-4599-a2c9-9e0bd76f11dc",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "入店迎賓",
    "type": "fraction",
    "topic": "顧客入店時，服務人員與顧客視線相交能點頭致意。",
    "content": ""
  },
  {
    "id": "34c7eea6-1ad3-4ab8-9fc2-044eec8a1991",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "入店迎賓",
    "type": "fraction",
    "topic": "顧客入店時，服務人員有微笑問候。",
    "content": ""
  },
  {
    "id": "8816206e-e312-4a8d-a22e-b4b21cfb181c",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "入店迎賓",
    "type": "fraction",
    "topic": "服務人員無私下交談嬉笑，忽視店內顧客。",
    "content": ""
  },
  {
    "id": "0efa0335-64ad-4fdc-b49c-4aacbb26d0d8",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "入店迎賓",
    "type": "fraction",
    "topic": "服務人員站姿端正，無三七步或倚靠櫃檯。",
    "content": ""
  },
  {
    "id": "cabcec15-fc77-407d-8eb0-5d517d5fee25",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "入店迎賓",
    "type": "fraction",
    "topic": "店內忙碌時，服務人員仍能有條不紊的服務每一位顧客；或店內無顧客時，服務人員仍能活動式等待",
    "content": ""
  },
  {
    "id": "0ac2be64-f46c-4207-9c00-787478ce06e1",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "入店迎賓",
    "type": "fraction",
    "topic": "氛圍良好，動線暢通，能輕鬆入店試用商品。",
    "content": ""
  },
  {
    "id": "e74587cf-b392-45c5-88f5-01c7c700c56c",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "入店迎賓",
    "type": "fraction",
    "topic": "服務人員與顧客問候之後，能說出請隨意試戴等招呼用語，並說明計價方式，結帳後20分鐘可領件。",
    "content": ""
  },
  {
    "id": "9bb13d1c-f6d2-42e6-838c-54825bf9192d",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "入店迎賓",
    "type": "fraction",
    "topic": "問候完3分鐘內能進行第二次的服務接觸。如：詢問「想找甚麼款式？」或「今天需要甚麼服務？」",
    "content": "或跟在顧客後面，不能問候完就拋下顧客"
  },
  {
    "id": "16dd3e2d-4de1-494c-a222-9f626a9c22d3",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "入店迎賓",
    "type": "fraction",
    "topic": "對於顧客提出「眼鏡今天能完成嗎？」的詢問能夠給予適當的回答。",
    "content": "確認顧客度數狀況，才回答是否當天拿到"
  },
  {
    "id": "7406ebbc-e975-48f5-9607-c0eb04c7b73a",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "入店迎賓",
    "type": "fraction",
    "topic": "與顧客應對用字遣詞恰當且有禮貌。",
    "content": ""
  },
  {
    "id": "d0060b6b-f379-46b2-b1cf-e0d295b462bb",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "入店迎賓",
    "type": "fraction",
    "topic": "顧客要試戴眼鏡時，呈送的方法適當。",
    "content": "雙手或托盤"
  },
  {
    "id": "6be66f2b-acfc-49cb-b532-78163d38f295",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "入店迎賓",
    "type": "fraction",
    "topic": "非負責的服務人員也能隨時注意狀況，有需要時提供支援協助。",
    "content": ""
  },
  {
    "id": "96578b51-2c7a-4185-b3ca-45b0fee76ada",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "入店迎賓",
    "type": "fraction",
    "topic": "服務人員能根據談話的內容，推薦適合顧客的產品。",
    "content": ""
  },
  {
    "id": "f29cab2c-3227-471d-8f92-d563c1ad0686",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "入店迎賓",
    "type": "fraction",
    "topic": "接待互動中能維持適當眼神交流。",
    "content": ""
  },
  {
    "id": "58d7f5c9-a48f-4ef5-a074-5743f9c23c65",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "入店迎賓",
    "type": "fraction",
    "topic": "店內提供給顧客使用的數位輔助工具（如：iPad）乾淨整潔，沒有指紋或污漬。",
    "content": ""
  },
  {
    "id": "3802d156-c716-48b8-954f-e05e279b4cf0",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "入店迎賓",
    "type": "fraction",
    "topic": "介紹二種以上鏡片時，除口頭說明外，能向顧客展示DM或POP或樣本鏡片。",
    "content": ""
  },
  {
    "id": "be0d2643-8435-47ef-b950-5ae254e94834",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "入店迎賓",
    "type": "fraction",
    "topic": "服務人員能詢問顧客有無戴眼鏡或隱形眼鏡，以及本次配鏡用途等問題。",
    "content": ""
  },
  {
    "id": "1e3a4866-317c-46dc-b6ea-740845689824",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "入店迎賓",
    "type": "fraction",
    "topic": "顧客坐著進行接待時，服務人員需彎腰或蹲下服務顧客，與顧客的視線平行。",
    "content": ""
  },
  {
    "id": "6869f90e-91ce-40a5-827d-8ab31cb633ea",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "入店迎賓",
    "type": "fraction",
    "topic": "人員能主動告知加入LINE好友的資訊。",
    "content": ""
  },
  {
    "id": "f693bb9c-3e4f-4e5c-bd3f-34eb3e47df82",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "入店迎賓",
    "type": "fraction",
    "topic": "人員能詳細說明加入LINE好友相關的優點，例如：通知製作完成、確認度數、確認保固期等。",
    "content": ""
  },
  {
    "id": "cd5f9d9a-c5fb-492a-a349-e65eebd4b7e7",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "入店迎賓",
    "type": "fraction",
    "topic": "Line 的推薦話術，不讓顧客感到壓力。",
    "content": ""
  },
  {
    "id": "38e7c422-de21-4c9b-8f8f-3adbdc4dd439",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "視力檢測",
    "type": "fraction",
    "topic": "檢測前，服務人員能確實使用酒精消毒儀器。",
    "content": ""
  },
  {
    "id": "eefe16e4-15eb-4ae2-b1a0-0c3e9603d780",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "視力檢測",
    "type": "fraction",
    "topic": "檢查開始前，服務人員有微笑問候",
    "content": "如：開始檢查前有親切問候提醒，如「要開始檢測囉！」"
  },
  {
    "id": "c65d8fa3-d95f-469c-930f-cfa675eebb65",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "視力檢測",
    "type": "fraction",
    "topic": "人員能主動詢問配新眼鏡的用途和需求",
    "content": "同一位人員，之前有問可直接給分"
  },
  {
    "id": "32070cc7-cc8c-47a9-bd81-8c7f9e4afd2c",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "視力檢測",
    "type": "fraction",
    "topic": "確認顧客目前使用的眼鏡（隱形眼鏡）度數及清晰度",
    "content": ""
  },
  {
    "id": "9e4b53e2-1301-4d03-9bf8-1ccd704176b3",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "視力檢測",
    "type": "fraction",
    "topic": "人員能提供兩種以上不同的度數讓顧客試戴挑選",
    "content": ""
  },
  {
    "id": "f0b243ba-6fa5-4b6e-8bb8-016ab567513a",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "視力檢測",
    "type": "fraction",
    "topic": "檢查人員能詢問眼鏡佩戴感受，並指引客人站起來走動試用。",
    "content": ""
  },
  {
    "id": "fb7b5558-f284-43f5-8dca-7ee5cabab644",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "視力檢測",
    "type": "fraction",
    "topic": "檢測後，顧客可自行選擇眼鏡度數。",
    "content": "依照需求做確認"
  },
  {
    "id": "66405715-3da4-49ff-ab67-1047087b79a3",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "視力檢測",
    "type": "fraction",
    "topic": "在決定度數後，服務人員有針對選擇之眼鏡度數進行詳盡說明。",
    "content": ""
  },
  {
    "id": "2cdf1258-4e91-44c0-927b-6c64a92196c0",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "視力檢測",
    "type": "fraction",
    "topic": "檢查過程中，說明用詞有條理，簡單易懂。",
    "content": ""
  },
  {
    "id": "184c9434-770e-4621-baef-5df7d5fd9f62",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "視力檢測",
    "type": "fraction",
    "topic": "檢查過程中，說明時詞句禮貌，並與顧客有適度的眼神交流。",
    "content": ""
  },
  {
    "id": "58ade9a2-9a6b-48b1-b7a7-1defd8a2b227",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "視力檢測",
    "type": "fraction",
    "topic": "說明時語速適中、音量恰當，能聽清楚內容。",
    "content": ""
  },
  {
    "id": "f5f34782-355c-4b0b-a232-872e8f8254ab",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "視力檢測",
    "type": "fraction",
    "topic": "檢查使用的試戴鏡片，沒有髒污或刮傷。",
    "content": ""
  },
  {
    "id": "3c479d4a-4f97-4f18-b786-447191371170",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "視力檢測",
    "type": "fraction",
    "topic": "檢查結束後，服務人員能快速整理桌面的檢查用品。",
    "content": "試戴片、遮眼棒等"
  },
  {
    "id": "459ce584-17bf-4998-84e5-4b2b43d66ed3",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "視力檢測",
    "type": "fraction",
    "topic": "針對鏡片的設計等產品資訊做相關說明",
    "content": ""
  },
  {
    "id": "09cbd941-1761-481e-a030-e085e36b202c",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "結帳取貨",
    "type": "fraction",
    "topic": "結帳人員使用托盤（或雙手）取還現金、信用卡、零錢、發票等物品。",
    "content": ""
  },
  {
    "id": "ad2e9320-40dd-446a-bf8e-bf0f6a42e639",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "結帳取貨",
    "type": "fraction",
    "topic": "能清楚說明顧客訂購商品的完成與取貨時間",
    "content": ""
  },
  {
    "id": "9fabc897-72b5-49f4-b86f-8063774a8fdb",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "結帳取貨",
    "type": "fraction",
    "topic": "告知交付需等待的時間，合理不拖延。",
    "content": ""
  },
  {
    "id": "3e384f3d-2853-4554-ba46-cc3dd8b856e5",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "結帳取貨",
    "type": "fraction",
    "topic": "不應遞送給顧客介紹券。",
    "content": "若有提供請特別註記"
  },
  {
    "id": "5e301da8-4743-4062-a40e-4aa6948a46c3",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "結帳取貨",
    "type": "fraction",
    "topic": "不應針對介紹券的使用規則做相關說明。",
    "content": "若有說明請特別註記"
  },
  {
    "id": "cae465e6-07be-4a69-8080-03a376f90943",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "結帳取貨",
    "type": "fraction",
    "topic": "結帳前對顧客說明使用的是何種鏡片。",
    "content": ""
  },
  {
    "id": "da9e9557-63ce-4752-aae4-445cbe9a54e3",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "結帳取貨",
    "type": "fraction",
    "topic": "關於鏡片的功能等結帳前做最終確認。",
    "content": ""
  },
  {
    "id": "b1c465a2-187b-43cf-b86d-fc1a86982521",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "結帳取貨",
    "type": "fraction",
    "topic": "能按照結帳時告知的預定時間完成。",
    "content": ""
  },
  {
    "id": "9c202695-e100-4a21-8ebd-3f68350ac4d7",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "結帳取貨",
    "type": "fraction",
    "topic": "有再次向顧客確認商品，如：「訂購的是這副眼鏡，對嗎？」或相關詢問詞句。",
    "content": ""
  },
  {
    "id": "083e62c8-bb91-46af-921e-a1d75801f648",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "結帳取貨",
    "type": "fraction",
    "topic": "主動邀請顧客現場進行試戴。",
    "content": ""
  },
  {
    "id": "b83171e5-8c1b-4dbe-9358-c900547b8766",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "結帳取貨",
    "type": "fraction",
    "topic": "確認清晰度前請客人先進行試戴。",
    "content": ""
  },
  {
    "id": "1da736e4-5283-4aaf-a0e1-c727c1dc86d4",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "結帳取貨",
    "type": "fraction",
    "topic": "確實執行試戴五步驟。",
    "content": "參考附件，第二步驟可跳過"
  },
  {
    "id": "c414a82e-260c-471b-8f5d-f8070972686b",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "結帳取貨",
    "type": "fraction",
    "topic": "依照顧客的需求，將鏡框調整至配戴最舒適的狀態。",
    "content": ""
  },
  {
    "id": "1c4e5d26-e1aa-4635-b627-13f8054631c6",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "結帳取貨",
    "type": "fraction",
    "topic": "試戴快結束時，是否有詢問客人「有哪裡需要再調整嗎?」等確認試戴狀況的關心問句。",
    "content": ""
  },
  {
    "id": "b5842587-bd79-4134-8dc4-1ce645c47cb2",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "結帳取貨",
    "type": "fraction",
    "topic": "服務人員能主動針對鏡片清潔方法及注意事項進行說明。",
    "content": "鏡片怕熱，用冷水洗滌，不要帶入溫泉三溫等"
  },
  {
    "id": "6402c6a0-f364-4cc4-a9b4-71e14d4afda0",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "結帳取貨",
    "type": "fraction",
    "topic": "服務人員說明顧客填寫滿意度調查表的相關內容並請顧客填寫。",
    "content": ""
  },
  {
    "id": "9d86ad46-89ca-4b84-b44d-a5f315b4c2fa",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "結帳取貨",
    "type": "fraction",
    "topic": "取貨過程中，服務人員和顧客有適當的眼神交流且態度友善、語速和音量適中。",
    "content": ""
  },
  {
    "id": "e5cead24-5037-4032-ae8d-be6bf06c7f42",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "結帳取貨",
    "type": "fraction",
    "topic": "取貨完成後，服務人員有微笑致上道別詞，如：「謝謝，歡迎再來！」",
    "content": ""
  },
  {
    "id": "944c070a-af74-4fe2-bc9f-2ff01d7aec5a",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "結帳取貨",
    "type": "fraction",
    "topic": "服務人員能說明保固相關事宜。",
    "content": "品質保固、度數保固為最低限必要說明"
  },
  {
    "id": "195edddd-c9ea-4fbe-b554-4717c29eb820",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "環境設施",
    "class5": "環境設施",
    "type": "fraction",
    "topic": "電視螢幕能正常撥放影片。",
    "content": ""
  },
  {
    "id": "0208b8ee-f532-45f3-9edb-fe4e38f42e7a",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "環境設施",
    "class5": "環境設施",
    "type": "fraction",
    "topic": "數位輔助工具如iPad能正常使用。",
    "content": ""
  },
  {
    "id": "da8a514e-d6e1-4075-9f74-95be436465d8",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "環境設施",
    "class5": "環境設施",
    "type": "fraction",
    "topic": "定時清潔Autoref機台，保持乾淨且設備均能正常使用。",
    "content": ""
  },
  {
    "id": "44234dcb-62a2-4a60-89d9-81dc03d52ed4",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "環境設施",
    "class5": "環境設施",
    "type": "fraction",
    "topic": "定時清潔驗光機台，保持乾淨且設備均能正常使用。",
    "content": ""
  },
  {
    "id": "aebd5a03-52b6-4dc8-8ec0-b47f8c9db92e",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服裝儀容",
    "type": "fraction",
    "topic": "服務人員儀容乾淨整潔。",
    "content": ""
  },
  {
    "id": "3e2f61ee-bf26-42bb-beed-56cd336ff30c",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服裝儀容",
    "type": "fraction",
    "topic": "服務人員未配戴過於華麗或過大飾品。",
    "content": ""
  },
  {
    "id": "65c3ea60-e36a-4f12-96ed-ddca002fad5a",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服裝儀容",
    "type": "fraction",
    "topic": "服務人員指甲乾淨整潔。",
    "content": ""
  },
  {
    "id": "b2fe0bfc-3b27-4b28-9377-6476851149b8",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服裝儀容",
    "type": "fraction",
    "topic": "服務人員制服無明顯污漬或皺紋。",
    "content": ""
  },
  {
    "id": "5d9f4c3b-4c53-494e-9f97-825ccb75c02f",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服裝儀容",
    "type": "fraction",
    "topic": "服務人員皆有依規定配戴名牌，符合專業形象。",
    "content": ""
  },
  {
    "id": "07134dfc-a3ef-450d-99e3-2aa4fe0f221d",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服裝儀容",
    "type": "fraction",
    "topic": "服務人員氣味適宜，無散發刺鼻惡臭，或噴過量香水。",
    "content": ""
  },
  {
    "id": "db151d0c-ca4f-4fb6-ae53-2dcc7fe5a7ae",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服裝儀容",
    "type": "fraction",
    "topic": "服務人員鞋子保持乾淨無明顯污漬。",
    "content": ""
  },
  {
    "id": "3a8bd3d1-ae3b-42fc-b68c-0952192e78f3",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服裝儀容",
    "type": "fraction",
    "topic": "服務人員有依規定穿著襪子或絲襪。",
    "content": ""
  },
  {
    "id": "31996ba1-d48b-482d-9168-b112229f7c9c",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服裝儀容",
    "type": "fraction",
    "topic": "店內所有服務人員皆有配戴口罩和眼鏡等防護用品。",
    "content": ""
  },
  {
    "id": "60bc809e-3b47-47cd-ab6d-42d255f9c6dd",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服裝儀容",
    "type": "fraction",
    "topic": "口罩乾淨衛生，無明顯髒汙。",
    "content": ""
  },
  {
    "id": "bcbae244-2f52-4c6b-bb08-a90ca0ca5df2",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "環境設施",
    "class5": "POP佈置",
    "type": "fraction",
    "topic": "「追加費用為0元」等海報或POP被擺放或張貼在明顯處。",
    "content": ""
  },
  {
    "id": "ed9f2d4a-0f1c-4c8d-bce3-8999720f8f39",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "環境設施",
    "class5": "POP佈置",
    "type": "fraction",
    "topic": "十大安心保證等POP有被擺放或張貼在明顯處。",
    "content": ""
  },
  {
    "id": "11e4b642-82c8-4af2-b319-29fa247502f4",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "環境設施",
    "class5": "POP佈置",
    "type": "fraction",
    "topic": "AIR ULTEM的展示品和相關的POP有被展示或張貼出來。",
    "content": ""
  },
  {
    "id": "94ee30e8-b57b-4eb2-8735-b74c84a39a47",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "環境設施",
    "class5": "POP佈置",
    "type": "fraction",
    "topic": "MEMORY METAL的展示品和相關的POP有被展示或張貼出來。",
    "content": ""
  },
  {
    "id": "842ad90a-20fe-4804-b732-d81a0dbf5f51",
    "class1": "眼鏡業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "環境設施",
    "class5": "POP佈置",
    "type": "fraction",
    "topic": "店內文宣、宣傳牌和海報保持完整，沒有污漬、破損、斑痕或皺摺等情形。",
    "content": ""
  },
  {
    "id": "613f8c7f-73c7-4914-8846-a2c51f092597",
    "class1": "百貨公司",
    "class2": "現場",
    "class3": "評鑑",
    "class4": "基本服務",
    "class5": "其他",
    "type": "fraction",
    "topic": "出入口警衛服裝儀容乾淨整齊，對顧客提問，能親切回應。",
    "content": "如大門出入口無設警衛，則改測「停車場指揮警衛(保全)」。"
  },
  {
    "id": "420750c2-5b37-43e3-a9d0-99c3f60f7ff4",
    "class1": "百貨公司",
    "class2": "現場",
    "class3": "評鑑",
    "class4": "基本服務",
    "class5": "其他",
    "type": "fraction",
    "topic": "服務台人員服裝儀容乾淨整齊，帶有淡妝，儀態優雅，對於顧客詢問，能清楚說明或引介，語氣清晰、態度親切，結束時能再關心顧客是否還需其他服務，過程中能面帶微笑且能適度目視顧客。",
    "content": ""
  },
  {
    "id": "283adcdf-a47d-4e57-b22d-5299f45cfe70",
    "class1": "百貨公司",
    "class2": "現場",
    "class3": "評鑑",
    "class4": "基本服務",
    "class5": "其他",
    "type": "fraction",
    "topic": "樓面清潔人員服裝儀容乾淨整齊，對顧客提問，能親切回應。",
    "content": ""
  },
  {
    "id": "cf8427a6-396f-42c9-ad7e-db554af6dc37",
    "class1": "百貨公司",
    "class2": "現場",
    "class3": "評鑑",
    "class4": "基本服務",
    "class5": "其他",
    "type": "fraction",
    "topic": "專櫃服務人員服裝儀容乾淨整齊，帶有淡妝(女性)，儀態優雅，對顧客詢問該櫃銷售商品的基本問題，能親切回應，過程中能面帶微笑且能適度目視顧客。",
    "content": "【稽查須知】請查紳士用品專櫃樓層。"
  },
  {
    "id": "5e7e5c9c-ed74-493b-8c1f-567fc9b5c4df",
    "class1": "百貨公司",
    "class2": "現場",
    "class3": "評鑑",
    "class4": "基本服務",
    "class5": "其他",
    "type": "fraction",
    "topic": "館內環境乾淨清潔(含樓梯間、電梯、手扶梯、賣場樓面、休息區)，各項指引 (停車場、樓層、電梯、廁所、服務台、安全門…)清楚標示且易於尋找。",
    "content": ""
  },
  {
    "id": "c7bf33b3-5f2d-4488-8ccb-ddcdd671d368",
    "class1": "百貨公司",
    "class2": "現場",
    "class3": "評鑑",
    "class4": "基本服務",
    "class5": "其他",
    "type": "fraction",
    "topic": "無障礙空間及設施友善，提供嬰兒車和輪椅的借用服務。",
    "content": "可觀察或詢問輪椅、嬰兒車的足量狀況。借用方式的便利性可酌以加扣分。"
  },
  {
    "id": "c2dd5593-59a9-4dd3-afef-1ddbcc9775c4",
    "class1": "百貨公司",
    "class2": "現場",
    "class3": "評鑑",
    "class4": "基本服務",
    "class5": "其他",
    "type": "fraction",
    "topic": "館內光線明亮，空調適中，音樂悅耳、音量適中，服務台廣播音量清晰可辨。(特別是尋人、尋物等)",
    "content": ""
  },
  {
    "id": "e031e2ae-d27f-4b89-bc15-b07378ec86eb",
    "class1": "百貨公司",
    "class2": "現場",
    "class3": "評鑑",
    "class4": "基本服務",
    "class5": "其他",
    "type": "fraction",
    "topic": "美食街用餐環境品質維護良好，清潔人員桌椅地面勤整理、餐飲人員備餐基本衛生習慣具備(口罩、內場清潔、不留長甲…)。",
    "content": "請抽查三家開放式櫃位的餐飲人員，綜合給分。"
  },
  {
    "id": "90befd7e-f2af-456f-94c8-08bb9d08c0ff",
    "class1": "百貨公司",
    "class2": "現場",
    "class3": "評鑑",
    "class4": "基本服務",
    "class5": "其他",
    "type": "fraction",
    "topic": "館內設有育嬰、哺乳室，具基本設備(熱開水、奶瓶消毒、座位)，有專人管理，環境保持乾淨清潔。",
    "content": "如哺乳室有上鎖，可與櫃台藉故表示幫後到的友人先了解環境設備。"
  },
  {
    "id": "d0f0dd63-6940-415e-a6c7-1e00e5ce80b8",
    "class1": "百貨公司",
    "class2": "現場",
    "class3": "評鑑",
    "class4": "基本服務",
    "class5": "其他",
    "type": "fraction",
    "topic": "洗手間境乾淨清潔、備品充足，相關設施配備運作正常。檢查表也確實依照清潔時間勾選。(環境面包含：無異味、水漬，垃圾桶中垃圾量少於一半)\r\n(設備面包含：燈光、門鎖、水龍頭、烘手機、沖水系統等)\r\n(備品面包含：擦手紙、衛生紙、洗手乳等)",
    "content": "請查美食街樓層。"
  },
  {
    "id": "c33f9b47-70f5-41cf-806b-ad3359d44dc6",
    "class1": "百貨公司",
    "class2": "現場",
    "class3": "評鑑",
    "class4": "應變服務",
    "class5": "其他",
    "type": "fraction",
    "topic": "訪員提出「要求設定」的情節後，請就對⽅應對的態度，描述當下情況及感受。",
    "content": "(包含語氣語速、主動性、理解深度、淺顯⽤詞、掌握重點)"
  },
  {
    "id": "fe88050d-bb71-4888-ad02-cbc3ddc204ee",
    "class1": "百貨公司",
    "class2": "現場",
    "class3": "評鑑",
    "class4": "應變服務",
    "class5": "其他",
    "type": "fraction",
    "topic": "訪員提出「要求設定」的情節後，請就對⽅關懷理解的展現，描述當下情況及感受。",
    "content": "(包含⽤語說明，如：是的，我了解...)"
  },
  {
    "id": "09e42691-ea5a-4d71-a0c7-6c22689245a5",
    "class1": "百貨公司",
    "class2": "現場",
    "class3": "評鑑",
    "class4": "應變服務",
    "class5": "其他",
    "type": "fraction",
    "topic": "訪員提出「要求設定」的情節後，請就提供對⽅解說或處理或解決的程度作為，描述當下情況及感受。",
    "content": ""
  },
  {
    "id": "217c8abc-d8df-41f7-b42f-816941e3f2a5",
    "class1": "百貨公司",
    "class2": "現場",
    "class3": "評鑑",
    "class4": "客訴處理",
    "class5": "其他",
    "type": "fraction",
    "topic": "訪員提出「所選抱怨」的情節後，請就對⽅應對的態度，描述當下情況及感受。",
    "content": "(包含語氣語速、主動性、理解深度、淺顯⽤詞、掌握重點)"
  },
  {
    "id": "121127de-8b7f-489a-8ca2-2cd6b0838b78",
    "class1": "百貨公司",
    "class2": "現場",
    "class3": "評鑑",
    "class4": "客訴處理",
    "class5": "其他",
    "type": "fraction",
    "topic": "訪員提出「所選抱怨」的情節後，請就對⽅同理⼼理解的展現，描述當下情況及感受。",
    "content": "(包含⽤語說明，如：是的，我了解...)"
  },
  {
    "id": "8ba50b70-77c1-4fb7-a8ee-4bc3339fd4af",
    "class1": "百貨公司",
    "class2": "現場",
    "class3": "評鑑",
    "class4": "客訴處理",
    "class5": "其他",
    "type": "fraction",
    "topic": "訪員提出「要求設定」的情節後，請就對⽅道歉的展現，描述當下情況及感受。",
    "content": "(包含⼝語致歉、記錄、反映上級)"
  },
  {
    "id": "f87d9673-7b93-43c3-b016-1cccb6c0bb6d",
    "class1": "百貨公司",
    "class2": "非現場",
    "class3": "評鑑",
    "class4": "客訴處理",
    "class5": "企業官網",
    "type": "fraction",
    "topic": "投訴電話或信箱是否標⽰客訴字眼之易顯度",
    "content": ""
  },
  {
    "id": "fc2c837b-fbfc-4d92-b3cf-4c5deb1f7949",
    "class1": "百貨公司",
    "class2": "非現場",
    "class3": "評鑑",
    "class4": "客訴處理",
    "class5": "企業官網",
    "type": "fraction",
    "topic": "投訴電話或信箱清楚標明服務時間。",
    "content": ""
  },
  {
    "id": "f4337b77-b548-4c4c-a719-4e69fd486762",
    "class1": "百貨公司",
    "class2": "非現場",
    "class3": "評鑑",
    "class4": "客訴處理",
    "class5": "企業官網",
    "type": "fraction",
    "topic": "投訴電話提供免付費專線。",
    "content": ""
  },
  {
    "id": "f2636072-df52-48ff-a5d5-14c7f21ee4f7",
    "class1": "百貨公司",
    "class2": "非現場",
    "class3": "評鑑",
    "class4": "客訴處理",
    "class5": "信件抱怨",
    "type": "fraction",
    "topic": "回信的效率，描述當下情況及感受。(包含時間、聯絡資訊提供)",
    "content": ""
  },
  {
    "id": "d2c652f9-c7a2-4c0a-a0eb-0ff15942dc9c",
    "class1": "百貨公司",
    "class2": "非現場",
    "class3": "評鑑",
    "class4": "客訴處理",
    "class5": "信件抱怨",
    "type": "fraction",
    "topic": "訪員提出「所選抱怨」的情節後，請就應對的態度，描述當下情況及感受。",
    "content": "(包含主動性、理解深度、淺顯⽤詞、掌握重點)"
  },
  {
    "id": "87a19f5e-8d2e-4be8-9199-9f585068bb1d",
    "class1": "百貨公司",
    "class2": "非現場",
    "class3": "評鑑",
    "class4": "客訴處理",
    "class5": "信件抱怨",
    "type": "fraction",
    "topic": "訪員提出「所選抱怨」的情節後，請就同理心理解的展現，描述當下情況及感受。",
    "content": "(包含用語說明，如：是的，我了解...)"
  },
  {
    "id": "4f10ee84-4512-45f2-ad20-3b3387bcd6c7",
    "class1": "百貨公司",
    "class2": "非現場",
    "class3": "評鑑",
    "class4": "客訴處理",
    "class5": "信件抱怨",
    "type": "fraction",
    "topic": "訪員提出「所選抱怨」的情節後，請就道歉的展現，描述當下情況及感受。",
    "content": "(包含明文致歉、記錄、反映上級)"
  },
  {
    "id": "e780de95-668e-4e47-978c-5bf9d50a45cd",
    "class1": "百貨公司",
    "class2": "非現場",
    "class3": "評鑑",
    "class4": "客訴處理",
    "class5": "電話抱怨",
    "type": "fraction",
    "topic": "進線的效率，包含等候時間、語音機制、主報姓氏、等候致意、等候掛斷。",
    "content": ""
  },
  {
    "id": "8aa57334-eef6-45c5-9e1c-05f1d1eaad3a",
    "class1": "百貨公司",
    "class2": "非現場",
    "class3": "評鑑",
    "class4": "客訴處理",
    "class5": "電話抱怨",
    "type": "fraction",
    "topic": "訪員提出「所選抱怨」的情節後，請就應對的態度，描述當下情況及感受。",
    "content": "(包含主動性、理解深度、淺顯⽤詞、掌握重點)"
  },
  {
    "id": "f16db198-90e3-49c5-b111-fe6e170546bd",
    "class1": "百貨公司",
    "class2": "非現場",
    "class3": "評鑑",
    "class4": "客訴處理",
    "class5": "電話抱怨",
    "type": "fraction",
    "topic": "訪員提出「所選抱怨」的情節後，請就同理心理解的展現，描述當下情況及感受。",
    "content": "(包含用語說明，如：是的，我了解...)"
  },
  {
    "id": "7b3d87d7-2bb1-430a-af6c-b3c241efbb67",
    "class1": "百貨公司",
    "class2": "非現場",
    "class3": "評鑑",
    "class4": "客訴處理",
    "class5": "電話抱怨",
    "type": "fraction",
    "topic": "訪員提出「所選抱怨」的情節後，請就道歉的展現，描述當下情況及感受。",
    "content": "(包含明文致歉、記錄、反映上級)"
  },
  {
    "id": "864a2271-b00b-417f-ad82-caa18e0bec53",
    "class1": "百貨公司",
    "class2": "非現場",
    "class3": "評鑑",
    "class4": "遠距服務",
    "class5": "APP",
    "type": "fraction",
    "topic": "該品牌是否具備APP?",
    "content": ""
  },
  {
    "id": "b71ff8e4-e018-4569-956b-1985222b6cfa",
    "class1": "百貨公司",
    "class2": "非現場",
    "class3": "評鑑",
    "class4": "遠距服務",
    "class5": "APP",
    "type": "fraction",
    "topic": "APP載點容易取得且下載便利",
    "content": ""
  },
  {
    "id": "c558d23e-b870-492c-853e-92a9de08be0a",
    "class1": "百貨公司",
    "class2": "非現場",
    "class3": "評鑑",
    "class4": "遠距服務",
    "class5": "APP",
    "type": "fraction",
    "topic": "APP介面設計功能易找並符合個人需求",
    "content": ""
  },
  {
    "id": "c0a8ac39-29a5-4bbe-9aa1-7c747b05a387",
    "class1": "百貨公司",
    "class2": "非現場",
    "class3": "評鑑",
    "class4": "遠距服務",
    "class5": "APP",
    "type": "fraction",
    "topic": "APP具備無需註冊也能使用之功能",
    "content": ""
  },
  {
    "id": "3a9eab5d-b8f1-44e8-8b18-984846be8da6",
    "class1": "百貨公司",
    "class2": "非現場",
    "class3": "評鑑",
    "class4": "遠距服務",
    "class5": "APP",
    "type": "fraction",
    "topic": "APP功能運作正常無異狀",
    "content": ""
  },
  {
    "id": "2ff000e0-fbcf-49b4-be21-c72bd9c13731",
    "class1": "百貨公司",
    "class2": "非現場",
    "class3": "評鑑",
    "class4": "遠距服務",
    "class5": "智能客服",
    "type": "fraction",
    "topic": "該品牌是否具備智能客服? (聊天機器人)",
    "content": ""
  },
  {
    "id": "080f54e9-e209-4d8a-b35b-970d7bfc828b",
    "class1": "百貨公司",
    "class2": "非現場",
    "class3": "評鑑",
    "class4": "遠距服務",
    "class5": "智能客服",
    "type": "fraction",
    "topic": "智能客服功能運作正常且容易使用",
    "content": ""
  },
  {
    "id": "1a70d9cc-69f6-42be-97fd-38e9b27ae95c",
    "class1": "百貨公司",
    "class2": "非現場",
    "class3": "評鑑",
    "class4": "遠距服務",
    "class5": "智能客服",
    "type": "fraction",
    "topic": "智能客服能確實理解顧客提出的問題",
    "content": ""
  },
  {
    "id": "a5920e89-116a-4811-b028-aa812c8310b6",
    "class1": "百貨公司",
    "class2": "非現場",
    "class3": "評鑑",
    "class4": "遠距服務",
    "class5": "智能客服",
    "type": "fraction",
    "topic": "智能客服能確實解決問題或解答疑問",
    "content": ""
  },
  {
    "id": "fc000b4e-6671-41f9-a4a9-5c15dacf919e",
    "class1": "百貨公司",
    "class2": "非現場",
    "class3": "評鑑",
    "class4": "遠距服務",
    "class5": "智能客服",
    "type": "fraction",
    "topic": "智能客服呈現方式讓人感覺到溫度",
    "content": ""
  },
  {
    "id": "4193410b-bb9a-4cbb-ad99-176392f33c46",
    "class1": "百貨公司",
    "class2": "現場",
    "class3": "評鑑",
    "class4": "額外觀察",
    "class5": "魅力反轉",
    "type": "fraction",
    "topic": "魅力品質加1分，摘述是歸納整理重點，不是寫魅力品質或反轉品質，用分數就足以辨別",
    "content": ""
  },
  {
    "id": "3d96a604-1bec-4df6-b014-da8b0d9d7772",
    "class1": "百貨公司",
    "class2": "非現場",
    "class3": "評鑑",
    "class4": "額外觀察",
    "class5": "魅力反轉",
    "type": "fraction",
    "topic": "反轉品質選扣1分，摘述是歸納整理重點，不是寫魅力品質或反轉品質，用分數就足以辨別",
    "content": ""
  },
  {
    "id": "1bad70c5-8215-4a90-8056-490c140d233f",
    "class1": "百貨公司",
    "class2": "現場",
    "class3": "評鑑",
    "class4": "額外觀察",
    "class5": "科技觀察",
    "type": "shortAnswer",
    "topic": "具備科技服務設施或其他符合科技服務並有獨特性者，請詳列科技設施名稱。",
    "content": ""
  },
  {
    "id": "3242516e-ac2a-4477-a9e9-80163989564f",
    "class1": "百貨公司",
    "class2": "非現場",
    "class3": "評鑑",
    "class4": "額外觀察",
    "class5": "科技觀察",
    "type": "shortAnswer",
    "topic": "具備科技服務設施或其他符合科技服務並有獨特性者，請詳列科技設施名稱。",
    "content": ""
  },
  {
    "id": "26d2dc90-bf52-4435-8d11-66ee3687325c",
    "class1": "百貨公司",
    "class2": "現場",
    "class3": "評鑑",
    "class4": "額外觀察",
    "class5": "服務尖兵",
    "type": "shortAnswer",
    "topic": "請具體清楚描述推薦理由。「現場」請一定要觀察到真實姓名(如：名牌上的顯示)",
    "content": "請就此稽查有接觸到的服務人員當中，列出相對服務最優異的。如實在無人可寫，仍請寫出一位，註明「備選」。"
  },
  {
    "id": "6657876f-6b6f-4456-8868-9164b9cd70ee",
    "class1": "百貨公司",
    "class2": "非現場",
    "class3": "評鑑",
    "class4": "額外觀察",
    "class5": "服務尖兵",
    "type": "shortAnswer",
    "topic": "請具體清楚描述推薦理由，對話結束前請務必詢問到姓氏或編號",
    "content": "請就此稽查有接觸到的服務人員當中，列出相對服務最優異的。如實在無人可寫，仍請寫出一位，註明「備選」。"
  },
  {
    "id": "5a7c3a28-5783-44b2-98c9-5b2ae0769932",
    "class1": "證券業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服務環境",
    "type": "fraction",
    "topic": "燈光設備充分開啟，現場照明充足。",
    "content": ""
  },
  {
    "id": "dec68c59-1d49-4972-9cd0-5f1a673018c9",
    "class1": "證券業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服務環境",
    "type": "fraction",
    "topic": "據點整體環境整潔，地面、桌椅無灰塵、紙屑、垃圾與汙漬。",
    "content": ""
  },
  {
    "id": "53230c8e-910f-4840-a684-35c20b3fe920",
    "class1": "證券業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服務環境",
    "type": "fraction",
    "topic": "據點桌面、地面無私人物品或雜物堆放，保持動線暢通。",
    "content": ""
  },
  {
    "id": "8bd85a41-f64e-43da-aa24-8fdf868333b1",
    "class1": "證券業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服務環境",
    "type": "fraction",
    "topic": "室內通風良好，氣味清新；若有植栽能整理維護，沒有積塵或落葉堆積。",
    "content": ""
  },
  {
    "id": "53743c8b-6551-486d-bb6d-be7d9c26f7ad",
    "class1": "證券業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服務環境",
    "type": "fraction",
    "topic": "張貼公示整齊美觀；僅張貼當期或有效的公告及活動，沒有逾期的公告或活動。",
    "content": ""
  },
  {
    "id": "2cdc5f24-b33a-41da-844d-ebc388d16e11",
    "class1": "證券業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服務環境",
    "type": "fraction",
    "topic": "雨天時門口放置傘架或雨傘套於顯眼處，並能保持整齊不凌亂。",
    "content": "（非雨天此題可N/A，但若有擺置出來，仍須整齊乾淨）"
  },
  {
    "id": "e9f294d9-1f50-413f-8c3b-e6e236a700f9",
    "class1": "證券業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服務環境",
    "type": "fraction",
    "topic": "數位體驗區設備可以正常使用體驗，能連線網路且沒有故障情況。",
    "content": "（若故障但有告示說明則不扣分，需說明清楚）"
  },
  {
    "id": "a336a6c2-7817-40c0-9a69-ce8e6bb09fdc",
    "class1": "證券業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服務環境",
    "type": "fraction",
    "topic": "辦公櫃檯桌面物品擺放整齊不雜亂。",
    "content": ""
  },
  {
    "id": "2b17e31a-ea4b-4981-8bde-35f0c67c1494",
    "class1": "證券業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服務環境",
    "type": "fraction",
    "topic": "人員若離座，桌面不遺留客戶資料或重要文件。",
    "content": ""
  },
  {
    "id": "04549f84-488a-4469-aa73-57769b6477b4",
    "class1": "證券業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服務環境",
    "type": "fraction",
    "topic": "洗手間乾淨清潔無異味，無過多水漬，垃圾桶未超過半滿，且設備能都能正常使用",
    "content": ""
  },
  {
    "id": "d5f1c7b3-ef22-4b48-b0ea-04c3d05fb9ae",
    "class1": "證券業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服裝儀容",
    "type": "fraction",
    "topic": "全體同仁髮型整齊清潔，不可披頭散髮，沒有誇張造型，女性同仁頭髮長度過肩宜紮起，保持不凌亂。",
    "content": ""
  },
  {
    "id": "5d109dcd-f136-437d-9dda-2f51faa3142b",
    "class1": "證券業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服裝儀容",
    "type": "fraction",
    "topic": "全體同仁穿著制服或正式西(套)裝，平整乾淨，並配戴識別證或名牌，不可配戴誇張飾品。",
    "content": "男性同仁須打領帶，不可捲袖子。"
  },
  {
    "id": "9e5086b7-c0de-48c2-a01e-f8b77f00aca7",
    "class1": "證券業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服裝儀容",
    "type": "fraction",
    "topic": "人員穿著皮鞋，以黑色或深色包鞋為原則，不得穿著拖鞋、涼鞋、前空或後空鞋。",
    "content": "（不露出腳趾或腳跟）"
  },
  {
    "id": "9b5a2c73-eecc-4f5c-818d-a1d243a5f47c",
    "class1": "證券業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服裝儀容",
    "type": "fraction",
    "topic": "手掌手指保持清潔，指甲顏色應以素雅、不誇張為原則。",
    "content": ""
  },
  {
    "id": "887b20df-35e1-4957-8ac8-b6fd2ddd75a2",
    "class1": "證券業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服務禮儀",
    "type": "fraction",
    "topic": "進門時，接待員說出招呼語：「您好／早安／午安，歡迎光臨(凱基證券)」。",
    "content": ""
  },
  {
    "id": "04d37495-d2b7-443e-8e2f-e79305f8b6d9",
    "class1": "證券業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服務禮儀",
    "type": "fraction",
    "topic": "接待員能進一步詢問顧客需求：「請問有什麼可以為您服務的？」或「請問您今天要辦理什麼業務呢？」。",
    "content": ""
  },
  {
    "id": "9628ba33-76ad-4e8b-8d96-ab5b7b914f5b",
    "class1": "證券業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服務禮儀",
    "type": "fraction",
    "topic": "接待員能引導顧客到櫃檯或等候區入座，並說出適切的引導話術。\n 如：「您好，這裡請」、「您好，這裡請坐，稍後立刻為您服務」、「您好，請用茶水，小心燙\n 」、「您好，這是我們近期的資料，提供給您參考」，訪員可照情境判斷，但不可默默引導。",
    "content": ""
  },
  {
    "id": "8992aaa4-052d-41f3-863b-e87ce14a2046",
    "class1": "證券業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服務禮儀",
    "type": "fraction",
    "topic": "接待員服務過程都能展露誠摯笑容，眼神溫暖，音調親切。",
    "content": ""
  },
  {
    "id": "58fe4e41-9ec0-48cb-9da8-ce563bcb7d1c",
    "class1": "證券業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服務禮儀",
    "type": "fraction",
    "topic": "客戶臨櫃時，人員面帶笑容起身，目視客戶，並以示意性點頭禮向客戶說出問候語，並五指併攏指引客戶落座，待客戶落座後方可坐下。",
    "content": ""
  },
  {
    "id": "0a33fb5e-7aaf-47e6-a8b4-4bb0cd2b15af",
    "class1": "證券業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服務禮儀",
    "type": "fraction",
    "topic": "人員展現積極專業態度，隨時留意顧客狀況，當顧客不知如何填寫表格或辦理事務時主動協助。",
    "content": "(請訪員佈梗)"
  },
  {
    "id": "5d6d20bd-ea0d-43e3-b666-74259348e2ac",
    "class1": "證券業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服務禮儀",
    "type": "fraction",
    "topic": "服務過程專心，正確迅速的處理事務，未與同事聊天或處理其他客戶業務，或自顧講電話。",
    "content": ""
  },
  {
    "id": "d2262131-4cf6-4f55-b275-56b5350dea62",
    "class1": "證券業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服務禮儀",
    "type": "fraction",
    "topic": "人員若短暫離開座位或中斷談話時，應向客戶致意告知，譬如需影印客戶證件，要告知客戶:『需將您的證件拿去影印，請您稍等』。",
    "content": ""
  },
  {
    "id": "f7c6011d-a705-4eae-8012-2c1de8d1f28c",
    "class1": "證券業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服務禮儀",
    "type": "fraction",
    "topic": "以雙手收取(遞送)客戶交付之證件、申請書或其他物件。",
    "content": ""
  },
  {
    "id": "3f6cfd6a-4cf2-469d-9ba7-68ab9f58bda7",
    "class1": "證券業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服務禮儀",
    "type": "fraction",
    "topic": "互動中使用禮貌敬語「您、請、謝謝、對不起」，未使用任何不當辭句。",
    "content": ""
  },
  {
    "id": "4be819aa-455e-48c1-8589-c71a36886f6e",
    "class1": "證券業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服務禮儀",
    "type": "fraction",
    "topic": "客戶離櫃/臨走時應展現感謝與服務熱忱地說：「謝謝光臨，請慢走／謝謝您，再見／祝您今天順心」。",
    "content": "(有誠摯道別致意，就給分)"
  },
  {
    "id": "7ac3f3a1-6a5f-4a9e-b852-f6e0a82da843",
    "class1": "證券業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "業務執行",
    "type": "fraction",
    "topic": "因應顧客提出業務需求，能以積極有效率方式完成服務作業，動作流暢展現專業。",
    "content": "（針對人員作業或服務的流暢專業度做判斷，若有其他長久等候狀況，另以反轉品質反應）"
  },
  {
    "id": "63960588-4d82-436e-a094-19e08731f354",
    "class1": "證券業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "業務執行",
    "type": "fraction",
    "topic": "針對顧客所需業務，能詳盡解答相關資訊，或積極協助填寫相應表單。",
    "content": ""
  },
  {
    "id": "7cf17689-817f-4594-8619-86fc73d4477f",
    "class1": "證券業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "客訴處理",
    "class5": "抱怨處理",
    "type": "fraction",
    "topic": "因應顧客申訴抱怨，能展現同理心，並耐心聆聽。",
    "content": ""
  },
  {
    "id": "596a1f4b-34c4-4403-9781-aee20807aa76",
    "class1": "證券業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "客訴處理",
    "class5": "抱怨處理",
    "type": "fraction",
    "topic": "因應顧客申訴抱怨，能先針對顧客感受致意或致歉，安撫顧客心情。",
    "content": ""
  },
  {
    "id": "ca5d6bc5-8a88-4969-b812-c250646f5c67",
    "class1": "證券業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "客訴處理",
    "class5": "抱怨處理",
    "type": "fraction",
    "topic": "因應顧客申訴抱怨，能釐清狀況或了解原因之後，提出適當解決方案或建議。",
    "content": "（解決方案若無法立刻提出，現場能以紙筆記錄或引導填寫客訴表單，也可得分）"
  },
  {
    "id": "ec93e822-9225-43d6-b3d4-2633711d5cd2",
    "class1": "證券業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "客訴處理",
    "class5": "抱怨處理",
    "type": "fraction",
    "topic": "因應顧客申訴抱怨，能請顧客留下聯絡資訊。",
    "content": "（若之前已填寫資料，有確認資訊及方便聯絡時間即可得分）"
  },
  {
    "id": "c2c198f3-a419-497e-887b-1dd325f0b9cb",
    "class1": "證券業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "應變服務",
    "class5": "友善服務",
    "type": "fraction",
    "topic": "訪員以正常顧客體驗視角，是否能觀察到現場任何友善服務的設施及服務設計？若有，請說明能看到哪些。",
    "content": ""
  },
  {
    "id": "a5ae6695-7ecb-4925-aa78-48154622355b",
    "class1": "證券業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "應變服務",
    "class5": "友善服務",
    "type": "fraction",
    "topic": "訪員提出親友可能需要友善金融的需求，觀察人員是否能簡易說明相關服務內容或流程",
    "content": "（如：小孩開戶、老人開戶、身心障礙的瘖啞、盲胞等，請訪員自行選擇）"
  },
  {
    "id": "925247df-02fc-48d9-b1dd-706a8cf08844",
    "class1": "證券業",
    "class2": "非現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服務禮儀",
    "type": "fraction",
    "topic": "按0轉接後，電話響三聲內有人員接聽。",
    "content": ""
  },
  {
    "id": "62016f9d-55f2-49e4-9a71-aca39ca1e5a9",
    "class1": "證券業",
    "class2": "非現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服務禮儀",
    "type": "fraction",
    "topic": "招呼問候語:「凱基證券您好，敝姓Ｏ，很高興為您服務！ 」",
    "content": ""
  },
  {
    "id": "3bd06ecd-eb97-4bff-81dc-d8f7d092d7ac",
    "class1": "證券業",
    "class2": "非現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服務禮儀",
    "type": "fraction",
    "topic": "聽完問題之後，能說出話術：「好的，馬上為您說明/查詢，請問先生/小姐貴姓，怎麼稱呼您？」",
    "content": ""
  },
  {
    "id": "92e69f99-dbcd-40ad-a9fb-01bc8ad2faf2",
    "class1": "證券業",
    "class2": "非現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服務禮儀",
    "type": "fraction",
    "topic": "顧客告知稱呼後，人員能再度致意：「Ｏ先生/小姐您好」，才開始回答問題。",
    "content": ""
  },
  {
    "id": "36aa7e27-09c1-407c-9cf9-6f999b113bea",
    "class1": "證券業",
    "class2": "非現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服務禮儀",
    "type": "fraction",
    "topic": "通話結束前，人員確認來電問題的說明是否能夠瞭解或問題已獲解決，並詢問是否還有其他需要服務的地方。",
    "content": ""
  },
  {
    "id": "45d3f117-9394-4dce-b7d2-5b45d750b6b3",
    "class1": "證券業",
    "class2": "非現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服務禮儀",
    "type": "fraction",
    "topic": "結束通話時，人員能致結束感謝語，譬如：「謝謝您的來電，祝您順心，再見！」",
    "content": ""
  },
  {
    "id": "c76a7c7e-05fc-46b3-82df-00152ab48110",
    "class1": "證券業",
    "class2": "非現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "服務禮儀",
    "type": "fraction",
    "topic": "結束通話時，人員能等候顧客先掛斷",
    "content": "（請訪員稍微等候1～2秒）"
  },
  {
    "id": "97f9a2ec-644c-4f98-b6a4-012d525c5f92",
    "class1": "證券業",
    "class2": "非現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "接聽感受",
    "type": "fraction",
    "topic": "語氣親切有精神，可感受朝氣。",
    "content": ""
  },
  {
    "id": "a35e2130-44a5-43d7-a19e-afe1b4937d40",
    "class1": "證券業",
    "class2": "非現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "接聽感受",
    "type": "fraction",
    "topic": "口條清晰，語速適中，讓顧客能清楚表達的意思。",
    "content": ""
  },
  {
    "id": "8e75f1c8-67fb-4ded-a951-e714670dba20",
    "class1": "證券業",
    "class2": "非現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "接聽感受",
    "type": "fraction",
    "topic": "互動耐心有禮，不打斷顧客也不插話。",
    "content": ""
  },
  {
    "id": "e0e2732c-3a68-4978-b3e1-f3252bd481dc",
    "class1": "證券業",
    "class2": "非現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "服務提供",
    "type": "fraction",
    "topic": "針對顧客提問的服務主題，能積極協助查詢相關資訊，並詳細說明。",
    "content": ""
  },
  {
    "id": "f80d8a07-139c-4652-ad4c-585d3bdf755d",
    "class1": "證券業",
    "class2": "非現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "服務提供",
    "type": "fraction",
    "topic": "對於提問事項，能妥適協助解決，或引導提出可行的解決建議，不一昧敷衍推托。",
    "content": ""
  },
  {
    "id": "57c91647-cf19-4e79-9ad8-9cbcef2873b4",
    "class1": "證券業",
    "class2": "非現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "服務提供",
    "type": "fraction",
    "topic": "提供訊息時，能主動提醒或詢問顧客是否能記錄相關資訊",
    "content": "（如告知電話號碼、地址、需要文件、辦理證件等）。"
  },
  {
    "id": "613bf409-3d29-4c93-8680-e7cd25508f88",
    "class1": "證券業",
    "class2": "非現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "服務提供",
    "type": "fraction",
    "topic": "服務有效率，查詢資料或說明內容節奏明快適當，不拖泥帶水。",
    "content": ""
  },
  {
    "id": "ee0e5623-9256-4896-b816-8fb4ded72c73",
    "class1": "證券業",
    "class2": "非現場",
    "class3": "神秘稽查",
    "class4": "客訴處理",
    "class5": "客訴處理",
    "type": "fraction",
    "topic": "訪員提出客訴，觀察人員因應顧客申訴抱怨，是否能展現同理心。",
    "content": "（1.客服中心電話很難打通,2.家人到分公司開戶卻被刁難,3.公司附近很難停車或交通不便,4.APP打開之後一直閃退,5.分公司人員現場態度不佳）"
  },
  {
    "id": "285f8488-5ab1-4482-9292-59f2852e2129",
    "class1": "證券業",
    "class2": "非現場",
    "class3": "神秘稽查",
    "class4": "客訴處理",
    "class5": "客訴處理",
    "type": "fraction",
    "topic": "因應顧客申訴抱怨，能先針對顧客感受致意或致歉，安撫顧客心情。",
    "content": ""
  },
  {
    "id": "8e560f5d-df54-4a72-a97f-80bd1a871fc9",
    "class1": "證券業",
    "class2": "非現場",
    "class3": "神秘稽查",
    "class4": "客訴處理",
    "class5": "客訴處理",
    "type": "fraction",
    "topic": "因應顧客申訴抱怨，能釐清狀況或了解原因之後，提出適當解決方案或建議。",
    "content": ""
  },
  {
    "id": "62ad6ccf-f04e-4681-bc00-82d24d6725c2",
    "class1": "證券業",
    "class2": "非現場",
    "class3": "神秘稽查",
    "class4": "客訴處理",
    "class5": "客訴處理",
    "type": "fraction",
    "topic": "因應顧客申訴抱怨，能請顧客留下聯絡資訊。",
    "content": ""
  },
  {
    "id": "11882417-494e-4793-8f89-5418aa4cef80",
    "class1": "連鎖超市",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "迎賓",
    "type": "fraction",
    "topic": "▲微笑\n 在顧客進店後或購物期間，服務人員於視線相對或問候時，能面帶微笑。",
    "content": ""
  },
  {
    "id": "7d9741e9-03e6-4a26-9cbf-5a37ec9bd9f8",
    "class1": "連鎖超市",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "迎賓",
    "type": "fraction",
    "topic": "▲歡迎詞\n 服務人員於顧客進店後能說出歡迎詞。",
    "content": "備註: 若服務人員正進行一對多服務時，只要服務過程有點頭微笑，展現歡迎之意即可給分。(須註明正進行一對多服務，如正服務其他顧客)"
  },
  {
    "id": "fe20a999-2161-4ade-a802-193a47fb7e64",
    "class1": "連鎖超市",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "迎賓",
    "type": "fraction",
    "topic": "▲親切熱忱\n 服務人員能透過問候或歡迎詞的語調，展現親切熱忱。\n \n 備註: 若服務人員正進行一對多服務時，只要服務過程有點頭微笑，展現歡迎之意即可給分。(須註明正進行一對多服務，如正服務其他顧客)",
    "content": ""
  },
  {
    "id": "b268bc84-5a59-473b-8767-4182dce2efa9",
    "class1": "連鎖超市",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "迎賓",
    "type": "fraction",
    "topic": "▲音量適中\n 歡迎詞音量適中，至少能清楚聽見內容",
    "content": ""
  },
  {
    "id": "4c1b0540-d07c-4d1d-9893-3123df9694ad",
    "class1": "連鎖超市",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "結帳",
    "type": "fraction",
    "topic": "▲會員確認\n 人員於結帳時能詢問顧客是否為會員。",
    "content": ""
  },
  {
    "id": "9cbb23d4-1956-4c3c-8ec2-db975f385e74",
    "class1": "連鎖超市",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "結帳",
    "type": "fraction",
    "topic": "▲統編載具\n 人員於結帳時能詢問顧客統編或載具需求。",
    "content": ""
  },
  {
    "id": "bcf15184-45d1-4451-9710-e48c756720ae",
    "class1": "連鎖超市",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "結帳",
    "type": "fraction",
    "topic": "▲購物袋\n 人員能詢問顧客是否自備購物袋。",
    "content": "購物品項少於3項不需詢問，但應整齊擺放"
  },
  {
    "id": "ee901dbc-3f2c-4592-aa45-523097c7d8a3",
    "class1": "連鎖超市",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "結帳",
    "type": "fraction",
    "topic": "▲加價購商品推薦\n 結帳時人員能推薦加價購商品。",
    "content": ""
  },
  {
    "id": "c3bef700-c9ad-4ca8-bdd6-93b0bcdc2dbc",
    "class1": "連鎖超市",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "結帳",
    "type": "fraction",
    "topic": "▲確認付款方式\n 結帳時人員能詢問顧客付款方式。",
    "content": ""
  },
  {
    "id": "434dfb1c-1010-4a33-9935-cd00807b56b7",
    "class1": "連鎖超市",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "結帳",
    "type": "fraction",
    "topic": "▲確認商品金額\n 結帳時人員能核對商品數量並說明總金額。",
    "content": ""
  },
  {
    "id": "6f59af65-a899-4146-a28b-8049fdcbde32",
    "class1": "連鎖超市",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "結帳",
    "type": "fraction",
    "topic": "▲唱收唱付\n 能說明收取金額和找零，若刷卡能提醒感應或簽名。",
    "content": ""
  },
  {
    "id": "cfe78904-ac83-46fe-96ba-18db0cc2a6f7",
    "class1": "連鎖超市",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "結帳",
    "type": "fraction",
    "topic": "▲禮貌遞收\n 結帳過程，人員能有禮貌動作輕柔遞取現金、信用卡和發票。",
    "content": "備註: 不論是雙手遞收或遞交時輕放於櫃台桌上皆給分。"
  },
  {
    "id": "7a0c15f7-cff4-490f-8a93-223ae76842d1",
    "class1": "連鎖超市",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "結帳",
    "type": "fraction",
    "topic": "▲裝袋交付\n 結帳後能協助顧客裝袋，或將物品擺放整齊方便顧客拿取。",
    "content": ""
  },
  {
    "id": "160ad027-5eeb-433c-9d1c-c447fb529a55",
    "class1": "連鎖超市",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "送客",
    "type": "fraction",
    "topic": "▲提醒\n 溫馨提醒顧客隨身攜帶物品",
    "content": "建議稽查要攜帶背包或提袋"
  },
  {
    "id": "9fd2c46e-7371-496a-9335-3c695c27758c",
    "class1": "連鎖超市",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "送客",
    "type": "fraction",
    "topic": "▲感謝道別\n 能向顧客親切致道別感謝詞。",
    "content": ""
  },
  {
    "id": "0690f419-0859-499b-976f-4b81255395c7",
    "class1": "連鎖超市",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "送客",
    "type": "fraction",
    "topic": "▲親切熱忱\n 服務人員問道別或感謝的語調親切熱忱。",
    "content": ""
  },
  {
    "id": "5201e646-d953-4937-9eff-dbeed48bcbea",
    "class1": "連鎖超市",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "送客",
    "type": "fraction",
    "topic": "▲音量適中\n 感謝道別詞音量適中，至少能清楚聽見內容",
    "content": ""
  },
  {
    "id": "f8729592-75e6-4566-8710-dd15db57fbdb",
    "class1": "連鎖超市",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "關懷",
    "type": "fraction",
    "topic": "▲服務意願\n 主動顧客表達提供服務的意願",
    "content": "如：「有需要協助都可以找我唷」等話語都可得分"
  },
  {
    "id": "8f1ab416-e894-4994-8769-439e96daa7e5",
    "class1": "連鎖超市",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "關懷",
    "type": "fraction",
    "topic": "▲主動協助\n 有任何主動協助顧客的行為，如幫忙拿取或引導到位。",
    "content": "不主動鋪梗，請描述服務情境和行為，若未協助，在做什麼？"
  },
  {
    "id": "9c92b9ba-516a-4af3-aed8-b58a4a1af1b6",
    "class1": "連鎖超市",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "關懷",
    "type": "fraction",
    "topic": "▲促銷活動說明\n 人員主動介紹當前或未來檔期的促銷活動。",
    "content": ""
  },
  {
    "id": "338e5c4e-57c9-4cfa-bce2-b1d664b8632b",
    "class1": "連鎖超市",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "關懷",
    "type": "fraction",
    "topic": "▲態度貼心\n 耐心專心傾聽顧客，主動協助解決顧客的問題。",
    "content": "不鋪梗，觀察到對其他顧客的服務也可列入判斷"
  },
  {
    "id": "bcf70649-4651-4aba-bb52-5dfb68dc9f82",
    "class1": "連鎖超市",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "會員",
    "type": "fraction",
    "topic": "▲會員服務-詢問\n 非APP會員，能主動詢問電話號碼。\n 是APP會員，能主動提醒顧客出示APP條碼。",
    "content": ""
  },
  {
    "id": "fde6c582-ee15-467a-b683-220513247529",
    "class1": "連鎖超市",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "會員",
    "type": "fraction",
    "topic": "▲會員服務-稱呼\n 非APP會員-查詢顧客資料後主動以對方姓氏尊稱。\n APP會員-能主動提醒顧客出示APP條碼",
    "content": ""
  },
  {
    "id": "9cc2f4e8-d58c-47c6-b95c-eb85ccf1bfb7",
    "class1": "連鎖超市",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "會員",
    "type": "fraction",
    "topic": "▲會員服務-推廣加入\n 若非會員，能主動邀請顧客成為美廉社會員。",
    "content": ""
  },
  {
    "id": "b76951f5-449e-4463-9c0d-964f56d28d15",
    "class1": "連鎖超市",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "會員",
    "type": "fraction",
    "topic": "▲會員服務-介紹優惠權益\n 能清楚說明或告知會員優惠與會員權益。",
    "content": ""
  },
  {
    "id": "8889c44d-6d25-4a23-985d-80c3bbd472cf",
    "class1": "連鎖超市",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "會員",
    "type": "fraction",
    "topic": "▲會員服務-協助辦理\n 顧客同意下，能積極協助辦理會員。",
    "content": ""
  },
  {
    "id": "ecd1a37b-41ae-4970-9bdf-6721f3845d9f",
    "class1": "連鎖超市",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "專業服務",
    "class5": "會員",
    "type": "fraction",
    "topic": "▲APP協助\n 親切有耐心的指導顧客下載會員APP，並正確教導操作步驟。\n 若顧客不方便現場學習，請親切的口頭簡易說明如何下載。",
    "content": ""
  },
  {
    "id": "0434b99b-a299-4e46-b57d-5d60d82ad030",
    "class1": "連鎖超市",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "額外觀察",
    "class5": "咖啡機店鋪－推廣專用題",
    "type": "fraction",
    "topic": "▲咖啡商品推薦\n 服務人員能主動推薦各式咖啡商品及促銷活動。\n 0分沒有任何邀請或推薦促銷咖啡行為\n 1分有邀請買咖啡\n 2分邀請或咖啡促銷活動推薦，且表現態度良好不敷衍\n 3分邀請+咖啡促銷活動推薦\n 4分邀請+咖啡促銷活動推薦且表現態度良好不敷衍\n 5分邀請+咖啡促銷活動推薦+咖啡豆、口感、喝咖啡好處介紹",
    "content": ""
  },
  {
    "id": "d86a6d40-76a0-4428-923b-2f01b0a852c7",
    "class1": "銀行業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "引導員",
    "class5": "服裝儀容",
    "type": "fraction",
    "topic": "頭髮，臉部清爽潔淨/ 女性淡妝，口氣清新，舉止優雅得宜",
    "content": ""
  },
  {
    "id": "0ab5e1f0-a007-40a7-a2a5-50a5029ff0d9",
    "class1": "銀行業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "引導員",
    "class5": "整體表現",
    "type": "fraction",
    "topic": "態度親切有禮",
    "content": ""
  },
  {
    "id": "048e505a-f4aa-4afc-a3bf-c5795942643b",
    "class1": "銀行業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "引導員",
    "class5": "整體表現",
    "type": "fraction",
    "topic": "能主動探詢來行目的",
    "content": ""
  },
  {
    "id": "f81b0bd2-0bbb-4347-8b0d-e0d57af0120d",
    "class1": "銀行業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "引導員",
    "class5": "整體表現",
    "type": "fraction",
    "topic": "能提供清楚指引或引導",
    "content": ""
  },
  {
    "id": "769f9a70-0d70-4195-8cf0-9f3a190834fc",
    "class1": "銀行業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "引導員",
    "class5": "送客",
    "type": "fraction",
    "topic": "送別時致上關心(外面車多，請小心，外面冷記得保暖等)及祝福話語(假期愉快、聖誕快樂...)",
    "content": ""
  },
  {
    "id": "a17dcc08-042a-499d-9c45-0e69c85a0e4e",
    "class1": "銀行業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "分行大廳服務",
    "class5": "客戶經營",
    "type": "fraction",
    "topic": "在分行營業廳有看見大廳服務人員",
    "content": ""
  },
  {
    "id": "ce84d497-e4f0-464e-8059-2f0405e6c548",
    "class1": "銀行業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "分行大廳服務",
    "class5": "客戶經營",
    "type": "fraction",
    "topic": "大廳服務人員能主動關心大廳等候客戶",
    "content": ""
  },
  {
    "id": "fb197d58-acb2-4f7b-832d-743746aa9e7c",
    "class1": "銀行業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "分行大廳服務",
    "class5": "客戶經營",
    "type": "fraction",
    "topic": "大廳服務人員顧客需等候時，服務人員有表現設法緩解客戶不耐久候感受或提供其他方案",
    "content": ""
  },
  {
    "id": "f98f5e42-36dc-4141-9bcd-6230ef223694",
    "class1": "銀行業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "分行大廳服務",
    "class5": "客戶經營",
    "type": "fraction",
    "topic": "大廳服務整體感受",
    "content": ""
  },
  {
    "id": "b5e13437-289a-467e-978c-9c8a668068ba",
    "class1": "銀行業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "分行大廳服務",
    "class5": "數位推廣",
    "type": "fraction",
    "topic": "有使用Pad服務客戶或推廣數位服務，提供客戶臨櫃前的交易服務",
    "content": ""
  },
  {
    "id": "427527ce-5c6a-406c-99ad-7bc74de24fda",
    "class1": "銀行業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "櫃台服務",
    "class5": "服裝儀容",
    "type": "fraction",
    "topic": "頭髮，臉部清爽潔淨/女性淡妝，口氣清新，舉止優雅得宜",
    "content": ""
  },
  {
    "id": "2c5880f1-1acb-4207-ba84-26cc20e22944",
    "class1": "銀行業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "櫃台服務",
    "class5": "服務應對",
    "type": "fraction",
    "topic": "主動打招呼且態度親切有禮",
    "content": ""
  },
  {
    "id": "783d5cde-3a4b-4810-b081-0c15269caf9d",
    "class1": "銀行業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "櫃台服務",
    "class5": "服務應對",
    "type": "fraction",
    "topic": "回答問題清楚完整",
    "content": ""
  },
  {
    "id": "26078daa-fda3-49d5-a859-de96ab5ca718",
    "class1": "銀行業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "櫃台服務",
    "class5": "服務應對",
    "type": "fraction",
    "topic": "主動詢問是否有其他業務需求",
    "content": ""
  },
  {
    "id": "437ab152-b5f3-405d-a45a-82549e3a2549",
    "class1": "銀行業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "櫃台服務",
    "class5": "服務應對",
    "type": "fraction",
    "topic": "起立迎接\n 提醒:著背心長裙為孕婦，可無需起立迎接，以微笑寒喧迎賓即可。",
    "content": ""
  },
  {
    "id": "147f0f31-720c-4745-84cb-5fbd37fc70be",
    "class1": "銀行業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "櫃台服務",
    "class5": "服務應對",
    "type": "fraction",
    "topic": "互動服務過程感受",
    "content": ""
  },
  {
    "id": "97887d69-c39d-49e1-a78b-1be10528cfd3",
    "class1": "銀行業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "櫃台服務",
    "class5": "服務應對",
    "type": "fraction",
    "topic": "親切有禮的回應",
    "content": ""
  },
  {
    "id": "64d4029f-0c46-4be9-9132-90946c6c8af2",
    "class1": "銀行業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "櫃台服務",
    "class5": "服務應對",
    "type": "fraction",
    "topic": "送客\n 提醒:著背心長裙為孕婦，可無需起立送客，以微笑致道別語即可。",
    "content": ""
  },
  {
    "id": "5eaf2f15-21cc-4703-ba7e-9d31b56e22ef",
    "class1": "銀行業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "櫃台服務",
    "class5": "專業服務",
    "type": "fraction",
    "topic": "熱忱\n -積極主動的熱情氛圍",
    "content": ""
  },
  {
    "id": "869efb47-9eee-4440-8d65-57408c1238db",
    "class1": "銀行業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "櫃台服務",
    "class5": "專業服務",
    "type": "fraction",
    "topic": "便利迅速\n -敏捷的處理顧客委託事項，為顧客節省時間",
    "content": ""
  },
  {
    "id": "b76172d1-4cef-4cb6-8665-c38f9e5acef6",
    "class1": "銀行業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "櫃台服務",
    "class5": "專業服務",
    "type": "fraction",
    "topic": "值得信賴\n -處理過程表現熟嫻業務之外，更能站在顧客立場看待業務事項，深得顧客信任",
    "content": ""
  },
  {
    "id": "a282466c-a074-4fd0-a2b5-9a1e239fb39e",
    "class1": "銀行業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "櫃台服務",
    "class5": "專業服務",
    "type": "fraction",
    "topic": "反應力\n -立即能正確無誤的掌握顧客所表達需求，互動過程有機敏的反應應對能力",
    "content": ""
  },
  {
    "id": "e7c5ec8f-924f-4395-b929-990fd51f30f5",
    "class1": "銀行業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "狀況題回應",
    "class5": "聆聽",
    "type": "fraction",
    "topic": "耐心聆聽，理解問題",
    "content": ""
  },
  {
    "id": "8cb55576-cbfb-402b-a826-0c1ac72c8d37",
    "class1": "銀行業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "狀況題回應",
    "class5": "確認核心",
    "type": "fraction",
    "topic": "確認問題核心\n (質疑抱怨時，安撫情緒)",
    "content": ""
  },
  {
    "id": "d4f1741c-fc4f-4594-9ae1-c790a44a31ee",
    "class1": "銀行業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "狀況題回應",
    "class5": "提出方法",
    "type": "fraction",
    "topic": "提出解說或解決方法\n (一時無法解決，也能提出替代方案)",
    "content": ""
  },
  {
    "id": "704f998e-a821-43cb-8cf0-379d19ec134f",
    "class1": "銀行業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "狀況題回應",
    "class5": "妥善完成",
    "type": "fraction",
    "topic": "確認問題解決",
    "content": ""
  },
  {
    "id": "3629ee20-4823-445b-aa45-9359d1ebf6de",
    "class1": "銀行業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "狀況題回應",
    "class5": "致意",
    "type": "fraction",
    "topic": "感謝顧客",
    "content": ""
  },
  {
    "id": "f5c4ef2b-258f-42df-8cd8-3444512f71a2",
    "class1": "銀行業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "友善環境",
    "class5": "整體整潔",
    "type": "fraction",
    "topic": "1.分行招牌乾淨無破損、大門貼字完整未掉字\n 2.客戶可視空間及客戶服務區域，地板/毯、傢俱、壁面是否無髒污、破損，燈光是否明亮",
    "content": ""
  },
  {
    "id": "425f3574-2e14-48be-ba22-d81e41234909",
    "class1": "銀行業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "友善環境",
    "class5": "行舍安全",
    "type": "fraction",
    "topic": "1.分行營業空間內是否堆積雜物，如打掃工具、紙箱、礦泉水，影響行走動線\n 2.營業空間內的門皆保持關閉。(無法看進內部空間)",
    "content": ""
  },
  {
    "id": "4bf7fbdd-8eda-4a3f-bb56-3346e045d981",
    "class1": "銀行業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "其他",
    "class5": "額外觀察",
    "type": "shortAnswer",
    "topic": "有無其他令您覺得感動或做得很好的事項？ 請簡要寫下",
    "content": ""
  },
  {
    "id": "42f5c40a-0e53-4164-a552-6509750dae2a",
    "class1": "銀行業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "其他",
    "class5": "額外觀察",
    "type": "shortAnswer",
    "topic": "有無其他令您覺得生氣或做得很差的事項？ 請簡要寫下",
    "content": ""
  },
  {
    "id": "4969f067-de07-4704-a054-2f62a9e18494",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "第一印象",
    "class5": "店內環境",
    "type": "fraction",
    "topic": "入口處的清潔度。",
    "content": ""
  },
  {
    "id": "6a30639b-dce1-4e8f-8d43-62798d43f9e2",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "第一印象",
    "class5": "店內環境",
    "type": "fraction",
    "topic": "門口的招貼、立式菜單以及廣告橫幅是否引人注目？",
    "content": ""
  },
  {
    "id": "3bf47e5e-b3f6-4888-a79e-9513d9d1f34b",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "第一印象",
    "class5": "店內環境",
    "type": "fraction",
    "topic": "用餐桌椅的清潔度。",
    "content": ""
  },
  {
    "id": "34b7e22c-2107-4ed3-90e0-aaa980f68485",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "第一印象",
    "class5": "服務應對",
    "type": "fraction",
    "topic": "在您光臨時店員是否熱情歡迎呢？",
    "content": ""
  },
  {
    "id": "2c50c2d1-0570-4883-ae91-922c4a2f6c7e",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "滿意度",
    "class5": "服務應對",
    "type": "fraction",
    "topic": "您對店員提供的服務感受如何？",
    "content": ""
  },
  {
    "id": "4d88da33-e448-4839-83a5-b4ca3ddb2de7",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "滿意度",
    "class5": "店內環境",
    "type": "fraction",
    "topic": "整體餐廳清潔度。",
    "content": ""
  },
  {
    "id": "9331c09c-05df-447b-b419-c3af84a2f200",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "滿意度",
    "class5": "產品內容",
    "type": "fraction",
    "topic": "您覺得肉品「擺盤」如何呢？",
    "content": ""
  },
  {
    "id": "2d6d4471-f588-483d-a98c-0a8e22d0db7f",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "滿意度",
    "class5": "產品內容",
    "type": "fraction",
    "topic": "您覺得肉品「份量」如何呢？",
    "content": ""
  },
  {
    "id": "809aaae4-97f9-4e48-a74c-b83b12d19013",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "滿意度",
    "class5": "產品內容",
    "type": "fraction",
    "topic": "您覺得沾醬和香辛料的味道如何呢？",
    "content": ""
  },
  {
    "id": "2b4b24ee-dba6-4d69-88bc-8d6b06ea97c5",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "滿意度",
    "class5": "產品內容",
    "type": "fraction",
    "topic": "您覺得飯的味道如何呢？",
    "content": ""
  },
  {
    "id": "edfab9fb-efa9-48f4-94d0-db5219dee5bb",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "滿意度",
    "class5": "產品內容",
    "type": "fraction",
    "topic": "您覺得商品價格如何呢？",
    "content": ""
  },
  {
    "id": "8b952f38-b4cd-445a-ac3e-5ef57a1734dd",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "顧客關懷",
    "class5": "服務應對",
    "type": "fraction",
    "topic": "店員是否能表現笑容、真誠的問候，令您感到受歡迎？",
    "content": ""
  },
  {
    "id": "7746e605-50a9-4171-8df8-93cf7216ebec",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "顧客關懷",
    "class5": "服務應對",
    "type": "shortAnswer",
    "topic": "店員侍應／接待您的方式：",
    "content": ""
  },
  {
    "id": "78e0217e-ffa6-41c9-ac5b-9900082d6e25",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "顧客關懷",
    "class5": "服務應對",
    "type": "fraction",
    "topic": "店員是否體貼地請您入座？",
    "content": ""
  },
  {
    "id": "74029489-514d-416f-9b7d-20ead7d124ef",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "顧客關懷",
    "class5": "服務應對",
    "type": "shortAnswer",
    "topic": "店員帶您入座的方式：",
    "content": ""
  },
  {
    "id": "4475f6f4-a44b-47f6-a0b8-177ca41913ac",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "顧客關懷",
    "class5": "服務應對",
    "type": "fraction",
    "topic": "店員是否詢問您是不是第一次到Yakiniku Like用餐？",
    "content": ""
  },
  {
    "id": "4d62ba10-7d3e-466b-a7f8-db11035c1742",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "顧客關懷",
    "class5": "服裝儀容",
    "type": "fraction",
    "topic": "店員是否佩戴名牌？",
    "content": ""
  },
  {
    "id": "02c8625f-d4a3-4855-8790-c6b8b180614d",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "顧客關懷",
    "class5": "服裝儀容",
    "type": "shortAnswer",
    "topic": "描述為您服務的店員姓名和外貌：",
    "content": ""
  },
  {
    "id": "e1c47dcf-f96d-4f34-a395-0bef42df0634",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "點單和服務",
    "class5": "服務應對",
    "type": "fraction",
    "topic": "QR條碼點餐系統是否容易使用？",
    "content": ""
  },
  {
    "id": "8efbbbb1-a5f4-4dc5-a377-bda0baf2f851",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "點單和服務",
    "class5": "服務應對",
    "type": "fraction",
    "topic": "店員是否有說出送來的餐點名稱？且送達的餐點正確？",
    "content": ""
  },
  {
    "id": "f85c287f-3a2d-4952-8c36-ca66aef904db",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "點單和服務",
    "class5": "服務應對",
    "type": "shortAnswer",
    "topic": "請您說明當時送餐狀況：",
    "content": ""
  },
  {
    "id": "dae5992b-3b22-4dd4-8696-8c68d89f9d85",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "點單和服務",
    "class5": "服務應對",
    "type": "fraction",
    "topic": "店員在送完餐點後是否有說「請慢用」？",
    "content": ""
  },
  {
    "id": "63473f69-eb5d-4419-9470-e315e599117a",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "點單和服務",
    "class5": "服務應對",
    "type": "fraction",
    "topic": "當您向店員提出服務需求時，店員是否能在30秒內察覺到？",
    "content": ""
  },
  {
    "id": "8904af09-1856-4ea4-a4ea-8f88e2b9b762",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "點單和服務",
    "class5": "店內環境",
    "type": "fraction",
    "topic": "用餐桌面是否乾淨？且設施能保持完整？",
    "content": ""
  },
  {
    "id": "6db6b46e-ba21-468e-836b-72f9dfe575dc",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "點單和服務",
    "class5": "店內環境",
    "type": "shortAnswer",
    "topic": "餐桌的狀況：",
    "content": ""
  },
  {
    "id": "9e51aabf-121c-456f-88a2-6eaf8c545f63",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "點單和服務",
    "class5": "服務應對",
    "type": "fraction",
    "topic": "結帳的過程是否順暢愉快？",
    "content": ""
  },
  {
    "id": "6ce4c8dc-6a3c-418b-8bc4-180e4ea36a23",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "點單和服務",
    "class5": "服務應對",
    "type": "shortAnswer",
    "topic": "結帳時的狀況：",
    "content": ""
  },
  {
    "id": "44081823-a84e-4884-bfd6-fe3a11eb73b1",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "點單和服務",
    "class5": "服務應對",
    "type": "fraction",
    "topic": "付完款後店員是否有感謝您來用餐？",
    "content": ""
  },
  {
    "id": "da5c3cfb-7c8e-42c6-812f-9df97339b481",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "點單和服務",
    "class5": "服務應對",
    "type": "shortAnswer",
    "topic": "店員當時的狀況：",
    "content": ""
  },
  {
    "id": "8c9ce309-97df-4acd-be9f-5fac7b30ca0c",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "服務速度",
    "class5": "服務速度",
    "type": "fraction",
    "topic": "餐點都有在3分鐘內上餐完畢嗎？",
    "content": ""
  },
  {
    "id": "d1723ea7-2569-44b8-a18f-d753a171337c",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "能量傳遞",
    "class5": "服務應對",
    "type": "fraction",
    "topic": "服務人員的態度和行為是否良好？",
    "content": ""
  },
  {
    "id": "fa0e801d-b246-494e-9cb6-39fc74f28726",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "能量傳遞",
    "class5": "服務應對",
    "type": "shortAnswer",
    "topic": "店員的態度、行為缺失之處：",
    "content": ""
  },
  {
    "id": "c79decd4-a438-45cf-aeb2-a501d91db091",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "儀容",
    "class5": "服裝儀容",
    "type": "fraction",
    "topic": "所有的店員儀容是否皆能保持乾淨整潔（頭髮、制服、帽子），且配戴口罩和名牌？",
    "content": ""
  },
  {
    "id": "66e9310b-2f04-48d4-98e9-1589345ab17e",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "儀容",
    "class5": "服裝儀容",
    "type": "shortAnswer",
    "topic": "店員儀容缺失之處：",
    "content": ""
  },
  {
    "id": "83f1168f-70ca-441d-84b7-4c8dc5259cc5",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "整潔度",
    "class5": "店內環境",
    "type": "fraction",
    "topic": "整體用餐區是否乾淨？",
    "content": ""
  },
  {
    "id": "2aa10cce-0d72-4a56-aad4-c89cf84dbac6",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "整潔度",
    "class5": "店內環境",
    "type": "shortAnswer",
    "topic": "用餐環境整潔狀況：",
    "content": ""
  },
  {
    "id": "5ddb4ca0-9411-48fb-9538-f04385fbe9e8",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "整潔度",
    "class5": "店內環境",
    "type": "fraction",
    "topic": "桌面所有設備及備品是否乾淨且沒有汙垢、碎片或裂痕？",
    "content": ""
  },
  {
    "id": "2aa85c84-22ad-41c9-9bb2-903da73f7a0d",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "整潔度",
    "class5": "店內環境",
    "type": "shortAnswer",
    "topic": "設備和備品的狀況：",
    "content": ""
  },
  {
    "id": "3866cfd4-39a4-4004-ac95-cc427106bb6e",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "內部",
    "class5": "店內環境",
    "type": "fraction",
    "topic": "餐廳環境是否令您感到舒適？",
    "content": ""
  },
  {
    "id": "0b039461-0163-4df3-a224-00e433754344",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "內部",
    "class5": "店內環境",
    "type": "shortAnswer",
    "topic": "餐廳環境狀況：",
    "content": ""
  },
  {
    "id": "dc2654ad-fd8e-4212-8301-e6371522bceb",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "產品",
    "class5": "產品內容",
    "type": "fraction",
    "topic": "肉品的品質是否良好？",
    "content": ""
  },
  {
    "id": "df604e23-9a45-4751-86a1-9d129cb08f4c",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "產品",
    "class5": "產品內容",
    "type": "shortAnswer",
    "topic": "肉品狀況：",
    "content": ""
  },
  {
    "id": "051d159f-2497-4e5d-8100-77ddfa271591",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "產品",
    "class5": "產品內容",
    "type": "fraction",
    "topic": "湯的品質是否良好？",
    "content": ""
  },
  {
    "id": "c3b10dd1-2282-4657-8db0-e37e64a25464",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "產品",
    "class5": "產品內容",
    "type": "fraction",
    "topic": "沾醬和香辛料的品質是否良好？",
    "content": ""
  },
  {
    "id": "364d41a9-2d81-4ed1-bd7a-990f31e28831",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "產品",
    "class5": "產品內容",
    "type": "fraction",
    "topic": "沙拉或韓式泡菜的品質是否良好？",
    "content": ""
  },
  {
    "id": "0372f28b-330a-4721-b957-e51ee9eb32ad",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本資訊",
    "class5": "基本資訊",
    "type": "shortAnswer",
    "topic": "這是您第幾次來用餐？",
    "content": ""
  },
  {
    "id": "d61726b8-d834-43df-8b90-4dd4655d560d",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本資訊",
    "class5": "基本資訊",
    "type": "shortAnswer",
    "topic": "使您願意下次再來用餐的原因為何呢？",
    "content": ""
  },
  {
    "id": "d49f9d90-434d-4282-ac0e-d52f6d03e903",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本資訊",
    "class5": "基本資訊",
    "type": "shortAnswer",
    "topic": "未來您希望餐廳推出什麼樣的餐點呢？",
    "content": ""
  },
  {
    "id": "bf70cc82-064c-4eeb-ae6c-17dd929bf0cb",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本資訊",
    "class5": "基本資訊",
    "type": "shortAnswer",
    "topic": "您覺得有其他一人燒肉品牌較值得嘗試嗎？如果有，是哪個品牌？它的什麼地方吸引您呢？",
    "content": ""
  },
  {
    "id": "24e9c8c8-262d-4f6c-b9f5-5dbdc8ace54c",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本資訊",
    "class5": "基本資訊",
    "type": "shortAnswer",
    "topic": "其他回饋：",
    "content": ""
  },
  {
    "id": "e0139fb5-3b1e-46e3-9986-0ec5fc4a9512",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本資訊",
    "class5": "基本資訊",
    "type": "shortAnswer",
    "topic": "您的年齡層介於：",
    "content": ""
  },
  {
    "id": "1bb14b83-4925-4b04-989b-5b563e5175d2",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "總評",
    "class5": "總評",
    "type": "shortAnswer",
    "topic": "觀察與建議事項",
    "content": ""
  },
  {
    "id": "73bbb2d8-ae3c-492a-ba4c-2eb568a214fb",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "額外觀察",
    "class5": "魅力反轉",
    "type": "shortAnswer",
    "topic": "魅力品質項目\n （請分點敘述）",
    "content": ""
  },
  {
    "id": "1e1e62ac-b1a7-4a0a-ba19-2d5ddd8bdf5b",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "額外觀察",
    "class5": "魅力反轉",
    "type": "shortAnswer",
    "topic": "反轉品質項目\n （請分點敘述）",
    "content": ""
  },
  {
    "id": "541764ab-87b9-4a70-b076-925e99fb6c5b",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "總評",
    "class5": "服務人員",
    "type": "shortAnswer",
    "topic": "推薦人員\n （稽查期間請選出一位表現最好的服務人員）",
    "content": ""
  },
  {
    "id": "9aef7811-c604-4853-932a-ac37a15de233",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "總評",
    "class5": "服務人員",
    "type": "shortAnswer",
    "topic": "點餐時，當訪員表現猶豫請店員推薦，店員如何介紹？",
    "content": ""
  },
  {
    "id": "a8899c25-19dd-4969-83cd-774439d9ebc1",
    "class1": "連鎖燒烤店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "總評",
    "class5": "點餐資訊",
    "type": "shortAnswer",
    "topic": "訪員套餐品項及金額",
    "content": ""
  },
  {
    "id": "0cb9b228-9c21-4741-86ec-65d78900e62c",
    "class1": "連鎖甜點店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "人員基本服務",
    "type": "fraction",
    "topic": "與客戶展開互動時，人員能致上招呼問候，如您好或早安、午安、晚安等相關方式問候。(描述請確實寫出失分人名)",
    "content": ""
  },
  {
    "id": "d849c424-efac-4856-a66c-fb91eead79da",
    "class1": "連鎖甜點店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "人員基本服務",
    "type": "fraction",
    "topic": "互動過程中，服務人員能展現微笑，並且服務時能適時保持目光接觸。(人員指引DM或促銷內容時除外）",
    "content": ""
  },
  {
    "id": "94c471f3-92d2-4289-9dbe-85ee0fd21647",
    "class1": "連鎖甜點店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "人員基本服務",
    "type": "fraction",
    "topic": "指引方向或商品時人員能以五指併攏伸直方式引導 。",
    "content": ""
  },
  {
    "id": "23359c1e-7d81-44e8-8a98-53ca64c11a31",
    "class1": "連鎖甜點店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "人員基本服務",
    "type": "fraction",
    "topic": "人員儀態良好，服裝整齊，配戴名牌，沒有不適宜的行為。\n （譬如：三七步、手插口袋及放置背後。頭髮凌亂，名牌歪垂、喧嘩、打瞌睡、聚集聊天或在客戶面前飲食）",
    "content": ""
  },
  {
    "id": "1a927867-4f45-4b06-be0b-1b7a8d77f2ae",
    "class1": "連鎖甜點店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "人員基本服務",
    "type": "fraction",
    "topic": "拿取或給予客人相關物品時，以雙手托住及示意等良好方式遞送（只有小白單與提供試吃時不在範圍內）。包含外點、結帳、對點三個階段的服務過程中，有不符合狀況需描述",
    "content": ""
  },
  {
    "id": "c6d72ac1-e905-48f7-81ef-a69f51161533",
    "class1": "連鎖甜點店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "人員基本服務",
    "type": "fraction",
    "topic": "當客戶有疑問或需求時，應給予正面的建議或指引，不直接表現出「不知道、不行、不清楚或沒辦法」等負面回應。(譬如詢問鹹食、商品口味、有沒有宅配等)並且客戶提出疑問時，人員能放下手邊工作，專心傾聽客戶的需求。",
    "content": ""
  },
  {
    "id": "17f5d215-c9f3-41cf-9322-e12b8d0769f2",
    "class1": "連鎖甜點店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "人員基本服務",
    "type": "fraction",
    "topic": "內用送餐時，人員能輕聲提醒。(當天若為外帶為N/A)\n （如：不好意思幫您送餐/這是您的餐點/請慢用）",
    "content": ""
  },
  {
    "id": "dea3af6b-ea37-4599-8f3e-6968ac0aa5be",
    "class1": "連鎖甜點店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "人員基本服務",
    "type": "fraction",
    "topic": "對點商品時人員能再次向客戶覆誦商品種類數量、食用效期或者提醒當天吃口感會更好等話術。(結帳後取餐時)\n 如：原味生乳一條，切片10片。保存期限到幾號。",
    "content": ""
  },
  {
    "id": "02caaf8e-70ed-4a83-9218-457aaa9c05a2",
    "class1": "連鎖甜點店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "人員基本服務",
    "type": "fraction",
    "topic": "若客戶表示先看看時，人員能告知客戶「有任何需要都可以再跟我說」等禮貌用語\n (婉轉說明及使用禮貌用語都給分，如您慢慢看，您再找我)",
    "content": ""
  },
  {
    "id": "da4e7254-4744-4fbd-b183-fa81929a1e20",
    "class1": "連鎖甜點店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "商品基本服務",
    "type": "fraction",
    "topic": "蛋糕櫃內切片蛋糕第一名到第三名及明星商品能不缺貨。(一陣子會更新一次)\n (明星商品:原味生乳捲、檔期生乳捲(紫薯芋泥 )、巴斯克2款、黑酷曲2款\"起司磚)",
    "content": ""
  },
  {
    "id": "39283552-b1cb-45f5-a78f-bc3468b2cfed",
    "class1": "連鎖甜點店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "商品基本服務",
    "type": "fraction",
    "topic": "蛋糕櫃內蛋糕外觀完整無缺損。",
    "content": ""
  },
  {
    "id": "8eb51a5c-2799-4eed-8a3c-90de9cc10c86",
    "class1": "連鎖甜點店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "商品基本服務",
    "type": "fraction",
    "topic": "蛋糕櫃內蛋糕售完能立即撤下價牌或擺放已售完牌子。\n (不會看到空位只有價牌)",
    "content": ""
  },
  {
    "id": "cfa7190a-72d9-4b36-8a7f-38cafe73d975",
    "class1": "連鎖甜點店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "商品基本服務",
    "type": "fraction",
    "topic": "伴手禮商品效期不得少於７日（麻糬除外）。例如：12/12看不到12/17到期之商品。\n (請參考文件) (請需記錄到包裝上的有效日期)",
    "content": ""
  },
  {
    "id": "765eeef9-d562-4b61-9d3d-3c52f6f8b862",
    "class1": "連鎖甜點店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "商品基本服務",
    "type": "fraction",
    "topic": "接觸蛋糕時（包裝、上架、切商品）須戴手套，製作飲料前須洗手，過程符合衛生清潔。\n 對點人員可戴手套，其餘工作崗位不戴手套，看不到飲料製作直接以接觸蛋糕情境評分\n （此題扣分請務必確認影片能拍到證據）",
    "content": ""
  },
  {
    "id": "cbed230f-8427-4fe7-80cd-50c278450de2",
    "class1": "連鎖甜點店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "商品基本服務",
    "type": "fraction",
    "topic": "當店內有提供試吃時，商品能使用油力士紙托盛裝，並由服務人員提供給客戶。\n （有使用油力士紙呈上即給分，現場若無試吃給N/A，不需特別提問避免曝光）\n 當店內有提供試吃時，是由服務人員拿取提供給客戶試吃。試吃品依商品現況執行試吃",
    "content": ""
  },
  {
    "id": "4e16f283-2867-42cc-8fed-9a9cffb2f8ab",
    "class1": "連鎖甜點店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "門市銷售力",
    "class5": "商品銷售服務",
    "type": "fraction",
    "topic": "外點人員主動詢問客戶是否需要商品或商品解釋，並探索客戶進一步業務需求。\n （如：需要幫忙嗎?今天想要吃什麼呢，都可幫您介紹，我們現在有OO新品，是季節限定喔，可以參考看看，接著詢問送禮或自用，生日或長條或切片，幾個人吃?或者加點飲料或任何可能增進額外業務收入的行為都算在內）",
    "content": ""
  },
  {
    "id": "4347eb8a-3116-4359-822a-e6804e0ae20e",
    "class1": "連鎖甜點店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "門市銷售力",
    "class5": "商品銷售服務",
    "type": "fraction",
    "topic": "外點人員能依照客戶需求推薦商品，或是在客戶猶豫不決時幫助客戶決策、提出建議。",
    "content": ""
  },
  {
    "id": "9a10f927-3278-4701-9d36-6f9e36a00a7c",
    "class1": "連鎖甜點店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "門市銷售力",
    "class5": "商品銷售服務",
    "type": "fraction",
    "topic": "當客戶詢問時，外點人員能說出產品口感或知識。",
    "content": ""
  },
  {
    "id": "cb8d6237-aaee-44ea-9d03-6d58f541e318",
    "class1": "連鎖甜點店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "門市銷售力",
    "class5": "商品銷售服務",
    "type": "fraction",
    "topic": "點餐時，人員能詢問是否有隨享卡，並能清楚說明隨享卡優惠訊息或申辦隨享卡的優惠差距。\n （如:辦卡購買寄捲方式提領生乳捲，一條現省50-80元）",
    "content": ""
  },
  {
    "id": "ef5c77f9-b616-4860-99b7-f404700d331c",
    "class1": "連鎖甜點店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "門市銷售力",
    "class5": "商品銷售服務",
    "type": "fraction",
    "topic": "內用用餐時，外點人員能主動詢問客戶商品印象。（該項目是指，當門市內業務量穩定，並且外點人員在等待時等的時機）",
    "content": ""
  },
  {
    "id": "91693894-b361-42dd-b41a-7b3b6846e3c3",
    "class1": "連鎖甜點店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "門市銷售力",
    "class5": "商品銷售服務",
    "type": "fraction",
    "topic": "對點時，人員能隨袋提供該檔期文宣、酷卡或DIY之DM，並簡略說明相關資訊。\n （有說明：這是我們檔期DM您可以參考看看等話語就算簡略說明，當天若內用則此題N/A）\n 若扣分，請拍攝提袋照片，確認內部沒有文宣資料",
    "content": ""
  },
  {
    "id": "1dcae19e-f8dc-4760-82fe-0acbc7041b4d",
    "class1": "連鎖甜點店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "門市銷售力",
    "class5": "商品銷售服務",
    "type": "fraction",
    "topic": "櫃檯人員或對點人員能主動告知 line@商圈 並邀請加入",
    "content": ""
  },
  {
    "id": "52d9cc74-20b7-465e-be43-c99ea2747f18",
    "class1": "連鎖甜點店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "門市銷售力",
    "class5": "結帳銷售服務",
    "type": "fraction",
    "topic": "結帳時，人員能推薦櫃台周邊加購商品。\n 不論內用或外帶都必須推薦才給分",
    "content": ""
  },
  {
    "id": "e3e07b08-0963-4d32-87fd-cc43a3e6b411",
    "class1": "連鎖甜點店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "門市銷售力",
    "class5": "結帳銷售服務",
    "type": "fraction",
    "topic": "櫃台結帳人員能詢問客戶是否有隨享卡，若客戶沒有，能詢問客戶是否申辦。\n (若入店期間都為同一位人員服務，給分依照20題再給一次)",
    "content": ""
  },
  {
    "id": "f22d6967-d70d-4937-8b07-2ea6f937c7cf",
    "class1": "連鎖甜點店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "門市銷售力",
    "class5": "結帳銷售服務",
    "type": "fraction",
    "topic": "結帳時能根據顧客需求包括這一次點的商品，提供額外的價值\n （例：買Ｘ歲以上的蠟燭顧客推薦親子DIY／推薦其他口味商品／推薦下季度新商品）",
    "content": ""
  },
  {
    "id": "eb959d61-d860-4567-b2fd-1afb9b59d61d",
    "class1": "連鎖甜點店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "結帳基本服務",
    "type": "fraction",
    "topic": "結帳時，人員能詢問付款方式；若客人已先行表明結帳方式(如拿出現金/信用卡/隨享卡)則可不問。",
    "content": ""
  },
  {
    "id": "7a43f306-0252-479c-8df7-bcdd2b7a5cd3",
    "class1": "連鎖甜點店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "結帳基本服務",
    "type": "fraction",
    "topic": "結帳時，人員能覆誦信用卡刷卡金額、收找零金額或隨享卡目前所剩餘額。",
    "content": ""
  },
  {
    "id": "e80f1e94-532a-490a-b9b5-26ced71a55eb",
    "class1": "連鎖甜點店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "結帳基本服務",
    "type": "fraction",
    "topic": "結帳時，人員能詢問是否需要統編。",
    "content": ""
  },
  {
    "id": "7c16971d-f7fc-4403-abac-06ad4ab10402",
    "class1": "連鎖甜點店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "結帳基本服務",
    "type": "fraction",
    "topic": "離店招呼，人員跟顧客說:謝謝，拜拜。",
    "content": ""
  },
  {
    "id": "0b4c3377-3518-4fe6-9da2-3d37f26bfe64",
    "class1": "連鎖甜點店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "門市環境",
    "type": "fraction",
    "topic": "門市環境－查檢點為如下\n －對點區不可放置私人物品。\n －結帳服務櫃台周圍不可有膠痕或便利貼。\n －店內地板環境能乾淨清潔無垃圾。\n －騎樓入口區域能乾淨清潔無垃圾。\n －周邊商品展示品整潔無污漬。\n －用餐區座椅乾淨整潔無髒污。",
    "content": ""
  },
  {
    "id": "ab188fd6-2e2c-4476-938c-a22f64e8098b",
    "class1": "連鎖甜點店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "基本服務",
    "class5": "門市環境",
    "type": "fraction",
    "topic": "廁所環境－查檢點為如下\n 廁所環境能乾淨清潔無垃圾。(針對廁所稽查，百貨點跟嘉義店沒有)\n 廁所提供衛生紙、洗手乳、擦手紙等備品充足 。(針對廁所稽查，百貨點跟嘉義店沒有)\n 垃圾桶能不超過八分滿。(針對廁所稽查，百貨點跟嘉義店沒有)",
    "content": ""
  },
  {
    "id": "4e0109c4-7ef0-483d-b659-959ea0549ddb",
    "class1": "連鎖甜點店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "加分題",
    "class5": "加分題",
    "type": "fraction",
    "topic": "外點人員當遇點餐顧客包含訪員超過三組顧客等候時，外點人員均能做好人員禮儀、銷售服務及隨享卡推薦。",
    "content": ""
  },
  {
    "id": "685fd1de-2a91-40f7-a9f7-03857c73e00d",
    "class1": "連鎖甜點店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "加分題",
    "class5": "加分題",
    "type": "fraction",
    "topic": "試吃加分題(有做到加3分，沒試吃或沒做到不加分)\n 試吃商品時，外點人員能說出試吃的正確商品全名及優惠內容。\n 例如：歡迎試吃XX生乳捲，現在使用隨享卡有寄捲活動；歡迎試吃萬里限定地瓜酥，現在兩盒優惠xxx元；歡迎試吃紫芋千層酥，現在兩盒優惠xxx元",
    "content": ""
  },
  {
    "id": "e08a5ad0-6f34-4f09-86d5-e189fd08c4d5",
    "class1": "連鎖甜點店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "額外觀察",
    "class5": "魅力品質項目",
    "type": "shortAnswer",
    "topic": "魅力品質項目",
    "content": ""
  },
  {
    "id": "624eceee-19f1-463b-a6cb-6684e7d09a7f",
    "class1": "連鎖甜點店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "額外觀察",
    "class5": "反轉品質項目",
    "type": "shortAnswer",
    "topic": "反轉品質項目",
    "content": ""
  },
  {
    "id": "32b12ef4-4df7-4dee-b150-9bc73f93704e",
    "class1": "連鎖甜點店",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "額外觀察",
    "class5": "推薦人員",
    "type": "shortAnswer",
    "topic": "稽查期間請選出一位表現最好的服務人員",
    "content": ""
  },
  {
    "id": "23abb400-d93b-4a79-b8b3-3d3cfe2580a0",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "環境",
    "class5": "環境",
    "type": "fraction",
    "topic": "展廳週圍環境",
    "content": "外圍環境（包含直立招牌旁）是否乾淨整潔、不雜亂，重點項目如下\n 1.如花圃垃圾、菸蒂、掃地用具 (掃把/拖把/水管)、大型垃圾、燒金紙爐子等 \n 3.其他雜物：如訪員無法理解為何展廳門口為何會有這樣的東西\n 4 布旗: 破舊、褪色、傾倒、三菱LOGO舊版錯誤等\n 5 天雨時，應有傘架、及品牌雨傘\n 6 業代於展廳內外抽菸(在展廳門面前皆算) (扣2分)"
  },
  {
    "id": "34115074-f5c3-4236-bbcc-9ae7288bb360",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "環境",
    "class5": "環境",
    "type": "fraction",
    "topic": "展廳外觀\n (含招牌及建築物外觀)",
    "content": "展廳外觀有「明顯」髒汙或破損，重點項目如下\n 包括落地玻璃、戶外看板/燈箱、直立/橫式招牌 (招牌夜間燈光需開啟，且招牌紅色燈光線條處，可不亮但不可斷線局部亮)"
  },
  {
    "id": "e251ab38-e819-4670-9776-76b473eb0025",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "環境",
    "class5": "環境",
    "type": "fraction",
    "topic": "展廳舒適度-雜物",
    "content": "1.快遞紙箱、零件: 常見錯誤於展間角落/值班櫃台旁\n  2.電風扇：可放置於值班櫃台內/泡茶區域內等不明顯的地方，其餘明顯地方不可以\n 3 值班櫃台: 除電腦外，其他物品不得高於櫃台 \n 4.其他雜物：如訪員無法理解為何展廳內部為何會有這樣的東西 (風水擺設皆需撤除、如捕蠅紙等)"
  },
  {
    "id": "05fd7622-3723-4c31-805f-ba3fa91ffd07",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "環境",
    "class5": "環境",
    "type": "fraction",
    "topic": "展廳舒適度\n -天花板/地面/牆壁/冷氣百葉窗",
    "content": "天花板：\n 1.燈管：燈管閃爍或訪員覺得展廳過於昏暗(若光照充足，可不開燈) \n 2.蜘蛛網：常見於地板/天花板牆角\n 3 漏水：常見於天花板冷氣/牆壁滲水 \n 4.天花板破洞、水漬、缺片等\n 地面：1 盆栽-落葉、枯葉須清除、不可有水流出、盆內不可以有垃圾。 2.垃圾 3 昆蟲屍體等\n 壁面(含冷氣百葉窗)：1.壁面髒汙或破損 2.所有油漆粉刷的牆面不可\"直接\"張貼任何海報(海報需在海報架內) \n 清潔用具(如拖把、掃把等): 1 不可置放於客戶視線內 2 工具間門須關閉"
  },
  {
    "id": "bd203560-a7e7-4150-88c5-c0c787a76387",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "環境",
    "class5": "環境",
    "type": "fraction",
    "topic": "展廳舒適度-廁所",
    "content": "1.廁所馬桶乾淨，沒有異味 \n 2 廁所衛生紙充裕，垃圾桶未超過7分滿 \n 3 小便斗不通尿液塞住 \n 4.洗手台台面及地板保持清潔. 及無水漬 \n 5 廁所排風口/風扇清潔 (不可有灰塵髒汙)\n ※若廁所有故障情況(例如：馬桶阻塞、門鎖故障、洗手乳滴漏不止…)，而據點有用A4紙在廁所門口張貼「廁所故障、暫停使用」且廁所門是關閉狀態，即不扣分 (CMC會列入硬體修繕追蹤)。"
  },
  {
    "id": "ed6182cb-3207-4738-8c71-90da78fcddec",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "環境",
    "class5": "環境",
    "type": "fraction",
    "topic": "展廳舒適度-冷氣、展廳音樂",
    "content": "1 冷氣: 不可高於27度(含)\n 2 公司規定制式音樂(無歌聲音樂) ~訪員聽不到音樂或很仔細才能聽到，視為沒播放音樂\n 註: 迎客大門叮咚: 叮咚聲有沒有響不列入計分項目，但若沒有響請訪員註記"
  },
  {
    "id": "78edb53a-478e-43b6-bff5-53e31bafbe23",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "環境",
    "class5": "環境",
    "type": "fraction",
    "topic": "展廳舒適度\n -商談桌/泡茶桌/吧檯",
    "content": "1.若無人使用，桌面需維持清潔，不可堆放雜物/垃圾/用過之茶杯等 2 桌面不有髒汙、發霉 \n 3.只要訪員無法理解為何桌面有這樣的東西(如蒼蠅拍、捕蠅紙等) ，即進行紀錄並扣分\n 4.據點電視需有素材播放(不可無畫面) \n  5.若有泡茶桌，茶具需整齊統一擺放，不可有\"明顯\"使用過卻未收妥之狀況\n 6.FMC以下兩種形式擇一：每張洽談桌須有交車桌曆且封面填寫/張貼「免費WIFI服務」相關文字、或洽談桌面須放有交車桌曆+WIFI小卡。註：匯豐北投所、東台南所、沙鹿所、中壢所、基隆所小圓桌不在此限，但須於長桌及交車室內放置交車桌曆。\n SYM以下兩種形式擇一：每張洽談桌須有交車桌曆且封面填寫/張貼「免費WIFI帳號密碼」、或洽談桌面須放有交車桌曆+WIFI小卡。註：順益濱江所、南屯所、內湖所不在此限，但須於長桌及交車室內放置交車桌曆。\n 7. 若有紅色洽談椅，不可褪色未噴漆，請拍照"
  },
  {
    "id": "bdbe008e-02ec-4200-a8a0-7087a2eced7c",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "環境",
    "class5": "環境",
    "type": "fraction",
    "topic": "停車位保留",
    "content": "若展廳前有設置停車區，最少須保留1個給客戶停放(2分)\n 註: 若無停車位，據點有張貼[代客泊車]POP且訪員停車於據點門口(須在停車位內，若無停車位須先停門口進入展間詢問)，業代1分鐘內有出迎並引導/代為停車，則本題給分。"
  },
  {
    "id": "4beac487-9f1c-4282-ba70-25dbeb4d276a",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "接待",
    "class5": "接待",
    "type": "fraction",
    "topic": "積極引導\n （客戶進展廳時）",
    "content": "業代坐在值班台，看到訪員需喊您好(3分)歡迎光臨(3分)，從椅子上起身、趨前去迎接訪員(3分)，欠身微笑致意(雙肩需有彎下)3分\n 註: 若值班人員已在接客戶，無他人代替值班，應向目前已接待客戶致歉、對之後進門的客戶進行迎賓：分數同上，\n  引導先入坐、致歉請稍後，協請其它人服務！\n 註: 若進入展廳30秒內無業代招呼，本大項0分"
  },
  {
    "id": "16a0ec5b-7ac2-4fa6-8e64-f6535d16988a",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "接待",
    "class5": "接待",
    "type": "fraction",
    "topic": "接待\n （客戶進展廳後）",
    "content": "1.引導客戶前往洽談桌就坐（介紹車輛前或後引導前往商談桌都可以，若現場洽談桌坐滿，則此項不扣分\n 2.引導就座後第一時間需詢問/提供客戶飲料 (業代須詢問客戶要喝什麼飲料、冷飲或熱飲)。※疫情三級警戒期間不在此限 \n 3.接待業代需遞送名片並簡要自我介紹 \n 4.不可稱呼客戶大哥、大姐、老闆 (部分客戶對裝熟不舒服) \n 5 使用待客用具-品牌杯、墊、白色托盤及品牌點心 (註：匯豐可使用白色或綠色托盤、順益可使用白色或白底印花托盤)\n 6 品牌點心需符合賞味期到期日前三個月 (如有效期限12/31、賞味期至9/30)，請神秘客協助帶回後拍照、以資證明\n 7 業代於展廳內聚集喧嘩、喝酒、打牌、看電視\n 8.接待訪員時接聽電話須先向客戶致歉，並不得超過3分鐘或於頻繁\n 9.業代若有不禮貌或不友善之態度\n 10 客戶貴姓經糾正一直講錯3次(含)以上\n 11.惡意話術(扣2分)，惡意話術包含(1)惡性砍價(2)流言中傷(3)車種攻擊 (扣2分)"
  },
  {
    "id": "9b6ab715-7b05-4701-bc32-1e262761f012",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "接待",
    "class5": "接待",
    "type": "fraction",
    "topic": "服裝儀容",
    "content": "營業所內同仁(包括所長、值班/非值班業代..等)，下列錯誤1項扣1分\n 1 共同: 未穿著中華三菱正式制服、未配戴金屬名牌(有穿外套則在外套,未穿外套則在襯衫胸前)、指甲未修剪、鞋款不正確\n 2 男性: 常見錯誤-襯衫未保持清潔(如領口袖口髒污)、襯衫第一扣釦子未扣/捲袖、無領帶、踝襪、腰間鑰匙串/眼鏡盒、身上煙味等\n 3 女性: 須穿著2022年新款制服。常見錯誤-值班人員需配戴絲巾、襯衫外露於背心外、捲袖。"
  },
  {
    "id": "33659812-a3ef-4497-bf56-2020f9acfec6",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "探詢需求",
    "class5": "探詢需求",
    "type": "fraction",
    "topic": "車輛資訊蒐集",
    "content": "(以下問法僅供參考，不需照順序，銷售顧問口語順暢即可)\n 1 車型: 如請問今天來看哪部車？ \n 2 用車者: 如請問車子是您在開比較多嗎？ 還是家人偶爾也會駕駛？\n 3 用途: 如請問這部車主要是上下通勤還是外出旅遊？ \n 4 重視點: 如對於車子您有沒有特別重視哪方面的功能？一般車子的重視要點多是安全、省油、操控性....等，您有特別重視哪一個項目？\n 5 競品: 如請問您有去其他本牌或是他牌的展間看過車了嗎？有在比較哪些車款嗎?"
  },
  {
    "id": "9e1d8ba1-8ded-46c8-bd90-daabc1b6ff65",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "探詢需求",
    "class5": "探詢需求",
    "type": "fraction",
    "topic": "客戶資訊蒐集",
    "content": "欠一項扣一分\n 1 工作: 如請問您目前從事哪類型的工作呢？我想知道之後大概什麼時間聯絡你方便呢。\n 2 現用車款: 如您是第一次購車嗎？(您目前用的車是哪一款呢？)未來如果有需要幫忙報廢或轉售，我都可以協助喔。\n 3 預算: 如請問您有大概預算範圍嗎？這次購車是想要現金付款還是分期貸款呢？\n 4 配備需求: 您有想要加裝哪些額外的配備嗎？我都可以協助您安裝喔！(可利用新聞案例分享某些配備的重要性，如行車紀錄器)\n 5 決策者: 如這台車您家人會一起開嗎？ 要一起試乘看看? 有需要請您家的財務大臣一起來看車嗎？\n 6 產品資訊來源: 如您從哪裡接觸到本品(車型)的相關資訊?"
  },
  {
    "id": "48043506-1e91-4953-b687-ee88921d189a",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "探詢需求",
    "class5": "探詢需求",
    "type": "fraction",
    "topic": "紀錄需求",
    "content": "銷售顧問在詢問車輛/客戶資訊時或之後，需把客戶之回覆紀錄下來，且使用制式表單 - FMC客戶需求服務記錄表/SYM貴賓資料卡\n ，不可使用筆記本、廢紙、客戶名片登記，請客戶自行填寫亦可(2分)"
  },
  {
    "id": "efc06584-ad46-4075-8daa-e3d620ae91a9",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "車輛介紹",
    "type": "fraction",
    "topic": "提供型錄",
    "content": "確認客戶所要看的車型後，業代需提供型錄或DM給客戶(1分)"
  },
  {
    "id": "b664a29d-c1bd-4e34-9bce-96912c643542",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "車輛介紹",
    "type": "fraction",
    "topic": "使用IPAD車輛介紹",
    "content": "業代在車輛介紹若有使用IPAD (2分)"
  },
  {
    "id": "458b19af-6fe8-4ffe-a1c6-f745f7ea520c",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "NPZ(COLT PLUS)",
    "type": "fraction",
    "topic": "NPZ(COLT PLUS)－\n 1.5L MIVEC引擎",
    "content": "F(特色):(2分):鋁鑄缸體帶來輕量化優勢，操控性與油耗都提升\n A(優勢):(1分):正時鏈條更耐用，節省保養費用\n B(效益):四缸獨立點火，燃油使用更有效率 。\n E(實證):享樂型油耗15.9km/L；輕鬆型油耗16.1km/L(1分)。"
  },
  {
    "id": "9c3d85b7-5d96-40c1-94ca-57b17a5cc6b7",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "NPZ(COLT PLUS)",
    "type": "fraction",
    "topic": "NPZ(COLT PLUS)－\n 7速S/M CVT無段變速系統",
    "content": "F(特色)(1分):CVT系統使用鋼帶作為變速箱動力傳輸工具，達到無段變速的效果，取代傳統自排變速箱的齒輪換檔方式。\n A(優勢)(1分 ):使用CVT的COLT PLUS擁有順暢的動力輸出以及平順無頓挫的駕駛感受。\n B(效益):CVT系統因為沒有傳統自排動力損失，所以會更加的省油，而且因為是無段變速，所以不像傳統自排變速箱齒輪切換的時候會產生不舒服的頓挫感(1分)\n E(實證):藉由手自排切換系統，讓超車、爬山時的動力更加充沛與順暢。(1分)_"
  },
  {
    "id": "1132a630-1c0c-4ddf-a441-112843d0ef19",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "NPZ(COLT PLUS)",
    "type": "fraction",
    "topic": "NPZ(COLT PLUS)－\n LED定位燈(輕鬆型)",
    "content": "F(特色):(1分):提供良好辨識性，不僅造型美觀也提升安全性。\n A(優勢):(1分):國產同級競品中，COLT PLUS率先將定位燈與大燈整合，整體視覺造型更美觀，也提升安全性。\n B(效益):(1分):不論是白天或黑夜，LED定位燈提供良好辨識性，增加行車安全。\n E(實證):增加被其他駕駛看到的機會，降低碰撞的可能性。"
  },
  {
    "id": "124679f8-75a7-42e2-8c7d-d6afdf98386f",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "NPZ(COLT PLUS)",
    "type": "fraction",
    "topic": "NPZ(COLT PLUS)－\n HAS陡坡起步輔助系統",
    "content": "F(特色):車輛停止於角度2.8度以上的斜坡，即會作動。\n A(優勢):上坡起步時即使放開煞車踏板，車輛仍可保有原煞車力道約2秒而不會向後滑。(2)\n B(效益):當駕駛將腳從煞車踏板移到油門踏板時，本功能會將煞車系統中的踏板壓力維持數秒，不會後滑。\n E(實證):進出賣場、百貨公司，常常會在斜坡上走走停停，HSA可防止車輛倒退，減緩駕駛壓力。(2)"
  },
  {
    "id": "fa6e5874-d90c-4037-932b-6b0472736fbb",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "NPZ(COLT PLUS)",
    "type": "fraction",
    "topic": "NPZ(COLT PLUS)－\n 全車系標配恆溫空調",
    "content": "F(特色)(2分):依照車主想要的車室溫度，自動調節風量大小，維持車室舒適溫度。\n A(優勢)(1分):國產同級車中，YARIS、VIOS都還沒有將這項配備列為全車系標配呢！\n B(效益)(1分):不論是豔陽高照還是午後陣雨，恆溫空調都能主動調整風量，維持舒適車室溫度，開起車來更舒適！\n E(實證):不論是豔陽高照還是午後陣雨，恆溫空調都能主動調整風量，維持舒適車室溫度，開起車來更舒適！"
  },
  {
    "id": "1b9eccc0-5b6f-4da5-9ba3-5342336c9162",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "NPZ(COLT PLUS)",
    "type": "fraction",
    "topic": "NPZ(COLT PLUS)－\n NPZ相較 FIT有什麼優點?",
    "content": "<記憶重點：高價才有安全(2分)、空間不夠靈活(2分)、購入成本遠高於油耗優勢(2分)>\n ◎FIT主要強調的訴求在於『安全』、『空間』與『高CP值』。但實際這些特點在與COLT PLUS比較起來，反而COLT PLUS才滿足您最實際的需求。\n ◎比方說FIT強調的安全性是架構在●HONDA Sensing防護系統和六氣囊上，但這些配備必須花費70萬以上才能全部擁有，相信對於一般小家庭來說是筆額外的負擔，而且我們想要購買小車的車主，大部分開車都比較守規矩，且COLT PLUS 安全升級，標配ASC車身動態穩定系統、TCL循跡防滑控制系統、HSA陡坡起步輔助系統、ESS智慧型緊急煞車輔助系統、LED日間行車燈、光感應頭尾燈，更只要親民的53.9萬起即可擁有。\n ◎此外您會比較FIT應該很重視『空間』需求，FIT空間上的表現在同級小車中是很不錯的，但相較COLT PLUS還是有一段差距： ●COLT PLUS最引以自豪的空間變化性，如高低可調行李箱隔板/ONE-TOUCH快倒後座椅，這些FIT通通沒有，而且FIT加大後座空間卻犧牲了行李箱的大小，容量只有363L比我們COLT PLUS的620L，實在是少非常多。\n ◎COLT PLUS的油耗早有好口碑，雖然● FIT油耗17.9km/L帳面上是比COLTPLUS的油耗佳，若以頂級輕鬆型油耗表現16.1km/L計算，以目前油價來看，如果每年開3萬公里計算，就算開個20年也賺不回來，更別說多數人一年只開1萬多公里，這樣您還覺得FIT的油耗有幫您省到錢嗎?"
  },
  {
    "id": "0a374c52-0243-4c5a-ba25-09f51c380286",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "SR(GRAND LANCER)",
    "type": "fraction",
    "topic": "SR(GRAND LANCER)－\n 4J10引擎",
    "content": "F(特色)：4J10引擎搭載新MIVEC系統，馬力140ps，扭力17.9kgm，油耗與動力完美平衡。(1)\n A(優勢)：頂規車型(旗艦型)15.1km/L油耗，勝過ALTIS頂規 14.9km/L。\n B(利益)：正時鏈條設計，降低養護維修成本。同步控制汽門正時、汽門揚程，動力輸出靈敏。(1)\n E(實證)：能源局認證，16吋車型平均油耗15.5km/L。 18吋車型平均油耗15.1km/L。(1)"
  },
  {
    "id": "2293c4c1-8e78-46e7-a612-c813beee4c60",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "SR(GRAND LANCER)",
    "type": "fraction",
    "topic": "SR(GRAND LANCER)－\n 全新世代變速箱",
    "content": "F(特色)：以全新世代的CVT8變速箱(1)，結合INVECS-III控制系統，轉速穩定，提升油耗表現。\n A(優勢)：相較SENTRA的輕小型車用的CVT7變速箱，CVT8變速箱是高動力輸出的中大型車專用，機構品質與耐用性更高。\n B(利益)：減少換檔頓挫(1)，隨時調整變速齒比，自主學習駕駛習慣，做出最佳化調整。\n E(實證)：JATCO為世界前三大CVT變速箱供應商(1)，合作車廠包含NISSAN、MAZDA、SUBARU、BMW、LANCER新舊款皆使用JATCO變速箱。"
  },
  {
    "id": "a488a0c4-ca32-48ea-b1a2-b1e4f3a8d310",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "SR(GRAND LANCER)",
    "type": "fraction",
    "topic": "SR(GRAND LANCER)－\n DSP正座安全帶雙預縮",
    "content": "F(特色)：意外發生時，胸腔及腹部安全帶預縮，將人確實固定於座位(1)\n A(優勢)：LANCER增加腹部預縮裝置，其他國產車僅在安全帶胸腔端配置預縮(1)\n B(效益)：意外發生時，安全帶確實固定，才能發揮氣囊最大效用\n E(實證)：車子準備要撞擊的那一瞬間，安全帶會先縮起來把乘客拉住，避免撞擊的時候人往前太多被壓在氣囊上"
  },
  {
    "id": "0672ebd6-c025-498e-8653-db81cc427d23",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "SR(GRAND LANCER)",
    "type": "fraction",
    "topic": "SR(GRAND LANCER)－\n 智慧型緊急煞車警示系統(ESS)",
    "content": "F(特色)：智慧型緊急煞車警示系統(ESS)─在高速行駛緊急狀況下急踩煞車時，系統會〝自動且立即地〞產生警示信號，讓後方駕駛提升注意力(1)\n B(利益)：高速行駛緊急煞車時，此系統可自動警示，即時提醒後方車輛避免追撞。\n E(實證)：根據M-Benz研究發現:\n 只要提早0.5秒警示時間，就能避免60%的追撞事故、只要提早1.5秒警示時間，就能避免90%的追撞事故(1)"
  },
  {
    "id": "cb3642f2-65e3-4882-b7c7-6ea5799f3489",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "SR(GRAND LANCER)",
    "type": "fraction",
    "topic": "SR(GRAND LANCER)－\n 6SRS環艙氣囊",
    "content": "F(特色)：全車系6氣囊可提供乘客更完整的安全防護，輔以安全帶提前預縮裝置，共同減少身體位移(1)\n A(優勢)：全車系標配，行車更安全。\n B(利益)：LANCER的主被動安全防護完整，車主和家人的安全都很重要(1)\n E(實證)：台灣近年公布ARTC撞擊測試影片的唯一廠商，網路上諸多撞擊影片說明LANCER車體安全性。"
  },
  {
    "id": "5ecee983-8a83-42d9-80df-1959476fca55",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "SR(GRAND LANCER)",
    "type": "fraction",
    "topic": "SR(GRAND LANCER)－\n LED頭燈",
    "content": "\"F(特色)：多為高階車型專屬的LED頭燈，含光感應自動啟閉及自動水平調整頭燈設計(1)\n A(優勢)：SENTRA 85萬車型配備之LED頭燈仍為手動水平調整(1)\n B(利益)：頭燈自動水平調整相較於手動操作，使駕駛可以更專注於路況(1)\n E(實證)：自動依車輛載重及路況調整，可減少對其他用路人的影響"
  },
  {
    "id": "53a1d272-ac6f-4278-be9e-4f12361da4d6",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "SR(GRAND LANCER)",
    "type": "fraction",
    "topic": "SR(GRAND LANCER)－\n MDSS運動化懸吊系統",
    "content": "F(特色)：後獨立多連桿懸吊與防傾桿(1)，搭載高強度橫樑\n A(優勢)：SENTRA搭載非獨立懸吊，但高階的CAMRY與XTRAIL均搭載獨立懸吊(1)\n B(利益)：獨立懸吊減少兩輪遇不同狀況路面時的互相干擾，例：一邊為碎石路一邊是平整柏油(1)"
  },
  {
    "id": "48fd29fe-9ddf-46ca-b443-73ce7a465d0e",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "SR(GRAND LANCER)",
    "type": "fraction",
    "topic": "SR(GRAND LANCER)－\n ENTRA對戰重點",
    "content": "<記憶重點：旗艦版一點都不旗艦，尊爵版一點都不尊爵>\n ●全新SENTRA旗艦版售價75.5萬，但這樣的價格卻還在用16吋鋼圈、手動收摺後視鏡與非恆溫空調(3)，這樣是旗艦版還是陽春版?反觀SR價格相近的魅力型車款，不僅配備齊全，價格也只要71.2萬。\n ●尊爵版與尊爵智駕版還在用手動水平調整的LED頭燈與7吋的數位儀錶板，但實際只是加大的中央資訊幕吧(2)，完全沒有給車主尊爵感受，SR的LED頭燈不僅為自動水平調整，數位儀錶板也為比ALTIS與SENTRA都大的10.25吋(2)。"
  },
  {
    "id": "68e691ed-6227-40a0-a8b7-e77d55e52966",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "AS VAN(ZINGER)",
    "type": "fraction",
    "topic": "AS VAN(ZINGER)－\n 低轉速高扭力引擎",
    "content": "F（特色）2.4L低轉速高扭力引擎，2300rpm發揮21.0kg/m大扭力(1)。\n B（利益）低轉速高扭力的，起步、載重更加輕鬆。(1)\n E（實證）請看爬坡力PK影片。"
  },
  {
    "id": "a3e9d0cb-4678-495b-9056-51edcdf6a202",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "AS VAN(ZINGER)",
    "type": "fraction",
    "topic": "AS VAN(ZINGER)－\n 5AT變速箱",
    "content": "F（特色）採用5速自排變速箱。\n B（利益）5AT檔位齒比更加綿密，大幅降低換檔頓挫感，並擁有更佳的加速性及省油性(2)。"
  },
  {
    "id": "8cf166e4-b675-4a8c-9aea-ef0a077b63db",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "AS VAN(ZINGER)",
    "type": "fraction",
    "topic": "AS VAN(ZINGER)－\n ASC車身動態穩定控制系統+TCL循跡防滑控制系統",
    "content": "F(特色)：ASC車身動態穩定控制系統+TCL循跡防滑控制系統─能主動保持車輛行進的穩定性，當車輛有打滑失控風險時，適時降低引擎動力輸出並輔以煞車作動，以降低失控之可能性 !(1)\n B(利益)：Zinger因車高關係重心較高，打滑或轉向過程較容易失控翻覆，ASC+TCL可維持車身穩定，降低意外發生之風險。(1)\n E(實證)：美國高速公路安全局研究: ASC可以有效降低高底盤車約6成的失控率及8成的翻車率。(1)"
  },
  {
    "id": "4ed19e54-f1a7-4ce4-8f4d-f5fedc5fb513",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "AS VAN(ZINGER)",
    "type": "fraction",
    "topic": "AS VAN(ZINGER)－\n EBD電子煞車力分配系統",
    "content": "F(特色) ： 系統會自動將煞車力道做適當分配(2)，以獲得更平衡且更接近理想化的煞車力道分佈(1)"
  },
  {
    "id": "ae385b3b-39ab-49b7-9695-47ed3854d61c",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "AS VAN(ZINGER)",
    "type": "fraction",
    "topic": "AS VAN(ZINGER)－\n RAB預警剎車系統",
    "content": "F(特色)：當緊急狀況發生時，駕駛會快速釋放油門踏板，系統監測油門踏板訊號達到快速釋放的門檻時，會觸發RAB功能，對油壓迴路進行預先增壓，縮短煞車來令片間隙，達到縮短煞車距離的效果。(2)\n B(利益)： 預判駕駛將進行煞車動作，系統先進行煞車準備，以利在緊急狀況下，能縮短煞車距離。(1)"
  },
  {
    "id": "e6181cbe-0efa-47a3-8426-1d20b5b45991",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "AS VAN(ZINGER)",
    "type": "fraction",
    "topic": "AS VAN(ZINGER)－\n ARP主動式防止傾覆系統",
    "content": "F(特色)：系統監測車輛速度、側向加速度、方向盤轉動速度等訊號，當判斷車輛處於傾向側翻狀態時，ARP功能會介入，藉由降低引擎扭力輸出及對車輪施加煞車力控制，來達到降低車輛側翻風險(2)\n B(利益)：防止車輛在高速駕駛狀態下的側翻現象。特別是針對有較高側翻風險的高重心廂型車等車輛，可具有顯著的保護效果(1)"
  },
  {
    "id": "8e39fbf4-b8bf-4aaa-a197-642ff1c8aaff",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "AS VAN(ZINGER)",
    "type": "fraction",
    "topic": "AS VAN(ZINGER)－\n BOS煞車優先系統",
    "content": "F(特色) ：當駕駛人不小心同時踩下油門和煞車時，系統會忽略油門的訊號，只進行煞車動作(1)，提高安全性。\n B(效益)：行駛中遇到緊急情形，客戶可能在油門轉踩剎車時，慌亂中不小心同時踩到油門和煞車(尤其腳大的人)，此設計可確保優先進行剎車避免危險。(1)"
  },
  {
    "id": "62c2dfb6-efdf-44f3-83ae-e5dadea63bc6",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "AS VAN(ZINGER)",
    "type": "fraction",
    "topic": "AS VAN(ZINGER)－\n 【空間大】",
    "content": "F（特色）'空間超大及多變的車室空間變化，可滿足您全面載物需求(1)。\n B（利益）\n 1.(前段移過來)不論載人的乘坐空間和載物行李廂空間都超寬敞，一趟就載完不必分兩趟(1) \n 2.大空間搭配靈活的座椅空間變化，不僅平日載貨超方便，假日全家出遊時，可以將座椅全部打平，變成一張大車床(1)。"
  },
  {
    "id": "68fa4b16-7ac6-4151-b031-f4dbb5ec5172",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "AS VAN(ZINGER)",
    "type": "fraction",
    "topic": "AS VAN(ZINGER)－\n 影音升級",
    "content": "F(特色) 8吋觸控影音系統，可以聲控及包含手機無線鏡射(for Android)(1)\n B（利益）1.螢幕變大觀看更輕鬆 2.聲控功能操作更方便(1) 3.與儀表連動駕駛更安全(1) 4.鏡射功能方便於大螢幕使用手機功能(1)"
  },
  {
    "id": "c6720e3c-01ae-4147-b2d5-f14d34cd76c8",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "AS VAN(ZINGER)",
    "type": "fraction",
    "topic": "AS PU(ZINGER PICKUP)－\n ASC/TCL",
    "content": "F(特色)：ASC車身動態穩定控制系統+TCL巡跡防滑控制系統─能主動保持車輛行進的穩定性，當車輛有打滑失控風險時， 適時降低引擎動力輸出並輔以煞車作動，以降低失控之可能性 !(1)\n B (效益)：Zinger因車高、重心較高，打滑或轉向過程較容易失控翻覆，ASC+TCL可維持車身穩定，降低意外發生之風險。(1)\n E(證據)：美國高速公路安全局研究: ASC可以有效降低SUV約 6成的失控率及8成的翻車率(1)，並規定所有新車必須配備此安全系統才能在美國市場銷售。"
  },
  {
    "id": "2443b8d8-90f0-4439-aba5-3200b64eca0b",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "AS VAN(ZINGER)",
    "type": "fraction",
    "topic": "AS PU(ZINGER PICKUP)－\n HSA",
    "content": "F(特色) ：陡坡起步輔助系統(HSA)─車輛停止於斜坡(角度2.8度以上)，HSA即會作動(1)。上坡起步時即使放開煞車踏板，車輛仍可保有原煞車力道約2秒而不會向後滑(1)。\n B(利益) ：進出停車場，常常會在斜坡上走走停停，HSA可防止車輛倒退，減緩駕駛壓力(1)；也可避免後滑時，急著大踩油門，衝撞到前車。"
  },
  {
    "id": "2322a88b-a4b5-4cb0-88d2-59a49339b23f",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "AS VAN(ZINGER)",
    "type": "fraction",
    "topic": "AS PU(ZINGER PICKUP)－\n BSW盲區偵測警示系統",
    "content": "F(特色)：1.車輛行駛速度達10km/h以上，主動監控車輛側後方視線盲區，當左右側後方7m內出現來車，若此時未打方向燈，則僅有警告燈號亮起，若有打方向燈則會同時有警告燈號與警告音(1)。\n 2.倒車時，自動偵測車輛左右側後方狀況，若左右側後方25m內有來車碰撞疑慮，警示燈閃燈及嗶聲警示音提醒駕駛，以減少因視線盲區造成倒車碰撞意外。(1)\n \n B (效益)：更正確掌握周遭車輛動向，避免誤判或疏忽而發生意外(1)"
  },
  {
    "id": "02f5a497-633a-48e2-a542-74b899bbf995",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "AS VAN(ZINGER)",
    "type": "fraction",
    "topic": "AS PU(ZINGER PICKUP)－\n RAB預警剎車系統",
    "content": "F(特色)：當緊急狀況發生時，駕駛會快速釋放油門踏板，系統監測油門踏板訊號達到快速釋放的門檻時，會觸發RAB功能，對油壓迴路進行預先增壓，縮短煞車來令片間隙，達到縮短煞車距離的效果。(2)\n B(利益)： 預判駕駛將進行煞車動作，系統先進行煞車準備，以利在緊急狀況下，能縮短煞車距離。(1)"
  },
  {
    "id": "d691d4a4-b902-4eeb-a210-c731d01c79ea",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "AS VAN(ZINGER)",
    "type": "fraction",
    "topic": "AS PU(ZINGER PICKUP)－\n 【一體式鈑金貨台】",
    "content": "F（特點）'貨台最大寬度1462mm、最大長度1703mm (1) 滿足車主輕載需求。\n B（利益）1.一體式鈑金貨台兼具休閒、美觀以及輕載或需求，呈現與一般商用車不同特色(1) \n  2.貨台空間大小媲美百萬等級運動皮卡車款。\n E（證據）請進行實車體驗"
  },
  {
    "id": "a4a82d1d-b0d6-4337-8693-02dddfbaf41f",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "AS VAN(ZINGER)",
    "type": "fraction",
    "topic": "AS PU(ZINGER PICKUP)－\n 【三開式木床貨台】",
    "content": "F（特點）'貨台最大寬度1747mm 、最大長度1820mm (1) 滿足車主輕載需求。\n B（利益）三開式木床貨台提供更佳的上下貨物便利性以及貨台空間與平整性，以靈活多變特色，滿足頭家需求(1) \n E（證據）請進行實車體驗"
  },
  {
    "id": "6911a313-a99c-48d0-aff9-957c6abe25fd",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "AS VAN(ZINGER)",
    "type": "fraction",
    "topic": "AS PU(ZINGER PICKUP)－\n 【省力尾門機構】",
    "content": "F（特點）'藉由省力機構設計，使尾門操作力僅6公斤(2)。\n B（利益）媲美百萬等級運動貨卡車型尾門操作力(1)。\n E ( 佐證 ) 請進行實車體驗。"
  },
  {
    "id": "f913ce67-602b-43a1-ac26-75b4ef996ac5",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "AS VAN(ZINGER)",
    "type": "fraction",
    "topic": "AS PU(ZINGER PICKUP)－\n 【下開式省力尾門】",
    "content": "F（特點）下開式省力尾門深度495mm (1) 可以提供200公斤承重能力(1)\n B（利益） 下開式尾門具有200公斤承載能力(1)，不論是輕休閒或是做為貨物中繼點都很方便使用\n E（證據） 請進行實車體驗"
  },
  {
    "id": "f6634bde-953d-406c-b478-7202ae3f27fa",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "AS VAN(ZINGER)",
    "type": "fraction",
    "topic": "AS PU(ZINGER PICKUP)－\n ADAS智能安全輔助系統",
    "content": "F(特色)：使用人工智慧避免駕駛人可能會發生分心、疲勞狀態，提升駕駛人危機意識。(1)滿足大部份商車車主需求，提早預知路況並及早因應\n B (利益)：駕駛人提早採取因應措施，避免交通意外事故發生，適時保障生命財產安全(1)\n E(實證)：美國國家公路交通安全管理局關於分心駕駛報告，「每年在美國發生約 600 萬起車輛交通事故之中，大概有 90％ 的事件是可預防的，這表示大部分事故是由人為失誤造成的。」"
  },
  {
    "id": "d924562b-a32c-475a-a8fa-98db2a8f5c1e",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "AS VAN(ZINGER)",
    "type": "fraction",
    "topic": "AS PU(ZINGER PICKUP)－\n 5AT變速箱",
    "content": "F（特點）5前進檔位設定。\n A（優勢）優於傳統商車或部分市售車種仍在使用的4AT變速箱(1)\n B（利益）相較傳統4AT檔位齒比更加綿密，大幅降低換檔頓挫感，並擁有更佳的加速性及省油性(1)。\n E（證據） 起步/加速/超車都輕鬆。"
  },
  {
    "id": "eefe1c4b-dd07-43e5-839f-1c69986224b4",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "NS(ECLIPSE CROSS)",
    "type": "fraction",
    "topic": "NS(ECLIPSE CROSS)－\n 1.5T缸內直噴渦輪引擎",
    "content": "F(特色)：\n 1. 1.5L 缸內直噴渦輪增壓引擎 [4B40]，最大馬力163/5500(ps/rpm)，最大扭力25.49/2400-4500(kg‧m/rpm)(1)\n 2.採用與排氣歧管整合之一體式汽缸頭與配備電子式洩壓閥的渦輪增壓器，縮短排氣歧管長度，搭配雙凸輪軸MIVEC 進氣/排氣 連續可變汽門正時系統(1)，實現引擎快速反應性能，立即回應駕駛者的動力需求。 \n 3.除常見的引擎渦輪機油冷卻技術外，並特別設計渦輪殼體的冷卻水道，維持渦輪於適宜的工作溫度。\n 4.鈉材質排氣閥：採用與LANCER EVOLUTION FINAL EDITION相同設計的鈉材質的中空排氣閥，透過鈉材質優異的散熱性能，提高引擎抗爆震性能，並有助於強化引擎動力輸出\n 5.符合最新Euro-6d temp 排放法規。\n \n A(優勢)：新down-sizing渦輪科技\n 1.超越2.0L N/A引擎的充沛扭力表現：SUV車身較重，扭力表現格外重要。NS於低轉速輸出最大扭力 25.49 /2400-4500(kg‧m/rpm)，優於CX-5：21.7 /4,000(kg‧m/rpm)、RAV 4：20.7 /4,400~4,900(kg‧m/rpm)(1)。\n \n B(利益)：\n 1.充沛動力：自2,400rpm即可爆發25.49kg-m之峰值扭力，2,400~4,500rpm形成扭力高原，相較傳統自然進氣引擎最大扭力僅出現在特定引擎轉速(ex CX-5 是4,000 rpm)，渦輪增加可提供源源不絕的動力感受。\n 2.相較同級常見2.0L自然進氣引擎，具有稅金優勢。\n 3.汽缸頭一體式排氣岐管，縮短歧管長度，消除渦輪遲滯現象(1)，加速反應快，動力隨傳隨到!\n 4.因渦輪溫度控制適宜，可延長引擎壽命，保養亦只需要採用與一般自然進氣引擎相同機油與機油濾蕊即可(1)(即可共用OUTLANDER車型機油/機油濾蕊)。\n 5.全球溫室效應日益明顯，符合最新EURO-6d temp排汙標準的NS，為環保一同努力。\n \n E(實證)：導引客戶看車規表，或競品比較表。"
  },
  {
    "id": "bb52d9f5-58b7-4283-8d94-27105ca5cca0",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "NS(ECLIPSE CROSS)",
    "type": "fraction",
    "topic": "NS(ECLIPSE CROSS)－\n S-AWC超能全時四輪控制系統",
    "content": "F(特色)：\n 1.ECLIPSE CROSS搭載源自Pajero 與Lancer Evolution兩大車種賽事經驗淬煉出的S-AWC超能全時四輪控制系統，將車輛動態控制系統集成於一體，整合4WD / AYC / ASC / ABS 等四大系統(1)\n (1)四輪驅動系統：分配車輛前後輪動力輸出(1)\n (2)AYC主動式偏擺控制系統：安全性確保之外，更以駕駛人的操控指令為優先目標，以施加煞車力的方式主動分配左右輪間的驅動/制動力(1)，降低因推頭而產生之外拋狀況，進而提供優異過彎性能與行駛穩定性\n 2.S-AWC模式選擇：\n (1)NORMAL一般：適合各種路面 (1)\n (2)SNOW雪地：增強濕滑路面的操控穩定性(1)\n (3)GRAVEL砂石：適合於崎嶇道路駕駛與脫困(1)\n \n A(優勢)：相較無法主動有效分配驅動力的一般4WD車輛，車輛轉向反應更靈活與線性，增加駕駛樂趣與安全性(1)。\n \n B(利益)：\n 1.S-AWC ECU(電腦控制單元)隨時偵測車輛動態，並以駕駛人的操控指令為優先目標，決定前後輪間的扭力分配與各輪煞車力，提升車輛的操控性能。也提升車輛遇到危險情境時，安全脫困的機會！\n 2.在積水路面，有了S-AWC系統，抓地力與操控性能提升，對安全更有保障。\n \n E(實證)：請看影片對S-AWC效果之展演。 https://studio.youtube.com/video/haKlLOGtbPY/edit"
  },
  {
    "id": "12b82bf3-14b8-4255-9739-34502e74a54c",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "NS(ECLIPSE CROSS)",
    "type": "fraction",
    "topic": "NS(ECLIPSE CROSS)－\n LDW車道偏移警示系統",
    "content": "F(特色)：時速超過65公里以上時(1)，可以偵測車輛是否行駛於車道線內，若在未打方向燈情況下，車輛偏離車道，則會發出警示音提醒駕駛者(1)。\n B(利益)：\n LDW車道偏移警示能及時提醒駕駛者行駛在正確路線上(1)，對於疲勞駕駛更能夠有效提醒注意危險，避免事故發生！"
  },
  {
    "id": "1209428e-6fb3-4984-b1c6-5a18f96bcc10",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "NS(ECLIPSE CROSS)",
    "type": "fraction",
    "topic": "NS(ECLIPSE CROSS)－\n 完整的主被動安全防護",
    "content": "F(特色)：三菱e-Assist導入，提供全面安全防護。包括AHB智慧遠光燈、FCM主動式智慧煞車輔助、ACC主動車距控制巡航、LDW車道偏移警示、BSW/LCA盲點偵測/車道變換輔助。\n A(優勢)：傲視同級安全規格，全車系標配ACC、FCM(1)、LDW、AHB(1)、ASC、7-SRS、HSA、BSW/LCA盲點偵測/車道變換輔助。\n E(實證)：------\n 1.相同車體結構、改款前的20年式，在EURO NCAP成人保護分數超過VOLVOS90/V90、MAZDA CX-5(1)\n 2.(承上)行人保護分數亦為第三名！成績領先MAZDA CX-5、VOLVO V90！\n 3.播放參考影片：https://youtu.be/V7n_0BAH4Qs\n 4.提供人車誌/UCAR等媒體報導：http://www.carstuff.com.tw/car-news/item/24152-volvo-s90-v90-mitsubishi-eclipse-cross-euro-ncap.html"
  },
  {
    "id": "381f6766-238d-4fe4-ad9b-fb55bd615789",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "NS(ECLIPSE CROSS)",
    "type": "fraction",
    "topic": "NS(ECLIPSE CROSS)－\n 9吋多媒體主機",
    "content": "F(特色)：\n 1.9吋大螢幕(1)。\n 2.懸浮式設計，確保駕駛人在最小視線移動範圍內，即可以掌握所需的娛樂資訊(1)。\n \n A(優勢)\n 1.9吋大螢幕，娛樂與資訊顯示效果最出色\n 2.中文化界面，便利使用(1)\n \n B(利益)：\n 資訊讀取便利，增進使用便利性與安全性。"
  },
  {
    "id": "63a6205c-085c-4078-9883-d8cae3409a0a",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "NS(ECLIPSE CROSS)",
    "type": "fraction",
    "topic": "NS(ECLIPSE CROSS)－\n HUD 全彩液晶抬頭顯示器",
    "content": "F(特色)：\n 1.位於儀表板上方，讓駕駛者以最小視線移動下輕鬆閱讀多元行車資訊。包含車速、ACC設定資訊、FCM煞車警示以及LDW警示等訊息(1)資訊。\n 2.車輛啟動、熄火都會自動升起、降下(1)。\n 3.HUD控制鍵於方向盤之左側，共有三個鍵由左而右，\n 按鈕A：調整HUD顯示亮度\n 按鈕B：開啟或關閉HUD\n 按鈕C：控制顯示資訊高低\n \n B(利益)：駕駛者視線維持在道路上也可以取得即時的行車資訊，提升駕駛安全性(1)！"
  },
  {
    "id": "df40847c-fe52-4feb-87d2-c7e987535795",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "NS(ECLIPSE CROSS)",
    "type": "fraction",
    "topic": "NS(ECLIPSE CROSS)－\n 同樣是進口SUV，MAZDA CX-5 形象好，比較吸引人?",
    "content": "<記憶重點：S-AWC優勢(1)、動力優勢(1)、舒適便利配備優勢>\n 1.●具備S-AWC，是Eclipse Cross相較CX-5最大的優勢，可確保在下雨等路面濕滑情形下的安全性。\n 2.●相較價位接近採用2.0引擎的CX-5 2.0，NS的扭力高出 3.8 kg-m，(NS:25.49 kg-m/2400-4500 rpm ; CX-5 2.0 21.7kg-m/4000 rpm)，且在低轉速就輸出，帶動SUV更順暢有力。 \n 3.●駕駛舒適性方面，大型螢幕更適合影音娛樂使用(主機尺寸 EC 9吋；CX-5 8吋)，雙電動/電熱椅讓正副駕駛用車一樣舒適 (CX-5具雙電動椅，但均無加熱功能)！"
  },
  {
    "id": "1c836b54-5bfa-4552-bbd6-03964608faa2",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "RE(OUTLANDER)",
    "type": "fraction",
    "topic": "RE(OUTLANDER)－\n FCM主動式智慧煞車輔助系統+行人偵測功能",
    "content": "F(特色)：\n 1. 由前擋玻璃上方的鏡頭+雷射模組進行感測，遇碰撞風險時發出警示聲提醒，距離過短時自動輔助煞車 (相對時速低於40km/h時有相當機會可幫助完全避免碰撞)\n 2. 具備行人偵測功能，大幅降低與行人發生碰撞事故之機率與嚴重性。\n \n A(優勢)：\n Outlander可在時速5-140km間發揮提醒、保護效果。95.5萬起即可擁有先進的主動安全配備(1)\n \n B(效益)：\n 疲勞駕駛或使用電子產品等分心的狀況，提供安全守護。行車視線不良時，可能無法正確察覺前車已經減速或停止。透過FCM可超越視覺，有效防止或減輕撞擊事故(1)\n \n E(佐證)：\n EURO NCAP資料顯示，90%以上的意外事故，是因為駕駛分心(ex 滑手機、撿東西)而發生(1)\n Outlander的FCM系統效果超越VOLVO XC60，並獲得EURO NCAP Advanced先進安全科技大獎。\n ● 請撥放I-PAD主要特色\"安全\" 影片~FCM。"
  },
  {
    "id": "e1647b81-a223-4511-bc4a-97279f396f24",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "RE(OUTLANDER)",
    "type": "fraction",
    "topic": "RE(OUTLANDER)－\n 主動車距控制巡航系統\n (ACC)",
    "content": "F(特色)：\n 1.前有車輛時，系統將監測前車車速變化，並對應調整自身車速，以確保安全車距(1)\n 2.可控制車速範圍廣，前車短暫停止2秒內再起步時，仍可保持自動跟隨(1)\n 3.前方道路恢復淨空後，恢復原設定之巡航速度(1)\n \n A(優勢)：\n 1.OUTLANDER ACC使用速域為10-180km/h。\n 2.與Mercedes-Benz相同採用雷達波偵測，較不易受濃霧等天候干擾。不論逆光、夜間或下雨/濃霧，性能皆穩定可靠。95.5萬起即可擁有先進的主動安全配備。\n B(效益)：\n 在頻繁的加減速對駕駛是很大的精神負擔。透過ACC可減輕對駕駛的負擔。"
  },
  {
    "id": "66c9f7e3-1511-4452-9d01-ae8eaaecf96f",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "RE(OUTLANDER)",
    "type": "fraction",
    "topic": "RE(OUTLANDER)－\n ASC車身動態穩定控制系統+TCL巡跡防滑控制系統",
    "content": "F(特色)：ASC車身動態穩定控制系統+TCL巡跡防滑控制系統─能主動保持車輛行進的穩定性，當車輛有打滑失控風險時，適時降低引擎動力輸出並輔以煞車作動，以降低失控之可能性 (1)\n \n B (效益)：SUV因車高關係重心較高，打滑或轉向過程較容易失控翻覆，ASC+TCL可維持車身穩定，降低意外發生之風險。\n \n E(證據)：\n 美國高速公路安全局研究: ASC可以有效降低SUV約 6成的失控率及8成的翻車率(1)，並規定所有新車必須配備此安全系統才能在美國市場銷售。\n ● 請撥放I-PAD主要特色\"安全\" 影片~ASC車身動態。"
  },
  {
    "id": "88000d5e-ff65-4494-9dea-38a6f7cff580",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "RE(OUTLANDER)",
    "type": "fraction",
    "topic": "RE(OUTLANDER)－\n 操控安定性提升",
    "content": "F(特色)：全新OUTLANDER透過車體與懸吊橫樑的剛性提升，後減震筒的尺寸加大，並重新設定懸吊彈簧係數、避震器阻尼係數，搭配全新設定的EPS電動輔助轉向系統，創造更多的駕駛樂趣(1)\n \n B(利益)：操控與行駛時車輛更加平順、安定，降低路面衝擊感，舒適性提升(1)\n 此外，操控轉向更加靈敏、準確，反應駕駛的動作即時又到位。"
  },
  {
    "id": "98bc3622-b1ea-4ea7-ab7b-239eb5fa0233",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "RE(OUTLANDER)",
    "type": "fraction",
    "topic": "RE(OUTLANDER)－\n S-AWC超能全時四輪控制系統",
    "content": "F(特色) ：\n 1.Outlander搭載源自Pajero 與Lancer Evolution兩大車種賽事經驗淬煉出的S-AWC超能全時四輪控制系統，將車輛動態控制系統集成於一體，整合4WD / AYC / ASC / ABS 等四大系統(1)\n (1)四輪驅動系統：分配車輛前後輪動力輸出(1)\n (2)AYC主動式偏擺控制系統(1)：安全性確保之外，更以駕駛人的操控指令為優先目標，以施加煞車力的方式主動分配左右輪間的驅動/制動力(1)，降低因推頭而產生之外拋狀況，進而提供優異過彎性能與行駛穩定性\n \n 2. 並具備 AWC ECO經濟] / NORMAL一般 / SNOW雪地 / GRAVEL砂石 四種模式，讓車主因應不同路況預先選擇適合的控制模式，且不論行進間或停止時均可進行切換。\n S-AWC模式選擇：\n (1)NORMAL一般：適合各種路面\n (2)SNOW雪地：增強濕滑路面的操控穩定性\n (3)GRAVEL砂石：適合於崎嶇道路駕駛與脫困\n (4)ECO經濟：平常以2WD驅動為主，一旦系統偵測到打滑情形立即自動切換為4WD。(1)\n \n 3.設計採全時四驅的概念，但仍保有2WD優先的省油效果。\n \n A(優勢)：\n 1.比起競品有更豐富的模式選擇，且搭載AYC主動式彎道動態控制，比一般的四輪傳動車型提供更佳的過彎與循跡性能(1)\n \n B(利益)：確保日常通勤時以2WD達成最佳的油耗表現，任何時刻亦能切換至S-AWC狀態，讓車輛與地面更服貼、更有「黏性」，大幅提升循跡穩定性及乘坐安心感，確保各種路況下的極致安全(1)"
  },
  {
    "id": "33f35502-5523-4360-949c-c9b84c690447",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "RE(OUTLANDER)",
    "type": "fraction",
    "topic": "RE(OUTLANDER)－\n 7-SRS環艙安全輔助系統",
    "content": "F(特色)：\n 1.搭載雙前與駕駛座膝部保護正面、雙側保護正副駕駛座手臂與胸腔、雙簾幕式氣囊保護前後座避免頭部撞擊到車窗玻璃與硬物(1)。\n 2.全車系標配雙前與駕駛座膝部氣囊。\n A(優勢)：\n 全面標配7SRS\n B(利益)：\n 1.多氣囊配置可提供乘客更完整的安全防護！\n 2.膝部氣囊可增加下半身安全防護，減少身體位移，降低安全帶束力對胸腔的壓迫(1)\n E(証據)：\n 在有繫安全帶的時速56公里前方撞擊測試中，有了膝部安全氣囊，不僅可減少駕駛下半身的傷害，胸部傷害嚴重性也可降低40%。\n \n ● 請撥放I-PAD主要特色\"安全\" 影片~7SRS。"
  },
  {
    "id": "01ba18ae-ff96-4194-b050-a290b5111b45",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "RE(OUTLANDER)",
    "type": "fraction",
    "topic": "RE(OUTLANDER)－\n 提供7人座選擇",
    "content": "F(特色)： 提供七人座選擇，二三排椅可收折創造靈活空間。\n  全車五個座位皆提供標準三點式安全帶，且於二排椅標配ISO FIX，保護全家人一起出遊之安全 。\n A(優勢)：領先多數SUV配備七人座，像是RVA4、CR-V等車型均沒有七人座設定可供選擇(1)\n B(效益)：第三排椅空間以備不時之需。\n E(証據)：(1)二排椅可前後滑動250mm，便利第三排乘客進出。(1)\n  (2)具備第三排椅設計，腿部空間達715mm且第三排座椅具備彈簧設計，增加舒適性。"
  },
  {
    "id": "adc3c5cc-c91a-4a83-ba63-a16a15181a50",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "RE(OUTLANDER)",
    "type": "fraction",
    "topic": "RE(OUTLANDER)－\n 聽說CRV全車系都有HONDA sensing (ACC/FCM)?",
    "content": "<記憶重點： RE安全型以上也都有全速域ACC/FCM>\n 1.CR-V終於從善如流，20年的小改款恢復了入門車型，搭載ACC/FCM，售價97.9萬起，還是比OUTLANDER的安全型95.9萬入手價來得貴(2)。\n \n 2.OUTLANDER安全型以上就有ACC/FCM，而且車型選擇較多(安全型、尊貴型、旗艦型)，可滿足不同車主的配備需求(2)\n  \n 3.FCM作動範圍時速範圍沒有OUTLANDER廣，功能性也沒有OUTLANDER強大\n FCM作動範圍：RE 5~140km/h 優於 CRV 5~100km/h"
  },
  {
    "id": "bda78229-c973-482e-90af-036698aa5511",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "CT(中華堅兵P350)",
    "type": "fraction",
    "topic": "CT(中華堅兵P350)－\n 2.4L三菱引擎+ISG油電複合動力",
    "content": "F(特色)：\n 1. 採用油電複合動力系統，2.4L引擎(4G69)搭配ISG(整合式馬達)。(2)\n 2. ISG位於引擎與變速箱中間，具有啟動引擎、提供助力及動能回收，於滑行、剎車、 怠速、小油門行駛時可對動力電池進行回充。(2)\n A(優勢)：為台灣首款自主研發ISG油電複合商用車型，ISG系統強化馬扭力表現，最大綜效馬力162.6ps、扭力26.2kg-m，馬力表現為同級最強(相比Porter、Kaon、Hino 200)。(2)\n B(利益)：車主對於動力感受更加直接，可以感受到起步輕巧、加速順暢與爬坡有力。\n E(實證)：PORTER與卡旺都只有馬力130ps、扭力26kg-m"
  },
  {
    "id": "ba3dc22f-54e9-47eb-be90-f5066df2aac7",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "CT(中華堅兵P351)",
    "type": "fraction",
    "topic": "CT(中華堅兵P350)－\n ISG油電複合動力模式",
    "content": "F(特色)：\n 1. 提供動力模式切換，PWR鍵為動力優先模式、CHR鍵為充電優先模式，也可同時開啟為綜合模式。(1)\n \n A(優勢)：3.5噸同級唯一擁有ISG油電複合動力車款\n \n B(利益)：\n 1. 不按任何按鍵為一般模式，即有最大額定約4.58kg-m馬達助力，市區行駛起步加速輕快。(2)\n 2. 開啟PWR鍵，可獲得最大額定約 6.62kg-m 馬達助力，載重輕鬆有力。(2)\n 3. 開啟CHR鍵，能加強電池回充，備足電量提供充足的動力輔助時間。(2)\n 4. 同時開啟PWR與CHR鍵，可獲得最大額定約 6.62kg-m 馬達助力並備足電量。(1)"
  },
  {
    "id": "e63ba468-6a34-4d22-9909-b43eaf3ee67c",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "CT(中華堅兵P352)",
    "type": "fraction",
    "topic": "CT(中華堅兵P350)－\n 同級最大載重與大貨台",
    "content": "F(特色)：搭載前後碟煞。(2)\n A(優勢)：Porter、卡旺、Hino200都只有前碟後鼓的煞車配置。\n B(利益)：載貨急煞時能有效縮短煞車距離，載重也煞得住，提高車主的安全性。(2)\n E(實證)：各車款在最大載重下，於時速80km/h下進行實測，中華堅兵煞停距離僅31.3m，Porter 要36m，卡旺甚至要46.8m。"
  },
  {
    "id": "01c62705-b5d8-4530-80e1-31d405ba5d21",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "CT(中華堅兵P353)",
    "type": "fraction",
    "topic": "CT(中華堅兵P350)－\n 多功能智慧煞車系統\n -HBA液壓煞車輔助系統",
    "content": "F(特色)：判斷駕駛者煞車動作，在緊急煞車時增加煞車力，縮短煞車距離(1)"
  },
  {
    "id": "b52c9dbe-f607-4f94-97ff-0fc60fc7812d",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "CT(中華堅兵P354)",
    "type": "fraction",
    "topic": "CT(中華堅兵P350)－\n 多功能智慧煞車系統\n -ARP主動式防止傾覆系統",
    "content": "F(特色)：系統監測車輛速度、側向加速度、方向盤轉動速度等訊號，當判斷車輛處於傾向側翻狀態時，ARP功能會介入，藉由降低引擎扭力輸出及對車輪施加煞車力控制，來達到降低車輛側翻風險(1)\n A(優勢)：領先同級車列為車型標配(1)\n B(利益)：防止車輛在高速駕駛狀態下的側翻現象。特別是針對有較高側翻風險的高重心商用車等車輛，可具有顯著的保護效果(1)"
  },
  {
    "id": "6102fdc1-5f97-4909-ac60-8825a775e1dd",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "CT(中華堅兵P355)",
    "type": "fraction",
    "topic": "CT(中華堅兵P350)－\n 複合動力系統保養費用會比一般燃油車貴嗎?",
    "content": "相較一般燃油車僅需每四萬公里時多更換動力電池防凍液、ISG馬達防凍液，每次更換價格合計約1,000元，保養費用與一般燃油車相當。若以Porter對比，10萬公里累積保養費用還省約3萬元"
  },
  {
    "id": "ec83122a-ae73-4de6-92d3-e940e06144ee",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "車輛介紹",
    "class5": "車輛介紹",
    "type": "fraction",
    "topic": "試乘重點(同試乘海報)",
    "content": "展廳內須有展示試乘海報(1分)。註：須展示於海報架內(牆壁固定式海報架、或著活動式海報架)\n 若有試乘，依以下車型可說明一項試乘重點得一分(非扣分)\n NPZ(COLT PLUS)：1 HSA陡坡起步輔助系統 2 同級最小迴轉半徑4.7m\n SR(GRAND LANCER)：1 NVH卓越隔音 2 極智數位中控台 3 極智數位儀表板 \n RE(OUTLANDER)：1 S-AWC/AYC 優異操控實力 2 NVH卓越隔音 3 ACC主動車距控制巡航 4 EPB電子式駐煞車附Auto Hold功能 \n AS(ZINGER)：1盲區偵測警示系統(BSW+LCA+RCTA) 2 HSA陡坡起步輔助系統 3數位儀錶板多種模式 4後座獨立空間\n AS PU(ZINGER PICKUP)：1舒適車室空間：座椅前後滑移/椅背角度調整 2 HSA陡坡起步輔助系統\n 僅SYM NS(ECLIPSE CROSS)：1 S-AWC/AYC優異操控實力 2 ACC 主動車距控制巡航系統 3 EPB電子式駐煞車附Auto Hold功能 4 BSW盲區偵測警示系統 5 HUD全彩液晶抬頭顯示器 6 NVH卓越隔音 \n 僅SYM REE(OUTLANDER PHEV)：1 電動車特有的低震動噪音、滑順行路品質 2 靈敏、快速的加速反應 3 不同動能回收功率等級的駕駛感受 4 切換CHRG充電/SAVE節電模式，體驗引擎作動與否的不同感受 5 ACC 主動車距控制巡航系統 6 BSW盲區偵測警示系統 \n 僅FMC JD/DE/DET(中華菱利/得利卡)：1 全新動力系統 2 自排平順便利 3 HSA陡坡起步輔助系統 4 盲區偵測警示系統(BSW+RCTA)\n 僅FMC 中華堅兵P350：1 同級最大油電複合動力162.6ps大馬力 2 同級唯一搭載前後碟煞高制動力 3 15吋後雙輪載重更穩定"
  },
  {
    "id": "726bb69e-e221-46f8-907d-78262c019a02",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "展車&試乘準備",
    "class5": "展車&試乘準備",
    "type": "fraction",
    "topic": "展車-清潔度、助成物",
    "content": "1. 門把、玻璃處、引擎蓋無指紋痕跡 \n 2.腳踏墊無明顯灰塵或碎石 \n 3. 車上部品塑膠袋/廠內文件\n 4. 展車若有充電器，電線需有蓋板(不可用膠帶黏貼)，且盒身放置靠近前輪內側，且將電線收拾整齊並以束帶綁好 \n 5. 展車需有隨車耗能標示 \n 6. 展車鑰匙不得放在車上"
  },
  {
    "id": "7b1ac23d-4343-463f-b7e8-aa8be6dad58f",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "展車&試乘準備",
    "class5": "展車&試乘準備",
    "type": "fraction",
    "topic": "展車-車輛充足性及顏色多樣",
    "content": "展車車型完整: OUTLANDER/ LANCER/COLT PLUS/ZINGER廂車/ZINGER貨車，NS(僅SYM)，展車車色須安排至少三色(含)\n 註1：因應各展廳空間差異，若空間僅能展三台車，則三車不同即可，若空間足夠(FMC5車、SYM6車)，則依上述各一，請紀錄缺車車款\n 註2：FMC基隆所、中壢所為特殊商用車據點，室內展車三台(ZNGER、.菱利、得利卡，貨車或廂車均可，且至少三色(含)，無乘用車為正確狀況 \n 註3：如遇展車缺車情形時，可由申訴返回成績。"
  },
  {
    "id": "50d8e1ae-6fd9-4250-8272-d4d6351384a6",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "展車&試乘準備",
    "class5": "展車&試乘準備",
    "type": "fraction",
    "topic": "試乘服務-客戶主動詢問試乘",
    "content": "業代須積極安排與回應，明確回覆可安排試乘(當日或他日試乘)\n ※註：若據點無指定車種的試乘車，業代須安排他日試乘(日期/時間)。"
  },
  {
    "id": "947897fd-cc87-45c3-ba5e-9a96f0f209b2",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "展車&試乘準備",
    "class5": "展車&試乘準備",
    "type": "fraction",
    "topic": "試乘前準備",
    "content": "1. 空調事先開啟 2. 備有杯水或礦泉水(1瓶) 3. 事先以試乘海報說明路線及試乘重點 4. 客戶駕照、填寫試乘申請書 (1項/1分)"
  },
  {
    "id": "7c9d1bae-32c0-4e71-a139-be0b977f9610",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "展車&試乘準備",
    "class5": "展車&試乘準備",
    "type": "fraction",
    "topic": "試乘車狀況",
    "content": "1. 車輛內外清潔 2. 試乘車油量至少二格 (1項/1分)"
  },
  {
    "id": "5decb2a9-5697-44fa-9ef4-edf5778a7794",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "展車&試乘準備",
    "class5": "展車&試乘準備",
    "type": "fraction",
    "topic": "試乘體驗",
    "content": "邀請顧客體驗車輛特性：利用試乘路況(線)進行車輛動態介紹。\n 業代於試駕過程中，解說車輛特性。例如：加速性、操控性、穩定性、舒適性、爬坡性能、變速系統、剎車系統…等。(提及1項得1分，上限3分)"
  },
  {
    "id": "f47f8d48-8c68-46a0-b96e-881f4f929e91",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "展車&試乘準備",
    "class5": "展車&試乘準備",
    "type": "fraction",
    "topic": "試乘後關懷",
    "content": "探詢客戶試乘後感受"
  },
  {
    "id": "3b6b56ee-5866-4dc0-a428-a2c43ad042d4",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "貸款＆保險",
    "class5": "貸款＆保險",
    "type": "fraction",
    "topic": "貸款說明-規範與流程",
    "content": "秘客主動提問0利率或分期貸款方式，銷售顧問需完整說明\n 1.重點要說到不是申請就一定會過，需依客戶狀況評估\n 2.貸款流程需會經過甄審→過件→動保設定三步驟"
  },
  {
    "id": "223c6f6d-7c50-4156-b228-cda1a7c64dca",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "貸款＆保險",
    "class5": "貸款＆保險",
    "type": "fraction",
    "topic": "保險說明-內容",
    "content": "1 強制險 2 車體險"
  },
  {
    "id": "eb947600-184e-43ca-b6f4-e9efb5b55e36",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "送客&後續聯絡",
    "class5": "送客&後續聯絡",
    "type": "fraction",
    "topic": "蒐集顧客連絡方式",
    "content": "業代須詢問客戶姓名及聯繫方式"
  },
  {
    "id": "40be11f7-ad88-46a5-843c-13374a5ba875",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "送客&後續聯絡",
    "class5": "送客&後續聯絡",
    "type": "fraction",
    "topic": "蒐集顧客連絡方式：邀請客戶加入官方LINE",
    "content": "2022/6/1起成立「 中華三菱LINE官方帳號」。據點須於洽談桌放置LINE QRCODE立牌，且業代須主動邀請客戶掃描QRCODE加入LINE好友。"
  },
  {
    "id": "baabf3b5-9022-4896-93b4-900a6b50c451",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "送客&後續聯絡",
    "class5": "送客&後續聯絡",
    "type": "fraction",
    "topic": "送客",
    "content": "1 商談結束時，業代需親送客戶至大門 (2分) 2 若客戶開車前來，業代需協助指揮交通並揮手再見(2分)"
  },
  {
    "id": "0f7d525d-9fc2-4175-9657-94590bc89aa8",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "送客&後續聯絡",
    "class5": "送客&後續聯絡",
    "type": "fraction",
    "topic": "售後聯繫",
    "content": "客戶離開展廳後，業代需於24小時內再次與客戶進行聯繫(2分)"
  },
  {
    "id": "f1ea33c7-eaa6-4f4e-a4c0-ec0181747a17",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "獨立加分題",
    "class5": "獨立加分題",
    "type": "bonus",
    "topic": "團隊服務",
    "content": "除了接待銷售顧問喊歡迎光臨，未接待其他客戶之銷售顧問於15秒內齊喊歡迎光臨 (3分)"
  },
  {
    "id": "04521bbf-4e50-46d4-8a98-f3240432bf80",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "獨立加分題",
    "class5": "獨立加分題",
    "type": "bonus",
    "topic": "驚喜/感動服務",
    "content": "舉例如下，請訪員文字說明，1項給1分\n 1 來店客上門時小跑步迎接、若下雨要幫客戶撐傘、協助引導停車等， 讓客人感受到銷售顧問積極的態度。\n 2.迎接來店客時，除了主要負責接待客戶的同仁外，其他人員協助、接待，展現據點團隊合作的精神。\n 3 替顧客想到客戶沒有想到的事，適時的作出提醒。\n 4 點心擺放形式如圖，使客人感受到服務周到。"
  },
  {
    "id": "29ba809b-e595-469f-ae58-331e21d27f83",
    "class1": "汽車業",
    "class2": "現場",
    "class3": "神秘稽查",
    "class4": "獨立加分題",
    "class5": "獨立加分題",
    "type": "bonus",
    "topic": "試乘服務",
    "content": "【業代主動幫客戶開門】並伸手保護客人頭部，可加2分。\n 【業代\"主動\"探詢試乘需求】業代主動邀約顧客當日或他日試乘，可加2分。\n ※註：若據點無指定車種的試乘車，業代主動預約他日試乘(日期/時間)。"
  }
];
