import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction
} from 'mobx';
import Papa from 'papaparse';
import dayjs from 'dayjs';
import { message } from 'antd';
import CustomModalViewModel from 'src/components/CustomModal/viewModel';
import VisitorServices from 'src/services/VisitorServices';
import ErrorServices from 'src/services/ErrorServices';
import { papaConfig } from 'src/global/config';
import { IMPORT_DATE_FORMATS } from 'src/global/constants';
import { checkImportData, downloadCSV } from 'src/global/methods';
import { example } from './fakeData';

class ImportModalViewModel {
  // 上傳的檔案
  @observable files = [];
  @observable data = [];
  @observable isAwait = false;

  // 彈窗 vm
  modalVM = new CustomModalViewModel();
  importErrorModalVM = new CustomModalViewModel();

  // 批次更新完成後執行
  onCreated = null;

  // 有效的
  @computed
  get validData() {
    const valid = this.data.filter((item) => {
      const res = this.checkForm(item);
      return res.check;
    });

    return valid.map((item) => {
      const { name, idNumber, birthday, phone, remark } = item;

      return { name, idNumber, birthday, phone, remark };
    });
  }

  // 無效的
  @computed
  get invalidData() {
    const invalid = this.data.filter((item) => {
      const res = this.checkForm(item);
      return !res.check;
    });

    return invalid.map((item) => {
      const res = this.checkForm(item);
      return { ...item, errorMessage: res.errorMessage };
    });
  }

  // 確認檢查
  @computed
  get disabledConfirm() {
    return !this.files.length || this.isAwait;
  }

  constructor() {
    makeObservable(this);
  }

  init = ({ onCreated }) => {
    this.onCreated = onCreated;
  };

  @action
  open = () => {
    this.reset();
    this.modalVM.show();
  };

  @action
  close = () => {
    this.modalVM.hide();
  };

  @action
  reset = () => {
    this.files = [];
    this.data = [];
  }

  // 下載範例
  onDownload = () => {
    downloadCSV({ data: example, fileName: '批次新增訪員範本' });
  };

  // 讀取 csv 資料
  getCSVData = (file) => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        ...papaConfig.import,
        complete: (results) => {
          resolve(results.data);
        }
      });
    });
  };

  // 檢查格式
  checkForm = (data) => {
    const checkName = data.name?.trim();
    const checkIdNumber = data.idNumber?.trim();
    const checkBirthday = data.birthday;

    if (!checkName) {
      return {
        check: false,
        errorMessage: '姓名'
      };
    }

    if (!checkIdNumber) {
      return {
        check: false,
        errorMessage: '身分證字號'
      };
    }

    if (!checkBirthday) {
      return {
        check: false,
        errorMessage: '生日'
      };
    }

    // 檢查身分證重複
    for (let i = 0; i < this.data.length; i += 1) {
      const item = this.data[i];

      if (data !== item && data.idNumber === item.idNumber) {
        return {
          check: false,
          errorMessage: '身分證字號重複'
        };
      }
    }

    return { check: true };
  }

  formatDate = (date) => {
    for (let i = 0; i < IMPORT_DATE_FORMATS.length; i += 1) {
      const format = IMPORT_DATE_FORMATS[i];
      const flag = dayjs(date, format, true).isValid();

      if (flag) {
        return dayjs(date, format).format('YYYY-MM-DD');
      }
    }

    return null;
  };

  // 覆蓋預設上傳行為
  @action
  onCustomRequest = async (event) => {
    this.isAwait = true;

    const files = [event.file];
    const data = await Promise.all(
      files.map((item) => {
        return this.getCSVData(item);
      })
    );

    runInAction(() => {
      const mapData = data.flat().map((item, i) => {
        const birthday = this.formatDate(item['生日']);

        return {
          index: i,
          name: item['姓名'],
          idNumber: item['身分證字號'],
          birthday,
          phone: item['通訊電話'],
          remark: item['備註']
        };
      }).filter((item) => checkImportData(item));

      if (mapData.length) {
        this.files = files;
        this.data = mapData;
      } else {
        this.files = [];
        this.data = [];

        message.error('資料格式錯誤, 請重新檢視', 5);
      }

      event.onSuccess();

      this.isAwait = false;
    });
  };

  // 刪除上傳選項
  @action
  onRemove = (file) => {
    const uid = file.uid;

    this.files = this.files.filter((item) => item.uid !== uid);

    return true;
  };

  // 確認
  @action
  onConfirm = async () => {
    this.postVisitorCreateBatchAPI();
  };

  // 無效資料彈窗, 確認按鈕
  @action
  onConfirmImportErrorModal = () => {
    this.reset();
    this.importErrorModalVM.hide();

    this.onCreated();
  };

  // create api
  @action
  postVisitorCreateBatchAPI = async () => {
    this.isAwait = true;

    try {
      const res = await VisitorServices.postVisitorCreateBatch(this.validData);

      runInAction(() => {
        this.close();

        // 沒有有無效資料
        if (!this.invalidData.length) {
          this.reset();
          this.onCreated();
        }
      });
    } catch (error) {
      const eMsg = ErrorServices.postVisitorCreateBatch(error);

      console.log('CreateModalViewModel', 'postVisitorCreateBatch', eMsg);
    } finally {
      runInAction(() => {
        // 有無效資料
        if (this.invalidData.length) {
          this.importErrorModalVM.show();
        }

        this.isAwait = false;
      });
    }
  };
}

export default ImportModalViewModel;
